import React, { useEffect, useState, createContext } from "react";
import { getOutletConfig } from "../js/config";
import { useTranslation } from "react-i18next";
import Accourdion from "@c/Accordion";
import CmpFormik from "@z/CmpFormik";

export const onLineOutletContext = createContext();
function AreaOutlet(props) {
  const {
    addFormik,
    outletConfig,
    setOutletConfig,
    expandedIndex,
    handleChange,
    handleConfirm,
    handlerCancel,
    initValue,
  } = props;
  const { t } = useTranslation();

  const [deviceOnlineOutlet, setDeviceOnlineOutlet] = useState([]);

  useEffect(() => {
    let configInfo = getOutletConfig(t, deviceOnlineOutlet, addFormik);
    setOutletConfig(configInfo);
  }, [deviceOnlineOutlet]);

  return (
    <onLineOutletContext.Provider
      value={{ deviceOnlineOutlet, setDeviceOnlineOutlet, initValue }}
    >
      <Accourdion
        elevation={0}
        expanded={expandedIndex === 1}
        label={t("events.areaFilter")}
        onChange={handleChange(1, ["name"])}
        handlerConfirm={handleConfirm(2, ["name", "outletIds"])}
        handlerCancel={handlerCancel(0, ["name", "outletIds"])}
      >
        <CmpFormik
          sx={4}
          formik={addFormik}
          formConfig={outletConfig}
        ></CmpFormik>
      </Accourdion>
    </onLineOutletContext.Provider>
  );
}

export default AreaOutlet;
