export default {
  table: {
    name: "ชื่อ:",
    code: "รหัส:",
    phone: "โทรศัพท์:",
    email: "อีเมล:",
    first_name: "ชื่อจริง:",
    last_name: "นามสกุล:",
    mobile_number: "หมายเลขโทรศัพท์มือถือ:",
    city: "เมือง:",
    country: "ประเทศ:",
    address: "ที่อยู่:",
    mobile: "โทรศัพท์มือถือ",
    state: "จังหวัด/รัฐ",
    country_code: "รหัสประเทศ",
    company_code: "รหัสบริษัท",
    company_name: "ชื่อบริษัท",
    app_id: "รหัสแอป",
    city_zone: "โซนเมือง",
    area_code: "รหัสพื้นที่",
    time_zone: "โซนเวลา",
  },

  table_product: {
    product_name: "ชื่อสินค้า",
  },

  table_approval: {
    title: "แผนภูมิขั้นตอนการอนุมัติ",
    add: "เพิ่มแผนภูมิขั้นตอนการอนุมัติ",
    edit: "แก้ไขแผนภูมิขั้นตอนการอนุมัติ",
    person: "ผู้อนุมัติ",
    createtime: "สร้างเวลา",
    note: "หมายเหตุ:",
  },
};
