import { Box, Typography } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getClientById } from "../../services/CompanyService";
import OverFlowText from "../../components/OverFlowText";
import { useTranslation } from "react-i18next";

export default function ViewRetailClient() {
  const { t } = useTranslation();
  const location = useLocation();
  const [payload, setPayload] = useState({
    id: "",
    name: "",
    addressLine1: "",
    cityId: "",
    stateId: "",
    countryId: "",
    phone: "",
    code: "",
    email: "",
  });

  useEffect(() => {
    if (location.state?.id) {
      getClientById(location.state?.id).then((res) => {
        if (res.data.code === "LVLI0000") {
          var client = {};
          client.id = res?.data?.data?.id;
          client.name = res?.data?.data?.name;
          client.code = res?.data?.data?.code;
          client.type = res?.data?.data?.type;
          client.addressLine1 = res?.data?.data.addressLine1;
          client.cityId = res?.data?.data?.cityId;
          client.stateId = res?.data?.data?.stateId;
          client.countryId = res?.data?.data?.countryId;
          client.phone = res?.data?.data?.phone;
          client.email = res?.data?.data?.email;
          setPayload({
            ...payload,
            ...client,
          });
        }
      });
    }
  }, [location.state?.id]);

  return (
    <RightViewLayout
      navigateBack={"-1"}
      title={t("retail_client.view_retail_client")}
    >
      <ViewPage>
        <Box>
          <Typography variant="fieldLabel">
            {t("retail_client.retail_client_name")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.name ? payload.name : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("retail_client.retail_client_code")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.code ? payload.code : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("retail_client.retail_admin_email")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.email ? payload.email : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("synchronized.mobile")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.phone ? payload.phone : "-"}
        </Typography>
        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("synchronized.address")}:
          </Typography>
        </Box>
        <OverFlowText variant="fieldValue" maxLength="100">
          {payload && payload.addressLine1 ? payload.addressLine1 : "-"}
        </OverFlowText>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("synchronized.country")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.countryId ? payload.countryId : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.state")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.stateId ? payload.stateId : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("synchronized.city")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.cityId ? payload.cityId : "-"}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
