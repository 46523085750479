import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import ListLayout from "../../components/ListLayout";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import { REACT_APPROVAL_FLOW_CHART_ADD } from "../../router/ReactEndPoints";
import { useNavigate } from "react-router-dom";
import { chnageStatus } from "../../services/PriceChangeEventservice";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getApprovedAndRejectedEvents } from "../../services/ApprovalService";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";

export default function ApprovalLog() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;

  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
  });

  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
  };

  const loadData = (request) => {
    getApprovedAndRejectedEvents(request).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRecords(res?.data?.data?.flowChartData);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.pageSize);
      }
    });
  };

  useEffect(() => {
    loadData(filters);
  }, [filters]);

  const handleStatusChange = () => {
    let request = {
      id: id,
      approvalStatus: status,
    };
    chnageStatus(id, request).then((res) => {
      if (res?.data?.code === "LVLI0003") {
        setConfirm(false);
        loadData(filters);
      }
      if (res?.data?.code !== "LVLI0003") {
        enqueueSnackbar(t(res?.data?.message), { variant: "error" });
      }
    });
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddApprovalLog");
    isAllowedView = permission && permission.includes("ViewApprovalLog");
    isAllowedEdit = permission && permission.includes("EditApprovalLog");
    isAllowedDelete = permission && permission.includes("DeleteApprovalLog");
  }

  const getFormatedDate = (date) => {
    const formatedDate = date ? moment(date).format(dateTimeFormat) : "";
    return formatedDate;
  };

  const approvalStatus = (status) => {
    if (status) {
      if (status === "Approved") {
        return <Typography color={"green"}>{status}</Typography>;
      }
      if (status === "Rejected") {
        return <Typography color={"red"}>{status}</Typography>;
      } else {
        return <Typography color={"gray"}>{status}</Typography>;
      }
    }
  };

  const columns = [
    {
      field: "eventName",
      headerName: `${t("approval.approvalLogEvent")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.eventName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.eventName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "Maker",
      headerName: `${t("approval.maker")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.createdByName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.createdByName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "approvedBy",
      headerName: `${t("approval.approvalLogPerson")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.approvedBy} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.approvedBy)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: `${t("table_approval.createtime")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            title={getFormatedDate(e.row.createdAt)}
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(getFormatedDate(e?.row?.createdAt))}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "approvedAt",
      headerName: `${t("approval.approvalLogApprovalTime")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            title={getFormatedDate(e.row.approvedAt)}
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(getFormatedDate(e.row.approvedAt))}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "approvalStatus",
      headerName: `${t("approval.approvalLogStatus")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.approvalStatus} arrow placement="bottom">
            <span>
              {CommonUtil.formatLongText(approvalStatus(e.row.approvalStatus))}
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleActions = (action, data) => {
    if (action === "View") {
      // navigate(REACT_APPROVAL_LIST_VIEW,{state: {data:data}});
    }
    if (action === "Approve") {
      setId(data);
      setConfirm(true);
      setStatus("Approved");
    }
    if (action === "Reject") {
      setId(data);
      setConfirm(true);
      setStatus("Rejected");
    }
  };

  const toolbarProps = {
    // add: true,
    // add: isAllowedCreate,
    filter: false,

    refresh: true,
    onAdd: (data) => {
      navigate(REACT_APPROVAL_FLOW_CHART_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...filters, ...defaultFilters });
      loadData(filters);
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  const handlePageChange = (e) => {
    setFilters({
      ...defaultFilters,
      pageSize: rowsPerPage,
      pageNumber: e,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
    setRowsPerPage(e);
  };

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  return (
    <ListLayout title={t("approval.approvalLog")} toolbarProps={toolbarProps}>
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onSelection={() => console.log()}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
      />
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">
            {t("tips_approval.change_status")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStatusChange()}
            >
              {t("common.yes")}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
