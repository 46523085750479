import React from "react";
import { Grid, Typography } from "@mui/material";
import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Years from "./Years";
import VaildDate from "./VaildDate";
import { useTranslation } from "react-i18next";

function SetRepeatCondition(props) {
  const { t } = useTranslation();
  const { addFormik } = props;

  return (
    <Grid>
      <Typography>{t("events.setRepeatCondition")}</Typography>

      <Grid bgcolor={"rgb(250, 250, 251)"} mt={3}>
        {(() => {
          const componentsMap = {
            Daily: <Daily addFormik={addFormik}></Daily>,
            Weekly: (
              <>
                <Weekly addFormik={addFormik} />
                <Daily addFormik={addFormik}></Daily>
              </>
            ),
            Monthly: (
              <>
                <Monthly addFormik={addFormik}></Monthly>
                <VaildDate addFormik={addFormik}></VaildDate>
                <Daily addFormik={addFormik}></Daily>
              </>
            ),
            Annually: (
              <>
                <Years addFormik={addFormik}></Years>
                <Monthly addFormik={addFormik}></Monthly>
                <VaildDate addFormik={addFormik}></VaildDate>
                <Daily addFormik={addFormik}></Daily>
              </>
            ),
          };
          return componentsMap[addFormik.values["scheduleMode"]] || null;
        })()}
      </Grid>
    </Grid>
  );
}

export default SetRepeatCondition;
