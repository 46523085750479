import { Box, Grid, Paper } from "@mui/material";
import * as React from "react";
import { useSSR, useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/images/L3ePriceLabel.svg";
import SidebarMenuItem from "./SidebarMenuItem";
import SidebarSubMenuItem from "./SidebarSubMenuItem";
import SwitchLanguage from "./SwitchLanguage";
import UserProfileMenu from "./UserProfileMenu";
import CompanyProfileMenu from "./CompanyProfileMenu";
import { getPermissionById, getPersonById } from "../../services/PersonService";
import { useEffect, useState } from "react";
import SidebarMenuItems from "./SidebarMenuItems";
import { ReactComponent as ActiveDashboardMenu } from "../../assets/images/DashboardActiveIcon.svg";
import { ReactComponent as InActiveDashboardMenu } from "../../assets/images/menu_dashboard_out.svg";
import { ReactComponent as InActiveRetailClientMenu } from "../../assets/images/menu_retail_client.svg";
import { ReactComponent as ActiveRetailClientMenu } from "../../assets/images/menu_retail_main_data_in.svg";
import { ReactComponent as ActiveTemplateMenu } from "../../assets/images/menu_template_in.svg";
import { ReactComponent as InActiveTemplateMenu } from "../../assets/images/menu_template_inactive.svg";
import { ReactComponent as ActivePriceMenu } from "../../assets/images/menu_price_management_active.svg";
import { ReactComponent as InActivePriceMenu } from "../../assets/images/menu_price_inactive.svg";
import { ReactComponent as InActiveDeviceMenu } from "../../assets/images/menu_device.svg";
import { ReactComponent as ActiveDeviceMenu } from "../../assets/images/menu_device_in.svg";
import { ReactComponent as ActiveUserManagement } from "../../assets/images/InActiveUserManagement.svg";
import { ReactComponent as InActiveUserManagement } from "../../assets/images/ActiveUserManagement.svg";
import { ReactComponent as ActiveSystemSetting } from "../../assets/images/SystemSetting_active.svg";
import { ReactComponent as InActiveSystemSetting } from "../../assets/images/SystemSetting_inactive.svg";
import { ReactComponent as ActivePrincipalMenu } from "../../assets/images/menu_principal.svg";
import { ReactComponent as InActivePrincipalMenu } from "../../assets/images/menu_principal.svg";
import { ReactComponent as ActiveRetailMainData } from "../../assets/images/menu_retail_main_data_in.svg";
import { ReactComponent as ActiveDeviceManagement } from "../../assets/images/menu_device_in.svg";
import { ReactComponent as InActiveDeviceManagement } from "../../assets/images/menu_device.svg";
import { ReactComponent as ActiveApprovalManagement } from "../../assets/images/Approval_Icon.svg";
import { ReactComponent as InActiveApprovalManagement } from "../../assets/images/ApproveInActiveMenu.svg";
import { ReactComponent as ActiveSubscriptionMenu } from "../../assets/images/SubscriptinActiveMenu.svg";
import { ReactComponent as InActiveSubscriptionMenu } from "../../assets/images/SubscriptionInActive.svg";

export default function Sidebar(props) {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  const path = window.location.pathname;
  const roleName = localStorage.getItem("ROLE_NAME");
  const retailClient = localStorage.getItem("RETAIL_CLIENT");
  const user = JSON.parse(localStorage.getItem("USER"));
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    if (user && user.id && roleName === "ADMIN") {
      loadData();
    }
  }, [roleName]);

  useEffect(() => {
    getPermissionById(user.id).then((res1) => {
      if (res1?.data?.code === "LVLI0000") {
        localStorage.setItem("permission", JSON.stringify(res1.data.data));
      }
    });
  }, []);

  const loadData = () => {
    getPersonById(user.id).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        const menuString = res.data.data;

        setGroupedData([menuString]);
      }
    });
  };

  const getCategoryImage = (category) => {
    switch (category) {
      case "User Management":
        return {
          active: ActiveUserManagement,
          inactive: InActiveUserManagement,
        };
      case "System Setting":
        return {
          active: ActiveSystemSetting,
          inactive: InActiveSystemSetting,
        };
      case "Retail Main Data":
        return {
          active: ActiveRetailMainData,
          inactive: InActiveRetailClientMenu,
        };
      case "Device Management":
        return {
          active: ActiveDeviceManagement,
          inactive: InActiveDeviceManagement,
        };
      case "Price Management":
        return {
          active: ActivePriceMenu,
          inactive: InActivePriceMenu,
        };
      case "Approval":
        return {
          active: ActiveApprovalManagement,
          inactive: InActiveApprovalManagement,
        };
      default:
        return {
          active: ActiveDeviceManagement,
          inactive: InActiveDeviceManagement,
        };
    }
  };

  return (
    <Box
      display={"flex"}
      component={Paper}
      variant="outlined"
      my={1}
      ml={1}
      sx={{
        paddingTop: "10px",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollbarColor: "rgb(145, 204, 117,0.8) transparent",
      }}
      width={"270px"}
      height={"98%"}
      border={"4px solid #FFF"}
      justifyContent={"flex-start"}
      bgcolor={"white"}
      borderRadius={2}
      borderColor="#78BC27"
      flexDirection={"column"}
    >
      <Grid pl={3} pt={0.5} pb={1}>
        <Logo />
      </Grid>

      <Grid
        display={"block"}
        sx={{
          font: "normal normal normal 18px/26px Roboto",
          letterSpacing: "0px",
          color: "#474B4F",
          opacity: "1",
        }}
      >
        {roleName === "OWNER" && retailClient === "" && (
          <>
            <SidebarMenuItem
              link="/retail-client"
              label={t("menu.retail_client_management")}
              isActive={path.includes("/retail-client")}
              activeMenu={ActiveRetailClientMenu}
              inActiveMenu={InActiveRetailClientMenu}
            />

            <SidebarMenuItem
              id="SystemSettingSubMenu"
              link="/template"
              label={t("menu.template_list")}
              isActive={
                path.includes("/template") ||
                path.includes("/template/view") ||
                path.includes("/template/add") ||
                path.includes("/template/createTemplate")
              }
              activeMenu={ActiveTemplateMenu}
              inActiveMenu={InActiveTemplateMenu}
            ></SidebarMenuItem>
            <SidebarMenuItem
              link="/third-party"
              label={t("menu.open_api_management")}
              isActive={path.includes("/third-party")}
              activeMenu={ActiveRetailClientMenu}
              inActiveMenu={InActiveRetailClientMenu}
            />
            <SidebarMenuItem
              link="/picture-library"
              label={t("menu.picture_library")}
              isActive={path.includes("/picture-library")}
              activeMenu={ActiveSystemSetting}
              inActiveMenu={InActiveSystemSetting}
            ></SidebarMenuItem>
            {/* <SidebarMenuItem
              link="/subscription"
              label={t("Subscription")}
              isActive={
                path.includes("/subscription")
              }
              activeMenu={ActiveSubscriptionMenu}
              inActiveMenu={InActiveSubscriptionMenu}
            >
               <SidebarSubMenuItem
                link="/subscription"
                label={t("Subscription")}
                isActive={path.includes("/subscription")}
              />
              <SidebarSubMenuItem
                link="/subscription_records"
                label={t("Subscription Rescords")}
                isActive={path.includes("/subscription_records")}
              />
            </SidebarMenuItem> */}
          </>
        )}

        {(roleName === "SUPERADMIN" ||
          (roleName === "OWNER" && retailClient !== "")) && (
          <>
            <SidebarMenuItems
              t={t}
              path={path}
              ActiveDashboardMenu={ActiveDashboardMenu}
              InActiveDashboardMenu={InActiveDashboardMenu}
              ActiveRetailClientMenu={ActiveRetailClientMenu}
              InActiveRetailClientMenu={InActiveRetailClientMenu}
              ActiveDeviceMenu={ActiveDeviceMenu}
              InActiveDeviceMenu={InActiveDeviceMenu}
              ActivePrincipalMenu={ActivePrincipalMenu}
              InActivePrincipalMenu={InActivePrincipalMenu}
              ActiveTemplateMenu={ActiveTemplateMenu}
              InActiveTemplateMenu={InActiveTemplateMenu}
              ActivePriceMenu={ActivePriceMenu}
              InActivePriceMenu={InActivePriceMenu}
              ActiveUserManagement={ActiveUserManagement}
              InActiveUserManagement={InActiveUserManagement}
              ActiveSystemSetting={ActiveSystemSetting}
              InActiveSystemSetting={InActiveSystemSetting}
              ActiveApprovalManagement={ActiveApprovalManagement}
              InActiveApprovalManagement={InActiveApprovalManagement}
            />
          </>
        )}

        {roleName === "ADMIN" && (
          <>
            <SidebarMenuItem
              link="/dashboard"
              label={t("menu.dashboard")}
              isActive={path.includes("/dashboard")}
              activeMenu={ActiveDashboardMenu}
              inActiveMenu={InActiveDashboardMenu}
            />

            {groupedData.length > 0 &&
              groupedData.map((categoryItems) => {
                if (
                  typeof categoryItems === "object" &&
                  !Array.isArray(categoryItems)
                ) {
                  const filteredCategoryItems = Object.entries(
                    categoryItems
                  )?.filter(([category, items]) => category !== "Dashboard");
                  return filteredCategoryItems.map(([category, items]) => {
                    const firstItemLink = items[0]?.link;
                    const isActiveCategory = items.some((item) =>
                      path.includes(item?.link)
                    );
                    const activeMenus = getCategoryImage(category).active;
                    const inActiveMenus = getCategoryImage(category).inactive;

                    return (
                      <SidebarMenuItem
                        key={category}
                        label={t(category)}
                        link={firstItemLink}
                        isActive={isActiveCategory}
                        activeMenu={activeMenus}
                        inActiveMenu={inActiveMenus}
                      >
                        {items?.map((item) => (
                          <SidebarSubMenuItem
                            key={item?.name}
                            link={item?.link}
                            label={t(item?.name)}
                            isActive={path.includes(item?.link)}
                          />
                        ))}
                      </SidebarMenuItem>
                    );
                  });
                }
                return null;
              })}
          </>
        )}
      </Grid>

      <Box
        display={"flex"}
        sx={{
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-end",
          mt: "30px",
          mb: "30px",
        }}
      >
        <CompanyProfileMenu />
        <UserProfileMenu />
        <SwitchLanguage />
      </Box>
    </Box>
  );
}
