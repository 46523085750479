import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PERSON_LIST, RETAIL_CLIENT } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProfilePic from "../../assets/images/ProfilePic.svg";
import ProfileUplod from "../../components/UploadImg";
import CommonUtil from "../../util/CommonUtils";
import {
  getPersonByIdOrCode,
  updatePerson,
} from "../../services/PersonService";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import axios from "axios";

export default function EditPerson() {
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState(ProfilePic);
  const [profileImage, setProfileImage] = useState();
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profilePhoto: "profilePhoto",
    localRoleId: "",
    outletIds: [],
  });

  const [error, setError] = useState({
    firstName: "",
    email: "",
  });

  useEffect(() => {
    getPersonByIdOrCode(params.data).then((res) => {
      if (res?.data?.code === "UASI0033") {
        setPayload({
          ...payload,
          ...res.data.data,
        });
        setProfile(res?.data?.data?.imagePreSignedURL);
      }
    });
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: `${t("tips.required")}`,
      });
      return;
    }
    if (
      !CommonUtil.isEmpty(payload.firstName) &&
      toString(payload.firstName).length > 60
    ) {
      setError({
        ...error,
        firstName: `${t("tips_table.first_name_limit")}`,
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: `${t("tips.required")}`,
      });
      return;
    } else {
      if (!CommonUtil.isValidEmail(payload.email)) {
        setError({
          ...error,
          email: `${t("tips.invalid_email_address")}`,
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      updatePerson(params.data, payload).then((res) => {
        if (res?.data?.data && res.data.code === "UASI0002") {
          enqueueSnackbar(t("tips_user.person_updated"), {
            variant: "success",
          });
          if (
            !CommonUtil.isEmpty(res.data.data.user.imagePreSignedURL) &&
            profileImage
          ) {
            uploadProfilePhoto(res);
          }
          navigate(
            window.location.pathname.includes("/retail-client")
              ? RETAIL_CLIENT +
                  params.id +
                  "/" +
                  params.code +
                  REACT_PERSON_LIST
              : "/principal/" +
                  params.id +
                  "/" +
                  location.state.code +
                  REACT_PERSON_LIST
          );
          return;
        }

        switch (res?.data?.code) {
          case "OMSE0010":
            setError({
              ...error,
              firstName: res.data.message,
            });
            break;
          case "OMSE0304":
            setError({
              ...error,
              firstName: res.data.message,
            });
            break;
          case "OMSE0305":
            setError({
              ...error,
              lastName: res.data.message,
            });
            break;
          case "OMSE0184":
            setError({
              ...error,
              email: res.data.message,
            });
            break;
          default:
            enqueueSnackbar(res.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: "profilePhoto",
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append("url", data?.data?.data?.user?.imagePreSignedURL);
    bodyFormData.append("file", profileImage);

    axios({
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL + "/web/person/profile",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
      },
    });
  };

  return (
    <RightViewLayout
      id="editpersonback"
      title={t("user.edit_person")}
      navigateBack={
        window.location.pathname.includes("/retail-client")
          ? RETAIL_CLIENT + params.id + "/" + params.code + REACT_PERSON_LIST
          : "/" +
            "principal" +
            "/" +
            params.id +
            "/" +
            params.code +
            REACT_PERSON_LIST
      }
    >
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0} sx={{ height: "100%", width: "70%" }}>
          <Grid container px={2}>
            <Grid
              item
              xs={12}
              md={4}
              container
              display={"flex"}
              justifyContent={"center"}
              pt={2}
            >
              <ProfileUplod
                imageInfo={t("tips_user.allowed_file_types")}
                imageSize={t("tips_user.max_file_size")}
                style={{ borderStyle: "none !important" }}
                onsetProfile={(profile) => handleProfile(profile)}
                profilePhoto={profile}
              />
            </Grid>
            <Grid container xs={12} md={8} item spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  id="EditPerson1"
                  required
                  label={t("table.first_name")}
                  size="small"
                  name="firstName"
                  error={error.firstName}
                  resetError={() => setError({ ...error, firstName: "" })}
                  value={payload.firstName}
                  handleChange={handleChange}
                  inputProps={{
                    maxLength: 60,
                  }}
                  helperText={error.firstName}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  id="EditPerson2"
                  label={t("table.last_name")}
                  size="small"
                  name="lastName"
                  error={error.lastName}
                  resetError={() => setError({ ...error, lastName: "" })}
                  value={payload.lastName}
                  handleChange={handleChange}
                  inputProps={{
                    maxLength: 60,
                  }}
                  helperText={error.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  id="EditPerson3"
                  disabled
                  label={t("table.email")}
                  size="small"
                  name="email"
                  value={payload.email}
                  error={error.email}
                  resetError={() => setError({ ...error, email: "" })}
                  helperText={error.email}
                  inputProps={{
                    maxLength: 36,
                  }}
                  validation="email"
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Box item pl={2}>
                    <Button
                      id="editpersonsubmit"
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      onClick={handleSubmit}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item>
                    <Button
                      id="editpersonnext"
                      className="text-transform-none"
                      variant="outlined"
                      onClick={() =>
                        navigate(
                          RETAIL_CLIENT +
                            params.id +
                            "/" +
                            params.code +
                            REACT_PERSON_LIST
                        )
                      }
                      size="large"
                    >
                      {t("common.back")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
