/* eslint-disable no-undef */
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import MapTextAutoComplete from "./MapTextAutoComplete";
import { NavigationControl, Map, MapApiLoaderHOC } from "react-bmapgl";
import { useConfirm } from "../../components/zkconfirm";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
const BaiduMap = forwardRef((props, ref) => {
  const { t } = useTranslation();
  // 传递事件给父组件调用
  // useImperativeHandle(ref, () => ({}));
  const confirm = useConfirm();
  const mapRef = useRef(null);
  const [code, setCode] = useState(undefined);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({
    lnt: 118.11591568760504,
    lat: 24.479353788124797,
  });

  const getNowLocation = () => {
    return new Promise((resolve, reject) => {
      const geolocation = new BMapGL.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() === BMAP_STATUS_SUCCESS) {
          setCode(r.address.city);
          resolve(r.point);
        } else {
          toast.error(t("获取当前位置失败"));
          // reject(t("获取当前位置失败"));
        }
      });
    });
  };

  const Geolocation = async (lng, lat) => {
    return new Promise((resolve, reject) => {
      const myGeo = new BMapGL.Geocoder();
      // 根据坐标得到地址描述
      myGeo.getLocation(new BMapGL.Point(lng, lat), function (result) {
        if (result) {
          resolve(result);
        } else {
          reject(null);
        }
      });
    });
  };
  const handleMapClick = () => {
    mapRef.current.map.addEventListener("click", async function (e) {
      const lng = e.latlng.lng;
      const lat = e.latlng.lat;

      const location = await Geolocation(lng, lat);

      confirm({
        title: t("确认选择该位置"),
        confirmationText: t("OK"),
        cancellationText: t("Cancel"),
        description: t(
          "当前选择的城市为：{{address}}，选择的经纬度为：{{lng}},{{lat}}。",
          {
            address: location.address,
            lng: String(lng),
            lat: String(lat),
          }
        ),
      })
        .then(() => {
          props.getLocation(e.latlng, location);
          handleClose();
        })
        .catch(() => {});
    });
    const currentPosition = props.currentPosition;
    if (currentPosition && props.storeInfo) {
      setCenter(currentPosition);
      setZoom(18);
      mapRef.current.map.addOverlay(
        new BMapGL.Marker(props.currentPosition, { title: "aaa" })
      );
      let infoWindow = new BMapGL.InfoWindow(
        `<span style="padding-top:10px">${t("当前门店位置为")}: ${
          props.storeInfo.address
        }  <br/>${t("经纬度信息")}：${currentPosition.lng} <br/> ${
          currentPosition.lat
        }</span>`,
        {
          width: 250, // 信息窗口宽度
          height: 120, // 信息窗口高度
          title: props.storeInfo.name + `-${t("门店信息")}`, // 信息窗口标题
        }
      ); // 创建信息窗口对象
      mapRef.current.map.openInfoWindow(
        infoWindow,
        new BMapGL.Point(currentPosition.lng, currentPosition.lat)
      ); //开启信息窗口
      const location = Geolocation(currentPosition.lng, currentPosition.lat);
      if (location && location.addressComponents) {
        setCode(location.addressComponents.city);
      }
    } else {
      // 新建打开，获取当前浏览器的ip地址拿到经纬度
      getNowLocation().then((point) => {
        setCenter(point);
      });
    }
  };

  const handleCenter = (value) => {
    if (value) {
      mapRef.current.map.centerAndZoom(value?.location, 18);
      // mapRef.current.map.clearOverlays();
      // mapRef.current.map.addOverlay(
      //   new BMapGL.Marker(value.location, { title: value.name })
      // );
      let infoWindow = new BMapGL.InfoWindow(
        `<span style="padding-top:10px">${t("Search Information")}: ${
          value.street
        } <br /><span style="word-wrap:break-word;
                  word-break:break-all;">${t("The location is")}: ${
          value.address
        }</span></span>`,
        {
          width: 200, // 信息窗口宽度
          height: 120, // 信息窗口高度
          title: t("Search Information"), // 信息窗口标题
        }
      ); // 创建信息窗口对象
      mapRef.current.map.openInfoWindow(
        infoWindow,
        new BMapGL.Point(value?.location.lng, value?.location.lat)
      );
    }
  };

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        handleMapClick();
      }, 500);
    }
  }, [props.open]);

  return (
    <Grid container direction="row" spacing={1} justifyContent="space-between">
      <Grid item container xs={4} direction="row" ml={72}>
        <MapTextAutoComplete
          setCenter={handleCenter}
          code={code}
          mapRef={mapRef}
          placeholder={t("请输入位置信息搜索")}
          sx={{
            height: "41px",
            width: "100%",
            ".MuiAutocomplete-endAdornment": {
              top: "6px",
              transform: "translate(0, 0)",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Map
          ref={mapRef}
          style={{ height: 500 }}
          enableScrollWheelZoom
          center={new BMapGL.Point(center.lnt, center.lat)}
          zoom={zoom}
          tilt={40}
        >
          <NavigationControl />
        </Map>
      </Grid>
    </Grid>
  );
});

export default MapApiLoaderHOC({ ak: "MCzSCJllahIrLpm3EsFInVa16lzj2VwC" })(
  BaiduMap
);
