import React, { useState } from "react";
import OnboardLayout from "../../components/layout-components/OnboardLayout";
import { Button, Grid } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useNavigate } from "react-router-dom";
import { activateUserAccount } from "../../services/CompanyService";
import CommonUtil from "../../util/CommonUtils";
import { ReactComponent as VisibilityIcon } from "../../assets/images/icon_viewoff.svg";
import { ReactComponent as VisibilityOffIcon } from "../../assets/images/icon_viewon.svg";
import { useSnackbar } from "notistack";

export default function ActivateAccount() {
  const [visibility, setVisibility] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  var urlValue = window.location.href;
  var url = new URL(urlValue);
  var userIdentifierData = url.searchParams.get("e");
  var idData = url.searchParams.get("t");
  var companyCodeData = url.searchParams.get("c");

  const [payload, setPayload] = useState({
    id: "",
    password: "",
    confirmPassword: "",
    userIdentifier: "",
    companyCode: "",
  });

  const [error, setError] = useState({
    id: "",
    password: "",
    confirmPassword: "",
    userIdentifier: "",
    companyCode: "",
  });
  const handleSubmit = () => {
    if (validatePayload()) {
      var state = {
        ...payload,
        userIdentifier: userIdentifierData,
        id: idData,
        companyCode: companyCodeData,
      };
      activateUserAccount(state).then((response) => {
        if (response.data.code === "LVLI0012") {
          enqueueSnackbar(response.data.message, {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate("/");
        } else enqueueSnackbar(response.data.message, { variant: "error" });
      });
    }
  };

  const validatePayload = () => {
    if (CommonUtil.isEmpty(payload.password)) {
      setError({
        ...error,
        password: "Password is required",
      });
      return false;
    }

    if (payload.password !== payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: "Password is mismatched",
      });
      return false;
    }

    return true;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };
  return (
    <Grid container sx={{ display: "flex", width: "auto", height: "100vh" }}>
      <OnboardLayout
        label={"Set Password"}
      >
        <Grid sx={{display: "flex",  width: "450px", height: "93px", opacity: "1" }}>
          <CustomInput
            id="ActivateAccount-01"
            required
            label="New Password"
            size="small"
            name="password"
            type="password"
            placeholder="New Password"
            error={error.password}
            resetError={() => setError({ ...error, password: "" })}
            helperText={error.password}
            handleChange={handleChange}
            validation={"password"}
          />
        </Grid>
        <Grid sx={{ flexWrap: "wrap", justifyContent: "flex-start", alignContent: "center", pb: 2, borderRadius: '8px 0px 0px 8px' }} >
          <CustomInput
            id="ActivateAccount-02"
            required
            label="Confirm Password"
            size="small"
            name="confirmPassword"
            placeholder="Confirm Password"
            error={error.confirmPassword}
            resetError={() => setError({ ...error, confirmPassword: "" })}
            helperText={error.confirmPassword}
            handleChange={handleChange}
            type={visibility ? "password" : "text"}
            autoComplete="off"
            InputProps={{
              endAdornment: visibility ? (
                <VisibilityOffIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setVisibility(!visibility);
                  }}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setVisibility(!visibility);
                  }}
                />
              ),
            }}
          />
        </Grid>
        <Grid sx={{ flexWrap: "wrap", justifyContent: "flex-start", alignContent: "center", pt: 1, pb: 0.5, }} >
          <Button fullWidth onClick={() => handleSubmit()}
            sx={{
              background: "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
              borderRadius: "8px", opacity: "1", font: "normal normal normal 16px/28px Roboto", color: "#FFFFFF"
            }}>  Submit </Button>
        </Grid>
      </OnboardLayout >
    </Grid >
  );
}
