import React from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as AddIcon } from "../assets/images/Plus_icon.svg";
import { ReactComponent as FilterIcon } from "../assets/images/icon_filter.svg";
import { ReactComponent as RefreshIcon } from "../assets/images/icon_refresh.svg";
 import OutletFilter from "./OutletFilter";
import RightViewLayout from "./RighViewLayout";
import LocationMenu from "./LocationMenu";
export default function OutletLayout(props) {
  return (
    <RightViewLayout {...props} type="table">
      {props.OutletFilter && <OutletFilter {...props.OutletFilter}
       />}
      {props.locationMenuProps && (
        <LocationMenu {...props.locationMenuProps}>
          {props.toolbarProps && (
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"row-reverse"}
              my={2}
              mr={0}
            >
              {props.toolbarProps.add && (
                <Box item pl={2} onClick={() => props.toolbarProps.onAdd()}>
                  <AddIcon width={"35"} height={"35"} className="pointer" id="DeviceListLayout-add"/>
                </Box>
              )}
              {props.toolbarProps.filter && (
                <Box item pl={2} onClick={() => props.toolbarProps.onFilter()}>
                  <FilterIcon width={"35"} height={"35"} className="pointer" id="DeviceListLayout-filter"/>
                </Box>
              )}
              {props.toolbarProps.refresh && (
                <Box item pl={2} onClick={() => props.toolbarProps.onRefresh()}>
                  <RefreshIcon width={"35"} height={"35"} className="pointer" id="DeviceListLayout-refresh"/>
                </Box>
              )}
            </Box>
          )}
        </LocationMenu>
      )}
      {!props.locationMenuProps && props.toolbarProps && (
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row-reverse"}
          my={2}
        >
          {props.toolbarProps.add && (
            <Box item pl={2} onClick={() => props.toolbarProps.onAdd()}>
              <AddIcon width={"35"} height={"35"} className="pointer" id="DeviceListLayout-add-1"/>
            </Box>
          )}
          {props.toolbarProps.filter && (
            <Box item pl={2} onClick={() => props.toolbarProps.onFilter()}>
              <FilterIcon width={"35"} height={"35"} className="pointer" id="DeviceListLayout-filter-1"/>
            </Box>
          )}
          {props.toolbarProps.refresh && (
            <Box item pl={2} onClick={() => props.toolbarProps.onRefresh()}>
              <RefreshIcon width={"35"} height={"35"} className="pointer" id="DeviceListLayout-refresh-1"/>
            </Box>
          )}
        </Box>
      )}
      {props.children}
    </RightViewLayout>
  );
}