import React, { useState } from 'react'
import { Grid, styled } from '@mui/material'
import RenderCommon from './RenderCommon'
export default function RenderImage(props) {
  const { info } = props

  return (
    <RenderCommon {...props}>
      <Grid
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: info.borderRadius,
          backgroundColor: info.bgColor,
          textDecoration: info.textDecoration,
          fontWeight: info.fontWeight,
          letterSpacing: info.wordSpacing + 'px',
          fontStyle: info.isItaly ? 'italic' : 'normal',
          fontFamily: info.font,
          fontSize: info.fontSize,
          color: info.color,
          overflow: 'hidden',
          lineHeight: info.lineHeight + 'px',
          textAlign:
            info.textAlign === 'start'
              ? 'left'
              : info.textAlign === 'middle'
                ? 'center'
                : 'right',
        }}
      >
        {info.value}
      </Grid>

      {/* 
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0"
          y="0"
          rx={info.borderRadius}
          width="100%"
          height="100%"
          fill={info.bgColor}
        />
        {info.textAlign === "middle" && (
          <text
            x={info.width / 2}
            y={info.height / 2}
            style={{
              textDecoration: info.textDecoration,
            }}
            font-weight={info.fontWeight}
            letter-spacing={info.wordSpacing + "px"}
            font-style={info.isItaly ? "italic" : "normal"}
            font-family={info.font}
            font-size={info.fontSize}
            fill={info.color}
            text-anchor={info.textAlign}
            dominant-baseline="middle"
          >
            {info.value}
          </text>
        )}
        {info.textAlign === "start" && (
          <text
            x={4}
            y={info.height / 2}
            style={{
              textDecoration: info.textDecoration,
            }}
            font-weight={info.fontWeight}
            letter-spacing={info.wordSpacing + "px"}
            font-style={info.isItaly ? "italic" : "normal"}
            font-family={info.font}
            font-size={info.fontSize}
            fill={info.color}
            text-anchor={info.textAlign}
            dominant-baseline="middle"
          >
            {" "}
            {info.value}
          </text>
        )}
        {info.textAlign === "end" && (
          <text
            x={info.width - 4}
            y={info.height / 2}
            style={{
              textDecoration: info.textDecoration,
            }}
            font-weight={info.fontWeight}
            letter-spacing={info.wordSpacing + "px"}
            font-style={info.isItaly ? "italic" : "normal"}
            font-family={info.font}
            font-size={info.fontSize}
            fill={info.color}
            text-anchor={info.textAlign}
            dominant-baseline="middle"
          >
            {" "}
            {info.value}
          </text>
        )}
      </svg> */}
    </RenderCommon>
  )
}
