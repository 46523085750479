export default {
  tips: {
    no_options: "ไม่มีตัวเลือก",
    search_by_name: "ค้นหาตามชื่อ",
    required: "จำเป็นต้องกรอกข้อมูลในช่องนี้",
    incorrect_email_address: "ที่อยู่อีเมลไม่ถูกต้อง",

    mobild_nubmer:
      "หมายเลขโทรศัพท์มือถือพร้อมรหัสประเทศต้องอยู่ระหว่าง 8 ถึง 23 หลัก",
    alphabets: "รองรับเฉพาะตัวอักษร ตัวเลข",
    numerics_and_hyphen: "รองรับเฉพาะตัวอักษร ตัวเลข และเครื่องหมายขีดกลาง",
    numerics_and_space: "รองรับเฉพาะตัวอักษร ตัวเลข และช่องว่าง",
    country_code_required: "จำเป็นต้องมีรหัสประเทศ",
    password_title:
      "เพื่อให้รหัสผ่านปลอดภัย จะต้องประกอบด้วย: ตัวพิมพ์ใหญ่ สัญลักษณ์พิเศษ ตัวเลข ตัวอย่าง: C89$Mp@q",
    numerics_and_underscore: "รองรับเฉพาะตัวอักษร ตัวเลข และเครื่องหมายขีดล่าง",
    dot_and_space: "รองรับเฉพาะตัวอักษร ตัวเลข ช่องว่าง และจุด",
    underscore_and_space:
      "รองรับเฉพาะตัวอักษร ตัวเลข ช่องว่าง และเครื่องหมายขีดล่าง",
    selected_detele_record: "คุณแน่ใจหรือไม่ว่าต้องการลบรายการบันทึกที่เลือก",
    occurred: "เกิดข้อผิดพลาด",
    no_record_found: "ไม่พบรายการบันทึก",
    no_of_records_rer_page: "จำนวนรายการบันทึกต่อหน้า",
    select_price_period: "กรุณาเลือกช่วงเวลาการเปลี่ยนแปลงราคา",

    outlet_code: "กรุณากรอกรหัสร้านค้า",
    country_name: "กรุณากรอกชื่อประเทศ",
    state_name: "กรุณากรอกชื่อรัฐ",
    city_name: "กรุณากรอกชื่อเมือง",
    city_zone: "กรุณากรอกเขตเมือง",
    address: "กรุณากรอกที่อยู่",
    area_code: "กรุณากรอกรหัสพื้นที่",
    upload_size: "รูปภาพต้องไมเกิน 3M",
    enter_number: "กรุณากรอกหมายเลข",
    search_retail_name: "ค้นหาด้วยชื่อไคลเอนต์ค้าปลีก",
  },

  common_tips: {
    country_code: "กรุณากรอกรหัสประเทศ",
    mobile_number: "กรุณากรอกหมายเลขโทรศัพท์มือถือ",
    first_name: "กรุณากรอกชื่อจริง",
    last_name: "กรุณากรอกนามสกุล",
    address: "กรุณากรอกที่อยู่",
    country_name: "กรุณากรอกชื่อประเทศ",
    state_name: "กรุณากรอกชื่อรัฐ",
    city_name: "กรุณากรอกชื่อเมือง",
    start_date: "กรุณาเลือกวันที่เริ่มต้น",
    end_date: "กรุณาเลือกวันที่หมดอายุ",
  },

  tips_retail_client: {
    name: "กรุณากรอกชื่อผู้ค้าปลีก",
    code: "กรุณากรอกรหัสผู้ค้าปลีก",
    email: "กรุณากรอกอีเมลผู้ค้าปลีก",
    devices: "กรุณากรอกจำนวนอุปกรณ์",
    mobile_required: "จำเป็นต้องระบุหมายเลขโทรศัพท์มือถือ",

    send_activation: "คุณแน่ใจหรือไม่ว่าต้องการส่งอีเมลเปิดใช้งาน",
    activation_success: "ส่งอีเมลเปิดใช้งานเรียบร้อยแล้ว",
    created_success: "สร้างไคลเอ็นต์เรียบร้อยแล้ว",
    updated_success: "อัปเดตไคลเอ็นต์เรียบร้อยแล้ว",
    removed_success: "ลบไคลเอ็นต์เรียบร้อยแล้ว",
    user_exist: "ผู้ใช้มีอยู่แล้ว",
    given_name: "ไคลเอ็นต์มีอยู่แล้วโดยใช้ชื่อที่กำหนด",
    given_code: "ไคลเอ็นต์มีอยู่แล้วโดยใช้รหัสที่กำหนด",

    subscription_start_date_invalid:
      "วันที่ไม่ถูกต้อง โปรดเลือกวันเริ่มต้นการสมัคร",
    subscription_expire_date_invalid:
      "วันที่ไม่ถูกต้อง โปรดเลือกวันหมดอายุการสมัคร",
  },

  tips_Template: {
    name: "กรุณากรอกชื่อเทมเพลต",
    characters: "ชื่อควรมีความยาวน้อยกว่า 51 อักขระ",
    screen_resolution: "ความละเอียดหน้าจอไม่ถูกต้อง",
    success: "สร้างเทมเพลตสำเร็จแล้ว",
    updated: "อัปเดตเทมเพลตสำเร็จแล้ว",
    removed: "ลบเทมเพลตสำเร็จแล้ว",
    not_deteled: "ไม่สามารถลบเทมเพลตเริ่มต้นได้",
    sure_delete: "คุณแน่ใจหรือไม่ว่าจะลบส่วนประกอบที่เลือก",
    can_not_deteled: "ไม่สามารถลบเทมเพลตที่ซิงก์กับ ESL ได้",
    template_exist: "ชื่อเทมเพลตมีอยู่แล้ว",
  },

  tips_editor: {
    is_exists: "องค์ประกอบมีอยู่แล้ว",
    height_than: "ความสูงไม่ควรมากกว่า ",
    width_than: "ความกว้างไม่ควรมากกว่า ",
    cannot_add_shape: "ไม่สามารถเพิ่มรูปร่างเมื่อรูปภาพมีขนาดผ้าใบเท่ากัน",
    cannot_add_text: "ไม่สามารถเพิ่มป้ายข้อความเมื่อรูปภาพมีขนาดผ้าใบเท่ากัน",
    already_added: "เพิ่มแล้ว",

    cannot_add_image: "ไม่สามารถเพิ่มรูปภาพเมื่อรูปภาพมีขนาดผ้าใบเท่ากัน",
    image_equal: "รูปภาพมีความสูงและความกว้างเท่ากัน แต่ผ้าใบมีวัตถุอยู่แล้ว",
    adjust_exceeds:
      "ความกว้างหรือความสูงที่ปรับแล้วเกินความสูงของความกว้างของผ้าใบ",
    canvas_is_empty: "ผ้าใบว่างเปล่า เพิ่มป้ายหรือรูปภาพบางส่วน",
    provide_image: "กรุณาส่งรูปภาพไปยังเทมเพลต",
    text_price: "ฉันเป็นข้อความ",
    dimensions_height: "ความสูงไม่ควรเกินผ้าใบ ขนาด",
    dimensions_width: "ความกว้างไม่ควรเกินขนาดของผืนผ้าใบ",
  },

  tips_openAPI: {
    name: "กรุณากรอกชื่อ",
    company_code: "กรุณากรอกรหัสบริษัท",
    company_name: "กรุณากรอกชื่อบริษัท",
    email: "กรุณากรอกอีเมล",
    email_exist: "กรุณากรอกอีเมลที่มีอยู่แล้ว",
  },

  tips_picture: {
    id: "กรุณากรอกรหัสรูปภาพ",
    name: "กรุณากรอกชื่อรูปภาพ",
    image_resolution: "ความละเอียดของรูปภาพต้องไม่เกิน 400x300 พิกเซล",
    file_size: "ขนาดไฟล์ต้องไม่เกิน 2 MB",
    upload_type: "อัปโหลด .png, .jpeg, .jpg เท่านั้น",
    character_50: "ชื่อรูปภาพต้องไม่เกิน 50 อักขระ",
    character_100: "รหัสรูปภาพต้องไม่เกิน 100 อักขระ",
    character_name: "ชื่อต้องไม่เกิน 100 อักขระ",
    pictureId_exist: "รหัสรูปภาพมีอยู่แล้ว",
    upload_image: "กรุณาอัปโหลดรูปภาพ",
    image_size: "รูปภาพควรมีขนาดไม่เกิน 2MB",
    support_size: "รองรับ .jpeg, .jpg, .png",
    resolution_size: "ความละเอียดต้องไม่เกิน 400*300",
    success: "สร้างรูปภาพสำเร็จ",
    updated: "อัปเดตรูปภาพสำเร็จแล้ว",
    deteled: "ลบรูปภาพสำเร็จแล้ว",
  },

  tips_dashboard: {
    than_start_date: "ถึงวันที่ควรมากกว่าวันที่เริ่มต้น",
    than_from_date: "ถึงวันที่ควรมากกว่าวันที่เริ่มต้น",
    period_date: "กรุณาเลือกช่วงเวลาถึงวันที่",
    period_from_date: "กรุณาเลือกช่วงเวลาตั้งแต่วันที่",
  },

  tips_authorization: {
    ชื่อ: "กรอกชื่อระดับการอนุญาต",
    requires_name: "จำเป็นต้องระบุชื่อ",
    requires_permission: "จำเป็นต้องได้รับอนุญาต",
    role_client_user: "บทบาทที่เชื่อมโยงกับผู้ใช้ไคลเอนต์",
    removed: "ลบการอนุญาตเรียบร้อยแล้ว",
    created: "สร้างการอนุญาตเรียบร้อยแล้ว",
    updated: "อัปเดตการอนุญาตเรียบร้อยแล้ว",
  },

  tips_product: {
    cannot_deleted: "ฉลากถูกผูกไว้กับผลิตภัณฑ์และไม่สามารถลบได้!",
    last_deleted: "ไม่สามารถลบฉลากสุดท้ายได้",
    removed_product: "ลบฉลากผลิตภัณฑ์สำเร็จแล้ว",
    added_product: "เพิ่มฉลากผลิตภัณฑ์สำเร็จแล้ว",
    field_empty: "ฟิลด์นี้ว่างเปล่า",

    product_name: "กรุณากรอกชื่อผลิตภัณฑ์",
    product_sku: "กรุณากรอก SKU ของผลิตภัณฑ์",
    category_level_1: "กรุณากรอกหมวดหมู่ระดับ 1",
    category_level_2: "กรุณากรอกหมวดหมู่ระดับ 2",
    category_level_3: "กรุณากรอกหมวดหมู่ระดับ 3",
    quantity: "กรุณากรอกจำนวน",
    barcode: "กรุณากรอกบาร์โค้ด",
    product_price: "กรุณากรอกราคาผลิตภัณฑ์",
    discount_price: "กรุณากรอกราคาส่วนลด",
    brand_name: "กรุณากรอกชื่อแบรนด์",
    origin_of_product: "กรุณากรอกแหล่งกำเนิดสินค้า",
    manufacture: "กรุณากรอกผู้ผลิต",
    qr_code: "กรุณากรอก QR รหัส",

    choose_excel_file: "เลือกไฟล์ Excel เพื่ออัปโหลดผลิตภัณฑ์",
    note_excel_format: "หมายเหตุ: รองรับเฉพาะรูปแบบ Xls และ Xlsx",
    download_excel_format: "ดาวน์โหลดรูปแบบไฟล์ Excel",
    add_product_excel: "เพิ่มผลิตภัณฑ์จากไฟล์ Excel",
    upload_picture: "คลิกที่นี่เพื่ออัปโหลดรูปภาพ",
    country_name: "กรุณากรอกชื่อประเทศ",

    name_character_limit: "ชื่อควรมีความยาวไม่เกิน 51 อักขระ",
    file_size_limit: "ขนาดไฟล์ไม่เกิน 3 MB",
    upload_image_format: "(อัปโหลดไฟล์ PNG, Jpeg, Jpg เท่านั้น)",
    quantity_character_limit: "จำนวนต้องไม่เกิน 6 อักขระ",
    category_1_character_limit: "หมวดหมู่ 1 ควรมีความยาวไม่เกิน 51 อักขระ",
    category_2_character_limit: "หมวดหมู่ 2 ควรมีความยาวไม่เกิน 51 อักขระ",
    category_3_character_limit: "หมวด 3 ควรมีความยาวไม่เกิน 51 อักขระ",
    manufacture_character_limit: "ผู้ผลิตควรมีความยาวไม่เกิน 51 อักขระ",
    origin_character_limit: "ที่มาของผลิตภัณฑ์ควรมีความยาวไม่เกิน 51 อักขระ",
    name_required: "จำเป็นต้องระบุชื่อ",
    product_exists: "ผลิตภัณฑ์มีอยู่แล้วโดยมีชื่อที่กำหนดไว้",
    name_character_limit_50: "ชื่อควรมีความยาวไม่เกิน 50 อักขระ",
    name_special_characters: "ชื่อไม่ควรมีอักขระพิเศษ",
    drag_and_drop_file: "ลากและวางไฟล์ที่นี่หรือเลือกไฟล์",
    upload_file_format: "(upload.png.jpg)",

    product_deleted_success: "ลบผลิตภัณฑ์เรียบร้อยแล้ว",
    product_created_success: "สร้างผลิตภัณฑ์เรียบร้อยแล้ว",
    accept_excel_format: "ยอมรับเฉพาะรูปแบบไฟล์ Excel เท่านั้น!",
    product_updated_success: "อัปเดตผลิตภัณฑ์เรียบร้อยแล้ว",
    product_change_confirmation:
      "การเปลี่ยนแปลงผลิตภัณฑ์จะเปลี่ยนแปลงป้ายราคาที่เกี่ยวข้องและเหตุการณ์การเปลี่ยนแปลงพร้อมกัน<br>คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนแปลงผลิตภัณฑ์",
    product_delete_confirmation:
      "การลบผลิตภัณฑ์แบบซิงโครนัสจะลบเหตุการณ์การเปลี่ยนแปลงราคาที่เกี่ยวข้องและบันทึกแบบซิงโครนัส",
    sure_change_product:
      "การเปลี่ยนแปลงผลิตภัณฑ์จะเปลี่ยนแปลงป้ายราคาที่เกี่ยวข้องและเหตุการณ์การเปลี่ยนแปลงพร้อมกัน<br>คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนแปลงผลิตภัณฑ์",
  },

  tips_outlet: {
    outlet_name: "กรุณากรอกชื่อร้าน",
    outlet_given_name: "ร้านมีอยู่แล้วโดยใช้ชื่อที่ตั้งไว้",
    outlet_given_code: "ร้านมีอยู่แล้วโดยใช้รหัสที่ตั้งไว้",
    name_length: "ชื่อควรมีความยาวไม่เกิน 50 อักขระ",
    code_length: "รหัสควรมีความยาวไม่เกิน 30 อักขระ",
    code_alphanumeric: "รหัสประกอบด้วยค่าตัวอักษรและตัวเลขเท่านั้น",
    code_exceed: "รหัสต้องไม่เกิน 30 อักขระ",
    outlet_delete_restricted: "ไม่สามารถลบร้านที่แมปกับอุปกรณ์ได้",
    outlet_deleted: "ลบร้านเรียบร้อยแล้ว",
    outlet_created: "สร้างร้านเรียบร้อยแล้ว",
    outlet_updated: "อัปเดตร้านเรียบร้อยแล้ว",
    select_outlet: "กรุณาเลือกร้าน",
  },

  tips_user: {
    outlet_in_charge: "รับผิดชอบ Outlet",
    permission_group_name: "ชื่อกลุ่มสิทธิ์",
    date_of_joining: "วันที่เข้าร่วม",
    first_name: "กรุณากรอกชื่อ",
    last_name: "กรุณากรอกนามสกุล",
    person_id: "กรุณากรอกรหัสบุคคล",
    enter_email: "กรุณากรอกอีเมล",
    select_date: "DD-MM-YYYY (กรุณาเลือกวันที่)",
    first_name_limit: "ชื่อต้องไม่เกิน 100 อักขระ",
    allowed_file_types: "อนุญาตเฉพาะ *JPEG, *JPG, *PNG",
    max_file_size: "ขนาดสูงสุด 3 MB",
    user_exists: "ผู้ใช้มีอยู่แล้ว",
    phone_required: "จำเป็นต้องมีโทรศัพท์",
    outlet_required: "จำเป็นต้องมีร้านค้า",
    role_required: "จำเป็นต้องมีบทบาท",
    activation_mail_confirmation:
      "คุณแน่ใจหรือไม่ว่าต้องการส่งอีเมล์การเปิดใช้งาน",
    activation_mail_sent: "ส่งอีเมล์การเปิดใช้งานเรียบร้อยแล้ว",
    cannot_delete_default_user: "ไม่สามารถลบผู้ใช้เริ่มต้นได้",
    person_deleted: "ลบบุคคลเรียบร้อยแล้ว",
    person_created: "สร้างบุคคลเรียบร้อยแล้ว",
    person_updated: "อัปเดตบุคคลเรียบร้อยแล้ว",
  },

  tips_gateway: {
    deviceNetworkConfigSteps: "ขั้นตอนการกำหนดค่าเครือข่ายอุปกรณ์",
    step1PowerUp: "ขั้นตอนที่ 1: เปิดเครื่องและเปิดเครื่อง",
    step1Description:
      "หากอุปกรณ์มีเครือข่าย อุปกรณ์จะเชื่อมต่อกับเครือข่ายโดยอัตโนมัติและเริ่มทำงาน",
    step2ConfigureNetwork: "ขั้นตอนที่ 2: กำหนดค่าเครือข่าย",
    step2Description:
      "คุณอาจใช้บลูทูธเพื่อตั้งค่าเครือข่าย หรืออุปกรณ์บางเครื่องมีหน้าจอสัมผัสที่มีการตั้งค่าเครือข่ายในเฟิร์มแวร์",
    step3RestoreFactorySettings: "ขั้นตอนที่ 3: คืนค่าการตั้งค่าจากโรงงาน",
    step3Description:
      "อุปกรณ์บางเครื่องไม่สามารถตั้งค่าเครือข่ายได้ คุณอาจลองรีเซ็ตการตั้งค่าจากโรงงานของอุปกรณ์",
    readInstructions: "ฉันได้อ่านคำแนะนำเหล่านี้แล้ว",

    manualRegisterDevice: "ลงทะเบียนอุปกรณ์ด้วยตนเอง",
    powerUpAndSetNetwork: "เปิดเครื่องและตั้งค่าเครือข่ายอุปกรณ์",
    step1EthernetConnection:
      "1. เสียบสายเครือข่ายหากอุปกรณ์รองรับอีเทอร์เน็ต ฟังก์ชัน",
    step2การตั้งค่าการสื่อสาร:
      "2. เข้าสู่เมนูการตั้งค่าอีเทอร์เน็ต/การตั้งค่า WiFi ของอุปกรณ์ของคุณ เพื่อเข้าสู่หน้าการตั้งค่าการสื่อสาร การตั้งค่าเครือข่ายสำเร็จ อุปกรณ์จะแสดงรหัส QR ในหน้าสแตนด์บาย",
    step3ค้นหาหมายเลขซีเรียล:
      "3. ที่ด้านข้างกล่องอุปกรณ์หรือด้านหลังอุปกรณ์ สามารถค้นหาหมายเลขซีเรียลของอุปกรณ์ได้",
    step4กรอกหมายเลขซีเรียล: "4. กรอกหมายเลขซีเรียลอุปกรณ์ในระบบ",

    specDeviceForClient: "กรุณาระบุอุปกรณ์ให้กับไคลเอนต์และร้านค้า",
    syncOutletTimeZone: "อุปกรณ์นี้จะซิงค์โซนเวลาเดียวกันกับร้านค้า",

    selectFile: "กรุณาเลือกไฟล์",
    versionStartWithV: "ต้องขึ้นต้นด้วยตัวอักษร V",
    enterUpgradePackageVersion:
      "กรุณากรอกเวอร์ชันแพ็คเกจอัปเกรด โดยขึ้นต้นด้วยตัวอักษร 'V'",
    uploadSuccessful: "อัปโหลดสำเร็จ",
    uploadAndUpgrade: "อัปโหลดแพ็คเกจอัปเกรดและอัปเกรด",
    uploadFile: "อัปโหลดไฟล์",
    unsupportedType: "ไม่รองรับประเภทปัจจุบัน",
    fileSizeLimit:
      "ขนาดไฟล์เกินขีดจำกัด: {{fileSize}}MB กรุณาอัปโหลดไฟล์ที่มีขนาดเล็กกว่าหรือเท่ากับ 100MB",
    dragOrBrowse: "ลากหรือเรียกดู",
    onlyApkFormat: "อนุญาตให้อัปโหลดไฟล์ในรูปแบบ apk เท่านั้น",

    searchByDeviceSn: "ค้นหาตามอุปกรณ์ Sn",
    selectYourZone: "กรุณาเลือกโซนของคุณ",
    bindZone: "ผูกโซน",
    enterDeviceSerialNumber: "กรุณากรอกหมายเลขซีเรียลของอุปกรณ์",
    readInstructionsToAddDevice:
      "กรุณาอ่านคำแนะนำและทำเครื่องหมายในช่องเพื่อเพิ่มอุปกรณ์",

    deviceUnbindSuccess: "ยกเลิกการเชื่อมต่ออุปกรณ์สำเร็จ",
    deviceBindSuccess: "ผูกอุปกรณ์สำเร็จ",
  },

  tips_screens: {
    choose_Product_display: "เลือกผลิตภัณฑ์ที่ต้องการให้หน้าจอนี้แสดง",
    choose_Template_display: "เลือกเทมเพลตที่ต้องการให้หน้าจอนี้แสดง",
    selected_screen: "หน้าจอที่เลือก",
    searchby_screenId: "ค้นหาตามรหัสหน้าจอ",
    enter_screen_name: "กรุณากรอกชื่อหน้าจอ",
    enter_position_no: "กรุณากรอกหมายเลขตำแหน่ง",
    enter_gateway_sn: "กรุณากรอกรหัสเกตเวย์",
    template_not_empty: "เทมเพลตไม่ควรว่างเปล่า",
    product_not_empty: "ผลิตภัณฑ์ไม่ควรว่างเปล่า",
    company_id_required: "จำเป็นต้องมี CompayId",
    select_one_screen: "กรุณาเลือกหน้าจอ",
    confirm_unbind_device:
      "คุณแน่ใจหรือไม่ว่าจะยกเลิกการเชื่อมโยงอุปกรณ์ที่เลือก",
    deviceUn_bind_success: "ยกเลิกการเชื่อมโยงอุปกรณ์สำเร็จ",
    screens_sync_success: "ซิงค์หน้าจอสำเร็จ",
    select_promotion_StartTime: "กรุณาเลือกเวลาเริ่มต้นของโปรโมชัน",
    select_or_screen: "กรุณาเลือกหนึ่งหน้าจอขึ้นไป",
  },

  tips_events: {
    deleted: "ลบการเปลี่ยนแปลงราคาสำเร็จแล้ว",
    created: "สร้างการเปลี่ยนแปลงราคาสำเร็จแล้ว",
    updated: "อัปเดตการเปลี่ยนแปลงราคาสำเร็จแล้ว",
  },

  tips_approval: {
    change_status: "แน่ใจว่าจะเปลี่ยนสถานะหรือไม่",
    updated: "บันทึกอัปเดตสำเร็จ",
    not_empty: "กรุณาเลือกค่าที่ไม่ซ้ำกันและไม่ควรว่างเปล่า",
    deteled: "ลบแผนภูมิการอนุมัติเรียบร้อยแล้ว",
    added: "เพิ่มแผนภูมิการอนุมัติเรียบร้อยแล้ว",
    information: "ผู้อนุมัติคนใดคนหนึ่งอนุมัติ ระบบจะซิงค์ข้อมูลไปยังหน้าจอ",
  },
};
