import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PICTURE_LIBRARY } from "../../router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Card,
  Typography,
  CardContent,
} from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPictureById,
  updatePicture,
  getDownloadUrl,
} from "../../services/PictureService";
import ProfilePic from "../../assets/images/ProfilePic.svg";
import axios from "axios";
import CommonUtil from "../../util/CommonUtils";
import { useSnackbar } from "notistack";
import DropzoneComponent from "../../components/layout-components/DropzoneComponent";
import { ReactComponent as Upload_Image } from "../../assets/images/Upload_Image.svg";

export default function EditPicture() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const [profile, setProfile] = useState(ProfilePic);
  const { enqueueSnackbar } = useSnackbar();
  const [processor, setProcessor] = React.useState("");
  const params = useParams();
  const [pixel, setPixel] = React.useState("");

  const [payload, setPayload] = useState({
    pictureId: "",
    pictureName: "",
    picturePreProcessing: "",
    profilePhoto: "",
    fileExtension: "",
    size: "",
    objectKey: "",
    pictureType: "",
    imagePixel: "",
  });

  const [error, setError] = useState({
    pictureId: "",
    pictureName: "",
    picturePreProcessing: "",
    profilePhoto: "",
    fileExtension: "",
    size: "",
    pictureType: "",
    imagePixel: "",
  });

  useEffect(() => {
    getPictureById(params.id).then((response) => {
      if (response?.data?.code === "LVLI0000" && response?.data?.data) {
        setPayload({
          ...payload,
          ...response.data.data,
        });
        setObjectKey(response?.data?.data?.objectKey);
        localStorage.setItem("pictureType", response?.data?.data?.pictureType);
      }
    });
  }, []);

  const [objectKey, setObjectKey] = useState();

  useEffect(() => {
    getDownloadUrl(payload.objectKey).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setProfile(res.data.data.downloadUrl);
        setImage(res.data.data.downloadUrl);
      }
    });
  }, [objectKey]);

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append("url", data?.data?.data?.profilePhotoURL);
    bodyFormData.append("file", profileImage);

    axios({
      method: "post",
      url:
        process.env.REACT_APP_SERVER_URL +
        "/web/picture_library/upload_picture",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
      },
    }).then(() => {});
  };

  const [image, setImage] = useState();
  const [file, setFile] = useState({});
  const [state, setState] = useState({
    profilePhoto: "",
  });
  const [isImage, setIsImage] = useState(false);
  const [productImage, setProductImage] = useState();

  const handleImage = (file) => {
    let maxSize = 2 * 1024 * 1024; // 2MB in bytes
    let maxResolution = { width: 400, height: 300 }; // Maximum allowed resolution

    if (
      file.file.type === "image/png" ||
      file.file.type === "image/jpeg" ||
      file.file.type === "image/bmp"
    ) {
      let fileSize = file.file.size;
      let size = parseInt(fileSize);
      if (size <= maxSize) {
        const img = new Image();
        img.onload = () => {
          // Check if the image dimensions exceed the maximum allowed resolution
          if (
            img.width <= maxResolution.width &&
            img.height <= maxResolution.height
          ) {
            setFile(file);
            setState({
              ...state,
              profilePhoto: file.base64.split(",")[1],
            });
            setImage(file?.base64);
            setProductImage(file?.file);

            setPayload((prevPayload) => ({
              ...prevPayload,
              productPhoto: file.file.name,
              profilePhoto: payload.pictureName,
              size: file.file.size + "KB",
            }));
            setProfile(file.base64);
            setProfileImage(file.file);
            setIsImage(true);

            // Clear the input value here
            const input = document.querySelector('input[type="file"]');
            if (input) input.value = null;
          } else {
            // Image dimensions exceed the maximum allowed resolution
            setFile("");
            enqueueSnackbar(t("tips_picture.image_resolution"), {
              variant: "error",
            });
          }
        };
        img.src = file.base64;
      } else {
        setFile("");
        enqueueSnackbar(t("tips_picture.file_size"), {
          variant: "error",
        });
      }
    } else {
      setFile("");
      //setProfile(state.profilePhoto);
      enqueueSnackbar(t("tips_product.upload_image_format"), {
        variant: "error",
      });
    }
  };
  // tips_product.upload_image_format
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updatePicture({ ...payload, imagePixel: pixel }).then((res) => {
        if (res?.data?.data && res?.data?.code === "LVLI0003") {
          enqueueSnackbar(t("tips_picture.updated"), { variant: "success" });

          if (
            !CommonUtil.isEmpty(res.data.data.profilePhotoURL) &&
            profileImage
          ) {
            uploadProfilePhoto(res);
          }
        }
        navigate(REACT_PICTURE_LIBRARY);
      });
    }
  };

  const validateForm = () => {
    if (payload.pictureName.length > 50) {
      setError({
        ...error,
        pictureName: `${t("tips_picture.character_50")}`,
      });
      return;
    }

    if (payload.pictureId.length > 100) {
      setError({
        ...error,
        pictureId: `${t("tips_picture.character_100")}`,
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.pictureName.trim())) {
      setError({
        ...error,
        pictureName: `${t("tips.required")}`,
      });
      return;
    }

    if (
      !CommonUtil.isEmpty(payload.pictureName) &&
      toString(payload.firstName).length > 50
    ) {
      setError({
        ...error,
        pictureName: `${t("tips_picture.character_name")}`,
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.pictureId)) {
      setError({
        ...error,
        pictureId: `${t("tips.required")}`,
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.picturePreProcessing)) {
      setError({
        ...error,
        picturePreProcessing: `${t("tips.required")}`,
      });
      return;
    }

    if (CommonUtil.isEmpty(payload.picturePreProcessing.trim())) {
      setError({
        ...error,
        picturePreProcessing: `${t("tips.required")}`,
      });
      return;
    }
    return true;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      pictureId: `${t("")}`,
      pictureName: `${t("")}`,
      picturePreProcessing: `${t("")}`,
    });
  };

  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const options = ["Original Image", "Dithering Image"];

  return (
    <RightViewLayout
      navigateBack={REACT_PICTURE_LIBRARY}
      title={t("picture_library.edit_picture")}
    >
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0} sx={{ height: "100%" }}>
          <CardContent>
            <Grid container>
              <Grid
                item
                xs={12}
                md={4}
                container
                display={"flex"}
                justifyContent={"center"}
                pr={2}
              >
                <Box
                  letiant="standard"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={{ paddingLeft: "0px", color: "#474B4F" }}
                  >
                    {t("product.product_picture")}
                  </InputLabel>
                </Box>
                <Grid
                  container
                  style={{
                    border: "2px dashed #36C96D",
                    borderRadius: "5px",
                    backgroundColor: "rgba(54, 201, 109,0.1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "220px",
                    width: "100%",
                  }}
                >
                  <DropzoneComponent
                    getExcelFile={(excelData) => handleImage(excelData)}
                  >
                    {image ? (
                      <img
                        src={image}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Upload_Image />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            textAlign: "center",
                            opacity: "0.8",
                            mt: 1,
                          }}
                        >
                          Image should be below 2MB
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            textAlign: "center",
                            opacity: "0.8",
                          }}
                        >
                          Supports .jpeg, .jpg, .png
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            textAlign: "center",
                            opacity: "0.8",
                          }}
                        >
                          Resolution should be below 400*300
                        </Typography>
                      </div>
                    )}
                    {/* </Box> */}
                  </DropzoneComponent>
                </Grid>
              </Grid>
              <Grid container xs={12} md={8} spacing={1}>
                <Grid item md={6} xs={12}>
                  <CustomInput
                    id="EditPicture1"
                    label={t("picture_library.id")}
                    required
                    size="small"
                    name="pictureId"
                    handleChange={handleChange}
                    value={payload.pictureId}
                    validation="alpha-numeric"
                    error={error.pictureId}
                    resetError={() => setError({ ...error, pictureId: "" })}
                    helperText={error.pictureId}
                    placeholder={t("tips_picture.id")}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomInput
                    id="EditPicture2"
                    required
                    label={t("picture_library.name")}
                    placeholder={t("tips_picture.name")}
                    size="small"
                    name="pictureName"
                    handleChange={handleChange}
                    value={payload.pictureName}
                    validation="alpha-numeric-ch-th"
                    error={error.pictureName}
                    resetError={() => setError({ ...error, pictureName: "" })}
                    helperText={error.pictureName}
                    inputProps={{
                      minLength: 5,
                      maxLength: 20,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={{ paddingLeft: "0px" }}
                  >
                    {t("picture_library.progressing")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Autocomplete
                    noOptionsText={t("tips.no_options")}
                    options={options}
                    value={payload.picturePreProcessing}
                    getOptionLabel={(option) => option}
                    onChange={(e, v) => {
                      setPayload({
                        ...payload,
                        picturePreProcessing: v,
                      });
                      setError({
                        ...error,
                        picturePreProcessing: `${t("")}`,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="picturePreProcessing"
                        size="small"
                        error={error?.picturePreProcessing}
                        helperText={error?.picturePreProcessing}
                        sx={{
                          "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall":
                            {
                              fontSize: "13px",
                              padding: "12px",
                            },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "40px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      onClick={() => navigate(REACT_PICTURE_LIBRARY)}
                      sx={{ marginRight: "10px" }}
                    >
                      {" "}
                      {t("common.cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        background: "linear-gradient(45deg, #1487CA, #78BC27)",
                      }}
                      className="text-transform-none"
                      onClick={handleSubmit}
                    >
                      {" "}
                      {t("common.save")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
