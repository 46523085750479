import { id } from "date-fns/locale";
import api from "../configurations/http-common";

export const createPriceChangeRules = async (payload) => {
  return api.securedAxios().post("web/price_change_rules", payload);
};

export const priceChangeRulesList = async (payload) => {
  let url = "web/price_change_rules/list";
  if (payload.pageNumber) {
    url = url + "?pageNumber=" + payload.pageNumber;
  }
  if (payload.pageSize) {
    url = url + "&pageSize=" + payload.pageSize;
  }
  return api.securedAxios().get(url);
};

export const getPriceChangeRule = async (id) => {
  return api.securedAxios().get("web/price_change_rules/" + id);
};

export const editPriceChangeRule = async (payload) => {
  return api.securedAxios().put("/web/price_change_rules/update", payload);
};
export const priceChangeRuleDelete = async (id) => {
  return api.securedAxios().delete("/web/price_change_rules/" + id);
}




