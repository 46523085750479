import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Tooltip,
  Grid,
  Radio,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  TextField,
  DialogContentText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "@/components/DataTable";
import { useTranslation } from "react-i18next";
import CommonUtil from "@/util/CommonUtils";
import { useSnackbar } from "notistack";
import { filterOutlet, getOutletData } from "@/services/OutletService";
import ClearIcon from "@mui/icons-material/Clear";
const SelectStoreDialog = forwardRef((props, ref) => {
  const { open, setOpen, setOutletID, setSelectStore } = props;

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  //模糊搜索的值
  const [inputValue, setInputValue] = useState("");

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };
  const defaultFilters = {
    page: 0,
    rowsPerPage: 5,
    name: "",
  };

  const [filters, setFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    name: inputValue,
  });

  /**
   * @method getloadData
   * 加载表格数据
   */

  // 使用 useImperativeHandle 将方法暴露给父组件
  useImperativeHandle(ref, () => ({
    getloadData: getloadData,
  }));

  const getloadData = () => {
    // setIsLoading(true);
    try {
      filterOutlet(filters.page + 1, filters.rowsPerPage, filters).then(
        (response) => {
          if (response?.data?.code === "LMSI6000") {
            const list = response?.data?.data?.objects || [];

            list.unshift({ name: t("dashboard.all_stores"), id: "All" });

            setRecords(list);
            setTotalRecords(response?.data?.data?.totalCount);
            setIsLoading(false);
          } else if (response?.data?.code === "LVLE0054") {
            setRecords([]);
            setTotalRecords(0);
            setIsLoading(false);
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!open) {
      return;
    }
    getloadData();
  }, [filters, open]);

  /**
   * @method handleChange
   * 根据id 保存选中行id
   */
  const [selectedValue, setSelectedValue] = React.useState("");
  const handleChange = (event, rowId) => {
    setSelectedValue(rowId);
    // setSelectStore(rowId);
  };

  /**
   * @method renderRadioColumn
   * 单选框 渲染方法
   */
  const renderRadioColumn = (params) => {
    const rowId = getRowId(params.row);

    return (
      <Radio
        checked={selectedValue === rowId}
        onChange={(event) => handleChange(event, rowId)}
        value={rowId}
        name="radio-buttons"
        inputProps={{ "aria-label": rowId }}
      />
    );
  };

  const getRowId = (data) => data.id;
  const columns = [
    {
      field: "radio",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: renderRadioColumn,
    },
    {
      field: "name",
      headerName: t("outlet.name"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t("outlet.code"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.code} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.code)}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: t("table.address"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.addressLine1} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.addressLine1)}</span>
        </Tooltip>
      ),
    },
    {
      field: "cityZone",
      headerName: t("table.city_zone"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.cityZone} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.cityZone)}</span>
        </Tooltip>
      ),
    },
    {
      field: "timeZone",
      headerName: t("table.time_zone"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip
          title={CommonUtil.getTimeZoneNameByValue(e.row.timeZone)}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              CommonUtil.getTimeZoneNameByValue(e.row.timeZone)
            )}
          </span>
        </Tooltip>
      ),
    },
  ];
  /**
   * @method handlePageChange
   * 切换当前页
   */
  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      page: e + 1,
    });
  };

  /**
   * @method handlePageSize
   * 切换每页数量
   */
  const handlePageSize = (e) => {
    setFilters({
      page: defaultFilters.page,
      rowsPerPage: e,
    });
  };

  /**
   * @method 关闭弹窗
   */
  const handlerCancel = () => {
    setOpen(false);
    setInputValue("");
  };
  // PCS119
  const handleSearch = () => {
    setIsLoading(true);
    if (inputValue === "") {
      enqueueSnackbar(t("PCS119"), {
        variant: "info",
      });
      getloadData();
      return;
    }
    try {
      setIsLoading(true);
      let params = {
        pageSize: filters.rowsPerPage,
        pageNumber: 1,
        name: inputValue,
      };
      getOutletData(params).then((response) => {
        if (response?.data?.code === "LMSI6000") {
          setRecords(response?.data?.data?.objects);
          setTotalRecords(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else {
          setRecords([]);
          setTotalRecords(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  /**
   * @method handleAgreeButtonClick
   * 点击确定插入数据
   */
  const handleAgreeButtonClick = () => {
    if (selectedValue) {
      let filterResult = records.find((item) => item?.id === selectedValue);
      setOutletID(filterResult?.id);
      setSelectStore(filterResult?.name);
    }

    setOpen(false);
    setInputValue("");
  };

  const handlerClear = () => {
    setInputValue("");
    setFilters({
      ...defaultFilters,
      name: "",
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(); // 调用搜索函数
    }
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent
        sx={{
          width: "1000px",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid>
              <TextField
                size="small"
                label={t("Please enter outlet Name")}
                variant="outlined"
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        id="clearButton"
                        onClick={handlerClear}
                        style={{
                          visibility: inputValue ? "visible" : "hidden",
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "10px",
              height: "410px",
            }}
          >
            <DataTable
              height="auto"
              rows={records || []}
              columns={columns}
              rowCount={totalRecords}
              getRowId={getRowId}
              pageSizeOptions={[5, 10, 20, 30, 50]}
              page={filters.pageNumber - 1}
              disableColumnMenu
              totalRecords={totalRecords}
              rowsPerPage={filters.pageSize}
              onSelection={() => console.log("666")}
              onPageChange={(pn) => handlePageChange(pn)}
              onPageSizeChange={(ps) => handlePageSize(ps)}
              checkboxSelection={false}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerCancel}>{t("common.cancel")}</Button>
        <Button onClick={handleAgreeButtonClick} autoFocus variant="contained">
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SelectStoreDialog;
