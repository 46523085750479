import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
function Accourdion(props) {
  const {
    label,
    children,
    onChange,
    expanded,
    handlerConfirm,
    handlerCancel,
    expandedIndex,
    disabled,
  } = props;
  const { t } = useTranslation();
  return (
    <Accordion elevation={0} expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
        sx={{ fontFamily: "Roboto" }}
      >
        {label}
      </AccordionSummary>

      <AccordionDetails>
        <Grid pl={8} mr={4} pb={2} pr={6}>
          {children}
        </Grid>
      </AccordionDetails>

      <Grid container spacing={2} pb={2} pr={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="AddAuthorizationLevel-button-01"
                variant="contained"
                size="large"
                disabled={disabled}
                type={expandedIndex === 5 ? "submit" : undefined}
                className="text-transform-none"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                }}
                onClick={handlerConfirm}
              >
                {expandedIndex === 5 ? t("common.save") : t("common.next")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="AddAuthorizationLevel-button-01"
                variant="none"
                size="large"
                className="text-transform-none"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  color: "#1487CA",
                }}
                onClick={handlerCancel}
              >
                {t("common.previous")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Accordion>
  );
}

export default Accourdion;
