import { useEffect, useState } from "react";
import { Box, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { allMonth } from "../js/enum";

import { handlerMonth, handlerAllCheckMonth } from "../js/way";
function Monthly(props) {
  const { addFormik } = props;
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [isCheckedMonth, setIsCheckedMonth] = useState(false);

  useEffect(() => {
    setSelectedMonth(addFormik.values.months);
  }, []);

  useEffect(() => {
    // 判断是否全选中
    const isAllSelected = allMonth.every((date) =>
      selectedMonth?.includes(date.value)
    );

    setIsCheckedMonth(isAllSelected);
  }, [selectedMonth]);

  return (
    <Box m={1} width={"60%"} ml={2.5}>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckedMonth}
              onChange={(e) =>
                handlerAllCheckMonth(
                  e,
                  setSelectedMonth,
                  setIsCheckedMonth,
                  allMonth,
                  addFormik
                )
              }
            />
          }
          required
          label="Valid Month"
          sx={{
            textAlign: "left",
            font: "normal normal medium 18px/24px Roboto",
            fontWeight: "bold",
            letterSpacing: "10px",
            color: "red",
          }}
        />
      </Box>

      <Box ml={4} display={"flex"}>
        {allMonth?.map((item, index) => {
          return (
            <Grid container spacing={1} md={10}>
              <Grid item xs={2} pr={1}>
                <FormControlLabel
                  key={item.id}
                  checked={selectedMonth?.includes(item.value)}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handlerMonth(
                          item.value,
                          selectedMonth,
                          setSelectedMonth,
                          allMonth,
                          addFormik,
                          setIsCheckedMonth
                        );
                      }}
                    />
                  }
                  label={item?.value.substring(0, 3)}
                  sx={{
                    textAlign: "left",
                    font: "normal normal medium 18px/24px Roboto",
                    fontWeight: "bold",
                    letterSpacing: "10px",
                    color: "#474B4F",
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
}

export default Monthly;
