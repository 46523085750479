import React, { useState, useEffect } from "react";
import { Grid, styled } from "@mui/material";
import RenderCommon from "./RenderCommon";
import QRCode from "qrcode";
export default function RenderImage(props) {
  const { info } = props;
  const [url, setUrl] = useState("");
  useEffect(() => {
    createQrcode();
  }, [info]);

  const createQrcode = () => {
    var options = {
      width: info.width,
      height: info.height,
      margin: 0,
      color: {
        dark: info.bgColor === "transparent" ? "#000000" : info.bgColor,
        light: info.fgColor === "transparent" ? "#000000" : info.fgColor,
      },
    };
    QRCode.toDataURL(info.text || "  ", options, (err, url) => {
      if (err) {
        return false;
      }
      setUrl(url);
    });
  };

  return (
    <RenderCommon {...props}>
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: info.bgColor,
          overflow: "hidden",
          opacity: info.transparency,
          borderRadius: info.borderRadius + "px",
        }}
      >
        {url && (
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            alt=""
            src={url}
          ></img>
        )}
      </Grid>
    </RenderCommon>
  );
}
