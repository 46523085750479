import api from "../configurations/http-common";

export const getAllCountries = async () => {
  return api.securedAxios().get("web/countries");
};

export const getAllCities = async () => {
  return api.securedAxios().get("web/cities");
};

export const getAllStates = async () => {
  return api.securedAxios().get("web/states");
};

/**
 *
 * @returns 一下是修改过后的接口
 */

export const getCountries = async () => {
  return api.securedAxios().get(`/api/v2/countries`);
};

export const getStaties = async (countryId) => {
  return api.securedAxios().get("/api/v2/states", {
    params: countryId,
  });
};

export const getCities = async (stateId) => {
  return api.securedAxios().get("/api/v2/cities", {
    params: stateId,
  });
};
