import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
import { ReactComponent as NoResultFoundPIC } from '../assets/images/no_result_found.svg';

export const NoResultFound = (props) => {
  return (
    <>
      <Paper elevation={0}
        style={{
          minHeight: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          paddingTop: '16px',
          paddingBottom: '16px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box display={'flex'} justifyContent='center' sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          <NoResultFoundPIC height={50}/>
        </Box>
        <Typography textAlign={'center'} sx={{fontSize: 15}} color={'#A2A4A6'} pt={.5}>
          {props.message ? props.message : 'No Result Found'}
        </Typography>
      </Paper>
    </>
  )
}
