export default {
  common: {
    actions: "Actions",
    view: "View",
    edit: "Edit",
    delete: "Delete",
    manage: "Manage",
    preview: "Preview",
    clear: "Clear",
    confirm: "Confirm",
    submit: "Submit",
    next: "Next",
    back: "Back",
    save: "Save",
    reset: "Reset",
    cancel: "Cancel",
    find: "Find",
    refresh: "Refresh",
    resend_email: "ResendEmail",
    upload: "Upload",
    add: "Add",
    prompt: "Prompt",
    ok: "Ok",
    continue: "Continue",
    update: "Update",
    apply: "Apply",
    previous: "Previous",
    type: "Type",
    success: "Success",
    fail: "Fail",
    no_data: "No Data",
    start_time: "Start Time",
    end_time: "End Time",
    per_page: "No of Records Per Page",
    yes: "Yes",
    start_date: "Start Date",
    end_date: "End Date",
  },
};
