import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import IconHandaler from "../../components/IconHandaler";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import {
  deleteProduct,
  downloadImage,
  getAllProducts,
  prodcutList,
} from "../../services/ProductService";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/images/Plus_icon.svg";
import { useNavigate } from "react-router-dom";
import {
  REACT_PRODUCT,
  REACT_PRODUCT_ADD,
  REACT_PRODUCT_EDIT,
  REACT_PRODUCT_VIEW,
} from "../../router/ReactEndPoints";
import { useSnackbar } from "notistack";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SimpleDailogBox from "../../components/SimpleDailogBox";
import { ReactComponent as RefreshIcon } from "../../assets/images/icon_refresh.svg";
import axios from "axios";
import RightViewLayout from "../../components/RighViewLayout";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import ClearIcon from "@mui/icons-material/Clear";
export default function Product() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [name, setName] = useState("");
  const [colums, setColums] = useState([]);
  const permission = JSON.parse(localStorage.getItem("permission"));
  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  let isAllowedUpload = true;
  let isAlloweddownloadExcel = true;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
    name: "",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
    name: "",
  };

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
    });
  };

  const loadData = () => {
    prodcutList(filters).then((res) => {
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.products
      ) {
        setColums(res.data.data?.columns);
        setRecords(res?.data?.data?.products);
        setTotalRecords(res?.data?.data?.totalCount);
      }
    });
  };

  const refreshLoadData = () => {
    setFilters({ ...defaultFilters });
    setSearchValue("");
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.some((x) => x === "AddProduct");
    isAllowedView = permission && permission.includes("ViewProduct");
    isAllowedEdit = permission && permission.includes("EditProduct");
    isAllowedDelete = permission && permission.includes("DeleteProduct");
    isAllowedUpload = permission && permission.includes("UploadProduct");
    isAlloweddownloadExcel =
      permission && permission.includes("DownloadProduct");
  }

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const response = colums?.map((item) => {
      return {
        headerName: item?.label,
        field: item?.name,
        flex: 1,
        headerAlign: "center",
        align: "center",
      };
    });

    let data = response.slice(0, 5);
    data.push({
      headerName: `${t("common.actions")}`,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedView && (
            <Tooltip id="Product-button-12" title={t("common.view")} arrow>
              <VisibilityIcon
                onClick={() => handleActions("View", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {isAllowedEdit && (
            <Tooltip title={t("common.edit")} sx={{ marginLeft: 1 }}>
              <EditIcon
                onClick={() => handleActions("Edit", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {isAllowedDelete && (
            <Tooltip
              id="Product-button-13"
              title={t("common.delete")}
              sx={{ marginLeft: 1 }}
            >
              <DeleteIcon
                onClick={() => handleActions("Delete", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
        </IconHandaler>
      ),
    });

    setColumns(data);
  }, [colums]);

  const handleActions = (action, data) => {
    if (action === "View") {
      const id = data;
      navigate(REACT_PRODUCT_VIEW, { state: { id } });
    }
    if (action === "Edit") {
      const id = data;
      navigate(REACT_PRODUCT_EDIT, { state: { id } });
    }
    if (action === "Delete") {
      // handleDelete(data);
      setId(data);
      setConfirm(true);
    }
  };

  const handleDelete = (id) => {
    deleteProduct(id).then((res) => {
      if (res?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("tips_product.product_deleted_success"), {
          variant: "success",
        });
        setConfirm(false);
        loadData();
        navigate(REACT_PRODUCT);
      }
      if (res?.data?.code !== "LVLI0002") {
        enqueueSnackbar(res.data.message, { variant: "error" });
        setConfirm(false);
      }
    });
  };

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const [excelFile, setExcelFile] = useState(null);
  const [filePayload, setFilePayload] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  const popUp = () => {
    setOpen(true);
  };

  const handleFileUpload = () => {
    if (!excelFile) {
      enqueueSnackbar("Please Choose A File!", { variant: "error" });
      return;
    }
    var bodyFormData = new FormData();
    bodyFormData.append("file", excelFile);
    setUploadProgress(0);
    axios({
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "/web/product/excel",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
        selectedClient: localStorage.getItem("RETAIL_CLIENT"),
        "accept-language": localStorage.getItem("RC_LANG_KEY"),
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    })
      .then((res) => {
        if (res?.data?.code === "LVLI0008") {
          enqueueSnackbar(t("tips_product.product_created_success"), {
            variant: "success",
          });
          setOpen(false);
          setFilePayload(null);
          loadData();
        } else {
          setUploadProgress(0);
          enqueueSnackbar(res?.data?.message, { variant: "error" });
        }
      })
      .finally(() => {
        setUploadProgress(0);
      });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedExtensions = [".xlsx", ".xls"];
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension =
        fileNameParts.length > 1 ? fileNameParts.pop().toLowerCase() : "";

      if (allowedExtensions.includes(`.${fileExtension}`)) {
        setExcelFile(selectedFile);
        setFilePayload(selectedFile.name);
      } else {
        enqueueSnackbar(t("tips_product.accept_excel_format"), {
          variant: "error",
        });
        e.target.value = null;
      }
    }
  };

  //Download data in excel format
  const downloadExcel = async () => {
    let clientId = localStorage.getItem("RETAIL_CLIENT");
    try {
      axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_URL + "/web/products/download/excel",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("USER_TOKEN"),
          "accept-language": localStorage.getItem("RC_LANG_KEY"),

          selectedClient: clientId,
        },
        responseType: "blob",
      })
        .then((response) => response.data)
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = blobUrl;
          a.download = "product.xls";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        });
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  useEffect(() => {
    if (!open) {
      setExcelFile(null);
      setFilePayload(null);
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  // Popup to upload data from excel file
  const UploadPopup = () => {
    return (
      <Card
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <CardContent>
          <Grid>
            <Typography>{t("tips_product.choose_excel_file")}</Typography>
          </Grid>
          <Grid
            pt={1}
            display={"flex"}
            border={"1px solid #ccc"}
            borderRadius={"10px"}
            justifyItems={"center"}
            alignItems={"center"}
            height={"55px"}
          >
            {/* <FormControl fullWidth>
              <InputLabel htmlFor="excelFile">Upload File</InputLabel>
              <TextField
                id="excelFile"
                name="excelFile"
                type="file"
                accept=".xls, .xlsx"
                sx={{
                  display: "none",
                }}
                onChange={handleFileChange}
                inputProps={{ "aria-label": "Upload File" }} // 用于辅助工具的文本
              />
            </FormControl> */}

            <div style={{ marginTop: "-10px", marginLeft: "15px" }}>
              <input
                accept=".xls, .xlsx"
                style={{ display: "none" }}
                id="excel-file-upload"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="excel-file-upload">
                <Button variant="contained" component="span">
                  Upload File
                </Button>
              </label>
            </div>
            {filePayload ? (
              <Typography
                variant="body1"
                style={{ marginTop: "8px", marginLeft: "10px" }}
              >
                {filePayload}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                style={{ marginTop: "0px", marginLeft: "10px" }}
              >
                Please upload the excel file
              </Typography>
            )}
          </Grid>
          <Grid justifyContent={"center"} container>
            {uploadProgress > 0 && <CircularProgress />}
          </Grid>
          <Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("product.serial_number")}</TableCell>
                  <TableCell>{t("table.name")}</TableCell>
                  <TableCell>{t("product.category_level1")}</TableCell>
                  <TableCell>{t("product.category_level2")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{t("product.number_one")}</TableCell>
                  <TableCell>{t("product.coca_cola")}</TableCell>
                  <TableCell>{t("product.drink")}</TableCell>
                  <TableCell>{t("product.cold_drink")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("product.number_two")}</TableCell>
                  <TableCell>{t("product.coca_cola")}</TableCell>
                  <TableCell>{t("product.drink")}</TableCell>
                  <TableCell>{t("product.cold_drink")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography pt={1}>
              {t("tips_product.note_excel_format")}
            </Typography>
          </Grid>
          <Grid container justifyContent={"flex-end"} pt={2}>
            <Box pr={1}>
              <Button
                id="Product-button-01"
                variant="outlined"
                onClick={handleCancel}
                style={{ borderRadius: "8px" }}
              >
                {t("common.cancel")}
              </Button>
            </Box>
            <Box>
              <Button
                id="Product-button-02"
                variant="contained"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                }}
                onClick={handleFileUpload}
              >
                {t("common.upload")}
              </Button>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(); // 调用搜索函数
    }
  };

  const handlerClear = () => {
    setSearchValue("");
    setFilters({
      ...defaultFilters,
      name: "",
    });
  };

  const searchClientProps = () => {
    return (
      <Grid container display={"flex"} justifyContent={"flex-end"} pb={0.5}>
        <TextField
          label={t("tips.search_by_name")}
          value={searchValue}
          onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            style: {
              height: 50,
              width: "300px",
              background: "#fff",
              color: "#474B4F",
              opacity: "0.6",
              boxShadow: "0px 1px 3px #0000001A",
              borderRadius: "8px",
              border: "0px !important",
              padding: "10px",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id="clearButton"
                  onClick={handlerClear}
                  style={{ visibility: searchValue ? "visible" : "hidden" }}
                >
                  <ClearIcon />
                </IconButton>
                <IconButton id="Device1" onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputLabel-root": {
              color: "#D1D1D1",
            },
          }}
        />
      </Grid>
    );
  };
  const [searchValue, setSearchValue] = useState();
  <Grid
    lg={3}
    item
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    }}
  >
    <TextField
      label={t("product.category_level1")}
      fullWidth
      value={name}
      onChange={(e) => setName(e.target.value)}
      InputProps={{
        style: {
          height: 50,
          width: "300px",
          background: "#fff",
          color: "#474B4F",
          opacity: "0.6",
          boxShadow: "0px 1px 3px #0000001A",
          borderRadius: "8px",
          border: "0px !important",
          padding: "10px",
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton id="retailClint" onClick={handleSearchClick}>
              <search />
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputLabel-root": {
          color: "#D1D1D1",
        },
      }}
    />
  </Grid>;
  return (
    <RightViewLayout
      title={t("product.product")}
      globalFilterProps={false}
      searchProps={searchClientProps()}
    >
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ display: "flex", width: "100%" }}
        pb={2}
      >
        <Grid
          item
          sx={{ display: "flex", width: "100%" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            item
            container
            rowGap={"7px"}
            md={10}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {isAlloweddownloadExcel && (
              <Tooltip title={t("tips_product.download_excel_format")}>
                <Grid
                  id="Product-button-03"
                  style={{
                    height: "35px",
                    width: "35px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    marginRight: 12,
                    background: "white",
                    border: "1px solid grey",
                  }}
                >
                  <FileDownloadOutlinedIcon onClick={downloadExcel} />
                </Grid>
              </Tooltip>
            )}
            {isAllowedUpload && (
              <Tooltip title={t("EPLPR0232")}>
                <Grid
                  id="Product-button-04"
                  style={{
                    height: "35px",
                    width: "35px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    marginRight: 12,
                    background: "white",
                    border: "1px solid grey",
                  }}
                >
                  <FileUploadOutlinedIcon onClick={() => popUp()} />
                </Grid>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            md={2}
            container
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Tooltip title={t("common.refresh")}>
              <Grid
                id="Product-button-05"
                style={{
                  height: "35px",
                  width: "35px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  marginRight: 12,
                  marginTop: "2px",
                }}
              >
                <RefreshIcon onClick={() => refreshLoadData()} />
              </Grid>
            </Tooltip>
            {isAllowedCreate ? (
              <Tooltip title={t("product.add_product")}>
                <Grid
                  id="Product-button-06"
                  style={{
                    height: "35px",
                    width: "35px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    background: "#1487ca",
                    marginLeft: 2,
                  }}
                >
                  <AddIcon
                    sx={{ color: "white" }}
                    onClick={() => navigate(REACT_PRODUCT_ADD)}
                  />
                </Grid>
              </Tooltip>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>

      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
        onSelection={() => console.log("")}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="subtitle2">
            {t("tips_product.product_delete_confirmation")}
          </Typography>
          <Typography sx={{ textAlign: "center" }} variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => handleDelete(id)}
            >
              {t("common.delete")}
            </Button>
          </Box>
        </Grid>
      </Dialog>
      <SimpleDailogBox
        width={"300"}
        open={open}
        onClose={handleClose}
        backdropFilter="blur(5px)"
      >
        <UploadPopup />
      </SimpleDailogBox>
    </RightViewLayout>
  );
}
