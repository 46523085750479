import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_USER } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProfilePic from "../../assets/images/ProfilePic.svg";
import ProfileUplod from "../../components/UploadImg";
import CommonUtil from "../../util/CommonUtils";
import { createPerson } from "../../services/PersonService";
import { useSnackbar } from "notistack";
import axios from "axios";
import { filterOutlet } from "../../services/OutletService";
import { getRoles } from "../../services/UserManagementService";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import CustomDatePicker from "../../components/CustomDatePicker";

export default function AddUser() {
  const customStyle = {
    borderStyle: "none",
    color: "#304669",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlets, setSelectedoutlets] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [profile, setProfile] = useState(ProfilePic);
  const [profileImage, setProfileImage] = useState();
  const [date, setDate] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [user, setUser] = useState();
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    personId: "",
    phoneCountryCode: "",
    phone: "",
    joinDate: null,
    roleId: "",
    clientId: "",
    localRoleId: "",
    outletIds: [],
    profilePhoto: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    personId: "",
    phoneCountryCode: "",
    phone: "",
    joinDate: "",
  });

  useEffect(() => {
    filterOutlet(1, 1000).then((response) => {
      if (response.data.code === "LMSI6000" && response.data.data) {
        setOutlets(response?.data?.data?.objects);
      }
    });

    getRoles().then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRoles(res?.data?.data);
      }
    });
    setUser(JSON.parse(localStorage?.getItem("USER")));
  }, []);

  useEffect(() => {
    setPayload({
      ...payload,
      localRoleId: role,
      outletIds: selectedOutlets,
    });
  }, []);

  const passwordInfo = t("tips.password_title");

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: `${t("tips.required")}`,
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.personId)) {
      setError({
        ...error,
        personId: `${t("tips.required")}`,
      });
      return false;
    }
    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: `${t("tips.required")}`,
      });
      return;
    }
    if (CommonUtil.isEmptyString(countryCode)) {
      setError({
        ...error,
        phone: `${t("tips.country_code_required")}`,
      });
      return;
    }
    if (CommonUtil.isEmptyString(phoneNumber)) {
      setError({
        ...error,
        phone: `${t("tips_user.phone_required")}`,
      });
      return;
    }
    if (CommonUtil.isEmpty(selectedOutlets)) {
      enqueueSnackbar(t("tips_user.outlet_required"), { variant: "error" });
      return;
    }
    if (CommonUtil.isEmpty(selectedRoles)) {
      enqueueSnackbar(t("tips_user.role_required"), { variant: "error" });
      return;
    }

    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          // phone: `${t("tips.country_code_required")}`,//"RESU00000"
          //  phone:t("RESU00000"),
        });
        return;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: `${t("tips.mobild_nubmer")}`,
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: `${t("LVLRCP010")}`,
        });
        return;
      }
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: `${t("tips.required")}`,
      });
      return;
    } else {
      if (!CommonUtil.isValidEmail(payload.email)) {
        setError({
          ...error,
          email: `${t("tips.invalid_email_address")}`,
        });
        return false;
      }
    }
    return true;
  };
  const regex = /^\+\d{1,3}\d{7,20}$/;
  const handleSubmit = () => {
    if (validateForm()) {
      if (date != null) {
        if (date > new Date()) {
          enqueueSnackbar(t("LVLRCP019"), {
            variant: "error",
          });
          return;
        }
        if (date < new Date("1900-01-01")) {
          enqueueSnackbar(t("LVLRCP020"), {
            variant: "error",
          });
          return;
        }
      }
      var request = {
        ...payload,
        clientId: user?.userId,
        joinDate: date,
        localRoleId: role,
        outletIds: selectedOutlets,
        email: payload.email.toLowerCase(),
        phone: phoneNumber,
        phoneCountryCode: countryCode,
        roleId: selectedRoles,
      };
      if (regex.test(phoneNumber)) {
        enqueueSnackbar(
          "Mobile Number Including Country Code Must Be Between 8 to 23 Digits",
          {
            variant: "error",
          }
        );

        return;
      }

      createPerson(request).then((res) => {
        if (res?.data?.data?.userDetails && res?.data?.code === "LVLI0008") {
          enqueueSnackbar(t("tips_user.person_created"), {
            variant: "success",
          });
          if (
            !CommonUtil.isEmptyString(res?.data?.data?.profilePhotoURL) &&
            profileImage
          ) {
            uploadProfilePhoto(res);
          }
          navigate(REACT_USER);
          return;
        }
        if (res?.data?.code !== "LVLI0008") {
          enqueueSnackbar(t(res?.data?.message), { variant: "error" });
          return;
        }
      });
    }
  };

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append("url", data?.data?.data?.profilePhotoURL);
    bodyFormData.append("file", profileImage);

    axios({
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL + "/web/person/profile",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
      },
    });
  };

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: file.file.name,
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };

  const handleOutletCheckboxChange = (outlet) => () => {
    if (selectedOutlets.includes(outlet)) {
      setSelectedoutlets(selectedOutlets.filter((o) => o !== outlet));
    } else {
      setSelectedoutlets([...selectedOutlets, outlet]);
    }
  };

  const handleRoleSelection = (role) => {
    setSelectedRoles(role);
  };

  useEffect(() => {
    setRole(selectedRoles);
  }, [selectedRoles]);

  const label = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <span>{CommonUtil.formatLongText(label)}</span>
      </Tooltip>
    );
  };

  return (
    <RightViewLayout
      id="addpersonback"
      title={t("user.create_person")}
      navigateBack={REACT_USER}
    >
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0} sx={{ height: "100%" }}>
          <Grid container px={2}>
            <Grid
              item
              xs={12}
              md={4}
              container
              display={"flex"}
              justifyContent={"center"}
              pt={2}
            >
              <ProfileUplod
                imageInfo={t("tips_user.allowed_file_types")}
                imageSize={t("tips_user.max_file_size")}
                style={{
                  borderStyle: "none !important",
                  color: "#304669",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
                onsetProfile={(profile) => handleProfile(profile)}
                profilePhoto={profile}
              />
            </Grid>
            <Grid container xs={12} md={8} item columnSpacing={2}>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson1"
                  required
                  label={t("table.first_name")}
                  size="small"
                  name="firstName"
                  error={error.firstName}
                  resetError={() => setError({ ...error, firstName: "" })}
                  value={payload.firstName}
                  handleChange={handleChange}
                  validation={"alpha-numeric-ch-th"}
                  inputProps={{
                    maxLength: 50,
                  }}
                  helperText={error.firstName}
                  placeholder={t("tips_user.first_name")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson2"
                  label={t("table.last_name")}
                  size="small"
                  name="lastName"
                  error={error.lastName}
                  resetError={() => setError({ ...error, lastName: "" })}
                  value={payload.lastName}
                  handleChange={handleChange}
                  validation={"alpha-numeric-ch-th"}
                  inputProps={{
                    maxLength: 50,
                  }}
                  helperText={error.lastName}
                  placeholder={t("tips_user.last_name")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson3"
                  required
                  label={t("user.person_Id")}
                  size="small"
                  name="personId"
                  value={payload.personId}
                  error={error.personId}
                  resetError={() => setError({ ...error, personId: "" })}
                  helperText={error.personId}
                  inputProps={{
                    maxLength: 20,
                  }}
                  validation={"alpha-numeric"}
                  handleChange={handleChange}
                  placeholder={t("tips_user.person_id")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson4"
                  required
                  label={t("table.email")}
                  size="small"
                  name="email"
                  value={payload.email}
                  error={error.email}
                  resetError={() => setError({ ...error, email: "" })}
                  helperText={error.email}
                  inputProps={{
                    maxLength: 50,
                  }}
                  validation={"email"}
                  handleChange={handleChange}
                  placeholder={t("tips_user.enter_email")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginBottom: "-11px" }}
                >
                  {t("table.mobile")} <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <CustomePhoneFiled
                  error={error.phone}
                  handleCountryCode={(data) => {
                    setCountryCode(data.dialCode);
                    setError({ ...error, phone: "" });
                  }}
                  countryCode={countryCode}
                  placeholder={t("table.mobile")}
                  phoneNumber={phoneNumber}
                  name={"phoneNumber"}
                  size={"small"}
                  handleChange={(e) => {
                    setPhoneNumber(e);
                    setError({ ...error, phone: "" });
                  }}
                  label={"Mobile"}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomDatePicker
                  size={"small"}
                  disableFuture={true}
                  date={date}
                  disabled={false}
                  label={t("tips_user.date_of_joining")}
                  placeholder={t("tips_user.select_date")}
                  SelectedDate={(e) => setDate(e)}
                />
              </Grid>
            </Grid>
            <Grid pt={2} style={{ width: "100%" }}>
              <Typography pb={2}>{t("tips_user.outlet_in_charge")}</Typography>
              <Grid
                container
                style={{ backgroundColor: "#F7FBFE", width: "100%" }}
                lg={12}
              >
                <Grid p={1} style={{ width: "100%" }}>
                  <FormGroup row>
                    {outlets.map((outlet, index) => (
                      <Grid item key={outlet.id} xs={3} lg={3} md={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={`outlet-checkbox-${index}`}
                              checked={selectedOutlets.includes(outlet?.id)}
                              onChange={handleOutletCheckboxChange(outlet?.id)}
                            />
                          }
                          label={label(outlet.name)}
                        />
                      </Grid>
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid pt={2} lg={12}>
              <Typography pb={2}>{t("authorization.title")}</Typography>
              <Grid
                container
                style={{ backgroundColor: "#F7FBFE" }}
                columnSpacing={2}
              >
                {roles.map((role, index) => (
                  <Grid item key={role.id} xs={6} sm={4} md={3} lg={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          id={`role-radio-${index}`}
                          checked={selectedRoles === role.id}
                          onChange={() => handleRoleSelection(role.id)}
                          value={role.id}
                          name="role-selection"
                        />
                      }
                      label={role.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Box item pl={2}>
                    <Button
                      id="addpersonsubmit"
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      onClick={handleSubmit}
                      style={{
                        size: "medium",
                        borderRadius: "8px",
                        opacity: 1,
                        background:
                          "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                      }}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item>
                    <Button
                      id="addpersonnext"
                      className="text-transform-none"
                      variant="outlined"
                      onClick={() => navigate(REACT_USER)}
                      size="large"
                    >
                      {t("common.back")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
