import { useTranslation } from "react-i18next";
import RightViewLayout from "../../components/RighViewLayout";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  attributeList,
  createAttribute,
  deleteAttribute,
} from "../../services/ProductService";
import CustomInput from "../../components/CustomInput";
import { REACT_PRODUCT_LABEL } from "../../router/ReactEndPoints";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import Delete from "../../assets/images/Delete Icon.svg";

export default function ProductLabel() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [check, setCheck] = useState(false);
  const [newFields, setNewFields] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const roleName = localStorage.getItem("ROLE_NAME");
  const permission = localStorage.getItem("permission");
  let isAllowedCreate = true;
  let isAllowedDelete = true;
  useEffect(() => {
    localStorage.setItem("newFields", JSON.stringify(newFields));
  }, [newFields]);
  const loadData = () => {
    attributeList().then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        let list = res.data.data.filter((item) => {
          return !(
            item.dataLevel === "System Default" && item.name === "priceRule"
          );
        });
        setFields(list);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleClose = () => {
    setConfirm(false);
  };

  const [field, setField] = useState("");

  const isFieldEmpty = (field) => {
    // console.log("Received Field Value : ", field);
    setField(field);
    //return !field.label.trim() || !field.type.trim();
    // return field.label == "" || field.type == "";
    let isContain = [
      "Product Name",
      "Product SKU",
      "Category level 1",
      "Category level 2",
      "Category level 3",
      "Quantity Barcode",
      "Barcode",
      "Product Prime Cost",
      "Discount Price",
      "Currency",
      "Brand Name",
      "Origin Of Product",
      "Manufacturer",
    ];
    if (
      isContain.some((item) => item.toLowerCase() === field.label.toLowerCase())
    ) {
      enqueueSnackbar("Already exist as default label.", { variant: "error" });
      return true;
    } else if (field.label === "" || field.type === "") {
      enqueueSnackbar(t("tips_product.field_empty"), { variant: "error" });
      return true;
    }
  };

  const handleAddField = () => {
    if (newFields.length === 0) {
      const newField = { label: "", type: "", name: "" };
      setNewFields([...newFields, newField]);
      setCheck(true);
    }

    if (newFields.length > 0) {
      const newCardIndex = newFields.length - 1;

      const newCardElement = document.getElementById(`card-${newCardIndex}`);

      if (newCardElement) {
        newCardElement.scrollIntoView({ behavior: "smooth", block: "end" });
      } else {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddProductLabel");
    // isAllowedDelete = permission && permission.includes("DeleteProductLabel");
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newFields[0].label) {
      newFields.forEach((field) => {
        // field.label = capitalizeFirst(field.label);
        // field.name = toCamelCase(field.label);

        field.label = field.label;
        field.name = field.label;
        createAttribute(newFields).then((res) => {
          if (res?.data?.data && res?.data?.code === "LVLI0008") {
            enqueueSnackbar(t("tips_product.added_product"), {
              variant: "success",
            });
            setNewFields([]);
            loadData();
            navigate(REACT_PRODUCT_LABEL);
          }
          if (res?.data?.code !== "LVLI0008") {
            enqueueSnackbar(t(res.data.message), { variant: "error" });
          }
        });
      });
    } else {
      enqueueSnackbar(t("tips_product.field_empty"), { variant: "error" });
    }
  };

  const handleCancel = () => {
    setNewFields([]);
    setCheck(false);
  };

  const handleActions = (action, data) => {
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
      localStorage.removeItem("newFields");
    }
  };

  const handleDelete = (id) => {
    deleteAttribute(id).then((res) => {
      if (res?.data?.code === "LVLE0034") {
        enqueueSnackbar(t("tips_product.cannot_deleted"), { variant: "error" });
      }
      if (res?.data?.code === "EPTE0070") {
        enqueueSnackbar(t("tips_product.last_deleted"), {
          variant: "error",
        });
      }

      if (res?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("tips_product.deleted_product"), {
          variant: "success",
        });

        loadData();
        navigate(REACT_PRODUCT_LABEL);
      }
      setConfirm(false);
    });
  };

  const handleFieldChange = (index, field, value) => {
    const { name, value: fieldValue } = value.target || value;
    const updatedFields = [...newFields];

    if (name === "options") {
      const optionsArray = fieldValue.split(",");
      field[name] = optionsArray;
    } else {
      field[name] = fieldValue;
    }

    updatedFields[index] = { ...field };
    setNewFields(updatedFields);
  };

  const toCamelCase = (str) => {
    let reg = new RegExp("[^\\u0000-\\u007F]+", "g");
    if (reg.test(str)) {
      return str;
    } else {
      return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
    }
  };

  const capitalizeFirst = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  return (
    <RightViewLayout navigateBack={false} title={t("product.title")}>
      <Grid container columnSpacing={1}>
        <Grid container direction="row" xs={12}>
          {fields.map((field, index) => (
            <Grid item>
              <Card
                style={{
                  width: 380,
                  height: 70,
                  borderRadius: 10,
                  marginLeft: "20px",
                  marginTop: "20px",
                }}
                elevation={0}
              >
                <CardContent>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">{field.label}</Typography>
                    {field?.dataLevel !== "System Default" && (
                      <Grid item xs={1} style={{ textAlign: "center" }}>
                        {field.id ? (
                          <Button
                            style={{
                              paddingTop: "14px",
                              background: `transparent url('${Delete}') 0% 0% no-repeat padding-box`,
                              border: "none",
                            }}
                            onClick={() => handleActions("Delete", field?.id)}
                          />
                        ) : null}
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {isAllowedCreate && (
            <Box
              style={{
                width: 380,
                height: 70,
                borderRadius: 10,
                border: "2px dashed #36C96D",
                backgroundColor: "rgba(54, 201, 109,0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "20px",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              elevation={0}
              onClick={handleAddField}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  xs={3}
                  style={{
                    width: 100,
                    height: 100,
                    marginLeft: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    id="ProductLabel-button-0"
                    onClick={handleAddField}
                    style={{
                      background: `transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat`,
                      border: "none",
                      width: "50px",
                      height: "30px",
                      cursor: "pointer",
                      minWidth: "10px",
                      minHeight: "10px",
                      rowGap: "2px",
                      marginTop: "30px",
                    }}
                  >
                    <span style={{ color: "white", fontSize: "20px" }}>+</span>
                  </Button>
                  <Typography
                    variant="body1"
                    style={{
                      width: "200px",
                      marginRight: "0px",
                      marginLeft: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {t("product.add_product_label")}
                  </Typography>
                </Grid>
              </CardContent>
            </Box>
          )}

          <Grid
            item
            container
            direction="row"
            spacing={1}
            rowSpacing={2}
            pl={2}
          >
            {newFields.map((field, index) => (
              <Grid key={index} item>
                <Card
                  id={`card-${index}`}
                  style={{
                    width: 380,
                    height: 70,
                    borderRadius: 10,

                    marginTop: "20px",
                  }}
                  elevation={0}
                >
                  <CardContent style={{ paddingBottom: 4 }}>
                    <Grid
                      item
                      xs={10}
                      style={{
                        width: "100%",
                      }}
                    >
                      <CustomInput
                        id="ProductLabel25"
                        value={field.label}
                        handleChange={(value) =>
                          handleFieldChange(index, field, value)
                        }
                        name="label"
                        size="small"
                        fullWidth
                        InputProps={field.id ? { readOnly: true } : {}}
                        resetError={() => console.log()}
                        style={{ boxSizing: "border-box" }}
                        placeholder="Product Label"
                        height="40px"
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {newFields.length > 0 && check ? (
          <Grid item container pb={3} mr={5}>
            <Grid item xs={12}>
              <Grid
                display={"flex"}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item pl={2} columnSpacing={2}>
                  <Button
                    id="ProductLabel-button-04"
                    className="text-transform-none"
                    variant="outlined"
                    onClick={handleCancel}
                    size="primary"
                    style={{
                      background: "#F7FBFE 0% 0% no-repeat padding-box",
                      marginRight: "10px",
                    }}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    id="ProductLabel-button-03"
                    variant="contained"
                    size="primary"
                    className="text-transform-none"
                    style={{
                      background:
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                      color: "#FFFFFF",
                      fontSize: "normal normal normal 14px / 22px Roboto",
                      width: "80px",
                    }}
                    onClick={handleSubmit}
                  >
                    {t("common.save")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Dialog open={confirm} onClose={handleClose} maxWidth="md">
          <DialogTitle>
            <Typography variant="subtitle2">
              {t("tips.selected_detele_record")}
            </Typography>
          </DialogTitle>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            padding={"10px"}
            justifyContent={"center"}
          >
            <Box display="flex" justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
                onClick={handleClose}
                sx={{ marginRight: 2 }}
              >
                {t("common.cancel")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                  color: "#FFFFFF",
                  fontSize: "normal normal normal 14px / 22px Roboto",
                  width: "80px",
                }}
                onClick={() => handleDelete(id)}
              >
                {t("common.delete")}
              </Button>
            </Box>
          </Grid>
        </Dialog>
      </Grid>
    </RightViewLayout>
  );
}
