import React, { forwardRef, useEffect, useState } from "react";
import { Stack, InputLabel, OutlinedInput } from "@mui/material";

const CustomInput = forwardRef((props, ref) => {
  const {
    onChange,
    onBlur,
    value,
    error,
    label,
    type = "text",
    required,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const name = props.name;
  const valueChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  const inputOnBlur = (e) => {
    setInputValue(e.target.value);
    if (onBlur) {
      onBlur(e);
    }
  };
  return (
    <Stack
      sx={{
        marginBottom: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      spacing={0}
    >
      {label && (
        <InputLabel
          sx={{
            color: "#707070",
            fontSize: "14px",
            mr: 2,
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
        >
          {label} {required && <i style={{ color: "red" }}>*</i>}
        </InputLabel>
      )}

      <OutlinedInput
        sx={
          props.margintop
            ? {
                flexGrow: 1,
                marginTop: props.margintop,
                input: {
                  padding: "6px",
                },
              }
            : {
                flexGrow: 1,
                input: {
                  padding: "6px",
                },
              }
        }
        id={"baseInput-" + name}
        type={type}
        name={name}
        value={inputValue}
        onBlur={inputOnBlur}
        onChange={valueChange}
        {...rest}
        error={Boolean(error)}
      />
    </Stack>
  );
});

export default CustomInput;
