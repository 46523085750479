import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { Avatar, Box, MenuItem, Tooltip } from "@mui/material";
import { useEffect } from "react";
import CommonUtil from "../../util/CommonUtils";
import UserService from "../../services/UserService";
import DefaultUserPic from "../../assets/images/default_user_pic.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import {
  REACT_UPDATE_USER_PROFILE,
  REACT_VIEW_USER_PROFILE,
} from "../../router/ReactEndPoints";
import { t } from "i18next";
export default function UserProfileMenu() {
  const navigate = useNavigate();
  const roleName = localStorage.getItem("ROLE_NAME");

  const handleView = () => {
    navigate(REACT_VIEW_USER_PROFILE);
  };

  const [user, setUser] = useState({});
  let tokenId = CommonUtil.decodeToken();

  useEffect(() => {
    if (tokenId) {
      UserService.getUserDetails(tokenId?.id, true).then((res) => {
        setUser(res?.data?.data);
      });
    }
  }, [tokenId?.id]);

  const logout = () => {
    CommonUtil.resetUserSession();
    localStorage.removeItem("DEVICE_CODE");
    window.location = "/";
  };

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  var lastName = !CommonUtil.isEmptyString(user?.lastName)
    ? user?.lastName
    : "";
  var userName = user?.firstName + " " + lastName;

  return (
    <div>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        style={{ cursor: "pointer" }}
        {...bindTrigger(popupState)}
        sx={{ pl: { xs: 0, md: 2 }, pr: 0, my: { xs: 0, md: 1 } }}
      >
        <Box>
          <Avatar src={DefaultUserPic} sx={{ width: 24, height: 24 }} />
        </Box>
        <Box flexGrow={1} sx={{ display: { xs: "none", md: "flex" }, pl: 2 }}>
          <Tooltip title={userName} arrow placement="bottom">
            <Typography variant="menuItem">
              {CommonUtil.formatLongText(userName.slice(0, 20))}
            </Typography>
          </Tooltip>
        </Box>
        <Box py={0.5} px={2} sx={{ display: { xs: "none", md: "flex" } }}>
          <Typography variant="menuItem">
            {" "}
            {popupState.isOpen ? (
              <KeyboardArrowLeftIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </Typography>
        </Box>
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {/* <MenuItem onClick={popupState.close}>Edit Profile</MenuItem> */}

        {roleName === "OWNER" && (
          <MenuItem
            id="UserProfileMenu-menuitem-1"
            onClick={() => {
              popupState.close();
              window.location.href = "/landing";
            }}
          >
            {t("menu.retail_client_management")}
          </MenuItem>
        )}

        <MenuItem
          id="UserProfileMenu-menuitem-1"
          onClick={() => {
            popupState.close();
            handleView();
          }}
        >
          {t("menu.view_profile")}
        </MenuItem>

        {/* <MenuItem
          id="UserProfileMenu-menuitem-2"
          onClick={() => {
            popupState.close();
            handleUpdate();
          }}
        >
        {t("VIEC0007")}
        </MenuItem> */}

        <MenuItem
          id="UserProfileMenu-menuitem-3"
          onClick={() => {
            popupState.close();
            logout();
          }}
        >
          {t("menu.logout")}
        </MenuItem>
      </Popover>
    </div>
  );
}
