import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { REACT_APPROVAL_LIST } from "../../router/ReactEndPoints";
import RightViewLayout from "../../components/RighViewLayout";
import CustomInput from "../../components/CustomInput";
import { useEffect, useState, useTransition } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPriceChangeEventById,
  getPriceChangeEventsApprovalList,
} from "../../services/PriceChangeEventservice";
import { findProductById } from "../../services/ProductService";
import { findTemplateById } from "../../services/TemplateService";
import { getUserById } from "../../services/UserService";
import { chnageStatus, getApprovalById } from "../../services/ApprovalService";
import { useSnackbar } from "notistack";
import { FormGroup } from "@mui/material";
import { findOutletById } from "../../services/OutletService";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import { timeZoneList } from "../../constants/TimeZone";

export default function ViewApprovalList() {
  const { t } = useTranslation();
  const [eventName, setEventName] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [productName, setProductName] = useState("");
  const [promotionType, setPromotionType] = useState("");
  const [timeZone, SetTimeZone] = useState("");
  const location = useLocation();
  const [scheduleMode, setScheduleMode] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateType, setTemplateType] = useState("");
  const id = location?.state?.data;
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const staticValues = Array.from({ length: 31 }, (_, index) => index + 1);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedMoths, setSelectedMonths] = useState([]);
  const [createdAt, setCreatedAt] = useState();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    getPriceChangeEventById(id).then((res) => {
      setEventName(res.data.data.promotionType);
      setCreatedAt(res.data.data.createdAt);
      setStartAt(res.data.data.startAt);
      setEndAt(res?.data?.data?.endAt);
      setPromotionType(res?.data?.data?.promotionType);
      setScheduleMode(res?.data?.data?.scheduleMode);
      setSelectedWeekdays(res?.data?.data?.days);
      setSelectedDates(res?.data?.data?.dates);

      findProductById(res?.data?.data?.productId).then((res) => {
        setProductName(res?.data?.data?.product?.name);
      });

      getApprovalById(res?.data?.data?.id).then((res) => {
        setCreatedByName(res.data.data.createdByName);
      });
      findTemplateById(res?.data?.data?.templateId).then((res) => {
        setTemplateName(res?.data?.data?.name); //templateType
        setTemplateType(res?.data?.data?.type);
      });
      //forOutlet
      findOutletById(res?.data?.data?.outletId).then((res) => {
        const name = timeZoneList.find(
          (data) => data.value === res?.data?.data?.timeZone
        )?.name;
        SetTimeZone(name);
      });
    });
  }, [id]);

  const handleApprove = (request) => {
    let request2 = {
      id: id,
      approvalStatus: "Approved",
    };

    chnageStatus(id, request2).then((res) => {
      if (res?.data?.code === "LVLI0003") {
        enqueueSnackbar(res?.data?.message, { variant: "success" });
        navigate(REACT_APPROVAL_LIST);
      }
    });
  };

  const handleReject = (request) => {
    let request1 = {
      id: id,
      approvalStatus: "Rejected",
    };

    chnageStatus(id, request1).then((res) => {
      if (res?.data?.code === "LVLI0003") {
        enqueueSnackbar(res?.data?.message, { variant: "success" });
        navigate(REACT_APPROVAL_LIST);
      }
    });
  };

  return (
    <RightViewLayout
      title={t("approval.viewApprovalRequest")}
      navigateBack={REACT_APPROVAL_LIST}
    >
      <>
        <Card elevation={0}>
          <Grid container xs={12} md={11} item spacing={2} ml={5}>
            <Grid item md={4} xs={12}>
              <CustomInput
                label={t("approval.priceChangeName")}
                size="small"
                value={`Price Change Name: ${eventName}`}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomInput
                label={t("approval.maker")}
                size="small"
                value={createdByName}
                disabled={true}
                style={{}}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomInput
                label={t("table_approval.createtime")}
                size="small"
                value={createdAt}
                disabled={true}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} md={11} item spacing={2} ml={5}>
            <span
              style={{
                paddingLeft: "13px",
                top: "263px",
                left: "390px",
                width: "158px",
                height: "22px",
              }}
            >
              {t("approval.priceChangeDetails")}
            </span>
            <Grid item md={12} xs={12}>
              <Box
                style={{
                  backgroundColor: "#F7FBFE",
                  borderRadius: " 8px",
                  opacity: 8,
                }}
                p={1}
                width="100%"
              >
                {t("approval.productFilter")}-
                <span
                  style={{
                    color: "blue",
                    fontSize: "14px",
                    top: "305px",
                    left: "390px",
                    width: "1470px",
                    height: "64px",
                  }}
                >
                  {t("table_product.product_name")}:{productName}
                </span>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box
                style={{
                  backgroundColor: "#F7FBFE",
                  borderRadius: " 8px",
                  opacity: 8,
                }}
                p={1}
                width="100%"
              >
                {t("approval.priceChangeRule")}-
                <span
                  style={{
                    color: "blue",
                    fontSize: "14px",
                    top: "399px",
                    left: "410px",
                    width: "146px",
                    height: "24px",
                  }}
                >
                  {t("approval.priceChangeRuleName")}:{promotionType}
                </span>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box
                style={{
                  backgroundColor: "#F7FBFE",
                  borderRadius: " 8px",
                  opacity: 8,
                }}
                p={1}
                width="100%"
              >
                {t("approval.templateSelection")}-
                <span
                  style={{
                    color: "blue",
                    fontSize: "14px",
                    marginRight: "16px",
                  }}
                >
                  {t("approval.templateName")}:{templateName}
                </span>
                <span
                  style={{
                    color: "blue",
                    fontSize: "14px",
                    top: "453px",
                    left: "390px",
                    width: "1470px",
                    height: "64px",
                  }}
                >
                  {t("approval.templateName")}:{templateType}
                </span>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            md={11}
            item
            spacing={2}
            ml={5}
            style={{ paddingTop: "10px" }}
          >
            <span
              style={{
                paddingLeft: "13px",
                top: "547px",
                left: "390px",
                width: "74px",
                height: "22px",
              }}
            >
              {" "}
              {t("approval.schedule")}
            </span>
            <Grid item md={12} xs={12}>
              <Box
                style={{
                  backgroundColor: "#F7FBFE",
                  borderRadius: " 8px",
                  opacity: 8,
                }}
                p={1}
                width="100%"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <span
                      style={{
                        color: "black",
                        fontSize: "14px",
                        top: "609px",
                        left: "410px",
                        width: "78px",
                        height: "21px",
                      }}
                    >
                      {t("approval.timeZone")}:{timeZone}
                    </span>
                  </Grid>

                  <Grid container item xs={12} alignItems="center" spacing={1}>
                    <Grid item>
                      <CustomInput
                        label={t("approval.startTime")}
                        size="small"
                        value={startAt}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item>
                      <CustomInput
                        label={t("approval.endTime")}
                        size="small"
                        value={endAt ? endAt : ""}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    {scheduleMode && (
                      <Box sx={{ paddingTop: 2 }}>
                        <Typography>
                          {t("approval.setRepeatCondition")}
                        </Typography>
                        <Box
                          pt={1}
                          style={{ backgroundColor: "#F7FBFE" }}
                          padding={3}
                          marginTop={2}
                        >
                          {scheduleMode === "Weekly" && (
                            <>
                              <Typography>
                                {t("approval.includeExcludeDays")}
                              </Typography>
                              <Grid padding={1} container md={12}>
                                <Grid item container pt={1} md={7} spacing={0}>
                                  <FormGroup row>
                                    {weekdays.map((value, index) => (
                                      <Grid key={index} item xs={1.5} pr={3}>
                                        <Button
                                          variant="contained"
                                          color={
                                            selectedWeekdays.includes(value)
                                              ? "primary"
                                              : "inherit"
                                          }
                                          style={{
                                            width: "30px",
                                            height: "50px",
                                            borderRadius: "100%",
                                            borderRightColor: "black",
                                          }}
                                        >
                                          {value.charAt(0)}
                                        </Button>
                                      </Grid>
                                    ))}
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {scheduleMode === "Monthly" && (
                            <>
                              <Grid container spacing={1} md={6}>
                                {staticValues.map((value, index) => (
                                  <Grid key={index} item xs={2} pr={1}>
                                    <Button
                                      variant="contained"
                                      color={
                                        selectedDates.includes(value)
                                          ? "primary"
                                          : "inherit"
                                      }
                                      sx={{
                                        width: "20px",
                                        height: "30px",
                                        borderRadius: "100%",
                                      }}
                                    >
                                      {value}
                                    </Button>
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          )}
                          {scheduleMode === "Annually" && (
                            <>
                              <Grid container spacing={1} md={6}>
                                {months.map((value, index) => (
                                  <Grid key={index} item xs={2} pr={1}>
                                    <Button
                                      variant="contained"
                                      disabled
                                      color={
                                        selectedMoths.includes(value)
                                          ? "primary"
                                          : "inherit"
                                      }
                                      sx={{
                                        width: "20px",
                                        height: "30px",
                                        borderRadius: "100%",
                                      }}
                                    >
                                      {value
                                        .substring(0, 3)
                                        .toLocaleUpperCase()}
                                    </Button>
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            style={{ paddingRight: "20px", paddingTop: "20px" }}
          >
            <Grid pb={3}>
              <Button
                variant="outlined"
                size="large"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  marginRight: "10px",
                }}
                onClick={() => navigate(REACT_APPROVAL_LIST)}
              >
                {" "}
                {t("common.cancel")}{" "}
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  marginRight: "10px",
                  background: "Red",
                }}
                onClick={handleReject}
              >
                {" "}
                {t("approval.rejectUpper")}{" "}
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  marginRight: "10px",
                  background: "#2AC135",
                }}
                onClick={handleApprove}
              >
                {" "}
                {t("approval.approveUpper")}{" "}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </>
    </RightViewLayout>
  );
}
