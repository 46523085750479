import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import CustomInput from "../../components/CustomInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { REACT_PRODUCT } from "../../router/ReactEndPoints";
import React, { useState } from "react";

import {
  attributeList,
  downloadImage,
  findProductById,
  updateProduct,
  getEventInfo,
  UpdataPriceEvent,
  getCurrentData,
} from "../../services/ProductService";
import { useEffect } from "react";
import CommonUtil from "../../util/CommonUtils";
import Image from "../../assets/images/ProfilePic.svg";
import axios from "axios";
import { handlerPriceData, handlerUpdataFile } from "./interfece";
import DropzoneComponent from "../../components/layout-components/DropzoneComponent";
import { ReactComponent as Upload_Image } from "../../assets/images/Upload_Image.svg";

export default function EditProduct() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let location = useLocation();
  const [product, setProduct] = useState({});
  const saleStatuOptions = ["On Sale", "Sold Out", "Discontinued"];
  // const priceUnitOptions = ["INR", "USD", "EU", "RMB", "THB"];
  const [saleStatus, setSaleStatus] = useState("");
  const [priceUnit, setPriceUnit] = useState("");
  const [customePayload, setCustomePayload] = useState({});
  const [image, setImage] = useState(null);
  const [productImage, setProductImage] = useState();
  const [objectKey, setObjectKey] = useState();
  const [newLabels, setNewLabels] = useState([]);
  const [priceUnitOptions, setPriceUnitOptions] = useState([]);
  const [priceEventList, setPriceEventList] = useState([]); // 变价事件列表
  const [templateList, setTemplateList] = useState([]); //模板事件列表

  const [confirm, setConfirm] = useState(false);

  const [payload, setPayload] = useState({
    id: "",
    name: "",
    sku: "",
    outlet: null,
    categoryLevel1: "",
    categoryLevel2: "",
    categoryLevel3: "",
    quantity: "",
    barcode: "",
    productPrice: "",
    discountPrice: "",
    priceUnit: "",
    brandName: "",
    origin: "",
    manufacturer: "",
    productPhoto: "",
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    updatedBy: "",
    createRequestedBy: "",
    updateRequestedBy: "",
    qrcode: "",
  });

  // useEffect(() => {
  //   getCurrentData().then((res) => {
  //     setPriceUnitOptions(res?.data?.data);
  //   });
  // }, []);

  useEffect(() => {
    findProductById(location.state?.id).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setProduct(res.data.data);
        setPayload({
          ...payload,
          ...res.data.data.product,
          discountPrice:
            res.data.data.product.discountPrice !== null
              ? res.data.data.product.discountPrice
              : "",
          brandName:
            res.data.data.product.brandName !== null
              ? res.data.data.product.brandName
              : "",
          barcode:
            res.data.data.product.barcode !== null
              ? res.data.data.product.barcode
              : "",
          origin:
            res.data.data.product.origin !== null
              ? res.data.data.product.origin
              : "",
          manufacturer:
            res.data.data.product.manufacturer !== null
              ? res.data.data.product.manufacturer
              : "",
          categoryLevel3:
            res.data.data.product.categoryLevel3 !== null
              ? res.data.data.product.categoryLevel3
              : "",
        });
        setObjectKey(res.data.data.product.objectKey);
        setCustomePayload(res.data.data.object);
      }
    });
  }, []);

  useEffect(() => {
    if (objectKey) {
      downloadImage(objectKey).then((res) => {
        if (res?.data?.data && res?.data?.code === "LVLI0000") {
          setImage(res?.data?.data?.url);
        }
      });
    }
  }, [objectKey]);

  useEffect(() => {
    attributeList().then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        let list = res.data.data.filter((item) => {
          return !(
            item.dataLevel === "System Default" && item.name === "priceRule"
          );
        });
        setNewLabels(list);
      }
    });
  }, []);

  const [error, setError] = useState({
    name: "",
    sku: "",
    outlet: null,
    categoryLevel1: "",
    categoryLevel2: "",
    categoryLevel3: "",
    quantity: "",
    productPrice: "",
    discountPrice: "",
    priceUnit: "",
    brandName: "",
    origin: "",
    manufacturer: "",
    qrcode: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleCustomChange = (name, value) => {
    setCustomePayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const [file, setFile] = useState({});
  const [state, setState] = useState({
    profilePhoto: "",
  });

  const handleImage = (file) => {
    const maxSize = 3 * 1024 * 1024; // 3MB in bytes
    if (
      file.file.type === "image/png" ||
      file.file.type === "image/jpeg" ||
      file.file.type === "image/bmp"
    ) {
      let fileSize = file.file.size;
      let size = parseInt(fileSize);
      if (size <= maxSize) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(",")[1],
        });
        setImage(file?.base64);
        setProductImage(file?.file);
      } else {
        setFile("");
        enqueueSnackbar(t("tips_product.file_size_limit"), {
          variant: "error",
        });
        return;
      }
    } else {
      setFile("");
      enqueueSnackbar(t("EPLPR0304"), {
        variant: "error",
      });
      return;
    }
    setPayload((prevPayload) => ({
      ...prevPayload,
      productPhoto: file.file.name,
    }));
    //setImage(file.base64);
    //setProductImage(file.file);
  };

  const uploadProductPhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append("url", data?.data?.data?.preSignedUrl);
    bodyFormData.append("file", productImage);

    axios({
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "/web/product/image",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
      },
    }).then(() => {
      enqueueSnackbar(t("tips_product.product_updated_success"), {
        variant: "success",
      });
      navigate(REACT_PRODUCT);
    });
  };

  const handleSubmit = async (id) => {
    let request = {
      product: payload,
      object: customePayload,
    };

    try {
      const response = await updateProduct(request); // 等待 updateProduct 完成

      // 检查响应
      if (response?.data?.data && response?.data?.code === "LVLI0003") {
        if (
          CommonUtil.isEmpty(response.data.data.preSignedUrl) &&
          !productImage
        ) {
          enqueueSnackbar(t("tips_product.product_updated_success"), {
            variant: "success",
          });
          navigate(REACT_PRODUCT);
        } else {
          uploadProductPhoto(response);
        }
      } else {
        enqueueSnackbar(response?.data?.message, { variant: "error" });
        return; // 终止执行，跳出函数
      }

      // 如果没有返回，继续执行后面的代码
      const getEventRes = await getEventInfo({ productId: location.state.id });

      let res = handlerPriceData(
        getEventRes?.data?.data,
        payload,
        setPriceEventList,
        setTemplateList,
        customePayload
      );

      processPriceList(res.priceList, res?.templateList);
    } catch (error) {
      // 处理可能的错误
      enqueueSnackbar(t("An error occurred while updating the product."), {
        variant: "error",
      });
      console.error(error);
    }
  };
  const processItem = (item, type) => {
    return handlerUpdataFile(item, type);
  };

  const processPriceList = async (priceList, templateList) => {
    try {
      const pricePromises = priceList.map((item) => processItem(item, "0"));

      const templatePromises = templateList.map((item) =>
        processItem(item, "1")
      );

      const [priceResults, templateResults] = await Promise.all([
        Promise.all(pricePromises),
        Promise.all(templatePromises),
      ]);

      const validPriceResults = priceResults.filter((res) => res !== null);

      const validTemplateResults = templateResults.filter(
        (res) => res !== null
      );

      if (validPriceResults.length > 0 || validTemplateResults.length > 0) {
        const params = {
          productId: location.state.id,
          templateImageList: validTemplateResults.map((res) => ({
            templateId: res?.id,
            imageUrl: res?.imageUrl,
          })),
          eventTemplateImageList: validPriceResults.map((res) => ({
            priceChangeEventId: res?.id,
            templateId: res?.templateVO?.id,
            imageUrl: res?.imageUrl,
          })),
        };

        const apiResponse = await UpdataPriceEvent(params);
        if (apiResponse?.data?.code === "LVLI0000") {
          enqueueSnackbar(apiResponse?.data?.message, {
            variant: "success",
          });
        }
      }
    } catch (error) {
      console.error("Error processing price or template list:", error);
    }
  };

  return (
    <RightViewLayout
      title={t("product.edit_product")}
      navigateBack={REACT_PRODUCT}
    >
      <Dialog
        open={confirm}
        onClose={() => {
          setConfirm(false);
        }}
        maxWidth="md"
      >
        <DialogTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: t("tips_product.sure_change_product"),
            }}
            style={{ textAlign: "center" }}
          ></div>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => {
                setConfirm(false);
              }}
              sx={{ marginRight: 2 }}
            >
              {t("common.back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={handleSubmit}
            >
              {t("common.save")}
            </Button>
          </Box>
        </Grid>
      </Dialog>
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0}>
          <Grid container item>
            <Grid container px={2} spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgb(71, 75, 79)",
                  }}
                  color="textSecondary"
                  pt={1}
                >
                  {t("product.product_picture")}
                </Typography>
                <DropzoneComponent
                  getExcelFile={(excelData) => handleImage(excelData)}
                >
                  <Box
                    mt={0}
                    p={1}
                    sx={{
                      border: "2px dashed #36C96D",
                      borderRadius: "5px",
                      backgroundColor: "rgba(54, 201, 109,0.1)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "400px",
                    }}
                  >
                    {image ? (
                      <img
                        src={image}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "380px" }}
                      />
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Upload_Image width="100px" height="100px" />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            textAlign: "center",
                            opacity: "0.8",
                            mt: 1,
                          }}
                        >
                          {t("tips_product.drag_and_drop_file")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            textAlign: "center",
                            opacity: "0.8",
                          }}
                        >
                          {t("tips_product.upload_image_format")}
                        </Typography>
                      </div>
                    )}
                  </Box>
                </DropzoneComponent>
              </Grid>

              <Grid container xs={12} md={8} item spacing={2} mt={2}>
                {newLabels.map((field, index) => (
                  <Grid key={index} item md={4} xs={12}>
                    <CustomInput
                      id="AddProduct12"
                      size="small"
                      label={field.label}
                      required={field.name == "productName"}
                      value={customePayload[field.name]}
                      handleChange={(event) =>
                        handleCustomChange(field.name, event.target.value)
                      }
                      inputProps={{
                        maxLength: 50,
                      }}
                      name={field.name}
                      resetError={() => console.log()}
                      fullWidth
                      placeholder={"Enter " + field.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2} px={2} py={2}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Box item pl={2}>
                    <Button
                      id="EditProduct-button-01"
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      onClick={() => {
                        setConfirm(true);
                      }}
                      style={{
                        size: "medium",
                        background:
                          "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        opacity: 1,
                      }}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item>
                    <Button
                      id="EditProduct-button-02"
                      className="text-transform-none"
                      variant="outlined"
                      onClick={() => navigate(REACT_PRODUCT)}
                      size="large"
                    >
                      {t("common.back")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
