import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import ListLayout from "../../components/ListLayout";
import IconHandaler from "../../components/IconHandaler";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import {
  REACT_APPROVAL_FLOW_CHART_ADD,
  REACT_APPROVAL_LIST,
  REACT_APPROVAL_LIST_VIEW,
} from "../../router/ReactEndPoints";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as Tick } from "../../assets/images/tick-circle.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { useSnackbar } from "notistack";
import {
  getPriceChangeEventsApprovalFlowChartListApproveList,
  chnageStatus,
} from "../../services/ApprovalService";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";

export default function ApprovalList() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [id, setId] = useState("");
  const [priceChangeEventId, setPriceChangeEventId] = useState("");
  const [status, setStatus] = useState("");
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  const retailClient = localStorage.getItem("RETAIL_CLIENT");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  const user = JSON.parse(localStorage.getItem("USER"));
  let userId = "";
  if (roleName === "OWNER" && retailClient !== "") {
    userId = retailClient;
  } else {
    userId = user.id;
  }
  const [filters, setFilters] = useState(
    {
      pageNumber: 0,
      pageSize: 8,
      loggedId: userId,
    },
    [user]
  );

  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
    loggedId: userId,
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  useEffect(() => {
    if (roleName === "OWNER") {
      setFilters({
        ...filters,
        loggedId: localStorage.getItem("RETAIL_CLIENT"),
      });
    } else {
      setFilters({
        ...filters,
        loggedId: user.id,
      });
    }
  }, []);

  const loadData = (request) => {
    getPriceChangeEventsApprovalFlowChartListApproveList(request).then(
      (res) => {
        if (res?.data?.code === "LVLI0011" && res?.data?.data) {
          const filteredRecords = res.data.data.objects.filter(
            (record) => record.approvalStatus === "pending"
          );
          setRecords(filteredRecords);
          setTotalRecords(res?.data?.data?.totalCount);
          setPage(res?.data?.data?.currentPage);
          setRowsPerPage(res?.data?.data?.pageSize);
        } else {
          setRecords([]);
        }
      }
    );
  };

  useEffect(() => {
    if (filters.loggedId !== "") {
      loadData(filters);
    }
  }, [filters]);

  const handleStatusChange = () => {
    let request = {
      id: priceChangeEventId,
      approvalStatus: status,
    };
    chnageStatus(id, request).then((res) => {
      if (res?.data?.code === "LVLI0003") {
        enqueueSnackbar(t("tips_approval.updated"), { variant: "success" });
        navigate(REACT_APPROVAL_LIST);
        setIsChanged(true);
        setConfirm(false);
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
        setConfirm(false);
      }

      const user = JSON.parse(localStorage.getItem("USER"));
      if (JSON.parse(localStorage.getItem("USER")) !== null) {
        setFilters({
          ...filters,
          loggedId: user.id,
        });
      }
      loadData(filters);
    });
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddApprovalList");
    isAllowedView = permission && permission.includes("ViewApprovalList");
    isAllowedEdit = permission && permission.includes("EditApprovalList");
    isAllowedDelete = permission && permission.includes("DeleteApprovalList");
  }

  const getFormatedDate = (date) => {
    const formatedDate = date ? moment(date).format(dateTimeFormat) : "";
    return formatedDate;
  };

  const columns = [
    {
      field: "eventName",
      headerName: `${t("approval.approvalEvent")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.eventName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.eventName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "Maker",
      headerName: `${t("approval.maker")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.createdByName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.createdByName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "personName",
      headerName: `${t("table_approval.createtime")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            title={getFormatedDate(e.row.createdAt)}
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(getFormatedDate(e?.row?.createdAt))}
            </span>
          </Tooltip>
        </>
      ),
    },

    {
      headerName: `${t("common.actions")}`,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <IconHandaler>
          {/* {isAllowedView && (
            <Tooltip title={t("common.view")} arrow>
              <VisibilityIcon
                onClick={() => handleActions("View", e.row)}
                style={{ alignSelf: "center", paddingTop: "0px", cursor: "pointer", opacity: '0.6', height: '17px', width: '20px', padding: "2px" }}
              />
            </Tooltip>
          )}*/}
          {e.row.approvalStatus !== "Approved" &&
            e.row.approvalStatus !== "Rejected" && (
              <>
                <Tooltip title={t("approval.approve")} sx={{ marginLeft: 1 }}>
                  <Tick
                    onClick={() => handleActions("Approve", e.row)}
                    style={{
                      alignSelf: "center",
                      paddingTop: "0px",
                      cursor: "pointer",
                      opacity: "0.6",
                      height: "17px",
                      width: "20px",
                    }}
                  />
                </Tooltip>

                <Tooltip title={t("approval.reject")} sx={{ marginLeft: 1 }}>
                  <Close
                    onClick={() => handleActions("Reject", e.row)}
                    style={{
                      alignSelf: "center",
                      paddingTop: "0px",
                      cursor: "pointer",
                      opacity: "0.6",
                      height: "20px",
                      width: "20px",
                      padding: "2px",
                    }}
                  />
                </Tooltip>
              </>
            )}
        </IconHandaler>
      ),
    },
  ];

  const handleActions = (action, data) => {
    {
      /*  if (action === "View") {
      navigate(REACT_APPROVAL_LIST_VIEW, { state: { data: data.priceChangeEventId } });
    }*/
    }
    if (action === "Approve") {
      setId(data.id);
      setPriceChangeEventId(data.priceChangeEventId);
      setConfirm(true);
      setStatus("Approved");
    }
    if (action === "Reject") {
      setId(data.id);
      setPriceChangeEventId(data.priceChangeEventId);
      setConfirm(true);
      setStatus("Rejected");
    }
  };

  const toolbarProps = {
    // add: true,
    // add: isAllowedCreate,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_APPROVAL_FLOW_CHART_ADD);
    },
    onRefresh: (data) => {
      const user = JSON.parse(localStorage.getItem("USER"));
      if (JSON.parse(localStorage.getItem("USER")) !== null) {
        setFilters({
          ...filters,
          loggedId: user.id,
        });
      }
      loadData(filters);
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  const handlePageChange = (e) => {
    setFilters({
      ...defaultFilters,
      pageSize: defaultFilters.pageSize,
      pageNumber: e,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  return (
    <ListLayout title={t("approval.approvalList")} toolbarProps={toolbarProps}>
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onSelection={() => console.log()}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
      />
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="subtitle2">
            {t("tips_approval.change_status")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStatusChange()}
            >
              {t("common.yes")}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
