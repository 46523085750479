import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getScreensById } from "../../services/ScreenService";
import { REACT_DEVICE_SCREENS } from "../../router/ReactEndPoints";
import {
  findProductById,
  getAllProducts,
  attributeList,
} from "../../services/ProductService";
import {
  findTemplateByTypeAndResolution,
  getDownloadUrl,
  getPreAssignedUrl,
  syncScreens,
} from "../../services/TemplateService";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import axios from "axios";
import CommonUtil from "../../util/CommonUtils";
import html2canvas from "html2canvas";
import PreView from "../editor/PreView";

export default function BindDataToScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [screens, setScreens] = useState([]);
  const [screenObjects, setScreenObjects] = useState([]);
  const [screenIds, setScreenIds] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState();
  const [templates, setTemplates] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState([]);
  const [template, setTemplate] = useState();
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [displayTemplates, setDisplayTemplates] = useState([]);
  const [objectKey, setObjectKey] = useState();
  const [customPayload, setCustomPayload] = useState({});

  const [payload, setPayload] = useState({
    screenData: {},
    eslId: "",
    url: "",
    orientation: "0",
    type: "Immediate",
  });

  const [tempJSON, settempJSON] = useState(null);

  const handleTemplateChange = (e, v) => {
    if (v?.templateJson) {
      settempJSON(JSON.parse(v.templateJson));
    } else {
      settempJSON(null);
    }

    setDisplayTemplates(v ? [v] : []);
    const selectedIdsAndResolutions = v ? { [v.id]: v?.screenResolution } : {};
    setSelectedTemplates([selectedIdsAndResolutions]);
  };

  useEffect(() => {
    findProductById(objectKey).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setCustomPayload(res.data.data.object);
      }
    });
  }, [objectKey]);

  useEffect(() => {
    const data = localStorage.getItem("screenIds");
    setScreenObjects(JSON.parse(data));

    getAllProducts().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setProducts(res?.data?.data);
      }
    });
  }, []);

  const loadDataTemplate = (templateFilter) => {
    findTemplateByTypeAndResolution(templateFilter).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setTemplates(res.data.data.templateData);
        setDownloadUrls(res?.data?.data?.downloadUrlData);
      } else if (res?.data?.code === "LVLE0054") {
        setTemplates([]);
      }
    });
  };

  useEffect(() => {
    if (screenObjects?.length > 0) {
      const screenIds = screenObjects?.map((obj) => Object.keys(obj)[0]);
      const resolutionsSet = new Set(
        screenObjects.map((obj) => Object.values(obj)[0])
      );
      const uniqueResolutions = Array.from(resolutionsSet);
      setScreenIds(screenIds);
      setResolutions(uniqueResolutions);
    }
  }, [screenObjects]);

  useEffect(() => {
    if (resolutions.length > 0) {
      loadDataTemplate({ screenResolution: resolutions });
    }
  }, [resolutions]);

  useEffect(() => {
    getScreensById(screenIds).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setScreens(res?.data?.data);
      }
    });
  }, [screenIds]);

  const [url, setUrl] = useState();
  useEffect(() => {
    if (template) {
      const displayUrl = downloadUrls?.find(
        (url) => url?.objectKey === template?.templateObjectKey
      );
      setUrl(displayUrl?.downloadUrl);
    }
  }, [template]);

  const [bindProductPayload, setBindProductPayload] = useState({
    productId: "",
    productBind: [],
  });

  let bindPayload = [];
  const handleUpdate = (templateId, screenId, url) => {
    let request = {
      eslId: screenId,
      url: url,
      templateId: templateId,
      deviceId: screens[0]?.deviceId,
    };

    bindPayload.push(request);
  };

  const blobToDataURI = (blob, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (e) {
      callback(e.target.result);
    };
  };

  const handleSubmits = async (saveCallback) => {
    if (selectedTemplates.length > 0) {
      for (let template of selectedTemplates) {
        const templateObject = template;
        const templateId = Object.keys(templateObject)[0];
        const screenResolution = Object.values(templateObject)[0];
        let matchingIds = [];
        matchingIds = screenObjects
          ?.filter((obj) => Object.values(obj)[0] === screenResolution)
          .map((obj) => Object.keys(obj)[0]);
        setScreenIds(matchingIds);
        const element = document.getElementById("preViewId");
        if (element) {
          const width = parseInt(tempJSON.width, 10);
          const height = parseInt(tempJSON.height, 10);
          // 创建自定义 canvas 元素
          const canvas = document.createElement("canvas");
          let ctx1 = canvas.getContext("2d");
          ctx1.antialias = "none";
          ctx1.imageSmoothingEnabled = false;
          ctx1.textDrawingMode = "glyph";
          //设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
          canvas.width = width;
          canvas.height = height;
          html2canvas(element, {
            canvas,
            scale: 1,
            useCORS: true,
            allowTaint: true,
            // dpi: 600, // 处理模糊问题
          }).then(async (borderedCanvas) => {
            let ctx1 = borderedCanvas.getContext("2d");
            ctx1.antialias = "none";
            ctx1.imageSmoothingEnabled = false;
            ctx1.textDrawingMode = "glyph";
            borderedCanvas.style.imageRendering = "auto";

            const dataUrl = borderedCanvas.toDataURL("image/png");
            const byteString = atob(dataUrl.split(",")[1]);
            const mimeString = dataUrl
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uintArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
              uintArray[i] = byteString.charCodeAt(i);
            }
            let productName =
              localStorage.getItem("productName") || product?.name;
            const blob = new Blob([arrayBuffer], { type: mimeString });

            blobToDataURI(blob, (result) => {
              console.log("HHHHHHHHHHH", result);
              console.log(
                "%c+",
                `font-size: 1px;
        padding: 100px 100px;
        background-image: url(` +
                  result +
                  `);
        background-size: contain;
        background-repeat: no-repeat;
        color: transparent;`
              );
            });

            let file = new File([blob], productName + ".png", {
              type: "image/png",
            });

            const res = await getPreAssignedUrl(productName);
            if (
              res?.data?.code === "LVLI0000" &&
              res?.data?.data &&
              !CommonUtil.isEmpty(res.data.data.preSignedUrl)
            ) {
              const bodyFormData = new FormData();
              bodyFormData.append("url", res?.data?.data?.preSignedUrl);
              bodyFormData.append("file", file);

              const respose = await axios({
                method: "post",
                url:
                  process.env.REACT_APP_SERVER_URL +
                  "/web/template/upload_picture",
                data: bodyFormData,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: localStorage.getItem("USER_TOKEN"),
                },
              });

              //  && !CommonUtil.isEmpty(res.data.data.objectKey)
              if (respose?.data && respose?.data?.code === "LVLI0000") {
                const res1 = await getDownloadUrl(res.data.data.objectKey);

                if (
                  res1?.data?.data &&
                  res1?.data?.code === "LVLI0000" &&
                  !CommonUtil.isEmpty(res1.data.data.downloadUrl)
                ) {
                  const url = res1.data.data.downloadUrl;

                  if (matchingIds.length > 0) {
                    for (const screenId of matchingIds) {
                      handleUpdate(templateId, screenId, url);
                    }
                    saveCallback();
                  }
                }
              }
            }
          });
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (CommonUtil.isEmpty(product)) {
      enqueueSnackbar("Product Should not be Empty", { variant: "error" });
      return;
    } else if (selectedTemplates.length === 0) {
      enqueueSnackbar("Template Should not be Empty", { variant: "error" });
      return;
    } else {
      const callback = () => {
        let request = {
          ...bindProductPayload,
          productBind: bindPayload,
          productId: product?.id,
          productName: product?.name,
          clientId: product?.clientId,
        };

        syncScreens(request).then((res) => {
          if (res?.data?.code === "LVLI0000") {
            enqueueSnackbar(t("tips_screens.screens_sync_success"), {
              variant: "success",
            });
            navigate(REACT_DEVICE_SCREENS);
          } else if (res?.data?.code === "EPTE0050") {
            enqueueSnackbar(t("tips_screens.template_not_empty"), {
              variant: "error",
            });
          } else if (res?.data?.code === "EPTE0017") {
            enqueueSnackbar(t("tips_screens.product_not_empty"), {
              variant: "error",
            });
          } else if (res?.data?.code !== "LVLI0000") {
            enqueueSnackbar(res?.data?.message, { variant: "error" });
          }
        });
      };
      await handleSubmits(callback);
    }
  };

  useEffect(() => {
    if (!customPayload) {
      settempJSON(null);
    } else {
      if (tempJSON) {
        let newJSON = JSON.parse(JSON.stringify(tempJSON));

        newJSON?.componentList?.forEach((item) => {
          if (customPayload?.hasOwnProperty(item.type)) {
            // 确保值的赋予正确
            if (item.type === "qrCode" || item.type === "barCode") {
              item.value = customPayload[item.type];
              item.text = customPayload[item.type];
            } else {
              item.value = customPayload[item.type];
            }
          }
        });

        settempJSON(newJSON);
      }
    }
  }, [selectedTemplates, customPayload]);

  return (
    <RightViewLayout
      title={t("screen.bind_to_screen")}
      navigateBack={REACT_DEVICE_SCREENS}
    >
      <Card elevation={0}>
        <CardContent>
          <Box>
            <Typography>{t("tips_screens.selected_screen")}</Typography>
            <Grid container pt={3}>
              {screens.map((screen) => (
                <Grid
                  item
                  lg={3}
                  m={1}
                  key={screen?.id}
                  style={{ backgroundColor: "#F7FBFE" }}
                  p={2}
                >
                  <Typography>
                    Model: <span>{screen?.name}</span>
                  </Typography>
                  <Typography color={"#474B4F"} fontSize={"16px"}>
                    Inc Screen ID: <span>{screen?.id}</span>
                  </Typography>
                  <Typography color={"#474B4F"} fontSize={"16px"}>
                    Position No: <span>{screen?.positionNo}</span>
                  </Typography>
                  <Typography color={"#474B4F"} fontSize={"16px"}>
                    Resolution: <span>{screen?.resolution}</span>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box pt={3}>
            <Grid container columnSpacing={2}>
              <Grid item lg={6}>
                <Typography>
                  {t("tips_screens.choose_Product_display")}
                </Typography>
                <Autocomplete
                  noOptionsText={t("tips.no_options")}
                  options={products}
                  value={product}
                  onChange={(e, v) => {
                    setProduct(v);
                    setObjectKey(v?.id);
                    setPayload({
                      ...payload,
                      productId: v?.id,
                    });
                  }}
                  getOptionLabel={(option) => (option ? option?.name : "")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Grid>
              <Grid item lg={6}>
                <Typography>
                  {t("tips_screens.choose_Template_display")}
                </Typography>
                <Autocomplete
                  // multiple
                  noOptionsText={t("tips.no_options")}
                  options={templates || []}
                  value={template}
                  disableCloseOnSelect
                  onChange={handleTemplateChange}
                  getOptionLabel={(option) =>
                    option ? `${option.name} - ${option.screenResolution}` : ""
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2} pb={2}>
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Box item pl={2}>
                  <Button
                    id="AddAuthorizationLevel-button-01"
                    variant="contained"
                    size="large"
                    className="text-transform-none"
                    onClick={handleSubmit}
                    style={{
                      size: "medium",
                      borderRadius: "8px",
                      opacity: 1,
                      background:
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    {t("common.save")}
                  </Button>
                </Box>
                <Box item>
                  <Button
                    id="AddAuthorizationLevel-button-02"
                    className="text-transform-none"
                    variant="outlined"
                    onClick={() => navigate(REACT_DEVICE_SCREENS)}
                    size="large"
                  >
                    {t("common.back")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid
        sx={{
          padding: "20px",
          display: "flex",
          marginTop: "20px",
          marginLeft: "20px",
          // justifyContent: "center",
        }}
      >
        {tempJSON && (
          <div>
            <PreView layoutJSON={tempJSON}> </PreView>
          </div>
        )}
      </Grid>
    </RightViewLayout>
  );
}
