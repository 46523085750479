import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SidebarMenuItem(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    navigate(props.link);
    setIsOpen(!isOpen);
    props.onToggle && props.onToggle(!isOpen);
  };

  useEffect(() => {
    setIsOpen(props.isActive);
  }, [props.isActive]);

  return (
    <Box
      fullWidth
      sx={{
        m: { xs: 0, md: 1 },
        height: { xs: "100%", md: "auto" },
        py: { xs: 1.5, md: 0 },
        px: { xs: 0.5, md: 0 },
      }}
    >
      {props.isActive && (
        <>
          <Box
            id="SidebarMenuItem-box-1"
            component={Paper}
            variant="none"
            elevation={3}
            display={"flex"}
            alignItems={"center"}
            bgcolor={""}
            borderRadius={"0"}
            style={{
              cursor: "pointer",
              width: 245,
              height: 50,
              borderLeft: "4px solid #78BC27",
              borderRadius: "8px", // Adjusted to use borderRadius instead of size
              opacity: 1,
              background: "linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
            }}
            onClick={() => handleClick()}
          >
            <Box px={1} py={0}>
              <props.activeMenu />
            </Box>
            <Box
              flexGrow={1}
              sx={{ display: { xs: "none", md: "flex" }, pl: 2 }}
            >
              <Typography
                variant="menuItem"
                color={"#FFFFFF"}
                sx={{
                  display: {
                    xs: "none",
                    md: "inline",
                    font: "normal normal normal 15px/22px Roboto",
                    letterSpacing: "0px",
                    textTransform: "capitalize",
                    opacity: "1",
                  },
                }}
              >
                {props.label}
              </Typography>
            </Box>
            <Box py={0.3} px={1} sx={{ display: { xs: "none", md: "flex" } }}>
              <Typography variant="menuItem">
                {props.children &&
                  (isOpen ? (
                    <ExpandLessIcon
                      style={{ color: "#FFFFFF", fontSize: "1.2rem" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      style={{ color: "#FFFFFF", fontSize: "1.2rem" }}
                    />
                  ))}
              </Typography>
            </Box>
          </Box>
          {isOpen && props.children}
        </>
      )}
      {!props.isActive && (
        <Box
          id="SidebarMenuItem-box-2"
          display={"flex"}
          alignItems={"center"}
          style={{ cursor: "pointer" }}
          onClick={() => handleClick()}
        >
          <Box px={1} py={0}>
            <props.inActiveMenu />
          </Box>
          <Box flexGrow={1} sx={{ display: { xs: "none", md: "flex" }, pl: 2 }}>
            <Typography
              variant="menuItem"
              sx={{
                display: {
                  xs: "none",
                  md: "inline",
                  font: "normal normal normal 15px/22px Roboto",
                  letterSpacing: "0px",
                  textTransform: "capitalize",
                  opacity: "1",
                },
              }}
            >
              {props.label}
            </Typography>
          </Box>
          <Box py={0.3} px={1} sx={{ display: { xs: "none", md: "flex" } }}>
            <Typography variant="menuItem">
              {props.children &&
                (isOpen ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                ))}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
