import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);
export const useStyles = makeStyles((theme) => ({
  gradientButton: {
    background:
      "linear-gradient(to right, #67B26F 0%, #4ca2cd  51%, #67B26F  100%)", // 定义渐变色样式
    borderRadius: 10,
    border: 0,
    color: "white",
    height: 60,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
}));

export const allMonth = [
  { id: 1, value: "January" },
  { id: 2, value: "February" },
  { id: 3, value: "March" },
  { id: 4, value: "April" },
  { id: 5, value: "May" },
  { id: 6, value: "June" },
  { id: 7, value: "July" },
  { id: 8, value: "August" },
  { id: 9, value: "September" },
  { id: 10, value: "October" },
  { id: 11, value: "November" },
  { id: 12, value: "December" },
];

export const convertTimeToFormat = (time) => {
  const hour = dayjs(time).hour().toString().padStart(2, "0");
  const minute = dayjs(time).minute().toString().padStart(2, "0");
  const sec = dayjs(time).second().toString().padStart(2, "0");
  return `${hour}:${minute}:${sec}`;
};

export const convert12to24 = (time12Hour) => {
  return dayjs(time12Hour, "hh:mma").format("HH:mm:ss");
};

export const generatePromotionTime = (startDay, endDay, startTime, endTime) => {
  const startFormattedTime = convert12to24(startTime);
  const endFormattedTime = convert12to24(endTime);

  const StartPromotion =
    startDay && startTime ? `${startDay} ${startFormattedTime}` : null;
  const EndPromotion =
    endDay && endTime ? `${endDay} ${endFormattedTime}` : null;
  return {
    StartPromotion,
    EndPromotion,
  };
};

export const getFutureYears = (startDayDate, endDayDate) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  if (startDayDate === null && endDayDate === null) {
    years.push({ id: 1, value: currentYear });
  }
  if (startDayDate !== null && endDayDate === null) {
    years.push({ id: 1, value: new Date(startDayDate).getFullYear() });
  } else {
    // 循环遍历开始日期到结束日期之间的每一年
    for (
      let year =
        startDayDate === null
          ? new Date().getFullYear()
          : new Date(startDayDate).getFullYear();
      year <= new Date(endDayDate).getFullYear();
      year++
    ) {
      years.push({ id: year, value: year });
    }
  }

  return years;
};

// 在单独的 JavaScript 文件 daysData.js 中
export const getFormattedDaysArray = (selectedMonth, selectedYears) => {
  const thirtyDayMonths = [
    "April",
    "June",
    "September",
    "November",
    "February",
  ];

  // check leap year
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  let daysInMonth = 31; // Default to 31 days

  if (selectedMonth?.length === 1 && selectedMonth.includes("February")) {
    if (selectedYears.length === 1) {
      daysInMonth = isLeapYear(selectedYears[0]) ? 29 : 28;
    } else {
      daysInMonth = 29;
    }
  } else if (
    selectedMonth?.length === 1 &&
    thirtyDayMonths.includes(selectedMonth[0])
  ) {
    daysInMonth = 30;
  } else if (
    selectedMonth?.length > 1 &&
    selectedMonth.length <= 5 &&
    selectedMonth.every((month) => thirtyDayMonths.includes(month))
  ) {
    daysInMonth = 30;
  }

  return Array.from(
    {
      length: daysInMonth,
    },
    (_, i) => i + 1
  ).map((day) => {
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }
    return {
      id: day,
      value: `${day}${suffix}`,
    };
  });
};

export const numberToDate = (dates) => {
  return dates?.map((day) => {
    day = Number.parseInt(day);
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }
    return `${day}${suffix}`;
  });
};

/**
 * 全选、反选年、月、日方法
 */
export const handlerAllCheckYears = (
  event,
  setSelectedYears,
  setIsChecked,
  years,
  addFormik
) => {
  if (event.target.checked) {
    const allYearValues = years.map((item) => item.value);
    setSelectedYears(allYearValues);
    setIsChecked(true);
    addFormik.setFieldValue("years", allYearValues);
  } else {
    setSelectedYears([]);
    setIsChecked(false);
  }
};

export const handlerAllCheckMonth = (
  event,
  setSelectedMonth,
  setIsCheckedMonth,
  allMonth,
  addFormik
) => {
  if (event.target.checked) {
    const allMonthsValues = allMonth.map((item) => item.value);
    setSelectedMonth(allMonthsValues);
    setIsCheckedMonth(true);
    addFormik.setFieldValue("months", allMonthsValues);
  } else {
    setSelectedMonth([]);
    setIsCheckedMonth(false);
  }
};

export const handlerAllCheckDay = (
  event,
  setSelectedDates,
  setIsChecked,
  formattedDaysArray,
  addFormik
) => {
  if (event.target.checked) {
    const allYearValues = formattedDaysArray.map((item) => item.value);
    setSelectedDates(allYearValues);
    setIsChecked(true);
    addFormik.setFieldValue("dates", allYearValues);
  } else {
    setSelectedDates([]);
    setIsChecked(false);
  }
};

/**
 * 以下是，选择年、月、日的方法
 */
export const handleYearChange = (
  year,
  selectedYears = [], // 使用默认值
  setSelectedYears,
  addFormik,
  years,
  setIsChecked
) => {
  const yearsArray = Array.isArray(selectedYears) ? selectedYears : [];
  const newSelectedYears = yearsArray.includes(year)
    ? yearsArray.filter((y) => y !== year)
    : [...yearsArray, year];

  const allSelected = years.length === newSelectedYears.length;
  setIsChecked(allSelected);
  setSelectedYears(newSelectedYears);
  addFormik.setFieldValue("years", newSelectedYears);
};

export const handlerMonth = (
  month,
  selectedMonth,
  setSelectedMonth,
  allMonth,
  addFormik,
  setIsCheckedMonth
) => {
  const monthsArray = Array.isArray(selectedMonth) ? selectedMonth : [];
  const newSelectedMonth = monthsArray?.includes(month)
    ? monthsArray.filter((y) => y !== month)
    : [...monthsArray, month];
  const allSelected =
    allMonth.length === newSelectedMonth.length ? true : false;
  setIsCheckedMonth(allSelected);
  setSelectedMonth(newSelectedMonth);
  addFormik.setFieldValue("months", newSelectedMonth);
};

export const handleDayChange = (
  day,
  selectedDates,
  setSelectedDates,
  addFormik,
  setIsChecked,
  formattedDaysArray
) => {
  const daysArray = Array.isArray(selectedDates) ? selectedDates : [];
  const newSelectedDates = daysArray.includes(day)
    ? daysArray.filter((d) => d !== day)
    : [...daysArray, day];
  const allSelected =
    formattedDaysArray.length === newSelectedDates.length ? true : false;
  setIsChecked(allSelected);
  setSelectedDates(newSelectedDates);
  addFormik.setFieldValue("dates", newSelectedDates);
};

/**
 *
 * @param {拆分时间格式方法} dateTimeStr
 * @returns
 */

export const splitDateTime = (dateTimeStr) => {
  // 创建一个新的日期对象
  const endDate = new Date(dateTimeStr);

  // 获取年月日
  const year = endDate.getFullYear();
  const month = endDate.getMonth() + 1; // 月份是从0开始计数的，所以要加1
  const day = endDate.getDate();

  // 获取时分秒
  let hours = endDate.getHours();
  const minutes = endDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM"; // 判断是上午还是下午
  hours = hours % 12;
  hours = hours ? hours : 12; // 将0小时转换为12小时

  // 设置起始日期和时间
  const setStartDayDate = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
  const setStartTimeDate = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${ampm}`;

  return {
    date: setStartDayDate,
    time: setStartTimeDate,
  };
};

export const formatDateTime = (timestamp) => {
  const dateObj = new Date(timestamp);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const date = dateObj.getDate().toString().padStart(2, "0");
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const seconds = dateObj.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};

export const formatTime = (originalTime) => {
  // 解析原始时间字符串并格式化为目标格式
  const formattedTime = dayjs(
    originalTime,
    "ddd, DD MMM YYYY HH:mm:ss [GMT] hh:mm"
  ).format("YYYY-MM-DDTHH:mm:ssZ");

  return formattedTime;
};

export const isFormatMatching = (timeString) => {
  const formatString = "ddd, DD MMM YYYY HH:mm:ss [GMT] hh:mm";
  // 尝试解析时间字符串
  const parsedTime = dayjs(timeString, formatString); // 第三个参数 true 表示严格模式

  // 检查解析结果是否有效
  if (parsedTime.isValid()) {
    return true; // 时间格式匹配
  } else {
    return false; // 时间格式不匹配
  }
};

// 判断结束时间不能早于开始时间
export const isEndDateValid = (startDayDate, endDayDate) => {
  // 使用 dayjs 解析日期字符串
  const start = dayjs(startDayDate);
  const end = dayjs(endDayDate);

  // 比较开始时间和结束时间
  if (end.isBefore(start)) {
    return false; // 结束时间早于开始时间，无效
  } else {
    return true; // 结束时间晚于或等于开始时间，有效
  }
};

/**
 *
 * @param {循环遍历时间段内的时间分别的周几} startDayDate
 * @param {*} endDayDate
 * @returns
 */

export const generateDaysOfWeek = (startDayDate, endDayDate) => {
  // 初始化一个数组，用于存放每一天的日期和星期几
  const daysOfWeek = [];

  // 使用 dayjs 解析开始日期和结束日期
  let currentDate = dayjs(startDayDate);
  const endDate = dayjs(endDayDate);

  // 循环遍历开始日期到结束日期的每一天
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    // 获取当前日期是星期几
    const dayOfWeek = currentDate.day(); // 0 表示星期天，1 表示星期一，依此类推

    // 将日期和星期几信息存入数组
    daysOfWeek.push({
      date: currentDate.format("YYYY-MM-DD"),
      dayOfWeek: dayOfWeek,
      dayOfWeekString: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][dayOfWeek],
    });

    // 增加一天，继续下一轮循环
    currentDate = currentDate.add(1, "day");
  }

  // 返回生成的日期和星期几数组
  return daysOfWeek;
};
