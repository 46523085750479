import { Box, Grid, Typography, Button } from "@mui/material";
import TimeDiaLog from "./TImeDialog";
import { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SelectStoreDialog from "./SelectStoreDialog";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
function Header(props) {
  const { t } = useTranslation();
  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    loadBoardData,
    setOutletID,
  } = props;
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOPen] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);
  const [selectStore, setSelectStore] = useState(null);

  const boxStyle = {
    width: "290px",
    minHeight: "65px",
    flexGrow: 1,
    borderRadius: "8px",
    padding: "0.2rem 0.4rem",
    background: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid transparent", // 设置边框以使阴影可见
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1)", // 边框阴影
  };

  const [displayStartTime, setDisplayStartTime] = useState(null);
  const [displayEndTime, setDisplayEndTime] = useState(null);

  useEffect(() => {
    setDisplayStartTime(dayjs(startTime).format("MMM, DD YYYY"));
    setDisplayEndTime(dayjs(endTime).format("MMM, DD YYYY"));
  }, [startTime, endTime]);

  return (
    <Grid>
      <Grid display={"flex"} container spacing={2}>
        <Grid
          borderRadius={"15px"}
          sx={boxStyle}
          item
          onClick={() => setStartTimeOpen(true)}
          ml={3.2}
        >
          <Grid>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(121,121,121,1)",
                whiteSpace: "nowrap",
              }}
            >
              {t("dashboard.period_from_date")}
            </Typography>

            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "900",
                color: "rgba(14,14,14,1)",
                whiteSpace: "nowrap",
                marginTop: "10px",
              }}
            >
              {startTime ? displayStartTime : t("common.start_date")}
            </Typography>
          </Grid>

          <Box>{startTimeOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
        </Grid>

        <Grid
          borderRadius={"15px"}
          sx={boxStyle}
          item
          onClick={() => setEndTimeOPen(true)}
          ml={1}
        >
          <Grid>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(121,121,121,1)",
                whiteSpace: "nowrap",
              }}
            >
              {t("dashboard.period_to_date")}
            </Typography>

            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "900",
                color: "rgba(14,14,14,1)",
                whiteSpace: "nowrap",
                marginTop: "10px",
              }}
            >
              {endTime ? displayEndTime : t("common.end_date")}
            </Typography>
          </Grid>

          <Box>{startTimeOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
        </Grid>

        <Grid
          borderRadius={"15px"}
          sx={boxStyle}
          item
          ml={1}
          onClick={() => setStoreOpen(true)}
        >
          <Grid>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(121,121,121,1)",
                whiteSpace: "nowrap",
              }}
            >
              {t("dashboard.store")}
            </Typography>

            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "900",
                color: "rgba(14,14,14,1)",
                whiteSpace: "nowrap",
                marginTop: "10px",
              }}
            >
              {selectStore ? selectStore : t("dashboard.select_store")}
            </Typography>
          </Grid>

          <Box>{storeOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
        </Grid>

        <Button
          variant="contained"
          onClick={() => loadBoardData()}
          sx={{
            height: "75px",
            width: "120px",
            borderRadius: "18px",
            fontWeight: 700,
            padding: "0.3rem 0.5rem",
            marginLeft: "20px",
            fontSize: "1rem",
            background: `linear-gradient(to right, #71b652, #3599d7)`,
          }}
        >
          <span
            dangerouslySetInnerHTML={{ __html: t("dashboard.apply_filters") }}
          ></span>
        </Button>
      </Grid>

      <TimeDiaLog
        open={startTimeOpen}
        setOpen={setStartTimeOpen}
        Tiele={t("common.start_date")}
        placeholder={t("common_tips.start_date")}
        date={startTime}
        setDate={setStartTime}
      ></TimeDiaLog>
      <TimeDiaLog
        open={endTimeOpen}
        setOpen={setEndTimeOPen}
        Tiele={t("common.end_date")}
        placeholder={t("common_tips.end_date")}
        date={endTime}
        setDate={setEndTime}
      ></TimeDiaLog>

      <SelectStoreDialog
        open={storeOpen}
        setOpen={setStoreOpen}
        selectStore={selectStore}
        setSelectStore={setSelectStore}
        setOutletID={setOutletID}
      ></SelectStoreDialog>
    </Grid>
  );
}

export default Header;
