import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import OverFlowText from "../../components/OverFlowText";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { REACT_DEVICE_GATEWAY } from "../../router/ReactEndPoints";
import { getDeviceById } from "../../services/DeviceService";
import DeviceIcon from "../../assets/images/icon_device.png";
export default function ViewDevice() {
  const { t } = useTranslation();
  const location = useLocation();
  const [sn, setSn] = useState("");
  const [gatewayName, setGatewayName] = useState("");
  const [gatewayModel, setGatewayModel] = useState("");
  const [gatewayType, setGatewayType] = useState("");
  const [status, setStatus] = useState("");
  const [outlet, setOutlet] = useState("");
  const [outletCode, setOutletCode] = useState("");

  useEffect(() => {
    const id = location?.state?.data;
    if (id) {
      getDeviceById(id).then((res) => {
        if (res.data.code === "DMSI0000") {
          setSn(res.data.data.sn);
          setGatewayName(res.data.data.sn);
          setGatewayType(res.data.data.deviceType);
          setGatewayModel(res.data.data.deviceModel);
          setOutlet(res.data.data.outletName);
          setOutletCode(res.data.data.outletCode);
          setStatus(res.data.data.status);
          // if (res.data.data.status === 0) {
          //     setStatus('Offline')
          // } else {
          //     setStatus('Online')
          // }
        }
      });
    }
  }, [location?.state?.data]);

  return (
    <RightViewLayout
      navigateBack={REACT_DEVICE_GATEWAY}
      title="View Gateway Details"
    >
      <ViewPage>
        <Box mt={2}>
          <img
            alt="complex"
            src={DeviceIcon}
            style={{ height: "60px", width: "60px" }}
          />
        </Box>
        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("gateway.gatewayName")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">{gatewayName}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("gateway.gateway")}:</Typography>
        </Box>
        <Typography variant="fieldValue">{sn}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("gateway.gatewayType")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">{gatewayType}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("gateway.gatewayRunningStatus")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">{status}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("gateway.outlet")}:</Typography>
        </Box>
        <Typography variant="fieldValue">{outlet}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("gateway.gatewayModel")}:
          </Typography>
        </Box>
        <OverFlowText variant="fieldValue" maxLength="100">
          {gatewayModel}
        </OverFlowText>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("gateway.outletCode")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">{outletCode}</Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
