import React, { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ListLayout from "../../../components/ListLayout";
import { useNavigate } from "react-router-dom";
import { REACT_DASHBOARD } from "../../../router/ReactEndPoints";
import { listClients } from "../../../services/CompanyService";
import CommonUtil from "../../../util/CommonUtils";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { getAllCountries } from "../../../services/LocationService";

export default function CustomerSelector(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryForClient, setCountryForClient] = useState({});
  const [filter, toggleFilter] = useState(false);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { client, setClient, setOpen } = props;

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
    type: "RETAIL_CLIENT",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
    type: "RETAIL_CLIENT",
  };

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res.data?.code === "LVLI0000") {
        setCountries(res.data.data);
      }
    });
  }, []);
  useEffect(() => {
    setFilters({ ...filters, country: countryForClient?.id });
  }, [countryForClient]);

  useEffect(() => {
    if (localStorage.getItem("RETAIL_CLIENT") === "") {
      loadData();
    } else {
      navigate(REACT_DASHBOARD);
    }
  }, [filters]);

  const loadData = () => {
    listClients(filters).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRecords(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
      }
    });
  };

  const handleSelection = (data) => {
    setClient(data);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "",
      flex: 0.1,
      renderCell: (params) => (
        <Radio
          checked={client === params.row}
          onChange={() => handleSelection(params.row)}
          value={params.row}
        />
      ),
    },
    {
      field: "name",
      headerName: t("LVLRC0002"),
      flex: 1.5,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t("LVLRC0003"),
      flex: 1.5,
      renderCell: (e) => (
        <Tooltip title={e.row.code} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.code)}</span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: t("LVLRC0004"),
      flex: 1.5,
      renderCell: (e) => (
        <Tooltip title={e.row.email} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: t("LVLDAC0021"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.phone} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.phone)}</span>
        </Tooltip>
      ),
    },
    {
      field: "addressLine1",
      headerName: t("synchronized.address"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.addressLine1} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.addressLine1)}</span>
        </Tooltip>
      ),
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
      operator: "AND",
    });
  };

  useEffect(() => {
    const suggestions = records?.filter(
      (record) =>
        record.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        record.code.toLowerCase().includes(searchValue.toLowerCase())
    );
    // console.log("suggestions : " + suggestions);
    setSearchSuggestions(suggestions);
  }, [searchValue, records]);

  const searchClientProps = () => {
    return (
      <Grid container display={"flex"} justifyContent={"flex-end"} pb={0.5}>
        <Autocomplete
          options={searchSuggestions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("LVLRC0011")}
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                style: {
                  height: 50,
                  width: "300px",
                  background: "#fff",
                  color: "#474B4F",
                  opacity: "0.6",
                  boxShadow: "0px 1px 3px #0000001A",
                  borderRadius: "8px",
                  border: "0px !important",
                  padding: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton id="retailClint" onClick={handleSearchClick}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#D1D1D1",
                },
              }}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <>
      <Grid pb={4}>
        <ListLayout
          navigateBack={false}
          title={" "}
          globalFilterProps={false}
          searchProps={searchClientProps()}
        >
          {filter && (
            <Box>
              <Grid item lg={2} sm={4} xs={12} pr={0.8}>
                <Typography sx={{ fontSize: 13 }}>
                  {t("synchronized.country")}
                </Typography>
                <Autocomplete
                  noOptionsText={t("synchronized.country")}
                  options={countries}
                  value={countryForClient}
                  onChange={(e, v) => {
                    setCountryForClient(v);
                  }}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                ></Autocomplete>
              </Grid>
            </Box>
          )}
          <DataTable
            columns={columns}
            rows={records}
            onSelection={() => console.log()}
            page={filters.pageNumber - 1}
            totalRecords={totalRecords}
            rowsPerPage={filters.pageSize}
            onPageChange={(pn) => handlePageChange(pn)}
            onPageSizeChange={(ps) => handlePageSize(ps)}
          />
        </ListLayout>
      </Grid>
      <Grid container spacing={2} pt={4}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="AddProduct-button-01"
                variant="contained"
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                }}
              >
                {t("common.save")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="AddProduct-button-02"
                className="text-transform-none"
                variant="outlined"
                onClick={() => setOpen(false)}
                size="large"
              >
                {t("common.previous")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
