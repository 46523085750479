import { Grid } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { FormLabel, PrettoSlider } from "../components/PropertiesComponent";
import ColorPick from "../components/ColorPick";
import CustomSelect from "../components/CustomSelect";
import { fontSizeList, fontList, fontWeightList } from "../utils";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CustomInput from "../components/CustomInput";
import { Stack, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
const TextBoxProperties = (props) => {
  const {
    setActiveId,
    activeId,
    current,
    setCurrent,
    layoutJSON,
    setLayoutJSON,
  } = props;
  const { t } = useTranslation();
  const [properties, setProperties] = useState({
    type: "",
    id: "",
    value: "",
    left: 0,
    top: 0,
    right: 0,
    width: 200,
    height: 100,
    hide: false,
    zIndex: 10,
    bgColor: "#ffffff",
    rotate: 0,
    font: "MyriadPro-Light", //字体
    lineHeight: 30, //行高
    fontSize: 12,
    textAlign: "center", //字体位置（居中还是左对齐右对齐等）
    transparency: 1, //透明度
    color: "#000000",
    isProductLabel: true,
    fontWeight: 600,
    wordSpacing: 1,
    isItaly: true, //是否斜体
    textDecoration: "",
    borderRadius: 0,
  });

  useEffect(() => {
    let componentList = JSON.parse(JSON.stringify(layoutJSON.componentList));
    let componentListResult = componentList.filter((element, fIndex) => {
      if (element.id === activeId) {
        return true;
      } else {
        return false;
      }
    });
    if (componentListResult.length === 1) {
      let aa = componentListResult[0];
      setProperties({
        ...properties,
        ...aa,
      });
    }
  }, [activeId, current, layoutJSON]);

  const updateProperties = (newInfo) => {
    setProperties(newInfo);
    let index = "";
    layoutJSON.componentList.forEach((element, fIndex) => {
      if (element.id === newInfo.id) {
        index = fIndex;
      }
    });
    if (index !== "") {
      layoutJSON.componentList[index] = newInfo;
      setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
    }
  };

  const changeProperties = (event) => {
    const name = event.target.name;
    let newInfo = {
      ...properties,
      [name]: event.target.value,
    };
    updateProperties(newInfo);
  };

  const handleSliderLineHeightChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      lineHeight: event.target.value,
    };
    updateProperties(newInfo);
  };

  const handleSliderRadiusChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      borderRadius: event.target.value,
    };
    updateProperties(newInfo);
  };

  const handleSliderWordSpacingChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      wordSpacing: event.target.value,
    };
    updateProperties(newInfo);
  };

  const setTextAlign = (textAlign) => {
    let newInfo = {
      ...properties,
      textAlign: textAlign,
    };
    updateProperties(newInfo);
  };

  const handleClickStyle = (name) => {
    let newInfo = {
      ...properties,
      [name]: !properties[name],
    };
    updateProperties(newInfo);
  };

  const handleRotationChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      rotate: newValue,
    };
    updateProperties(newInfo);
  };

  const handleClickTextDecoration = (name) => {
    if (properties.textDecoration === name) {
      name = "none";
    }
    let newInfo = {
      ...properties,
      textDecoration: name,
    };
    updateProperties(newInfo);
  };
  return (
    <Grid
      sx={{
        height: "100%",
        overflow: "auto",
        p: 1.5,
      }}
    >
      <Grid>
        <InputLabel
          sx={{
            color: "#707070",
            fontSize: "14px",
            mr: 2,
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
        >
          {t("editor.content_example")}
        </InputLabel>
        <CustomInput
          label=""
          value={properties.value}
          onChange={changeProperties}
          name="value"
        ></CustomInput>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.bgcolor")}</FormLabel>
        <ColorPick
          value={properties.bgColor}
          name="bgColor"
          onChange={changeProperties}
        ></ColorPick>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.font_color")}</FormLabel>
        <ColorPick
          value={properties.color}
          name="color"
          onChange={changeProperties}
        ></ColorPick>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <CustomSelect
          label={t("editor.font_size")}
          name="fontSize"
          onChange={changeProperties}
          value={properties.fontSize}
          items={fontSizeList}
        ></CustomSelect>
      </Grid>

      <Grid>
        <CustomSelect
          label={t("editor.font")}
          name="font"
          onChange={changeProperties}
          value={properties.font}
          items={fontList}
        ></CustomSelect>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <CustomSelect
          label={t("editor.font_weight")}
          name="fontWeight"
          onChange={changeProperties}
          value={properties.fontWeight}
          items={fontWeightList}
        ></CustomSelect>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 3,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.spacing")}</FormLabel>
        <PrettoSlider
          onChange={handleSliderWordSpacingChange}
          size="small"
          min={1}
          max={50}
          step={1}
          color="secondary"
          value={properties.wordSpacing}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid>

      {/* <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>行高:</FormLabel>
        <PrettoSlider
          onChange={handleSliderLineHeightChange}
          size="small"
          min={10}
          max={500}
          step={1}
          color="secondary"
          value={properties.lineHeight}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid> */}

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 3,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.rounded_corners")}</FormLabel>
        <PrettoSlider
          onChange={handleSliderRadiusChange}
          size="small"
          min={0}
          max={500}
          step={1}
          color="secondary"
          value={properties.borderRadius}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 3,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.line_height")}</FormLabel>
        <PrettoSlider
          onChange={handleSliderLineHeightChange}
          size="small"
          min={1}
          max={500}
          step={1}
          color="secondary"
          value={properties.lineHeight}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid>

      <Grid
        sx={{
          mt: 2,
        }}
      >
        <InputLabel
          sx={{
            color: "#707070",
            fontSize: "14px",
            mr: 2,
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
        >
          {t("editor.level")}
        </InputLabel>
        <CustomInput
          label=""
          type="number"
          value={properties.zIndex}
          onChange={changeProperties}
          name="zIndex"
        ></CustomInput>
      </Grid>

      <Grid sx={{ mt: 2 }}>
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="outlined button group"
        >
          <Button
            variant={
              properties.textAlign === "start" ? "contained" : "outlined"
            }
            onClick={() => {
              setTextAlign("start");
            }}
          >
            <svg
              t="1582712720542"
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1710"
              width="16"
              height="16"
            >
              <path
                d="M624 119.6H64v112h560v-112z m0 448H64v112h560v-112z m224-224H64v112h784v-112z m-784 448v112h896v-112H64z"
                p-id="1711"
              />
            </svg>
          </Button>
          <Button
            variant={
              properties.textAlign === "middle" ? "contained" : "outlined"
            }
            onClick={() => {
              setTextAlign("middle");
            }}
          >
            <svg
              t="1582712739697"
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2052"
              width="16"
              height="16"
            >
              <path
                d="M733.9 120.6H286.4v111.9h447.5V120.6zM789.8 680V568.1H230.5V680h559.3z m56-335.6H174.5v111.9h671.3V344.4zM62.6 791.9v111.9h895V791.9h-895z"
                p-id="2053"
              />
            </svg>
          </Button>
          <Button
            sx={{ p: 1 }}
            variant={properties.textAlign === "end" ? "contained" : "outlined"}
            onClick={() => {
              setTextAlign("end");
            }}
          >
            <svg
              t="1582712868266"
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2426"
              width="16"
              height="16"
            >
              <path
                d="M400 231.6h560v-112H400v112z m0 448h560v-112H400v112z m-224-224h784v-112H176v112z m-112 336v112h896v-112H64z"
                p-id="2427"
              />
            </svg>
          </Button>
        </ButtonGroup>

        <Grid
          sx={{
            mt: 2,
          }}
        >
          <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="outlined button group"
          >
            {/* <Button
              variant={properties.isBold ? 'contained' : 'outlined'}
              onClick={() => {
                handleClickStyle('isBold')
              }}
            >
              B
            </Button> */}

            <Button
              sx={{
                fontStyle: properties.isItaly ? "italic" : "normal",
              }}
              variant={properties.isItaly ? "contained" : "outlined"}
              onClick={() => {
                handleClickStyle("isItaly");
              }}
            >
              I
            </Button>

            <Button
              variant={
                properties.textDecoration === "underline"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                handleClickTextDecoration("underline");
              }}
              sx={{
                textDecoration: "underline",
              }}
            >
              U
            </Button>

            <Button
              variant={
                properties.textDecoration === "line-through"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                handleClickTextDecoration("line-through");
              }}
              sx={{
                textDecoration: "line-through",
              }}
            >
              U
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <FormLabel sx={{ mr: 2 }}>{t("editor.rotation_angle")}</FormLabel>
          <PrettoSlider
            onChange={handleRotationChange}
            size="small"
            min={0}
            max={360}
            step={1}
            color="secondary"
            value={properties.rotate}
            aria-label="Small"
            valueLabelDisplay="on"
          ></PrettoSlider>
        </Grid>

        <CustomInput
          label="X："
          value={properties.left}
          onChange={changeProperties}
          name="left"
        ></CustomInput>

        <CustomInput
          label="Y："
          value={properties.top}
          onChange={changeProperties}
          name="top"
        ></CustomInput>

        <CustomInput
          label={t("editor.width")}
          value={properties.width}
          onChange={changeProperties}
          name="width"
        ></CustomInput>

        <CustomInput
          label={t("editor.height")}
          value={properties.height}
          onChange={changeProperties}
          name="height"
        ></CustomInput>
      </Grid>
    </Grid>
  );
};

export default TextBoxProperties;
