import React, { useEffect, useState } from "react";
import { Grid, Typography, Card } from "@mui/material";
import { getPriceNameConfig } from "../js/config";
import { useTranslation } from "react-i18next";
import CmpFormik from "@z/CmpFormik";
function PriceName(props) {
  const { addFormik, priceNameConfig, setPriceNameConfig } = props;
  const { t } = useTranslation();
  useEffect(() => {
    let configInfo = getPriceNameConfig(t);
    setPriceNameConfig(configInfo);
  }, []);
  return (
    <Grid bgcolor={"#fff"}>
      <Grid pl={10} mr={4} mt={2} pb={2} pr={6}>
        <CmpFormik
          sx={4}
          formik={addFormik}
          formConfig={priceNameConfig}
        ></CmpFormik>
      </Grid>
    </Grid>
  );
}

export default PriceName;
