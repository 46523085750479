export default {
  table: {
    name: "Name:",
    code: "Code:",
    phone: "Phone:",
    email: "E-Mail:",
    first_name: "First Name:",
    last_name: "Last Name:",
    mobile_number: "Mobile Number:",
    city: "City:",
    country: "Country:",
    address: "Address:",
    mobile: "Mobile",
    state: "Province/State",
    country_code: "Country Code",
    company_code: "Company Code",
    company_name: "Company Name",
    app_id: "App Id",
    city_zone: "City Zone",
    area_code: "Area Code",
    time_zone: "Time Zone",
  },

  table_product: {
    product_name: "Product Name",
  },

  table_approval: {
    title: "Approval FlowChart",
    add: "Add Approval FlowChart",
    edit: "Edit Approval FlowChart",
    person: "Approval Person",
    createtime: "CreateTime",
    note: "Note:",
  },
};
