import { Grid } from "@mui/material";
import CmpSelect from "./CmpSelect";
import CmpInput from "./CmpInput";
import CmpSwitch from "./CmpSwitch";
import CmpInputTreeSelect from "./CmpInputTreeSelect";
import CmpTextarea from "./CmpTextarea";
import CmpRangePicker from "./CmpRangePicker";
import CmpAutocomplete from "./CmpAutocomplete";
import CumtomPhoneFiled from "./CumtomPhoneFiled";
import CustomDatePicker from "./CustomDate";
import CustomButton from "./CustomButton";
import CustomSelect from "./CustomSelect";
import CmpRadioGrounp from "./CmpRadioGrounp";
import PersonAutocomplete from "./PersonAutocomplete";
import CustomDatePicker1 from "@p/price/Schedule/CustomDatePicker";
import TimePickerDate from "@p/price/Schedule/TimePickerDate";
import ZKAutocomplete from "@p/price/Schedule/ZKAutocomplete";
import AutocomplateProduct from "@p/price/Schedule/AutocomplateProduct";
const RenderingFromItem = (props) => {
  const {
    config,
    renderingCustomItem = () => {
      return "没有自定义渲染方法";
    },
    formik = null,
    labelPostion = "top",
    defaultBox = (result, item, index) => {
      return (
        <Grid
          key={item.name + index}
          sx={{ color: "red" }}
          item
          xs={item.sx ? item.sx : props.sx}
        >
          {result}
        </Grid>
      );
    },
  } = props;

  const Placeholder = ({ message }) => (
    <div style={{ padding: "20px", textAlign: "center", color: "#999" }}>
      <p>{message}</p>
    </div>
  );

  let formConfig = config?.map((item, index) => {
    const {
      type,
      conditionalRendering,
      width,
      height,
      display = "true",
      ...orther
    } = item;
    orther.labelPostion = labelPostion;
    if (item.custom) {
      if (conditionalRendering) {
        if (conditionalRendering(formik)) {
          return item.renderingCustomItem
            ? item.renderingCustomItem(item, formik)
            : renderingCustomItem(item, formik);
        } else {
          return null;
        }
      } else {
        return item.renderingCustomItem
          ? item.renderingCustomItem(item, formik)
          : renderingCustomItem(item, formik);
      }
    } else {
      if (conditionalRendering) {
        //条件渲染
        if (conditionalRendering(formik)) {
          if (type === "input" && display) {
            let result = (
              <CmpInput key={item.name} formik={formik} {...orther}></CmpInput>
            );
            return defaultBox(result, item);
          } else if (type === "select" && display) {
            let result = (
              <CustomSelect
                key={item.name}
                formik={formik}
                {...orther}
              ></CustomSelect>
            );
            return defaultBox(result, item);
          } else if (type === "selectTree" && display) {
            let result = (
              <CmpInputTreeSelect
                key={item.name}
                formik={formik}
                treeData={item.data}
                valueKey={item.valueKey || "id"}
                labelKey={item.labelKey || "label"}
                selectd={item.selectd}
                {...orther}
              ></CmpInputTreeSelect>
            );
            return defaultBox(result, item);
          } else if (type === "switch" && display) {
            let result = (
              <CmpSwitch
                key={item.name}
                formik={formik}
                {...orther}
              ></CmpSwitch>
            );
            return defaultBox(result, item);
          } else if (type === "textArea" && display) {
            let result = (
              <CmpTextarea
                key={item.name}
                formik={formik}
                {...orther}
              ></CmpTextarea>
            );
            return defaultBox(result, item);
          } else if (type === "dataRange" && display) {
            let result = (
              <CmpRangePicker
                key={type + index}
                formik={formik}
                {...orther}
              ></CmpRangePicker>
            );
            return defaultBox(result, item);
          } else if (type === "autocomplete" && display) {
            let result = (
              <CmpAutocomplete
                key={type + index}
                formik={formik}
                {...orther}
              ></CmpAutocomplete>
            );
            return defaultBox(result, item);
          } else if (type === "selectDate" && display) {
            let result = (
              <CustomDatePicker
                key={type + index}
                formik={formik}
                {...orther}
              ></CustomDatePicker>
            );
            return defaultBox(result, item);
          } else if (type === "button" && display) {
            let result = (
              <CustomButton
                key={type + index}
                formik={formik}
                {...orther}
              ></CustomButton>
            );
            return defaultBox(result, item);
          } else if (type === "radio" && display) {
            let result = (
              <CmpRadioGrounp
                key={type + index}
                formik={formik}
                {...orther}
              ></CmpRadioGrounp>
            );
            return defaultBox(result, item);
          } else if (type === "null" && display) {
            return <Placeholder></Placeholder>;
          }
        } else if (type === "person") {
          let result = (
            <PersonAutocomplete
              key={type + index}
              formik={formik}
              {...orther}
            ></PersonAutocomplete>
          );
          return defaultBox(result, item);
        } else if (type === "timeMonths" && display) {
          let result = (
            <CustomDatePicker1
              key={type + index}
              formik={formik}
              {...orther}
            ></CustomDatePicker1>
          );
          return defaultBox(result, item);
        } else if (type === "timeDay" && display) {
          let result = (
            <TimePickerDate
              key={type + index}
              formik={formik}
              {...orther}
            ></TimePickerDate>
          );
          return defaultBox(result, item);
        } else if (type === "ZKAutocomplete" && display) {
          let result = (
            <ZKAutocomplete
              key={type + index}
              formik={formik}
              {...orther}
            ></ZKAutocomplete>
          );
          return defaultBox(result, item);
        } else if (type === "AutocomplateProduct" && display) {
          let result = (
            <AutocomplateProduct
              key={type + index}
              formik={formik}
              {...orther}
            ></AutocomplateProduct>
          );
          return defaultBox(result, item);
        } else {
          return null;
        }
      } else {
        if (type === "input" && display) {
          let result = (
            <CmpInput
              key={item.name}
              formik={formik}
              width={width}
              height={height}
              {...orther}
            ></CmpInput>
          );
          return defaultBox(result, item);
        } else if (type === "select" && display) {
          let result = (
            <CmpSelect key={item.name} formik={formik} {...orther}></CmpSelect>
          );
          return defaultBox(result, item);
        } else if (type === "selectTree" && display) {
          let result = (
            <CmpInputTreeSelect
              key={item.name}
              formik={formik}
              {...orther}
            ></CmpInputTreeSelect>
          );
          return defaultBox(result, item);
        } else if (type === "switch" && display) {
          let result = (
            <CmpSwitch key={item.name} formik={formik} {...orther}></CmpSwitch>
          );
          return defaultBox(result, item);
        } else if (type === "textArea" && display) {
          let result = (
            <CmpTextarea
              key={item.name}
              formik={formik}
              {...orther}
            ></CmpTextarea>
          );
          return defaultBox(result, item);
        } else if (type === "dataRange" && display) {
          let result = (
            <CmpRangePicker
              key={type + index}
              formik={formik}
              {...orther}
            ></CmpRangePicker>
          );
          return defaultBox(result, item);
        } else if (type === "autocomplete" && display) {
          let result = (
            <CmpAutocomplete
              key={type + index}
              formik={formik}
              {...orther}
            ></CmpAutocomplete>
          );
          return defaultBox(result, item);
        } else if (type === "phone" && display) {
          let result = (
            <CumtomPhoneFiled
              key={type + index}
              formik={formik}
              {...orther}
            ></CumtomPhoneFiled>
          );
          return defaultBox(result, item);
        } else if (type === "selectDate" && display) {
          let result = (
            <CustomDatePicker
              key={type + index}
              formik={formik}
              {...orther}
            ></CustomDatePicker>
          );
          return defaultBox(result, item);
        } else if (type === "button" && display) {
          let result = (
            <CustomButton
              key={type + index}
              formik={formik}
              {...orther}
            ></CustomButton>
          );
          return defaultBox(result, item);
        } else if (type === "radio" && display) {
          let result = (
            <CmpRadioGrounp
              key={type + index}
              formik={formik}
              {...orther}
            ></CmpRadioGrounp>
          );
          return defaultBox(result, item);
        } else if (type === "person" && display) {
          let result = (
            <PersonAutocomplete
              key={type + index}
              formik={formik}
              {...orther}
            ></PersonAutocomplete>
          );
          return defaultBox(result, item);
        } else if (type === "timeMonths" && display) {
          let result = (
            <CustomDatePicker1
              key={type + index}
              formik={formik}
              {...orther}
            ></CustomDatePicker1>
          );
          return defaultBox(result, item);
        } else if (type === "timeDay" && display) {
          let result = (
            <TimePickerDate
              key={type + index}
              formik={formik}
              {...orther}
            ></TimePickerDate>
          );
          return defaultBox(result, item);
        } else if (type === "ZKAutocomplete" && display) {
          let result = (
            <ZKAutocomplete
              key={type + index}
              formik={formik}
              {...orther}
            ></ZKAutocomplete>
          );
          return defaultBox(result, item);
        } else if (type === "AutocomplateProduct" && display) {
          let result = (
            <AutocomplateProduct
              key={type + index}
              formik={formik}
              {...orther}
            ></AutocomplateProduct>
          );
          return defaultBox(result, item);
        } else if (type === "null" && display) {
          return <Placeholder></Placeholder>;
        } else {
          return null;
        }
      }
    }
  });
  return formConfig;
};

export default RenderingFromItem;
