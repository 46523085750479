import { Button, Card, CardContent, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useTransition } from "react";
import { useState } from "react";
import CommonUtil from "../../util/CommonUtils";
import ListLayout from "../../components/ListLayout";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import DataTable from "../../components/DataTable";
import { getAllSubscription } from "../../services/SubscriptionService";

const SubscriptionRecords = () => {
	const [payload, setPayload] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { navigate } = useNavigate();
	// const [page, setPage] = useState(0);
	// const [pageSize, setPageSize] = useState(8);//pageNumber
	const [pageNumber, setPageNumber] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [rowCount, setRowCount] = useState(0);
	const [formValues, setFormValues] = useState({
		customerName: "",
		customerEmail: "",
		createdAt: "",
		expiredTime: ""
	})
	const [filters, setFilters] = useState({
		pageNumber: 0,
		pageSize: 8,
	});

	const defaultFilters = {
		pageNumber: 0,
		pageSize: 8,
	}

	const handlePageChange = (e) => {
		setPageNumber(e)
	};

	const handlePageSize = (e) => {
		setFilters({
			...defaultFilters,
			pageNumber: defaultFilters.pageNumber,
			pageSize: e,
		});
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues(prevValues => ({
			...prevValues,
			[name]: value
		}));
	};

	const handleReset = () => {
		setFormValues({
			customerName: "",
			customerEmail: "",
			createdAt: "",
			expiredTime: ""
		});
		loadData();
	}

	const handleSubmit = () => {
		const pageNumber = 0;
		const pageSize = 0;
		let request = {
			pageNumber,
			pageSize
		};
		getAllSubscription(request, formValues).then((res) => {
			if (res?.data?.code === "LVLI0000") {
				setPayload(res?.data?.data?.objects);
				setRowCount(res.data.data.totalCount);
			}
		})

	}
	useEffect(() => {
		loadData();
	}, [pageNumber, pageSize])

	const loadData = () => {
		let request = {
			pageNumber,
			pageSize
		};
		getAllSubscription(request, formValues).then((res) => {
			if (res?.data?.code === "LVLI0000") {
				setPayload(res?.data?.data?.objects);
				setRowCount(res.data.data.totalCount);
			}
		})
	};

	const toolbarProps = {

		refresh: true,
		onRefresh: (data) => {
			setFilters({ ...filters, ...defaultFilters });
			loadData(filters);
		},
		onFilter: (data) => {
			console.log("onFilter");
		},
	};
	const columns = [
		{
			field: "customerName",
			headerName: `${t("Customer Name")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e.row.customerName} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e.row.customerName)}</span>
					</Tooltip>
				</>
			),
		},
		{
			field: "customerEmail",
			headerName: `${t("customerAccount")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.customerEmail} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.customerEmail)}</span>
					</Tooltip>
				</>
			),
		},
		{
			field: "createdBy",
			headerName: `${t("AccountCreatedBy")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.createdBy} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.createdBy)}</span>
					</Tooltip>
				</>
			),
		},
		{
			field: "contractId",
			headerName: `${t("contractId")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.contractId} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.contractId)}</span>
					</Tooltip>
				</>
			),
		},
		{
			field: "contractAmount",
			headerName: `${t("contractAmount")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.contractAmount} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.contractAmount)}</span>
					</Tooltip>
				</>
			)
		},
		{
			field: "subscriptionPackage",
			headerName: `${t("subscriptionPackage")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.subscriptionPackage} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.subscriptionPackage)}</span>
					</Tooltip>
				</>
			)
		},
		{
			field: "subscriptionExceuteDate",
			headerName: `${t("subscriptionExceuteDate")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.createdAt} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.createdAt)}</span>
					</Tooltip>
				</>
			)
		},
		{
			field: "Expire Date",
			headerName: `${t("Expier Date")}`,
			flex: 1,
			renderCell: (e) => (
				<>
					<Tooltip title={e?.row?.expiredTime} arrow placement="bottom">
						<span>{CommonUtil.formatLongText(e?.row?.expiredTime)}</span>
					</Tooltip>
				</>
			)
		},
	]
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						Subscription Search
					</Typography>
					<Card>
						<CardContent>
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={12} md={2}>
									<TextField
										id="customerName1"
										label="customerName"
										size="small"
										name="customerName"
										fullWidth
										value={formValues.customerName}
										onChange={handleChange}
										InputProps={{
											style: { height: '30px' }
										}}
										InputLabelProps={{
											style: { fontSize: '12px', height: '30px' }
										}}
									/>
								</Grid>
								<Grid item xs={12} md={2}>
									<TextField
										id="customerAccount1"
										label="customerAccount"
										size="small"
										name="customerEmail"
										fullWidth
										value={formValues.customerEmail}
										onChange={handleChange}
										InputProps={{
											style: { height: '30px' }
										}}
										InputLabelProps={{
											style: { fontSize: '12px', height: '30px' }
										}}
									/>
								</Grid>
								<Grid item xs={12} md={2}>
									<TextField
										id="subscriptionExceuteDate1"
										label="subscriptionExceuteDate"
										size="small"
										name="createdAt"
										fullWidth
										value={formValues.createdAt}
										onChange={handleChange}
										InputProps={{
											style: { height: '30px' }
										}}
										InputLabelProps={{
											style: { fontSize: '12px', height: '30px' }
										}}
									/>
								</Grid>
								<Grid item xs={12} md={2}>
									<TextField
										id="Expier Date1"
										label="Expier Date"
										size="small"
										name="expiredTime"
										fullWidth
										value={formValues.expiredTime}
										onChange={handleChange}
										InputProps={{
											style: { height: '30px' }
										}}
										InputLabelProps={{
											style: { fontSize: '12px', height: '30px' }
										}}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<Grid container spacing={2} justifyContent="flex-end">
										<Grid item>
-
										</Grid>	
										<Button 
										variant="contained" 
										style={{								
											borderRadius: '8px', 
											opacity: 1,
											background: 'transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box',
										}} onClick={handleSubmit}>Find</Button>
										<Grid item>
											<Button variant="contained"  onClick={handleReset}>Reset</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<ListLayout
				toolbarProps={toolbarProps}
			>
				<DataTable
					columns={columns}
					rows={payload}
					page={pageNumber}
					totalRecords={rowCount}
					rowsPerPage={pageSize}
					onPageChange={(pn) => handlePageChange(pn)}
					onPageSizeChange={(ps) => handlePageSize(ps)}
					onSelection={() => console.log()}
				>
				</DataTable>
			</ListLayout>
		</>
	)
}
export default SubscriptionRecords;