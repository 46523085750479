import { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import { getScreenLogs } from "../../services/ScreenService";
import { Tooltip, Avatar, Dialog, Button } from "@mui/material";
import CommonUtil from "../../util/CommonUtils";
import { t } from "i18next";
import ListLayout from "../../components/ListLayout";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";

export default function ScreenLogs() {
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
  });

  const [currentUrl, setCurrentUrl] = useState();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const preView = (row) => {
    setCurrentUrl(row.url);
    setOpen(true);
  };

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
  };

  const toolbarProps = {
    refresh: true,
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
    },
  };

  const columns = [
    {
      field: "screenId",
      headerName: t("synchronized.screenID"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip id="Logs-1" title={e.row.screenId} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.screenId)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "outletName",
      headerName: t("synchronized.outlet"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            id="Logs-2"
            title={e.row.outletName}
            arrow
            placement="bottom"
          >
            <span>{CommonUtil.formatLongText(e.row.outletName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "productName",
      headerName: t("synchronized.product"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            id="Logs-3"
            title={e.row.productName}
            arrow
            placement="bottom"
          >
            <span>{CommonUtil.formatLongText(e.row.productName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "deviceSn",
      headerName: t("synchronized.deviceSN"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip id="Logs-4" title={e.row.deviceSn} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.deviceSn)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "status",
      headerName: t("synchronized.status"),
      flex: 1,
      renderCell: (e) => {
        const { color, text } = renderStatus(e.row.status);
        return (
          <Tooltip id="Logs-5" title={text} arrow placement="bottom">
            <span style={{ color }}>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "url",
      headerName: t("synchronized.picture"),
      flex: 1,
      renderCell: (e) => {
        return (
          <Avatar
            variant="square"
            onClick={() => {
              preView(e.row);
            }}
            alt="Remy Sharp"
            src={e.row.url}
            sx={{ width: 34, height: 34 }}
          />
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("table_approval.createtime"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            id="Logs-6"
            title={moment(e.row.createdAt).format(dateTimeFormat)}
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(
                moment(e.row.createdAt).format(dateTimeFormat)
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const renderStatus = (status) => {
    let color = "black";
    let text = "Created";
    if (status === 1) {
      color = "green";
      text = "Success";
    } else if (status === 0) {
      color = "red";
      text = "Failed";
    } else if (status === -1) {
      color = "orange";
      text = "Device deleted";
    }
    return { color, text };
  };

  const loadData = () => {
    getScreenLogs(filters).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setRecords(res?.data?.data?.screenLogs);
        setTotalRecords(res?.data?.data?.totalCount);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  return (
    <ListLayout
      title={t("synchronized.systemRecords")}
      toolbarProps={toolbarProps}
    >
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
        onSelection={() => console.log("")}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <img src={currentUrl}></img>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </ListLayout>
  );
}
