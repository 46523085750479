import { useEffect, useState, createContext } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import RighViewLayout from "@c/RighViewLayout";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { createValidation } from "@/util/validationUtils";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { REACT_PRICE_CHANGE_EVENT } from "@/router/ReactEndPoints";
import { getInitialValues } from "./js/initialValues";
import { monthMapping } from "./js/enum";
import { addChangeEvent, editChangeEvent } from "@s/PriceChangeEventservice";
import {
  getProductBrandInfo,
  getProductInfo,
  updateComponentValues,
  handlerUpdataFile,
  getPriceDetail,
  loadDataTemplates,
  getProductLabelData,
  getProductDetail,
} from "./js/interface";
import PriceName from "./menu/PriceName";
import AreaOutlet from "./menu/AreaOutlet";
import ProductFilter from "./menu/ProductFilter";
import PriceRule from "./menu/PriceRule";
import Template from "./menu/Template";
import Schedule from "./menu/Schedule";
export const ChangePriceContext = createContext();
function AddPriceEvent(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [priceNameConfig, setPriceNameConfig] = useState([]); // 设备数量控制
  const [outletConfig, setOutletConfig] = useState([]); //门店列表数据
  const [productConfig, setProductConfig] = useState([]); //商品列表数据
  const [priceRuleConfig, setPriceRulrConfig] = useState([]); //变价规则模块配置数据
  const [templateConfig, setTemplateConfig] = useState([]); //模板选择模块配置数据
  const [scheduleConfig, setScheduleConfig] = useState([]); // schedule 变价计划模块配置数据
  const [expandedIndex, setExpandedIndex] = useState(false); // 是否打开当前模块

  const [templateList, setTemplateList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [brandData, setBrandData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [customPayload, setCustomPayload] = useState({}); // 获取商品详情
  const [templateJSON, setTemplateJSON] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const [productLabel, setProductLabel] = useState([]);
  const [resolution, setResolution] = useState(""); // 报错模板分辨率

  const [editorTemplateJSON, setEditorTemplateJSON] = useState(null); // 回显模板JSON (仅回显使用)
  const formConfig = [
    ...priceNameConfig,
    ...outletConfig,
    ...productConfig,
    ...priceRuleConfig,
    ...templateConfig,
    ...scheduleConfig,
  ];

  const addFormik = useFormik({
    initialValues: getInitialValues(state, detailData),
    validationSchema: createValidation(formConfig),
    onSubmit: async (values) => {
      let updatedValues;

      if (values.endOrNot) {
        updatedValues = {
          ...values,
          startDayDate: `${values.startTime} ${values.timeDay}`,
          endDayDate: null,
        };
      } else {
        updatedValues = {
          ...values,
          startDayDate: `${values.startTime} ${values.timeDay}`,
          endDayDate: `${values.endTime} ${values.endTimeDay}`,
        };
      }
      if (updatedValues.dates) {
        updatedValues.dates = updatedValues.dates.map((x) =>
          Number.parseInt(x)
        );
      }

      if (validateForm(updatedValues)) {
        if (state?.action === "Edit") {
          if (!addFormik.values?.detailList?.isAllowEdit) {
            enqueueSnackbar(
              "Price change event is being executed. Modification is forbidden",
              {
                variant: "error",
              }
            );
            return;
          }

          if (templateJSON) {
            let res111 = await handlerUpdataFile(templateJSON, addFormik);

            const res = await editChangeEvent({
              ...updatedValues,
              url: res111?.imageUrl,
              outletIds: values.outletIds
                ? values.outletIds.map((item) => (item.id ? item.id : item))
                : [],
              productAttributeId: values.elementId,
              attributeValueId: values.productBrand,
              resolution: values.resolution ? values?.resolution : resolution,
              days: values.days ? values.days : [],
              dates: updatedValues.dates ? updatedValues.dates : [],
              months: values.months ? values.months : [],
              years: values.years ? values.years : [],
            });
            if (res?.data?.code === "LVLI0003") {
              enqueueSnackbar(res?.data?.message, { variant: "success" });
              navigate(REACT_PRICE_CHANGE_EVENT);
            } else if (res?.data?.code === "EPTE0008") {
              enqueueSnackbar("Please select a template", { variant: "error" });
            } else {
              enqueueSnackbar(res?.data?.message, { variant: "error" });
            }
          }
        } else {
          if (templateJSON) {
            let res111 = await handlerUpdataFile(templateJSON, addFormik);

            const res = await addChangeEvent({
              ...updatedValues,
              url: res111?.imageUrl,
              outletIds: values.outletIds
                ? values.outletIds.map((item) => (item.id ? item.id : item))
                : [],
              productAttributeId: values.elementId,
              attributeValueId: values.productBrand,
              days: values.days ? values.days : [],
              dates: updatedValues.dates ? updatedValues.dates : [],
              months: values.months ? values.months : [],
              years: values.years ? values.years : [],
            });
            if (res?.data?.code === "LVLI0008") {
              enqueueSnackbar(res?.data?.message, { variant: "success" });
              navigate(REACT_PRICE_CHANGE_EVENT);
            } else {
              enqueueSnackbar(res?.data?.message, { variant: "error" });
            }
          }
        }
      }
    },
  });

  // 校验选择 时间 范围方法
  const validateForm = (updatedValues) => {
    if (!updatedValues?.scheduleMode) {
      enqueueSnackbar("The schedule mode cannot be empty", {
        variant: "error",
      });
      return false;
    }

    let currentTime = dayjs(); // 当前时间

    //  开始促销时间
    let startTime = dayjs(
      updatedValues?.startDayDate,
      "YYYY-MM-DD HH:mm:ss"
    ).set("second", 0);
    // 结束促销时间
    let endTime = dayjs(updatedValues?.endDayDate, "YYYY-MM-DD HH:mm:ss").set(
      "second",
      59
    );

    if (!startTime.isValid()) {
      enqueueSnackbar("The start promotion time cannot be empty", {
        variant: "error",
      });
      return false;
    }

    const { endOrNot } = updatedValues;

    if (!endOrNot && !endTime.isValid()) {
      enqueueSnackbar("The end promotion time cannot be empty", {
        variant: "error",
      });
      return false;
    }

    if (currentTime.startOf("day").isAfter(startTime)) {
      enqueueSnackbar(
        "The start date of the promotion cannot be earlier than the current date",
        {
          variant: "error",
        }
      );
      return false;
    }

    if (currentTime.startOf("day").isAfter(endTime)) {
      enqueueSnackbar(
        "The end date of the promotion cannot be earlier than the current date",
        {
          variant: "error",
        }
      );
      return false;
    }

    const { scheduleMode } = updatedValues;

    if (scheduleMode && scheduleMode !== "Does not repeat") {
      if (!updatedValues?.startDayTime) {
        enqueueSnackbar("The start repeat time cannot be empty", {
          variant: "error",
        });
        return false;
      }

      if (!updatedValues?.endDayTime) {
        enqueueSnackbar("The end repeat time cannot be empty", {
          variant: "error",
        });
        return false;
      }
    }

    let everyDayStartTime = dayjs(updatedValues?.startDayTime, "HH:mm:ss"); // 当日 促销开始时间
    let everyDayEndTime = dayjs(updatedValues?.endDayTime, "HH:mm:ss"); // 当日 促销结束时间

    // 比较 结束促销时间是否在开始促销时间之前
    if (endTime?.isBefore(startTime)) {
      enqueueSnackbar(
        "The end time of the promotion cannot be earlier than the start time of the promotion",
        {
          variant: "error",
        }
      );
      return false;
    }

    // 比较当日 促销执行时间 结束时间是否在开始时间之前
    if (everyDayEndTime?.isBefore(everyDayStartTime)) {
      enqueueSnackbar(
        "The end time of the promotion shall not be earlier than the start time",
        {
          variant: "error",
        }
      );
      return false;
    }

    const { fullDay } = updatedValues;

    // 检验每日
    if (scheduleMode === "Daily") {
      if (fullDay) {
        return true;
      }
      if (endOrNot) {
        return true;
      }
      const daysDiff = endTime.diff(startTime, "day");
      if (daysDiff > 0) {
        return true;
      }
      for (
        let s = startTime;
        s.isBefore(endTime);
        s = s.add(1, "day").startOf("day")
      ) {
        const day = s.format("YYYY-MM-DD");
        const eStart = dayjs(
          day + " " + updatedValues?.startDayTime,
          "YYYY-MM-DD HH:mm:ss"
        ).set("second", 0);
        const eEnd = dayjs(
          day + " " + updatedValues?.endDayTime,
          "YYYY-MM-DD HH:mm:ss"
        ).set("second", 59);
        if (!(startTime.isAfter(eEnd) || endTime.isBefore(eStart))) {
          return true;
        }
      }

      enqueueSnackbar(
        "There is no overlap between promotion time and repetition time",
        {
          variant: "error",
        }
      );
      return false;
    }

    // 检验每周
    if (scheduleMode === "Weekly") {
      const { days } = updatedValues;

      if (!days || days.length === 0) {
        enqueueSnackbar("Week cannot be empty", {
          variant: "error",
        });
        return false;
      }

      if (endOrNot) {
        return true;
      }

      for (
        let s = startTime;
        s.isBefore(endTime);
        s = s.add(1, "day").startOf("day")
      ) {
        const week = s.format("dddd");

        if (!days.includes(week)) {
          continue;
        }

        const day = s.format("YYYY-MM-DD");
        const eStart = dayjs(
          day + " " + updatedValues?.startDayTime,
          "YYYY-MM-DD HH:mm:ss"
        ).set("second", 0);
        const eEnd = dayjs(
          day + " " + updatedValues?.endDayTime,
          "YYYY-MM-DD HH:mm:ss"
        ).set("second", 59);
        if (!(startTime.isAfter(eEnd) || endTime.isBefore(eStart))) {
          return true;
        }
      }

      enqueueSnackbar(
        "There is no overlap between promotion time and repetition time",
        {
          variant: "error",
        }
      );
      return false;
    }

    // 检验每月
    if (scheduleMode === "Monthly") {
      const { months } = updatedValues;

      if (!months || months.length === 0) {
        enqueueSnackbar("Month cannot be empty", {
          variant: "error",
        });
        return false;
      }

      let { dates } = updatedValues;
      if (!dates || dates.length === 0) {
        enqueueSnackbar("Date cannot be empty", {
          variant: "error",
        });
        return false;
      }

      if (endOrNot) {
        return true;
      }

      const daysDiff = endTime.diff(startTime, "day");
      if (daysDiff > 366 * 8) {
        return true;
      }

      for (
        let s = startTime;
        s.isBefore(endTime);
        s = s.add(1, "day").startOf("day")
      ) {
        const month = s.format("MMMM");

        if (!months.includes(month)) {
          continue;
        } else {
          const date = s.get("date");
          if (!dates.includes(date)) {
            continue;
          }
        }

        const day = s.format("YYYY-MM-DD");
        const eStart = dayjs(
          day + " " + updatedValues?.startDayTime,
          "YYYY-MM-DD HH:mm:ss"
        ).set("second", 0);
        const eEnd = dayjs(
          day + " " + updatedValues?.endDayTime,
          "YYYY-MM-DD HH:mm:ss"
        ).set("second", 59);
        if (!(startTime.isAfter(eEnd) || endTime.isBefore(eStart))) {
          return true;
        }
      }

      enqueueSnackbar(
        "There is no overlap between promotion time and repetition time",
        {
          variant: "error",
        }
      );
      return false;
    }

    // 检验每年
    if (scheduleMode === "Annually") {
      const { years, months } = updatedValues;

      if (!years || years.length === 0) {
        enqueueSnackbar("Year cannot be empty", {
          variant: "error",
        });
        return false;
      }

      if (!months || months.length === 0) {
        enqueueSnackbar("Month cannot be empty", {
          variant: "error",
        });
        return false;
      }

      let { dates } = updatedValues;
      if (!dates || dates.length === 0) {
        enqueueSnackbar("Date cannot be empty", {
          variant: "error",
        });
        return false;
      }

      for (let i = 0; i < years.length; i++) {
        for (let j = 0; j < months.length; j++) {
          for (let k = 0; k < dates.length; k++) {
            let date = dates[k];

            const startOfMonth = dayjs(
              `${years[i]}-${monthMapping[months[j]]}-01`,
              "YYYY-MM-DD"
            );
            const daysInMonth = startOfMonth.daysInMonth();
            if (daysInMonth < date) {
              continue;
            }

            if (date < 10) {
              date = "0" + date;
            }

            const day = `${years[i]}-${monthMapping[months[j]]}-${date}`;
            const eStart = dayjs(
              day + " " + updatedValues?.startDayTime,
              "YYYY-MM-DD HH:mm:ss"
            ).set("second", 0);
            const eEnd = dayjs(
              day + " " + updatedValues?.endDayTime,
              "YYYY-MM-DD HH:mm:ss"
            ).set("second", 59);
            if (
              !(
                startTime.isAfter(eEnd) ||
                (!endOrNot && endTime.isBefore(eStart))
              )
            ) {
              return true;
            }
          }
        }
      }

      enqueueSnackbar(
        "There is no overlap between promotion time and repetition time",
        {
          variant: "error",
        }
      );
      return false;
    }

    return true;
  };

  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 3,
  });

  // 获取变价模块详情信息  （编辑回显用）
  useEffect(() => {
    if (state?.action === "Edit") {
      getPriceDetail(state?.id, setDetailData, addFormik);
    }
  }, [state?.id]);

  // 获取Label 列表数据
  useEffect(() => {
    getProductLabelData(setProductLabel);
  }, []);

  // 获取品牌列表
  useEffect(() => {
    if (addFormik.values.elementId) {
      getProductBrandInfo(addFormik.values.elementId, setBrandData);
    } else {
      setBrandData([]);
      addFormik.setFieldValue("productBrand", "");
    }
  }, [addFormik.values.elementId]);

  useEffect(() => {
    if (addFormik.values.productId) {
      getProductDetail(addFormik.values.productId, setCustomPayload);
    }
  }, [addFormik.values.productId]);

  //获取商品列表
  useEffect(() => {
    if (addFormik.values?.productBrand) {
      getProductInfo(
        addFormik.values.elementId,
        addFormik.values?.productBrand,
        setProductData
      );
    } else {
      setProductData([]);
      addFormik.setFieldValue("productId", "");
    }
  }, [addFormik.values.elementId, addFormik.values?.productBrand]);

  // 获取模板列表
  useEffect(() => {
    if (!addFormik.values.productId) {
      setTemplateList([]);
      return;
    }
    loadDataTemplates(
      filters,
      setTemplateList,
      addFormik.values.promotionType,
      addFormik.values.productId,
      undefined,
      null,
      setTotalPages
    );
  }, [filters, addFormik.values.promotionType, addFormik.values.productId]);

  // 获取用户选中模板的 分辨率  例：400 * 300
  useEffect(() => {
    let templateData = templateList?.find(
      (item) => item?.id === addFormik.values.templateId
    );

    addFormik.setFieldValue("resolution", templateData?.screenResolution);
    if (templateData && productData) {
      let res = updateComponentValues(
        templateData?.templateJson,
        customPayload,
        addFormik
      );

      setTemplateJSON(res);
    } else {
      if (editorTemplateJSON && productData) {
        let res = updateComponentValues(
          editorTemplateJSON,
          customPayload,
          addFormik
        );
        setTemplateJSON(res);
      }
    }
  }, [
    editorTemplateJSON,
    productData,
    addFormik.values.templateId,
    addFormik.values.productId,
  ]);

  // 分解开始 促销变价时间和 结束促销变价时间
  useEffect(() => {
    let detailList = addFormik.values.detailList;

    // 解析时间
    const startTime = dayjs(detailList?.startAt);
    const endTime = dayjs(detailList?.endAt);

    // 格式化为所需的两个字段
    if (startTime.isValid()) {
      const formattedDate = startTime.format("YYYY-MM-DD");
      const formattedTime = startTime.format("HH:mm:ss");
      addFormik.setFieldValue("startTime", formattedDate);
      addFormik.setFieldValue("timeDay", formattedTime);
    }

    if (endTime.isValid()) {
      const formattedDate1 = endTime.format("YYYY-MM-DD");
      const formattedTime1 = endTime.format("HH:mm:ss");
      addFormik.setFieldValue("endTime", formattedDate1);
      addFormik.setFieldValue("endTimeDay", formattedTime1);
    }

    addFormik.setFieldValue("startDayTime", detailList?.startDayTime);
    addFormik.setFieldValue("endDayTime", detailList?.endDayTime);
    addFormik.setFieldValue("zoneId", detailList?.zoneId);

    addFormik.setFieldValue("days", detailList?.days);

    addFormik.setFieldValue("dates", detailList?.dates);
    addFormik.setFieldValue("outletIds", detailList?.outlets);

    addFormik.setFieldValue("months", detailList?.months);
    addFormik.setFieldValue("years", detailList?.years);
    addFormik.setFieldValue("resolution", detailList?.resolution);
    addFormik.setFieldValue("promotionType", detailList?.promotionType);
    addFormik.setFieldValue(
      "promotionDescription",
      detailList?.promotionDescription
    );

    setResolution(detailList?.resolution);
    setEditorTemplateJSON(detailList?.templateJson);
  }, [addFormik.values.detailList]);

  const [outletMarked, setOutletMarked] = useState(true); // 是否显示门店选择框提示语
  const handleConfirm = (nextIndex, fields) => async (event, isExpanded) => {
    // 检查 fields 是否有效
    if (!fields || !Array.isArray(fields)) {
      console.error("fields is not an array", fields);
      return; // 结束函数执行
    }

    let templateId = fields.find((item) => item === "templateId");

    if (templateId && !addFormik.values["templateId"]) {
      enqueueSnackbar("Please Select a Template ", {
        variant: "error",
      });

      return;
    }

    // 检查是否所有字段都有值
    const allFieldsFilled = fields?.every((element) => {
      return (
        addFormik.values[element] && !addFormik.errors[element] // 如果某个字段没有值，返回 false
      );
    });

    setOutletMarked(allFieldsFilled);

    if (allFieldsFilled) {
      setExpandedIndex(nextIndex);
    } else {
      try {
        // addFormik.setTouched(
        //   fields.reduce((acc, field) => {
        //     acc[field] = true; // 标记所有字段为 touched
        //     return acc;
        //   }, {})
        // );

        addFormik.setTouched({
          ...addFormik.touched,
          ...fields?.reduce((acc, field) => {
            acc[field] = true; // 标记所有字段为 touched
            return acc;
          }, {}),
        });
      } catch (error) {
        return; // 不打开下一个面板
      }
    }
  };

  const handleChange = (index, fields) => (event, isExpanded) => {
    const allFieldsFilled = fields?.every((element) => {
      return addFormik.values[element]; // 如果某个字段没有值，返回 false
    });

    if (allFieldsFilled) {
      setExpandedIndex(isExpanded ? index : null);
    } else {
      addFormik.setTouched({
        ...addFormik.touched,
        ...fields?.reduce((acc, field) => {
          acc[field] = true; // 标记所有字段为 touched
          return acc;
        }, {}),
      });
    }
  };

  const handlerCancel = (previousIndex, fields) => (event, isExpanded) => {
    setExpandedIndex(previousIndex);

    // if (previousIndex === "4") {
    //   setExpandedIndex(previousIndex);
    // } else {
    //   // 检查是否所有字段都有值
    //   const allFieldsFilled = fields.every((element) => {
    //     return addFormik.values[element]; // 如果某个字段没有值，返回 false
    //   });

    //   if (allFieldsFilled) {
    //     // 如果所有字段都有值，打开上一个面板
    //     setExpandedIndex(previousIndex);
    //   } else {
    //     addFormik.setTouched(
    //       fields.reduce((acc, field) => {
    //         acc[field] = true; // 标记所有字段为 touched
    //         return acc;
    //       }, {})
    //     );
    //   }
    // }
  };

  return (
    <ChangePriceContext.Provider value={{ outletMarked, setOutletMarked }}>
      <RighViewLayout
        title={
          state?.action === "Edit"
            ? t("events.edit_priceChangeEvent")
            : t("events.add_priceChangeEvent")
        }
        navigateBack={REACT_PRICE_CHANGE_EVENT}
      >
        <Grid p={2}>
          <form noValidate onSubmit={addFormik.handleSubmit}>
            <PriceName
              addFormik={addFormik}
              priceNameConfig={priceNameConfig}
              setPriceNameConfig={setPriceNameConfig}
              handleChange={handleChange}
              handleConfirm={handleConfirm}
              setExpandedIndex={setExpandedIndex}
              expandedIndex={expandedIndex}
            ></PriceName>

            <Grid mt={2}>
              <AreaOutlet
                initValue={detailData?.outlets}
                addFormik={addFormik}
                outletConfig={outletConfig}
                setOutletConfig={setOutletConfig}
                handleChange={handleChange}
                handleConfirm={handleConfirm}
                handlerCancel={handlerCancel}
                setExpandedIndex={setExpandedIndex}
                expandedIndex={expandedIndex}
              ></AreaOutlet>
            </Grid>

            <Grid mt={2}>
              <ProductFilter
                addFormik={addFormik}
                productConfig={productConfig}
                setProductConfig={setProductConfig}
                handleChange={handleChange}
                handleConfirm={handleConfirm}
                handlerCancel={handlerCancel}
                setExpandedIndex={setExpandedIndex}
                expandedIndex={expandedIndex}
                brandData={brandData}
                productData={productData}
                productLabel={productLabel}
              ></ProductFilter>
            </Grid>

            <Grid mt={2}>
              <PriceRule
                addFormik={addFormik}
                priceRuleConfig={priceRuleConfig}
                setPriceRulrConfig={setPriceRulrConfig}
                handleChange={handleChange}
                handleConfirm={handleConfirm}
                handlerCancel={handlerCancel}
                setExpandedIndex={setExpandedIndex}
                expandedIndex={expandedIndex}
              ></PriceRule>
            </Grid>

            <Grid mt={2}>
              <Template
                addFormik={addFormik}
                templateConfig={templateConfig}
                setTemplateConfig={setTemplateConfig}
                handleChange={handleChange}
                handleConfirm={handleConfirm}
                handlerCancel={handlerCancel}
                setExpandedIndex={setExpandedIndex}
                expandedIndex={expandedIndex}
                templateList={templateList}
                productData={customPayload}
                setTemplateList={setTemplateList}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              ></Template>
            </Grid>

            <Grid mt={2}>
              <Schedule
                addFormik={addFormik}
                scheduleConfig={scheduleConfig}
                setScheduleConfig={setScheduleConfig}
                handleChange={handleChange}
                handleConfirm={handleConfirm}
                handlerCancel={handlerCancel}
                setExpandedIndex={setExpandedIndex}
                expandedIndex={expandedIndex}
                productData={productData}
              ></Schedule>
            </Grid>
          </form>
        </Grid>
      </RighViewLayout>
    </ChangePriceContext.Provider>
  );
}

export default AddPriceEvent;
