import { Box, Divider, InputLabel, Stack, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "./CustomInput";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import i18n from "i18next";
import cn from "react-phone-input-2/lang/cn.json";
import es from "react-phone-input-2/lang/es.json";
import RequirePoint from "./RequirePoint";
import { ReactComponent as DownArrow } from "@/assets/images/downArrow.svg";
import CommonUtil from "../../util/CommonUtils";
import AppLanguage from "@/util/AppLanguages";
import { list } from "postcss";
function CumtomPhoneFiled(props) {
  const { t } = useTranslation();
  const [local, setLocal] = useState();

  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 2,
    width,
    height,
    inputType,
    size = "small",
    fontSize = "20px",
    ...orther
  } = props;
  const useStyles = makeStyles({
    borderClass: {
      "&.react-tel-input .selected-flag .flag": {
        display: CommonUtil.isEmpty(formik.values["mobileArea"])
          ? "none"
          : "block",
      },
      "& .react-tel-input::after": {
        content: DownArrow,
      },
    },
    customInput: {
      "& .MuiOutlinedInput-root ": {
        paddingLeft: "0px",
      },
    },
  });
  const classes = useStyles();
  const lang = AppLanguage.getPrevLanguage();
  const countries = "zh" === lang ? cn : es;
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    i18n.changeLanguage(language);
    setLocal(language);
  }, []);

  return (
    <Stack spacing={spacing}>
      <Stack>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          style={{
            marginTop: labelPostion === "left" ? "12px" : "",
            width: width,
            fontSize: fontSize,
          }}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>
        <Stack>
          <CustomInput
            type={"text"}
            placeholder={props.placeholder}
            autoComplete="off"
            fullWidth={true}
            disabled={disabled}
            className={classes.customInput}
            error={Boolean(
              (formik?.touched[name] && formik?.errors[name]) || error
            )}
            name={name}
            value={formik?.values[name]}
            variant="outlined"
            size={size}
            handleChange={(e) => {
              formik.setFieldValue(name, e.target.value);
            }}
            validation="mobile"
            InputProps={{
              startAdornment: (
                <>
                  <PhoneInput
                    disabled={disabled}
                    autoFormat={true}
                    countryCodeEditable={true}
                    enableSearch={true}
                    searchPlaceholder={"Search"}
                    searchNotFound={"No Options Found"}
                    onChange={(value, data) => {
                      formik.setFieldValue("mobileArea", data?.dialCode);
                    }}
                    localization={countries}
                    inputProps={{
                      disabled: false,
                    }}
                    value={formik.values.mobileArea}
                    containerClass={classes.borderClass}
                    placeholder={t("table.country_code")}
                    specialLabel={true}
                    // isValid={CommonUtil.isEmptyString(props.error)}
                    style={{ width: "auto" }}
                    inputStyle={{ marginBottom: "0px" }}
                  />
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginRight: "10px" }}
                  />
                </>
              ),
            }}
          />

          {((formik?.touched[name] && formik?.errors[name]) || error) && (
            <FormHelperText error id={`standard-weight-helper-text-${name}`}>
              {formik?.errors[name] || error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CumtomPhoneFiled;
