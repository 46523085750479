import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { REACT_DEVICE_GATEWAY } from "../../router/ReactEndPoints";
import { timeZoneList } from "../../constants/TimeZone";
import { bindDevice } from "../../services/DeviceService";
import { filterOutlet } from "../../services/OutletService";
import CommonUtil from "../../util/CommonUtils";

const AddDevice = (props) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [timezone, setTimezone] = useState({});
  const [client, setClient] = useState({});
  const [outlet, setOutlet] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [filters, setFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    outletType: "",
    clientId: "",
    ids: [],
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
      localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });

  const defaultFilters = {
    page: 0,
    rowsPerPage: 5,
    clientId: "",
    ids: [],
    companyIds: [],
  };

  const [error, setError] = React.useState({
    id: "",
    sn: "",
    outletId: "",
    timeZone: "",
  });

  const loadOutlets = () => {
    filterOutlet(1, 1000, filters).then((response) => {
      if (response.data.code === "LMSI6000" && response.data.data) {
        setOutlets(response?.data?.data?.objects);
      } else if (response.data.code === "LVLE0054") {
        setOutlet({});
        setOutlets([]);
      }
    });
  };

  useEffect(() => {
    if (!_.isEqual(filters, defaultFilters)) {
      loadOutlets();
    }
  }, [filters]);

  useEffect(() => {
    setTimezone({});
    if (!CommonUtil.isEmpty(outlet)) {
      let timeZone = CommonUtil.getTimeZoneByValue(outlet?.timeZone);
      setTimezone(timeZone);
    }
  }, [outlet]);

  const handleReset = () => {
    setError({
      id: "",
      sn: "",
      timeZone: "",
      clientId: "",
    });
    setFilters({ ...defaultFilters });
    setOutlet({});
    setClient({});
    setTimezone({});
  };

  const handleSubmit = () => {
    const request = {
      id: props.state?.deviceId,
      sn: props.state?.sn,
      clientId: client?.id,
      outletId: outlet?.id,
    };

    if (CommonUtil.isEmptyString(request.outletId)) {
      setError({
        outletId: `${t("tips.required")}`,
      });
      return;
    }
    bindDevice(request, (res) => {
      if (res != null && res.data.code === "LVLI0009") {
        enqueueSnackbar(t("tips_gateway.deviceBindSuccess"), {
          variant: "success",
        });
        navigate(REACT_DEVICE_GATEWAY);
      } else if (res?.data?.code === "DMSE0028") {
        enqueueSnackbar("Device Is Already Connected!", {
          variant: "error",
        });
      } else if (res?.data?.code === "DMSE9999") {
        enqueueSnackbar("Technical error!", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <Grid
        container
        my={1}
        sx={{
          width: "100%",
        }}
        p={2}
        component={Paper}
        elevation={0}
        alignItems={"center"}
      >
        <Grid container width={"100%"}>
          <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
            <Typography sx={{ fontSize: 13 }}>
              {t("gateway.outlet")}
              <span className="required"> *</span>
            </Typography>
            <Autocomplete
              id="AddDeviceOutlets"
              options={outlets}
              value={outlet}
              onChange={(e, v) => {
                setOutlet(v);
                setError();
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={error?.outletId}
                  helperText={error?.outletId}
                />
              )}
            ></Autocomplete>
          </Grid>
          <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
            <Typography sx={{ fontSize: 13 }}>
              {t("gateway.outletTimeZone")}
            </Typography>
            <Autocomplete
              disabled
              id="AddDeviceTimeZoneList"
              options={timeZoneList}
              value={timezone}
              onChange={(e, v) => {
                setTimezone(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row-reverse"}
          py={1}
          px={0.5}
        >
          <Box item>
            <Button id="AddDevice1" variant="outlined" onClick={handleReset}>
              {t("common.reset")}
            </Button>
          </Box>
          <Box item px={1}>
            <Button
              id="AddDevice2"
              variant="contained"
              onClick={handleSubmit}
              style={{
                size: "medium",
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                borderRadius: "8px",
                opacity: 1,
                marginLeft: 1,
              }}
            >
              {" "}
              {t("common.save")}{" "}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

AddDevice.defaultProps = {
  onClientChange: () => {},
};

export default AddDevice;
