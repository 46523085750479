import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../../../components/CustomInput";
import { useTranslation } from "react-i18next";
import DropzoneComponent from "../../../components/layout-components/DropzoneComponent";
import { ReactComponent as Upload_Image } from "../../../assets/images/Upload_Image.svg";

export default function ContractInformation(props) {
  const { t } = useTranslation();
  const {
    payload,
    setPayload,
    error,
    setError,
    image,
    handleChange,
    handleImage
  } = props;

  return (
    <Grid mt={2}>
      <Typography style={{ font: 'normal normal medium 18px/22px Roboto', color: '#474B4F', opacity: 1 }}>Contract Information</Typography>
      <Grid mt={2} container spacing={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("Contract ID")}
            size="small"
            name="contractId"
            value={payload.contractId}
            error={error.contractId}
            resetError={() => setError({ ...error, contractId: "" })}
            helperText={error.contractId}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("Contract Amount")}
            size="small"
            name="contractAmount"
            value={payload.contractAmount}
            error={error.contractAmount}
            resetError={() => setError({ ...error, contractAmount: "" })}
            helperText={error.contractAmount}
            inputProps={{
              maxLength: 60,
            }}
            validation="numeric"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Typography style={{ font: 'normal normal medium 18px/22px Roboto', color: '#474B4F', opacity: 1 }}>Upload Contract</Typography>
      <Grid mt={2}>
        <DropzoneComponent
          getExcelFile={(file) => handleImage(file)}
        >
          <Box
            mt={0}
            p={1}
            sx={{
              cursor: "pointer",
              backgroundColor: "rgba(54, 201, 109,0.1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "220px",
            }}
          >
            {image ? (
              <img
                src={image}
                alt="Uploaded"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <Upload_Image />
                <Typography
                  sx={{
                    font: 'normal normal medium 18px/22px Roboto',
                    color: '#474B4F',
                    fontSize: "14px",
                    textAlign: "center",
                    opacity: "0.8",
                    mt: 1,
                  }}
                >
                  {t("PROD0001")}
                </Typography>
                <Typography
                  sx={{
                    font: 'normal normal medium 18px/22px Roboto',
                    color: '#474B4F',
                    fontSize: "14px",
                    textAlign: "center",
                    opacity: "0.8",
                    mt:1
                  }}
                >
                  {t("PROD0002")}
                </Typography>
                <Typography sx={{
                  font: 'normal normal medium 18px/22px Roboto',
                  color: '#474B4F',
                  fontSize: "14px",
                  textAlign: "center",
                  opacity: "0.8",
                  mt: 1
                }}>Maximux file size 100MB</Typography>
              </div>
            )}
          </Box>
        </DropzoneComponent>
      </Grid>
    </Grid>
  )
}