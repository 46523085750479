import api from "../configurations/http-common";

export const findOutletById = async (id) => {
  return api.securedAxios().get("api/v2/outlet/" + id);
};

export const filterOutlet = async (page, size, request) => {
  let query = "";
  if (page !== null || page !== "") {
    query += "?pageNumber=" + page;
  }
  if (size !== null || size !== "") {
    query += "&pageSize=" + size;
  }
  if (request?.ids) {
    query += "&ids=" + request.ids;
  }
  if (request?.name) {
    query += "&name=" + encodeURIComponent(request.name);
  }
  if (request?.clientId) {
    query += "&clientId=" + request.clientId;
  }

  if (request?.country) {
    query += "&country=" + request.country;
  }
  if (request?.state) {
    query += "&state=" + request.state;
  }
  if (request?.city) {
    query += "&city=" + request.city;
  }
  if (request?.code) {
    query += "&code" + request.code;
  }
  if (request?.outletType) {
    query += "&outletType=" + request.outletType;
  }
  if (request?.cityZone) {
    query += "&cityZone=" + request.cityZone;
  }

  return api.securedAxios().get("api/v2/outlets/list" + query);
};

export const getOutletData = async (params) => {
  return api.securedAxios().get("api/v2/outlets/list", {
    params,
  });
};

export const createOutlet = async (payload) => {
  return api.securedAxios().post("api/v2/outlet", payload);
};

export const updateOutlet = async (id, payload) => {
  return api.securedAxios().put("api/v2/outlet/" + id, payload);
};

export const getRoles = async () => {
  return api.securedAxios().get("api/roles");
};

export const deleteOutlet = async (id) => {
  return api.securedAxios().delete("api/v2/outlet/" + id);
};

// 获取设备在线的门店
export const getDeviceOnlineOutlet = async (params) => {
  return api.securedAxios().get("/api/v2/deviceOnlineOutletList", {
    params,
  });
};

// 根据元素类型 选择当前类型下的商品数据
// export const getProductBrand = async (params) => {
//   return api.securedAxios().get("/web/product/elementValue", {
//     params,
//   });
// };

// 根据元素类型 选择当前类型和商品品牌下的商品数据
// export const getProductData = async (params) => {
//   return api.securedAxios().get("/web/product/productByElementValue", {
//     params,
//   });
// };

// 根据产品标签ID 获取商品列表
export const getProductBrand = async (id) => {
  return api.securedAxios().get(`/web/product/attribute_values/${id}`);
};

//根据 产品标签Id和品牌ID 获取商品列表
export const getProductData = async (params) => {
  return api.securedAxios().get("/web/products/filter/attribute_values", {
    params,
  });
};
