import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./css/App.css";
import { ConfirmProvider } from "./components/zkconfirm";
import AppRoutes from "./router/AppRoutes";
import { SnackbarProvider } from "notistack";
import i18n from "i18next";
import AppLanguage from "./util/AppLanguages";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    i18n.changeLanguage(language);
  }, []);



  return (
    <BrowserRouter basename="/">
      <ConfirmProvider>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            marginTop: "300px",
          }}
        >
          <AppRoutes />
          <ToastContainer
            autoClose={2000}
            position="top-center"
            hideProgressBar
            className="toast-container"
            toastClassName="dark-toast"
          />
        </SnackbarProvider>
      </ConfirmProvider>
    </BrowserRouter>
  );
}

export default App;
