import api from "../configurations/http-common";

export const createAttribute = async (payload) => {
  return api.securedAxios().post("web/attribute", payload);
};

export const attributeList = async () => {
  return api.securedAxios().get("web/attributes");
};

export const deleteAttribute = async (id) => {
  let url = "web/attribute/";
  if (id) {
    url = url + id;
  }

  return api.securedAxios().delete(url);
};

export const prodcutList = async (payload) => {
  let url = "web/products";
  if (payload.pageNumber) {
    url = url + "?pageNumber=" + payload.pageNumber;
  }
  if (payload.pageSize) {
    url = url + "&pageSize=" + payload.pageSize;
  }
  if (payload.name) {
    url = url + "&name=" + encodeURIComponent(payload.name);
  }
  if (payload.categoryLevel1) {
    url = url + "&categoryLevel1=" + payload.categoryLevel1;
  }
  if (payload.categoryLevel2) {
    url = url + "&categoryLevel2=" + payload.categoryLevel2;
  }
  if (payload.barcode) {
    url = url + "&barcode=" + payload.barcode;
  }
  return api.securedAxios().get(url);
};

export const createProduct = async (payload) => {
  return api.securedAxios().post("web/product", payload);
};

export const findProductById = async (id) => {
  let url = "web/product/";
  if (id) {
    url = url + id;
  }

  return api.securedAxios().get(url);
};

export const updateProduct = async (payload) => {
  return api.securedAxios().put("web/product", payload);
};

export const deleteProduct = async (id) => {
  let url = "web/product/";
  if (id) {
    url = url + id;
  }

  return api.securedAxios().delete(url);
};

export const downloadImage = async (key) => {
  return api.securedAxios().post("web/product/image-url" + "?key=" + key);
};

export const downloadExcelFile = async () => {
  return api.securedAxios().get("web/products/download/excel");
};

export const getAllProducts = async () => {
  return api.securedAxios().get("web/products/list");
};

//查询模板及时间信息
export const getEventInfo = (params) => {
  return api.securedAxios().get("/web/template/templateAndEventByProductId", {
    params,
  });
};

// 变价模块立即变价的接口 更新设备图片的接口
export const UpdataPriceEvent = (payload) => {
  return api.securedAxios().post("/web/updateScreenAfterProduct", payload);
};

//上传模板的接口
export const uploadFile = (payload) => {
  return api.securedAxios().post("/web/uploadFile", payload);
};

// 获取货币接口
export const getCurrentData = () => {
  return api.securedAxios().get("/web/common/getCurrencyData");
};
