import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Grid, styled } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import CustomInput from "../components/TimeCustInput";

function TimePickerDate(props) {
  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          onChangeText={props.onChangeText}
          value={props.value ? props.value : dayjs()}
          onChange={props.onChange}
          renderInput={(params) => (
            <CustomInput
              fullWidth
              handleChange={(event) => event.preventDefault()}
              resetError={() => console.log("6666")}
              size={props.size ? props.size : " "}
              {...params}
              style={{
                width: "100%",
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
}

export default TimePickerDate;
