import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import {
  REACT_PRINCIPAL_MANAGEMENT,
  REACT_RETAIL_CLIENT_LIST,
} from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  addClient,
  editClent,
  editClient,
  getClientById,
  updateClient,
} from "../../services/CompanyService";
import { getAllCountries, getAllStates } from "../../services/LocationService";
import { getAllCities } from "../../services/CityService";

export default function EditPrincipal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [payload, setPayload] = useState({
    name: "",
    code: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    type: "PRINCIPAL",
    partnerType: "PRINCIPAL",
    superAdminEmail: "",
    superAdminFirstName: "",
    superAdminLastName: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    type: "PRINCIPAL",
    partnerType: "PRINCIPAL",
    superAdminEmail: "",
    superAdminFirstName: "",
    superAdminLastName: "",
  });

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res.data.data);
        getAllStates().then((res) => {
          if (res?.data?.code === "LVLI0000") {
            setStates(res.data.data);
            getAllCities().then((res) => {
              if (res?.data?.code === "LVLI0000") {
                setCities(res.data.data);
                if (id) {
                  getClientById(id).then((res) => {
                    if (res?.data?.code === "LVLI0000") {
                      var parts = res?.data?.data?.phone?.split("-");
                      var countryCode = parts ? parts[0] : "";
                      var mobile = parts ? parts[1] : "";

                      setCountryCode(countryCode);
                      setPhoneNumber(mobile);

                      setPayload({
                        ...payload,
                        ...res.data.data,
                      });
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (countries.length > 0 && payload.country) {
      let country = countries.filter((c) => c.id === payload.country.id);
      if (country) {
        setCountry({
          ...country[0],
        });
      }
    }
  }, [payload.country]);

  useEffect(() => {
    if (states.length > 0 && payload.state) {
      let state_ = states.filter((c) => c.id === payload.state.id);
      if (
        state_ &&
        payload.country &&
        payload.country.id === state_[0]?.country?.id
      ) {
        setState({
          ...state_[0],
        });
      }
    }
  }, [stateList]);

  useEffect(() => {
    if (cities.length > 0 && payload.city) {
      let city_ = cities.filter((c) => c.id === payload.city.id);

      if (city_ && payload.state.id === state?.id) {
        setCity({
          ...city_[0],
        });
      }
    }
  }, [cityList]);

  useEffect(() => {
    setState({});
    setCity({});
    if (country === null) {
      setStateList([]);
    }
    if (country)
      setStateList(states.filter((s) => s.country?.id === country?.id));
  }, [country]);

  useEffect(() => {
    setCity({});
    if (state === null) {
      setCityList([]);
    }
    if (state) setCityList(cities.filter((c) => c.state?.id === state?.id));
  }, [state]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return false;
    }
    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: t("tips.country_code_required"),
        });
        return;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: t("tips.mobild_nubmer"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("LVLRCP010"),
        });
        return;
      }
    }

    if (CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("tips.required"),
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        phone: "+" + countryCode.replace("+", "") + "-" + phoneNumber,
        countryId: country?.id,
        stateId: state?.id,
        cityId: city?.id,
      };

      editClient(request).then((response) => {
        if (response.data.code === "LVLI0003") {
          enqueueSnackbar(t("LVLRC0024"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_PRINCIPAL_MANAGEMENT);
          return;
        }
        if (response.data.code !== "LVLI0003") {
          enqueueSnackbar(t("PRNC0000"), {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };
  return (
    <RightViewLayout
      navigateBack={REACT_PRINCIPAL_MANAGEMENT}
      title={t("LVLRC0029")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditPrincipal1"
            required
            label={t("LVLRC0002")}
            size="small"
            name="name"
            value={payload.name}
            error={error.name}
            resetError={() => setError({ ...error, name: "" })}
            handleChange={handleChange}
            helperText={error.name}
            inputProps={{
              maxLength: 60,
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditPrincipal2"
            label={t("LVLRC0003")}
            size="small"
            name="code"
            error={error.code}
            resetError={() => setError({ ...error, code: "" })}
            value={payload.code}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLDAC0023")}
          </InputLabel>
          <Autocomplete
            id="EditPrincipalCountries"
            options={countries}
            value={country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({
                ...payload,
                country: v ? v : "",
                state: "",
                city: "",
              });
              setError({ ...error, country: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.country)}
                helperText={error.country}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0006")}
          </InputLabel>
          <Autocomplete
            id="EditPrincipalState"
            error={error.state}
            helperText={error.state}
            options={stateList}
            value={state}
            onChange={(e, v) => {
              setState(v);
              setError({ ...error, state: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.state)}
                helperText={error.state}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={1} px={2}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLGF0003")}
          </InputLabel>
          <Autocomplete
            id="EditPrincipalCity"
            error={error.city}
            helperText={error.city}
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              setError({ ...error, city: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.city)}
                helperText={error.city}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            id="EditPrincipal3"
            label={t("LVLOT0024")}
            size="small"
            name="pincode"
            value={payload.pincode}
            error={error.pincode}
            resetError={() => setError({ ...error, pincode: "" })}
            helperText={error.pincode}
            inputProps={{
              maxLength: 20,
            }}
            validation={"numericWithoutDot"}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditPrincipal4"
            label={t("LVLOT0013")}
            size="small"
            name="latitude"
            value={payload.latitude}
            error={error.latitude}
            resetError={() => setError({ ...error, latitude: "" })}
            helperText={error.latitude}
            inputProps={{
              maxLength: 60,
            }}
            handleChange={handleChange}
            validation={"numeric"}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditPrincipal5"
            label={t("LVLOT0014")}
            size="small"
            name="longitude"
            value={payload.longitude}
            error={error.longitude}
            resetError={() => setError({ ...error, longitude: "" })}
            helperText={error.longitude}
            inputProps={{
              maxLength: 60,
            }}
            validation={"numeric"}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-10px" }}
          >
            {t("LVLDAC0021")} {<span style={{ color: "red" }}>*</span>}
          </InputLabel>
          <CustomePhoneFiled
            error={error.phone}
            resetError={() => setError({ ...error, phone: "" })}
            handleCountryCode={(data) => {
              setCountryCode(data.dialCode);
              setError({ ...error, phone: "" });
            }}
            countryCode={countryCode}
            placeholder={t("LVLDAC0021")}
            phoneNumber={phoneNumber}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => {
              setPhoneNumber(e);
              setError({ ...error, phone: "" });
            }}
            label={t("LVLDAC0021")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditPrincipal6"
            label={t("LVLOT0022")}
            size="small"
            name="addressLine1"
            value={payload.addressLine1}
            error={error.addressLine1}
            resetError={() => setError({ ...error, addressLine1: "" })}
            helperText={error.addressLine1}
            inputProps={{
              maxLength: 255,
            }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={12} xs={12}>
          <CustomInput
            id="EditPrincipal7"
            label={t("LVLOT0023")}
            size="small"
            name="addressLine2"
            value={payload.addressLine2}
            error={error.addressLine2}
            resetError={() => setError({ ...error, addressLine2: "" })}
            helperText={error.addressLine2}
            inputProps={{
              maxLength: 255,
            }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="EditPrincipal-button-01"
                variant="contained"
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
              >
                {t("EPLSCN0017")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="EditPrincipal-button-02"
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_PRINCIPAL_MANAGEMENT)}
                size="large"
              >
                {t("LVLOT0015")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
