import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import CustomInput from "../../../components/CustomInput";
import { useTranslation } from "react-i18next";
import DropzoneComponent from "../../../components/layout-components/DropzoneComponent";
import { ReactComponent as Upload_Image } from "../../../assets/images/Upload_Image.svg";
import CloseIcon from "@mui/icons-material/Close";
import CustomerSelector from "./CustomerSelector";
import { useState } from "react";
import { useEffect } from "react";
import CustomePhoneFiled from "../../../components/CustomePhoneFiled";

export default function AccountInformation(props) {
  const { t } = useTranslation();
  const [client, setClient] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const {
    payload,
    setPayload,
    error,
    setError,
    image,
    handleChange,
    handleImage,
  } = props;

  useEffect(() => {
    if (client) {
      setPayload({
        ...payload,
        name: client?.name,
        email: client?.email,
        clientId: client?.id,
      });
    }
  }, [client]);

  useEffect(() => {
    setPayload({
      ...payload,
      mobile: "+" + countryCode.replace("+", "") + "-" + phoneNumber,
    });
  }, [countryCode, phoneNumber]);

  const [open, setOpen] = useState(false);
  const popUp = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={6} mt={2} mb={3}>
          <Typography
            style={{
              font: "normal normal medium 18px/22px Roboto",
              color: "#474B4F",
              opacity: 1,
            }}
          >
            Account Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            size="large"
            className="text-transform-none"
            onClick={popUp}
            style={{
              size: "medium",
              borderRadius: "8px",
              opacity: 1,
              background:
                "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
            }}
          >
            Select A Customer
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("Customer Name")}
            size="small"
            name="name"
            value={client.name}
            error={error.numberOfDevices}
            resetError={() => setError({ ...error, numberOfDevices: "" })}
            helperText={error.numberOfDevices}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("Customer Account")}
            size="small"
            name="email"
            value={client.email}
            error={error.numberOfDevices}
            resetError={() => setError({ ...error, numberOfDevices: "" })}
            helperText={error.numberOfDevices}
            inputProps={{
              maxLength: 60,
            }}
            validation="email"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("Area")}
            size="small"
            name="area"
            value={payload.area}
            error={error.area}
            resetError={() => setError({ ...error, area: "" })}
            helperText={error.area}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-3px" }}
          >
            {t("LVLDAC0021")} {<span style={{ color: "red" }}>*</span>}
          </InputLabel>
          <CustomePhoneFiled
            error={error.phone}
            handleCountryCode={(data) => {
              setCountryCode(data.dialCode);
              setError({ ...error, phone: "" });
            }}
            countryCode={countryCode}
            placeholder={t("LVLDAC0021")}
            phoneNumber={phoneNumber}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => {
              setPhoneNumber(e);
              setError({ ...error, phone: "" });
            }}
            label={"LVLDAC0021"}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("New Password")}
            size="small"
            name="password"
            value={payload.password}
            error={error.password}
            resetError={() => setError({ ...error, password: "" })}
            helperText={error.password}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("Confirm Password")}
            size="small"
            name="confirmPassword"
            value={payload.confirmPassword}
            error={error.confirmPassword}
            resetError={() => setError({ ...error, confirmPassword: "" })}
            helperText={error.confirmPassword}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid mt={2}>
        <Typography
          style={{
            font: "normal normal medium 18px/22px Roboto",
            color: "#474B4F",
            opacity: 1,
          }}
        >
          Upload Company Logo
        </Typography>
        <Grid mt={2}>
          <DropzoneComponent getExcelFile={(file) => handleImage(file)}>
            <Box
              mt={0}
              p={1}
              sx={{
                cursor: "pointer",
                backgroundColor: "rgba(54, 201, 109,0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "220px",
              }}
            >
              {image ? (
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Upload_Image />
                  <Typography
                    sx={{
                      font: "normal normal medium 18px/22px Roboto",
                      color: "#474B4F",
                      fontSize: "14px",
                      textAlign: "center",
                      opacity: "0.8",
                      mt: 1,
                    }}
                  >
                    {t("PROD0001")}
                  </Typography>
                  <Typography
                    sx={{
                      font: "normal normal medium 18px/22px Roboto",
                      color: "#474B4F",
                      fontSize: "14px",
                      textAlign: "center",
                      opacity: "0.8",
                      mt: 1,
                    }}
                  >
                    {t("PROD0002")}
                  </Typography>
                  <Typography
                    sx={{
                      font: "normal normal medium 18px/22px Roboto",
                      color: "#474B4F",
                      fontSize: "14px",
                      textAlign: "center",
                      opacity: "0.8",
                      mt: 1,
                    }}
                  >
                    Maximux file size 100MB
                  </Typography>
                </div>
              )}
            </Box>
          </DropzoneComponent>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth: "95%", minHeight: "90%", overflow: "auto" }, // Set your desired width here
        }}
      >
        <DialogTitle mt={2}>
          {t("menu.retail_client_management")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 2,
              top: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomerSelector
            client={client}
            setClient={setClient}
            setOpen={setOpen}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
