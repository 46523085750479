import api from "../configurations/http-common";

export const findOutletTypeByIdOrCode = async (idOrCode) => {
    return api.securedAxios().get("web/outlet_type/" + idOrCode);
}

export const filterOutletType = async (page, size, name, code) => {
    let query = "";
    if(name) {
        query += "&name=" + name;
    }
    if(code) {
        query += "&code=" + code;
    }
    if(page !== null || page !== "") {
        query += "&pageNumber=" + page;
    }
    if(size !== null || size !== "") {
        query += "&pageSize=" + size;
    }
  return api
    .securedAxios()
    .get("web/outlet_types/list?" + query);
};

export const createOutletType = async (payload) => {
    return api
    .securedAxios()
    .post("web/outlet_type", payload);
}

export const updateOutletType = async (id,payload) => {
    return api
    .securedAxios()
    .put("web/outlet_type/"+id, payload);
}

export const deleteOutletType = async(id)=> {
    return api
    .securedAxios()
    .delete("web/outlet_type/"+id);
}