import * as React from "react";
import Box from "@mui/material/Box";
import { ReactComponent as Phone } from "../assets/images/icon_phone.svg";
import { ReactComponent as Email } from "../assets/images/icon_email.svg";
import { Grid, InputLabel, Tooltip } from "@mui/material";
import CustomInput from "./CustomInput";
import CustomePhoneFiled from "./CustomePhoneFiled";
import { useEffect } from "react";
import CommonUtil from "../util/CommonUtils";

export default function UserNameField(props) {
  const [fieldType, setFieldType] = React.useState("Email");

  useEffect(() => {
    props.onChange("");
    props.setUserNameType(fieldType);
    props.onCountryCodeChange("");
  }, [fieldType]);

  const emailFiled = () => (
    <Grid component="form" noValidate sx={{ display: "flex", gridTemplateColumns: { sm: "1fr 1fr" }, gap: 1, }}>
      <CustomInput validation="email"  inputProps={{ maxLength: 36, }}
        label={"Email"} size="small"
        required  resetError={() => console.log()}
        placeholder="Enter Your E-mail ID"
        id="bootstrap-input" fullWidth {...props}
        handleChange={(e) => props.onChange(e.target.value)}
      />
      {/* <Tooltip title='Login with mobile'>
        <Phone onClick={() => setFieldType('Phone')} style={{ height: '45px', width: '45px', marginTop: '20px', cursor: 'pointer' }} />
      </Tooltip> */}
    </Grid>
  );

  const phoneFiled = () => (
    <Grid component="form" noValidate sx={{ display: "flex", gridTemplateColumns: { sm: "1fr 1fr" }, gap: 1, }}>
      <Grid>
        <InputLabel shrink htmlFor="bootstrap-input" style={{ marginBottom: "5px" }} >
          <span style={{ fontSize: "16px" }}>{"Mobile"}</span> &nbsp;  {
            <span style={{ fontSize: "19px", color: "red", marginLeft: "-4px" }} > *  </span>
          }
        </InputLabel>
        <Grid style={{ display: "flex" }}>
          <CustomePhoneFiled  error={props.error}
            handleCountryCode={(data) =>
              props.onCountryCodeChange(data.dialCode)
            }
            countryCode={props.countryCode}
            placeholder="Enter Your Mobile No"
            phoneNumber={props.userName}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => props.onChange(e)}
            label={"Mobile"}
            marginLeft={props.marginLeft}
          />
          <Tooltip title="Login with Email">
            <Email onClick={() => setFieldType("Email")}
              style={{ height: "45px", width: "45px", marginTop: "7px", cursor: "pointer",  marginRight: "7px" }} />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );

  return fieldType === "Email" ? emailFiled() : phoneFiled();
}
