import axios from "axios";

import UserService from "../services/UserService";
// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let tryRequestQueue = [];
const axiosHelper = (headers) => {
  let req = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: headers,
  });

  req.interceptors.request.use(function (request) {
    localStorage.setItem("isLoading", true);
    window.dispatchEvent(new Event("storage"));
    return request;
  });

  const { tokenRefresh } = UserService;

  req.interceptors.response.use(
    function (response) {
      localStorage.setItem("isLoading", false);
      window.dispatchEvent(new Event("storage"));
      return response;
    },
    function (error) {
      const response = error.response;
      if (response?.data?.code === "G0000001") {
        window.location = "/";
        return;
      }
      if (response?.data?.code === "G0000002") {
        console.log("过期了");
        // token 失效重新返回登录
        if (!isRefreshing) {
          isRefreshing = true;

          var decoded_jwtToken = JSON.parse(localStorage.getItem("USER"));

          var payload = {
            refreshToken: localStorage
              .getItem("REFRESH_TOKEN")
              .replace("Bearer ", ""),
            companyCode: decoded_jwtToken.companyCode,
          };

          return tokenRefresh(payload)
            .then((res) => {
              if (res?.data?.code === "G0000005") {
                window.location = "/";
                isRefreshing = false;
                localStorage.removeItem("USER_TOKEN");
                localStorage.removeItem("REFRESH_TOKEN");
                return Promise.reject("error");
              } else {
                const tokenRes = res?.data;

                const token = tokenRes.data.access_token;

                // 刷新token成功，将最新的token更新到header中
                console.log("Refreshed token @" + new Date());
                localStorage.setItem("USER_TOKEN", "Bearer " + token);
                localStorage.setItem(
                  "REFRESH_TOKEN",
                  "Bearer " + tokenRes?.data.refresh_token
                );

                // 获取当前失败的请求
                const config = response.config;
                // 重置一下配置
                config.headers["Authorization"] = "Bearer " + token;
                // 已经刷新了token，将所有队列中的请求进行重试
                tryRequestQueue.forEach((cb) => cb(token));
                tryRequestQueue = [];

                // 重试当前请求并返回promise
                return req(config);
              }
            })
            .catch((tokenRes) => {
              window.location = "/";
              isRefreshing = false;
              return Promise.reject("error");
            })
            .finally(() => {
              isRefreshing = false;
            });
        } else {
          console.log("加入等待队列");
          // 正在刷新token，返回一个未执行resolve的promise
          return new Promise((resolve) => {
            // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
            tryRequestQueue.push((token) => {
              // config.baseURL = '';
              response.config.headers["Authorization"] = "Bearer " + token;
              resolve(req(response.config));
            });
          });
        }
      }
    }
  );

  return req;
};

const securedAxios = () => {
  let clientId = localStorage.getItem("RETAIL_CLIENT");
  return axiosHelper({
    "Content-type": "application/json",
    Authorization: localStorage.getItem("USER_TOKEN"),
    "accept-language": localStorage.getItem("RC_LANG_KEY"),

    selectedClient: clientId,
  });
};

const unsecuredAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    "accept-language": localStorage.getItem("RC_LANG_KEY"),
  });
};

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
};

export default api;
