import api from "../configurations/http-common";

const autorize = (data) => {
  return api.unsecuredAxios().post("/web/user/authorize", data);
};

const sentOtp = (phone) => {
  return api.unsecuredAxios().post("/web/user/login/" + phone);
};
const verifyOtp = (phone) => {
  return api
    .unsecuredAxios()
    .post("/web/user/authorize/" + phone.phone + "/verify/" + phone.otp);
};

const forgotPassword = (userName) => {
  return api.securedAxios().get("/web/user/forgot_password/" + userName);
};
const resetPassword = (payload) => {
  return api
    .securedAxios()
    .put(
      "/web/user/reset_password/" + encodeURIComponent(payload.code),
      payload
    );
};

const getUserDetails = (userIdOrEmailOrPhone) => {
  return api.securedAxios().get("/api/v2/profile?id=" + userIdOrEmailOrPhone);
};
const updatePassword = (userName, payload) => {
  return api
    .securedAxios()
    .put("web/user/change_password/" + userName, payload);
};
const logout = () => {
  return api.securedAxios().get("web/user/revoke");
};

const updateUser = (userIdOrEmailOrPhone, payload) => {
  return api.securedAxios().put("web/user/" + userIdOrEmailOrPhone, payload);
};

const tokenRefresh = (payload) => {
  return api.unsecuredAxios().put("/web/user/refresh_token", payload);
};

const getUserById = (id) => {
  return api.securedAxios().get("api/mobile/user/" + id);
};

export const getVersionData = () => {
  return api.securedAxios().get("/api/v1/git/version");
};

const UserService = {
  autorize,
  sentOtp,
  verifyOtp,
  forgotPassword,
  resetPassword,
  getUserDetails,
  updatePassword,
  logout,
  updateUser,
  tokenRefresh,
  getUserById,
};

export default UserService;
