import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  FormGroup,
} from "@mui/material";
import { weekdays } from "../js/enum";
import RequirePoint from "@z/RequirePoint";

function Weekly(props) {
  const { addFormik } = props;

  const [everyDay, setEveryDay] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  useEffect(() => {
    if (selectedDays?.length === 7) {
      setEveryDay(true);
    } else {
      setEveryDay(false);
    }
  }, [selectedDays]);

  useEffect(() => {
    setSelectedDays(addFormik.values.days);
  }, []);

  const handleDayChange = (weekday) => {
    setSelectedDays((prevSelectedWeekdays) => {
      const daysArray = Array.isArray(prevSelectedWeekdays)
        ? prevSelectedWeekdays
        : [];
      const updatedDays = daysArray?.includes(weekday)
        ? daysArray.filter((day) => day !== weekday)
        : [...daysArray, weekday];
      addFormik?.setFieldValue("days", updatedDays);
      return updatedDays;
    });
  };

  const handleAllDays = () => {
    if (!everyDay) {
      setEveryDay(true);
      const allDays = weekdays?.map((day) => day.value); // 提取星期几的名称
      setSelectedDays(allDays);
      addFormik?.setFieldValue("days", allDays);
    } else {
      setEveryDay(false);
      setSelectedDays([]); // 清空选中的天
      addFormik?.setFieldValue("days", []); // 清空表单字段
    }
  };

  return (
    <Grid pt={2} lineHeight={3}>
      <Typography ml={3}>
        <RequirePoint />
        Include/Exclude days of the week
      </Typography>
      <Grid padding={3} container>
        <FormGroup row>
          {weekdays?.map((day, index) => {
            return (
              <Grid key={index} item xs={1.5} pr={3}>
                <Button
                  variant="contained"
                  color={
                    selectedDays?.includes(day?.value) ? "success" : "inherit"
                  }
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                    background: selectedDays?.includes(day?.value)
                      ? "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box"
                      : "white",
                  }}
                  onClick={() => handleDayChange(day?.value)}
                >
                  {day?.value.charAt(0)}
                </Button>
              </Grid>
            );
          })}
        </FormGroup>

        <Grid>
          <FormControlLabel
            style={{ paddingTop: 10 }}
            control={
              <Radio
                id={`role-radio-1}`}
                checked={everyDay}
                onClick={handleAllDays}
                value={everyDay}
                name="selection"
              />
            }
            label={"Weekly"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Weekly;
