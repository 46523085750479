import { Button, Grid } from "@mui/material";
import Header from "./Header";
import CenterArea from "./CenterArea";
import { useEffect, useState, useRef } from "react";
import LeftArea from "./LeftArea";
import TopRuler from "./LeftRuler";
import LeftRuler from "./TopRuler";
import ComponentProperties from "./ComponentProperties";
import "./editor.css";
import "./fonts/fonts.css";
import { useLocation, useNavigate } from "react-router-dom";
import { saveTemplate } from "../../services/TemplateService";
import { useSnackbar } from "notistack";
import { REACT_TEMPLATE } from "../../router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import RefreshToken from "../../configurations/RefreshToken";

const leftWidth = "240px";
const rightWidth = "280px";

export default function Editor() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [screenResolution, setScreenResolution] = useState("");
  const [name, setName] = useState("");
  const [screenOrientation, setScreenOrientation] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [splitArray, setSplitArray] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();
  const [layoutJSON, setLayoutJSON] = useState({
    bgColor: "#ffffff", //背景颜色
    bgImg: "", //背景图片
    opacity: 1, //透明度,
    width: 400,
    height: 400,
    showBorder: true,
    showRule: true,
    componentList: [],
  });

  const [payload, setPayload] = useState({
    id: "",
    name: "",
    screenResolution: "",
    model: "",
    screenOriantation: "",
    type: "",
    templateJson: "",
  });

  useEffect(() => {
    if (splitArray && splitArray.length === 2) {
      layoutJSON.width = parseInt(splitArray[0]);
      layoutJSON.height = parseInt(splitArray[1]);
      setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
    }
  }, [splitArray]);

  useEffect(() => {
    if (location.state) {
      let tJson = location.state.templateJson;
      if (tJson) {
        setLayoutJSON(JSON.parse(tJson));
      }

      let id = location.state.id;
      if (id) {
        setPayload({
          ...payload,
          id,
        });
        localStorage.setItem("editorId", id);
      } else {
        localStorage.removeItem("editorId");
      }

      setScreenResolution(location.state.screenResolution);
      let temp = location.state.screenResolution.split("*");
      setSplitArray(temp);
      setName(location.state.name);
      setScreenOrientation(location.state.screenOriantation);
      setModel(location.state.model);
      setType(location.state.type);
      localStorage.setItem("screenResolution", location.state.screenResolution);
      localStorage.setItem("name", location.state.name);
      localStorage.setItem(
        "screenOrientation",
        location.state.screenOriantation
      );
      localStorage.setItem("model", location.state.model);
      localStorage.setItem("type", location.state.type);
      localStorage.setItem("templateJson", tJson);
    } else {
      let id = localStorage.getItem("editorId");
      if (id) {
        setPayload({
          ...payload,
          id,
        });
      }
      let screenResolution = localStorage.getItem("screenResolution");
      let name = localStorage.getItem("name");
      let screenOrientation = localStorage.getItem("screenOrientation");
      let model = localStorage.getItem("model");
      let type = localStorage.getItem("type");
      let tJson = localStorage.getItem("templateJson");
      if (tJson) {
        setLayoutJSON(JSON.parse(tJson));
      }

      setScreenResolution(screenResolution);
      let temp = screenResolution.split("*");
      setSplitArray(temp);
      setSplitArray(screenResolution.split("*"));
      setName(name);
      setScreenOrientation(screenOrientation);
      setModel(model);
      setType(type);
    }
  }, []);

  const submitFrom = () => {
    let params = {
      ...payload,
      name: name,
      screenResolution: screenResolution,
      model: model,
      type: type,
      screenOriantation: screenOrientation,
      templateJson: JSON.stringify(layoutJSON),
    };
    setPayload(params);
    let message = t("tips_Template.success");
    if (params.id !== "") {
      message = t("tips_Template.updated");
    }
    if (!params.id) {
      delete params.id;
    }
    saveTemplate(params).then((res) => {
      enqueueSnackbar(message, { variant: "success" });
      navigate(REACT_TEMPLATE);
    });
  };

  const backList = () => {
    navigate(REACT_TEMPLATE);
  };

  const [activeId, setActiveId] = useState("");
  const [current, setCurrent] = useState(null);

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 30px",
          }}
        >
          <div> {name}</div>
          <div>
            <Button variant="outlined" onClick={backList}>
              {t("common.back")}
            </Button>
            <Button
              sx={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                opacity: "1",
                font: "normal normal normal 16px/28px Roboto",
                color: "#FFFFFF",
                ml: 2,
              }}
              onClick={submitFrom}
            >
              {t("common.save")}
            </Button>
          </div>
        </div>
      </Header>
      <Grid
        sx={{
          flexGrow: 1,
          display: "flex",
          maxHeight: "calc(100vh - 50px)",
        }}
      >
        <Grid
          sx={{
            width: leftWidth,
          }}
        >
          <LeftArea
            layoutJSON={layoutJSON}
            setLayoutJSON={setLayoutJSON}
          ></LeftArea>
        </Grid>
        <Grid
          sx={{
            position: "relative",
            flexGrow: 1,
            overflow: "hidden",
            backgroundColor: "aliceblue",
          }}
        >
          {layoutJSON.showRule && <TopRuler></TopRuler>}
          {layoutJSON.showRule && <LeftRuler></LeftRuler>}

          <CenterArea
            setActiveId={setActiveId}
            activeId={activeId}
            current={current}
            setCurrent={setCurrent}
            layoutJSON={layoutJSON}
            setLayoutJSON={setLayoutJSON}
          ></CenterArea>
        </Grid>
        <Grid
          sx={{
            width: rightWidth,
          }}
        >
          <ComponentProperties
            setActiveId={setActiveId}
            activeId={activeId}
            current={current}
            setCurrent={setCurrent}
            layoutJSON={layoutJSON}
            setLayoutJSON={setLayoutJSON}
          ></ComponentProperties>
        </Grid>

        <RefreshToken />
      </Grid>
    </Grid>
  );
}
