import React, { useEffect, useState } from "react";
import { getProductConfig } from "../js/config";
import { useTranslation } from "react-i18next";
import Accourdion from "@c/Accordion";
import CmpFormik from "@z/CmpFormik";

function ProductFilter(props) {
  const {
    addFormik,
    productConfig,
    setProductConfig,
    expandedIndex,
    handleChange,
    handleConfirm,
    brandData,
    productData,
    handlerCancel,
    productLabel,
  } = props;
  const { t } = useTranslation();

  const [selectLabel, setSelectLabel] = useState("");

  useEffect(() => {
    let res = productLabel?.find(
      (item) => item.id == addFormik.values.elementId
    );

    setSelectLabel(res?.name);
  }, [addFormik.values.elementId]);

  // useEffect(() => {
  //   addFormik.setFieldValue("productBrand", "");
  // }, [selectLabel]);

  useEffect(() => {
    let configInfo = getProductConfig(
      t,
      productLabel,
      brandData,
      productData,
      selectLabel
    );
    setProductConfig(configInfo);
  }, [productLabel, brandData, productData, selectLabel]);

  return (
    <Accourdion
      elevation={0}
      expanded={expandedIndex === 2}
      label={t("events.productFilter")}
      onChange={handleChange(2, ["elementId", "productBrand", "productId"])}
      handlerConfirm={handleConfirm(3, [
        "elementId",
        "productBrand",
        "productId",
      ])}
      handlerCancel={handlerCancel(1, [
        "elementId",
        "productBrand",
        "productId",
      ])}
    >
      <CmpFormik
        sx={4}
        formik={addFormik}
        formConfig={productConfig}
      ></CmpFormik>
    </Accourdion>
  );
}

export default ProductFilter;
