import api from "../configurations/http-common";

export const filterScreen = async ({
  pageNumber,
  pageSize,
  comapnyId,
  deviceId,
}) => {
  var params = "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
  if (comapnyId) params = params + "&comapnyId=" + comapnyId;
  if (deviceId) params = params + "&deviceId=" + deviceId;
  return api.securedAxios().get("web/screen/list" + params);
};

export const filterScreens = async (filters) => {
  let url =
    "web/screen/list?pageNumber=" +
    filters.pageNumber +
    "&pageSize=" +
    filters.pageSize;
  if (filters?.resolution) url = url + "&resolution=" + filters.resolution;
  if (filters?.companyId) url = url + "&comapnyId=" + filters.companyId;
  if (filters?.deviceId) url = url + "&deviceId=" + filters.deviceId;
  if (filters?.id) url = url + "&id=" + filters.id;
  if (filters?.operator) url = url + "&operator=" + filters.operator;
  return api.securedAxios().get(url);
};

export const getAllScreens = async () => {
  return api.securedAxios().get("web/screens/all");
};

export const filterScreenStartsWithId = async (filters) => {
  let url =
    "web/screens/starts_with/" +
    filters.id +
    "?pageNumber=" +
    filters.pageNumber +
    "&pageSize=" +
    filters.pageSize;
  if (filters?.operator) url = url + "&operator=" + filters.operator;
  return api.securedAxios().get(url);
};

export const filterScreenByDevice = async (deviceIds) => {
  let url = "web/screens/list?deviceIds=" + deviceIds;
  return api.securedAxios().get(url);
};

export const getEslRefreshEvent = async (deviceIds) => {
  let url = "api/v2/devices/event?deviceIds=" + deviceIds;
  return api.securedAxios().get(url);
};

export const getScreenById = async (id, deviceId) => {
  return api.securedAxios().get("web/screens/findById?" + "id=" + id + "&deviceId=" + deviceId);
};

export const updateScreenById = async (screenId, request) => {
  return api.securedAxios().put("web/screen/" + screenId, request);
};

export const updateScreenWithProductBind = async (payload) => {
  return api.securedAxios().put("web/screen/bind", payload);
};

export const getScreensById = async (screenIds) => {
  return api.securedAxios().get("web/screens?" + "screenIds=" + screenIds);
};

export const getBoundScreens = async () => {
  return api.securedAxios().get("web/screens/bound");
};

export const getScreenLogs = async (filters) => {
  let url = "web/system/logs?pageNumber=" + filters.pageNumber + "&pageSize=" +
   filters.pageSize;
  return api.securedAxios().get(url)
};
