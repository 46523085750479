import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  REACT_STATE_LIST,
  REACT_COUNTRY_LIST,
  REACT_CITY_LIST,
  REACT_CITYZONE_LIST,
  REACT_OUTLET_TYPE_LIST,
} from "../router/ReactEndPoints";
import CommonUtil from "../util/CommonUtils";

export default function LocationMenu(props) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const theme = useTheme();
  const data = [
    { name: `${t("LVLLOC0049")}`, index: 0, path: REACT_COUNTRY_LIST },
    { name: `${t("LVLLOC0050")}`, index: 1, path: REACT_STATE_LIST },
    { name: `${t("LVLLOC0051")}`, index: 2, path: REACT_CITY_LIST },
    { name: `${t("LVLLOC0052")}`, index: 3, path: REACT_CITYZONE_LIST },
    { name: `${t("LVLLOC0053")}`, index: 4, path: REACT_OUTLET_TYPE_LIST },
  ];
  return (
    <Grid
      style={{
        padding: "14px 12px",
        marginLeft: "-12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      // onClick={() => console.info('params')}
    >
      <Grid style={{ display: "flex", flexDirection: "row" }}>
        {!CommonUtil.isEmptyString(props.selected) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              border: "1px solid #EAEBEB",
              pl: 1,
              pr: 1,
            }}
          >
            {data.map(({ name, index, path }) => (
              <Box
                sx={{
                  cursor: "pointer",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderBottom:
                    props.selected === index
                      ? `4px solid ${theme.palette.primary.main}`
                      : "none",
                }}
                onClick={() => navigate(path)}
              >
                <Typography>{name}</Typography>
              </Box>
            ))}
          </Box>
        )}
        {CommonUtil.isEmptyString(props.selected) && (
          <Typography variant="title" gutterBottom component="div">
            {props.count ? props.title + " -  " : props.title}
          </Typography>
        )}
        {CommonUtil.isEmptyString(props.selected) && props.count && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 0,
              m: 0,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="value"
              gutterBottom
              component="div"
              style={{ color: "#36C96D", letterSpacing: 1, fontWeight: "bold" }}
            >
              {"  " + props.count}
            </Typography>
          </Box>
        )}

        {props.toolTip}
      </Grid>
      <Grid
        style={{
          cursor: "pointer",
        }}
      >
        {props.children}
      </Grid>
    </Grid>
  );
}
