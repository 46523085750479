import {
  Autocomplete,
  InputLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import RequirePoint from "@z/RequirePoint";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";

function AutocomplateProduct(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height = "40px",
    inputType,
    options,
    fontSize = "22px",
    readonly,
    defaultValue,
    typeValue = "0",
    noOptionsText,
    ...orther
  } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    const list = options?.find((item) => {
      return item?.value === formik.values[name];
    });

    setData(list);
    formik.setFieldValue(name, formik.values[name]);
  }, [options, formik.values]);

  return (
    <Stack>
      <Stack
        direction={labelPostion === "left" ? "row" : "column"}
        sx={{
          alignItems: labelPostion === "left" ? "flex-start" : "",
        }}
        spacing={spacing}
      ></Stack>
      <InputLabel
        shrink
        htmlFor={"CmpAutoComPlete_" + name}
        style={{
          marginTop: labelPostion === "left" ? "12px" : "",
          width: width,
          fontSize: fontSize,
        }}
      >
        {props.required && <RequirePoint></RequirePoint>}
        {label}
      </InputLabel>

      <Stack>
        <Autocomplete
          noOptionsText={noOptionsText}
          options={options}
          value={data}
          name={name}
          disabled={disabled}
          onChange={(event, newValue) => {
            formik.setFieldValue(name, newValue?.id);
            setData(newValue);
          }}
          isOptionEqualToValue={(option, value) => (option ? option?.name : "")}
          getOptionLabel={(option) => (option ? option?.label : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              readonly={readonly}
              placeholder={placeholder}
              disableMaskedInput={true}
            />
          )}
          {...orther}
        />
        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText
            error
            id={`standard-weight-helper-text-${name}`}
            sx={{
              mt: 2,
            }}
          >
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}

export default AutocomplateProduct;
