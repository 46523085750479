import { Box, Paper } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import OverFlowText from "../../components/OverFlowText";
import { useEffect, useState } from "react";
import {
  getPriceChangeEventById,
  getPriceChangeEvents,
} from "../../services/PriceChangeEventservice";
import { useParams } from "react-router-dom";
import { promotionType } from "./js/enum";
import { useTranslation } from "react-i18next";

export default function ViewPriceChangeEvent() {
  const { t } = useTranslation();
  const params = useParams();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
  });
  const [product, setProduct] = useState({});
  const [productName, setProductName] = useState();
  const [priceChangeRuleId, setPriceChangeRuleId] = useState();
  const [templateId, setTemplateId] = useState();
  const [scheduleTime, setScheduleTime] = useState();
  const [startAt, setStartAt] = useState();
  const [endAt, setEndAt] = useState();
  const [days, setDays] = useState([]);
  const [dates, setDates] = useState([]);

  const dateFormat = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const year = newDate.getFullYear();
    const hours = String(newDate.getHours()).padStart(2, "0");
    const minutes = String(newDate.getMinutes()).padStart(2, "0");
    const seconds = String(newDate.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    getPriceChangeEventById(params.id).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setProduct(res.data.data);

        setProductName(res.data.data.productName);
        setTemplateId(res.data.data.templateName);

        setScheduleTime(dateFormat(res.data.data.startAt));
        setStartAt(dateFormat(res.data.data.startAt));
        setEndAt(res.data.data?.endAt ? dateFormat(res.data.data.endAt) : null);
        setDays(res.data.data?.days?.map((day) => day + ", "));
        setDates(res.data.data?.dates?.map((date) => date + ", "));
        let resdata = promotionType.find(
          (item) => item.id == res.data.data.promotionType
        );

        setPriceChangeRuleId(resdata?.value);
      }
    });
  }, []);

  return (
    <RightViewLayout
      title={t("events.view_priceChangeEvent")}
      navigateBack={"-1"}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          paddingTop: "20px",
          paddingBottom: "36px",
          paddingLeft: "26px",
          border: "1px solid #DDDDDD",
          margin: "0 1rem 2rem 0",
          overflow: "hidden",
        }}
      >
        <Box>
          <Box pt={2}>
            <Box>
              <OverFlowText variant="fieldLabel">
                {t("table_product.product_name")} :
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {productName ? productName : "-"}
            </OverFlowText>
            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("events.priceChangeRule")} :
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {priceChangeRuleId ? priceChangeRuleId : "-"}
            </OverFlowText>
            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("events.templateName")} :
              </OverFlowText>
            </Box>

            <OverFlowText variant="fieldValue">
              {templateId ? templateId : "-"}
            </OverFlowText>
            {/* <Box mt={2}>
                            <OverFlowText variant='fieldLabel'>Schedule Time :</OverFlowText>
                        </Box> */}
            {/* <OverFlowText variant='fieldValue'>{scheduleTime  ? scheduleTime : '-'}</OverFlowText> */}
            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("events.startDate")} :
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {startAt ? startAt : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("events.endDate")} :
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {endAt ? endAt : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("events.days")} :
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {days ? days : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("events.dates")} :
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue" maxLength={50}>
              {dates ? dates : "-"}
            </OverFlowText>
          </Box>
        </Box>
      </Paper>
    </RightViewLayout>
  );
}
