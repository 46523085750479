import html2canvas from "html2canvas";
import PreView from "../editor/PreView";
import ReactDOM from "react-dom";
import axios from "axios";

export const updateComponentValues = (componentList, customPayload) => {
  componentList?.forEach((item) => {
    if (customPayload?.hasOwnProperty(item.type)) {
      if (item.type === "qrCode" || item.type === "barCode") {
        item.value = customPayload[item.type];
        item.text = customPayload[item.type];
      } else {
        item.value = customPayload[item.type];
      }
    }
  });
};

export const handlerPriceData = (
  priceEventList,
  payload,
  setPriceEventList,
  setTemplateList,
  customePayload
) => {
  let priceList = [];

  priceEventList.priceChangeEventList?.forEach((item) => {
    const templateJSON = JSON.parse(item.templateVO?.templateJson);
    updateComponentValues(templateJSON?.componentList, customePayload);
    item.templateVO.templateJson = JSON.stringify(templateJSON);
    priceList.push(item);
    setPriceEventList(priceList);
  });

  let templateList = [];
  priceEventList.templateList?.forEach((item) => {
    const templateJSON = JSON.parse(item?.templateJson);

    updateComponentValues(templateJSON?.componentList, customePayload);

    item.templateJson = JSON.stringify(templateJSON);
    templateList.push(item);
    setTemplateList(templateList);
  });

  return {
    priceList,
    templateList,
  };
};

export const getCurrencySymbol = (currency) => {
  if (currency === "INR") {
    return "\u20B9";
  }
  if (currency === "USD") {
    return "\u0024";
  }
  if (currency === "EU") {
    return "\u20AC";
  }
  if (currency === "RMB") {
    return "\u00A5";
  }
  if (currency === "THB") {
    return "\u0E3F";
  }
};

export const handlerUpdataFile = (item, action) => {
  return new Promise((resvoe, reject) => {
    // Step 1: Create an off-screen container
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    document.body.appendChild(tempContainer);

    let elementJSON =
      action === "1"
        ? JSON.parse(item?.templateJson)
        : JSON.parse(item.templateVO?.templateJson);

    // Step 2: Render the PreView component into the temporary container
    ReactDOM.render(
      <PreView layoutJSON={elementJSON} />,
      tempContainer,
      () => {
        setTimeout(() => {
          const width = parseInt(elementJSON.width, 10);
          const height = parseInt(elementJSON.height, 10);
          // 创建自定义 canvas 元素
          const canvas = document.createElement("canvas");
          let ctx1 = canvas.getContext("2d");
          ctx1.antialias = "none";
          ctx1.imageSmoothingEnabled = false;
          ctx1.textDrawingMode = "glyph";
          //设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
          canvas.width = width;
          canvas.height = height;

          html2canvas(tempContainer, {
            canvas,
            scale: 1,
            useCORS: true,
            allowTaint: true,
            // dpi: 600, // 处理模糊问题
          }).then(async (borderedCanvas) => {
            let ctx1 = borderedCanvas.getContext("2d");
            ctx1.antialias = "none";
            ctx1.imageSmoothingEnabled = false;
            ctx1.textDrawingMode = "glyph";
            borderedCanvas.style.imageRendering = "auto";

            const dataUrl = borderedCanvas.toDataURL("image/png");
            const byteString = atob(dataUrl?.split(",")[1]);
            const mimeString = dataUrl
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uintArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
              uintArray[i] = byteString.charCodeAt(i);
            }
            let productName = localStorage.getItem("productName");
            const blob = new Blob([arrayBuffer], { type: mimeString });

            blobToDataURI(blob, (result) => {
              console.log("HHHHHHHHHHH", result);
              console.log(
                "%c+",
                `font-size: 1px;
            padding: 100px 100px;
            background-image: url(` +
                  result +
                  `);
            background-size: contain;
            background-repeat: no-repeat;
            color: transparent;`
              );
            });

            let file = new File([blob], productName + ".png", {
              type: "image/png",
            });
            const formData = new FormData();
            formData.append("mediaType", "image/png");
            formData.append("file", file);
            const response = await axios({
              method: "post",
              url: process.env.REACT_APP_SERVER_URL + "/web/uploadFile",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("USER_TOKEN"),
              },
            });

            if (response?.data?.code === "LVLI0000") {
              const updatedItem = {
                ...item,
                imageUrl: response?.data?.data, // Add imageUrl to the item
              };

              resvoe(updatedItem);
              // return updatedItem; // Return the updated item
            } else {
              console.error("Upload failed");
              reject(null);
              // return null;
            }
          });
        });
      },
      100
    );
  });
};

export const blobToDataURI = (blob, callback) => {
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function (e) {
    callback(e.target.result);
  };
};

// const processItem = async (item, type) => {
//   try {
//     const res = await handlerUpdataFile(item, type);

//     if (res) {
//       // Set the appropriate state based on type
//       if (type === "0") {
//         setPriceData(res);
//       } else if (type === "1") {
//         setTemplateData(res);
//       }

//       // Construct params for UpdataPriceEvent API call
//       let params = {
//         productId: location.state.id,
//         templateImageList: [
//           {
//             templateId: res?.id,
//             imageUrl: res?.imageUrl,
//           },
//         ],
//         eventTemplateImageList: [
//           {
//             priceChangeEventId: res?.id,
//             templateId: res?.templateVO?.id,
//             imageUrl: res?.imageUrl,
//           },
//         ],
//       };

//       // Call UpdataPriceEvent API
//       const apiResponse = await UpdataPriceEvent(params);
//       if (apiResponse?.data?.code === "LVLI0000") {
//         enqueueSnackbar(apiResponse?.data?.message, {
//           variant: "success",
//         });
//       }
//     }
//   } catch (error) {
//     console.error("Error processing item:", error);
//   }
// };

// // Optimized processPriceList function
// const processPriceList = (priceList, templateList) => {
//   // Process priceList
//   priceList.forEach((item) => {
//     processItem(item, "0"); // Type "0" for priceList
//   });

//   // Process templateList
//   templateList.forEach((item) => {
//     processItem(item, "1"); // Type "1" for templateList
//   });
// };

// const processPriceList = (priceList, templateList) => {
//   for (const item of priceList) {
//     handlerUpdataFile(item, "0")
//       .then((res) => {
//         setPriceData(res);
//         let params = {
//           productId: location.state.id,
//           templateImageList: [
//             {
//               templateId: res?.id,
//               imageUrl: res?.imageUrl,
//             },
//           ],
//           eventTemplateImageList: [
//             {
//               priceChangeEventId: res?.id,
//               templateId: res?.templateVO?.id,
//               imageUrl: res?.imageUrl,
//             },
//           ],
//         };

//         UpdataPriceEvent(params).then((res) => {
//           if (res?.data?.code === "LVLI0000") {
//             enqueueSnackbar(res?.data?.message, {
//               variant: "success",
//             });
//           }
//         });
//       })
//       .catch((error) => {}); // This will now correctly log the data
//   }

//   for (const item of templateList) {
//     handlerUpdataFile(item, "1")
//       .then((res) => {
//         setTemplateData(res);

//         let params = {
//           productId: location.state.id,
//           templateImageList: [
//             {
//               templateId: res?.id,
//               imageUrl: res?.imageUrl,
//             },
//           ],
//           eventTemplateImageList: [
//             {
//               priceChangeEventId: res?.id,
//               templateId: res?.templateVO?.id,
//               imageUrl: res?.imageUrl,
//             },
//           ],
//         };

//         UpdataPriceEvent(params).then((res) => {
//           if (res?.data?.code === "LVLI0000") {
//             enqueueSnackbar(res?.data?.message, {
//               variant: "success",
//             });
//           }
//         });
//       })
//       .catch((error) => {}); // This will now correctly log the data
//   }
// };
