import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listClients } from "../services/CompanyService";
import { filterOutlet } from "../services/OutletService";
import { filterOutletType } from "../services/OutletTypeService";
import { getAllCountries } from "../services/LocationService";
import { getAllCityZones } from "../services/CityZoneService";
import { filterState } from "../services/StateService";
import { filterCity } from "../services/CityService";
import { ReactComponent as FilterIcon } from "../assets/images/icon_filter.svg";
import { ReactComponent as DownArrow } from "../assets/images/downArrow.svg"
import AppContext from "../context/AppContext";
import CommonUtil from "../util/CommonUtils";

const OutletFilter = (props) => {
  const { t } = useTranslation();
  const { selectedClient, setSelectedClient } = useContext(AppContext);
  const [country, setCountry] = useState({});
  const [countryForClient, setCountryForClient] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [cityZone, setCityZone] = useState({});
  const [client, setClient] = useState({});
  const [outletType, setOutletType] = useState({});
  const [outlet, setOutlet] = useState({});
  const [toggleFilter, setToggleFilter] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cityZones, setCityZones] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [filters, setFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    outletType: "",
    ids: [],
    clientId: "",
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
        localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });

  const defaultFilters = {
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    outletType: "",
    ids: [],
    clientId: "",
    companyIds: [],
  };

  const loadCompanies = () => {
    listClients({
      pageNumber: 1,
      pageSize: 1000,
      country: countryForClient?.id,
    }).then((res) => {
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.client
      ) {
        setClients(res?.data?.data?.client);
      }
    });
  };

  const loadOutlets = () => {
    filterOutlet(1, 1000, filters).then((response) => {
      if (response.data.code === "LMSI6000" && response.data.data) {
        setOutlets(response?.data?.data?.objects);
      } else if (response.data.code === "LVLE0054") {
        setOutlets([]);
      }
    });
  };

  const resetOutlet = () => {
    setOutlet(null);
    setOutlets([]);
  };

  useEffect(() => {
    filterOutletType(0, 0).then((res) => {
      if (res.data.code === "LVLI0000") {
        setOutletTypes(res.data.data);
      }
    });
    // loadCompanies();
    getAllCountries().then((res) => {
      if (res.data?.code === "LVLI0000") {
        setCountries(res.data.data);
      }
    });

    getAllCityZones().then((res) => {
      if (res.data.code === "LVLI0000") {
        setCityZones(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    let retailClient = JSON.parse(localStorage.getItem("selectedClient"));
    if (
      countries.length > 0 &&
      CommonUtil.isEmpty(countryForClient) &&
      retailClient?.country
    ) {
      let country_ = countries.find((c) => c.id === retailClient?.countryId);
      setCountryForClient(country_);
    }
  }, [countries]);

  useEffect(() => {
    let retailClient = JSON.parse(localStorage.getItem("selectedClient"));
    let country_ = countries.find((c) => c.id === retailClient?.countryId);
    if (clients.length > 0 && country_?.id) {
      setClient(retailClient);
    } else if (clients.length) {
      if (
        !CommonUtil.isEmpty(countryForClient) &&
        CommonUtil.isEmpty(client) &&
        CommonUtil.isEmpty(country) &&
        CommonUtil.isEmpty(cityZone) &&
        CommonUtil.isEmpty(outletType)
      ) {
        let rClients = [];
        clients.forEach((c) => rClients.push(c.id));
        let filters_ = { ...filters };
        filters_.companyIds = rClients;
        setFilters({ ...filters, ...filters_ });
      }
    }
  }, [clients]);

  useEffect(() => {
    console.log(filters)
    if (!_.isEqual(filters, defaultFilters)) {
      setOutlet(null);
      loadOutlets();
    }
  }, [filters]);

  useEffect(() => {
    console.log(country)
    setState(null);
    setCity(null);

    if (!CommonUtil.isEmpty(country)) {
      filterState(0, 0, country.id).then((res) => {
        if (res.data.code === "LVLI0000") {
          setStateList(res.data.data);
        }
      });

      let client_ = [];
      if (client?.id) {
        client_.push(client.id);
      }
      setFilters({
        ...filters,
        companyIds: client_,
        country: country?.id,
      });
    } else {
      setStateList([]);
      if (
        CommonUtil.isEmpty(countryForClient) &&
        CommonUtil.isEmpty(client) &&
        CommonUtil.isEmpty(cityZone) &&
        CommonUtil.isEmpty(outletType)
      ) {
        resetOutlet();
      } else if (client?.id) {
        setFilters({ ...filters, country: "" });
      } else {
        let rClients = [];
        clients.forEach((c) => rClients.push(c.id));
        let filters_ = { ...filters };
        filters_.country = "";
        filters_.companyIds = rClients;
        setFilters({ ...filters, ...filters_ });
      }
    }
  }, [country]);

  useEffect(() => {
    if (
      CommonUtil.isEmpty(country) &&
      CommonUtil.isEmpty(cityZone) &&
      CommonUtil.isEmpty(outletType)
    ) {
      setOutlet(null);
      setOutlets([]);
    }
    setClient(null);
    setClients([]);
    if (!CommonUtil.isEmpty(countryForClient)) {
      loadCompanies();
    }
  }, [countryForClient]);

  useEffect(() => {
    resetOutlet();
    if (!CommonUtil.isEmpty(client)) {
      setFilters({ ...filters, companyIds: [client?.id] });
    } else {
      if (
        !CommonUtil.isEmpty(country) ||
        !CommonUtil.isEmpty(cityZone) ||
        !CommonUtil.isEmpty(outletType)
      ) {
        setFilters({ ...filters, companyIds: [] });
      } else if (!CommonUtil.isEmpty(countryForClient) && clients.length > 0) {
        let rClients = [];
        clients.forEach((c) => rClients.push(c.id));
        setFilters({ ...filters, companyIds: rClients });
      } else if (country?.id) {
        setFilters({ ...filters, companyIds: [], country: country?.id });
      } else {
        let rClients = [];
        clients.forEach((c) => rClients.push(c.id));
        let filters_ = { ...filters };
        filters_.country = "";
        filters_.companyIds = rClients;
        setFilters({ ...filters, ...filters_ });
      }
    }
  }, [client]);

  useEffect(() => {
    setCity(null);

    if (!CommonUtil.isEmpty(state)) {
      filterCity(0, 0, state.id).then((res) => {
        if (res.data.code === "LVLI0000") {
          setCityList(res.data.data);
        }
      });
      let client_ = [];
      if (client?.id) {
        client_.push(client.id);
      }
      setFilters({
        ...filters,
        companyIds: client_,
        city: "",
        state: state?.id,
      });
    } else {
      setCityList([]);
      setFilters({ ...filters, city: "", state: "" });
    }
  }, [state]);

  const handleReset = () => {
    localStorage.setItem("selectedClientId", "-1");
    localStorage.setItem("selectedClient", JSON.stringify({}));
    setOutlet(null);
    setOutletType(null);
    setCityZone(null);
    setCity(null);
    setState(null);
    setCountry(null);
    setClient({});
    setCountryForClient({});
    setOutlets([]);
    setStateList([]);
    setCityList([]);
    setClients([]);
    setFilters({ ...defaultFilters });
    // loadCompanies();
    props.onReset();
  };

  const handleSubmit = () => {
    setToggleFilter(!toggleFilter);
    const filter = {
      country: country?.id,
      city: city?.id,
      state: state?.id,
      outletType: outletType?.id,
      cityZone: cityZone?.id,
      outlet: outlet?.id,
      client: filters.companyIds,
    };
    if (client && JSON.stringify(client) !== "{}") {
      setSelectedClient(client);
      localStorage.setItem("selectedClient", JSON.stringify(client));
      localStorage.setItem("selectedClientId", client?.id);
    } else {
      localStorage.setItem("selectedClientId", "-1");
      localStorage.setItem("selectedClient", JSON.stringify({}));
    }
    console.log(filter)
    props.onFilter(filter);
  };

  return (
    <>
      <Box
        sx={{ display: { xs: "flex", md: "none" }, width: "100%" }}
        flexDirection={"row-reverse"}
      >
        <Box item onClick={() => setToggleFilter(!toggleFilter)}>
          <FilterIcon width={"35"} height={"35"} className="pointer" />
        </Box>
      </Box>
    </>
  );
};

OutletFilter.defaultProps = {
  onClientChange: () => { },
};

export default OutletFilter;
