import { Autocomplete, Grid, InputLabel, FormHelperText } from "@mui/material";
import CustomInput from "@c/CustomInput";
import RequirePoint from "@z/RequirePoint";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";

function ZKAutocomplete(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height = "40px",
    inputType,
    options,
    fontSize = "22px",
    readonly,
    defaultValue,
    typeValue = "0",
    noOptionsText,
    ...orther
  } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    const list = options?.find((item) => {
      return item?.value === formik.values[name];
    });

    setData(list);
    formik.setFieldValue(name, formik.values[name]);
  }, [options, formik.values]);

  return (
    <Stack>
      <Stack
        direction={labelPostion === "left" ? "row" : "column"}
        sx={{
          alignItems: labelPostion === "left" ? "flex-start" : "",
        }}
        spacing={spacing}
      ></Stack>
      <InputLabel
        shrink
        htmlFor={"CmpAutoComPlete_" + name}
        style={{
          marginTop: labelPostion === "left" ? "12px" : "",
          width: width,
          fontSize: fontSize,
        }}
      >
        {props.required && <RequirePoint></RequirePoint>}
        {label}
      </InputLabel>

      <Stack>
        <Autocomplete
          noOptionsText={noOptionsText}
          options={options}
          value={data || ""}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={(event, newValue) => {
            formik.setFieldValue(name, newValue?.value); // 保存选中的 id 到 formik
            setData(newValue); // 设置 data 为选中的对象

            if (typeValue === "1") {
              formik.setFieldValue(name, newValue);
            } else if (typeValue === "2") {
              formik.setFieldValue(name, newValue?.id);
            } else if (typeValue === "3") {
              formik.setFieldValue(name, newValue?.value);
            } else {
              formik.setFieldValue(name, newValue?.value);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            if (typeValue === "1") {
              return option ? option : "";
            } else if (typeValue === "2") {
              return option ? option?.id : "";
            } else if (typeValue === "3") {
              return option ? option?.value : "";
            } else {
              return option ? option?.name : "";
            }
          }}
          getOptionLabel={(option) => {
            if (typeValue === "1") {
              return option ? option : "";
            } else if (typeValue === "2") {
              return option ? option?.id : "";
            } else if (typeValue === "3") {
              return option ? option?.value : "";
            } else {
              return option ? option?.name : "";
            }
          }}
          renderInput={(params) => (
            <CustomInput
              fullWidth
              value={data || ""}
              handleChange={(e) => {}}
              resetError={() => console.log("6666")}
              size={props.size ? props.size : " "}
              style={{ width: "100%" }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
            />
          )}
          {...orther}
        />
        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText
            error
            id={`standard-weight-helper-text-${name}`}
            sx={{
              mt: 2,
            }}
          >
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}

export default ZKAutocomplete;
