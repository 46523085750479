import { Grid, styled } from '@mui/material'
const LeftRuler = () => {

  return (
    <Grid sx={{
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '20px',
      top: '0px',
      zIndex: 100,
      backgroundColor: '#000000',
      overflow: 'hidden',
    }}>

      {new Array(200)
        .fill('1')
        .map((v, index) => {
          return index * 5
        })
        .map((v, index) => {
          return <Grid key={index} sx={{
            width: '50px',
            height: '100%',
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            backgroundImage: 'repeating-linear-gradient(90deg, #ffffff 0, #ffffff 1px, transparent 0, transparent 50px), repeating-linear-gradient(90deg, #ffffff 0, #ffffff 1px, transparent 0, transparent 5px)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0px 100%, 0px 100%',
            backgroundSize: '100% 100%, 100% 5px',
          }}>
            <span style={{
              fontSize: '12px',
              color: '#ffffff'
            }}> {(index + 1) * 50}</span>
          </Grid>
        })}
    </Grid>
  )
}

export default LeftRuler;