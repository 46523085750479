export default {
  menu: {
    retail_client_management: "Retail Client Management",
    template_list: "Template List",
    picture_library: "Picture Library",
    open_api_management: "Open API Management",
    dashboard: "Dashboard",
    system_setting: "System Setting",
    authorization_level: "Authorization Level",
    approval_flowchart: "Approval Flowchart",
    product_information: "Product Information",
    retail_main_data: "Retail Main Data",
    outlet: "Outlet",
    product: "Product",
    user_management: "User Management",
    user: "User",
    device_management: "Device Management",
    gateway: "Gateway",
    screens: "Screens",
    price_management: "Price Management",
    price_change_events: "Price Change Events",
    approval: "Approval",
    approval_list: "Approval List",
    approval_log: "Approval Log",
    system_records: "System Records",
    synchronized_logs: "Synchronized Logs",
    view_company: "View Company",
    about: "About",
    view_profile: "View Profile",
    logout: "Logout",
    english: "English",
    chinese: "Chinese",
    indonesian: "Indonesian",
    thai: "Thai",
    language: "Language",
    arabic: "Arabic",
    back_to_login: "Back to Login",
  },
};
