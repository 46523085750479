export default {
  login: {
    wrong_password: "Wrong Password",
  },

  about: {
    version: "Version",
    about_system: "Browser is recommended for this system",
    display_resolution: "Display Resolution",
    package_details: "Package Details",
    remaining_valid_days: "Remaining Valid Days:",
    remaining_device_count: "Remaining Device Count:",
    no_used: "No. of Used:",
    total_no_limit: "Total No. of Limit:",
  },

  company: {
    company_profile: "Company Profile",
  },

  user: {
    user_profile: "User's Profile",
  },

  retail_client: {
    add_retail_client: "Add Retail Client",
    edit_retail_client: "Edit Retail Client",
    view_retail_client: "View Retail Client",
    retail_client_name: "Retail Client Name",
    retail_client_code: "Retail Client Code",
    retail_admin_email: "Retail Admin Email",
    retail_client_first_name: "Retail Admin First Name",
    retail_client_last_name: "Retail Admin Last Name",
    number_of_devices: "Number of Devices",
    subscription_start_date: "Subscription Start Date",
    subscription_expire_date: "Subscription Expire Date",
    select_one_retaile: "Select a Retail Client Want to Operator",
  },

  template: {
    view_template: "View Template",
    add_template: "Add Template",
    edit_template: "Edit Template",
    template_editor: "Template Editor",
    name: "Template Name",
    resolution: "Resolution",
    type: "Template Type",
    screen_direction: "Screen Direction",
    preview: "Template Preview",
    screen_model: "Screen Model",
  },

  editor: {
    product_element: "Product Element",
    price_pule: "Price Rule",
    product_price: "Product Price",
    product_label: "Product Label",
    quantity: "Quantity",
    category1: "Category1",
    category2: "Category2",
    currency: "Currency",
    origin: "Origin",
    manufacturer: "Manufacturer",
    discount_price: "Discount Price",
    barcode: "Barcode",
    qrcode: "QR code",
    company_logo: "Company Logo",
    basic_tags: "Basic Tags",
    image_library: "Image Library",
    change_rule: "Change Rule",
    free_text: "Free Text",
    add_label: "Add Label",
    add_shape: "Add Shape",
    delete_element: "Delete Element",
    rounded_corners: "Rounded Corners:",
    content_example: "Content Example",
    bgcolor: "Background Color:",
    show_border: "Show Border:",
    show_scale: "Show Scale:",
    show_value: "Show Value:",
    font_color: "Font Color:",
    font_size: "Font Size:",
    font_style: "Font Style",
    font_weight: "Font Weight:",
    font: "Font:",
    spacing: "Spacing:",
    rotation_angle: "Rotation Angle:",
    width: "Width:",
    height: "Height:",
    opacity: "Opacity:",
    line_color: "Line Color:",
    foreground_color: "Foreground Color:",
    level: "Level",
    color: "Color",
    shape: "Shape",
    line_height: "Line Height",
  },

  openAPI: {
    open_api_client: "Open API Client",
    add_open_api: "Add Open API Client",
    secret_key: "Secret Key",
  },

  picture_library: {
    picture_library: "Picture Library",
    add_picture: "Add Picture",
    edit_picture: "Edit Picture",
    name: "Picture Name",
    id: "Picture ID",
    perview: "Picture Preview",
    type: "Picture Type",
    size: "Picture Size",
    progressing: "Picture Progressing",
  },

  dashboard: {
    welcome_back: "Welcome back!",
    login_for_next: "Your subscription has expired, You can login for next",
    days_only: " days only",
    subscription_expires: "Your subscription expires on ",
    title: "NuTag Dashboard",
    period_from_date: "Period From Date",
    from_date: "From Date",
    period: "Period",
    period_to_date: "Period To Date",
    registered_gateway: "Registered Gateway",
    registered_ESL: "Registered ESL",
    gateway_online_status: "Gateway Online Status",
    last_refreshed: "Last refreshed",
    refresh_success_rate: "Refresh Success Rate",
    total_refreshes: "Total Refreshes",
    total_number:
      "Total number of ESL updates within the specified time period",
    refreshes: "REFRESHES",
    devices_distribution: "Devices Distribution",
    esl_total_stores: "Number of stores with ESL vs. Total number of stores",
    esl_stores: "Stores with ESL Installed",
    total_stores: "Total Stores",
    refresh_events: "Refresh Events",
    time_in_hours: "Time In Hours",
    refresh_time: "Refresh Times",
    success_rate: "Success Rate",
    day: "Day",
    store: "STORE",
    all_stores: "ALL STORES",
    apply_filters: "APPLY FILTERS",
    period_from_date: "PERIOD FROM DATE",
    stores_installed: "Stores Installed",
    period_to_date: "PERIOD TO DATE",
    select_store: "Please select a store",
  },

  authorization: {
    title: "Authorization Level",
    add: "Add Authorization Level",
    edit: "Edit Authorization Level",
    view: "View Authorization Level",
    name: "Authorization Level Name",
    list: "Authorization Level List",
  },

  product: {
    title: "Product Information",
    add_product_label: "Add Product Label",
    sku: "Product SKU",
    category_level1: "Category Level 1",
    category_level2: "Category Level 2",
    category_level3: "Category Level 3",
    currency_symbol: "Currency Symbol",
    brand_name: "Brand Name",
    origin_of_product: "Origin Of Product",
    product: "Product",
    add_product: "Add Product",
    view_product: "View Product",
    edit_product: "Edit Product",
    product_picture: "Product Picture",
    product_price: "Product Price",
    discount_price: "Discount Price",
    serial_number: "S.No",
    number_one: "1",
    coca_cola: "Coca Cola",
    drink: "Drink",
    cold_drink: "Cold Drink",
    number_two: "2",
  },

  outlet: {
    title: "Outlet",
    add_outlet: "Add Outlet",
    edit_outlet: "Edit Outlet",
    view_outlet: "View Outlet",
    name: "Outlet Name",
    code: "Outlet Code",
  },

  user: {
    name: "User",
    person_list: "Person List",
    create_person: "Create Person",
    edit_person: "Edit Person",
    view_person: "View Person",
    person_name: "Person Name",
    person_Id: "Person ID",
  },

  gateway: {
    gateway: "Gateway",
    viewDeviceDetails: "View Device Details",
    addDeviceToOutlet: "Add Device To Outlet",
    bindDeviceToOutlet: "Bind Device To Outlet",
    gatewaySerialNumber: "Gateway Serial Number",
    gatewayName: "Gateway Name",
    gatewayIPAddress: "Gateway IP Address",
    gatewayFirmware: "Gateway Firmware",
    gatewayRunningStatus: "Gateway Running Status",
    gatewayUpdateTime: "Gateway Update Time",
    outlet: "Outlet",
    outletTimeZone: "Outlet Time Zone",
    versionNumber: "Version Number",
    gatewayType: "Gateway Type",
    gatewayModel: "Gateway Model",
    outletCode: "Outlet Code",
    logTime: "Log Time",
    logType: "Log Type",
    logCode: "Log Code",
    enable: "Enable",
    deviceName: "Device Name",
    deviceModel: "Device Model",
    serialNumber: "Serial Number",
    deviceType: "Device Type",
    outletId: "Outlet Id",
    clientName: "Client Name",
    deviceLog: "Device Log",
  },

  screen: {
    edit_screen: "Edit Screen",
    bind_to_screen: "Bind Data To Screen",
    screenId: "Screen ID",
    screen_resolution: "Screen Resolution",
    positionNo: "Position No",
    screenName: "Screen Name",
    powerStatus: "Power Status",
    gateway: "Gateway",
    outlet: "Outlet",
    bindToProduct: "Bind TO Product",
    templatePreview: "Template Preview",

    screenStatus: "Screen Status",
    screenUpdateTime: "Screen Update Time",
    gatewaySerialNumber: "Gateway Serial Number",
    gatewayName: "Gateway Name",
    deviceModel: "Device Model",
    deviceType: "Device Type",
    gatewayRunningStatus: "Gateway Running Status",
    unbindDevice: "Unbind Device",

    bindData: "Bind Data",
    normal: "Normal",
    lowPower: "Low Power",
    total: "Total",
  },

  events: {
    price_Change_Events: "Price Change Events",
    add_priceChangeEvent: "Add Price Change Event",
    edit_priceChangeEvent: "Edit Price Change Event",
    view_priceChangeEvent: "View Price Change Event",
    priceChangeName: "Price Change Name",
    priceChangeRule: "Price Change Rule",
    templateName: "Template Name",
    scheduleTime: "Schedule Time",
    approvalStatus: "Approval Status",
    areaFilter: "Area Filter",
    outlet: "Outlet",
    productFilter: "Product Filter",
    productElement: "Product Element",
    brand: "Brand",
    promotionType: "Promotion Type",
    templateSelection: "Template Selection",
    selectTemplate: "Select Template To Use For This Price Change Event",
    schedule: "Schedule",
    scheduleMode: "Schedule Mode",
    timeZone: "Time Zone",
    startPromotion: "Start Promotion",
    endPromotion: "End Promotion",
    doesNotEnd: "Does not end",
    setRepeatCondition: "Set Repeat Condition",
    immediateSync: "Immediate sync",
    startTime: "Start Time",
    endTime: "End Time",
    validYear: "Valid Year",
    validMonth: "Valid Month",
    validDate: "Valid Date",
    startDate: "Start Date",
    endDate: "End Date",
    days: "Days",
    dates: "Dates",
    allDay: "All Day",
  },

  approval: {
    approvalList: "Approval List",
    priceChangeDetails: "Price Change Details",
    viewApprovalRequest: "View Approval Request Of Price Change Event",
    approvalEvent: "Approval Event",
    maker: "Maker",
    createTime: "Create Time",
    priceChangeName: "Price Change Name",
    productFilter: "Product Filter",
    priceChangeRule: "Price Change Rule",
    priceChangeRuleName: "Price Change Rule Name",
    templateSelection: "Template Selection",
    templateName: "Template Name",
    schedule: "Schedule",
    timeZone: "Time Zone",
    startTime: "Start Time",
    endTime: "End Time",
    setRepeatCondition: "Set Repeat Condition",
    includeExcludeDays: "Include/Exclude Days of the Week",
    approve: "Approve",
    reject: "Reject",
    rejectUpper: "REJECT",
    approveUpper: "APPROVE",
    approvalLog: "Approval Log",
    approvalLogEvent: "Approval Event",
    approvalLogPerson: "Approval Person",
    approvalLogApprovalTime: "Approval Time",
    approvalLogStatus: "Approval Status",
  },

  synchronized: {
    systemRecords: "System Records",

    screenID: "Screen ID",
    outlet: "Outlet",
    product: "Product",
    deviceSN: "Device SN",
    status: "Status",
    picture: "Picture",

    // 新增
    mobile: "Mobile",
    address: "Address",
    country: "Country",
    stateProvince: "State/Province",
    city: "City",
    noRecordFound: "No Record Found.",
    clear: "Clear",
    confirm: "Confirm",
  },
};
