import React, { useEffect, useRef, useState } from "react";
import DataTable from "../../components/DataTable";

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import { ReactComponent as VisibilityIcon } from "../../assets/images/icon_viewoff.svg";
import { ReactComponent as VisibilityOffIcon } from "../../assets/images/icon_viewon.svg";

import ListLayout from "../../components/ListLayout";
import { useNavigate } from "react-router-dom";
import { REACT_THIRD_PARTY_ADD } from "../../router/ReactEndPoints";
import { deleteClientById } from "../../services/CompanyService";
import CommonUtil from "../../util/CommonUtils";
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { getAllCountries } from "../../services/LocationService";

import { useSnackbar } from "notistack";
import {
  listThirdPartyClient,
  listThirdPartyClients,
} from "../../services/ThirdPartyClientService";
export default function ThirdPartyClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryForClient, setCountryForClient] = useState({});
  const [filter, toggleFilter] = useState(false);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { enqueueSnackbar } = useSnackbar();
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
  });

  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
  };

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e });
  };

  const handlePageSize = (pageSize) => {
    setFilters({ ...filters, pageNumber: 0, pageSize: pageSize });
  };

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res.data?.code === "LVLI0000") {
        setCountries(res.data.data);
      }
    });
  }, []);
  useEffect(() => {
    setFilters({ ...filters, country: countryForClient?.id });
  }, [countryForClient]);

  useEffect(() => {
    loadData();
  }, [filters]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  const loadData = () => {
    listThirdPartyClient(filters).then((res) => {
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.thirdPartyUsers
      ) {
        setRecords(res?.data?.data?.thirdPartyUsers);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
      }
    });
  };
  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const [showSecretKey, setSecretKey] = useState({});

  const handleTogglePassword = (rowId) => {
    setSecretKey((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const columns = [
    {
      field: "name",
      headerName: t("table.name"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t("table.company_name"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.companyName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.companyName)}</span>
        </Tooltip>
      ),
    },

    {
      field: "SecretKey",
      headerName: t("openAPI.secret_key"),
      flex: 2,
      renderCell: (e) => (
        <Tooltip
          title={showSecretKey[e.row.id] ? "Hide SecretKey" : "Show SecretKey"}
          arrow
          placement="bottom"
        >
          <span>
            {showSecretKey[e.row.id] ? (
              <span>{e.row.apiSecret}</span>
            ) : (
              <span>********</span>
            )}
            <IconButton
              onClick={() => handleTogglePassword(e.row.id)}
              size="small"
            >
              {showSecretKey[e.row.id] ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      ),
    },

    {
      field: "appid",
      headerName: t("table.app_id"),
      flex: 2,
      renderCell: (e) => (
        <Tooltip title={e.row.appId} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.appId)}</span>
        </Tooltip>
      ),
    },

    /*  {
      headerName: t("common.actions"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (e) => (
        <IconHandaler>
          <Tooltip title={t("common.view")} arrow>
            <VisibilityIcon
              id="viewpersonlist"
              onClick={() => handleActions("View", e)}
              style={{ alignSelf: "center", paddingTop: "0px", cursor: "pointer", opacity: '0.6', height: '17px', width: '20px' }}
            />
          </Tooltip>
          <Tooltip title={t("common.edit")} arrow sx={{ pl: 1 }}>
            <EditIcon
              style={{ alignSelf: "center", paddingTop: "0px", paddingLeft: '10px', cursor: "pointer", opacity: '0.6', paddingLeft: "5px", height: '17px', width: '20px' }}
              onClick={() => handleActions("Edit", e)}
            />
          </Tooltip>

          <Tooltip title={t("common.edit")} arrow sx={{ marginLeft: 1 }}>
            <ForwardToInboxIcon
              sx={{
                "& .MuiSvgIcon-root": {
                  marginLeft: '0px'
                }
              }}
              onClick={() => handleActions("ResendEmail", e.row.id)}
              style={{ alignSelf: "center", paddingTop: "0px", cursor: "pointer", opacity: '0.6', paddingLeft: "5px", height: '17px', width: '20px' }}
            />
          </Tooltip>
          <Tooltip title="Delete" sx={{ pl: 1 }}>
            <DeleteIcon
              onClick={() => handleActions("Delete", e.row.id)}
              style={{ alignSelf: "center", paddingTop: "0px", cursor: "pointer", opacity: '0.6', paddingLeft: "5px", height: '17px', width: '20px' }}
            />
          </Tooltip>
        </IconHandaler>
      ),
    },
 */
  ];

  const handleDelete = (data) => {
    deleteClientById(data).then((response) => {
      if (response?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("LVLDAC0030"), {
          variant: "success",
        });
        setConfirm(false);
        loadData();
      }
      if (response?.data?.code !== "LVLI0002") {
        // enqueueSnackbar(response.data.message, { variant: "error" });Default User cannot be deleted.
        enqueueSnackbar(t("RETAL0002"), { variant: "error" });
        setConfirm(false);
      }
    });
  };

  const toolbarProps = {
    add: true,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_THIRD_PARTY_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
    },
    onFilter: (data) => {
      if (filter) {
        setCountryForClient(null);
      }
      toggleFilter(!filter);
    },
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
      operator: "AND",
    });
  };

  useEffect(() => {
    const suggestions = records?.filter(
      (record) =>
        record.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        record.code?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setSearchSuggestions(suggestions);
  }, [searchValue, records]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(); // 调用搜索函数
    }
  };

  const searchClientProps = () => {
    return (
      <Grid mt={2}>
        <TextField
          label={t("tips.search_by_name")}
          fullWidth
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
          InputProps={{
            style: {
              height: 50,
              width: "300px",
              background: "#fff",
              color: "#474B4F",
              opacity: "0.6",
              boxShadow: "0px 1px 3px #0000001A",
              borderRadius: "8px",
              border: "0px !important",
              padding: "10px",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id="clearButton"
                  onClick={() => {
                    setSearchValue("");
                    setFilters({
                      ...defaultFilters,
                      name: "",
                      operator: "AND",
                    });
                  }}
                  style={{ visibility: searchValue ? "visible" : "hidden" }} // 根据输入框内容控制可见性
                >
                  <ClearIcon />
                </IconButton>
                <IconButton id="retailClint" onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputLabel-root": {
              color: "#D1D1D1",
            },
          }}
        />
      </Grid>
    );
  };

  return (
    <ListLayout
      navigateBack={false}
      title={t("openAPI.open_api_client")}
      globalFilterProps={false}
      toolbarProps={toolbarProps}
      searchProps={searchClientProps()}
    >
      {filter && (
        <Box>
          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("table.country")}</Typography>
            <Autocomplete
              noOptionsText={t("table.country")}
              options={countries}
              value={countryForClient}
              onChange={(e, v) => {
                setCountryForClient(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
        </Box>
      )}
      <DataTable
        columns={columns}
        rows={records}
        onSelection={() => console.log()}
        page={filters.pageNumber}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDelete(id)}
            >
              {" "}
              Delete{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
