import React from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { Grid, Box, Typography } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  REACT_DASHBOARD,
  REACT_VIEW_USER_PROFILE,
} from "../../router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import UserService from "../../services/UserService";
import ViewPage from "../../components/ViewPage";
import CommonUtil from "../../util/CommonUtils";

export default function ViewUserProfile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    country: "",
    province: "",
    city: "",
    address: "",
  });

  useEffect(() => {
    let userId = JSON.parse(localStorage.getItem("USER"))?.id;
    UserService.getUserDetails(userId).then((res) => {
      setPayload(res?.data?.data);
    });
  }, []);

  return (
    <RightViewLayout navigateBack={"-1"} title={t("menu.view_profile")}>
      <ViewPage>
        <Box>
          <Typography variant="fieldLabel">{t("table.first_name")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.firstName ? payload.firstName : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.last_name")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.lastName ? payload.lastName : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.email")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.email ? payload.email : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("table.mobile_number")}
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload?.phone ? payload?.phone : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.city")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload?.city ? payload?.city : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.state")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload?.state ? payload?.state : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.country")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload?.country ? payload?.country : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.address")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload?.address ? payload?.address : "-"}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
