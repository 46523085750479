import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Close } from "../assets/images/icon_close.svg";

const SimpleDailogBox = (props) => {
  const styleDialog = {
    "& .MuiDialog-paper": {
      position: "absolute",
      boxShadow: 1,
      width: props.width ? props.width : "550px",
      borderRadius: "12px",
      padding: "1rem",
      top: "none",
    },
  };
  return (
    <>
      <Dialog
        id="DailogBox"
        sx={styleDialog}
        open={props.open}
        onClose={() => props.onClose()}
        style={{ backdropFilter: props?.backdropFilter ? props?.backdropFilter : 'none' }}
      >
        <Box>
          <Box display="flex" justifyContent={"space-between"}>
            <Typography
              component="div"
              gutterBottom
              style={{ color: "#474A4E", fontWeight: "bold" }}
            >
              {props.title}
            </Typography>
            <IconButton disableRipple onClick={props.onClose}>
              <Close />
            </IconButton>
          </Box>
          <Grid marginTop={"10px"}>{props.children}</Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default SimpleDailogBox;
