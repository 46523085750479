import { Box, Typography } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTemplateDetail } from "../../services/TemplateService";

export default function ViewTemplate() {
  const { t } = useTranslation();
  const [payload, setPayload] = useState({
    name: "",
    screenResolution: "",
    screenOriantation: "",
  });
  const { id } = useParams();

  useEffect(() => {
    getTemplateDetail(id).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setPayload({
          ...payload,
          ...res.data.data,
        });
      }
    });
  }, []);

  return (
    <RightViewLayout
      id="viewtempback"
      navigateBack={"-1"}
      title={t("template.view_template")}
    >
      <ViewPage>
        <Box mt={2}>
          <Typography variant="fieldLabel">{t("template.name")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.name ? payload.name : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("template.resolution")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.screenResolution ? payload.screenResolution : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("template.screen_direction")}:
          </Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.screenOriantation
            ? payload.screenOriantation
            : "-"}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
