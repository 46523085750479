import React, { useState, useEffect } from "react";
import Moveable from "react-moveable";
import { debounce } from "@mui/material/utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import BurstModeIcon from "@mui/icons-material/BurstMode";

import { confirmAlert } from "react-confirm-alert";
import ConfirmModal from "../../components/ConfirmModel";
import { t } from "i18next";

const ComponentList = (props) => {
  const targetRef = React.useRef(null);
  const moveableRef = React.useRef(null);
  const { layoutJSON, setLayoutJSON, current, isPreView } = props;

  const [frame, setFrame] = useState({
    translate: [0, 0],
  });

  function handleDragStart(e) {
    e.set(frame.translate);
  }
  function handleDrag(e) {
    frame.translate = e.beforeTranslate;
    e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px)`;
  }

  const deleteCmp = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("tips_Template.sure_delete")}
            onConfirm={() => {
              layoutJSON.componentList.splice(index, 1);
              setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const clickItem = (event, info) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.setActiveId) {
      props.setActiveId(info.id);
    }
    if (props.setCurrent) {
      props.setCurrent(info);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "40px",
        left: "40px",
      }}
    >
      <div
        className="target"
        ref={targetRef}
        style={{
          minHeight: "200px",
          minWidth: "200px",
          padding: "10px 10px",
          borderRadius: "15px",
          border: "1px solid gray",
        }}
      >
        <List>
          {layoutJSON?.componentList?.map((item, i) => {
            return (
              <ListItem
                sx={{
                  border: item.id === current?.id ? "1px solid gray" : "none",
                  borderRadius: "5px",
                }}
                onClick={(e) => {
                  clickItem(e, item);
                }}
                secondaryAction={
                  <IconButton
                    onClick={(e) => {
                      deleteCmp(i);
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar
                  sx={{
                    minWidth: 30,
                  }}
                >
                  <Avatar
                    sx={{ bgcolor: "#bdbdbd", width: 24, height: 24 }}
                    alt="Remy Sharp"
                  >
                    {i + 1}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    item.showName || item.type || item.value || item.text
                  }
                ></ListItemText>
              </ListItem>
            );
          })}
        </List>
      </div>
      <Moveable
        style={{ border: "none" }}
        target={targetRef} // moveable的对象
        draggable // 是否可以拖拽
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }} // padding距离
        zoom={1} // 缩放包裹的moveable
        origin={false} // 显示中心点
        className="center_Moveable"
        throttleDrag={0} // 拖拽阈值 达到这个值才执行拖拽
        onDragStart={handleDragStart} // 拖动开始执行
        onDrag={handleDrag} // 拖动中
      />
    </div>
  );
};

export default ComponentList;
