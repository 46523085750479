import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TitleBar from "./TitleBar";
import { listCompanies } from "../services/CompanyService";
import AppContext from "../context/AppContext";

export default function RightViewLayout(props) {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(
    JSON.parse(localStorage.getItem("selectedClient"))
  );
  const { setSelectedClient } = useContext(AppContext);

  useEffect(() => {
    if (client && JSON.stringify(client) !== "{}") {
      setSelectedClient(client);
      localStorage.setItem("selectedClient", JSON.stringify(client));
      localStorage.setItem("selectedClientId", client?.id);
    } else {
      localStorage.setItem("selectedClientId", "-1");
      localStorage.setItem("selectedClient", JSON.stringify({}));
    }
  }, [client]);

  useEffect(() => {
    if (clients.length > 0 && localStorage.getItem("selectedClient")) {
      setClient(JSON.parse(localStorage.getItem("selectedClient")));
    }
  }, [clients]);

  return (
    <Grid>
      <Grid width={"100%"}>
        <Grid spacing={3} xs={12} sx={{ display: "flex", pb: "10px" }}>
          <Grid xs={2} justifyContent={"flex-start"} m={2}>
            <TitleBar
              navigateBack={props.navigateBack}
              title={props.title ? props.title : ""}
              actions={props.actions}
            />
          </Grid>
          <Grid xs={9.5} display={"flex"} justifyContent={"flex-end"}>
            {props.searchProps}
          </Grid>
        </Grid>
        {props.children}
      </Grid>
    </Grid>
  );
}
