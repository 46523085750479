import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { ChromePicker } from "react-color";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const ColorPick = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(props.value || "");
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (color === "transparent") {
      setColor("#FD0000");
    }
  };

  const handleChangeColor = (aaa) => {
    setColor(
      `rgba(${aaa.rgb.r}, ${aaa.rgb.g}, ${aaa.rgb.b}, ${aaa.rgb.a ?? 1})`
    );
  };

  const open = Boolean(anchorEl);
  const sureClick = () => {
    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name,
          value: color,
        },
      });
    }
    setAnchorEl(null);
  };

  const clearFn = () => {
    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name,
          value: "transparent",
        },
      });
    }
    setColor("transparent");
    setAnchorEl(null);
  };

  const handleClose = () => {
    if (props.onChange) {
      props.onChange({
        target: {
          name: props.value,
          value: color,
        },
      });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setColor(props.value);
  }, [props.value]);

  return (
    <Grid>
      <Grid
        sx={{
          width: "20px",
          height: "20px",
          border: "1px solid #999",
          boxShadow: "1px 1px 3px #999",
          cursor: "pointer",
          backgroundImage:
            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)",
        }}
        onClick={handleClick}
      >
        <Grid
          sx={{
            width: "20px",
            height: "20px",
            backgroundColor: color,
          }}
        ></Grid>
      </Grid>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box sx={{ p: 1, backgroundColor: "#ffffff", boxShadow: "none" }}>
          <ChromePicker
            style={{ marginLeft: 20 }}
            color={color}
            onChangeComplete={handleChangeColor}
          />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={clearFn}>{t("synchronized.clear")}</Button>
            <Button onClick={sureClick}>{t("synchronized.confirm")}</Button>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  );
};

export default ColorPick;
