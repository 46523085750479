import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_RETAIL_CLIENT_LIST } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { Box, Button, Grid, InputAdornment, InputLabel } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { updateClient, getClientById } from "../../services/CompanyService";
import { useSnackbar } from "notistack";
import AddressFromMap from "../../components/AddressFromMap";
import CustomDatePicker from "../../components/CustomDatePicker";
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
dayjs.extend(utc);
export default function UpdateRetailClient() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const location = useLocation();

  const [subscriptionStartDate, setSubscriptionStartDate] = useState();
  const [subscriptionExpireDate, setSubscriptionExpireDate] = useState();
  const [numberOfDevices, setNumberOfDevices] = useState("");
  const [payload, setPayload] = useState({
    id: "",
    name: "",
    addressLine1: "",
    cityId: "",
    stateId: "",
    countryId: "",
    phone: "",
    code: "",
    email: "",
    superAdminFirstName: "",
    superAdminLastName: "",
    subscription: {
      numberOfDevices: "",
      subscriptionStartDate: null,
      subscriptionExpireDate: null,
      priceStrategyId: "",
    },
  });

  const [error, setError] = useState({
    id: "",
    name: "",
    addressLine1: "",
    cityId: "",
    stateId: "",
    countryId: "",
    phone: "",
    code: "",
    email: "",
    numberOfDevices: "",
    superAdminFirstName: "",
    superAdminLastName: "",
    subscriptionStartDate: "",
    subscriptionExpireDate: "",
  });

  useEffect(() => {
    if (location.state?.id) {
      getClientById(location.state?.id).then((res) => {
        if (res.data.code === "LVLI0000") {
          var client = {};
          client.id = res?.data?.data?.id;
          client.name = res?.data?.data?.name;
          client.code = res?.data?.data?.code;
          client.type = res?.data?.data?.type;
          client.addressLine1 = res?.data?.data.addressLine1;
          client.cityId = res?.data?.data?.cityId;
          client.stateId = res?.data?.data?.stateId;
          client.countryId = res?.data?.data?.countryId;
          client.phone = res?.data?.data?.phone;
          client.email = res?.data?.data?.email;
          client.superAdminFirstName = res?.data?.data?.firstName;
          client.superAdminLastName = res?.data?.data?.lastName;
          var parts = client?.phone?.split("-");
          var countryCode = parts ? parts[0] : "";
          var mobile = parts ? parts[1] : "";
          setCountryCode(countryCode);
          setPhoneNumber(mobile);

          const subscriptionStartDate =
            res?.data?.data?.subscription?.subscriptionStartDate.split("T")[0];

          const subscriptionExpireDate =
            res?.data?.data?.subscription?.subscriptionExpireDate.split("T")[0];

          setSubscriptionStartDate(subscriptionStartDate);
          setSubscriptionExpireDate(subscriptionExpireDate);
          setNumberOfDevices(res?.data?.data?.subscription?.numberOfDevices);

          setPayload({
            ...payload,
            ...client,
          });
        }
      });
    }
  }, []);

  const handleLocationFromMap = (data) => {
    const dataItem = data.content;
    const detailItem = dataItem?.address_detail;

    const baiduMap = localStorage.getItem("BAIDUMAP");
    if (baiduMap == "cn") {
      setPayload({
        ...payload,
        latitude: dataItem.point.x,
        longitude: dataItem.point.y,
        addressLine1: dataItem.address,
        countryId: detailItem.country,
        stateId: detailItem?.province,
        cityId: detailItem?.city,
        areaCode: detailItem?.city_code,
      });
      setError({
        ...error,
        latitude: "",
        longitude: "",
        addressLine1: "",
        countryId: "",
        stateId: "",
        cityId: "",
      });
    } else {
      if (data) {
        if (data.country) {
          let country = data.country;
          let state = data.state;
          let city = data.city;
          let areaCode = data.pincode;
          let addressDetail = country + state + city;

          setPayload({
            ...payload,
            latitude: data.lat,
            longitude: data.lng,
            addressLine1: data.addressLine1 ? data.addressLine1 : addressDetail,
            countryId: country,
            stateId: state,
            cityId: city,
            areaCode: areaCode,
          });
          setError({
            ...error,
            latitude: "",
            longitude: "",
            addressLine1: "",
            countryId: "",
            stateId: "",
            cityId: "",
          });
        }
      }
    }
  };

  // const handleLocationFromMap = (data) => {
  //   if (data) {
  //     if (data.country) {
  //       let country = data.country;
  //       let state = data.state;
  //       let city = data.city;
  //       let areaCode = data.pincode;

  //       setPayload({
  //         ...payload,
  //         latitude: data.lat,
  //         longitude: data.lng,
  //         addressLine1: data.addressLine1,
  //         countryId: country,
  //         stateId: state,
  //         cityId: city,
  //         areaCode: areaCode
  //       });
  //       setError({
  //         ...error,
  //         latitude: "",
  //         longitude: "",
  //         addressLine1: "",
  //         countryId: "",
  //         stateId: "",
  //         cityId: "",
  //       });
  //     }
  //   }
  // };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.code)) {
      setError({
        ...error,
        code: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.addressLine1)) {
      setError({
        ...error,
        addressLine1: t("tips.required"),
      });
      return;
    }
    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: t("tips.country_code_required"),
        });
        return;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: t("tips.mobild_nubmer"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("tips_retail_client.mobile_required"),
        });
        return;
      }
    }

    if (CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("tips.required"),
        });
        return false;
      }
    }
    if (CommonUtil.isEmptyString(numberOfDevices)) {
      setError({
        ...error,
        numberOfDevices: t("tips.required"),
      });
      return;
    }
    if (!subscriptionStartDate) {
      setError({
        ...error,
        subscriptionStartDate: t("tips.required"),
      });
      return;
    }
    if (!subscriptionExpireDate) {
      setError({
        ...error,
        subscriptionExpireDate: t("tips.required"),
      });
      return;
    }
    if (!dayjs(subscriptionStartDate).isValid()) {
      setError({
        ...error,
        subscriptionStartDate: t(
          "tips_retail_client.subscription_start_date_invalid"
        ),
      });
      return;
    }
    if (!dayjs(subscriptionExpireDate).isValid()) {
      setError({
        ...error,
        subscriptionExpireDate: t(
          "tips_retail_client.subscription_expire_date_invalid"
        ),
      });
      return;
    }
    if (
      dayjs(subscriptionExpireDate)
        .startOf("day")
        .isBefore(dayjs(subscriptionStartDate).startOf("day"))
    ) {
      enqueueSnackbar("Expiration date cannot before the start date.", {
        variant: "error",
      });
      return;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        phone: "+" + countryCode.replace("+", "") + "-" + phoneNumber,
        subscription: {
          numberOfDevices: numberOfDevices,
          subscriptionStartDate: dayjs(subscriptionStartDate).format(
            "YYYY-MM-DD 00:00:00"
          ),
          subscriptionExpireDate: dayjs(subscriptionExpireDate).format(
            "YYYY-MM-DD 23:59:59"
          ),
        },
      };
      updateClient(request).then((response) => {
        if (response.data.code === "LVLI0003") {
          enqueueSnackbar(t("tips_retail_client.updated_success"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_RETAIL_CLIENT_LIST);
          return;
        }

        switch (response.data.code) {
          case "UASE0060":
            setError({
              ...error,
              name: response.data.message,
            });
            break;
          case "UASE0020":
            setError({
              ...error,
              ownerEmail: response.data.message,
            });
            break;
          case "UASE0011":
            setError({
              ...error,
              code: response.data.message,
            });
            break;
          default:
            enqueueSnackbar(response.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "numberOfDevices") {
      setNumberOfDevices(value);
    } else {
      setPayload({
        ...payload,
        [name]: value,
      });
    }

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  let date = new Date(subscriptionStartDate);
  let minDate = date.setDate(date.getDate() + 1);

  return (
    <RightViewLayout
      navigateBack={REACT_RETAIL_CLIENT_LIST}
      title={t("retail_client.edit_retail_client")}
    >
      <Grid sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box" }}>
        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="UpdateRetailClint1"
              required
              label={t("retail_client.retail_client_name")}
              size="small"
              name="name"
              error={error.name}
              resetError={() => setError({ ...error, name: "" })}
              value={payload.name}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.name}
              placeholder={t("tips_retail_client.name")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="UpdateRetailClint2"
              label={t("retail_client.retail_client_code")}
              placeholder={t("tips_retail_client.code")}
              size="small"
              name="code"
              error={error.code}
              resetError={() => setError({ ...error, code: "" })}
              value={payload.code}
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="UpdateRetailClint2"
              label={t("table.email")}
              placeholder={t("tips_retail_client.email")}
              size="small"
              name="email"
              error={error.email}
              resetError={() => setError({ ...error, email: "" })}
              value={payload.email}
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ marginBottom: "-11px" }}
            >
              {t("table.mobile")} {<span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <CustomePhoneFiled
              id="UpdateRetailClientPhone"
              error={error.phone}
              resetError={() => setError({ ...error, phone: "" })}
              handleCountryCode={(data) => {
                setCountryCode(data.dialCode);
                setError({ ...error, phone: "" });
              }}
              countryCode={countryCode}
              placeholder={t("table.mobile")}
              phoneNumber={phoneNumber}
              name={"phoneNumber"}
              size={"small"}
              handleChange={(e) => {
                setPhoneNumber(e);
                setError({ ...error, phone: "" });
              }}
              label={"table.mobile"}
              disabled={true}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddRetailClint4"
              required
              label={t("table.first_name")}
              size="small"
              name="superAdminFirstName"
              value={payload.superAdminFirstName}
              error={error.superAdminFirstName}
              resetError={() => setError({ ...error, superAdminFirstName: "" })}
              helperText={error.superAdminFirstName}
              inputProps={{
                maxLength: 60,
              }}
              validation="alpha-numeric"
              handleChange={handleChange}
              placeholder={t("table.first_name")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddRetailClint5"
              label={t("table.last_name")}
              size="small"
              name="superAdminLastName"
              value={payload.superAdminLastName}
              error={error.superAdminLastName}
              resetError={() => setError({ ...error, superAdminLastName: "" })}
              helperText={error.superAdminLastName}
              inputProps={{
                maxLength: 60,
              }}
              validation="alpha-numeric"
              handleChange={handleChange}
              placeholder={t("table.last_name")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomInput
              id="EditOutlet3"
              required
              label={t("table.address")}
              placeholder={t("common_tips.address")}
              size="small"
              name="addressLine1"
              value={payload.addressLine1}
              error={error.addressLine1}
              helperText={error.addressLine1}
              resetError={() => setError({ ...error, addressLine1: "" })}
              inputProps={{ maxLength: 255 }}
              handleChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AddressFromMap
                      onSelectLocation={(address) =>
                        handleLocationFromMap(address)
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddOutlet22"
              label={t("table.country")}
              size="small"
              name="countryId"
              error={error.countryId}
              resetError={() => setError({ ...error, countryId: "" })}
              value={payload.countryId}
              handleChange={handleChange}
              inputProps={{
                maxLength: 30,
              }}
              helperText={error.countryId}
              validation="alpha-numeric-ch-th"
              placeholder={t("common_tips.country_name")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddOutlet22"
              label={t("table.state")}
              size="small"
              name="stateId"
              error={error.stateId}
              resetError={() => setError({ ...error, stateId: "" })}
              value={payload.stateId}
              handleChange={handleChange}
              inputProps={{
                maxLength: 30,
              }}
              helperText={error.stateId}
              validation="alpha-numeric-ch-th"
              placeholder={t("common_tips.state_name")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddOutlet22"
              label={t("table.city")}
              size="small"
              name="cityId"
              error={error.cityId}
              resetError={() => setError({ ...error, cityId: "" })}
              value={payload.cityId}
              handleChange={handleChange}
              inputProps={{
                maxLength: 30,
              }}
              helperText={error.cityId}
              validation="alpha-numeric-ch-th"
              placeholder={t("common_tips.city_name")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              required
              label={t("retail_client.number_of_devices")}
              size="small"
              name="numberOfDevices"
              value={numberOfDevices}
              error={error.numberOfDevices}
              resetError={() => setError({ ...error, numberOfDevices: "" })}
              helperText={error.numberOfDevices}
              inputProps={{
                maxLength: 4,
              }}
              placeholder={t("retail_client.number_of_devices")}
              validation="numericWithoutDot"
              handleChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomDatePicker
              required
              size={"small"}
              disableFuture={false}
              date={subscriptionStartDate}
              disabled={false}
              minDate={new Date()}
              label={t("retail_client.subscription_start_date")}
              placeholder={t("common_tips.start_date")}
              SelectedDate={(e) => {
                setSubscriptionStartDate(e);
                setError({
                  ...error,
                  subscriptionStartDate: null,
                });
              }}
              error={error.subscriptionStartDate}
              helperText={error.subscriptionStartDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomDatePicker
              required
              size={"small"}
              disableFuture={false}
              date={subscriptionExpireDate}
              disabled={false}
              minDate={minDate}
              label={t("retail_client.subscription_expire_date")}
              placeholder={t("common_tips.end_date")}
              SelectedDate={(e) => {
                setSubscriptionExpireDate(e);
                setError({
                  ...error,
                  subscriptionExpireDate: null,
                });
              }}
              error={error.subscriptionExpireDate}
              helperText={error.subscriptionExpireDate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} px={2} marginTop={"2px"}>
          <Grid item xs={12} mb={2}>
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Box item pl={2}>
                <Button
                  id="updateRetailClint1"
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  style={{
                    size: "medium",
                    borderRadius: "8px",
                    opacity: 1,
                    background:
                      "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                  }}
                  onClick={handleSubmit}
                >
                  {" "}
                  {t("common.save")}{" "}
                </Button>
              </Box>
              <Box item>
                <Button
                  id="updateretailClint2"
                  className="text-transform-none"
                  variant="outlined"
                  size="large"
                  onClick={() => navigate(REACT_RETAIL_CLIENT_LIST)}
                >
                  {" "}
                  {t("common.back")}{" "}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
