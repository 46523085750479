import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import axios from "axios";
import { Box, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RightViewLayout from "../../components/RighViewLayout";
import {
  REACT_TEMPLATE_EDIT,
  REACT_TEMPLATE_LIST,
} from "../../router/ReactEndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { attributeList } from "../../services/ProductService";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import html2canvas from "html2canvas";

export default function TemplateUpdateEditor() {
  const { t } = useTranslation();
  const [canvasColor, setCanvasColor] = useState("white");
  const [screenResolution, setScreenResolution] = useState("");
  const [name, setName] = useState("");
  const [screenOrientation, setScreenOrientation] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [templateObjectKey, setTemplateObjectKey] = useState("");
  const location = useLocation();
  const [splitArray, setSplitArray] = useState([]);
  const [templateImage, setTemplateImage] = useState();
  const [id, setId] = useState("");
  const [imageObjectKey, setImageObjectKey] = useState("");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const canvasRef = useRef(null);
  const [textColor, setTextColor] = useState("black");
  const [dynamicFontStyle, setDynamicFontStyle] = useState("bold");
  const [dynamicFontSize, setDynamicFontSize] = useState("22");
  const [boxHeight, setBoxHeight] = useState(100); // Initial height
  const [boxWidth, setBoxWidth] = useState(100);
  const [activeElement, setActiveElement] = useState(null);
  const [selectedButton, setSelectedButton] = useState("red");
  const [activeHeight, setActiveHeight] = useState(0);
  const [activeWidth, setActiveWidth] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [newLabels, setNewLabels] = useState([]);
  const [selectedShape, setSelectedShape] = useState("square");
  const canvasHeight = splitArray[1]; // Adjust the canvas width as needed
  const canvasWidth = splitArray[0]; // Adjust the canvas height as needed
  const [payload, setPayload] = useState({
    name: "",
    screenOriantation: "",
    screenResolution: "",
    templatePhoto: "",
    fileExtension: "",
    size: "",
    model: "",
    type: "",
    templateObjectKey: "",
  });
  const [labels, setLabels] = useState([]);
  const initializeCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.dispose();
    }

    let canvas = new fabric.Canvas("canvas", {
      height: canvasHeight,
      width: canvasWidth,
      backgroundColor: canvasColor,
      selection: false,
      renderOnAddRemove: true,
    });

    canvas
      .on("selection:updated", (e) => {
        let active = canvas.getActiveObject();
        setActiveElement(active);
        if (active) {
          let type = active.type;
          if (type === "image") {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            let canvasWidth = canvas.width;
            let canvasHeight = canvas.height;

            if (resultWidth > canvasWidth) {
              scaleX = canvasWidth / active.width;
            }

            if (resultHeight > canvasHeight) {
              scaleY = canvasHeight / active.height;
            }

            active.set({
              scaleX: scaleX,
              scaleY: scaleY,
            });

            canvas.renderAll(); // Corrected line
            let activeNew = canvas.getActiveObject();
            setActiveElement(activeNew);
          } else {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            let type = active.type;
            if (type === "i-text") {
              setDynamicFontSize(parseInt(active.fontSize));
              setDynamicFontStyle(active.fontStyle);
            }
            setActiveHeight(resultHeight);
            setActiveWidth(resultWidth);
            active.set({
              width: resultWidth,
              height: resultHeight,
              scaleX: 1,
              scaleY: 1,
            });
            canvas.renderAll(); // Corrected line
            let activeNew = canvas.getActiveObject();
            setActiveElement(activeNew);
          }
        }
      })
      .on("mouse:up", (e) => {
        let active = canvas.getActiveObject();
        setActiveElement(active);
        if (active) {
          let type = active.type;
          if (type === "image") {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            let canvasWidth = canvas.width;
            let canvasHeight = canvas.height;
            if (resultWidth > canvasWidth) {
              scaleX = canvasWidth / active.width;
            }
            if (resultHeight > canvasHeight) {
              scaleY = canvasHeight / active.height;
            }
            active.set({
              scaleX: scaleX,
              scaleY: scaleY,
            });
            canvas.renderAll(); // Corrected line
            let activeNew = canvas.getActiveObject();
            setActiveElement(activeNew);
            movingFn(activeNew);
          } else {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            if (type === "i-text") {
              setDynamicFontSize(parseInt(active.fontSize));
              setDynamicFontStyle(active.fontStyle);
            }
            setActiveHeight(resultHeight);
            setActiveWidth(resultWidth);
            active.set({
              width: resultWidth,
              height: resultHeight,
              scaleX: 1,
              scaleY: 1,
            });
            canvas.renderAll(); // Corrected line
            let activeNew = canvas.getActiveObject();
            setActiveElement(activeNew);
            movingFn(activeNew);
          }
        }
      });

    canvasRef.current = canvas;
  };

  useEffect(() => {
    attributeList().then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        const newLabelsData = res.data.data;
        const updatedNewLabels = {};
        const newLabelNames = [];

        newLabelsData.forEach((item) => {
          if (item.label) {
            updatedNewLabels[item.name] = item.name; // Using item.name as key
            newLabelNames.push(item.name); // Collecting the names in an array
          }
        });

        setNewLabels(updatedNewLabels);

        const initialLabels = [
          t("EDTE0000"),
          t("EDTE0001"),
          t("EDTE0003"),
          t("EDTE0004"),
          t("EDTE0002"),
          t("EDTE0006"),
          t("EDTE0007"),
          type === "Discount" ? t("EDTE0011") : null,
        ];

        const combinedLabels = initialLabels.concat(
          newLabelNames.map((name) => updatedNewLabels[name])
        );

        setLabels(combinedLabels.filter((label) => label !== null));
      }
    });
    const initializeState = () => {
      const state = location.state;
      if (state) {
        setScreenResolution(state.screenResolution);
        setSplitArray(state.screenResolution.split("*"));
        setName(state.name);
        setScreenOrientation(state.screenOriantation);
        setModel(state.model);
        setType(state.type);
        setTemplateObjectKey(state.templateObjectKey);
        setTemplateImage(state.templateImage);
        setId(state.id);
        setImageObjectKey(state.imageObjectKey);
        localStorage.setItem("screenResolution", state.screenResolution);
        localStorage.setItem("name", state.name);
        localStorage.setItem("screenOrientation", state.screenOriantation);
        localStorage.setItem("model", state.model);
        localStorage.setItem("type", state.type);
        localStorage.setItem("templateObjectKey", state.templateObjectKey);
        localStorage.setItem("templateImage", state.templateImage);
      } else {
        const screenResolution = localStorage.getItem("screenResolution");
        const name = localStorage.getItem("name");
        const screenOrientation = localStorage.getItem("screenOrientation");
        const model = localStorage.getItem("model");
        const type = localStorage.getItem("type");
        setScreenResolution(screenResolution);
        setSplitArray(screenResolution.split("*"));
        setName(name);
        setScreenOrientation(screenOrientation);
        setModel(model);
        setType(type);
        setTemplateObjectKey(localStorage.getItem("templateObjectKey"));
        setTemplateImage(localStorage.getItem("templateImage"));
      }
    };

    const loadTemplateData = () => {
      if (templateImage) {
        fetch(templateImage)
          .then((response) => response.text())
          .then((data) => {
            if (canvasRef.current) {
              canvasRef.current.clear();
              drawCanvas(data);
            }
          })
          .catch((error) => console.error("Error fetching HTML data:", error));
      }
    };

    initializeState();
    initializeCanvas();
    loadTemplateData();

    let active = canvasRef.current.getActiveObject();
    setActiveElement(active);

    const handleKeyDown = (e) => {
      const movementAmount = 5;
      const activeObject = canvasRef.current.getActiveObject();
      if (activeObject) {
        let newLeft = activeObject.left;
        let newTop = activeObject.top;
        if (e.key === "ArrowLeft") {
          newLeft = Math.max(0, activeObject.left - movementAmount);
        } else if (e.key === "ArrowRight") {
          newLeft = Math.min(
            canvasWidth - activeObject.width,
            activeObject.left + movementAmount
          );
        } else if (e.key === "ArrowUp") {
          newTop = Math.max(0, activeObject.top - movementAmount);
        } else if (e.key === "ArrowDown") {
          newTop = Math.min(
            canvasHeight - activeObject.height,
            activeObject.top + movementAmount
          );
        }

        activeObject.set({
          left: newLeft,
          top: newTop,
        });
        canvasRef.current.renderAll();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Delete") {
        const activeObject = canvasRef.current.getActiveObject();
        if (activeObject) {
          canvasRef.current.remove(activeObject);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null; // Ensure ref is nullified after disposal
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      }
    };
  }, [
    canvasColor,
    screenResolution,
    templateImage,
    canvasHeight,
    canvasWidth,
    location.state,
  ]);

  const drawCanvas = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("div > div, img");
    elements.forEach((element) => {
      const style = element.getAttribute("style");

      if (element.tagName === "IMG") {
        const src = element.getAttribute("src");
        const styleProperties = style.split(";").map((prop) => prop.trim());
        const left = parseFloat(
          styleProperties
            .find((prop) => prop.startsWith("left"))
            .match(/left:\s*(\d+(?:\.\d+)?)px/)[1]
        );
        const top = parseFloat(
          styleProperties
            .find((prop) => prop.startsWith("top"))
            .match(/top:\s*(\d+(?:\.\d+)?)px/)[1]
        );
        const getLastStyleValue = (property) => {
          for (let i = styleProperties.length - 1; i >= 0; i--) {
            if (styleProperties[i].startsWith(property)) {
              return parseFloat(
                styleProperties[i].match(
                  new RegExp(`${property}:\\s*(\\d+(?:\\.\\d+)?)px`)
                )[1]
              );
            }
          }
          return null;
        };

        const width = getLastStyleValue("width");
        const height = getLastStyleValue("height");
        if (src) {
          addImageHTMLToCanvas(src, left, top, width, height);
        }
      } else {
        var textAlign = "";
        const text = element.innerText;
        const src = element.getAttribute("src");
        const styleProperties = style.split(";").map((prop) => prop.trim());
        const getPropertyValue = (properties, propertyName) => {
          const property = properties.find((prop) =>
            prop.startsWith(propertyName)
          );
          return property ? property.split(":")[1].trim() : null;
        };
        const left = parseFloat(getPropertyValue(styleProperties, "left"));
        const top = parseFloat(getPropertyValue(styleProperties, "top"));
        const width = getPropertyValue(styleProperties, "width")
          ? parseFloat(getPropertyValue(styleProperties, "width"))
          : null;
        const height = getPropertyValue(styleProperties, "height")
          ? parseFloat(getPropertyValue(styleProperties, "height"))
          : null;
        const fontSize = getPropertyValue(styleProperties, "font-size")
          ? parseFloat(getPropertyValue(styleProperties, "font-size"))
          : null;
        const fontFamily = getPropertyValue(styleProperties, "font-family");
        const color = getPropertyValue(styleProperties, "color");
        const backgroundColor = getPropertyValue(
          styleProperties,
          "background-color"
        );
        const fontStyle = getPropertyValue(styleProperties, "font-style");
        if (getPropertyValue(styleProperties, "text-align") === null) {
          textAlign = "";
        } else {
          textAlign = getPropertyValue(styleProperties, "text-align");
        }

        if (backgroundColor) {
          const borderRadius = styleProperties.find((prop) =>
            prop.startsWith("border-radius")
          )
            ? styleProperties
                .find((prop) => prop.startsWith("border-radius"))
                .match(/border-radius:\s*(\d+)%/)[1]
            : null;

          if (borderRadius === "50") {
            // If border-radius is 50%, it's a circle
            const radius = width / 2; // Assuming width equals height for circles
            addCircleHTMLToCanvas(backgroundColor, left, top, radius);
          } else {
            addBoxHTMLToCanvas(backgroundColor, left, top, width, height);
          }
        }

        if (text) {
          addElementHTMLToCanvas(
            text,
            left,
            top,
            width,
            height,
            fontSize,
            fontFamily,
            color,
            fontStyle,
            textAlign
          );
        }

        if (src) {
          addImageHTMLToCanvas(src, left, top, width, height);
        }
      }
    });
  };

  const addCircleHTMLToCanvas = (backgroundColor, left, top, radius) => {
    const shape = new fabric.Circle({
      left,
      top,
      radius,
      fill: backgroundColor,
      stroke: "black",
      lockRotation: true,
      lockScalingX: false,
      lockScalingY: false,
    });

    canvasRef.current.add(shape);
  };

  const addImageHTMLToCanvas = (src, left, top, width, height) => {
    fabric.Image.fromURL(src, (img) => {
      img.set({
        left,
        top,
        scaleX: width / img.width,
        scaleY: height / img.height,
      });
      canvasRef.current.add(img);
    });
  };

  const addElementHTMLToCanvas = (
    text,
    left,
    top,
    width,
    height,
    fontSize,
    fontFamily,
    color,
    fontStyle,
    textAlign
  ) => {
    const textBox = new fabric.IText(text, {
      left: left,
      top: top,
      fontSize: fontSize, // Set as number
      fontFamily: fontFamily,
      fill: color, // Set as string ('normal', 'bold', etc.)
      fontStyle: fontStyle, // Set as string ('normal', 'italic', 'oblique')
      textAlign: textAlign, // Add textAlign here
      editable: false,
      lockRotation: true,
      lockScalingX: false,
      lockScalingY: false,
      width: width,
      height: height,
    });

    // Set the width and height after initializing the text box
    textBox.set({
      width: width,
      height: height,
    });
    canvasRef.current.add(textBox);
  };

  const addBoxHTMLToCanvas = (backgroundColor, left, top, width, height) => {
    const shape = new fabric.Rect({
      left,
      top,
      width,
      height,
      fill: backgroundColor,
      stroke: "black",
      lockRotation: true,
      lockScalingX: false,
      lockScalingY: false,
    });

    canvasRef.current.add(shape);
  };

  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectLabel, setSelectLabel] = useState("");

  const changeTextAlign = (alignment) => {
    let active = canvasRef.current.getActiveObject();
    if (active && active.type === "i-text") {
      const originalWidth = active.width;
      const originalHeight = active.height;
      active.set({
        textAlign: alignment,
      });
      active.set({
        width: originalWidth,
        height: originalHeight,
      });
      canvasRef.current.renderAll();
      movingFn(active);
    }
  };

  //设置选择标签
  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    setSelectLabel(label);
  };

  //添加产品标签到画布
  function addLabelToCanvas() {
    const hasEqualSizeImage = canvasRef.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvasRef.current.width) &&
          Number(obj.height) === Number(canvasRef.current.height)
      );

    //当画布存在图形并且图像占满画布则无法添加标签
    if (hasEqualSizeImage) {
      enqueueSnackbar(t("TepL0003"), { variant: "error" });
      return;
    }
    //添加产品标签
    switch (selectLabel) {
      case t("EDTE0000"):
        addElementToCanvas("${productName}", t("EDTE0000"));
        break;
      case "Price Rule":
        addElementToCanvas("${priceRule}", "Price Rule");
        break;
      case t("EDTE0001"):
        addElementToCanvas("${productPrice}", t("EDTE0001"));
        break;
      case t("EDTE0002"):
        addElementToCanvas("${quantity}", t("EDTE0002"));
        break;
      case t("EDTE0003"):
        addElementToCanvas("${category1}", t("EDTE0003"));
        break;
      case t("EDTE0004"):
        addElementToCanvas("${category2}", t("EDTE0004"));
        break;
      case t("EDTE0005"):
        addElementToCanvas("${currency symbol}", t("EDTE0005"));
        break;
      case t("EDTE0006"):
        addElementToCanvas("${origin}", t("EDTE0006"));
        break;
      case t("EDTE0007"):
        addElementToCanvas("${manufacturer}", t("EDTE0007"));
        break;
      case t("EDTE0011"):
        addElementToCanvas("${discountPrice}", t("EDTE0011"));
        break;
      default:
        addElementToCanvas("${" + selectLabel + "}", selectLabel);
    }
  }

  //添加产品标签
  function addElementToCanvas(text, labelName) {
    if (dynamicFontSize < 0) {
      //字体大小不应该是负数
      console.error(`Error: ${labelName} - Negative dynamicFontSize.`);
      enqueueSnackbar(`${labelName} -  FontSize should not be Negative`, {
        variant: "error",
      });
      return;
    }

    const existingLabels = canvasRef.current
      .getObjects()
      .filter((obj) => obj.type === "i-text" && obj.text === text);

    if (existingLabels.length > 0) {
      //存在相同标签，不允许添加
      console.error(`Error: ${labelName} Already Added.`);
      enqueueSnackbar(` ${labelName}, ${t("TepL0004")}`, { variant: "error" });
      return;
    }

    let textBox = new fabric.IText(text, {
      left: 2,
      top: 2,
      fontSize: dynamicFontSize,
      fontStyle: dynamicFontStyle,
      fontFamily: "Helvetica",
      editable: false,
      fill: textColor,
      lockRotation: true,
      lockScalingX: false,
      lockScalingY: false,
      lockScalingFlip: true,
    });
    //添加不可编辑产品标签
    canvasRef.current.add(textBox);
  }

  //添加价格规则标签
  function addPriceRuleToCanvas(e) {
    const hasEqualSizeImage = canvasRef.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvasRef.current.width) &&
          Number(obj.height) === Number(canvasRef.current.height)
      );

    if (hasEqualSizeImage) {
      enqueueSnackbar(t("TepL0005"), { variant: "error" });
      return;
    }

    const existingLabels = canvasRef.current
      .getObjects()
      .filter((obj) => obj.type === "i-text" && obj.text === "${priceRule}");

    if (existingLabels.length > 0) {
      enqueueSnackbar(t("TepL0006"), { variant: "error" });
      return;
    }
    let textBox = new fabric.IText("${priceRule}", {
      left: 2,
      top: 2,
      fontSize: dynamicFontSize,
      fontStyle: dynamicFontStyle,
      fontFamily: "Helvetica",
      editable: false,
      fill: textColor,
      lockRotation: true,
      lockScalingX: false, // Set lockScalingX property to true
      lockScalingY: false,
      lockScalingFlip: true,
    });
    canvasRef.current.add(textBox);
  }

  //添加自由文本
  function addTextToCanvas(e) {
    const hasEqualSizeImage = canvasRef.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvasRef.current.width) &&
          Number(obj.height) === Number(canvasRef.current.height)
      );

    if (hasEqualSizeImage) {
      enqueueSnackbar(t("TepL0007"), { variant: "error" });
      return;
    }

    let textBox = new fabric.IText(t("TEML0023"), {
      left: 5,
      top: 5,
      fontSize: dynamicFontSize,
      fontStyle: dynamicFontStyle,
      fontFamily: "Helvetica",
      fill: textColor,
      lockRotation: true,
      lockScalingX: false, // Set lockScalingX property to true
      lockScalingY: false,
      lockScalingFlip: true,
    });
    canvasRef.current.add(textBox);
  }

  const handleShapeChange = (e) => {
    setSelectedShape(e.target.value);
  };

  //拖动之后禁止元素超出
  const movingFn = (rect) => {
    let obj = rect;
    // 获取画布边界
    let canvasWidth = canvasRef.current.width;
    let canvasHeight = canvasRef.current.height;

    // 计算对象的边界框
    let objLeft = obj.left;
    let objTop = obj.top;

    //计算元素实际宽高
    let objWidth = obj.width * obj.scaleX;
    let objHeight = obj.height * obj.scaleY;

    //计算元素右边位置和底部位置
    let objRight = objLeft + objWidth;
    let objBottom = objTop + objHeight; // 检查对象是否移出了画布边界

    let isOutside = false;

    //元素宽度超出画布宽度
    if (objWidth > canvasWidth) {
      obj.set({ width: canvasWidth });
      isOutside = true;
    }

    //元素高度超出画布宽度
    if (objHeight > canvasHeight) {
      obj.set({ height: canvasHeight });
      isOutside = true;
    }

    // 检查左边是否超出边界
    if (objLeft < 0) {
      obj.set({ left: 0 });
      isOutside = true;
    }

    // 检查上边是否超出边界
    if (objTop < 0) {
      obj.set({ top: 0 });
      isOutside = true;
    }

    // 检查右边是否超出边界
    if (objRight > canvasWidth) {
      obj.set({ left: canvasWidth - objWidth });
      isOutside = true;
    }
    // 检查底边是否超出边界
    if (objBottom > canvasHeight) {
      obj.set({ top: canvasHeight - objHeight });
      isOutside = true;
    }

    // 如果对象移出了边界，阻止对象移动
    if (isOutside) {
      canvasRef.current.renderAll();
    }
  };

  const changeFontStyle = (e) => {
    setDynamicFontStyle(e.target.value);
    if (activeElement) {
      let active = canvasRef.current.getActiveObject();
      let type = active.type;
      if (type === "image") {
      } else {
        let scaleX = active.scaleX;
        let scaleY = active.scaleY;
        let resultWidth = parseInt(active.width * scaleX);
        let resultHeight = parseInt(active.height * scaleY);
        setActiveHeight(resultHeight);
        setActiveWidth(resultWidth);
        let type = active.type;
        if (type === "i-text") {
          active.set("fontStyle", e.target.value);
          canvasRef.current.renderAll();
        }
        active.set({
          width: resultWidth,
          height: resultHeight,
          scaleX: 1,
          scaleY: 1,
        });
        canvasRef.current.renderAll();
      }
      movingFn(active);
    }
  };

  const adjustTextSize = (active, fontSize) => {
    let textWidth, textHeight;
    let canvasWidth = canvasRef.current.width;
    let canvasHeight = canvasRef.current.height;

    // Create a temporary fabric.Text object to measure the text dimensions
    const tempText = new fabric.Text(active.text, {
      fontSize: fontSize,
      fontFamily: active.fontFamily,
      fontWeight: active.fontWeight,
      fontStyle: active.fontStyle,
    });

    textWidth = tempText.width;
    textHeight = tempText.height;

    if (textWidth <= canvasWidth && textHeight <= canvasHeight) {
      active.set({
        fontSize: fontSize,
        width: textWidth,
        height: textHeight,
      });
    } else {
      // If the new size exceeds the canvas size, revert to the previous size
      let prevFontSize = active.fontSize - 1;
      active.set({
        fontSize: prevFontSize,
      });
      enqueueSnackbar(t("Font size exceeds canvas dimensions"), {
        variant: "error",
      });
      setDynamicFontSize(prevFontSize);
    }

    active.set({
      width: active.width,
      height: active.height,
    });
    canvasRef.current.renderAll();
  };

  const changeFontSize = (e) => {
    let fontSize = parseInt(e.target.value);
    if (fontSize < 12) {
      fontSize = 12;
    }
    let active = canvasRef.current.getActiveObject();
    if (
      active &&
      (active.type === "textBox" ||
        active.type === "i-text" ||
        active.type === "text")
    ) {
      adjustTextSize(active, fontSize);
    }
    setDynamicFontSize(fontSize);
  };

  const blueFontSize = (e) => {
    let fontSize = parseInt(e.target.value);
    if (fontSize < 12) {
      fontSize = 12;
    }
    let active = canvasRef.current.getActiveObject();
    if (
      active &&
      (active.type === "textBox" ||
        active.type === "i-text" ||
        active.type === "text")
    ) {
      adjustTextSize(active, fontSize);
    }
    setDynamicFontSize(fontSize);
  };

  const changeActiveHeight = (e) => {
    let tempHeight = parseInt(e.target.value);
    if (tempHeight > canvasHeight) {
      tempHeight = canvasHeight;
      enqueueSnackbar(t("ERR0003"), {
        variant: "error",
      });
    }
    setActiveHeight(tempHeight);
    let active = canvasRef.current.getActiveObject();
    if (active) {
      let type = active.type;
      if (type === "image") {
        let scaleY = active.scaleY;
        active.set({
          height: tempHeight * scaleY,
          scaleY: scaleY,
        });
        canvasRef.current.renderAll();
      } else {
        let fontSize = active.fontSize;
        if (fontSize) {
          fontSize = parseInt(fontSize);
          if (tempHeight < fontSize) {
            tempHeight = fontSize;
            setActiveHeight(tempHeight);
          }
        }
        active.set("height", tempHeight);
        canvasRef.current.renderAll();
      }
      movingFn(active);
    }
  };

  const changeActiveWidth = (e) => {
    let tempWidth = parseInt(e.target.value);
    if (tempWidth > canvasWidth) {
      tempWidth = canvasWidth;
      enqueueSnackbar(t("ERR0004"), {
        variant: "error",
      });
    }
    setActiveWidth(tempWidth);
    let active = canvasRef.current.getActiveObject();
    if (active) {
      if (type === "image") {
        let scaleX = active.scaleX;
        active.set({
          width: tempWidth * scaleX,
          scaleX: scaleX,
        });
        canvasRef.current.renderAll();
      } else {
        active.set("width", tempWidth);
        canvasRef.current.renderAll();
      }
      movingFn(active);
    }
  };

  const handleButtonClick = (color) => {
    setTextColor(color);
  };

  //添加一个盒子到画布
  function addBoxToCanvas() {
    if (boxHeight > canvasHeight) {
      enqueueSnackbar(t("TepL0000") + canvasHeight + ".", { variant: "error" });
      return;
    }

    if (boxWidth > canvasWidth) {
      enqueueSnackbar(t("TepL0001") + canvasWidth + ".", { variant: "error" });
      return;
    }

    const hasEqualSizeImage = canvasRef.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvasRef.current.width) &&
          Number(obj.height) === Number(canvasRef.current.height)
      );

    if (hasEqualSizeImage) {
      enqueueSnackbar(t("TepL0002"), { variant: "error" });
      return;
    }

    let rect;

    switch (selectedShape) {
      case "square":
        rect = new fabric.Rect({
          left: 0,
          top: 0,
          width: boxWidth,
          height: boxHeight,
          fill: textColor,
          lockRotation: true,
          lockScalingX: false, // Set lockScalingX property to true
          lockScalingY: false,
        });
        break;
      case "circle":
        rect = new fabric.Circle({
          left: 0,
          top: 0,
          radius: boxWidth / 2,
          fill: textColor,
          lockRotation: true,
          lockScalingX: false, // Set lockScalingX property to true
          lockScalingY: false,
        });
        break;
      // Add more shapes as needed
      default:
        rect = new fabric.Rect({
          left: 0,
          top: 0,
          width: boxWidth,
          height: boxHeight,
          fill: textColor,
          lockRotation: true,
          lockScalingX: false, // Set lockScalingX property to true
          lockScalingY: false,
        });
    }

    canvasRef.current.add(rect);
  }

  function submitURL(e) {
    const reader = new FileReader();
    // Set up a callback function for when the file reading is completed
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;

      // Set your desired height
      image.onload = function () {
        // Now you can access the image's width and height
        const base64Image = event.target.result; // The base64 representation of the image
        let scale = 1;
        const canvasWidth = canvasRef.current.width;
        const canvasHeight = canvasRef.current.height;
        const imageWidth = image.width;
        const imageHeight = image.height;

        if (imageWidth > canvasWidth || imageHeight > canvasHeight) {
          let widthScal = imageWidth / canvasWidth;
          let heightScal = imageHeight / canvasHeight;
          //宽度超出比例多
          if (widthScal > heightScal) {
            scale = canvasWidth / imageWidth;
          } else {
            scale = canvasHeight / imageHeight;
          }
        }

        const hasEqualSizeImage = canvasRef.current
          .getObjects()
          .some(
            (obj) =>
              obj.type === "image" &&
              Number(obj.width) === Number(canvasRef.current.width) &&
              Number(obj.height) === Number(canvasRef.current.height)
          );

        //已经存在图片，并且占满则不允许添加
        if (hasEqualSizeImage) {
          enqueueSnackbar(t("TepL0008"), { variant: "error" });
          return;
        }

        //如果存在元素，则占满的图片也不允许添加
        if (
          Number(image.width) === Number(canvasRef.current.width) &&
          Number(image.height) === Number(canvasRef.current.height)
        ) {
          // Check if the canvas already contains any other objects
          if (canvasRef.current.getObjects().length > 0) {
            // Trigger an error or handle the condition as needed
            enqueueSnackbar(t("TepL0009"), { variant: "error" });
            return;
          }
        }
        // Now you can use base64Image as needed, e.g., add it to the canvas or do something else
        //添加图片
        fabric.Image.fromURL(base64Image, function (img) {
          var oImg = img.set({
            left: 2,
            top: 2,
            scaleX: scale,
            scaleY: scale,
            width: image.width,
            height: image.height,
            lockRotation: true,
            lockScalingX: false,
            lockScalingY: false,
          });
          canvasRef.current.add(oImg).renderAll();
        });
      };
    };

    // Read the image file from the input field
    const inputElement = document.getElementById("input");
    const file = inputElement.files[0];
    if (file) {
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
    setPayload({
      ...payload,
      name: name,
      screenOriantation: screenOrientation,
      screenResolution: screenResolution,
      templatePhoto: name,
      fileExtension: "",
      size: "",
      model: model,
      type: type,
    });
  }

  //删除画布元素
  function deleteElement(e) {
    canvasRef.current.remove(canvasRef.current.getActiveObject());
  }

  //将画布对象转成json 数据
  function captureCanvasAsJson() {
    const objects = canvasRef.current.getObjects();
    const objectsData = objects.map((object) => object.toObject());
    return JSON.stringify(objectsData);
  }

  //将画布转成html
  function generateHTMLWithCanvasData(width, height) {
    //将画布对象转成json
    const canvasData = captureCanvasAsJson();
    const parsedData = JSON.parse(canvasData);
    let errorLabels = [];
    // Function to generate styles for the container
    const generateContainerStyle = (objectData) => `
          position: absolute;
          left: ${objectData.left}px;
          top: ${objectData.top}px;
          width: ${objectData.width}px;
          height: ${objectData.height}px;
          overflow: hidden;
      `;

    const htmlContent = parsedData.map((objectData, index) => {
      const containerStyle = generateContainerStyle(objectData);

      const aspectRatio = objectData.width / objectData.height;
      let adjustedWidth = objectData.width;
      let adjustedHeight = objectData.height;

      // Adjust width and height if they exceed container dimensions
      if (adjustedWidth > width || adjustedHeight > height) {
        if (adjustedWidth / width > adjustedHeight / height) {
          adjustedWidth = width;
          adjustedHeight = width / aspectRatio;
        } else {
          adjustedHeight = height;
          adjustedWidth = height * aspectRatio;
        }
      }

      let elementHtml = "";
      if (objectData.type === "i-text") {
        if (objectData.scaleX > 1 || objectData.scaleY > 1) {
          errorLabels.push(objectData.text);
        }
        let fontSize = objectData.fontSize;
        let fontStyle = objectData.fontStyle;
        let textAlign = objectData.textAlign;
        elementHtml = `<div style="overflow: hidden;${containerStyle}; text-align: ${textAlign}; font-size: ${fontSize}px; font-family: ${objectData.fontFamily}; color: ${objectData.fill}; font-style: ${fontStyle}; font-weight: ${fontStyle}; width: ${adjustedWidth}px; height: ${adjustedHeight}px;">${objectData.text}</div>`;
      } else if (objectData.type === "rect") {
        elementHtml = `<div style=" overflow: hidden;${containerStyle}; background-color: ${objectData.fill}; border: ${objectData.strokeWidth}px solid ${objectData.stroke}; width: ${adjustedWidth}px; height: ${adjustedHeight}px;"></div>`;
      } else if (objectData.type === "circle") {
        elementHtml = `<div style="overflow: hidden; ${containerStyle}; background-color: ${objectData.fill}; border-radius: 50%; border: ${objectData.strokeWidth}px solid ${objectData.stroke}; width: ${adjustedWidth}px; height: ${adjustedHeight}px;"></div>`;
      } else if (objectData.type === "image") {
        let imageWidth = objectData.width * objectData.scaleX;
        let imageHeight = objectData.height * objectData.scaleY;
        elementHtml = `<img src="${objectData.src}" style="overflow: hidden; ${containerStyle}; max-width: 100%; max-height: 100%; width: ${imageWidth}px; height: ${imageHeight}px;"/>`;
      }
      return elementHtml;
    });

    if (errorLabels.length > 0) {
      enqueueSnackbar(
        `Given Texts are wrong format: ${errorLabels.join(", ")}.`,
        { variant: "error" }
      );
      return;
    }

    return `
          <!DOCTYPE html>
          <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Exported HTML with Canvas</title>
                  <style>
                      body {
                          margin: 0;
                          padding: 0;
                          height: 100vh;
                      }
                  </style>
              </head>
              <body>
                  <div style="overflow: hidden;position: relative; width: ${width}px; height: ${height}px;">
                      ${htmlContent.join("")}
                  </div>
              </body>
          </html>
      `;
  }

  //提交
  async function convertToImg(width, height) {
    //没有元素不允许提交
    if (canvasRef.current.getObjects().length === 0) {
      enqueueSnackbar(t("TepL0011"), { variant: "error" });
      return;
    }
    setDisableSubmit(true);
    const htmlContent = generateHTMLWithCanvasData(width, height);
    const file = new File([htmlContent], location.state.name, {
      type: "text/html",
    });

    if (htmlContent === null) {
      return;
    }
    localStorage.setItem("elementInnerHTML", htmlContent);
    try {
      const elementHTML = localStorage.getItem("elementInnerHTML");
      if (elementHTML) {
        const element = document.createElement("div");
        element.style.display = "hidden";
        element.innerHTML = elementHTML;
        document.body.appendChild(element);
        //let file = null;

        const borderSize = 2;
        const extraHeight = 6;
        const canvas = await html2canvas(element, {
          width: screenResolution.split("*")[0],
          height: screenResolution.split("*")[1],
        });

        element.style.visibility = "hidden";
        document.body.removeChild(element);

        const borderedCanvas = document.createElement("canvas");
        const ctx = borderedCanvas.getContext("2d");
        borderedCanvas.width = screenResolution.split("*")[0];
        borderedCanvas.height = screenResolution.split("*")[1];
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);
        ctx.drawImage(canvas, borderSize, borderSize);
        ctx.strokeStyle = "#000";
        ctx.lineWidth = borderSize;

        ctx.strokeRect(
          borderSize,
          borderSize,
          element.clientWidth,
          element.clientHeight
        );

        const dataUrl = borderedCanvas.toDataURL("image/png");
        const byteString = atob(dataUrl.split(",")[1]);
        const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uintArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeString });
        const file1 = new File(
          [blob],
          localStorage.getItem("productName") + ".png",
          {
            type: "image/png",
          }
        );
        if (!CommonUtil.isEmpty(templateObjectKey)) {
          var bodyFormData = new FormData();
          bodyFormData.append("file", file);
          bodyFormData.append("file1", file1);
          bodyFormData.append("objectKey", templateObjectKey);
          bodyFormData.append("id", id);
          bodyFormData.append("screenResolution", screenResolution);
          bodyFormData.append("templateName", name);
          bodyFormData.append("model", model);
          bodyFormData.append("imageObjectKey", imageObjectKey);
          bodyFormData.append("screenOriantation", screenOrientation);
          bodyFormData.append("type", type);

          await axios({
            method: "post",
            url:
              process.env.REACT_APP_SERVER_URL +
              "/web/template/uploadUpdatedTemplate",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("USER_TOKEN"),
              selectedClient: localStorage.getItem("RETAIL_CLIENT"),
            },
          });
        }
        localStorage.removeItem("screenResolution");
        localStorage.removeItem("name");
        localStorage.removeItem("screenOrientation");
        localStorage.removeItem("model");
        localStorage.removeItem("type");
        localStorage.removeItem("templateObjectKey");
        localStorage.removeItem("templateImage");
        localStorage.removeItem("elementInnerHTML");
        enqueueSnackbar(t("EPLSCN0023"), {
          variant: "success",
        });
        navigate(REACT_TEMPLATE_LIST);
        return;
      }
    } catch (error) {
      setDisableSubmit(false);
      console.error("Error:", error);
    }
  }

  const labelHeight = 32; // Estimated height of a single label (including margin and padding)
  const maxVisibleLabels = 7;
  const maxHeight = labelHeight * maxVisibleLabels;

  return (
    <RightViewLayout
      title={t("EPLSCN0026")}
      navigateBack={REACT_TEMPLATE_EDIT + id}
    >
      <Grid container spacing={3} xs={12}>
        <Grid item xs={2}>
          <Box
            item
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              padding: "5px",
              margin: "5px",
              fontFamily: "sans-serif",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              Container
              style={{ fontFamily: "sans-serif", justifyContent: "center" }}
            >
              {activeElement && activeElement.type !== "image" && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    marginTop: "20px",
                  }}
                >
                  <Tooltip title="Align Left">
                    <IconButton
                      onClick={() => changeTextAlign("left")}
                      style={{
                        padding: "3px",
                        margin: "3px",
                        cursor: "pointer",
                        fontFamily: "sans-serif",
                      }}
                      aria-label="left align"
                    >
                      <FormatAlignLeftIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Align Center">
                    <IconButton
                      onClick={() => changeTextAlign("center")}
                      style={{
                        padding: "3px",
                        margin: "3px",
                        cursor: "pointer",
                        fontFamily: "sans-serif",
                      }}
                      aria-label="center align"
                    >
                      <FormatAlignCenterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Align Right">
                    <IconButton
                      onClick={() => changeTextAlign("right")}
                      style={{
                        padding: "3px",
                        margin: "3px",
                        cursor: "pointer",
                        fontFamily: "sans-serif",
                      }}
                      aria-label="right align"
                    >
                      <FormatAlignRightIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box>
                <Typography
                  style={{
                    padding: "3px",
                    margin: "3px",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  {t("LVLOT0027")}{" "}
                </Typography>
                <div
                  className="custom-scrollbar"
                  style={{
                    display: "block",
                    textAlign: "left",
                    padding: "3px",
                    margin: "3px",
                    fontFamily: "sans-serif",
                    maxHeight: `${maxHeight}px`, // Set the maximum height
                    overflowY: "auto", // Enable vertical scrolling
                  }}
                >
                  {labels.map((label) => (
                    <div
                      key={label}
                      onClick={() => handleLabelClick(label)}
                      style={{
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginBottom: "5px", // Add some space between labels
                        backgroundColor:
                          selectedLabel === label ? "#E0F7FA" : "transparent", // Change background color for selected label
                      }}
                    >
                      {label}
                    </div>
                  ))}
                </div>
              </Box>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="tempeditoraddlabel"
                  variant="contained"
                  color="default"
                  size="medium"
                  className="text-transform-none"
                  onClick={addLabelToCanvas}
                >
                  {t("LVLOT0028")}
                </Button>
              </Box>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="addPriceRule"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className="text-transform-none"
                  style={{
                    backgroundColor: "#00a5ff",
                    padding: "6px",
                    margin: "3px",
                    justifyContent: "center",
                  }}
                  onClick={addPriceRuleToCanvas}
                  component="span"
                >
                  {t("LVLOT0025")}
                </Button>
              </Box>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="addText"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className="text-transform-none"
                  style={{
                    backgroundColor: "#00a5ff",
                    padding: "6px",
                    margin: "3px",
                    justifyContent: "center",
                  }}
                  onClick={addTextToCanvas}
                  component="span"
                >
                  {" "}
                  {t("LVLOT0026")}{" "}
                </Button>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "left",
                  fontFamily: "sans-serif",
                  marginTop: "5px",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("EPLT0002")}{" "}
                </Typography>
                <select
                  id="TemplateEditorFontStyle"
                  value={dynamicFontStyle}
                  onChange={(e) => {
                    changeFontStyle(e);
                  }}
                  style={{
                    padding: "3px",
                    margin: "3px",
                    cursor: "pointer",
                    fontFamily: "sans-serif",
                  }}
                >
                  <option
                    style={{
                      padding: "3px",
                      margin: "3px",
                      fontFamily: "sans-serif",
                    }}
                    value="bold"
                  >
                    Bold
                  </option>
                  <option
                    style={{
                      padding: "3px",
                      margin: "3px",
                      fontFamily: "sans-serif",
                    }}
                    value="normal"
                  >
                    Normal
                  </option>
                  <option
                    style={{
                      padding: "3px",
                      margin: "3px",
                      fontFamily: "sans-serif",
                    }}
                    value="italic"
                  >
                    Italic
                  </option>
                </select>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("EPLT0003")}
                </Typography>
                <input
                  type="number"
                  value={dynamicFontSize}
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    width: "75px",
                  }}
                  onBlur={(e) => {
                    blueFontSize(e);
                  }}
                  onChange={(e) => {
                    changeFontSize(e);
                  }}
                />
              </Box>
              {activeElement && activeElement.type !== "image" && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                  }}
                >
                  <Typography
                    style={{
                      padding: "2px",
                      margin: "2px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {t("EDTE0009")}
                  </Typography>
                  <input
                    type="number"
                    value={activeWidth}
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                      width: "75px",
                    }}
                    onChange={(e) => {
                      changeActiveWidth(e);
                    }}
                  />
                </Box>
              )}
              {activeElement && activeElement.type !== "image" && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                  }}
                >
                  <Typography
                    style={{
                      padding: "2px",
                      margin: "2px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {t("EDTE0010")}
                  </Typography>
                  <input
                    type="number"
                    value={activeHeight}
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                      width: "75px",
                    }}
                    onChange={(e) => {
                      changeActiveHeight(e);
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <canvas
            id="canvas"
            style={{ border: "1px solid black" }}
            width={splitArray[0]}
            height={splitArray[1]}
          />
        </Grid>
        <Grid item xs={2}>
          <Box
            item
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              padding: "5px",
              margin: "5px",
              fontFamily: "sans-serif",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              Container
              style={{
                padding: "3px",
                margin: "3px",
                justifyContent: "left",
                fontFamily: "sans-serif",
              }}
            >
              <Typography
                style={{
                  padding: "2px",
                  margin: "2px",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {t("EPLT00000")}
              </Typography>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              >
                <button
                  style={{
                    padding: "20px",
                    marginRight: "5px",
                    backgroundColor: selectedButton === "red" ? "red" : "red",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick("red")}
                />
                <button
                  style={{
                    padding: "20px",
                    marginRight: "5px",
                    backgroundColor:
                      selectedButton === "black" ? "black" : "black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick("black")}
                />
                <button
                  style={{
                    padding: "20px",
                    marginRight: "5px",
                    backgroundColor:
                      selectedButton === "white" ? "white" : "white",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick("white")}
                />
              </Box>
            </Box>

            <Box
              Container
              style={{
                justifyContent: "end",
                padding: "3px",
                margin: "3px",
                fontFamily: "sans-serif",
              }}
            >
              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("EPLT0005")}
                </Typography>
                <input
                  type="number"
                  value={boxHeight}
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    width: "75px",
                  }}
                  onChange={(e) => setBoxHeight(parseInt(e.target.value))}
                />
              </Box>

              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("EPLT0006")}
                </Typography>
                <input
                  type="number"
                  value={boxWidth}
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    width: "75px",
                  }}
                  onChange={(e) => setBoxWidth(parseInt(e.target.value))}
                />
              </Box>

              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("EPLT0007")}{" "}
                </Typography>
                <select
                  id="templateEditorAddShape"
                  onChange={handleShapeChange}
                  value={selectedShape}
                  style={{
                    padding: "3px",
                    margin: "3px",
                    cursor: "pointer",
                    fontFamily: "sans-serif",
                  }}
                >
                  <option value="square">Square</option>
                  <option value="circle">Circle</option>
                </select>
              </Box>

              <Box
                item
                style={{
                  justifyContent: "end",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="tempeditoraddshape"
                  variant="contained"
                  color="default"
                  size="medium"
                  className="text-transform-none"
                  onClick={addBoxToCanvas}
                >
                  {" "}
                  {t("LVLOT0029")}{" "}
                </Button>
              </Box>
            </Box>

            <Box
              style={{
                display: "block",
                textAlign: "end",
                padding: "3px",
                margin: "3px",
                fontFamily: "sans-serif",
              }}
            >
              <Typography
                style={{
                  padding: "5px",
                  margin: "5px",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {t("LVLOT0033")}{" "}
              </Typography>
              <input
                style={{
                  padding: "3px",
                  margin: "15px",
                  border: "0.5px solid #000",
                  maxWidth: "135px",
                }}
                id="input"
                type="file"
                accept="image/*"
              />
              <Button
                id="tempeditoraddimage"
                variant="contained"
                color="primary"
                size="medium"
                className="text-transform-none"
                style={{
                  backgroundColor: "#00a5ff",
                  padding: "3px",
                  margin: "3px",
                  width: "100px",
                  justifyContent: "center",
                }}
                onClick={submitURL}
                component="span"
              >
                {t("LVLOT0032")}
              </Button>
            </Box>

            <Box
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
              }}
            >
              <Button
                id="tempeditordelete"
                variant="contained"
                color="secondary"
                size="medium"
                className="text-transform-none"
                onClick={deleteElement}
                style={{
                  padding: "5px",
                  margin: "5px",
                  width: "150px",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                {t("LVLOT0030")}
              </Button>
            </Box>
          </Box>

          <Box
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              id="tempeditornext"
              variant="contained"
              color="primary"
              size="medium"
              className="text-transform-none"
              disabled={disableSubmit}
              onClick={() => convertToImg(canvasWidth, canvasHeight)}
            >
              {" "}
              {t("LVLOT0016")}{" "}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
