import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_OUTLET_LIST } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { timeZoneList } from "../../constants/TimeZone";
import AddressFromMap from "../../components/AddressFromMap";
import { findOutletById, updateOutlet } from "../../services/OutletService";

export default function EditOutlet() {
  const location = useLocation();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [timezone, setTimezone] = useState({});

  const [payload, setPayload] = useState({
    id: "",
    name: "",
    code: "",
    addressLine1: "",
    city: "",
    state: "",
    country: "",
    areaCode: "",
    latitude: "",
    longitude: "",
    timeZone: "",
    clientId: "",
    outletTypeId: "",
    cityZone: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    addressLine1: "",
    city: "",
    state: "",
    country: "",
    areaCode: "",
    latitude: "",
    longitude: "",
    timeZone: "",
    clientId: "",
    cityZone: "",
  });

  useEffect(() => {
    if (location?.state?.id) {
      findOutletById(location?.state?.id).then((res) => {
        if (res.data.code === "LVLI0000") {
          var outlet = {};
          outlet.id = res?.data?.data?.id;
          outlet.clientId = res?.data?.data?.clientId;
          outlet.code = res?.data?.data?.code;
          outlet.name = res?.data?.data?.name;
          outlet.addressId = res?.data?.data?.address?.id;
          outlet.country = res?.data?.data?.country;
          outlet.state = res?.data?.data?.state;
          outlet.city = res?.data?.data?.city;
          outlet.addressLine1 = res.data.data?.addressLine1;
          outlet.latitude = res?.data?.data?.latitude;
          outlet.longitude = res?.data?.data?.longitude;
          outlet.timeZone = res?.data?.data?.timeZone;
          outlet.areaCode = res?.data?.data?.areaCode;
          outlet.cityZone = res?.data?.data?.cityZone;

          setTimezone(CommonUtil.getTimeZoneByValue(res.data.data?.timeZone));

          setPayload({
            ...payload,
            ...outlet,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (timeZoneList.length > 0 && payload.timeZone) {
      let timeZone = timeZoneList.find((tz) => tz.value === payload.timeZone);
      if (timeZone) {
        setTimezone(timeZone);
      } else {
        console.log("No matching timezone found for value:", payload.timeZone);
        setTimezone(null);
      }
    }
  }, [payload.timeZone, timeZoneList]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.code)) {
      setError({
        ...error,
        code: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.clientId)) {
      setError({
        ...error,
        companyId: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.timeZone)) {
      setError({
        ...error,
        timeZone: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.addressLine1)) {
      setError({
        ...error,
        addressLine1: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.country)) {
      setError({
        ...error,
        country: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.state)) {
      setError({
        ...error,
        state: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.city)) {
      setError({
        ...error,
        city: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.cityZone)) {
      setError({
        ...error,
        cityZone: t("tips.required"),
      });
      return;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      updateOutlet(location?.state?.id, payload).then((response) => {
        if (response.data.code === "LMSI6011") {
          enqueueSnackbar(t("tips_outlet.outlet_updated"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_OUTLET_LIST);
          return;
        } else enqueueSnackbar(response?.data?.message, { variant: "error" });
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleLocationFromMap = (data) => {
    const dataItem = data.content;
    const detailItem = dataItem?.address_detail;

    const baiduMap = localStorage.getItem("BAIDUMAP");
    if (baiduMap == "cn") {
      setPayload({
        ...payload,
        latitude: dataItem.point.x,
        longitude: dataItem.point.y,
        addressLine1: dataItem.address,
        country: detailItem.country,
        state: detailItem?.province,
        city: detailItem?.city,
        areaCode: detailItem?.city_code,
      });
      setError({
        ...error,
        latitude: "",
        longitude: "",
        addressLine1: "",
        countryId: "",
        stateId: "",
        cityId: "",
      });
    } else {
      if (data) {
        if (data.country) {
          let country = data.country;
          let state = data.state;
          let city = data.city;
          let areaCode = data.pincode;
          let addressDetail = country + state + city;

          setPayload({
            ...payload,
            latitude: data.lat,
            longitude: data.lng,
            addressLine1: data.addressLine1 ? data.addressLine1 : addressDetail,
            country: country,
            state: state,
            city: city,
            areaCode: areaCode,
          });
          setError({
            ...error,
            latitude: "",
            longitude: "",
            addressLine1: "",
            countryId: "",
            stateId: "",
            cityId: "",
          });
        }
      }
    }
  };

  // const handleLocationFromMap = (data) => {
  //   const dataItem = data.content;
  //   const detail = dataItem?.address_detail;
  //   const baiduMap = localStorage.getItem("BAIDUMAP");

  //   const commonPayload = {
  //     latitude: "",
  //     longitude: "",
  //     addressLine1: "",
  //     country: "",
  //     state: "",
  //     city: "",
  //     areaCode: "",
  //   };

  //   const commonError = {
  //     latitude: "",
  //     longitude: "",
  //     addressLine1: "",
  //     countryId: "",
  //     stateId: "",
  //     cityId: "",
  //   };

  //   let payload = { ...commonPayload };
  //   let error = { ...commonError };

  //   if (baiduMap === "cn") {
  //     if (detail) {
  //       const { country, province: state, city, city_code: areaCode } = detail;

  //       payload = {
  //         ...payload,
  //         latitude: dataItem.point.x,
  //         longitude: dataItem.point.y,
  //         addressLine1: dataItem.address,
  //         country,
  //         state,
  //         city,
  //         areaCode,
  //       };
  //     }
  //   } else {
  //     if (data.country) {
  //       const { country, state, city, pincode: areaCode } = data;

  //       payload = {
  //         ...payload,
  //         latitude: data.lat,
  //         longitude: data.lng,
  //         addressLine1: data.addressLine1,
  //         country,
  //         state,
  //         city,
  //         areaCode,
  //       };
  //     }
  //   }

  //   setPayload(payload);
  //   console.log("EEEEEEEE22222222222222ORRRRRRR", payload);
  //   setError(error);
  // };
  return (
    <RightViewLayout
      navigateBack={REACT_OUTLET_LIST}
      title={t("outlet.edit_outlet")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutLet1"
            required
            label={t("outlet.name")}
            size="small"
            name="name"
            error={error.name}
            resetError={() => setError({ ...error, name: "" })}
            value={payload.name}
            handleChange={handleChange}
            validation={"alpha-numeric-ch-th"}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
            placeholder={t("tips_outlet.outlet_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet2"
            required
            label={t("outlet.code")}
            size="small"
            name="code"
            error={error.code}
            resetError={() => setError({ ...error, code: "" })}
            value={payload.code}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.code}
            validation="code"
            placeholder={t("tips.outlet_code")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("table.time_zone")}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            id="AddOutletTimeZoneList"
            options={timeZoneList}
            value={timezone}
            onChange={(e, v) => {
              setTimezone(v);

              setPayload({ ...payload, timeZone: v ? v.value : "" });
              setError({ ...error, timeZone: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.timeZone}
                helperText={error.timeZone}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet3"
            required
            label={t("table.address")}
            size="small"
            name="addressLine1"
            value={payload.addressLine1}
            error={error.addressLine1}
            resetError={() => setError({ ...error, addressLine1: "" })}
            helperText={error.addressLine1}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AddressFromMap
                    id="AddOutletAddress3"
                    onSelectLocation={(address) =>
                      handleLocationFromMap(address)
                    }
                  />
                </InputAdornment>
              ),
            }}
            placeholder={t("tips.address")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet22"
            required
            label={t("table.country")}
            size="small"
            name="country"
            error={error.country}
            resetError={() => setError({ ...error, country: "" })}
            value={payload.country}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.country}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.country_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet22"
            required
            label={t("table.state")}
            size="small"
            name="state"
            error={error.state}
            resetError={() => setError({ ...error, state: "" })}
            value={payload.state}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.state}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.state_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet22"
            required
            label={t("table.city")}
            size="small"
            name="city"
            error={error.city}
            resetError={() => setError({ ...error, city: "" })}
            value={payload.city}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.city}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.city_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet21"
            label={t("table.area_code")}
            size="small"
            name="areaCode"
            error={error.areaCode}
            resetError={() => setError({ ...error, areaCode: "" })}
            value={payload.areaCode}
            handleChange={handleChange}
            inputProps={{
              maxLength: 12,
            }}
            helperText={error.areaCode}
            validation="alpha-numeric"
            placeholder={t("tips.area_code")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet21"
            label={t("table.city_zone")}
            size="small"
            required
            name="cityZone"
            error={error.cityZone}
            resetError={() => setError({ ...error, cityZone: "" })}
            value={payload.cityZone}
            handleChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
            helperText={error.cityZone}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.city_zone")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} pt={4}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="EditOutlet-button-01"
                variant="contained"
                size="large"
                className="text-transform-none"
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                }}
                onClick={handleSubmit}
              >
                {t("common.save")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="EditOutlet-button-02"
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_OUTLET_LIST)}
                size="large"
              >
                {t("common.back")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
