import React, { useState, useEffect } from "react";
import { Grid, styled } from "@mui/material";
import font from "../../assets/editor/font.png";
import { useTranslation } from "react-i18next";
import { getProductLabel } from "../../services/TemplateService";
import { pictureList } from "../../services/PictureService";
import { useSnackbar } from "notistack";
const LeftArea = (props) => {
  const { layoutJSON, setLayoutJSON } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [productElement, setProductElement] = useState([]);

  const [picturesList, setPicturesList] = useState([]);

  const ElementBox = styled(Grid)(() => ({
    backgroundColor: "#ffffff",
    padding: "0px 12px 12px 12px",
  }));

  const LableGrid = styled(Grid)(() => ({
    height: "40px",
    lineHeight: "40px",
    color: "#555",
  }));

  const ProductGrid = styled(Grid)(() => ({
    height: "40px",
    lineHeight: "40px",
    color: "#555",
    margin: "5px 0px",
    border: "1px solid gray",
    textAlign: "center",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  }));

  const ImgGrid = styled(Grid)(() => ({
    width: "45px",
    height: "45px",
    border: "1px solid gray",
    margin: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    backgroundSize: "contain",
  }));

  const addProductElement = (item) => {
    let result = layoutJSON.componentList.filter((element) => {
      if (element.productElementName === item.name) {
        return true;
      } else {
        return false;
      }
    });

    if (result.length < 1 || item.name === "currencySymbol") {
      if (item.name === "qrCode") {
        addQrCode();
      } else if (item.name === "barCode") {
        addBarCode();
      } else {
        layoutJSON.componentList.push({
          type: item.name,
          id: new Date().getTime(),
          value: `\${${item.name}}`,
          productElementName: item.name,
          showName: `\${${item.name}}`,
          left: 0,
          top: 0,
          right: 0,
          width: 200,
          height: 100,
          hide: false,
          zIndex: 10,
          bgColor: "#ffffff",
          fontWeight: 600,
          wordSpacing: 1,
          // isBold: false, //是否加粗
          isItaly: false, //是否斜体
          textDecoration: "",
          rotate: 0,
          font: "zfull-GB", //字体
          lineHeight: 30, //行高
          fontSize: 14,
          textAlign: "middle", //字体位置（居中还是左对齐右对齐等）
          transparency: 1, //透明度
          color: "#000000",
          isProductLabel: true,
          borderRadius: 0,
        });
        setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
      }
    } else {
      enqueueSnackbar(t("tips_editor.is_exists"), { variant: "error" });
    }
  };

  const addLogoImage = (item) => {
    layoutJSON.componentList.push({
      type: "logo",
      id: new Date().getTime(),
      left: 0,
      top: 0,
      right: 0,
      width: 200,
      height: 100,
      hide: false,
      zIndex: 10,
      rotate: 0,
      url: item,
      transparency: 1, //透明度
      borderRadius: 0,
    });
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  const addImage = (item) => {
    layoutJSON.componentList.push({
      type: "image",
      id: new Date().getTime(),
      left: 0,
      top: 0,
      right: 0,
      width: 200,
      height: 100,
      zIndex: 10,
      hide: false,
      url: item,
      transparency: 1, //透明度
      rotate: 0,
      borderRadius: 0,
    });
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  const addTextBox = (item) => {
    layoutJSON.componentList.push({
      type: "textBox",
      id: new Date().getTime(),
      value: "content",
      left: 0,
      top: 0,
      right: 0,
      width: 200,
      height: 100,
      hide: false,
      zIndex: 10,
      bgColor: "#ffffff",
      rotate: 0,
      font: "zfull-GB", //字体
      lineHeight: 30, //行高
      fontSize: 14,
      wordSpacing: 1,
      textAlign: "middle", //字体位置（居中还是左对齐右对齐等）
      transparency: 1, //透明度
      color: "#000000",
      isProductLabel: false,
      // isBold: false, //是否加粗
      fontWeight: 600,
      isItaly: false, //是否斜体
      textDecoration: "", //下划线
      borderRadius: 0,
    });
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  const addBarCode = (item) => {
    layoutJSON.componentList.push({
      type: "barCode",
      id: new Date().getTime(),
      left: 0,
      top: 0,
      right: 0,
      width: 200,
      height: 100,
      zIndex: 10,
      rotate: 0,
      hide: false,
      displayValue: true,
      text: "barCode",
      transparency: 1, //透明度
      borderRadius: 0,
      background: "#ffffff",
      format: "CODE128",
      lineColor: "#000000",
    });
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  const addQrCode = (item) => {
    layoutJSON.componentList.push({
      type: "qrCode",
      id: new Date().getTime(),
      left: 0,
      top: 0,
      right: 0,
      width: 100,
      height: 100,
      zIndex: 10,
      text: "qrCode",
      rotate: 0,
      hide: false,
      transparency: 1, //透明度
      borderRadius: 0,
      bgColor: "#ffffff",
      fgColor: "#000000",
    });
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  useEffect(() => {
    loadDataProductLabel();
    loadDataProductPicture();
  }, []);

  const loadDataProductLabel = () => {
    getProductLabel().then((res) => {
      res.data.data.map((item) => {
        productElement.push(item);
      });
    });
  };

  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 100,
    name: "",
  });

  const [downloadURL, setDownLoadURl] = useState([]);
  const loadDataProductPicture = () => {
    pictureList(filters).then((res) => {
      setDownLoadURl(res?.data?.data?.downloadUrlData);
      setPicturesList(res?.data?.data?.picturesData?.picturesData);
    });
  };

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "aliceblue",
      }}
    >
      <ElementBox
        sx={{
          maxHeight: "35%",
          overflowY: "auto",
        }}
      >
        <LableGrid>{t("editor.product_element")}</LableGrid>
        <Grid>
          {productElement?.map((item) => {
            return (
              <ProductGrid
                onClick={() => {
                  addProductElement(item);
                }}
                key={item.label}
              >
                {item.label}
              </ProductGrid>
            );
          })}
        </Grid>
      </ElementBox>

      <ElementBox
        sx={{
          mt: 2,
          maxHeight: "25%",
          overflowY: "auto",
        }}
      >
        <LableGrid>{t("editor.company_logo")}</LableGrid>

        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {picturesList?.map((item) => {
            if (item.pictureType === "Company Logo") {
              let foundUrl = downloadURL.find((obj) => {
                return obj.objectKey === item?.objectKey;
              });

              return (
                <ImgGrid
                  key={foundUrl?.downloadUrl}
                  onClick={() => {
                    addLogoImage(foundUrl?.downloadUrl);
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    src={foundUrl?.downloadUrl}
                  ></img>
                </ImgGrid>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </ElementBox>

      <ElementBox
        sx={{
          mt: 2,
        }}
      >
        <LableGrid>{t("editor.basic_tags")}</LableGrid>
        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <ImgGrid
            onClick={addTextBox}
            sx={{
              backgroundImage: `url(${font})`,
            }}
          ></ImgGrid>

          {/*<ImgGrid*/}
          {/*  onClick={addBarCode}*/}
          {/*  sx={{*/}
          {/*    backgroundImage: `url(${barCode})`,*/}
          {/*  }}*/}
          {/*></ImgGrid>*/}

          {/*<ImgGrid*/}
          {/*  onClick={addQrCode}*/}
          {/*  sx={{*/}
          {/*    backgroundImage: `url(${qrcode})`,*/}
          {/*  }}*/}
          {/*></ImgGrid>*/}
        </Grid>
      </ElementBox>

      <ElementBox
        sx={{
          mt: 2,
          maxHeight: "25%",
          overflowY: "auto",
        }}
      >
        <LableGrid>{t("editor.image_library")}</LableGrid>
        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {picturesList?.map((item) => {
            if (item.pictureType === "General Use") {
              let foundUrl = downloadURL.find((obj) => {
                return obj.objectKey === item?.objectKey;
              });

              return (
                <ImgGrid
                  key={foundUrl?.downloadUrl}
                  onClick={() => {
                    addImage(foundUrl?.downloadUrl);
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    src={foundUrl?.downloadUrl}
                  ></img>
                </ImgGrid>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </ElementBox>
    </Grid>
  );
};
export default LeftArea;
