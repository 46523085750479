import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import OverFlowText from "../../components/OverFlowText";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { REACT_OUTLET_LIST } from "../../router/ReactEndPoints";
import { findOutletById } from "../../services/OutletService";
import CommonUtil from "../../util/CommonUtils";

export default function ViewOutlet() {
  const location = useLocation();
  const { t } = useTranslation();
  const [payload, setPayload] = useState({
    name: "",
    client: "",
    outletTypeName: "",
    cityZoneName: "",
    timeZone: "",
    address: "",
    cityName: "",
    stateName: "",
    countryName: "",
    areaCode: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    findOutletById(location?.state?.id).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        var client = "";
        var outlet = res.data.data;
        setPayload({
          ...payload,
          ...outlet,
        });
      }
    });
  }, []);

  return (
    <RightViewLayout
      navigateBack={REACT_OUTLET_LIST}
      title={t("outlet.view_outlet")}
    >
      <ViewPage>
        <Box mt={2}>
          <Typography variant="fieldLabel">{t("outlet.code")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.code ? payload.code : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("outlet.name")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.name ? payload.name : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.time_zone")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {CommonUtil.getTimeZoneNameByValue(payload?.timeZone)
            ? CommonUtil.getTimeZoneNameByValue(payload?.timeZone)
            : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.address")}:</Typography>
        </Box>
        <OverFlowText variant="fieldValue" maxLength="100">
          {payload && payload?.addressLine1 ? payload?.addressLine1 : "-"}
        </OverFlowText>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.country")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.country ? payload.country : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.state")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.state ? payload.state : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.city")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.city ? payload.city : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.city_zone")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.cityZone ? payload.cityZone : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.area_code")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.areaCode ? payload.areaCode : "-"}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
