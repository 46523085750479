import api from "../configurations/http-common";

export const getPermissions = async (filter) => {
  let url =
    "?pageNumber=" + filter?.pageNumber + "&pageSize=" + filter?.pageSize;
  return api.securedAxios().get("api/permissions" + url);
};

export const getPermissionDetails = async (id) => {
  let url = "?id=" + id;
  return api.securedAxios().get("web/personPermissionDetails" + url);
};

export const getMenuItems = async () => {
  return api.securedAxios().get("api/menuItems");
};

export const getMenus = async () => {
  return api.securedAxios().get("api/menus");
};

export const createRole = async (payload) => {
  return api.securedAxios().post("api/role", payload);
};

export const getRoles = async () => {
  return api.securedAxios().get("api/roles");
};

export const filterRole = async (filter) => {
  let url =
    "?pageNumber=" + filter?.pageNumber + "&pageSize=" + filter?.pageSize;
  return api.securedAxios().get("api/role/list" + url);
};

export const getRoleById = async (id) => {
  return api.securedAxios().get("api/role/" + id);
};

export const updateRole = async (id, payload) => {
  return api.securedAxios().put("api/role/" + id, payload);
};

export const deleteRole = async (id) => {
  return api.securedAxios().delete("api/role/" + id);
};
