export const fontList = [
  "Microsoft YaHei UI Light",
  "Bebas",
  "Impact",
  "Heavy",
  "FZCuSong",
  "Corbel",
  "mnjhzbg",
  "WenQuanYi Micro Hei",
  "zfull-GB",
];

export const fontSizeList = [
  14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 60, 80, 100, 150,
];

export const fontWeightList = [600, 700, 800, 900];
