import api from "../configurations/http-common";

export const chnageStatus = async (id, payload) => {
  return api
    .securedAxios()
    .put(
      "web/price_change_event/change/status/approvalList" + "?id=" + id,
      payload
    );
};

export const getApprovedAndRejectedEvents = async (filters) => {
  let url =
    "web/approved_rejected" +
    "?pageNumber=" +
    filters.pageNumber +
    "&pageSize=" +
    filters.pageSize;
  return api.securedAxios().get(url);
};

export const getPriceChangeEventsApprovalFlowChartListApproveList = async (
  filters
) => {
  let url =
    "web/price_change_events/approval_list" +
    "?pageNumber=" +
    filters?.pageNumber +
    "&pageSize=" +
    filters?.pageSize;

  if (filters.loggedId) {
    url = url + "&loggedId=" + filters?.loggedId;
  }
  return api.securedAxios().get(url);
};

export const getPriceChangeEventsFlowChart = async (filters) => {
  let url =
    "web/get/Approval_FlowChart" +
    "?pageNumber=" +
    filters?.pageNumber +
    "&pageSize=" +
    filters?.pageSize;
  if (filters.loggedId) {
    url = url + "&loggedId=" + filters?.loggedId;
  }

  return api.securedAxios().get(url);
};

export const addApprovalPersonId = async (payload) => {
  return api.securedAxios().post("web/add_approval_person", payload);
};

export const deleteApprovalFlowChartListById = async (filters) => {
  let url = "web/approval_flowchart/delete/" + filters?.id;
  return api.securedAxios().delete(url);
};

export const getApprovalById = async (id) => {
  return api.securedAxios().get(`/web/approval_flowchart/get/${id}`);
};

export const editApprovalFlowChart = async (id, payload) => {
  return api.securedAxios().put("web/approval_flowchart/edit/" + id, payload);
};
