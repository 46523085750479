import React, { useState, useEffect } from "react";
import { Grid, styled } from "@mui/material";
import RenderCommon from "./RenderCommon";
import * as Barcode from "jsbarcode";
export default function RenderImage(props) {
  const { info } = props;
  const barCodeRef = React.useRef(null);

  useEffect(() => {
    createBarcode();
  }, [info]);

  const createBarcode = () => {
    if (!barCodeRef.current) return;
    Barcode(barCodeRef.current, info.text || "  ", {
      renderer: "svg",
      width: 1.6,
      height: info.displayValue ? info.height - 26 : info.height,
      displayValue: info.displayValue,
      // displayValue: false,
      textPosition: "bottom",
      textMargin: 2,
      fontSize: 16,
      background: info.background,
      format: info.format,
      fontOptions: 600,
      lineColor: info.lineColor,
      textAlign: "center",
      margin: 0,
      marginBottom: 0,
    });
  };

  return (
    <RenderCommon {...props}>
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: info.bgColor,
          overflow: "hidden",
          opacity: info.transparency,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg ref={barCodeRef}></svg>
      </Grid>
    </RenderCommon>
  );
}
