import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyBoardLeftArrowIcon } from "../assets/images/KeyboardArrowLeftIcon.svg";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
const TitleBar = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <Grid
      display={"flex"}
      onClick={() => {
        props?.navigateBack === "-1"
          ? navigate(-1)
          : navigate(props?.navigateBack);
      }}
    >
      {props.navigateBack && (
        <Tooltip title={t("common.back")}>
          <KeyBoardLeftArrowIcon
            id="TitleBar"
            style={{ cursor: "pointer", marginTop: "5px", marginRight: "10px" }}
            width={17}
            height={17}
            fontSize="small"
          />
        </Tooltip>
      )}
      <Typography variant="title" sx={{ fontSize: "20px" }}>
        {props.title}
      </Typography>
      {props.children}
    </Grid>
  );
};
export default TitleBar;
