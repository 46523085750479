import React, { useState, useEffect } from "react";
import { getTemplateData } from "@s/TemplateService";
import { loadDataTemplates } from "../js/interface";
import { ReactComponent as Tick } from "@/assets/images/Tick.svg";
import PreView from "@p/editor/PreView";
import { Grid, Typography, Button } from "@mui/material";
import Search from "./Serach";
function Templete(props) {
  const {
    addFormik,
    templateList,
    setTemplateList,
    totalPages,
    setTotalPages,
  } = props;
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [serchData, setSerchData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 3,
  });
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);

  useEffect(() => {
    if (currentPage == 0) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
    if (totalPages - 1 <= currentPage) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [currentPage, totalPages]);

  const handleTemplateSelection = (template) => {
    if (selectedTemplate && selectedTemplate !== template.id) {
      setSelectedTemplates([template.id]);
      setSelectedTemplate(template.id);
      addFormik.setFieldValue("templateId", template.id);
    } else {
      if (selectedTemplates.includes(template.id)) {
        setSelectedTemplates(
          selectedTemplates.filter((t) => t !== template.id)
        );
        setSelectedTemplate(null);
        addFormik.setFieldValue("templateId", "");
      } else {
        setSelectedTemplates([template.id]);
        setSelectedTemplate(template.id);
        addFormik.setFieldValue("templateId", template.id);
      }
    }
  };

  // 根据name搜索模板
  const handlerSerach = () => {
    loadDataTemplates(
      {
        pageNumber: 0,
        pageSize: 3,
      },
      setTemplateList,
      addFormik.values.promotionType,
      addFormik.values.productId,
      setCurrentPage,
      serchData,
      setTotalPages
    );
  };

  //点击上一页
  const handlePrev = () => {
    let params = {
      ...filters,
      pageNumber: currentPage === 0 ? currentPage : currentPage - 1,
    };

    loadDataTemplates(
      params,
      setTemplateList,
      addFormik.values.promotionType,
      addFormik.values.productId,
      setCurrentPage,
      serchData,
      setTotalPages
    );
  };

  //点击下一页
  const handleNext = () => {
    let params = {
      ...filters,
      pageNumber: currentPage + 1,
    };

    loadDataTemplates(
      params,
      setTemplateList,
      addFormik.values.promotionType,
      addFormik.values.productId,
      setCurrentPage,
      serchData,
      setTotalPages
    );
  };

  const handlerClear = () => {
    setSerchData("");
    loadDataTemplates(
      {
        pageNumber: 0,
        pageSize: 3,
      },
      setTemplateList,
      addFormik.values.promotionType,
      addFormik.values.productId,
      setCurrentPage,
      null,
      setTotalPages
    );
  };

  return (
    <Grid>
      <Grid mt={4} position={"absolute"} right={10}>
        <Search
          disablePrev={disablePrev}
          disableNext={disableNext}
          showIcon={true}
          value={serchData}
          onChange={(e) => {
            setSerchData(e.target.value);
          }}
          onClick={handlerSerach}
          handlePrev={handlePrev}
          handleNext={handleNext}
          handlerClear={handlerClear}
        ></Search>
      </Grid>
      <Grid
        sx={{
          display: "flex",
        }}
        spacing={2}
        mt={12}
      >
        {templateList?.map((template) => (
          <Grid item key={template.id} style={{ margin: "10px" }}>
            <Typography variant="h6">{template?.name}</Typography>

            <Typography>{template?.screenResolution}</Typography>

            <Grid
              sx={{
                flexDirection: "row",
                display: "flex",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <div
                onClick={() => handleTemplateSelection(template)}
                style={{
                  border:
                    selectedTemplates &&
                    selectedTemplates.includes(template.id) &&
                    selectedTemplate
                      ? "3px solid #1487CA"
                      : "2px solid #A2A3A3",

                  width: template?.templateJson?.width,
                  height: template?.templateJson?.height,
                }}
              >
                {/* {selectedTemplates &&
                selectedTemplates.includes(template.id) &&
                selectedTemplate ? (
                  <Tick
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 11111,
                    }}
                  />
                ) : null} */}

                <PreView layoutJSON={template?.templateJson}></PreView>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Templete;
