import React, { useEffect, useState, useRef } from "react";
import { getPriceRuleConfig } from "../js/config";
import { useTranslation } from "react-i18next";
import { promotionType } from "../js/enum";
import Accourdion from "@c/Accordion";
import CmpFormik from "@z/CmpFormik";

function PriceRule(props) {
  const {
    addFormik,
    priceRuleConfig,
    setPriceRulrConfig,
    expandedIndex,
    handleChange,
    handleConfirm,
    handlerCancel,
  } = props;
  const { t } = useTranslation();
  let inputRef = useRef();
  const [labelValue, setLabelValue] = useState("");
  const [isShowPrecent, setIsShowPreCent] = useState("");

  useEffect(() => {
    let res = promotionType.find((item) => {
      return item.id == addFormik.values.promotionType;
    });

    setLabelValue(res?.value);
    setIsShowPreCent(res?.id);
  }, [addFormik.values.promotionType]);

  useEffect(() => {
    let configInfo = getPriceRuleConfig(
      t,
      addFormik,
      labelValue,
      isShowPrecent,
      inputRef
    );
    setPriceRulrConfig(configInfo);
  }, [labelValue, isShowPrecent]);

  return (
    <Accourdion
      elevation={0}
      expanded={expandedIndex === 3}
      label={t("events.priceChangeRule")}
      onChange={handleChange(3, ["promotionType", "promotionDescription"])}
      handlerConfirm={handleConfirm(
        4,
        ["promotionType", "promotionDescription"],
        () => {
          inputRef.current?.focus();
        }
      )}
      handlerCancel={handlerCancel(
        2,
        ["promotionType", "promotionDescription"],
        () => {
          inputRef.current?.focus();
        }
      )}
    >
      <CmpFormik
        sx={4}
        formik={addFormik}
        formConfig={priceRuleConfig}
      ></CmpFormik>
    </Accourdion>
  );
}

export default PriceRule;
