import {
  getTemplateData,
  getProductLabelIndo,
  getlabelValue,
  getProductList,
} from "@s/TemplateService";
import html2canvas from "html2canvas";
import PreView from "../../editor/PreView";
import ReactDOM from "react-dom";
import axios from "axios";
import { getDeviceOnlineOutlet } from "@s/OutletService";
import { getByIdPriceData } from "@s/PictureService";
import { findProductById } from "@s/ProductService";

//获取模板列表接口数据
export const loadDataTemplates = (
  filters,
  setTemplateList = () => {},
  type,
  productId = "",
  setCurrentPage = () => {},
  serchData = null,
  setTotalPages = () => {}
) => {
  getTemplateData({
    ...filters,
    type: type,
    name: serchData,
    productId: productId,
  }).then((res) => {
    if (res?.data?.code === "LVLI0000" && res?.data?.data) {
      setTemplateList(res?.data?.data?.objects);
      setCurrentPage(res?.data?.data?.currentPage);
      setTotalPages(res?.data?.data?.totalPages);
    } else if (res?.data?.code === "LVLE0054") {
      setTemplateList([]);
      setTotalPages(0);
    }
    // return res
  });
};

// 获取设备在线的门店
export const getOnlineOutlet = (
  filters,
  setTotalRecords = [],
  setRecords,
  zoneId = "-12:00"
) => {
  getDeviceOnlineOutlet({
    ...filters,
    zoneId: zoneId,
  }).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setTotalRecords(res?.data?.data?.totalCount);
      setRecords(res?.data?.data?.objects);

      // return res
    }
  });
};

export const getProductlabel = (setProductlabel) => {
  getProductLabelData().then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setProductlabel(res?.data?.data);
    } else {
      setProductlabel([]);
    }
  });
};

// 根据商品类型 获取当前商品品牌
export const getProductBrandInfo = (elementType, setBrandData) => {
  try {
    getlabelValue(elementType).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setBrandData(res?.data?.data);
      } else {
        setBrandData([]);
      }
    });
  } catch (error) {
    console.error("响应错误,无返回数据");
    setBrandData([]);
  }
};

// 根据商品品牌 获取当前商品数据
export const getProductInfo = (elementType, brandData, setProductData) => {
  getProductList({
    attributeId: elementType,
    attributeValue: brandData,
  }).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setProductData(res?.data?.data);
    } else {
      setProductData([]);
    }
  });
};

export const getCurrencySymbol = (currency) => {
  if (currency === "INR") {
    return "\u20B9";
  }
  if (currency === "USD") {
    return "\u0024";
  }
  if (currency === "EU") {
    return "\u20AC";
  }
  if (currency === "RMB") {
    return "\u00A5";
  }
  if (currency === "THB") {
    return "\u0E3F";
  }
};

// 将选中商品数据替换到选张得模板上面
export const updateComponentValues = (
  componentList,
  customPayload,
  addFormik
) => {
  let newJSON = JSON.parse(componentList);
  newJSON?.componentList?.forEach((item) => {
    if (item.type == "priceRule") {
      if (addFormik.values["promotionType"] == 1) {
        item.value = addFormik.values["promotionDescription"] + "%";
      } else {
        item.value = addFormik.values["promotionDescription"];
      }
    } else {
      if (customPayload?.hasOwnProperty(item.type)) {
        if (item.type === "qrCode" || item.type === "barCode") {
          item.value = customPayload[item.type];
          item.text = customPayload[item.type];
        } else if (
          item.type === "discountPrice" &&
          addFormik.values["promotionType"] == 1
        ) {
          item.value = (
            customPayload.productPrice -
            customPayload.productPrice *
              (addFormik.values["promotionDescription"] / 100)
          ).toFixed(2);
        } else {
          item.value = customPayload[item.type];
        }
      }
    }
  });

  return newJSON;
};

export const handlerUpdataFile = (item, addFormik) => {
  return new Promise((resolve, reject) => {
    // Create a temporary container for rendering
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    document.body.appendChild(tempContainer);

    const elementJSON = item;

    // Render the PreView component inside the temporary container
    ReactDOM.render(<PreView layoutJSON={elementJSON} />, tempContainer, () => {
      // Ensure the DOM has rendered by introducing a short delay
      setTimeout(() => {
        const width = parseInt(elementJSON.width, 10);
        const height = parseInt(elementJSON.height, 10);

        if (!width || !height) {
          console.error("Invalid width or height");
          reject(new Error("Invalid width or height"));
          return;
        }

        // Create a canvas element for capturing the rendered component
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        // Capture the rendered content using html2canvas
        html2canvas(tempContainer, {
          canvas,
          scale: 1,
          useCORS: true,
          allowTaint: true,
        })
          .then(async (capturedCanvas) => {
            const dataUrl = capturedCanvas.toDataURL("image/png");
            const byteString = atob(dataUrl.split(",")[1]);
            const mimeString = dataUrl
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uintArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
              uintArray[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([uintArray], { type: mimeString });
            const productName =
              localStorage.getItem("productName") || "product";
            const file = new File([blob], `${productName}.png`, {
              type: "image/png",
            });

            blobToDataURI(blob, (result) => {
              console.log("HHHHHHHHHHH", result);
              console.log(
                "%c+",
                `font-size: 1px;
              padding: 100px 100px;
              background-image: url(` +
                  result +
                  `);
              background-size: contain;
              background-repeat: no-repeat;
              color: transparent;`
              );
            });
            // Create FormData for file upload
            const formData = new FormData();
            formData.append("mediaType", "image/png");
            formData.append("file", file);

            // Upload the file to the server
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/web/uploadFile`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("USER_TOKEN"),
                  },
                }
              );

              if (response?.data?.code === "LVLI0000") {
                const updatedItem = {
                  ...item,
                  imageUrl: response.data.data, // Update the item with the new imageUrl
                };
                addFormik.setFieldValue("url", response.data.data);
                resolve(updatedItem); // Resolve the updated item
              } else {
                console.error("Upload failed");
                addFormik.setFieldValue("url", "");
                reject(new Error("Upload failed"));
              }
            } catch (uploadError) {
              console.error("Error during file upload:", uploadError);
              reject(uploadError);
            } finally {
              // Clean up the temporary container after processing
              document.body.removeChild(tempContainer);
            }
          })
          .catch((canvasError) => {
            console.error("Error capturing canvas:", canvasError);
            reject(canvasError);
            // Clean up
            document.body.removeChild(tempContainer);
          });
      }, 100); // Optional delay to ensure rendering completion
    });
  });
};

export const blobToDataURI = (blob, callback) => {
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function (e) {
    callback(e.target.result);
  };
};

//获取变价详情

export const getPriceDetail = (id, setDetailData, addFormik) => {
  getByIdPriceData(id).then((res) => {
    setDetailData(res?.data?.data);
    addFormik.setFieldValue("detailList", res?.data?.data);
  });
};

export const handlerView = (
  templateList,
  productData,
  templateId,
  setSelectedImage,
  setLightboxOpen,
  addFormik
) => {
  const templateData = templateList?.find((itme) => {
    return itme?.id === templateId;
  });

  if (templateData && productData) {
    let elementJSON = updateComponentValues(
      templateData?.templateJson,
      productData,
      addFormik
    );
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    document.body.appendChild(tempContainer);

    // Render the PreView component inside the temporary container
    ReactDOM.render(<PreView layoutJSON={elementJSON} />, tempContainer, () => {
      setTimeout(() => {
        const width = parseInt(elementJSON.width, 10);
        const height = parseInt(elementJSON.height, 10);

        if (!width || !height) {
          return;
        }

        // Create a canvas element for capturing the rendered component
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        // Capture the rendered content using html2canvas
        html2canvas(tempContainer, {
          canvas,
          scale: 1,
          useCORS: true,
          allowTaint: true,
        })
          .then((capturedCanvas) => {
            const dataUrl = capturedCanvas.toDataURL("image/png");
            const byteString = atob(dataUrl.split(",")[1]);
            const mimeString = dataUrl
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uintArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
              uintArray[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([uintArray], { type: mimeString });
            const productName =
              localStorage.getItem("productName") || "product";
            const file = new File([blob], `${productName}.png`, {
              type: "image/png",
            });

            blobToDataURI(blob, (result) => {
              // console.log("HHHHHHHHHHH", result);
              setSelectedImage(result);
              setLightboxOpen(true);

              // console.log(
              //   "%c+",
              //   `font-size: 1px;
              // padding: 100px 100px;
              // background-image: url(` +
              //     result +
              //     `);
              // background-size: contain;
              // background-repeat: no-repeat;
              // color: transparent;`
              // );
            });
          })
          .catch((canvasError) => {
            // Clean up
            document.body.removeChild(tempContainer);
          });
      }, 100); // Optional delay to ensure rendering completion
    });
  }
};

// 获取商品标签 Element Label
export const getProductLabelData = (setProductLabel) => {
  getProductLabelIndo().then((res) => {
    if (res?.data?.code === "LVLI0000") {
      let list = res.data.data.filter((item) => {
        return !(
          item.dataLevel === "System Default" && item.name === "priceRule"
        );
      });

      setProductLabel(list);
    } else {
      setProductLabel([]);
    }
  });
};

// 获取商品详情接口
export const getProductDetail = (productId, setCustomPayload) => {
  findProductById(productId).then((res) => {
    if (res?.data?.data && res?.data?.code === "LVLI0000") {
      setCustomPayload(res.data.data.object);
    }
  });
};
