import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_APPROVAL_FLOW_CHART_LIST } from "../../router/ReactEndPoints";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { approvalUserList } from "../../services/PersonService";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";

import { useSnackbar } from "notistack";
import { addApprovalPersonId } from "../../services/ApprovalService";
import { use } from "echarts";

export default function AddFlowChart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [persons, setPersons] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState({
    event: "",
    persons: [],
    id: "",
    productId: "",
    priceChangeRuleId: "",
    templateId: "",
    deviceId: [],
    companyId: "",
    startAt: null,
    endAt: null,
    days: [],
    dates: [],
    imageDownloadUrl: "",
    approvalPersons: [],
    approvalStatus: "",
    clientId: "",
  });

  const [error, setError] = useState({});

  useEffect(() => {
    approvalUserList().then((res) => {
      if (res?.data?.code === "UASI0000" && res?.data?.data) {
        setPersons(
          res.data.data.map((user) => ({ id: user.id, value: user.firstName }))
        );
      }
    });
  }, []);

  const [userId, setUserId] = useState();

  useEffect(() => {
    const user = localStorage.getItem("USER");
    const userData = JSON.parse(user);

    setUserId(userData?.id);
  }, []);

  const [persons_, setPersons_] = useState([]);
  useEffect(() => {
    let person = persons.map((p) => p?.id !== userId);
    setPersons_(person);
  }, [userId]);

  const [approvalPersons, setApprovalPersons] = useState([
    { id: 1, value: "" },
  ]);
  const [selectedValues, setSelectedValues] = useState({});

  const handleAddApprovalPerson = () => {
    if (approvalPersons.length < 5) {
      const newId = approvalPersons.length + 1;

      const updatedApprovalPersons = [
        ...approvalPersons,
        { id: newId, value: "" },
      ];
      setApprovalPersons(updatedApprovalPersons);
    }
  };
  const handleRemoveApprovalPerson = () => {
    if (approvalPersons.length > 1) {
      // 获取最后一个输入人的 ID
      const lastPersonId = approvalPersons[approvalPersons.length - 1].id;

      // 创建一个新的数组，移除最后一个输入框
      const updatedApprovalPersons = approvalPersons.slice(0, -1);

      setApprovalPersons(updatedApprovalPersons);
      delete selectedValues[lastPersonId];
      setSelectedValues(selectedValues);
      delete error[lastPersonId];
      setError(error);
    }
  };

  const handleChangeApprovalPerson = (id, value) => {
    // Check if the selected value is already present in any other dropdown
    const isValueSelected = Object.values(selectedValues).some(
      (val) => val && val.id === value?.id
    );

    if (isValueSelected) {
      setError((prevError) => ({
        ...prevError,
        [id]: ` ${value.value} is already selected. Please choose a different one.`,
      }));
    } else {
      setError({
        ...error,
        [id]: "",
      });

      setSelectedValues((prevState) => {
        // 检查当前状态中是否已经存在该 ID
        if (prevState[id]) {
          return prevState; // 如果已存在，返回旧状态，不进行更新
        }

        // 如果不存在，添加新的值
        return {
          ...prevState,
          [id]: value,
        };
      });

      const updatedPersons = approvalPersons.map((person) => ({
        id: person.id,
        value: person.id === id ? value : person.value,
      }));

      setApprovalPersons(updatedPersons);

      const updatedIds = updatedPersons.map((person) => person?.value?.id);

      setPayload((prevPayload) => ({
        ...prevPayload,
        approvalPersons: updatedIds,
      }));
    }
  };

  const handleSubmit = () => {
    // Check if any approval person is empty
    const isEmptyApprovalPerson = approvalPersons.some(
      (person) =>
        person.value === null ||
        person.value === undefined ||
        person.value === ""
    );

    if (isEmptyApprovalPerson) {
      enqueueSnackbar(t("tips_approval.not_empty"), { variant: "error" });
      return;
    }
    // Check for duplicate approval persons
    const duplicatePersons = Object.values(selectedValues).reduce(
      (duplicates, person, index, array) => {
        if (array.indexOf(person) !== index) {
          duplicates.push(person);
        }
        return duplicates;
      },
      []
    );

    if (duplicatePersons.length > 0) {
      // Set error messages for duplicate approval persons
      const errorMessage =
        "This approval persons contains duplicate values. Please choose unique values.";
      Object.entries(selectedValues).forEach(([id, person]) => {
        if (duplicatePersons.includes(person)) {
          // setError(prevError => ({
          //   ...prevError,
          //   persons: errorMessage
          // }));
        } else {
          setError((prevError) => ({
            ...prevError,
            persons: "",
          }));
        }
      });

      enqueueSnackbar(errorMessage, { variant: "error" });
      return;
    }

    const valueList = [];
    approvalPersons.forEach((person) => {
      valueList.push(person.value.id);
    });
    const approvalPersonValues = approvalPersons.map((person) => person.value);

    const updatedPayload = {
      ...payload,
      id: payload.id,
      approvalPersons: valueList,
      approvalStatus: "pending",
      clientId: payload.clientId,
    };
    console.log("the approval personss", updatedPayload);

    var request = {
      ...payload,
      id: payload.id,
      approvalPersons: payload.approvalPersons,
      approvalStatus: "pending",
      clientId: payload.clientId,
    };

    const updatedApprovalPersonValues = approvalPersonValues.map(
      ({ value, ...rest }) => {
        return {
          firstName: value,
          ...rest, // 保留其他字段
        };
      }
    );

    let that = [];
    let params = {
      grade: "",
      userList: updatedApprovalPersonValues,
    };
    that.push(params);

    addApprovalPersonId(that).then((res) => {
      if (res?.data?.code === "LVLI0008") {
        enqueueSnackbar(t("tips_approval.added"), { variant: "success" });
        navigate(REACT_APPROVAL_FLOW_CHART_LIST);
      } else {
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
        });
      }
    });
  };

  return (
    <RightViewLayout
      title={t("table_approval.add")}
      navigateBack={REACT_APPROVAL_FLOW_CHART_LIST}
    >
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0} sx={{ height: "100%" }}>
          <Typography p={2}>
            {t("table_approval.note")}
            <span style={{ fontSize: 16, color: "gray", marginLeft: "5px" }}>
              {t("tips_approval.information")}
            </span>
          </Typography>
          <Grid container px={2}>
            <Grid xs={12} md={8} item spacing={2}>
              {approvalPersons.map((person) => (
                <Grid container display="flex" alignItems="center" px={2}>
                  <Grid xs={12} md={7} item key={person.id}>
                    {/* Autocomplete for Approval Person */}
                    <InputLabel shrink htmlFor="bootstrap-input">
                      {`${t("table_approval.person")} ${person.id}`}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Autocomplete
                      size="small"
                      noOptionsText={t("tips.no_options")}
                      options={persons.filter(
                        (x) =>
                          !approvalPersons
                            .map((x) => x.value?.id)
                            .includes(x.id)
                      )}
                      value={selectedValues[person.id] || null}
                      onChange={(event, value) => {
                        setError((prevError) => ({
                          ...prevError,
                          [person.id]: "",
                        }));
                        if (value === null) {
                          setSelectedValues((prevSelectedValues) => ({
                            ...prevSelectedValues,
                            [person.id]: value,
                          }));
                          handleChangeApprovalPerson(person.id, value);
                        }
                        if (value !== null) {
                          setSelectedValues({
                            ...selectedValues,
                            [person.id]: value,
                          });
                          handleChangeApprovalPerson(person.id, value);
                        }
                      }}
                      getOptionLabel={(option) =>
                        option.value ? option.value : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={error[person.id] ? true : false}
                          helperText={error[person.id] || ""}
                        />
                      )}
                    />
                    {/* Render Add Button only after Approval Person 1 */}
                  </Grid>
                  {person.id === approvalPersons.length && (
                    <Grid item xs={12} md={5} pl={2} pt={1} display={"flex"}>
                      <Box mt={1}>
                        {/* Add Button */}
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#FFFFFF",
                            background:
                              "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                          }}
                          onClick={handleAddApprovalPerson}
                          startIcon={
                            <AddIcon
                              style={{
                                marginLeft: "10px",
                              }}
                            />
                          }
                        ></Button>
                      </Box>
                      <Box mt={1}>
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#FFFFFF",
                            background:
                              "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                            ml: 2,
                          }}
                          onClick={handleRemoveApprovalPerson}
                          startIcon={
                            <MinusIcon
                              style={{
                                marginLeft: "10px",
                              }}
                            />
                          }
                        ></Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              ))}

              <Grid xs={12} md={7} item pt={2}>
                {/* Submit and Cancel Buttons */}
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Box item pl={2}>
                    <Button
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      style={{
                        size: "medium",
                        background:
                          "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        opacity: 1,
                      }}
                      onClick={handleSubmit}
                      sx={{
                        color: "#FFFFFF",
                        background:
                          "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                      }}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item>
                    <Button
                      className="text-transform-none"
                      variant="outlined"
                      onClick={() => navigate(REACT_APPROVAL_FLOW_CHART_LIST)}
                      size="large"
                    >
                      {t("common.cancel")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
