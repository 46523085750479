export default {
  common: {
    actions: "การดำเนินการ",
    view: "ดู",
    edit: "แก้ไข",
    delete: "ลบ",
    manage: "จัดการ",
    preview: "ดูตัวอย่าง",
    clear: "ล้าง",
    confirm: "ยืนยัน",
    submit: "ส่ง",
    next: "ถัดไป",
    back: "ย้อนกลับ",
    save: "บันทึก",
    reset: "รีเซ็ต",
    cancel: "ยกเลิก",
    find: "ค้นหา",
    refresh: "รีเฟรช",
    resendEmail: "ส่งอีเมลอีกครั้ง",
    upload: "อัปโหลด",
    add: "เพิ่ม",
    prompt: "แจ้งเตือน",
    ok: "ตกลง",
    continue: "ดำเนินการต่อ",
    update: "อัปเดต",
    apply: "นำไปใช้",
    previous: "ก่อนหน้า",
    type: "ประเภท",
    success: "สำเร็จ",
    fail: "ล้มเหลว",
    no_data: "ไม่มีข้อมูล",
    start_time: "เวลาเริ่มต้น",
    end_time: "เวลาสิ้นสุด",
    per_page: "จำนวนรายการต่อหน้า",
    start_date: "เวลาเริ่ม",
    end_date: "เวลาสิ้นสุด",
    yes: "ใช่",
  },
};
