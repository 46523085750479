import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";

export const getDefaultTemplateList = async (filters) => {
  let url = `web/template/list?`;

  if (filters?.pageNumber) {
    url = url + "&pageNumber=" + filters.pageNumber;
  }

  if (filters?.pageSize) {
    url = url + "&pageSize=" + filters.pageSize;
  }
  if (filters?.screenResolution) {
    url = url + "&screenResolutions=" + filters.screenResolution;
  }
  if (filters?.name) {
    url = url + "&name=" + filters.name;
  }
  if (filters?.type) {
    url = url + "&type=" + filters.type;
  }
  return api.securedAxios().get(url);
};

export const getTemplates = async () => {
  return api.securedAxios().get("web/templates");
};

export const findTemplateById = async (id) => {
  return await api.securedAxios().get("web/template/" + id);
};

export const syncScreens = async (payload) => {
  return api.securedAxios().post("web/template/esls", payload);
};

export const getTemplateList = async (filters) => {
  return api
    .securedAxios()
    .get(
      "web/template/list" +
        "?pageNumber=" +
        filters.pageNumber +
        "&pageSize=" +
        filters.pageSize
    );
};

export const createTemplate = async (payload) => {
  return api.securedAxios().post("web/template", payload);
};

export const updateTemplate = async (payload) => {
  return api.securedAxios().put("web/template", payload);
};

export const getDownloadUrl = async (payload) => {
  return api.securedAxios().get("web/template/url?objectKey=" + payload);
};

export const deleteTemplate = async (id) => {
  return api.securedAxios().delete("web/template/" + id);
};

export const getPreAssignedUrl = async (fileName) => {
  return api.securedAxios().post("web/upload_template/" + fileName);
};

export const findTemplateByTypeAndResolution = async (filters) => {
  let url = `web/template/resolutions?`;
  if (!CommonUtil.isEmpty(filters?.screenResolution)) {
    url = url + "screenResolutions=" + filters.screenResolution;
  }
  if (!CommonUtil.isEmpty(filters?.type)) {
    url = url + "&type=" + filters.type;
  }

  return api.securedAxios().get(url);
};

export const saveTemplate = async (payload) => {
  return api.securedAxios().post("/web/template/saveTemplate", payload);
};

export const getProductLabel = () => {
  return api.securedAxios().get("/web/attributes");
};

export const getProductPicture = (payload) => {
  return api.securedAxios().get("/web/picture_library/list", payload);
};

export const getTemplateDetail = (id) => {
  return api.securedAxios().get(`/web/template/${id}`);
};

export const getTemplateData = (params) => {
  return api.securedAxios().get("/web/template/pageResolutions", {
    params,
  });
};

export const getProductLabelIndo = () => {
  return api.securedAxios().get("/web/attributes");
};

// 根据产品标签Id获取产品标签Value
export const getlabelValue = (id) => {
  return api.securedAxios().get(`/web/product/attribute_values/${id}`);
};

// 根据attribute_values过滤，查询产品列表
export const getProductList = (params) => {
  return api.securedAxios().get(`/web/products/filter/attribute_values`, {
    params: params,
  });
};
