import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DeviceIcon from "../../assets/images/icon_device.png";
import DeviceIcon1 from "../../assets/images/Gateway_Icon-20240320-042304.svg";
import CustomInput from "../../components/CustomInput";
import SimpleDailogBox from "../../components/SimpleDailogBox";
import TitleBar from "../../components/TitleBar";
import { REACT_DEVICE_LIST } from "../../router/ReactEndPoints";
import { listCompanies } from "../../services/CompanyService";
import { bindDevice, findDeviceBySn } from "../../services/DeviceService";
import { filterOutlet } from "../../services/OutletService";
import CommonUtil from "../../util/CommonUtils";
import { timeZoneList } from "../../util/TimeZone";
import AddDevice from "./AddDevice";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const BindDevice = () => {
  const [open, setOpen] = useState(false);
  const [site, setSite] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [zone, setZone] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [timeZone, setTimeZone] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deviceType, setDeviceType] = useState("acc");
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    deviceId: "",
    sn: "",
    outletId: "",
    timeZone: "",
    clientId: "",
    applicationId: "",
  });
  const [error, setError] = React.useState({
    deviceId: "",
    sn: "",
    outletId: "",
    timeZone: "",
    clientId: "",
    applicationId: "",
  });

  useEffect(() => {
    if (open === true) {
      setClients([]);
      setSite([]);
      setClient({});
      loadCompanies();
    }
  }, [open]);

  useEffect(() => {
    setTimeZone("");
    setSelectedSite({});
    setState({
      ...state,
      outletId: "",
    });
    setSite([]);
    if (client?.id) {
      filterOutlet(1, 1000, { companyIds: client?.id }).then((response) => {
        if (response.data.code === "LMSI6000" && response.data.data) {
          setSite(response?.data?.data?.objects);
        }
      });
    }
  }, [client]);

  const loadCompanies = () => {
    listCompanies({ pageNumber: 1, pageSize: 1000 }).then((res) => {
      if (
        res.data.code === "UASI0029" &&
        res.data.data &&
        res?.data?.data?.companies
      ) {
        setClients(res?.data?.data?.companies);
      }
    });
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedSite)) {
      const payload = {
        pageNumber: 1,
        pageSize: 0,
        filter: { outletId: selectedSite },
      };
    }
  }, [selectedSite]);

  const handleClear = () => {
    setState({
      ...state,
      timeZone: "",
      outletId: "",
      companyId: "",
    });
    setTimeZone("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isValidSerialNumber = (sn) => {
    const pattern = /^[^.\/?][^\/?]*$/;
    return pattern.test(sn);
  };

  const findDevice = () => {
    if (CommonUtil.isEmptyString(state.sn)) {
      setError({
        sn: t("tips.required"),
      });
      return;
    }
    if (!isValidSerialNumber(state.sn)) {
      setError({
        sn: "Invaild device serial number",
      });
      return;
    }
    findDeviceBySn(state.sn, (res) => {
      if (res.data.code === "DMSI0000" && res.data.data.protocolType === null) {
        enqueueSnackbar("Device does not exist.", { variant: "error" });
      } else if (
        res != null &&
        res.data.code === "DMSI0000" &&
        res != undefined
      ) {
        setState({
          ...state,
          deviceId: res.data.data.deviceId,
        });
        setDeviceType(res.data.data.deviceType);
        setOpen(true);
      } else if (res === undefined) {
        enqueueSnackbar(t("APR0049"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
      }
    });
  };
  const getTimezoneName = (value) => {
    var name = value;
    timeZoneList.map((t) => {
      if (value == t.value) {
        name = t.name;
      }
    });
    return name;
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.companyId)) {
      setError({
        companyId: `${t("tips.required")}`,
      });
      return;
    }

    if (CommonUtil.isEmptyString(state.outletId)) {
      setError({
        outletId: `${t("tips.required")}`,
      });
      return;
    }
    bindDevice(state, (res) => {
      if (res != null && res.data.code === "DMSI0000") {
        enqueueSnackbar(t("tips_gateway.deviceBindSuccess"), {
          variant: "success",
        });
        navigate(REACT_DEVICE_LIST);
      } else {
        alert(res.data.message);
        console.log(res);
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
      }
    });
  };

  const findZone = (id) => {
    let zoneObj = { id: "", name: "" };
    zone.map((data) => {
      if (data.id === id) zoneObj = data;
    });
    return zoneObj;
  };

  const findSite = (id) => {
    let siteObj = { id: "", name: "" };
    site.map((data) => {
      if (data.id === id) siteObj = data;
    });
    return siteObj;
  };

  const zoneField = () => (
    <Autocomplete
      required
      error={error.zoneId}
      fullWidth
      disablePortal
      value={state.zoneId ? findZone(state.zoneId) : { id: "", name: "" }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="zone bind list"
      getOptionLabel={(option) => option.name}
      options={zone}
      renderInput={(params) => (
        <CustomInput
          id="BindDevice1"
          placeholder={t("tips_gateway.selectYourZone")}
          {...params}
          helperText={error.zoneId}
          error={error.zoneId}
          resetError={() => setError({ ...error, zoneId: "" })}
          required
          label={t("tips_gateway.bindZone")}
          size="large"
        />
      )}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            zoneId: newValue.id,
          });
        }
      }}
      ListboxProps={{ style: { maxHeight: "90px" } }}
    />
  );

  const siteField = (key) => (
    <Autocomplete
      fullWidth
      error={error.outletId}
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={state.outletId ? findSite(state.outletId) : { id: "", name: "" }}
      id="site bind list"
      getOptionLabel={(option) => option.name}
      options={site}
      sx={{ margin: "20px 0px" }}
      renderInput={(params) => (
        <CustomInput
          id="BindDevice2"
          {...params}
          required
          helperText={error.outletId}
          error={error.outletId}
          resetError={() => setError({ ...error, outletId: "" })}
          label={t("gateway.outlet")}
          size="small"
        />
      )}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            outletId: newValue.id,
          });
          setTimeZone(newValue.address.timeZone);
          setSelectedSite(newValue.id);
        }
      }}
      ListboxProps={{ style: { maxHeight: "150px" } }}
    />
  );

  return (
    <>
      <TitleBar navigateBack={-1} title={t("gateway.addDeviceToOutlet")} />
      <Grid container>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="title"
            style={{
              marginBottom: "1rem",
              fontWeight: "450",
              color: "#474B4F",
              fontSize: "16px",
              opacity: "80%",
            }}
          >
            {t("tips_gateway.manualRegisterDevice")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: "1rem",
            borderRadius: "8px",
            border: "1px solid #DDDDDD",
          }}
        >
          <Typography
            variant="title"
            style={{ fontSize: "16px", fontWeight: 450, color: "#474B4F" }}
          >
            {t("tips_gateway.powerUpAndSetNetwork")}
          </Typography>
          <Box margin={"10px 0px 0px 15px"}>
            <Typography>{t("tips_gateway.step1EthernetConnection")}</Typography>
            <Typography>
              {t("tips_gateway.step2CommunicationSetting")}
            </Typography>
            <Typography>{t("tips_gateway.step3FindSerialNumber")}</Typography>
            <Typography> {t("tips_gateway.step4FillSerialNumber")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} py={2}>
          <Typography
            variant="title"
            style={{
              margin: "1rem 0rem",
              fontSize: "16px",
              fontWeight: "450",
              color: "#474B4F",
              opacity: "80%",
            }}
          >
            {t("gateway.gateway")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: "#FFFF",
          padding: "1rem",
          borderRadius: "8px",
          border: "1px solid #DDDDDD",
        }}
      >
        <Grid item sm={4} paddingRight={1}>
          <TextField
            error={!CommonUtil.isEmptyString(error.sn)}
            required
            fullWidth
            name="sn"
            placeholder={t("tips_gateway.enterDeviceSerialNumber")}
            helperText={error.sn}
            variant="outlined"
            size="small"
            value={state.sn}
            onChange={(e) => {
              setState({ sn: e.target.value });
              setError({ sn: "" });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        py={2}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginTop: "1rem",
        }}
      >
        <Button
          id="BindDevice1"
          onClick={() => findDevice()}
          variant="contained"
          style={{
            size: "medium",
            background:
              "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
            borderRadius: "8px",
            opacity: 1,
            width: "160px",
            height: "48px",
          }}
        >
          {t("common.add")}
        </Button>
      </Grid>
      <SimpleDailogBox
        open={open}
        onClose={handleClose}
        title={t("gateway.bindDeviceToOutlet")}
      >
        <Grid container>
          <Grid item xs={12} padding={2}>
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                display: "flex",
                padding: 1,
              }}
            >
              {/* {deviceType === "cam" && (
                <img
                  alt="complex"
                  src={DeviceIcon}
                  style={{ height: "60px", width: "60px" }}
                />
              )}
              {deviceType === "acc" && <DeviceIcon style={{ width: "60px" }} />}
              {deviceType === "att" && <DeviceIcon style={{ width: "60px" }} />} */}
              <img
                alt="complex"
                src={DeviceIcon1}
                style={{ height: "60px", width: "60px" }}
              />
              <Box
                style={{
                  margin: "0rem 0.5rem",
                  alignSelf: "center",
                }}
              >
                <Typography>{state.deviceModel}</Typography>
                <Typography variant="fieldLabel">{state.sn}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} px={2}>
            <Typography variant="dashboardDeviceLabel">
              {t("tips_gateway.specifyDeviceForClient")}
            </Typography>
          </Grid>
          <Grid item xs={12} px={2}>
            <Typography variant="menuItem" style={{ color: "#1487CA" }}>
              {t("tips_gateway.syncOutletTimeZone")}
            </Typography>
          </Grid>
          {!CommonUtil.isEmpty(selectedSite) && (
            <Grid item xs={12} px={2} mt={1}>
              <Typography
                style={{
                  color: "#36c96d",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                {" "}
                {timeZone !== undefined &&
                  timeZone !== "" &&
                  `This Device will Sync the same Time Zone ${getTimezoneName(
                    timeZone
                  )} of the Site.`}
              </Typography>
            </Grid>
          )}
          <AddDevice state={state} />
        </Grid>
      </SimpleDailogBox>
    </>
  );
};

export default BindDevice;
