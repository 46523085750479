import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import CustomInput from "../../components/CustomInput";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createRole,
  getMenuItems,
  getMenus,
  getPermissions,
  getPermissionDetails,
} from "../../services/UserManagementService";
import { REACT_AUTHORIZATION_LEVEL } from "../../router/ReactEndPoints";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";

export default function AddAuthorizationLevel() {
  const user = CommonUtil.decodeToken();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [menus, setMenus] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [checkedMenus, setCheckedMenus] = useState({});
  const [checkedMenuItems, setCheckedMenuItems] = useState({});
  const [checkedPermissions, setCheckedPermissions] = useState([]);

  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 100,
  });

  const loadData = (filter) => {
    const roleCode = user?.roleCode?.toUpperCase();
    if ("OWNER" === roleCode || "SUPERADMIN" === roleCode) {
      getPermissions(filter).then((res) => {
        if (res?.data?.code === "LVLI0000" && res?.data?.data) {
          setPermissions(res?.data?.data);
        }
      });
    } else {
      getPermissionDetails(user?.id).then((res) => {
        if (res?.data?.code === "LVLI0000" && res?.data?.data) {
          setPermissions(res.data.data);
        }
      });
    }

    getMenus(filter).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        const menusList = res?.data?.data;
        const updateMenus = menusList.filter(
          (item) => item.name !== "Dashboard"
        );
        setMenus(updateMenus);
      }
    });

    getMenuItems(filter).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setMenuItems(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    loadData(filters);
  }, []);

  const [payload, setPayload] = useState({
    name: "",
    code: "",
    permissionIds: [],
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    permissionIds: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleCheckboxChange = (permissionId) => {
    setSelectedPermissions((prevSelectedPermissions) => {
      if (prevSelectedPermissions.includes(permissionId)) {
        return prevSelectedPermissions.filter((p) => p !== permissionId);
      } else {
        return [...prevSelectedPermissions, permissionId];
      }
    });
  };

  useEffect(() => {
    setPayload({
      ...payload,
      permissionIds: selectedPermissions,
    });
  }, [selectedPermissions]);

  const handleSubmit = () => {
    if (validateForm()) {
      createRole(payload).then((res) => {
        if (res?.data?.data && res?.data?.code === "LVLI0008") {
          enqueueSnackbar(t("tips_authorization.created"), {
            variant: "success",
          });
          navigate(REACT_AUTHORIZATION_LEVEL);
        }
        if (res?.data?.code !== "LVLI0008") {
          enqueueSnackbar(t(res.data.message), { variant: "error" });
          return;
        }
      });
    }
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      enqueueSnackbar(t("tips_authorization.requires_name"), {
        variant: "error",
      });
      return;
    }
    if (CommonUtil.isEmpty(payload.permissionIds)) {
      enqueueSnackbar(t("tips_authorization.requires_permission"), {
        variant: "error",
      });
      return;
    }
    return true;
  };

  const label = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <Typography
          sx={{
            textAlign: "left",
            font: "normal normal medium 16px/19px Roboto",
            fontWeight: "bold",
            letterSpacing: "0px",
            opacity: "0.8",
          }}
        >
          {CommonUtil.formatLongText(label)}
        </Typography>
      </Tooltip>
    );
  };

  const childLabel = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <Typography
          style={{
            font: "normal normal normal 16px/21px",
            color: "#474B4F",
            opacity: 0.8,
          }}
        >
          {CommonUtil.formatLongText(label)}
        </Typography>
      </Tooltip>
    );
  };

  const subChildLabel = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <Typography
          style={{
            font: "normal normal normal 16px/21px",
            color: "#474B4F",
            opacity: 0.8,
          }}
        >
          {CommonUtil.formatLongText(t(label))}
        </Typography>
      </Tooltip>
    );
  };

  useEffect(() => {
    setSelectedPermissions(
      Object.keys(checkedPermissions).filter(
        (permissionId) => checkedPermissions[permissionId]
      )
    );
  }, [checkedPermissions]);

  const handleMenuCheckboxChange = (menuId) => {
    const isChecked = !checkedMenus[menuId];
    const updatedCheckedMenus = { ...checkedMenus, [menuId]: isChecked };
    setCheckedMenus(updatedCheckedMenus);

    // Check/uncheck all menu items and permissions for the menu
    const menuItemsForMenu = menuItems.filter(
      (item) => item.menu.id === menuId
    );
    const permissionsForMenu = permissions.filter((perm) =>
      menuItemsForMenu.some((item) => item.id === perm.menuItem.id)
    );
    const updatedCheckedMenuItems = { ...checkedMenuItems };
    const updatedCheckedPermissions = { ...checkedPermissions };

    menuItemsForMenu.forEach((menuItem) => {
      updatedCheckedMenuItems[menuItem.id] = isChecked;
    });

    permissionsForMenu.forEach((permission) => {
      updatedCheckedPermissions[permission.id] = isChecked;
    });

    setCheckedMenuItems(updatedCheckedMenuItems);
    setCheckedPermissions(updatedCheckedPermissions);
  };

  const handleMenuItemCheckboxChange = (menuItemId) => {
    const isChecked = !checkedMenuItems[menuItemId];
    const updatedCheckedMenuItems = {
      ...checkedMenuItems,
      [menuItemId]: isChecked,
    };
    setCheckedMenuItems(updatedCheckedMenuItems);

    // Check if any menu items are checked for this menu
    const menuItem = menuItems.find((item) => item.id === menuItemId);
    const menuId = menuItem.menu.id;
    const menuItemsForMenu = menuItems.filter(
      (item) => item.menu.id === menuId
    );
    const anyMenuItemsChecked = menuItemsForMenu.some(
      (item) => updatedCheckedMenuItems[item.id]
    );

    // If no menu items are checked, uncheck the parent menu
    if (!anyMenuItemsChecked) {
      const updatedCheckedMenus = { ...checkedMenus };
      updatedCheckedMenus[menuId] = false;
      setCheckedMenus(updatedCheckedMenus);
    } else {
      // Check the parent menu if it wasn't already checked
      const updatedCheckedMenus = { ...checkedMenus };
      if (!updatedCheckedMenus[menuId]) {
        updatedCheckedMenus[menuId] = isChecked;
        setCheckedMenus(updatedCheckedMenus);
      }
    }

    // Check/uncheck all permissions for the menu item
    const permissionsForMenuItem = permissions.filter(
      (perm) => perm.menuItem.id === menuItemId
    );
    const updatedCheckedPermissions = { ...checkedPermissions };

    permissionsForMenuItem.forEach((permission) => {
      updatedCheckedPermissions[permission.id] = isChecked;
    });

    setCheckedPermissions(updatedCheckedPermissions);
  };

  const handlePermissionCheckboxChange = (permissionId) => {
    const isChecked = !checkedPermissions[permissionId];
    const updatedCheckedPermissions = {
      ...checkedPermissions,
      [permissionId]: isChecked,
    };
    setCheckedPermissions(updatedCheckedPermissions);

    // Find the parent menu item and check if any permissions are checked for it
    const permission = permissions.find((perm) => perm.id === permissionId);
    const menuItemId = permission.menuItem.id;
    const permissionsForMenuItem = permissions.filter(
      (perm) => perm.menuItem.id === menuItemId
    );
    const anyPermissionsCheckedForMenuItem = permissionsForMenuItem.some(
      (perm) => updatedCheckedPermissions[perm.id]
    );

    // Update the parent menu item based on permissions
    const updatedCheckedMenuItems = { ...checkedMenuItems };
    updatedCheckedMenuItems[menuItemId] = anyPermissionsCheckedForMenuItem;
    setCheckedMenuItems(updatedCheckedMenuItems);

    // Find the parent menu and check if any menu items are checked
    const menuItem = menuItems.find((item) => item.id === menuItemId);
    const menuId = menuItem.menu.id;
    const menuItemsForMenu = menuItems.filter(
      (item) => item.menu.id === menuId
    );
    const anyMenuItemsCheckedForMenu = menuItemsForMenu.some(
      (item) => updatedCheckedMenuItems[item.id]
    );

    // Update the parent menu based on menu items
    const updatedCheckedMenus = { ...checkedMenus };
    updatedCheckedMenus[menuId] = anyMenuItemsCheckedForMenu;
    setCheckedMenus(updatedCheckedMenus);
  };

  return (
    <RightViewLayout
      title={t("authorization.add")}
      navigateBack={REACT_AUTHORIZATION_LEVEL}
    >
      <Box pr={2}>
        <Grid>
          <Card
            elevation={0}
            sx={{
              background: " #FFFFFF 0% 0% no-repeat padding-box",
              fontWeight: "bold",
              opacity: 1,
              borderRadius: "8px",
            }}
          >
            <CardContent style={{ width: "30%" }}>
              <CustomInput
                resetError={() => setError({ ...error, name: "" })}
                required
                error={error.name}
                label={t("authorization.name")}
                size="small"
                name="name"
                value={payload.name}
                helperText={error.name}
                validation="alpha-numeric-ch-th"
                handleChange={handleChange}
                inputProps={{
                  maxLength: 50,
                }}
                labelSize="20px"
                placeholder={t("tips_authorization.name")}
              />
            </CardContent>
          </Card>
        </Grid>
        <Typography
          pt={1}
          pl={0}
          sx={{
            textAlign: "left",
            font: "normal normal medium 16px/22px Roboto",
            fontWeight: "bold",
            letterSpacing: "0px",
            opacity: "0.8",
          }}
        >
          {t("authorization.list")}
        </Typography>
        {menus.map(
          (menu) =>
            permissions?.filter((x) => x.menu.name === menu.name).length >
              0 && (
              <Grid key={menu.id} pt={1.5}>
                <Card
                  elevation={0}
                  sx={{
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    fontWeight: "bold",
                    opacity: 1,
                    borderRadius: "8px",
                  }}
                >
                  <CardContent>
                    <FormControlLabel
                      key={menu.id}
                      control={
                        <Checkbox
                          id={`permission-checkbox-${menu.id}`}
                          checked={checkedMenus[menu.id] || false}
                          onChange={() => handleMenuCheckboxChange(menu.id)}
                        />
                      }
                      label={label(t(menu.name))}
                    />
                    <Card elevation={0} style={{ width: "100%" }}>
                      <CardContent style={{ paddingTop: 1, paddingBottom: 1 }}>
                        {menuItems
                          .filter(
                            (menuItem) => menuItem.menu.name === menu.name
                          )
                          .map((menuItem) => (
                            <Grid key={menuItem.id}>
                              <FormControlLabel
                                key={menuItem.id}
                                control={
                                  <Checkbox
                                    id={`permission-checkbox-${menuItem.id}`}
                                    checked={
                                      checkedMenuItems[menuItem.id] || false
                                    }
                                    onChange={() =>
                                      handleMenuItemCheckboxChange(menuItem.id)
                                    }
                                  />
                                }
                                label={childLabel(t(menuItem.name))}
                              />
                              <Grid key={menuItem.id} pl={2}>
                                <Card elevation={0} style={{ width: "100%" }}>
                                  <CardContent
                                    style={{ paddingTop: 1, paddingBottom: 1 }}
                                  >
                                    <FormGroup row key={menuItem.id}>
                                      {permissions
                                        .filter(
                                          (permission) =>
                                            permission.menuItem.name ===
                                            menuItem.name
                                        )
                                        .map((permission) => (
                                          <Grid
                                            lg={2.4}
                                            sx={{
                                              textAlign: "left",
                                              font: "normal normal medium 16px/21px Roboto",
                                              letterSpacing: "0px",
                                              opacity: "0.8",
                                              color: "#474B4F",
                                            }}
                                            key={permission.id}
                                          >
                                            <FormControlLabel
                                              key={permission.id}
                                              control={
                                                <Checkbox
                                                  id={`permission-checkbox-${permission.id}`}
                                                  checked={
                                                    checkedPermissions[
                                                      permission.id
                                                    ] || false
                                                  }
                                                  onChange={() =>
                                                    handlePermissionCheckboxChange(
                                                      permission.id
                                                    )
                                                  }
                                                />
                                              }
                                              label={subChildLabel(
                                                t(permission.name)
                                              )}
                                            />
                                          </Grid>
                                        ))}
                                    </FormGroup>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          ))}
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
        <Grid container spacing={2} pb={2} paddingBottom={"15px"}>
          <Grid item xs={12}>
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Box item pl={2}>
                <Button
                  id="AddAuthorizationLevel-button-01"
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  onClick={handleSubmit}
                  style={{
                    size: "medium",
                    borderRadius: "8px",
                    opacity: 1,
                    background:
                      "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  {t("common.save")}
                </Button>
              </Box>
              <Box item>
                <Button
                  id="AddAuthorizationLevel-button-02"
                  className="text-transform-none"
                  variant="outlined"
                  onClick={() => navigate(REACT_AUTHORIZATION_LEVEL)}
                  size="large"
                >
                  {t("common.back")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </RightViewLayout>
  );
}
