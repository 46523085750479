import React, { useEffect, useState, useRef } from "react";
import DataTable from "../../components/DataTable";
import { useTranslation } from "react-i18next";
import ListLayout from "../../components/ListLayout";
import {
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router";
import IconHandaler from "../../components/IconHandaler";
import PreView from "../editor/PreView";
import {
  deleteTemplate,
  getDefaultTemplateList,
  getDownloadUrl,
  getTemplateList,
} from "../../services/TemplateService";
import {
  REACT_TEMPLATE_ADD,
  REACT_TEMPLATE_EDIT,
  REACT_TEMPLATE_VIEW,
} from "../../router/ReactEndPoints";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
export default function Template() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadUrls, setDownloadUrls] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  const retailClient = localStorage.getItem("RETAIL_CLIENT");

  const [preViewOpen, setPreViewOpen] = useState(false);
  const [preViewItem, setPreViewItem] = useState(null);

  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedDelete = true;
  let isAllowedEdit = true;
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
    name: "",
    type: "",
    screenResolution: "",
  });

  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
    name: "",
    type: "",
    screenResolution: "",
  };

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
      operator: "AND",
    });
  };

  const [downloadUrlObjData, setDownloadUrlObjData] = useState("");

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    getDefaultTemplateList(filters).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        let data = res.data.data.templateData;
        setRecords(data.template);
        setTotalRecords(data.totalCount);
        setPage(data.currentPage);
        setRowsPerPage(data.totalPages);
        setDownloadUrls(res?.data?.data?.downloadUrlData);
        setDownloadUrlObjData(res?.data?.data?.downloadUrlData);
      } else if (res?.data?.code === "LVLE0054") {
        setRecords([]);
      }
    });
  };

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      screenResolution: resolution,
      type: templateValue,
      pageSize: filters.pageSize,
      pageNumber: e,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      screenResolution: resolution,
      type: templateValue,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      navigate(REACT_TEMPLATE_VIEW + data);
    }
    if (action === "Edit") {
      navigate(REACT_TEMPLATE_EDIT + data);
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  const handleDelete = (id) => {
    deleteTemplate(id).then((response) => {
      if (response?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("tips_Template.deleted"), {
          variant: "success",
        });
        setConfirm(false);
        loadData();
      } else if (response?.data?.code === "EPTE0037") {
        enqueueSnackbar(t("tips_Template.can_not_deteled"), {
          variant: "error",
        });
      } else if (response?.data?.code === "LVLE0000") {
        enqueueSnackbar(t("tips_Template.not_deteled"), {
          variant: "error",
        });
      } else
        enqueueSnackbar(response?.data?.message, {
          variant: "error",
        });
      setConfirm(false);
    });
  };
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const popUp = (urls) => {
    setOpen(true);
    setUrl(urls);
    downloadAndDisplayFile(urls);
  };

  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");

  const downloadAndDisplayFile = async (urls) => {
    try {
      const response = await fetch(urls);
      const htmlContent = await response.text();
      setHtmlContent(htmlContent);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const getPicPreview = (key) => {
    getDownloadUrl(key.row.templateObjectKey).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setHtmlContent("");
        let templateObj = [];
        records.map((e) => {
          let templateData = e;
          if (e.templateObjectKey === res.data.data.objectKey) {
            templateData.preSignedUrl = res.data.data.downloadUrl;
          }
          templateObj.push(templateData);
        });
        setRecords(templateObj);
        popUp(res.data.data.downloadUrl);
      }
    });
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddTemplate");
    isAllowedView = permission && permission.includes("ViewTemplate");
    isAllowedDelete = permission && permission.includes("DeleteTemplate");
  }

  const columns = [
    {
      field: "name",
      headerName: t("template.name"),
      flex: 1,
      renderCell: (e) => e.row.name,
    },
    {
      field: "screenResolution",
      headerName: t("template.resolution"),
      flex: 1,
      renderCell: (e) => e.row.screenResolution,
    },
    {
      field: "screenOriantation",
      headerName: t("template.screen_direction"),
      flex: 1,
      renderCell: (e) => e.row.screenOriantation,
    },
    {
      field: "type",
      headerName: t("template.type"),
      flex: 1,
      renderCell: (e) => e.row.type,
    },
    {
      field: "templateType",
      headerName: t("common.type"),
      flex: 1,
      renderCell: (e) => e.row.templateType,
    },

    {
      field: "templatePreview",
      headerName: t("template.preview"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={t("common.preview")} arrow>
            <Button
              id="temppreview"
              sx={{ px: 0 }}
              onClick={() => {
                let templateJson = e.row.templateJson;
                if (templateJson) {
                  setPreViewOpen(true);
                  setPreViewItem(e.row);
                } else {
                  enqueueSnackbar("Unbound templates", {
                    variant: "info",
                  });
                  getPicPreview(e);
                }
              }}
            >
              {t("common.preview")}
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      headerName: t("common.actions"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedView && (
            <Tooltip title={t("common.view")} arrow>
              <VisibilityIcon
                id="viewtemplatelist"
                onClick={() => handleActions("View", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {e.row.templateType !== "System Default" && isAllowedEdit && (
            <Tooltip title={t("common.edit")} sx={{ marginLeft: 1 }}>
              <EditIcon
                onClick={() => handleActions("Edit", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  paddingLeft: "5px",
                  height: "17px",
                  width: "20px",
                }}
              />
            </Tooltip>
          )}
          {roleName === "OWNER" && retailClient === "" && (
            <Tooltip title={t("common.edit")} sx={{ marginLeft: 1 }}>
              <EditIcon
                onClick={() => handleActions("Edit", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  paddingLeft: "5px",
                  height: "17px",
                  width: "20px",
                }}
              />
            </Tooltip>
          )}
          {e.row.templateType !== "System Default" && isAllowedDelete && (
            <Tooltip title={t("common.delete")} sx={{ marginLeft: 1 }}>
              <DeleteIcon
                id="deletetemplist"
                onClick={() => handleActions("Delete", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {roleName === "OWNER" && retailClient === "" && (
            <Tooltip title={t("common.delete")} sx={{ marginLeft: 1 }}>
              <DeleteIcon
                id="deletetemplist"
                onClick={() => handleActions("Delete", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
        </IconHandaler>
      ),
    },
  ];

  const toolbarProps = {
    add: isAllowedCreate,
    filter: false,
    refresh: true,

    onAdd: (data) => {
      navigate(REACT_TEMPLATE_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
      window.location.reload();
    },
  };

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const resolutionOptions = [
    "200*200",
    "202*104",
    "250*128",
    "296*152",
    "320*240",
    "400*300",
    "648*480",
  ];

  let templateTypeOptions = [
    "General Use",
    "Discount",
    "By Unit",
    "By Value",
    "Special Promotion",
  ];
  /* if(localStorage.getItem("ROLE_NAME") === "OWNER" && localStorage.getItem("RETAIL_CLIENT") === ""){
    templateTypeOptions = ["Default"];
  } */

  const [resolution, setResolution] = useState("");
  const [templateValue, setTemplateValue] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchClientProps = () => {
    return (
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-end"}
        pr={1.4}
        xs={12}
        md={4}
        pt={2}
      >
        <Autocomplete
          options={searchSuggestions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("tips.search_by_name")}
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                style: {
                  height: 50,
                  width: "400px",
                  background: "#fff",
                  color: "#474B4F",
                  opacity: "0.6",
                  boxShadow: "0px 1px 3px #0000001A",
                  borderRadius: "8px",
                  border: "0px !important",
                  padding: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton id="retailClint" onClick={handleSearchClick}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#D1D1D1",
                },
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const handleResolutionChange = () => {
    setFilters({
      ...defaultFilters,
      screenResolution: resolution,
      type: templateValue,
      operator: "AND",
    });
  };

  const handleReset = () => {
    setResolution("");
    setTemplateValue("");
    setFilters({ ...defaultFilters });
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Typography variant="title" sx={{ fontSize: "20px" }}>
          {t("menu.template_list")}
        </Typography>
      </Grid>

      <Grid
        container
        xs={12}
        spacing={1}
        m={0}
        style={{
          width: "1530px",
          height: "80px",
          background: "#FFFFFF",
          borderRadius: "8px",
        }}
      >
        <Grid item xs={8} sm={6} md={7} lg={8} container alignItems="center">
          <Grid item xs={6} sm={6} md={6} lg={4} pr={2}>
            <Typography
              sx={{ fontSize: "12px", color: "#474B4F", opacity: "0.8" }}
            >
              {t("template.resolution")}
            </Typography>
            <Autocomplete
              noOptionsText={t("tips.no_options")}
              options={resolutionOptions}
              value={resolution}
              onChange={(e, v) => {
                setResolution(v);
              }}
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={4}>
            <Typography
              sx={{ fontSize: "12px", color: "#474B4F", opacity: "0.8" }}
            >
              {t("template.type")}
            </Typography>
            <Autocomplete
              noOptionsText={t("tips.no_options")}
              options={templateTypeOptions}
              value={templateValue}
              onChange={(e, v) => {
                setTemplateValue(v);
              }}
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          md={5}
          lg={4}
          pb={1}
          container
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            id="index2"
            className="text-transform-none"
            variant="outlined"
            size="medium"
            style={{ marginRight: "10px" }}
            onClick={handleReset}
          >
            {" "}
            {t("common.reset")}{" "}
          </Button>
          <Button
            id="index1"
            variant="outlined"
            size="medium"
            className="text-transform-none"
            onClick={handleResolutionChange}
            style={{
              background: "linear-gradient(45deg, #1487CA, #78BC27)",
              marginRight: "10px",
              color: "#FFFF",
            }}
          >
            {t("common.find")}
          </Button>
        </Grid>
      </Grid>

      {/* </Grid> */}

      <ListLayout
        id="templlistback"
        navigateBack={false}
        title={t(" ")}
        toolbarProps={toolbarProps}
      >
        <DataTable
          columns={columns}
          rows={records}
          page={filters.pageNumber}
          totalRecords={totalRecords}
          rowsPerPage={filters.pageSize}
          onSelection={() => console.log()}
          onPageChange={(pn) => handlePageChange(pn)}
          onPageSizeChange={(ps) => handlePageSize(ps)}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          style={{ backdropFilter: "blur(5px)" }}
        >
          <DialogContent>
            <ImagePopUp />
          </DialogContent>
        </Dialog>

        <Dialog
          open={preViewOpen}
          onClose={() => {
            setPreViewItem(null);
            setPreViewOpen(false);
          }}
          maxWidth={"md"}
          style={{ backdropFilter: "blur(5px)" }}
        >
          {preViewItem?.templateJson && preViewOpen && (
            <PreView
              layoutJSON={JSON.parse(preViewItem.templateJson)}
            ></PreView>
          )}
        </Dialog>

        <Dialog open={confirm} onClose={handleClose} maxWidth="md">
          <DialogTitle>
            {" "}
            <Typography variant="subtitle2">
              {t("tips.selected_detele_record")}
            </Typography>
          </DialogTitle>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            padding={"10px"}
            justifyContent={"center"}
          >
            <Box display="flex" justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
                onClick={handleClose}
                sx={{ marginRight: 2 }}
              >
                {" "}
                {t("common.cancel")}{" "}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                  color: "#FFFFFF",
                  fontSize: "normal normal normal 14px / 22px Roboto",
                  width: "80px",
                }}
                onClick={() => handleDelete(id)}
              >
                {" "}
                {t("common.delete")}{" "}
              </Button>
            </Box>
          </Grid>
        </Dialog>
      </ListLayout>
    </>
  );
}
