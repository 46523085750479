import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import CustomInput from "../../components/CustomInput";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getMenuItems,
  getMenus,
  getPermissions,
  getRoleById,
} from "../../services/UserManagementService";
import { REACT_AUTHORIZATION_LEVEL } from "../../router/ReactEndPoints";
import CommonUtil from "../../util/CommonUtils";

export default function ViewAuthorizationLevel() {
  const location = useLocation();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [role, setRole] = useState({});
  const [menus, setMenus] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  // State to track the checked state of menus, menu items, and permissions
  const [checkedMenus, setCheckedMenus] = useState({});
  const [checkedMenuItems, setCheckedMenuItems] = useState({});
  const [checkedPermissions, setCheckedPermissions] = useState([]);

  const [payload, setPayload] = useState({
    id: "",
    name: "",
    code: "",
    permissionIds: [],
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    permissionIds: "",
  });

  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getRoleById(location?.state?.id).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setRole(res?.data?.data);
        setPayload({
          ...payload,
          ...res?.data?.data,
        });
      }
    });
  }, []);

  console.log(role);

  const loadData = (filter) => {
    getPermissions(filter).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setPermissions(res?.data?.data);
      }
    });

    getMenus(filter).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        const menusList = res?.data?.data;
        const updateMenus = menusList.filter(
          (item) => item.name !== "Dashboard"
        );
        setMenus(updateMenus);
      }
    });

    getMenuItems(filter).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setMenuItems(res?.data?.data);
      }
    });
  };

  const [permissionIds, setPermissionIds] = useState([]);
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    setPermission(role.permissions);
  }, [role]);

  useEffect(() => {
    if (permission) {
      const ids = permission.map((permission) => permission?.id);
      setPermissionIds(ids);
      setSelectedPermissions([...selectedPermissions, ...ids]);

      const checkedPermissionsObj = {};
      ids.forEach((id) => {
        checkedPermissionsObj[id] = true;
      });
      setCheckedPermissions(checkedPermissionsObj);

      const menuItemIds = permission.map(
        (permission) => permission?.menuItem?.id
      );
      const checkedMenuItemObj = {};
      menuItemIds.forEach((id) => {
        checkedMenuItemObj[id] = true;
      });
      setCheckedMenuItems(checkedMenuItemObj);

      const menuIds = permission.map(
        (permission) => permission?.menuItem?.menu?.id
      );
      const checkedMenuObj = {};
      menuIds.forEach((id) => {
        checkedMenuObj[id] = true;
      });
      setCheckedMenus(checkedMenuObj);
    }
  }, [permission]);

  useEffect(() => {
    loadData(filters);
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  useEffect(() => {
    setPayload({
      ...payload,
      permissionIds: selectedPermissions,
    });
  }, [selectedPermissions]);

  const label = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <Typography
          sx={{
            textAlign: "left",
            font: "normal normal medium 16px/19px Roboto",
            fontWeight: "bold",
            letterSpacing: "0px",
            opacity: "0.8",
          }}
        >
          {CommonUtil.formatLongText(label)}
        </Typography>
      </Tooltip>
    );
  };

  const childLabel = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <Typography
          style={{
            font: "normal normal normal 16px/21px",
            color: "#474B4F",
            opacity: 0.8,
          }}
        >
          {CommonUtil.formatLongText(t(label))}
        </Typography>
      </Tooltip>
    );
  };

  const subChildLabel = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <Typography
          style={{
            font: "normal normal normal 16px/21px",
            color: "#474B4F",
            opacity: 0.8,
          }}
        >
          {t(label)}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <RightViewLayout
      title={t("authorization.view")}
      navigateBack={REACT_AUTHORIZATION_LEVEL}
    >
      <Box pr={2}>
        <Grid>
          <Card
            elevation={0}
            sx={{
              background: " #FFFFFF 0% 0% no-repeat padding-box",
              fontWeight: "bold",
              opacity: 1,
              borderRadius: "8px",
            }}
          >
            <CardContent style={{ width: "30%" }}>
              <CustomInput
                resetError={() => setError({ ...error, name: "" })}
                required
                error={error.name}
                label={t("authorization.name")}
                size="small"
                name="name"
                value={payload.name}
                helperText={error.name}
                validation="alpha-numeric"
                handleChange={handleChange}
                disabled={true}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Typography
          pt={1}
          pl={0}
          sx={{
            textAlign: "left",
            font: "normal normal medium 16px/22px Roboto",
            fontWeight: "bold",
            letterSpacing: "0px",
            opacity: "0.8",
          }}
        >
          {t("authorization.list")}
        </Typography>
        {menus.map((menu) => (
          <Grid key={menu.id} pt={1.5}>
            <Card
              elevation={0}
              sx={{
                background: " #FFFFFF 0% 0% no-repeat padding-box",
                fontWeight: "bold",
                opacity: 1,
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <FormControlLabel
                  key={menu.id}
                  control={
                    <Checkbox
                      id={`permission-checkbox-${menu.id}`}
                      checked={checkedMenus[menu.id] || false}
                    />
                  }
                  label={label(menu.name)}
                />
                <Card elevation={0} style={{ width: "100%" }}>
                  <CardContent style={{ paddingTop: 1, paddingBottom: 1 }}>
                    {menuItems
                      .filter((menuItem) => menuItem.menu.name === menu.name)
                      .map((menuItem) => (
                        <Grid key={menuItem.id}>
                          <FormControlLabel
                            key={menuItem.id}
                            control={
                              <Checkbox
                                id={`permission-checkbox-${menuItem.id}`}
                                checked={checkedMenuItems[menuItem.id] || false}
                              />
                            }
                            label={childLabel(menuItem.name)}
                          />
                          <Grid key={menuItem.id} pl={2}>
                            <Card elevation={0} style={{ width: "100%" }}>
                              <CardContent
                                style={{ paddingTop: 1, paddingBottom: 1 }}
                              >
                                <FormGroup row key={menuItem.id}>
                                  {permissions
                                    .filter(
                                      (permission) =>
                                        permission.menuItem.name ===
                                        menuItem.name
                                    )
                                    .map((permission) => (
                                      <Grid
                                        lg={2.4}
                                        sx={{
                                          textAlign: "left",
                                          font: "normal normal medium 16px/21px Roboto",
                                          letterSpacing: "0px",
                                          opacity: "0.8",
                                          color: "#474B4F",
                                        }}
                                        key={permission.id}
                                      >
                                        <FormControlLabel
                                          key={permission.id}
                                          control={
                                            <Checkbox
                                              id={`permission-checkbox-${permission.id}`}
                                              checked={
                                                checkedPermissions[
                                                  permission.id
                                                ] || false
                                              }
                                            />
                                          }
                                          label={subChildLabel(permission.name)}
                                        />
                                      </Grid>
                                    ))}
                                </FormGroup>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      ))}
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Box>
    </RightViewLayout>
  );
}
