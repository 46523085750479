export const promotionType = [
  { id: 1, value: "Discount" },
  { id: 2, value: "By Unit" },
  { id: 3, value: "By Value" },
  { id: 4, value: "Special Promotion" },
];

export const productElement = [
  { id: 1, value: "Brand" },
  { id: 2, value: "Manufacturer" },
  { id: 3, value: "Category1" },
  { id: 4, value: "Category2" },
];

export const scheduleModes = [
  { id: 1, value: "Does not repeat" },
  { id: 2, value: "Daily" },
  { id: 3, value: "Weekly" },
  { id: 4, value: "Monthly" },
  { id: 5, value: "Annually" },
];

export const weekdays = [
  { id: 1, value: "Sunday" },
  { id: 2, value: "Monday" },
  { id: 3, value: "Tuesday" },
  { id: 4, value: "Wednesday" },
  { id: 5, value: "Thursday" },
  { id: 6, value: "Friday" },
  { id: 7, value: "Saturday" },
];

export const allMonth = [
  { id: 1, value: "January" },
  { id: 2, value: "February" },
  { id: 3, value: "March" },
  { id: 4, value: "April" },
  { id: 5, value: "May" },
  { id: 6, value: "June" },
  { id: 7, value: "July" },
  { id: 8, value: "August" },
  { id: 9, value: "September" },
  { id: 10, value: "October" },
  { id: 11, value: "November" },
  { id: 12, value: "December" },
];

export const monthMapping = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};
