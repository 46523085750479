import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Grid,
  DialogTitle,
  DialogContent,
  Dialog,
  Tooltip,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { onLineOutletContext } from "../menu/AreaOutlet";
import { timeZoneList } from "@/constants/TimeZone";
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";
import { getOnlineOutlet } from "../js/interface";
import { useTranslation } from "react-i18next";
import CommonUtil from "@/util/CommonUtils";
import DataTable from "./DataTable";
import Serach from "./Serach";
function OutletDialog(props) {
  const {
    open,
    setOpen,
    setData,
    data,
    setDataZoonId,
    dataZoonId,
    addFormik,
    totalRecords,
    setTotalRecords,
  } = props;
  const { t } = useTranslation();
  const { deviceOnlineOutlet, setDeviceOnlineOutlet } =
    useContext(onLineOutletContext);

  const [selectRow, setSelectRow] = useState([]);
  const [outletName, SetOutletName] = useState("");
  const [allSelectedRows, setAllSelectedRows] = useState([]); //  左边表格选中的数据
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 5,
  });

  const columns = [
    {
      field: "name",
      headerName: `${t("outlet.name")}`,
      flex: 1,
      headerAlign: "center", // 使列标题居中
      align: "center", // 使数据单元格内容居中
      renderCell: (e) => (
        <>
          <Tooltip
            id="Product-button-06"
            title={e.row.name}
            arrow
            placement="bottom"
          >
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        </>
      ),
    },

    {
      field: "code",
      headerName: t("Outlet Code"),
      flex: 1,
      headerAlign: "center", // 使列标题居中
      align: "center", // 使数据单元格内容居中
      renderCell: (e) => (
        <Tooltip
          id="Product-button-11"
          title={e.row.code}
          arrow
          placement="bottom"
        >
          <span>{CommonUtil.formatLongText(e.row.code)}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: t("Address"),
      flex: 1,
      headerAlign: "center", // 使列标题居中
      align: "center", // 使数据单元格内容居中
      renderCell: (e) => (
        <Tooltip
          id="Product-button-09"
          title={e.row.address}
          arrow
          placement="bottom"
        >
          <span>{CommonUtil.formatLongText(e.row.address)}</span>
        </Tooltip>
      ),
    },
    {
      field: "timeZone",
      headerName: `${t("Time Zone")}`,
      flex: 1,
      headerAlign: "center", // 使列标题居中
      align: "center", // 使数据单元格内容居中
      renderCell: (e) => (
        <Tooltip
          id="Product-button-22"
          title={e.row.timeZone}
          arrow
          placement="bottom"
        >
          <span>{CommonUtil.formatLongText(e.row.timeZone)}</span>
        </Tooltip>
      ),
    },
  ];

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...filters,
      pageNumber: filters.pageNumber,
      pageSize: e,
    });
  };

  useEffect(() => {
    let res = timeZoneList.find(
      (item) => item.value === addFormik.values.zoneId
    );

    setDataZoonId(res);
  }, [addFormik.values.zoneId]);

  const handlerSearch = () => {
    let params = {
      ...filters,
      name: outletName,
    };

    getOnlineOutlet(
      params,
      setTotalRecords,
      setDeviceOnlineOutlet,
      dataZoonId?.value
    );
  };

  const removeDuplicate = (arr) => {
    let key = [];
    let temp = arr.filter((item, index) => {
      let id = Object.keys(item)[0];
      if (key.indexOf(id) < 0) {
        key.push(id);
        return true;
      } else {
        return false;
      }
    });
    return temp;
  };

  const handleSelection = (ids) => {
    const updatedSelectedRows = [];

    let pageIds = deviceOnlineOutlet?.map((item) => {
      return item.id;
    });
    let newTemp = allSelectedRows.filter((item) => {
      let id = Object.keys(item)[0];
      if (pageIds.indexOf(id) < 0) {
        return true;
      } else {
        return false;
      }
    });
    ids.forEach((id) => {
      const record = deviceOnlineOutlet?.find((row) => row.id === id);
      if (record) {
        updatedSelectedRows.push({ [id]: record });
      }
    });

    let temp = removeDuplicate([...newTemp, ...updatedSelectedRows]);

    setAllSelectedRows(temp);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setSelectRow(data);
    setAllSelectedRows(
      data.map((x) => {
        return { [x.id]: x };
      })
    );
  }, [data, open]);

  useEffect(() => {
    const selectedValues = allSelectedRows.flatMap((obj) => Object.values(obj));

    setSelectRow(selectedValues);
  }, [allSelectedRows, deviceOnlineOutlet]);

  const handlerClear = () => {
    SetOutletName("");
    let params = {
      ...filters,
      name: "",
    };

    getOnlineOutlet(
      params,
      setTotalRecords,
      setDeviceOnlineOutlet,
      dataZoonId?.value
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        position={"relative"}
        fullWidth
        sx={{
          "& .MuiDialogContent-root": {
            padding: 0,
          },
          "& .MuiDialog-paperFullWidth": {
            maxWidth: "1300px",
          },
        }}
      >
        <DialogTitle position={"absolute"}>{"OutLet Name"}</DialogTitle>
        <DialogContent bgcolor={"#f4f4f4"}>
          <Grid container>
            <Grid item xs={6} sx={{ bgcolor: "#f4f4f4", p: 2 }}>
              <Grid mb={5} mt={8}>
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  container
                >
                  <Serach
                    value={outletName}
                    onChange={(e) => {
                      SetOutletName(e.target.value);
                    }}
                    dataZoonId={dataZoonId}
                    onClick={handlerSearch}
                    setDataZoonId={setDataZoonId}
                    handlerClear={handlerClear}
                  ></Serach>

                  <div
                    style={{
                      width: "300px",
                    }}
                  >
                    <Autocomplete
                      options={timeZoneList}
                      value={dataZoonId}
                      defaultValue={timeZoneList[0]}
                      onChange={(event, newValue) => {
                        setDataZoonId(newValue);
                        addFormik.setFieldValue("zoneId", newValue?.value);
                      }}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      disableClearable
                    />
                  </div>
                </Grid>

                <Grid mt={2} maxWidth={"650px"}>
                  <DataTable
                    columns={columns}
                    rows={deviceOnlineOutlet}
                    getRowId={(item) => item.id}
                    page={filters.pageNumber}
                    totalRecords={totalRecords}
                    rowsPerPage={filters.pageSize}
                    onSelection={(id) => handleSelection(id)}
                    rowSelectionModel={allSelectedRows?.map((item) => {
                      return Object.keys(item)[0];
                    })}
                    onPageChange={(pn) => handlePageChange(pn)}
                    onPageSizeChange={(ps) => handlePageSize(ps)}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ bgcolor: "#ffffff", p: 2 }}>
              <Typography variant="h6">{`outlets has been selected`}</Typography>

              <Grid mt={4}>
                <div style={{ maxHeight: "475px" }}>
                  <DataGrid
                    columns={columns}
                    rows={selectRow}
                    getRowId={(item) => item.id}
                    hideFooter="true"
                    loading={false}
                    rowSelectionModel={selectRow?.map((item) => item?.id)}
                    onRowSelectionModelChange={(selectedIds) => {
                      const selectedRows = allSelectedRows?.filter((row) => {
                        return selectedIds?.includes(Object.values(row)[0].id);
                      });
                      setAllSelectedRows(selectedRows);
                      const selectedValues = selectedRows.flatMap((obj) =>
                        Object.values(obj)
                      );

                      setSelectRow(selectedValues);
                    }}
                    hideFooterSelectedRowCount={true}
                    checkboxSelection
                    pagination={false}
                  />
                </div>
              </Grid>

              <Grid
                container
                spacing={2}
                mt={2}
                display={"flex"}
                flexDirection={"row-reverse"}
              >
                <Box item pl={2}>
                  <Button
                    id="AddProduct-button-01"
                    variant="contained"
                    size="large"
                    className="text-transform-none"
                    onClick={() => {
                      setOpen(false);
                      let id = selectRow.map((item) => item.id);
                      setData(selectRow);
                      props.addFormik.setFieldValue("outletIds", id);
                    }}
                    style={{
                      size: "medium",
                      borderRadius: "8px",
                      opacity: 1,
                      background:
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    {t("common.save")}
                  </Button>
                </Box>
                <Box item>
                  <Button
                    id="AddProduct-button-02"
                    className="text-transform-none"
                    variant="outlined"
                    onClick={() => {
                      setOpen(false);
                    }}
                    size="large"
                  >
                    {t("common.cancel")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default OutletDialog;
