import api from "../configurations/http-common"

export const createZone = async (payload) => {
    return api
    .securedAxios()
    .post("web/city_zone" ,payload);
}
export const getAllCityZones = async () => {
    return api
    .securedAxios()
    .get("web/city_zones");
}

export const filterCityZone = async (pageNumber, pageSize) => {
    return api
    .securedAxios()
    .get("web/city_zones/list" + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize);
}

export const getZoneById = async (id) => {
    return api
    .securedAxios()
    .get("web/city_zone" + "?id=" + id);
}

export const editCityZone = async (payload) => {
    return api 
    .securedAxios()
    .put("web/city_zone", payload);
}

export const deleteCityZone = async (id) => {
    return api 
    .securedAxios()
    .delete("web/city_zone/"+id);
}