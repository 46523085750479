import { Grid, styled } from '@mui/material'
const TopRuler = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        position: 'absolute',
        width: '20px',
        height: '100%',
        top: '20px',
        zIndex: 100,
        backgroundColor: '#000000',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {new Array(300)
        .fill('1')
        .map((v, index) => {
          return index * 5
        })
        .map((v, index) => {
          return (
            <Grid
              key={index}
              sx={{
                width: '100%',
                height: '50px',
                flexShrink: 0,
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                position: 'relative',
                backgroundImage:
                  'repeating-linear-gradient(180deg, #ffffff 0, #ffffff 1px, transparent 0, transparent 50px), repeating-linear-gradient(180deg, #ffffff 0, #ffffff 1px, transparent 0, transparent 5px)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '100% 0px,0px 100% ',
                backgroundSize: '100% 100%,5px 100% ',
              }}
            >
              <span
                style={{
                  fontSize: '12px',
                  color: '#ffffff',
                  writingMode: 'vertical-lr'
                }}
              >
                {' '}
                {(index + 1) * 50}
              </span>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default TopRuler
