import React, { useEffect, useState } from "react";
import { getTemplateConfig } from "../js/config";
import { Typography, Button, Grid } from "@mui/material";
import { REACT_PRICE_CHANGE_EVENT } from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import { addChangeEvent } from "@s/PriceChangeEventservice";
import {
  handlerUpdataFile,
  updateComponentValues,
  handlerView,
} from "../js/interface";
import TemplateList from "../Schedule/Templete";
import { useNavigate, useLocation } from "react-router-dom";
import Accourdion from "@c/Accordion";
import CmpFormik from "@z/CmpFormik";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useSnackbar } from "notistack";

function Template(props) {
  const {
    addFormik,
    templateConfig,
    setTemplateConfig,
    expandedIndex,
    handleChange,
    handleConfirm,
    templateList,
    productData,
    setTemplateList,
    handlerCancel,
    totalPages,
    setTotalPages,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let configInfo = getTemplateConfig(t, addFormik, templateList);
    setTemplateConfig(configInfo);
  }, []);

  const handlerLoad = async () => {
    const templateData = templateList?.find((itme) => {
      return itme?.id === addFormik.values.templateId;
    });

    if (templateData && productData) {
      let updatedValues = await updateComponentValues(
        templateData?.templateJson,
        productData,
        addFormik
      );

      if (updatedValues) {
        let res111 = await handlerUpdataFile(updatedValues, addFormik);
        const res = await addChangeEvent({
          ...addFormik.values,
          url: res111?.imageUrl,
          status: "1",
          productAttributeId: addFormik.values.elementId,
          attributeValueId: addFormik.values.productBrand,
        });
        if (res?.data?.code === "LVLI0008") {
          enqueueSnackbar(res?.data?.message, { variant: "success" });
          navigate(REACT_PRICE_CHANGE_EVENT);
        } else {
          enqueueSnackbar(res?.data?.message, { variant: "error" });
        }
      }
    }
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(""); // 点击查看事件图

  return (
    <Accourdion
      elevation={0}
      expanded={expandedIndex === 4}
      label={t("events.templateSelection")}
      onChange={handleChange(4, ["templateId"])}
      handlerConfirm={handleConfirm(5, ["templateId"])}
      handlerCancel={handlerCancel(3, ["templateId"])}
    >
      <Grid position={"relative"}></Grid>
      <Typography variant="body" color={"rgb(73 92 123)"}>
        {t("events.selectTemplate")}
      </Typography>

      <Grid mt={2}>
        <Typography>
          Last template used -{addFormik.values?.detailList?.templateName}
        </Typography>
      </Grid>

      <CmpFormik sx={4} formik={addFormik} formConfig={templateConfig}>
        <TemplateList
          addFormik={addFormik}
          templateList={templateList}
          setTemplateList={setTemplateList}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
        ></TemplateList>
        <Grid position={"absolute"} bottom={"0px"}>
          {state?.action === "Edit" ? null : (
            <Button
              variant="contained"
              onClick={handlerLoad}
              style={{
                size: "medium",
                borderRadius: "15px",
                width: "150px",
                height: "50px",
                opacity: 1,
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
              }}
            >
              {t("events.immediateSync")}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() =>
              handlerView(
                templateList,
                productData,
                addFormik.values.templateId,
                setSelectedImage,
                setLightboxOpen,
                addFormik
              )
            }
            style={{
              size: "medium",
              borderRadius: "15px",
              opacity: 1,
              width: "150px",
              height: "50px",
              marginLeft: "15px",
              background:
                "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
            }}
          >
            {t("common.preview")}
          </Button>

          {lightboxOpen && (
            <Lightbox
              mainSrc={selectedImage}
              style={{ zIndex: 99999999999 }}
              onCloseRequest={() => setLightboxOpen(false)}
            />
          )}
        </Grid>
      </CmpFormik>
    </Accourdion>
  );
}

export default Template;
