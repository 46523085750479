import { Grid } from '@mui/material'
import BarCodeProperties from './RightComponent/BarCodeProperties'
import ContainerProperties from './RightComponent/ContainerProperties'
import ImagePropertise from './RightComponent/ImagePropertise'
import ProductElement from './RightComponent/ProductElement'
import QrCodeProperties from './RightComponent/QrCodeProperties'
import TextBoxProperties from './RightComponent/TextBoxProperties'

const ComponentProperties = (props) => {
  const {
    setActiveId,
    activeId,
    current,
    setCurrent,
    layoutJSON,
    setLayoutJSON,
  } = props

  const RenderProperties = () => {
    if (current === null) {
      return <ContainerProperties {...props}></ContainerProperties>
    } else if (current.type === 'barCode') {
      return <BarCodeProperties {...props}></BarCodeProperties>
    } else if (current.type === 'qrCode') {
      return <QrCodeProperties {...props}></QrCodeProperties>
    } else if (current.type === 'textBox') {
      return <TextBoxProperties {...props}></TextBoxProperties>
    } else if (current.type === 'image' || current.type === 'logo') {
      return <ImagePropertise {...props}></ImagePropertise>
    } else if (current.isProductLabel) {
      return <ProductElement {...props}></ProductElement>
    }
  }

  return (
    <Grid
      sx={{
        height: '100%',
        overflow: 'auto',
        p: 1.5,
      }}
    >
      {RenderProperties()}
    </Grid>
  )
}

export default ComponentProperties
