import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import {
  REACT_PRINCIPAL_MANAGEMENT,
  REACT_RETAIL_CLIENT_LIST,
} from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { addClient } from "../../services/CompanyService";
import { getAllCountries, getAllStates } from "../../services/LocationService";
import { getAllCities } from "../../services/CityService";

export default function AddPrincipal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [payload, setPayload] = useState({
    name: "",
    code: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    cityId: "",
    stateId: "",
    countryId: "",
    pincode: "",
    type: "PRINCIPAL",
    partnerType: "PRINCIPAL",
    superAdminFirstName: "",
    superAdminLastName: "",
    superAdminEmail: "",
    latitude: "",
    longitude: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    cityId: "",
    stateId: "",
    countryId: "",
    pincode: "",
    type: "PRINCIPAL",
    partnerType: "PRINCIPAL",
    superAdminFirstName: "",
    superAdminLastName: "",
    superAdminEmail: "",
    latitude: "",
    longitude: "",
    phone: "",
  });

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res.data.data);
      }
    });
    getAllStates().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setStates(res.data.data);
      }
    });
    getAllCities().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCities(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setState({});
    setCity({});
    if (country === null) {
      setStateList([]);
    }
    if (country)
      setStateList(states.filter((s) => s.country?.id === country?.id));
  }, [country]);

  useEffect(() => {
    setCity({});
    if (state === null) {
      setCityList([]);
    }
    if (state) setCityList(cities.filter((c) => c.state?.id === state?.id));
  }, [state]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(payload.code)) {
      setError({
        ...error,
        code: t("tips.required"),
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.superAdminFirstName)) {
      setError({
        ...error,
        superAdminFirstName: t("tips.required"),
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.superAdminEmail)) {
      setError({
        ...error,
        superAdminEmail: t("tips.required"),
      });
      return false;
    } else if (!CommonUtil.isValidEmail(payload.superAdminEmail)) {
      setError({
        ...error,
        superAdminEmail: t("tips.invalid_email_address"),
      });
      return false;
    }
    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: t("tips.country_code_required"),
        });
        return;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: t("tips.mobild_nubmer"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("LVLRCP010"),
        });
        return;
      }
    }

    if (CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("tips.required"),
        });
        return false;
      }
    }

    return true;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        superAdminEmail: payload.superAdminEmail.toLowerCase(),
        phone: "+" + countryCode.replace("+", "") + "-" + phoneNumber,
        countryId: country?.id,
        stateId: state?.id,
        cityId: city?.id,
      };

      if (CommonUtil.isEmptyString(countryCode)) {
        if (CommonUtil.isEmptyString(phoneNumber)) {
          var request = {
            ...payload,
            phone: "",
            countryId: country.id ? country.id : "",
            stateId: state.id ? state.id : "",
            cityId: city.id ? city.id : "",
          };
        }
      }

      addClient(request).then((response) => {
        if (response.data.code === "LVLI0014") {
          enqueueSnackbar(response.data.message, {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_PRINCIPAL_MANAGEMENT);
          return;
        }
        switch (response.data.code) {
          case "LVLE0071":
            setError({
              ...error,
              name: t("PRNC0002"),
            });
            break;
          case "LVLE0068":
            setError({
              ...error,
              superAdminEmail: t("RETA0003"),
            });
            break;
          case "LVLE0075":
            setError({
              ...error,
              code: t("PRNC0003"),
            });
            break;
          default:
            enqueueSnackbar(response.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };
  return (
    <RightViewLayout
      navigateBack={REACT_PRINCIPAL_MANAGEMENT}
      title={t("LVLRC0026")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal1"
            required
            label={t("LVLRC0002")}
            size="small"
            name="name"
            value={payload.name}
            error={error.name}
            resetError={() => setError({ ...error, name: "" })}
            handleChange={handleChange}
            helperText={error.name}
            inputProps={{
              maxLength: 60,
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal2"
            required
            label={t("LVLRC0003")}
            size="small"
            name="code"
            value={payload.code}
            error={error.code}
            resetError={() => setError({ ...error, code: "" })}
            handleChange={handleChange}
            helperText={error.code}
            inputProps={{
              maxLength: 30,
            }}
            validation={"code"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal3"
            required
            label={t("LVLRC0004")}
            size="small"
            name="superAdminEmail"
            value={payload.superAdminEmail}
            error={error.superAdminEmail}
            resetError={() => setError({ ...error, superAdminEmail: "" })}
            helperText={error.superAdminEmail}
            inputProps={{
              maxLength: 36,
            }}
            validation="email"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal4"
            required
            label={t("LVLRC0013")}
            size="small"
            name="superAdminFirstName"
            value={payload.superAdminFirstName}
            error={error.superAdminFirstName}
            resetError={() => setError({ ...error, superAdminFirstName: "" })}
            helperText={error.superAdminFirstName}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal5"
            label={t("LVLRC0014")}
            size="small"
            name="superAdminLastName"
            value={payload.superAdminLastName}
            error={error.superAdminLastName}
            resetError={() => setError({ ...error, superAdminLastName: "" })}
            helperText={error.superAdminLastName}
            inputProps={{
              maxLength: 60,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-10px" }}
          >
            {t("LVLDAC0021")} {<span style={{ color: "red" }}>*</span>}
          </InputLabel>
          <CustomePhoneFiled
            id="AddPrincipalPhone"
            error={error.phone}
            resetError={() => setError({ ...error, phone: "" })}
            handleCountryCode={(data) => {
              setCountryCode(data.dialCode);
              setError({ ...error, phone: "" });
            }}
            countryCode={countryCode}
            placeholder={t("LVLDAC0021")}
            phoneNumber={phoneNumber}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => {
              setPhoneNumber(e);
              setError({ ...error, phone: "" });
            }}
            label={t("LVLDAC0021")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLDAC0023")}
          </InputLabel>
          <Autocomplete
            id="AddPrincipalCountries"
            options={countries}
            value={country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({ ...payload, country: v ? v.id : "" });
              setError({ ...error, country: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.countryId)}
                helperText={error.countryId}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0006")}
          </InputLabel>
          <Autocomplete
            id="AddPrincipalStateId"
            error={error.stateId}
            helperText={error.stateId}
            options={stateList}
            value={state}
            onChange={(e, v) => {
              setState(v);
              setError({ ...error, state: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.state)}
                helperText={error.stateId}
              />
            )}
          ></Autocomplete>
        </Grid>

        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ paddingLeft: "0px" }}
            >
              {t("LVLGF0003")}
            </InputLabel>
            <Autocomplete
              id="AddPrincipalCity"
              error={error.city}
              helperText={error.city}
              options={cityList}
              value={city}
              onChange={(e, v) => {
                setCity(v);
                setError({ ...error, city: "" });
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={!CommonUtil.isEmptyString(error.city)}
                  helperText={error.city}
                />
              )}
            ></Autocomplete>
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddPrincipal6"
              label={t("LVLOT0024")}
              size="small"
              name="pincode"
              value={payload.pincode}
              error={error.pincode}
              resetError={() => setError({ ...error, pincode: "" })}
              helperText={error.pincode}
              inputProps={{
                maxLength: 20,
              }}
              validation={"numericWithoutDot"}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal7"
            label={t("LVLOT0013")}
            size="small"
            name="latitude"
            error={error.latitude}
            resetError={() => setError({ ...error, latitude: "" })}
            value={payload.latitude}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.latitude}
            validation={"numeric"}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal8"
            label={t("LVLOT0014")}
            size="small"
            name="longitude"
            error={error.longitude}
            resetError={() => setError({ ...error, longitude: "" })}
            value={payload.longitude}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.longitude}
            validation={"numeric"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal9"
            label={t("LVLOT0022")}
            size="small"
            name="addressLine1"
            value={payload.addressLine1}
            error={error.addressLine1}
            resetError={() => setError({ ...error, addressLine1: "" })}
            helperText={error.addressLine1}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddPrincipal10"
            label={t("LVLOT0023")}
            size="small"
            name="addressLine2"
            value={payload.addressLine2}
            error={error.addressLine2}
            resetError={() => setError({ ...error, addressLine2: "" })}
            helperText={error.addressLine2}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="AddPrincipal-button-01"
                variant="contained"
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
              >
                {t("EPLSCN0017")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="AddPrincipal-button-02"
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_PRINCIPAL_MANAGEMENT)}
                size="large"
              >
                {t("LVLOT0015")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
