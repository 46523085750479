import React from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { Grid, Box, Typography, Button, Autocomplete } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  REACT_DASHBOARD,
  REACT_VIEW_USER_PROFILE,
} from "../../router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import UserService from "../../services/UserService";
import ViewPage from "../../components/ViewPage";
import CommonUtil from "../../util/CommonUtils";
import { InputLabel, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import {
  getAllCities,
  getAllCountries,
  getAllStates,
} from "../../services/LocationService";

export default function UpdateUserProfile() {
  var data = localStorage.getItem("USER");
  var parseData = JSON.parse(data);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    addressLine1: "",
    phoneCountryCode: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res.data.code === "LVLI0000") {
        setCountries(res.data.data);
      }
    });
    getAllStates().then((res) => {
      if (res.data.code === "LVLI0000") {
        setStates(res.data.data);
      }
    });
    getAllCities().then((res) => {
      if (res.data.code === "LVLI0000") {
        setCities(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setState({});
    setCity({});
    if (country === null) {
      setStateList([]);
      setPayload({
        ...payload,
        state: "",
        city: "",
      });
    }
    if (country)
      setStateList(states.filter((s) => s.country?.id === country.id));
  }, [country]);

  useEffect(() => {
    setCity({});
    if (state === null) {
      setCityList([]);
      setPayload({
        ...payload,
        city: "",
      });
    }
    if (state) setCityList(cities.filter((c) => c.state?.id === state.id));
  }, [state]);

  // useEffect(() => {
  //   let user = CommonUtil.decodeToken();
  //   if (user) {
  //     getUser(user?.id, true);
  //   }
  // }, []);

  // const getUser = async (id, loadProfileImage) => {
  //   await UserService.getUserDetails(id, loadProfileImage).then((res) => {
  //     setPayload({
  //       ...payload,
  //       ...res.data.data,
  //     });
  //     let phonWithCounrtyCode = res?.data?.data?.phone;
  //     if (phonWithCounrtyCode !== "") {
  //       var mobile = phonWithCounrtyCode?.split("-");
  //       if (mobile) {
  //         setCountryCode(mobile[0]);
  //         setPhoneNumber(mobile[1]);
  //         setPayload({
  //           ...payload,
  //           phone: mobile[1],
  //           phoneCountryCode: mobile[0],
  //           ...res.data.data,
  //         });
  //       }
  //     }
  //   });
  // };

  const updateUser = async (id, payload) => {
    if (validateForm()) {
      var request = {
        ...payload,
        phone: phoneNumber,
        phoneCountryCode: countryCode,
      };
      await UserService.updateUser(id, request).then((res) => {
        if (res?.data?.code === "UASI0002" && res?.data?.data) {
          enqueueSnackbar(res.data.message, {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_DASHBOARD);
        }
        if (res?.data?.code !== "UASI0002") {
          enqueueSnackbar(res.data.message, {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleSubmit = (event) => {
    let user = CommonUtil.decodeToken();
    updateUser(user.id, payload);
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: t("tips.required"),
      });
      return false;
    }
    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: t("tips.country_code_required"),
        });
        return false;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: t("tips.mobild_nubmer"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t("LVLRCP010"),
        });
        return false;
      }
    }
    return true;
  };

  return (
    <RightViewLayout navigateBack={"-1"} title={t("LVLDAC0031")}>
      <Grid sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box" }}>
        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              required
              label={t("LVLRCP014")}
              name="firstName"
              size="small"
              value={payload.firstName}
              handleChange={handleChange}
              error={error.firstName}
              resetError={() => setError({ ...error, firstName: "" })}
              helperText={error.firstName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              label={t("LVLRCP015")}
              name="lastName"
              size="small"
              value={payload.lastName}
              handleChange={handleChange}
              error={error.lastName}
              resetError={() => setError({ ...error, lastName: "" })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="UpdateUserProfile1"
              label={t("LVLDAC0020")}
              name="email"
              size="small"
              value={payload.email}
              handleChange={handleChange}
              error={error.email}
              resetError={() => setError({ ...error, email: "" })}
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ marginBottom: "7px" }}
            >
              {t("LVLDAC0021")} {<span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <CustomePhoneFiled
              id="UpdateRetailClientPhone"
              error={error.phone}
              resetError={() => setError({ ...error, phone: "" })}
              handleCountryCode={(data) => {
                setCountryCode(data.dialCode);
                setError({ ...error, phone: "" });
              }}
              countryCode={countryCode}
              placeholder={t("LVLDAC0021")}
              phoneNumber={phoneNumber}
              name={"phoneNumber"}
              size={"small"}
              handleChange={(e) => {
                setPhoneNumber(e);
                setError({ ...error, phone: "" });
              }}
              label={"LVLDAC0021"}
              //disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ marginBottom: "7px" }}
            >
              {t("synchronized.country")}
            </InputLabel>
            <Autocomplete
              id="UpdateUserProfileCountry"
              options={countries || []}
              error={error.country}
              helperText={error.country}
              value={country}
              onChange={(e, v) => {
                setCountry(v);
                setState({});
                setCity({});
                setError({ ...error, country: "" });
                setPayload({
                  ...payload,
                  country: v,
                  stateId: "",
                  cityId: "",
                });
              }}
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  error={!CommonUtil.isEmptyString(error.country)}
                  helperText={error.country}
                />
              )}
              sx={{
                "& .MuiAutocomplete-popupIndicator": {
                  transform: "none", // Reset any transform on the popup indicator
                },
                "& .MuiAutocomplete-listbox": {
                  maxHeight: "200px", // Set the max height for the listbox
                  fontSize: "14px", // Adjust the font size as needed
                },
                "& .MuiAutocomplete-option": {
                  padding: "5px", // Adjust padding for each option
                },
                "& .MuiOutlinedInput-root": {
                  padding: "5px",
                  borderRadius: "8px",
                },
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ marginBottom: "7px" }}
            >
              {t("synchronized.stateProvince")}
            </InputLabel>
            <Autocomplete
              id="UpdateUserProfileState"
              error={error.state}
              helperText={error.state}
              options={states || []}
              value={state}
              onChange={(e, v) => {
                setState(v);
                setCity({});
                setError({ ...error, state: false });
              }}
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 10,
                    },
                  }}
                  {...params}
                  variant="outlined"
                  size="small"
                  helperText={error.state}
                  error={error.state}
                />
              )}
              sx={{
                "& .MuiAutocomplete-popupIndicator": {
                  transform: "none", // Reset any transform on the popup indicator
                },
                "& .MuiAutocomplete-listbox": {
                  maxHeight: "200px", // Set the max height for the listbox
                  fontSize: "14px", // Adjust the font size as needed
                },
                "& .MuiAutocomplete-option": {
                  padding: "5px", // Adjust padding for each option
                },
                "& .MuiOutlinedInput-root": {
                  padding: "5px",
                  borderRadius: "8px",
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ marginBottom: "7px" }}
            >
              {t("synchronized.city")}
            </InputLabel>
            <Autocomplete
              id="UpdateUserProfileCity"
              helperText={error.city}
              options={cities || []}
              value={city}
              onChange={(e, v) => {
                setCity(v);
                setError({ ...error, city: false });
              }}
              getOptionLabel={(option) => {
                return option ? option.name : "";
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  error={error.city}
                  helperText={error.city}
                />
              )}
              sx={{
                "& .MuiAutocomplete-popupIndicator": {
                  transform: "none", // Reset any transform on the popup indicator
                },
                "& .MuiAutocomplete-listbox": {
                  maxHeight: "200px", // Set the max height for the listbox
                  fontSize: "14px", // Adjust the font size as needed
                },
                "& .MuiAutocomplete-option": {
                  padding: "5px", // Adjust padding for each option
                },
                "& .MuiOutlinedInput-root": {
                  padding: "5px",
                  borderRadius: "8px",
                },
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomInput
              id="UpdateUserProfile2"
              label={t("LVLDAC0022")}
              name="addressLine1"
              size="small"
              value={payload.addressLine1}
              handleChange={handleChange}
              error={error.address}
              resetError={() => setError({ ...error, address: "" })}
            />
          </Grid>
        </Grid>

        {/* <Box item pt={2}>
          <Button
            id="UpdateUserProfile-button-01"
            variant="contained"
            size="large"
            className="text-transform-none"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box> */}
        <Grid container spacing={2} px={2}>
          <Grid item xs={12} mb={2}>
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Box item pl={2}>
                <Button
                  id="updateRetailClint1"
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  style={{
                    size: "medium",
                    borderRadius: "8px",
                    opacity: 1,
                    background:
                      "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                  }}
                  // onClick={handleSubmit}
                >
                  {" "}
                  {t("EPLSCN0017")}{" "}
                </Button>
              </Box>
              <Box item>
                <Button
                  id="updateretailClint2"
                  className="text-transform-none"
                  variant="outlined"
                  size="large"
                  onClick={() => navigate(REACT_DASHBOARD)}
                >
                  {" "}
                  {t("LVLRC0017")}{" "}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
