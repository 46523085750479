import React, { useEffect, useState } from "react";
import { Grid, styled, FormHelperText } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as CalenderIcon } from "@/assets/images/calendar-icon.svg";
import CustomInput from "./DateInput";
import { format } from "date-fns";
import { zhCN } from "date-fns/locale";
import { formatDateFromString } from "@/util/utils";
const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

function CustomDatePicker(props) {
  const {
    dateFormat = "dd-MM-yyyy",
    formik,
    label,
    name,
    error,
    views = ["year", "month", "day"],
    disableFuture,
    disabled = false,
  } = props;
  const [date, setDate] = useState("");

  const handlerChange = (date) => {
    formik.setFieldValue(name, formatDateFromString(date));
    setDate(date);
  };

  useEffect(() => {
    let values = formik.values[name];
    if (values) {
      setDate(values);
    } else {
      setDate("");
    }
  }, [formik.values[name]]);

  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat={dateFormat}
          disableFuture={disableFuture}
          disabled={disabled}
          placement="bottom-right"
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          name={name}
          label={label}
          dateRangeIcon={<CalenderIcon />}
          views={views}
          value={date}
          sx={{
            p: 1,
            border: "1px solid black",
          }}
          onChange={(e) => handlerChange(e)}
          renderInput={(params) => (
            <CustomInput
              fullWidth
              handleChange={(e) => console.log()}
              size={props.size ? props.size : " "}
              value={date}
              required={props.required}
              style={{ width: "100%" }}
              {...params}
              disableMaskedInput={true}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
            />
          )}
        />

        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </Grid>
  );
}

export default CustomDatePicker;
