import api from "../configurations/http-common";

export const createPerson = async (payload) => {
  return api.securedAxios().post("web/person", payload);
};

export const approvalUserList = async () => {
  let url = `web/person/approvalUsers`;
  return api.securedAxios().get(url);
};

export const personList = async (filters) => {
  let url = `web/persons/list`;

  if (filters?.pageNumber) {
    url = url + "?pageNumber=" + filters.pageNumber;
  }
  if (filters?.pageSize) {
    url = url + "&pageSize=" + filters.pageSize;
  }
  if (filters?.clientIds) {
    url = url + "&clientId=" + filters.clientIds;
  }
  if (filters?.firstName) {
    url = url + "&firstName=" + filters.firstName;
  }
  if (filters?.lastName) {
    url = url + "&lastName=" + filters.lastName;
  }
  if (filters?.email) {
    url = url + "&email=" + filters.email;
  }
  if (filters?.phone) {
    url = url + "&lastName=" + filters.phone;
  }
  if (filters?.status) {
    url = url + "&status=" + filters.status;
  }
  if (filters?.code) {
    url = url + "&code=" + filters.code;
  }
  if (filters?.toggleKeyToURL) {
    url = url + "&toggleKeyToURL=" + filters.toggleKeyToURL;
  }
  if (filters?.operator) {
    url = url + "&operator=" + filters.operator;
  }

  return api.securedAxios().get(url);
};

export const deletePerson = async (personIdOrCode, clientId) => {
  let url = "web/person";
  if (personIdOrCode) {
    url = url + "?id=" + personIdOrCode;
  }
  if (clientId) {
    url = url + "&clientId=" + clientId;
  }
  return api.securedAxios().delete(url);
};

export const getPersonByIdOrCode = async (personId, clientId) => {
  let url = "web/person" + "?id=" + personId + "&clientId=" + clientId;
  return api.securedAxios().get(url);
};

export const updatePerson = async (personIdOrCode, clientId, payload) => {
  let url = "web/person" + "?id=" + personIdOrCode + "&clientId=" + clientId;
  return api.securedAxios().put(url, payload);
};

export const getPersonById = async (personId) => {
  let url = "web/personDetails" + "?id=" + personId;
  return api.securedAxios().get(url);
};

export const getPermissionById = async (personId) => {
  let url = "web/personPermission" + "?id=" + personId;
  return api.securedAxios().get(url);
};

export const getCreatedByName = async (personId) => {
  let url = "web/person/get" + "?id=" + personId;
  return api.securedAxios().get(url);
};

export const downloadImageUrl = async (objectKey) => {
  return api.securedAxios().post("web/person/image-url", objectKey);
};
