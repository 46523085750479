import CustomDatePicker from "@c/CustomDatePicker";
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

function TimeDiaLog(props) {
  const { open, setOpen, placeholder, label, Tiele, date, setDate } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
      <DialogTitle>
        {Tiele}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CustomDatePicker
          label={label}
          size={"small"}
          disableFuture={true}
          date={date}
          disabled={false}
          placeholder={placeholder}
          SelectedDate={(e) => {
            let time = dayjs(e).format("YYYY-MM-DD");
            setDate(time);
            setOpen(false);
          }}
        ></CustomDatePicker>
      </DialogContent>
    </Dialog>
  );
}

export default TimeDiaLog;
