import RightViewLayout from "../../components/RighViewLayout";
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import { REACT_DEVICE_LIST } from "../../router/ReactEndPoints";
import DeviceIcon from "../../assets/images/icon_device.png";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import { useTranslation } from "react-i18next";
export default function DeviceDetails() {
  const { t } = useTranslation();
  const columns = [
    {
      field: "logTime",
      headerName: `${t("gateway.logTime")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.sn} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
    {
      field: "logType",
      headerName: `${t("gateway.logType")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.sn} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
    {
      field: "logCode",
      headerName: `${t("gateway.logCode")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.sn} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
    {
      field: "enable",
      headerName: `${t("gateway.enable")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.sn} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
  ];
  return (
    <RightViewLayout
      navigateBack={REACT_DEVICE_LIST}
      title={t("gateway.viewDeviceDetails")}
    >
      <Card sx={{ display: "flex" }} elevation={0}>
        <CardContent sx={{ marginBottom: 1 }}>
          <Grid container xs={12} width={"70%"} alignItems={"center"}>
            <Grid item xs={2} justifySelf={"center"}>
              <img
                alt="complex"
                src={DeviceIcon}
                style={{ height: "60px", width: "60px" }}
              />
            </Grid>
            <Grid item container xs justifyContent="center" spacing={1}>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.deviceName")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.deviceModel")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.outlet")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.serialNumber")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.deviceType")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.outletId")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.gatewayName")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.clientName")}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("gateway.gatewayRunningStatus")}
                </Typography>
                <Typography></Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid pt={3} pb={4}>
        <Typography variant="h6">{t("gateway.deviceLog")}</Typography>
      </Grid>
      <DataTable columns={columns} />
    </RightViewLayout>
  );
}
