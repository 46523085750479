import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

export default function ConfirmModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = () => {
    props.onConfirm();
  };

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        id="ConfirmModal-button-3"
      >
        <DialogContent>
          <Box display="flex" p={1}>
            {props.text}
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            size="large"
            className="text-transform-none"
            id="ConfirmModal-button-1"
            style={{
              size: "medium",
              borderRadius: "8px",
              opacity: 1,
              background:
                "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
              color: "white", // Set text color to white
            }}
          >
            {t("common.submit")}
          </Button>

          <Button
            onClick={handleClose}
            variant="outlined"
            size="large"
            className="text-transform-none"
            id="ConfirmModal-button-1"
            color="primary"
            style={{
              size: "medium",
              borderRadius: "8px",
              opacity: 1,
            }}
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
