import {
  Avatar,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import IconHandaler from "../../components/IconHandaler";
import { ReactComponent as ForwardToInboxIcon } from "../../assets/images/send_invitation_icon.svg";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import DataTable from "../../components/DataTable";
import CommonUtil from "../../util/CommonUtils";
import {
  REACT_USER_ADD,
  REACT_USER_EDIT,
  REACT_USER_VIEW,
} from "../../router/ReactEndPoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ListLayout from "../../components/ListLayout";
import { useRef, useState } from "react";
import {
  deletePerson,
  getPersonById,
  getPersonByIdOrCode,
  personList,
} from "../../services/PersonService";
import { useEffect } from "react";
import ConfirmModal from "../../components/ConfirmModel";
import { confirmAlert } from "react-confirm-alert";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { da } from "date-fns/locale";
import { sendActivationMail } from "../../services/CompanyService";

export default function User() {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [user, setUser] = useState();
  const [downloadUrls, setDownloadUrls] = useState([]);
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  let isAllowedResendEmail = true;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
    clientIds: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: "",
    code: "",
    toggleKeyToURL: "",
    operator: "",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
    clientIds: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: "",
    code: "",
    toggleKeyToURL: "",
    operator: "",
  };
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage?.getItem("USER")));
  }, []);

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    personList(filters).then((res) => {
      if (res?.data?.code === "UASI0000") {
        setRecords(res?.data?.data?.users);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
        setDownloadUrls(res?.data?.data?.downloadUrls);
      }
      if (res?.data?.code === "LVLE0054") {
        setRecords([]);
      }
    });
  };
  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  };

  // console.log(downloadUrls)
  const downloadUrl = (objectKey) => {
    if (downloadUrls !== null) {
      const downloadUrl = downloadUrls?.find(
        (url) => url.objectKey === objectKey
      );
      console.log(downloadUrl?.downloadUrl);
      return downloadUrl?.downloadUrl;
    }
  };

  const getoutletName = (outlet) => {
    if (outlet !== null) {
      let names = outlet.map((p) => p.name);
      return names.join(", ");
    } else {
      return null;
    }
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddUser");
    isAllowedView = permission && permission.includes("ViewUser");
    isAllowedEdit = permission && permission.includes("EditUser");
    isAllowedDelete = permission && permission.includes("DeleteUser");
    isAllowedResendEmail = permission && permission.includes("ResendEmail");
  }

  const columns = [
    {
      field: "imageObjectKey",
      headerName: `${t("Person Avatar")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Avatar
            sx={{ bgcolor: "#C0C0C0", marginRight: "8px" }}
            imgProps={{ draggable: "false" }}
            alt={e.row.firstName}
            src={e.row.imageUrl}
          ></Avatar>
        </>
      ),
    },
    {
      field: "firstName",
      headerName: `${t("user.person_name")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip
            title={
              e?.row?.lastName
                ? e.row.firstName + " " + e?.row?.lastName
                : e.row.firstName
            }
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(
                e.row.lastName
                  ? e.row.firstName + " " + e.row.lastName
                  : e.row.firstName
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "personid",
      headerName: `${t("user.person_Id")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.personId} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.personId)}</span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: `${t("table.email")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.email} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: "mobile",
      headerName: `${t("table.mobile")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.mobile} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.mobile)}</span>
        </Tooltip>
      ),
    },
    {
      field: "outlets",
      headerName: `${t("tips_user.outlet_in_charge")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={getoutletName(e.row.outlets)} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(getoutletName(e.row.outlets))}</span>
        </Tooltip>
      ),
    },
    {
      field: "role",
      headerName: `${t("tips_user.permission_group_name")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.role?.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.role?.name)}</span>
        </Tooltip>
      ),
    },
    {
      headerName: `${t("common.actions")}`,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedView && (
            <Tooltip title={t("common.view")} arrow>
              <VisibilityIcon
                id="viewpersonlist"
                onClick={() => handleActions("View", e?.row?.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {isAllowedEdit && (
            <Tooltip title={t("common.edit")} arrow>
              <EditIcon
                onClick={() => handleActions("Edit", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}

          {isAllowedResendEmail && (
            <Tooltip
              title={t("common.resend_email")}
              arrow
              sx={{ marginLeft: 1 }}
            >
              <ForwardToInboxIcon
                sx={{
                  "& .MuiSvgIcon-root": {
                    marginLeft: "0px",
                  },
                }}
                onClick={() => handleActions("ResendEmail", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  paddingLeft: "5px",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}

          {isAllowedDelete && (
            <Tooltip title={t("common.delete")} sx={{ pl: 1 }}>
              <DeleteIcon
                id="deletepersonlist"
                onClick={() => handleActions("Delete", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  paddingLeft: "5px",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
        </IconHandaler>
      ),
    },
  ];

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      clientIds: params.id,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      clientIds: params.id,
      pageSize: e,
    });
  };

  const handleDelete = (id) => {
    deletePerson(id, user?.id)
      .then((response) => {
        if (response.data.code === "LVLI0002") {
          enqueueSnackbar(t("tips_user.person_deleted"), {
            variant: "success",
          });
          setConfirm(false);
          loadData();
        } else {
          enqueueSnackbar(response?.data?.message, {
            variant: "error",
          });
          setConfirm(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("tips.occurred"), {
          variant: "error",
        });
        setConfirm(false);
        loadData();
      });
  };

  const handleActions = (action, data) => {
    let id = data;
    if (action === "View") {
      navigate(REACT_USER_VIEW, { state: { id: id } });
    }
    if (action === "ResendEmail") {
      sendEmail(data);
    }
    if (action === "Edit") {
      let id = data;
      navigate(REACT_USER_EDIT, { state: { id: id } });
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const sendEmail = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={
              <span>
                <span
                  style={{
                    font: "normal normal normal 15px/20px Roboto",
                    color: "#000",
                    // fontSize: "normal normal normal 14px / 22px Roboto",
                    // width: "80px",
                    fontWeight: "bold",
                  }}
                >
                  {t("tips_user.activation_mail_confirmation")}
                </span>
              </span>
            }
            onConfirm={() => {
              getPersonByIdOrCode(id, user?.id).then((res) => {
                if (res?.data?.code === "LVLI0000") {
                  sendActivationMail(res.data.data.email).then((res) => {
                    if (res?.data?.code === "LVLI0000") {
                      enqueueSnackbar(t("tips_user.activation_mail_sent"), {
                        variant: "success",
                      });
                      loadData();
                    } else
                      enqueueSnackbar(res?.data?.message, { variant: "error" });
                  });
                } else
                  enqueueSnackbar(res?.data?.message, { variant: "error" });
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleSelection = (id) => {};

  const toolbarProps = {
    add: isAllowedCreate,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_USER_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  return (
    <ListLayout
      id="personlistback"
      navigateBack={false}
      title={t("user.name")}
      toolbarProps={toolbarProps}
    >
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
        onSelection={(id) => handleSelection(id)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {t("common.cancel")}{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => handleDelete(id)}
            >
              {" "}
              {t("common.delete")}{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
