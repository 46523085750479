import api from "../configurations/http-common";

export const deviceList = async (filter) => {
  let url = "api/v2/devices/list?";

  if (filter?.pageNumber) {
    url = url + "pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  if (filter?.id) {
    url = url + "&id=" + filter.id;
  }
  if (filter?.sn) {
    url = url + "&sn=" + filter.sn;
  }
  if (filter?.deviceType) {
    url = url + "&deviceType=" + filter.deviceType;
  }
  if (filter?.deviceModel) {
    url = url + "&deviceModel=" + filter.deviceModel;
  }
  if (filter?.clientId) {
    url = url + "&clientId=" + filter.clientId;
  }
  if (filter?.deviceAlias) {
    url = url + "&deviceAlias=" + filter.deviceAlias;
  }
  if (filter?.mac) {
    url = url + "&mac=" + filter.mac;
  }
  if (filter?.siteId) {
    url = url + "&siteId=" + filter.siteId;
  }
  if (filter?.zoneId) {
    url = url + "&zoneId=" + filter.zoneId;
  }
  if (filter?.checkInOrOutType) {
    url = url + "&checkInOrOutType=" + filter.checkInOrOutType;
  }
  if (filter?.ip) {
    url = url + "&ip=" + filter.ip;
  }
  if (filter?.registrationDevice) {
    url = url + "&registrationDevice=" + filter.registrationDevice;
  }
  if (filter?.operator) {
    url = url + "&operator=" + filter.operator;
  }
  return api.securedAxios().get(url);
};

export const bindDevice = async (payload, callBack) => {
  try {
    const res = await api.securedAxios().post("api/v2/device", payload);
    callBack(res);
  } catch (error) {}
};

export const findDeviceBySn = async (sn, callBack) => {
  try {
    const res = await api
      .securedAxios()
      .get("api/v2/device/pre_register/" + sn);
    callBack(res);
  } catch (error) {}
};

export const getDeviceBySn = async (sn) => {
  return api.securedAxios().get("api/v2/device/" + sn);
};

export const getDeviceById = async (id) => {
  return api.securedAxios().get("api/v2/devices/" + id);
};

export const unBindDevice = async (id) => {
  let url = "api/v2/device/" + id;
  return api.securedAxios().delete(url);
};

export const filterDeviceByDate = async (filter) => {
  let url = "api/v2/devices/filter/list?";
  if (filter?.pageNumber) {
    url = url + "pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  if (filter?.fromDate) {
    url = url + "&fromDate=" + filter.fromDate;
  }
  if (filter?.toDate) {
    url = url + "&toDate=" + filter.toDate;
  }
  if (filter?.siteId) {
    url = url + "&siteId=" + filter.siteId;
  }
  return api.securedAxios().get(url);
};

export const findByIdIn = async (payload) => {
  return api.securedAxios().post("api/v2/devices/ids", payload);
};
// formData.append("type", params.type);
export const uploadUpgrade = (params) => {
  let formData = new FormData();
  formData.append("file", params.file);
  formData.append("deviceIdList", params.deviceIdList);
  formData.append("version", params.version);
  return api.securedAxios().post("api/v2/device/uploadUpgrade", formData, {
    headers: {
      isRetry: false,
      "Content-Type": "multipart/form-data",
    },
  });
};
