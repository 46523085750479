import { Grid } from "@mui/material";
import { promotionType, productElement, scheduleModes } from "./enum";
import OutletInput from "../Schedule/OutletInput";
import { timeZoneList } from "@/constants/TimeZone";

// 变价名称模块
export const getPriceNameConfig = (t) => {
  let baseConfig = [
    {
      name: "name",
      label: t("events.priceChangeName"),
      type: "input",
      // placeholder: t("Please enter the name of the price change"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          // message: t("Please enter the name of the price change"),
        },
      ],
    },
  ];

  return baseConfig;
};

// 变价名称模块
export const getOutletConfig = (t, deviceOnlineOutlet, addFormik) => {
  let baseConfig = [
    {
      name: "outletIds",
      label: t("events.outlet"),
      // placeholder: t("请选择门店"),
      options: deviceOnlineOutlet,
      custom: true,
      renderingCustomItem: () => {
        return (
          <Grid xs={3.9} ml={2}>
            <OutletInput
              required
              label={t("events.outlet")}
              addFormik={addFormik}
            ></OutletInput>
          </Grid>
        );
      },
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          // message: t("请选择门店"),
        },
      ],
    },
  ];

  return baseConfig;
};

// 商品模块配置数据
export const getProductConfig = (
  t,
  productLabel,
  brandData,
  productData,
  selectLabel
) => {
  let baseConfig = [
    {
      name: "elementId",
      label: t("product.title"),
      type: "person",
      required: true,
      options: productLabel,
      typeValue: "4",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          // message: t("PRC0022"),
        },
      ],
    },
    {
      name: "productBrand",
      label: selectLabel,
      type: "person",
      // placeholder: t("请选择品牌"),
      required: true,
      typeValue: "1",
      options: brandData,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("Please select"),
        },
      ],
    },
    {
      name: "productId",
      label: t("table_product.product_name"),
      type: "person",
      // placeholder: t("EVSC0002"),
      required: true,
      typeValue: "5",
      options: productData,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          // message: t("EVSC0002"),
        },
      ],
    },
  ];

  return baseConfig;
};

// 商品模块配置数据
export const getPriceRuleConfig = (
  t,
  addFormik,
  labelValue,
  isShowPrecent,
  inputRef
) => {
  let baseConfig = [
    {
      name: "promotionType",
      label: t("events.promotionType"),
      type: "autocomplete",
      // placeholder: t("请选择促销类型"),
      typeValue: "1",
      required: true,
      options: promotionType,
      handleChange: () => {
        inputRef.current?.focus();
      },
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          // message: t("请选择促销类型"),
        },
      ],
    },
    {
      name: "promotionDescription",
      label: labelValue,
      type: "input",
      // placeholder: t("Please enter Discount"),
      required: true,
      inputType: isShowPrecent,
      inputRef: inputRef,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t(
            labelValue
              ? `${labelValue} is a required field`
              : "Please select a promotionType"
          ),
        },

        {
          type:
            isShowPrecent == "1" || labelValue == "Discount"
              ? "number"
              : "null",
          message: t("Please enter the number"),
        },
        {
          type:
            isShowPrecent == "1" || labelValue == "Discount"
              ? "discount"
              : "null",
          message: t("The maximum discount is 100%"),
        },
      ],
    },
  ];

  return baseConfig;
};

//模板选择模块配置数据
export const getTemplateConfig = (t, addFormik, templateList) => {
  let baseConfig = [
    {
      name: "templateId",
      label: t("events.promotionType"),
      custom: true,
      sx: 12,
      renderingCustomItem: (item) => {},
    },
  ];

  return baseConfig;
};

// 变价计划模块配置数据
export const getScheduleConfig = (t, does) => {
  let baseConfig = [
    {
      name: "scheduleMode",
      label: t("events.scheduleMode"),
      type: "ZKAutocomplete",
      typeValue: "3",
      placeholder: t("tips.select_price_period"),
      // required: true,
      sx: 4,
      options: scheduleModes,
      // validation: [
      //   {
      //     type: "string",
      //     message: "",
      //   },
      //   {
      //     type: "required",
      //     message: t("请选择变价周期"),
      //   },
      // ],
    },
    {
      name: "zoneId",
      label: t("events.timeZone"),
      type: "ZKAutocomplete",
      disabled: true,
      // placeholder: t("请选择时区"),
      sx: 4,
      options: timeZoneList,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("请选择时区"),
        },
      ],
    },

    {
      type: "null",
      sx: 6,
      custom: true,
      renderingCustomItem: () => {
        return <Grid width={"30%"}></Grid>;
      },
    },
    {
      name: "startTime",
      label: t("events.startPromotion"),
      required: true,
      placeholder: t("common_tips.start_date"),
      type: "timeMonths",
      minDate: new Date(),
      sx: 2,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("common_tips.start_date"),
        },
      ],
    },
    {
      name: "timeDay",
      placeholder: t("请选择时间"),
      type: "timeDay",
      sx: 2,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("请选择开始时间"),
        },
      ],
    },

    {
      name: "endTime",
      label: t("events.endPromotion"),
      display: does ? false : true,
      placeholder: t("common_tips.end_date"),
      type: "timeMonths",
      minDate: new Date(),
      sx: 2,
      validation: [
        {
          type: "string",
          message: "",
        },
      ],
    },
    {
      name: "endTimeDay",
      placeholder: t("common_tips.end_date"),
      type: "timeDay",
      display: does ? false : true,
      sx: 2,
      validation: [
        {
          type: "string",
          message: "",
        },
      ],
    },
  ];

  return baseConfig;
};
