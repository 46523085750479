import {
  InputLabel,
  Stack,
  Autocomplete,
  TextField,
  FormHelperText,
} from "@mui/material";
import RequirePoint from "@/components/Retail-components/RequirePoint";
import { useEffect, useState } from "react";

function PersonAutocomplete(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height = "40px",
    inputType,
    options,
    fontSize = "22px",
    readonly,
    typeValue = "0",
    ...orther
  } = props;

  const [data, setData] = useState("");

  useEffect(() => {
    if (typeValue === "1") {
      if (options?.includes(formik.values?.detailList?.attributeValueId)) {
        setData(formik.values?.detailList?.attributeValueId);
        formik.setFieldValue(
          "productBrand",
          formik.values?.detailList?.attributeValueId
        );
      } else {
        setData("");
        formik.setFieldValue("productBrand", "");
      }
    } else if (typeValue === "5") {
      const list = options?.find((item) => {
        return item.id === formik.values?.detailList?.productId;
      });

      setData(list);
      formik.setFieldValue("productId", list?.id);
    } else if (typeValue === "4") {
      const list = options?.find((item) => {
        return item.id === formik.values?.detailList?.productAttributeId;
      });
      setData(list);

      formik.setFieldValue(
        "elementId",
        formik.values?.detailList?.productAttributeId
      );
    } else {
      let userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
      if (userInfo?.clientId) {
        const list = options.find((item) => item.id === userInfo?.clientId);
        setData(list);
        formik.setFieldValue(name, userInfo?.clientId);
      } else {
        const list = options?.find((item) => {
          return item.id === formik.values[name];
        });

        setData(list);
        formik.setFieldValue(name, list?.id);
      }
    }
  }, [options]);

  return (
    <Stack>
      <Stack
        direction={labelPostion === "left" ? "row" : "column"}
        sx={{
          alignItems: labelPostion === "left" ? "flex-start" : "",
        }}
        spacing={spacing}
      ></Stack>
      <InputLabel
        shrink
        htmlFor={"CmpAutoComPlete_" + name}
        style={{
          marginTop: labelPostion === "left" ? "12px" : "",
          width: width,
          fontSize: fontSize,
        }}
      >
        {props.required && <RequirePoint></RequirePoint>}
        {label}
      </InputLabel>
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Autocomplete
          disablePortal
          id={props.id}
          fullWidth
          options={options || []}
          onChange={(event, newValue) => {
            if (typeValue === "1") {
              setData(newValue);
              formik.setFieldValue(name, newValue);
            } else if (typeValue === "2") {
              setData(newValue);
              formik.setFieldValue(name, newValue?.id);
            } else if (typeValue === "3") {
              setData(newValue);
              formik.setFieldValue(name, newValue?.value);
            } else if (typeValue === "4") {
              setData(newValue);
              formik.setFieldValue(name, newValue?.id);
            } else if (typeValue === "5") {
              setData(newValue);
              formik.setFieldValue(name, newValue?.id);
            } else {
              setData(newValue);
              formik.setFieldValue(name, newValue?.id);
            }
          }}
          value={data}
          disabled={disabled}
          name={name}
          isOptionEqualToValue={(option, value) => {
            if (typeValue === "1") {
              return option ? option : "";
            } else if (typeValue === "2") {
              return option ? option?.id : "";
            } else if (typeValue === "3") {
              return option ? option?.value : "";
            } else if (typeValue === "4") {
              return option ? option?.label : "";
            } else if (typeValue === "5") {
              return option ? option?.name : "";
            } else {
              return option ? option?.name : "";
            }
          }}
          getOptionLabel={(option) => {
            if (typeValue === "1") {
              return option ? option : "";
            } else if (typeValue === "2") {
              return option ? option?.id : "";
            } else if (typeValue === "3") {
              return option ? option?.value : "";
            } else if (typeValue === "5") {
              return option ? option?.name : "";
            } else {
              return option ? option?.name : "";
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              readonly={readonly}
              placeholder={placeholder}
              disableMaskedInput={true}
            />
          )}
          {...orther}
        />
        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}

export default PersonAutocomplete;
