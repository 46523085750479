import { useLocation } from "react-router";
import RightViewLayout from "../../components/RighViewLayout";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomInput from "../../components/CustomInput";
import CustomDatePicker from "../../components/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import AccountInformation from "./components/AccountInformation";
import ContractInformation from "./components/ContractInformation";
import { createSubscription } from "../../services/SubscriptionService";
import { REACT_SUBSCRIPTION } from "../../router/ReactEndPoints";

export default function CreateSubscription() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [selectedPackage, setSelectedPackage] = useState();
  const [subscriptionStartDate, setSubscriptionStartDate] = useState();
  const [subscriptionExpireDate, setSubscriptionExpireDate] = useState();
  const [companyLogo, setCompanyLogo] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    const selectedPackage_ = location.state.selectedPackage;
    setSelectedPackage(selectedPackage_);
  }, []);

  const [payload, setPayload] = useState({
    name: "",
    email: "",
    clientId: "",
    numberOfDevices: "",
    subscriptionStartDate: null,
    subscriptionExpireDate: null,
    contractId: "",
    contractAmount: "",
    subscriptionPackage: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    mobile: "",
    numberOfDevices: "",
    subscriptionStartDate: null,
    subscriptionExpireDate: null,
    contractId: "",
    contractAmount: "",
    subscriptionPackage: "",
  });

  useEffect(() => {
    if (selectedPackage && selectedPackage === "Trial") {
      setPayload({
        ...payload,
        numberOfDevices: 2,
      });
    }
  }, [selectedPackage]);

  useEffect(() => {
    if (subscriptionStartDate && selectedPackage === "Trial") {
      const expiryDate = new Date(subscriptionStartDate);
      expiryDate.setDate(subscriptionStartDate.getDate() + 15);
      setSubscriptionExpireDate(expiryDate);
    }
  }, [subscriptionStartDate]);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
    });
  };

  const handleSelection = (event) => {
    setSelectedPackage(event.target.value);
  };

  const [file, setFile] = useState({});
  const [state, setState] = useState({
    profilePhoto: "",
  });

  const handleImage = (file) => {
    const maxSize = 3 * 1024 * 1024; // 3MB in bytes
    if (
      file.file.type === "image/png" ||
      file.file.type === "image/jpeg" ||
      file.file.type === "image/bmp"
    ) {
      let fileSize = file.file.size;
      let size = parseInt(fileSize);
      if (size <= maxSize) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(",")[1],
        });
        setImage(file?.base64);
        setCompanyLogo(file?.file);
      } else {
        setFile("");
        enqueueSnackbar(t("tips.upload_size"), {
          variant: "error",
        });
      }
    } else {
      setFile("");
      //setProfile(state.profilePhoto);
      enqueueSnackbar(t("Upload Png,Jpeg,Jpg Only"), {
        variant: "error",
      });
    }
    setPayload((prevPayload) => ({
      ...prevPayload,
      productPhoto: file.file.name,
    }));
    // setImage(file?.base64);
    // setProductImage(file?.file);
  };

  const handleSubmit = () => {
    let request = {
      ...payload,
      subscriptionPackage: selectedPackage,
      subscriptionStartDate: subscriptionStartDate,
      subscriptionExpireDate: subscriptionExpireDate,
    };

    createSubscription(request).then((res) => {
      if (res?.data?.code && res?.data?.code === "LVLI0000") {
        enqueueSnackbar(res?.data?.message, { variant: "success" });
      }
    });
  };

  return (
    <RightViewLayout title={"Subscription"} navigateBack={REACT_SUBSCRIPTION}>
      <Card elevation={0}>
        <CardContent>
          <Grid>
            <Typography
              style={{
                font: "normal normal medium 18px/22px Roboto",
                color: "#474B4F",
                opacity: 1,
              }}
            >
              Subscription
            </Typography>
            <Grid container spacing={2} px={2} pt={3}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="selectedPackage"
                  name="selectedPackage"
                  value={selectedPackage}
                  onChange={handleSelection}
                  row
                >
                  <FormControlLabel
                    value="Trial"
                    control={
                      <Radio
                        checked={selectedPackage === "Trial" ? true : false}
                      />
                    }
                    label="Trial"
                  />
                  <FormControlLabel
                    value="Starter"
                    control={
                      <Radio
                        checked={selectedPackage === "Starter" ? true : false}
                      />
                    }
                    label="Starter"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container spacing={2} pt={2}>
              <Grid item md={6} xs={12}>
                <CustomInput
                  required
                  label={t("Number of Devices")}
                  disabled={selectedPackage === "Trial" ? true : false}
                  size="small"
                  name="numberOfDevices"
                  value={payload.numberOfDevices}
                  error={error.numberOfDevices}
                  resetError={() => setError({ ...error, numberOfDevices: "" })}
                  helperText={error.numberOfDevices}
                  inputProps={{
                    maxLength: 60,
                  }}
                  validation="alpha-numeric"
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomDatePicker
                  required
                  size={"small"}
                  disableFuture={false}
                  date={subscriptionStartDate}
                  disabled={false}
                  minDate={new Date()}
                  label={t("Subscription Start Date")}
                  placeholder={t("Select Start Date")}
                  SelectedDate={(e) => setSubscriptionStartDate(e)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomDatePicker
                  required
                  size={"small"}
                  disableFuture={false}
                  date={subscriptionExpireDate}
                  disabled={selectedPackage === "Trial" ? true : false}
                  minDate={new Date()}
                  label={t("Subscription Expire Date")}
                  placeholder={t("Select Exipre Date")}
                  SelectedDate={(e) => setSubscriptionExpireDate(e)}
                />
              </Grid>
            </Grid>
            <AccountInformation
              payload={payload}
              setPayload={setPayload}
              error={error}
              setError={setError}
              image={image}
              handleChange={handleChange}
              handleImage={handleImage}
            />
            <ContractInformation
              payload={payload}
              setPayload={setPayload}
              error={error}
              setError={setError}
              image={image}
              handleChange={handleChange}
              handleImage={handleImage}
            />
          </Grid>
          <Grid container spacing={2} pt={4}>
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Box item pl={2}>
                  <Button
                    id="AddProduct-button-01"
                    variant="contained"
                    size="large"
                    className="text-transform-none"
                    onClick={handleSubmit}
                    style={{
                      size: "medium",
                      borderRadius: "8px",
                      opacity: 1,
                      background:
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    {t("EPLSCN0017")}
                  </Button>
                </Box>
                <Box item>
                  <Button
                    id="AddProduct-button-02"
                    className="text-transform-none"
                    variant="outlined"
                    //onClick={() => navigate(REACT_PRODUCT)}
                    size="large"
                  >
                    {t("LVLRC0017")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </RightViewLayout>
  );
}
