import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
function Serach(props) {
  const {
    value,
    onChange,
    options,
    onClick,
    handlePrev,
    handleNext,
    showIcon,
    handlerClear,
    disablePrev,
    disableNext,
  } = props;
  const { t } = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // 阻止默认行为
      onClick(); // 调用搜索函数
    }
  };

  return (
    <Grid
      lg={3}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Grid>
        <TextField
          label={t("tips.search_by_name")}
          fullWidth
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
          InputProps={{
            style: {
              height: 50,
              width: "250px",
              background: "#fff",
              color: "#474B4F",
              opacity: "0.6",
              boxShadow: "0px 1px 3px #0000001A",
              borderRadius: "8px",
              border: "0px !important",
              padding: "10px",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id="clearButton"
                  onClick={handlerClear}
                  style={{ visibility: value ? "visible" : "hidden" }} // 根据输入框内容控制可见性
                >
                  <ClearIcon />
                </IconButton>
                <IconButton id="retailClint" onClick={onClick}>
                  <search />
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputLabel-root": {
              color: "#D1D1D1",
            },
          }}
        />
      </Grid>

      {showIcon && (
        <Grid display={"flex"} ml={6} mt={1}>
          <IconButton
            disabled={disablePrev}
            onClick={handlePrev}
            sx={{
              background: "#f4f4f4",
              borderRadius: "0",
              "&:hover": {
                background: "#ccc", // 悬停时的背景色
              },
              "&:active": {
                background: "#f4f4f4", // 点击时的背景色
              },
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            disabled={disableNext}
            onClick={handleNext}
            sx={{
              background: "#f4f4f4",
              borderRadius: "0",
              ml: 2,
              "&:hover": {
                background: "#ccc", // 悬停时的背景色
              },
              "&:active": {
                background: "#f4f4f4", // 点击时的背景色
              },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
export default Serach;
