import React from "react";
import SidebarMenuItem from "./SidebarMenuItem";
import SidebarSubMenuItem from "./SidebarSubMenuItem";
import { ReactComponent as ActiveSystemLogs } from "../../assets/images/System_records_icon_1.svg";
import { ReactComponent as InActiveSystemLogs } from "../../assets/images/System_records_icon.svg";

const SidebarMenuItems = (props) => {
  const {
    t,
    path,
    ActiveDashboardMenu,
    InActiveDashboardMenu,
    ActiveUserManagement,
    InActiveUserManagement,
    ActiveRetailClientMenu,
    InActiveRetailClientMenu,
    ActiveDeviceMenu,
    InActiveDeviceMenu,
    ActivePriceMenu,
    InActivePriceMenu,
    ActiveSystemSetting,
    InActiveSystemSetting,
    ActiveApprovalManagement,
    InActiveApprovalManagement,
  } = props;

  const DEVICE_CODE = localStorage.getItem("DEVICE_CODE");
  return (
    <>
      {DEVICE_CODE && DEVICE_CODE === "EPTE0063" ? (
        <SidebarMenuItem
          link="/gateway"
          label={t("menu.device_management")}
          isActive={
            path.includes("/gateway") ||
            path.includes("/screens") ||
            path.includes("/device/add") ||
            path.includes("/deviceView")
          }
          activeMenu={ActiveDeviceMenu}
          inActiveMenu={InActiveDeviceMenu}
        >
          <SidebarSubMenuItem
            link="/gateway"
            label={t("menu.gateway")}
            isActive={
              path.includes("/gateway") ||
              path.includes("/device/add") ||
              path.includes("/deviceView")
            }
          />
        </SidebarMenuItem>
      ) : (
        <>
          <SidebarMenuItem
            link="/dashboard"
            label={t("menu.dashboard")}
            isActive={path.includes("/dashboard")}
            activeMenu={ActiveDashboardMenu}
            inActiveMenu={InActiveDashboardMenu}
          />
          <SidebarMenuItem
            id="SystemSettingSubMenu"
            link="/authorization-level"
            label={t("menu.system_setting")}
            isActive={
              path.includes("/authorization-level") ||
              path.includes("/approval-flowchart") ||
              path.includes("/picture-library") ||
              path.includes("/template") ||
              path.includes("/template/view") ||
              path.includes("/label-product") ||
              path.includes("/template/add") ||
              path.includes("/template/createTemplate")
            }
            activeMenu={ActiveSystemSetting}
            inActiveMenu={InActiveSystemSetting}
          >
            <SidebarSubMenuItem
              link="/authorization-level"
              label={t("menu.authorization_level")}
              isActive={path.includes("/authorization-level")}
            />
            <SidebarSubMenuItem
              link="/approval-flowchart"
              label={t("menu.approval_flowchart")}
              isActive={path.includes("/approval-flowchart")}
            />
            <SidebarSubMenuItem
              link="/label-product"
              label={t("menu.product_information")}
              isActive={path.includes("/label-product")}
            />
            <SidebarSubMenuItem
              link="/picture-library"
              label={t("menu.picture_library")}
              isActive={path.includes("/picture-library")}
            />
            <SidebarSubMenuItem
              link="/template"
              label={t("menu.template_list")}
              isActive={
                path.includes("/template") ||
                path.includes("/template/add") ||
                path.includes("/template/view") ||
                path.includes("/template/createTemplate")
              }
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            link="/outlet"
            label={t("menu.retail_main_data")}
            isActive={path.includes("/outlet") || path.includes("/product")}
            activeMenu={ActiveRetailClientMenu}
            inActiveMenu={InActiveRetailClientMenu}
          >
            <SidebarSubMenuItem
              link="/outlet"
              label={t("menu.outlet")}
              isActive={
                path.includes("/outlet") && !path.includes("/outlet_type")
              }
            />
            <SidebarSubMenuItem
              link="/product"
              label={t("menu.product")}
              isActive={
                path.includes("/product") && !path.includes("/product-label")
              }
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            link="/user"
            label={t("menu.user_management")}
            isActive={path.includes("/user")}
            activeMenu={ActiveUserManagement}
            inActiveMenu={InActiveUserManagement}
          >
            <SidebarSubMenuItem
              link="/user"
              label={t("menu.user")}
              isActive={path.includes("/user")}
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            link="/gateway"
            label={t("menu.device_management")}
            isActive={
              path.includes("/gateway") ||
              path.includes("/screens") ||
              path.includes("/device/add") ||
              path.includes("/deviceView")
            }
            activeMenu={ActiveDeviceMenu}
            inActiveMenu={InActiveDeviceMenu}
          >
            <SidebarSubMenuItem
              link="/gateway"
              label={t("menu.gateway")}
              isActive={
                path.includes("/gateway") ||
                path.includes("/device/add") ||
                path.includes("/deviceView")
              }
            />
            <SidebarSubMenuItem
              link="/screens"
              label={t("menu.screens")}
              isActive={path.includes("/screens")}
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            link="/price-change-event"
            label={t("menu.price_management")}
            isActive={path.includes("/price-change-event")}
            activeMenu={ActivePriceMenu}
            inActiveMenu={InActivePriceMenu}
          >
            <SidebarSubMenuItem
              link="/price-change-event"
              label={t("menu.price_change_events")}
              isActive={path.includes("/price-change-event")}
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            link="/approval/list"
            label={t("menu.approval")}
            isActive={
              path.includes("/approval/list") || path.includes("/approval/log")
            }
            activeMenu={ActiveApprovalManagement}
            inActiveMenu={InActiveApprovalManagement}
          >
            <SidebarSubMenuItem
              link="/approval/list"
              label={t("menu.approval_list")}
              isActive={path.includes("/approval/list")}
            />
            <SidebarSubMenuItem
              link="/approval/log"
              label={t("menu.approval_log")}
              isActive={path.includes("/approval/log")}
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            link="/system-logs"
            label={t("menu.system_records")}
            isActive={path.includes("/system-logs")}
            activeMenu={ActiveSystemLogs}
            inActiveMenu={InActiveSystemLogs}
          >
            <SidebarSubMenuItem
              link="/system-logs"
              label={t("menu.synchronized_logs")}
              isActive={path.includes("/system-logs")}
            />
          </SidebarMenuItem>
        </>
      )}
    </>
  );
};

export default SidebarMenuItems;
