import {
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/L3ePriceLabel.svg";
import { ReactComponent as Stroke } from "../../assets/images/Icon_Stroke.svg";
import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../components/layout-components/Footer";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import { listClients } from "../../services/CompanyService";
import {
  REACT_DASHBOARD,
  REACT_RETAIL_CLIENT_LIST,
} from "../../router/ReactEndPoints";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";
function LandingPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedGrid, setSelectedGrid] = useState("");
  const [retailClient, setRetailClient] = useState("");
  const [records, setRecords] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [storeName, setStoreName] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 500,
    name: "",
    code: "",
    country: "",
    operator: "",
    type: "RETAIL_CLIENT",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
    type: "RETAIL_CLIENT",
  };

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      loadData();
    }
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   loadData();
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [filters]);

  useEffect(() => {
    if (localStorage.getItem("ROLE_NAME") === "OWNER") {
      loadData();
      localStorage.removeItem("RETAIL_CLIENT");
    } else {
      CommonUtil.resetUserSession();
      window.location = "/";
    }
  }, [filters]);

  const loadData = () => {
    listClients(filters).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setRecords(res.data.data.objects);

        // setFilters((prevFilters) => ({
        //   ...prevFilters,
        //   pageNumber: prevFilters.pageNumber + 1,
        // }));
      } else {
        setRecords([]);
      }
    });
  };

  //查询方法
  const handleSubmit = () => {
    let params = {
      ...filters,
      pageSize: 1000,
      name: storeName,
    };
    listClients(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setRecords(res.data.data.objects);

        // setFilters((prevFilters) => ({
        //   ...prevFilters,
        //   pageNumber: prevFilters.pageNumber + 1,
        // }));
      }
    });
  };
  const logout = () => {
    CommonUtil.resetUserSession();
    localStorage.removeItem("DEVICE_CODE");
    window.location = "/";
  };

  const handleGridClick = (gridNumber) => {
    if (selectedGrid === gridNumber) {
      setSelectedGrid(""); // Deselect the grid if it's already selected
      setRetailClient(""); // Clear the selected retail client
      localStorage.removeItem("RETAIL_CLIENT"); // Remove the retail client from localStorage
    } else {
      setSelectedGrid(gridNumber);
      setRetailClient(gridNumber);
      localStorage.setItem("RETAIL_CLIENT", gridNumber);
      navigate(REACT_DASHBOARD);
    }
  };

  const retailClientManagement = () => {
    if (retailClient === "") {
      if (localStorage.getItem("ROLE_NAME") === "") {
        const decoded = jwt_decode(localStorage.getItem("USER_TOKEN"));
        localStorage.setItem("USER", JSON.stringify(decoded));
        const user = JSON.parse(localStorage.getItem("USER"));
        localStorage.setItem("ROLE_NAME", user.roleCode);
      }
      localStorage.setItem("RETAIL_CLIENT", retailClient);
      navigate(REACT_RETAIL_CLIENT_LIST);
    } else {
      navigate(REACT_DASHBOARD);
    }
  };

  // const handleSearchClick = () => {
  //   setFilters({
  //     ...defaultFilters,
  //     name: searchValue,
  //     operator: "AND",
  //   });
  // };

  const renderGridItem = (gridNumber) => (
    <Grid
      style={{
        height: "25px",
        width: "25px",
        border: "1px solid #00A4FF",
        float: "right",
        borderRadius: "0px 16px",
        background: "#00A4FF",
        alignItems: "center",
        justifyContent: "center",
        display: selectedGrid === gridNumber ? "flex" : "none",
      }}
    >
      <Icon size="small" component={Stroke} style={{ width: "14px" }} />
    </Grid>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(); // 调用搜索函数
    }
  };

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Grid item sx={{ width: "100%", height: "auto" }}>
          <Grid item xs={12}>
            <Grid xs={12} item sx={{ mt: "10px", display: "flex" }}>
              <Grid xs={6} pl={3} justifyContent={"center"}>
                <Logo
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    marginTop: "20px",
                  }}
                ></Logo>
              </Grid>
              <Grid
                xs={6}
                pr={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <TextField
                  id="outlined-search"
                  label={t("tips.search_retail_name")}
                  type="search"
                  variant="outlined"
                  value={storeName}
                  onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
                  onChange={(e) => setStoreName(e.target.value)}
                  sx={{ width: "65%", color: "#474B4F", mt: "20px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton id="retailClint" onClick={handleSubmit}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            pt={2}
            pb={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid xs={6} pl={3}>
              <Typography sx={{ fontWeight: "bold", pt: "15px" }}>
                {t("retail_client.select_one_retaile")}
              </Typography>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent="flex-end"
              alignItems="flex-end"
              pr={3}
            >
              <Grid
                item
                pl={2}
                columnSpacing={2}
                display={"flex"}
                justifyContent="flex-end"
              >
                <Button
                  id="LandingBack"
                  className="text-transform-none"
                  variant="outlined"
                  size="medium"
                  style={{
                    height: "55px",
                    width: "300px",
                    background: "#F7FBFE 0% 0% no-repeat padding-box",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    logout();
                  }}
                >
                  {t("menu.back_to_login")}
                </Button>
                <Button
                  id="LandingRetail"
                  variant="contained"
                  size="medium"
                  className="text-transform-none"
                  style={{
                    height: "55px",
                    width: "300px",
                    size: "medium",
                    background:
                      "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    borderRadius: "8px",
                    opacity: 1,
                  }}
                  onClick={retailClientManagement}
                >
                  {t("menu.retail_client_management")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ overflowY: "auto", height: "70%" }}>
          <Grid xs={12} item sx={{ display: "flex" }} pt={5}>
            <Grid
              container
              item
              gap="12px"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              pl={1}
              display={"flex"}
            >
              {records?.map((record, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    md={2}
                    lg={2.9}
                    xl={3.9}
                    style={{
                      width: "350px",
                      height: "131px",
                      border: "1px solid #DDDDDD",
                      borderRadius: "8px",
                      cursor: "pointer",
                      background: selectedGrid === 1 ? "#E2F5FF" : "#FFF",
                      overflow: "hidden", // Hide overflow content
                      position: "relative", // Needed for absolutely positioned element
                    }}
                    onClick={() => handleGridClick(record.id)}
                  >
                    {renderGridItem(record.id)}
                    <Grid container sx={{}}>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Typography
                          style={{
                            height: "22px",
                            font: "normal normal medium 16px/20px Roboto",
                            fontSize: "14px",
                            textAlign: "center",
                            letterSpacing: "0px",
                            color: "#474B4F",
                            marginTop: "35px",

                            transition: "visibility 0s, opacity 0.3s linear", // Smooth transition
                          }}
                          className="hover-text"
                        >
                          {t("retail_client.retail_client_name")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        pl={1}
                      >
                        <Typography
                          style={{
                            height: "22px",
                            font: "normal normal medium 16px/20px Roboto",
                            fontSize: "14px",
                            textAlign: "center",
                            marginTop: "35px",
                            letterSpacing: "0px",
                            color: "#474B4F",
                            fontWeight: "bold",
                            whiteSpace: "nowrap", // Prevent wrapping
                            overflow: "hidden", // Hide overflow content
                            textOverflow: "ellipsis", // Add ellipsis when content overflows
                          }}
                          title={record.name} // Set title attribute for tooltip
                        >
                          {record.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        pr={0.5}
                      >
                        <Typography
                          style={{
                            height: "22px",
                            font: "normal normal medium 16px/20px Roboto",
                            fontSize: "14px",
                            textAlign: "center",
                            letterSpacing: "0px",
                            color: "#474B4F",
                          }}
                        >
                          {t("retail_client.retail_client_code")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        pl={1}
                      >
                        <Typography
                          style={{
                            height: "22px",
                            font: "normal normal medium 16px/20px Roboto",
                            fontSize: "14px",
                            textAlign: "center",
                            letterSpacing: "0px",
                            color: "#474B4F",
                            fontWeight: "bold",
                            whiteSpace: "nowrap", // Prevent wrapping
                            overflow: "hidden", // Hide overflow content
                            textOverflow: "ellipsis", // Add ellipsis when content overflows
                          }}
                          title={record.code} // Set title attribute for tooltip
                        >
                          {record.code}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ bottom: "1px", minWidth: "100%", position: "fixed" }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
export default LandingPage;
