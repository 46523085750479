import { Avatar, Box, Button, Card, Grid } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PERSON_LIST, RETAIL_CLIENT } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ProfilePic from "../../assets/images/ProfilePic.svg";
import ProfileUplod from "../../components/UploadImg";
import CommonUtil from "../../util/CommonUtils";
import { createPerson } from "../../services/PersonService";
import { useSnackbar } from "notistack";
import axios from "axios";

export default function AddPerson() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState(ProfilePic);
  const [profileImage, setProfileImage] = useState();
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: "",
    clientId: "",
    localRoleId: "",
    outletIds: [],
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const passwordInfo = t("tips.password_title");

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: `${t("tips.required")}`,
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.password)) {
      setError({
        ...error,
        password: `${t("tips.required")}`,
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.confirmPassword)) {
      setError({
        ...error,
        confirmPassword: `${t("tips.required")}`,
      });
      return false;
    }

    if (payload.password !== payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t("EPLP0002"),
      });
      return false;
    }

    if (!CommonUtil.isEmptyString(payload.password)) {
      if (!CommonUtil.isValidPassword(payload.password)) {
        setError({
          ...error,
          password: passwordInfo,
        });
        return false;
      }
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: `${t("tips.required")}`,
      });
      return;
    } else {
      if (!CommonUtil.isValidEmail(payload.email)) {
        setError({
          ...error,
          email: `${t("tips.invalid_email_address")}`,
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        email: payload.email.toLowerCase(),
        profilePhoto: "profilePhoto",
        clientId: params.id,
      };
      createPerson(request).then((res) => {
        if (res.data.data && res.data.code === "LVLI0008") {
          enqueueSnackbar(t("tips_user.person_created"), {
            variant: "success",
          });
          if (
            !CommonUtil.isEmpty(res.data.data.imagePreSignedURL) &&
            profileImage
          ) {
            uploadProfilePhoto(res);
          }
          if (window.location.pathname.includes("/retail-client"))
            navigate(
              RETAIL_CLIENT + params.id + "/" + params.code + REACT_PERSON_LIST
            );
          if (window.location.pathname.includes("/principal"))
            navigate(
              "/" +
                "principal" +
                "/" +
                params.id +
                "/" +
                params.code +
                REACT_PERSON_LIST
            );
          return;
        }

        switch (res?.data?.code) {
          default:
            //enqueueSnackbar(res.data.message, { variant: "error" });"tips_user.user_exists"
            enqueueSnackbar(t("tips_user.user_exists"), { variant: "error" });
            return;
        }
      });
    }
  };

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append("url", data?.data?.data?.imagePreSignedURL);
    bodyFormData.append("file", profileImage);

    axios({
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL + "/web/person/profile",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
      },
    });
  };

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: "profilePhoto",
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };

  return (
    <RightViewLayout
      id="addpersonback"
      title={t("user.create_person")}
      navigateBack={
        window.location.pathname.includes("/retail-client")
          ? RETAIL_CLIENT + params.id + "/" + params.code + REACT_PERSON_LIST
          : "/" +
            "principal" +
            "/" +
            params.id +
            "/" +
            params.code +
            REACT_PERSON_LIST
      }
    >
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0} sx={{ height: "100%" }}>
          <Grid container px={2}>
            <Grid
              item
              xs={12}
              md={4}
              container
              display={"flex"}
              justifyContent={"center"}
              pt={2}
            >
              <ProfileUplod
                imageInfo={t("tips_user.allowed_file_types")}
                imageSize={t("tips_user.max_file_size")}
                style={{ borderStyle: "none !important" }}
                onsetProfile={(profile) => handleProfile(profile)}
                profilePhoto={profile}
              />
            </Grid>
            <Grid container xs={12} md={8} item spacing={2}>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson1"
                  required
                  label={t("table.first_name")}
                  size="small"
                  name="firstName"
                  error={error.firstName}
                  resetError={() => setError({ ...error, firstName: "" })}
                  value={payload.firstName}
                  handleChange={handleChange}
                  inputProps={{
                    maxLength: 60,
                  }}
                  helperText={error.firstName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson2"
                  label={t("table.last_name")}
                  size="small"
                  name="lastName"
                  error={error.lastName}
                  resetError={() => setError({ ...error, lastName: "" })}
                  value={payload.lastName}
                  handleChange={handleChange}
                  inputProps={{
                    maxLength: 60,
                  }}
                  helperText={error.lastName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson3"
                  required
                  label={t("EPLP0000")}
                  type="password"
                  size="small"
                  name="password"
                  value={payload.password}
                  error={error.password}
                  resetError={() => setError({ ...error, password: "" })}
                  helperText={error.password}
                  inputProps={{
                    maxLength: 20,
                  }}
                  validation={"password"}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomInput
                  id="AddPerson4"
                  required
                  label={t("EPLP0001")}
                  size="small"
                  name="confirmPassword"
                  value={payload.confirmPassword}
                  error={error.confirmPassword}
                  resetError={() => setError({ ...error, confirmPassword: "" })}
                  helperText={error.confirmPassword}
                  inputProps={{
                    maxLength: 20,
                  }}
                  validation={"password"}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  id="AddPerson5"
                  required
                  label={t("table.email")}
                  size="small"
                  name="email"
                  value={payload.email}
                  error={error.email}
                  resetError={() => setError({ ...error, email: "" })}
                  helperText={error.email}
                  inputProps={{
                    maxLength: 36,
                  }}
                  validation="email"
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Box item pl={2}>
                    <Button
                      id="addpersonsubmit"
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      onClick={handleSubmit}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item>
                    <Button
                      id="addpersonnext"
                      className="text-transform-none"
                      variant="outlined"
                      onClick={() => {
                        if (window.location.pathname.includes("/retail-client"))
                          navigate(
                            RETAIL_CLIENT +
                              params.id +
                              "/" +
                              params.code +
                              REACT_PERSON_LIST
                          );
                        if (window.location.pathname.includes("/principal"))
                          navigate(
                            "/" +
                              "principal" +
                              "/" +
                              params.id +
                              "/" +
                              params.code +
                              REACT_PERSON_LIST
                          );
                      }}
                      size="large"
                    >
                      {t("common.back")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
