import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography,Slider } from "@mui/material";

export const AntTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'none'
    },
});

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    width: '50%',
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: '#707070',
    padding: '0px',
    marginRight: '0px',
    backgroundColor: "#E3E3E3",
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#707070',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#78BC27',
        backgroundColor: "#ffffff",
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));



export const FormLabel = styled(Typography)({
    color: '#707070',
    fontSize: '14px',
    whiteSpace:'nowrap'
});


export const PrettoSlider = styled(Slider)({
    color: '#5a9c2f',
    height: 2,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        backgroundColor: '#fff',
        border: '2px solid #5a9c2f',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
        '&::after': {
            height: '10px',
            width: '10px'
        },
    }
});