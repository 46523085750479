import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import IconHandaler from "../../components/IconHandaler";
import DataTable from "../../components/DataTableScreens";
import { filterScreens } from "../../services/ScreenService";
import SearchIcon from "@mui/icons-material/Search";
import CommonUtil from "../../util/CommonUtils";
import {
  REACT_DEVICE_EDIT_SCREENS,
  REACT_PRODUCT_BIND_TO_SCREEN,
} from "../../router/ReactEndPoints";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BindIcon } from "../../assets/images/bind_icon.svg";
import { useSnackbar } from "notistack";
import { getDownloadUrl } from "../../services/TemplateService";
import RightViewLayout from "../../components/RighViewLayout";
import { ReactComponent as RefreshIcon } from "../../assets/images/icon_refresh.svg";
import PreView from "../editor/PreView";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";

export default function Screen() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);

  const [allSelectedRows, setAllSelectedRows] = useState([]);

  const [records, setRecords] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [products, setProducts] = useState([]);
  const [preViewOpen, setPreViewOpen] = useState(false);
  const [preViewItem, setPreViewItem] = useState(null);
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );

  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  let isAllowedBindScreen = true;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
    companyId: "",
    deviceId: "",
    country: "",
    state: "",
    city: "",
    cityZone: "",
    ids: [],
    clientId: "",
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("SelectedClientId")) ||
      localStorage.getItem("SelectedClientId") === "-1"
        ? []
        : [localStorage.getItem("SelectedClientId")],
  });

  const [defaultFilters, setDefaultFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
    companyId: "",
    deviceId: "",
    country: "",
    state: "",
    city: "",
    cityZone: "",
    outletType: "",
    ids: [],
    clientId: "",
    companyIds: [],
  });

  const getStatus = (data) => {
    return data === "1" ? "online" : "offline";
  };

  useEffect(() => {
    localStorage.removeItem("resolution");
  }, []);

  useEffect(() => {
    if (allSelectedRows.length < 1) localStorage.removeItem("resolution");
  }, [allSelectedRows]);

  useEffect(() => {
    // getAllProducts().then((res) => {
    //   if (res.data?.code === "LVLI0000") {
    //     setProducts(res.data.data);
    //   }
    // });

    loadData();
  }, [filters]);

  const loadData = () => {
    filterScreens(filters).then((res) => {
      if (res?.data?.code === "DMSI0000" && res?.data?.data) {
        setRecords(res?.data?.data?.screens);
        setTotalRecords(res?.data?.data?.totalCount);
        setRowsPerPage(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
      } else {
        console.log("No Records Found");
        setRecords([]);
      }
    });
  };

  const getProductName = (id) => {
    if (id) {
      const product = products?.find((product) => product.id === id);
      return product ? product?.name : "";
    }
  };

  const getPicPreview = (key) => {
    if (key) {
      getDownloadUrl(key.row.templateObjectKey).then((res) => {
        if (res?.data?.data && res?.data?.code === "LVLI0000") {
          setHtmlContent("");
          let templateObj = [];
          records.map((e) => {
            let templateData = e;
            if (e.templateObjectKey === res.data.data.objectKey) {
              templateData.preSignedUrl = res.data.data.downloadUrl;
            }
            templateObj.push(templateData);
          });
          setRecords(templateObj);
          popUp(res.data.data.downloadUrl);
        }
      });
    } else {
      enqueueSnackbar("Unbound templates.", {
        variant: "info",
      });
    }
  };

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const popUp = (urls) => {
    setOpen(true);
    setUrl(urls);
    downloadAndDisplayFile(urls);
  };

  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");

  const downloadAndDisplayFile = async (urls) => {
    try {
      const response = await fetch(urls);
      const htmlContent = await response.text();
      setHtmlContent(htmlContent);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateBatteryStatus = (batteryState) => {
    if (batteryState === undefined || batteryState === null) {
      return "";
    }
    const v = batteryState / 1000;
    const vs = Math.floor(batteryState / 100) / 10;
    if (v >= 2.3) {
      return t("screen.normal") + "(" + vs + "V)";
    } else {
      return t("screen.lowPower") + "(" + vs + "V)";
    }
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddScreen");
    isAllowedView = permission && permission.includes("ViewScreen");
    isAllowedEdit = permission && permission.includes("EditScreen");
    isAllowedDelete = permission && permission.includes("DeleteScreen");
    isAllowedBindScreen = permission && permission.includes("BindingScreen");
  }

  const getFormattedDate = (date) => {
    const formattedDate = date ? moment(date).format(dateTimeFormat) : "";
    return formattedDate;
  };

  const columns = [
    {
      field: "id",
      headerName: t("screen.screenId"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.id,
    },
    {
      field: "resolution",
      headerName: t("screen.screen_resolution"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.resolution,
    },
    {
      field: "positionNO",
      headerName: t("screen.positionNo"),
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.positionNo,
    },
    {
      field: "screenName",
      headerName: t("screen.screenName"),
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.screenName,
    },
    {
      field: "powerStatus",
      headerName: t("screen.powerStatus"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => calculateBatteryStatus(e.row.battery),
    },
    {
      field: "gateway",
      headerName: t("screen.gateway"),
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.sn,
    },
    {
      field: "outletName",
      headerName: t("screen.outlet"),
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.outletName,
    },
    {
      field: "productId",
      headerName: t("screen.bindToProduct"),
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.productName,
    },
    {
      field: "templateId",
      headerName: t("screen.templatePreview"),
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip title={t("common.preview")} arrow>
            <Button
              id="temppreview"
              sx={{ px: 0 }}
              onClick={() => {
                let templateJson = e.row.templateJson;

                if (templateJson) {
                  setPreViewOpen(true);
                  setPreViewItem(e.row);
                } else {
                  // getPicPreview(e);
                  enqueueSnackbar("Unbound templates.", {
                    variant: "info",
                  });
                }
              }}
            >
              {t("common.preview")}
            </Button>
          </Tooltip>
        </>
      ),
    },

    {
      field: "screenStatus",
      headerName: t("screen.screenStatus"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={getStatus(e.row.status)} arrow placement="bottom">
          {getStatus(e.row.status) === "online" ? (
            <span style={{ color: "green" }}>
              {CommonUtil.formatLongText(getStatus(e.row.status))}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {CommonUtil.formatLongText(getStatus(e.row.status))}
            </span>
          )}
        </Tooltip>
      ),
    },
    {
      field: "screenUpdateTime",
      headerName: t("screen.screenUpdateTime"),
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip
            title={getFormattedDate(e.row.updatedAt)}
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(getFormattedDate(e.row.updatedAt))}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      headerName: t("common.actions"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedEdit && (
            <Tooltip title={t("common.edit")} arrow sx={{ marginLeft: 0.5 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          )}

          {e.row.status === "1" && isAllowedBindScreen && (
            <Tooltip
              title={t("screen.bindData")}
              arrow
              sx={{ marginLeft: 0.5 }}
            >
              <BindIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                }}
                onClick={() => handleActions("Bind", e)}
              />
            </Tooltip>
          )}
        </IconHandaler>
      ),
    },
  ];

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      id: searchValue,
      operator: "AND",
    });
  };

  const handlePageChange = (e) => {
    setAllSelectedRows(removeDuplicate([...allSelectedRows, ...selectedRows]));
    setFilters({ ...filters, pageNumber: e + 1, id: searchValue });
    setPage(e + 1);
  };

  const removeDuplicate = (arr) => {
    let key = [];
    let temp = arr.filter((item, index) => {
      let id = Object.keys(item)[0];
      if (key.indexOf(id) < 0) {
        key.push(id);
        return true;
      } else {
        return false;
      }
    });
    return temp;
  };

  const handlePageSize = (e) => {
    setAllSelectedRows(removeDuplicate([...allSelectedRows, ...selectedRows]));
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
      id: searchValue,
    });
    setRowsPerPage(e);
  };

  const handleSelection = (ids) => {
    const updatedSelectedRows = [];
    const selectedResolution = [];
    let pageIds = records.map((item) => {
      return item.id;
    });
    let newTemp = allSelectedRows.filter((item) => {
      let id = Object.keys(item)[0];
      if (pageIds.indexOf(id) < 0) {
        return true;
      } else {
        return false;
      }
    });
    ids.forEach((id) => {
      const record = records.find((row) => row.id === id);
      if (record) {
        updatedSelectedRows.push({ [id]: record.resolution });

        selectedResolution.push(record.resolution);
        localStorage.setItem("resolution", record.resolution);
      }
    });

    if (selectedResolution.length > 1) {
      const resolution = selectedResolution[0];
      const mismatchedResolution = selectedResolution.some(
        (e) => e !== resolution
      );
      if (mismatchedResolution) {
        updatedSelectedRows.pop();
        enqueueSnackbar("Please select same resolution screen", {
          variant: "error",
        });
        return;
      }
    }

    let temp = removeDuplicate([...newTemp, ...updatedSelectedRows]);
    setAllSelectedRows(temp);
    setSelectedRows(updatedSelectedRows);
  };

  const refreshLoadData = () => {
    setSearchValue("");
    setSelectedRows([]);
    setAllSelectedRows([]);
    setFilters({ ...defaultFilters });
  };

  const toolbarProps = {
    //add: isAllowedCreate,
    add: false,
    filter: false,
    refresh: true,

    onAdd: (data) => {},
    onRefresh: (data) => {
      setFilters({
        ...defaultFilters,
        pageNumber: 1,
      });
      setSearchValue("");
    },
  };
  const navigate = useNavigate();
  const handleActions = (action, data) => {
    if (action === "Edit") {
      const id = data.row.id;
      const deviceId = data.row.deviceId;
      navigate(REACT_DEVICE_EDIT_SCREENS, {
        state: { id: id, deviceId: deviceId },
      });
    }

    if (action === "Bind") {
      calculateBatteryStatus(data.row.battery);
      if (calculateBatteryStatus(data.row.battery) === "0%") {
        enqueueSnackbar("Screen battery is 0%", { variant: "error" });
        return;
      }
      handleDataBind(data.row.id);
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(); // 调用搜索函数
    }
  };

  const handlerClear = () => {
    setSearchValue("");
    setFilters({
      ...defaultFilters,
      name: "",
    });
  };

  const searchScreenProps = () => {
    return (
      <TextField
        label={t("tips_screens.searchby_screenId")}
        value={searchValue}
        onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          style: {
            height: 50,
            width: "300px",
            background: "#fff",
            color: "#474B4F",
            opacity: "0.6",
            boxShadow: "0px 1px 3px #0000001A",
            borderRadius: "8px",
            border: "0px !important",
            padding: "10px",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                id="clearButton"
                onClick={handlerClear}
                style={{ visibility: searchValue ? "visible" : "hidden" }} // 根据输入框内容控制可见性
              >
                <ClearIcon />
              </IconButton>
              <IconButton id="ScreenBtn1" onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiInputLabel-root": {
            color: "#D1D1D1",
            cursor: "pointer",
          },
          cursor: "pointer",
        }}
      />
    );
  };

  const handleDataBind = (id) => {
    const updatedSelectedRows = [];
    const record = records.find((row) => row.id === id);
    updatedSelectedRows.push({ [id]: record.resolution });
    localStorage.setItem("screenIds", JSON.stringify(updatedSelectedRows));
    console.log(selectedRows);
    navigate(REACT_PRODUCT_BIND_TO_SCREEN, {
      state: id,
    });
  };

  const handleBinds = () => {
    localStorage.setItem("screenIds", JSON.stringify(allSelectedRows));

    if (CommonUtil.isEmpty(allSelectedRows)) {
      enqueueSnackbar(t("tips_screens.select_one_screen"), {
        variant: "error",
      });
      return;
    }
    navigate(REACT_PRODUCT_BIND_TO_SCREEN);
  };

  const getRowId = (screen) => screen.id;

  return (
    <>
      <RightViewLayout
        title={t("menu.screens")}
        navigateBack={false}
        toolbarProps={toolbarProps}
        globalFilterProps={false}
        searchProps={searchScreenProps()}
      >
        <Grid
          container
          direction={"row"}
          style={{ display: "flex", justifyContent: "flex-end" }}
          p={1}
        >
          <Tooltip title={t("common.refresh")}>
            <Grid
              id="Product-button-05"
              style={{
                height: "35px",
                width: "35px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                marginRight: 12,
                marginTop: "2px",
              }}
            >
              <RefreshIcon onClick={() => refreshLoadData()} />
            </Grid>
          </Tooltip>

          {isAllowedBindScreen && (
            <Tooltip title={t("screen.bindData")}>
              <Grid
                id="Product-button-06"
                style={{
                  height: "35px",
                  width: "35px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  background: "#fff",
                  marginLeft: 2,
                }}
              >
                <BindIcon sx={{ color: "white" }} onClick={handleBinds} />
              </Grid>
            </Tooltip>
          )}
        </Grid>

        <DataTable
          columns={columns}
          rows={records}
          getRowId={getRowId}
          page={filters.pageNumber - 1}
          totalRecords={totalRecords}
          rowsPerPage={filters.pageSize}
          onSelection={(id) => handleSelection(id)}
          rowSelectionModel={allSelectedRows.map((item) => {
            return Object.keys(item)[0];
          })}
          isRowSelectable={(params) => {
            return params.row.status === "1"; // 只有 status 为 "1" 的行可选
          }}
          onPageChange={(pn) => handlePageChange(pn)}
          onPageSizeChange={(ps) => handlePageSize(ps)}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
        />

        <Dialog
          open={preViewOpen}
          onClose={() => {
            setPreViewItem(null);
            setPreViewOpen(false);
          }}
          maxWidth={"md"}
          style={{ backdropFilter: "blur(5px)" }}
        >
          {preViewItem?.templateJson && preViewOpen && (
            <PreView
              layoutJSON={JSON.parse(preViewItem.templateJson)}
            ></PreView>
          )}
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          style={{ backdropFilter: "blur(5px)" }}
        >
          <DialogContent>
            <ImagePopUp />
          </DialogContent>
        </Dialog>
      </RightViewLayout>
    </>
  );
}
