import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/Plus_icon.svg";
import {
  Button,
  Grid,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import IconHandaler from "../../components/IconHandaler";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import ListLayout from "../../components/ListLayout";
import { useNavigate } from "react-router-dom";
import {
  REACT_PRICE_CHANGE_EVENT,
  REACT_PRICE_CHANGE_EVENT_ADD,
  REACT_PRICE_CHANGE_EVENT_UPDATE,
  REACT_PRICE_CHANGE_EVENT_VIEW,
} from "../../router/ReactEndPoints";
import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  deletePriceChangeEvent,
  getPriceChangeEvents,
} from "../../services/PriceChangeEventservice";
import { getAllProducts } from "../../services/ProductService";
import { getTemplates } from "../../services/TemplateService";
import { useSnackbar } from "notistack";
import { priceChangeRulesList } from "../../services/PriceManagement";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";
import dayjs from "dayjs";

export default function PriceChangeEvent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [templates, setTemplates] = useState([]);
  const [changeRules, setChangeRules] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
  };

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };
  const loadData = () => {
    getPriceChangeEvents(filters).then((res) => {
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.products
      ) {
        setRecords(res?.data?.data?.products);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const listFilter = {
    pageNumber: 0,
    pageSize: 10,
  };

  const loadTemplates = () => {
    getTemplates().then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setTemplates(res?.data?.data?.templateData);
      }
    });
  };

  const loadDataChangeRules = () => {
    priceChangeRulesList(listFilter).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setChangeRules(res?.data?.data?.priceChangeRules);
      }
    });
  };

  useEffect(() => {
    loadTemplates();
    loadDataChangeRules();
  }, []);

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };
  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddPriceChangeEvent");
    isAllowedView = permission && permission.includes("ViewPriceChangeEvent");
    isAllowedEdit = permission && permission.includes("EditPriceChangeEvent");
    isAllowedDelete =
      permission && permission.includes("DeletePriceChangeEvent");
  }
  const approvalStatus = (status) => {
    if (status) {
      if (status === "Approved") {
        return <Typography color={"green"}>{status}</Typography>;
      }
      if (status === "Rejected") {
        return <Typography color={"red"}>{status}</Typography>;
      }
      if (status === "Deleted") {
        return <Typography color={"#ebba34"}>{status}</Typography>;
      } else {
        return <Typography color={"gray"}>{"Pending"}</Typography>;
      }
    }
  };

  const columns = [
    {
      field: "name",
      headerName: `${t("events.priceChangeName")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "product",
      headerName: `${t("table_product.product_name")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.productName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.productName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "promotionType",
      headerName: `${t("events.priceChangeRule")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.promotionType} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.promotionType)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "templateId",
      headerName: `${t("events.templateName")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e?.row?.templateName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.templateName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "startAt",
      headerName: `${t("events.scheduleTime")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip
          title={dayjs(e.row.startAt).format(dateTimeFormat)}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              dayjs(e.row.startAt).format(dateTimeFormat)
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "approvalStatus",
      headerName: `${t("events.approvalStatus")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.approvalStatus} arrow placement="bottom">
            <span>
              {CommonUtil.formatLongText(approvalStatus(e.row.approvalStatus))}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      headerName: `${t("common.actions")}`,
      headerAlign: "center",
      align: "center",
      headerAlign: "center",
      align: "center",
      renderCell: (e) => {
        return (
          <IconHandaler>
            {isAllowedView && (
              <Tooltip
                title={t("common.view")}
                arrow
                id="PriceChangeEvent-tooltip-1"
              >
                <VisibilityIcon
                  onClick={() => handleActions("View", e.row.id)}
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    cursor: "pointer",
                    opacity: "0.6",
                    height: "17px",
                    width: "20px",
                    padding: "2px",
                  }}
                />
              </Tooltip>
            )}
            {isAllowedEdit && (
              <Tooltip
                title={t("common.edit")}
                sx={{ marginLeft: 1 }}
                id="PriceChangeEvent-tooltip-2"
              >
                <EditIcon
                  onClick={() => handleActions("Edit", e.row)}
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    cursor: "pointer",
                    opacity: "0.6",
                    height: "17px",
                    width: "20px",
                    padding: "2px",
                  }}
                />
              </Tooltip>
            )}
            {isAllowedDelete && (
              <Tooltip
                title={t("common.delete")}
                sx={{ marginLeft: 1 }}
                id="PriceChangeEvent-tooltip-3"
              >
                <DeleteIcon
                  onClick={() => handleActions("Delete", e.row.id)}
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    cursor: "pointer",
                    opacity: "0.6",
                    height: "17px",
                    width: "20px",
                    padding: "2px",
                  }}
                />
              </Tooltip>
            )}
          </IconHandaler>
        );
      },
    },
  ];

  const handleActions = (action, data) => {
    if (action === "View") {
      navigate(REACT_PRICE_CHANGE_EVENT_VIEW + data);
    }
    if (action === "Edit") {
      localStorage.setItem("event", JSON.stringify(data));
      navigate(REACT_PRICE_CHANGE_EVENT_ADD, {
        state: { ...data, action: action },
      });
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const handleDelete = (id) => {
    deletePriceChangeEvent(id).then((res) => {
      if (res?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("tips_events.deleted"), { variant: "success" });
        setConfirm(false);
        loadData();
        navigate(REACT_PRICE_CHANGE_EVENT);
      } else {
        enqueueSnackbar(t(res?.data?.message), { variant: "error" });
        setConfirm(false);
      }
    });
  };

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const toolbarProps = {
    add: isAllowedCreate,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_PRICE_CHANGE_EVENT_ADD);
    },
    onRefresh: (data) => {
      loadData();
    },
    // onFilter: (data) => {
    //   console.log("onFilter");
    // },
  };

  return (
    <ListLayout
      title={t("events.price_Change_Events")}
      toolbarProps={toolbarProps}
      navigateBack={false}
    >
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
        onSelection={() => console.log("")}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              {t("common.cancel")}{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => handleDelete(id)}
            >
              {" "}
              {t("common.delete")}{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
