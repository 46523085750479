import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip,
} from "@mui/material";
import DefaultOrgPic from "../../assets/images/default_org_pic.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { REACT_VIEW_COMPANY_PROFILE } from "../../router/ReactEndPoints";
import CommonUtil from "../../util/CommonUtils";
import { t } from "i18next";
import PriceStrategy from "../../pages/subscription/PriceStrategy";
import About from "../../pages/subscription/About";
export default function CompanyProfileMenu() {
  const navigate = useNavigate();
  const handleView = () => {
    navigate(REACT_VIEW_COMPANY_PROFILE);
  };

  const user = CommonUtil.decodeToken();
  const loginType = CommonUtil.getLoginType();
  const ROLE_ID = localStorage.getItem("ROLE_NAME");
  const SELECTED_CLIENT = localStorage.getItem("RETAIL_CLIENT");

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const [open, setOpen] = useState(false);
  const popUp = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        style={{ cursor: "pointer" }}
        {...bindTrigger(popupState)}
        sx={{ pl: { xs: 0, md: 2 }, pr: 0, my: { xs: 0, md: 1 } }}
      >
        <Box>
          <Avatar src={DefaultOrgPic} sx={{ width: 24, height: 24 }} />
        </Box>
        <Box flexGrow={1} sx={{ display: { md: "flex" }, pl: 2 }}>
          <Tooltip title={user?.companyName} arrow placement="bottom">
            <Typography variant="menuItem">
              {CommonUtil.formatLongText(user?.companyName?.slice(0, 20))}
            </Typography>
          </Tooltip>
        </Box>
        <Box py={0.5} px={2} sx={{ display: { md: "flex" } }}>
          <Typography variant="menuItem">
            {popupState.isOpen ? (
              <KeyboardArrowLeftIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </Typography>
        </Box>
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem
          id="CompanyProfileMenu-menuitem-1"
          onClick={() => {
            popupState.close();
            handleView();
          }}
        >
          {t("menu.view_company")}
        </MenuItem>
        {(ROLE_ID !== "OWNER" || SELECTED_CLIENT !== "") && (
          <MenuItem
            id="CompanyProfileMenu-menuitem-2"
            onClick={() => {
              popupState.close();
              popUp();
            }}
          >
            {t("menu.about")}
          </MenuItem>
        )}
      </Popover>

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle mt={2}>
          {t("menu.about")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <About />
        </DialogContent>
      </Dialog>
    </div>
  );
}
