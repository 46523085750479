import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import {
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { ReactComponent as CheckedCircle } from "../../assets/images/Subtraction.svg";
import { useState } from "react";
import { useNavigate } from "react-router";
import { REACT_SUBSCRIPTION_CREATE } from "../../router/ReactEndPoints";
import { useSnackbar } from "notistack";

export default function PackageSelector() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState();

  const handleSelection = (value) => {
    setSelectedPackage(value);
  };

  const handleSubmit = () => {
    if (!selectedPackage) {
      enqueueSnackbar("Please Select one Package!", { variant: "error" });
      return;
    }
    navigate(REACT_SUBSCRIPTION_CREATE, {
      state: { selectedPackage: selectedPackage },
    });
  };

  return (
    <RightViewLayout title={"Subscription"}>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container md={6}>
              <Grid
                item
                container
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  position: "relative",
                  padding: 2,
                  boxShadow:
                    selectedPackage === "Trial"
                      ? "0 8px 10px rgba(0, 0, 0, 0.1)"
                      : "none",
                  borderRadius: 2,
                  border: selectedPackage !== "Trial" && "1px solid #ECECED",
                  overflow: "hidden",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 2,
                    padding: "1px",
                    background:
                      selectedPackage === "Trial" &&
                      "linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                    WebkitMask:
                      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "destination-out",
                    maskComposite: "exclude",
                  },
                }}
                onClick={() => handleSelection("Trial")}
              >
                <Grid item container mb={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography
                      style={{
                        font: "normal normal medium 24px/29px Roboto",
                        color: "#474B4F",
                        opacity: 1,
                      }}
                    >
                      Trial
                    </Typography>
                    <Typography
                      style={{ font: "normal normal bold 54px/65px Roboto" }}
                    >
                      Free
                    </Typography>
                    <Typography
                      style={{
                        font: "normal normal normal 14px/19px Roboto",
                        color: "#474B4F",
                      }}
                    >
                      2 devices for 15 days
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-start"}
                  >
                    {selectedPackage === "Trial" && <CheckedCircle />}
                  </Grid>
                </Grid>
                <Divider orientation="horizontal" flexItem />
                <Grid container columnSpacing={2} padding={0} mt={2}>
                  <Grid item md={6}>
                    {[
                      "Outlet Management",
                      "Device Management",
                      "Product Management",
                      "Template Management",
                    ].map((label) => (
                      <Grid item container alignItems="center" key={label}>
                        <Grid item>
                          <Checkbox
                            checked={true}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              font: "normal normal medium 16px/21px Roboto",
                              color: "#474B4F",
                              opacity: 1,
                            }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    {[
                      "Multi Account Management",
                      "Dashboard",
                      "Price Management",
                      "Approval",
                    ].map((label) => (
                      <Grid item container alignItems="center" key={label}>
                        <Grid item>
                          <Checkbox
                            checked={true}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              font: "normal normal medium 16px/21px Roboto",
                              color: "#474B4F",
                              opacity: 1,
                            }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"flex-end"}
                  m={1}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button
                    variant={
                      selectedPackage === "Trial" ? "contained" : "outlined"
                    }
                    size="large"
                    className="text-transform-none"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (selectedPackage === "Trial") {
                        handleSubmit();
                      }
                    }}
                    style={{
                      size: "medium",
                      borderRadius: "8px",
                      opacity: 1,
                      background:
                        selectedPackage === "Trial" &&
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    Get Started
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={6}>
              <Grid
                item
                container
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  position: "relative",
                  padding: 2,
                  border: selectedPackage !== "Starter" && "1px solid #ECECED",
                  boxShadow:
                    selectedPackage === "Starter"
                      ? "0 8px 10px rgba(0, 0, 0, 0.1)"
                      : "none",
                  borderRadius: 2,
                  overflow: "hidden",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 2,
                    padding: "1px",
                    background:
                      selectedPackage === "Starter" &&
                      "linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                    WebkitMask:
                      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "destination-out",
                    maskComposite: "exclude",
                  },
                }}
                onClick={() => handleSelection("Starter")}
              >
                <Grid item container mb={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography
                      style={{
                        font: "normal normal medium 24px/29px Roboto",
                        color: "#474B4F",
                        opacity: 1,
                      }}
                    >
                      Starter
                    </Typography>
                    <Typography
                      style={{ font: "normal normal bold 54px/65px Roboto" }}
                    >
                      US$0
                      <span
                        style={{
                          font: "normal normal normal 14px/19px Roboto",
                          color: "#474B4F",
                        }}
                      >
                        {" "}
                        per Device/Month
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        font: "normal normal normal 14px/19px Roboto",
                        color: "#474B4F",
                      }}
                    >
                      billed annually. US$0 billed monthly
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-start"}
                  >
                    {selectedPackage === "Starter" && <CheckedCircle />}
                  </Grid>
                </Grid>
                <Divider orientation="horizontal" flexItem />
                <Grid container columnSpacing={2} mt={2}>
                  <Grid item md={6}>
                    {[
                      "Outlet Management",
                      "Device Management",
                      "Product Management",
                      "Template Management",
                    ].map((label) => (
                      <Grid item container alignItems="center" key={label}>
                        <Grid item>
                          <Checkbox
                            checked={true}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              font: "normal normal medium 16px/21px Roboto",
                              color: "#474B4F",
                              opacity: 1,
                            }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    {[
                      "Multi Account Management",
                      "Dashboard",
                      "Price Management",
                      "Approval",
                    ].map((label) => (
                      <Grid item container alignItems="center" key={label}>
                        <Grid item>
                          <Checkbox
                            checked={true}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              font: "normal normal medium 16px/21px Roboto",
                              color: "#474B4F",
                              opacity: 1,
                            }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"flex-end"}
                  m={1}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button
                    variant={
                      selectedPackage === "Starter" ? "contained" : "outlined"
                    }
                    size="large"
                    className="text-transform-none"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (selectedPackage === "Starter") {
                        handleSubmit();
                      }
                    }}
                    style={{
                      size: "medium",
                      borderRadius: "8px",
                      opacity: 1,
                      background:
                        selectedPackage === "Starter" &&
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    Get Started
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </RightViewLayout>
  );
}
