import { useEffect, useRef, useState } from "react";
import ListLayout from "../../components/ListLayout";
import { deleteRole, filterRole } from "../../services/UserManagementService";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import IconHandaler from "../../components/IconHandaler";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import {
  REACT_AUTHORIZATION_LEVEL_ADD,
  REACT_AUTHORIZATION_LEVEL_EDIT,
  REACT_AUTHORIZATION_LEVEL_VIEW,
} from "../../router/ReactEndPoints";
import { useSnackbar } from "notistack";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";

export default function AuthorizationLevel() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
  });

  const loadRoles = (filters) => {
    filterRole(filters).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRoles(res?.data?.data?.roles);
        setTotalRecords(res?.data?.data?.totalCount);
      }
    });
  };

  useEffect(() => {
    loadRoles(filters);
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };
  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddAuthorization");
    isAllowedView = permission && permission.includes("ViewAuthorization");
    isAllowedEdit = permission && permission.includes("EditAuthorization");
    isAllowedDelete = permission && permission.includes("DeleteAuthorization");
  }

  const columns = [
    {
      field: "name",
      headerName: t("authorization.name"),
      width: 180,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
      flex: 2,
    },
    {
      headerName: `${t("common.actions")}`,
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedView && (
            <Tooltip title={t("common.view")} arrow>
              <VisibilityIcon
                id="authorizationlist"
                onClick={() => handleActions("View", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {e.row.name !== "Super Admin" && isAllowedEdit && (
            <Tooltip title={t("common.edit")} sx={{ marginLeft: 1 }}>
              <EditIcon
                onClick={() => handleActions("Edit", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  paddingLeft: "5px",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {e.row.name !== "Super Admin" && isAllowedDelete && (
            <Tooltip title={t("common.delete")} sx={{ marginLeft: 1 }}>
              <DeleteIcon
                onClick={() => handleActions("Delete", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  paddingLeft: "5px",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
        </IconHandaler>
      ),
    },
  ];

  const handleChangePage = (newPage) => {
    setFilters({ ...filters, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setFilters({ ...filters, pageNumber: 0, pageSize: pageSize });
  };

  const handleEdit = (id) => {
    navigate(REACT_AUTHORIZATION_LEVEL_EDIT, { state: { id: id } });
  };

  const handleView = (id) => {
    navigate(REACT_AUTHORIZATION_LEVEL_VIEW, { state: { id: id } });
  };

  const handleDelete = (id) => {
    deleteRole(id).then((response) => {
      if (response?.data?.code === "LVLI0002") {
        //enqueueSnackbar(response.data.message, {
        enqueueSnackbar(t("tips_authorization.deleted"), {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
        loadRoles(filters);
        return;
      }
      if (response?.data?.code === "LVLE00115") {
        //!== "LVLI0002"
        enqueueSnackbar(`${t("tips_authorization.role_client_user")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
        loadRoles(filters);
        return;
      }
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      handleView(data);
    } else if (action === "Edit") {
      handleEdit(data);
    } else if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const toolbarProps = {
    add: isAllowedCreate,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_AUTHORIZATION_LEVEL_ADD);
    },
    onRefresh: (data) => {
      loadRoles(filters);
    },
    // onFilter: (data) => {
    //   console.log("onFilter");
    // },
  };

  const getRowId = (role) => role.id;

  return (
    <ListLayout
      navigateBack={false}
      title={t("authorization.title")}
      toolbarProps={toolbarProps}
    >
      <DataTable
        columns={columns}
        rows={roles}
        getRowId={getRowId}
        page={filters.pageNumber}
        onSelection={(e) => console.log()}
        totalRecords={totalRecords}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(pageSize) => handleChangeRowsPerPage(pageSize)}
        rowsPerPage={filters.pageSize}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              {t("common.cancel")}{" "}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
                textTransform: "none",
              }}
              size="small"
              id="ConfirmModal-button-1"
              onClick={() => handleDelete(id)}
            >
              {" "}
              {t("common.delete")}{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
