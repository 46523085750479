import React, { useState, useEffect } from 'react'
import Moveable from 'react-moveable'
import { debounce } from '@mui/material/utils'
const RenderCommon = (props) => {
  const targetRef = React.useRef(null)
  const moveableRef = React.useRef(null)
  const { info, layoutJSON, setLayoutJSON, isPreView } = props
  const [verticalGuidelines, setVerticalGuidelines] = useState([])
  const [horizontalGuidelines, setHorizontalGuidelines] = useState([])

  let [dropBounds, setDropBounds] = useState({
    top: 0,
    left: 0,
    right: 10000,
    bottom: 10000,
  })

  useEffect(() => {
    let aa = new Array(300).fill('1').map((v, index) => {
      return index * 5
    })
    setVerticalGuidelines(aa)
    setHorizontalGuidelines(aa)
  }, [])

  useEffect(() => {
    let dom = document.querySelector('.boundary-element')
    if (dom) {
      let offsetLeft = dom.offsetLeft
      let offsetTop = dom.offsetTop
      let aa = {
        top: offsetTop,
        left: offsetLeft,
        right: offsetLeft + layoutJSON.width,
        bottom: offsetTop + layoutJSON.height,
      }
      setDropBounds(aa)
    }
  }, [info])

  let style = {
    zIndex: info.zIndex === '' || info.zIndex === undefined ? 10 : info.zIndex,
    width: info.width + 'px',
    height: info.height + 'px',
    transform: `translate(${info.left}px, ${info.top}px) rotate(${
      info.rotate ? info.rotate : 0
    }deg)`,
    // overflow: 'hidden',
    position: 'absolute',
    border: isPreView
      ? 'none'
      : layoutJSON.showBorder
      ? '1px solid #e5e6eb'
      : 'none',
  }

  if (info.bgcolor) {
    style.backgroundColor = info.bgcolor
  }

  const setClickInfo = (event) => {
    if (isPreView) {
      return false
    }
    event.preventDefault()
    event.stopPropagation()

    if (props.setActiveId) {
      props.setActiveId(info.id)
    }
    if (props.setCurrent) {
      props.setCurrent(info)
    }
  }

  useEffect(() => {
    if (isPreView && info.type === 'barCode' && (info.text === '' || info.text === null || info.text === undefined)) {
      return () => { }
    } else if (isPreView && info.type === 'qrCode' && (info.text === '' || info.text === null || info.text === undefined)) {
      return () => { }
    } else { 
      targetRef.current.style.width = info.width + 'px'
      targetRef.current.style.height = info.height + 'px'
      if (isPreView) {
        targetRef.current.style.left = `${info.left}px`
        targetRef.current.style.top = `${info.top}px`
        targetRef.current.style.transform = `rotate(${
          info.rotate ? info.rotate : 0
        }deg)`
      } else {
        targetRef.current.style.transform = `translate(${info.left}px, ${
          info.top
        }px) rotate(${info.rotate ? info.rotate : 0}deg)`
      }
  
      if (props.activeId === info.id) {
        moveableRef.current.moveable.updateRect()
      }
    }
  }, [info, layoutJSON])

  const setComponentInfo = (baseInfo) => {
    let newInfo = {
      ...info,
      ...baseInfo,
    }
    let index = ''
    layoutJSON.componentList.forEach((element, fIndex) => {
      if (element.id === newInfo.id) {
        index = fIndex
      }
    })
    if (index !== '') {
      layoutJSON.componentList[index] = newInfo
      setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)))
    }
  }

  const validationDebounced = debounce(setComponentInfo, 200)

  if (
    isPreView &&
    info.type === 'barCode' &&
    (info.text === '' || info.text === null || info.text === undefined)
  ) {
    return null
  }

  if (
    isPreView &&
    info.type === 'qrCode' &&
    (info.text === '' || info.text === null || info.text === undefined)
  ) {
    return null
  }

  return (
    <>
      <div
        onClick={setClickInfo}
        className="target"
        style={style}
        ref={targetRef}
      >
        {props.children}
      </div>

      {props.activeId === info.id && !isPreView && (
        <Moveable
          style={{ border: '2px solid red', zIndex: 100 }}
          target={targetRef}
          ref={moveableRef}
          rotatable={false} //不可旋转
          origin={false}
          className="component_Moveable"
          throttleDrag={0} // 拖拽阈值 达到这个值才执行拖拽
          // edge={true}
          snappable={true}
          draggable={props.activeId === info.id} //拖拽改变位置
          resizable={props.activeId === info.id} //拖拽改变大小
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          snapDirections={{ top: true, left: true, bottom: true, right: true }}
          bounds={dropBounds}
          onDrag={(e) => {
            e.target.style.transform = e.transform
            let left = Math.floor(e.translate[0])
            let top = Math.floor(e.translate[1])
            validationDebounced({
              left: left < 0 ? 0 : left,
              top: top < 0 ? 0 : top,
            })
          }}
          onResize={(e) => {
            let translate = e.drag.translate
            let width = Math.floor(e.width)
            let height = Math.floor(e.height)
            let left = Math.floor(translate[0])
            let top = Math.floor(translate[1])
            e.target.style.width = `${width}px`
            e.target.style.height = `${height}px`
            e.target.style.transform = e.drag.transform
            validationDebounced({
              width: width,
              height: height,
              left: left < 0 ? 0 : left,
              top: top < 0 ? 0 : top,
            })
          }}
        />
      )}
    </>
  )
}

export default RenderCommon
