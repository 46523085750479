import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { AutoComplete } from "react-bmapgl";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

const MapTextAutoComplete = (props) => {
  const { t } = useTranslation();
  const { mapRef, setCenter } = props;

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const autoCompleteRef = useRef(null);

  // useEffect(() => {
  //   if (autoCompleteRef.current) {
  //     autoCompleteRef.current.addEventListener("input", (event) => {
  //       if (event.target.value) {
  //         document.getElementById("selectAddressInput").value =
  //           event.target.value;
  //       }
  //     });
  //   }
  // }, []);

  const handleInputChange = (event, newInputValue) => {
    setValue(newInputValue);

    // setValue(newInputValue?.street);
    // if (autoCompleteRef.current) {
    //   document.getElementById("selectAddressInput").value = newInputValue;
    // }
  };

  const handleOptionChange = (event, newValue) => {
    if (newValue) {
      setValue(newValue.street || ""); // 使用新选项值更新 value
      if (newValue.location) {
        setCenter(newValue);
        mapRef.current.map.centerAndZoom(newValue.location, 15);
      }
    }
  };

  return (
    <>
      <Autocomplete
        id="selectAddressInput"
        sx={props.sx}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.street
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={t("请输入位置信息搜索")}
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            inputRef={autoCompleteRef}
            label=""
            size="small"
            fullWidth
          />
        )}
        renderOption={(props, option, index) => (
          <li {...props} key={`${option.street}-${index}`}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  {option.street}
                </Box>
              </Grid>
            </Grid>
          </li>
        )}
      />

      <AutoComplete
        options={options}
        input="selectAddressInput"
        onHighlight={(e) => {}}
        onChange={(e) => {
          console.log("BBBBBBBBBBBBBBBBB", e);
        }}
        onConfirm={(e) => {
          const selectedOption = e.item.value;
          setValue(selectedOption);
        }}
        onSearchComplete={(e) => {
          setOptions(e._pois);
        }}
      />
    </>
  );
};

export default MapTextAutoComplete;
