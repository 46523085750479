import { filter } from "lodash";
import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";

export const createPriceChangeEvent = async (payload) => {
  return api.securedAxios().post("web/price_change_event", payload);
};

export const createInstantPriceChangeEvent = async (payload) => {
  return api.securedAxios().post("web/instant_price_change_event", payload);
};

export const updateRefreshCount = async (payload) => {
  return api.securedAxios().put("api/v2/dashboard/refresh_count", payload);
};

export const updatePriceChangeEvent = async (payload) => {
  return api.securedAxios().put("web/price_change_event", payload);
};

export const addApprovalPersonId = async (payload) => {
  return api.securedAxios().post("web/add_personId", payload);
};

export const getPriceChangeEvents = async (filters) => {
  let url = "web/price_change_events";
  if (filters.pageNumber >= 0) {
    url = url + "?pageNumber=" + filters.pageNumber;
  }
  if (filters.pageSize) {
    url = url + "&pageSize=" + filters.pageSize;
  }

  return api.securedAxios().get(url);
};

export const getEventList = async () => {
  let url = "web/price_change_event/list";
  return api.securedAxios().get(url);
};

export const deletePriceChangeEvent = async (id) => {
  return api.securedAxios().delete("web/price_change_event/" + id);
};

export const getPriceChangeEventById = async (id) => {
  return api.securedAxios().get("web/price_change_event/" + id);
};

export const getEventByBetweenStartAndEndDate = async (filter) => {
  let url = "";
  if (!CommonUtil.isEmpty(filter?.fromDate)) {
    url = url + "?startAt=" + filter.fromDate;
  }
  if (!CommonUtil.isEmpty(filter?.toDate)) {
    url = url + "&endAt=" + filter.toDate;
  }
  if (filter?.siteId) {
    url = url + "&outletId=" + filter.siteId;
  }
  return api.securedAxios().get("web/price_change_event/byDate" + url);
};

export const getLastOneWeekPriceChangeEvent = () => {
  return api.securedAxios().get("web/price_change_event/last/week");
};

export const getTodayPriceChangeEvent = () => {
  return api.securedAxios().get("web/price_change_event/today");
};

export const getPriceChangeEventsByStatus = async (id) => {
  return api.securedAxios().get("web/price_change_events/approvalList/" + id);
};

export const getPriceChangeEventsApprovalList = async (id) => {
  return api.securedAxios().get("web/price_change_events/approvalList/" + id);
};

export const getPriceChangeEventsFlowChart = async (filters) => {
  let url =
    "web/price_change_events/approval_flow_chart" +
    "?pageNumber=" +
    filters?.pageNumber +
    "&pageSize=" +
    filters?.pageSize;
  // if (filters.pageNumber) {
  //   url = url + "?pageNumber=" + filters.pageNumber;
  // }
  // if (filters.pageSize) {
  //   url = url + "&pageSize=" + filters.pageSize;
  // }
  if (filters.loggedId) {
    url = url + "&loggedId=" + filters?.loggedId;
  }

  return api.securedAxios().get(url);
};

export const getPriceChangeEventsApprovalFlowChartListApproveList = async (
  filters
) => {
  let url =
    "web/price_change_events/approval_flow_chart/approveList" +
    "?pageNumber=" +
    filters?.pageNumber +
    "&pageSize=" +
    filters?.pageSize;

  if (filters.loggedId) {
    url = url + "&loggedId=" + filters?.loggedId;
  }
  return api.securedAxios().get(url);
};

export const deletePriceChangeEventsApprovalFlowChartList = async (filters) => {
  let url =
    "web/price_change_events/approval_flow_chart/delete" +
    "?id=" +
    filters?.id +
    "&personName=" +
    filters?.personName;

  return api.securedAxios().delete(url);
};

export const chnageStatus = async (id, payload) => {
  return api
    .securedAxios()
    .put("web/price_change_event/change/status" + "?id=" + id, payload);
};

export const getApprovedAndRejectedEvents = async (filters) => {
  let url =
    "web/price_change_events/approved" +
    "?pageNumber=" +
    filters.pageNumber +
    "&pageSize=" +
    filters.pageSize;
  return api.securedAxios().get(url);
};

//新增变价事件

export const addChangeEvent = (payload) => {
  return api.securedAxios().post("/web/price_change_event", payload);
};

export const editChangeEvent = (payload) => {
  return api.securedAxios().put("/web/price_change_event", payload);
};
