import { Box, Divider, Grid, InputLabel } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "./CustomInput copy";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { ReactComponent as DownArrow } from "../assets/images/downArrow.svg";
import CommonUtil from "../util/CommonUtils";

const CustomePhoneFiled = (props) => {
  const { t } = useTranslation();

  const useStyles = makeStyles({
    borderClass: {
      "&.react-tel-input .selected-flag .flag": {
        display: CommonUtil.isEmpty(props.countryCode) ? "none" : "block",
      },
      "& .react-tel-input::after": {
        content: DownArrow,
      },
    },
    customInput: {
      "& .MuiOutlinedInput-root ": {
        paddingLeft: "0px",
      },
    },
  });
  const classes = useStyles();

  return (
    <Grid variant="standard" style={{ width: "100%" }}>
      <Grid sx={{ margin: " 10px 0px", width: "100%" }}>
        <Grid
          xs={12}
          sm={5.5}
          md={12}
          lg={12}
          xl={12}
          style={{ display: "flex" }}
        >
          <Grid xs={12} sm={5.5} md={3} lg={3} xl={3} pr={0.01}>
            <PhoneInput
              autoFormat={true}
              countryCodeEditable={true}
              enableSearch={true}
              searchPlaceholder={"Search"}
              searchNotFound={"No Options Found"}
              onChange={(value, data) => {
                props.handleCountryCode(data);
              }}
              disabled={props.disabled ? props.disabled : false}
              value={props.countryCode}
              containerClass={classes.borderClass}
              placeholder={t("table.country_code")}
              specialLabel={false}
              style={{
                height: "43.5px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "8px 0px 0px 8px",
              }}
              sx={{ zIndex: "999", position: "relative" }}
              inputStyle={{ marginBottom: "0px" }}
            />
          </Grid>
          <Grid xs={12} sm={4} md={9} lg={9} xl={9}>
            <CustomInput
              type={"text"}
              placeholder={props.placeholder}
              autoComplete="off"
              fullWidth
              className={classes.customInput}
              error={props.error}
              resetError={() => console.log()}
              name={props.name}
              value={props.phoneNumber}
              variant="outlined"
              size={props.size}
              handleChange={(e) => props.handleChange(e.target.value)}
              helperText={props.error}
              validation="mobile"
              sx={{ zIndex: "1000" }}
              disabled={props.disabled ? props.disabled : false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomePhoneFiled;
