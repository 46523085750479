import api from "../configurations/http-common";

export const pictureList = async (filters) => {
  let url = "web/picture_library/list?";

  if (filters?.pageNumber) {
    url = url + "pageNumber=" + filters.pageNumber;
  }
  if (filters?.pageSize) {
    url = url + "&pageSize=" + filters.pageSize;
  }
  if (filters?.name) {
    url += "&name=" + filters.name;
  }
  if (filters?.pictureId) {
    url += "&pictureId=" + filters.pictureId;
  }
  return api.securedAxios().get(url);
};

export const createPicture = async (payload) => {
  return api.securedAxios().post("web/picture_library", payload);
};

export const getDownloadUrl = async (payload) => {
  return api.securedAxios().get("web/picture_library/url?objectKey=" + payload);
};

export const getDownloadUrlBatch = async (payload) => {
  return api
    .securedAxios()
    .get("web/picture_library/url/batch?objectKey=" + payload);
};

export const getPictureById = async (id) => {
  return api.securedAxios().get("web/picture_library/" + id);
};

export const updatePicture = async (payload) => {
  return api.securedAxios().put("web/picture_library", payload);
};

export const deletePicture = async (id) => {
  return api.securedAxios().delete("web/picture_library/" + id);
};

export const getByIdPriceData = (id) => {
  return api.securedAxios().get(`/web/price_change_event/${id}`);
};
