

import RenderBarCode from './CenterComponent/RenderBarCode'
import RenderProductElement from './CenterComponent/RenderProductElement'
import RenderQrcode from './CenterComponent/RenderQrcode'
import RenderTextBox from './CenterComponent/RenderTextBox'
import RenderImage from './CenterComponent/RenderImage'

const RenderComponent = (props) => {
  let info = props.info;
  let type = info.type;
  let isProductLabel = info.isProductLabel;
  let component = null;
  if (isProductLabel) {
    component = <RenderProductElement {...props}></RenderProductElement>;
  } else if (type === 'barCode') {
    component = <RenderBarCode {...props}></RenderBarCode>;
  } else if (type === 'qrCode') {
    component = <RenderQrcode {...props}></RenderQrcode>;
  } else if (type === 'image' || type === 'logo') {
    component = <RenderImage {...props}></RenderImage>;
  } else if (type === 'textBox') {
    component = <RenderTextBox {...props}></RenderTextBox>;
  }
  return component;
};

export default RenderComponent