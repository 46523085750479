import React, { useEffect, useState, useRef } from "react";
import { Grid, styled, InputLabel, FormHelperText } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as CalenderIcon } from "@/assets/images/calendar-icon.svg";
import CustomInput from "@c/CustomInput";
import RequirePoint from "@z/RequirePoint";
import dayjs from "dayjs";
const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

export default function CustomDatePicker(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height = "40px",
    inputType,
    options,
    fontSize = "24px",
    readonly,
    defaultValue,
    typeValue = "0",
    ...orther
  } = props;

  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat={"MMM d,yyyy"}
          disableFuture={props.disableFuture}
          disabled={props.disabled}
          placement="bottom-right"
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          dateRangeIcon={<CalenderIcon />}
          name={name}
          label={
            <InputLabel
              shrink
              htmlFor={"CmpAutoComPlete_" + name}
              style={{
                marginTop: labelPostion === "left" ? "12px" : "",
                width: width,
                fontSize: fontSize,
              }}
            >
              {props.required && <RequirePoint></RequirePoint>}
              {label}
            </InputLabel>
          }
          views={["year", "month", "day"]}
          value={formik.values[name]}
          onChange={(e) => {
            let dateString = dayjs(e).format("YYYY-MM-DD");
            formik.setFieldValue(name, dateString);
          }}
          minDate={props.minDate}
          maxDate={props.maxDate}
          {...orther}
          renderInput={(params) => (
            <CustomInput
              handleChange={(event) => event.preventDefault()}
              fullWidth
              resetError={() => console.log("6666")}
              size={props.size ? props.size : ""}
              // value={formik.values[name]}
              style={{ width: "100%" }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
                readOnly: true,
              }}
            />
          )}
        />

        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </Grid>
  );
}
