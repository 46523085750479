import {
  Avatar,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import IconHandaler from "../../components/IconHandaler";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import DataTable from "../../components/DataTable";
import CommonUtil from "../../util/CommonUtils";
import {
  REACT_PERSON_ADD,
  REACT_PERSON_EDIT,
  REACT_PERSON_VIEW,
  REACT_PRINCIPAL_MANAGEMENT,
  REACT_RETAIL_CLIENT_LIST,
  RETAIL_CLIENT,
} from "../../router/ReactEndPoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ListLayout from "../../components/ListLayout";
import { useRef, useState } from "react";
import { deletePerson, personList } from "../../services/PersonService";
import { useEffect } from "react";
import ConfirmModal from "../../components/ConfirmModel";
import { confirmAlert } from "react-confirm-alert";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function Person() {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
    clientIds: params.id,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: "",
    code: "",
    toggleKeyToURL: "",
    operator: "",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
    clientIds: params.id,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: "",
    code: "",
    toggleKeyToURL: "",
    operator: "",
  };
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    personList(filters).then((res) => {
      if (
        res?.data?.code === "UASI0000" &&
        res?.data?.data &&
        res?.data?.data?.users
      ) {
        setRecords(res?.data?.data?.users);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
      }
    });
  };
  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const columns = [
    {
      field: "firstName",
      headerName: `${t("user.person_name")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Avatar
            sx={{ bgcolor: "#C0C0C0", marginRight: "8px" }}
            imgProps={{ draggable: "false" }}
            alt={e.row.firstName}
            src={e.row.imagePreSignedURL}
          ></Avatar>
          <Tooltip
            title={
              e?.row?.lastName
                ? e.row.firstName + " " + e?.row?.lastName
                : e.row.firstName
            }
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(
                e.row.lastName
                  ? e.row.firstName + " " + e.row.lastName
                  : e.row.firstName
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "email",
      headerName: `${t("table.email")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.email} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.email)}</span>
        </Tooltip>
      ),
    },
    {
      headerName: `${t("common.actions")}`,
      renderCell: (e) => (
        <IconHandaler>
          <Tooltip title={t("common.view")} arrow>
            <VisibilityIcon
              id="viewpersonlist"
              onClick={() => handleActions("View", e.row.id)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
                height: "17px",
                width: "20px",
                padding: "2px",
              }}
            />
          </Tooltip>
          <Tooltip title={t("common.edit")} arrow>
            <EditIcon
              onClick={() => handleActions("Edit", e.row.id)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
                height: "17px",
                width: "20px",
                padding: "2px",
              }}
            />
          </Tooltip>
          <Tooltip title={t("common.delete")} sx={{ pl: 1 }}>
            <DeleteIcon
              id="deletepersonlist"
              onClick={() => handleActions("Delete", e.row.id)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
                paddingLeft: "5px",
                height: "17px",
                width: "20px",
                padding: "2px",
              }}
            />
          </Tooltip>
        </IconHandaler>
      ),
    },
  ];

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      clientIds: params.id,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      clientIds: params.id,
      pageSize: e,
    });
  };

  const handleDelete = (id) => {
    deletePerson(id).then((response) => {
      if (response.data.code === "LVLI0002") {
        enqueueSnackbar(t("tips_user.person_deleted"), {
          variant: "success",
        });
        //navigate(REACT_DATA_ACCESS_CONTROL);
        loadData();
        setConfirm(false);
      } else if (response?.data?.code === "LVLE00101") {
        enqueueSnackbar(t("tips_user.cannot_delete_default_user"), {
          variant: "error",
        });
        setConfirm(false);
      } else if (response?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("tips_user.person_deleted"), {
          variant: "error",
        });
        setConfirm(false);
      }
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      if (window.location.pathname.includes("/retail-client"))
        navigate(RETAIL_CLIENT + params.id + REACT_PERSON_VIEW + data, {
          state: { code: params.code },
        });
      if (window.location.pathname.includes("/principal"))
        navigate(
          REACT_PRINCIPAL_MANAGEMENT +
            "/" +
            params.id +
            REACT_PERSON_VIEW +
            data,
          {
            state: { code: params.code },
          }
        );
    }
    if (action === "Edit") {
      if (window.location.pathname.includes("/retail-client"))
        navigate(RETAIL_CLIENT + params.id + REACT_PERSON_EDIT + data, {
          state: { code: params.code },
        });
      if (window.location.pathname.includes("/principal"))
        navigate(
          REACT_PRINCIPAL_MANAGEMENT +
            "/" +
            params.id +
            REACT_PERSON_EDIT +
            data,
          {
            state: { code: params.code },
          }
        );
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const handleSelection = (id) => {};

  const toolbarProps = {
    add: true,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      if (window.location.pathname.includes("/retail-client"))
        navigate(
          RETAIL_CLIENT + params.id + "/" + params.code + REACT_PERSON_ADD
        );
      if (window.location.pathname.includes("/principal"))
        navigate(
          REACT_PRINCIPAL_MANAGEMENT +
            "/" +
            params.id +
            "/" +
            params.code +
            REACT_PERSON_ADD
        );
    },
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  return (
    <ListLayout
      id="personlistback"
      navigateBack={
        params.name === "retail-client"
          ? REACT_RETAIL_CLIENT_LIST
          : REACT_PRINCIPAL_MANAGEMENT
      }
      title={t("user.person_list")}
      toolbarProps={toolbarProps}
    >
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
        onSelection={(id) => handleSelection(id)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDelete(id)}
            >
              {" "}
              Delete{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
