import React, { useEffect, useState, useRef } from "react";
import { Grid, styled, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as CalenderIcon } from "../assets/images/calendar-icon.svg";
import CustomInput from "./CustomInput";

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

export default function CustomDatePicker(props) {
  const [date, setDate] = useState("");

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  const join = (t, a, s) => {
    try {
      function format(m) {
        let f = new Intl.DateTimeFormat("en", m);
        return f.format(t);
      }
      return a.map(format).join(s);
    } catch (error) {}
  };

  const handleDate = (e) => {
    let formet = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
    let formetChange = join(e, formet, " ");
    setDate(e);
    props.SelectedDate(e);
  };
  const preventInput = (event) => {
    event.preventDefault();
  };

  const label = (label) => {
    return (
      <>
        {label} <span style={{ color: "red" }}>*</span>
      </>
    );
  };

  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat={"MMM d,yyyy"}
          disableFuture={props.disableFuture}
          disabled={props.disabled}
          placement="bottom-right"
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          dateRangeIcon={<CalenderIcon />}
          label={props.required ? label(props.label) : props.label}
          views={["year", "month", "day"]}
          value={date || ""}
          onChange={(e) => handleDate(e)}
          minDate={props.minDate}
          renderInput={(params) => (
            <CustomInput
              handleChange={preventInput}
              fullWidth
              resetError={() => console.log("6666")}
              // handleChange={(e)=>console.log()}
              size={props.size ? props.size : " "}
              value={date}
              style={{ width: "100%" }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
            />
          )}
        />
      </LocalizationProvider>
      {props.error && (
        <Typography color={"red"} fontSize={"13px"}>
          {props.error}
        </Typography>
      )}
    </Grid>
  );
}
