/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";
const LineCharts = ({ dashBoardData }) => {
  const chartRef = useRef(null);

  let data = dashBoardData?.refreshDataList;
  let timeList = data?.map((item) => item?.time);
  let totalRefreshCount = data?.map((item) => item?.totalRefreshCount);
  let successRefreshCount = data?.map((item) => item?.successRefreshPercentage);

  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [interGap, setInterGap] = useState(0); // 假设初始值为0

  const calculateInterval = () => {
    // 根据屏幕宽度动态设置 interval
    if (windowWidth < 600) {
      setInterGap(6); // 小屏幕，显示每5个标签
    } else if (windowWidth < 1000) {
      setInterGap(5);
    } else if (windowWidth < 1200) {
      setInterGap(4);
    } else if (windowWidth < 1400) {
      setInterGap(3);
    } else if (windowWidth < 1500) {
      setInterGap(2);
    } else if (windowWidth < 1600) {
      setInterGap(1);
    } else {
      setInterGap(0);
    }
  };

  useEffect(() => {
    calculateInterval(); // 初始计算

    // 监听窗口大小变化
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 清理函数
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 仅在组件挂载时执行一次

  // 当 windowWidth 变化时调用 calculateInterval
  useEffect(() => {
    calculateInterval();
  }, [windowWidth]);

  let success = t("dashboard.refresh_time");
  let refreshTimes = t("dashboard.success_rate");

  const getOptions = () => {
    let option = {
      grid: {
        left: "5%",
        right: "5%",
        bottom: "13%",
        containLabel: true,
      },

      tooltip: {
        trigger: "axis",
        enterable: true, //允许鼠标进入提示悬浮层中
        showContent: true,
        triggerOn: "mousemove", //提示框触发的条件  mousemove鼠标移动时触发 click鼠标点击时触发  'mousemove|click'同时鼠标移动和点击时触发
        formatter: function (params) {
          var result = params[0].name + "<br/>"; // 如果需要显示横坐标的值，可以通过 params[0].name 获取
          params.forEach(function (item) {
            // 在悬浮框中显示的内容格式化为百分比
            result +=
              item.seriesName +
              ": " +
              item.value +
              (item.seriesName === "Refresh Times" ? "" : "%") +
              "<br/>"; // 添加条件判断
          });
          return result;
        },
      },

      legend: {
        data: [success, refreshTimes],
        bottom: "0",

        color: "#747474",
        width: "100%",
        backgroundColor: "#f5f5f5",
        borderColor: "#ccc",
        borderWidth: 1,
        padding: [10, 100],
        itemGap: 150,
        itemWidth: 14,
        itemHeight: 14,
        borderRadius: 5,
        align: "auto",
      },
      xAxis: [
        // 2023x轴
        {
          type: "category",
          data: timeList,
          axisTick: {
            show: false, // 是否显示坐标轴轴线
          },
          axisLabel: {
            color: "#282828",
            fontSize: 10,
            interval: interGap, // 根据屏幕宽度设置间隔
          },

          boundaryGap: true,
          axisLine: {
            //坐标轴轴线相关设置。
            show: true,
            inside: false,
            lineStyle: {
              color: "#d9d9d9",
            },
          },
        },
      ],
      yAxis: [
        // 柱状图
        {
          type: "value",
          min: 0,
          splitNumber: 6,
          splitLine: {
            show: true,
          },
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            color: "#737373",
            fontSize: 14,
            formatter: function (value) {
              return value; // Refresh Times 保持原样
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          // splitLine: {
          //   show: true,
          //   lineStyle: {
          //     color: "#CCC",
          //     type: "solid",
          //     width: 2,
          //   },
          // },
        },
        {
          type: "value",
          formatter: "{value}%", // 这里添加百分号
          axisLabel: {
            formatter: "{value}%", // 直接添加百分号
          },
        },
      ],
      series: [
        {
          name: refreshTimes,
          type: "bar",
          barWidth: 36,
          xAxisIndex: 0,
          data: totalRefreshCount,
          smooth: true, // 平滑线条,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: "#7cb6f9" }, // 逐渐变为蓝色
                { offset: 0.5, color: "#6dd5fa" }, // 中间渐变为浅蓝色
                { offset: 1, color: "#7cb6f9" }, // 最后渐变为白色
              ],
            },
            label: {
              show: false, //开启显示
              position: "top", //在上方显示
              fontSize: 12,
              fontWeight: 400,
              width: 8,
            },
          },
        },

        {
          name: success,
          type: "line",
          yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
          showAllSymbol: true, //显示所有图形。
          showSymbol: false,
          symbol: "line", //标记的图形为实心圆
          symbolSize: 4, //标记的大小
          lineStyle: {
            color: "#78BC27",
            width: 5,
          },
          data: successRefreshCount,
        },
      ],
    };

    return option;
  };
  // const options = useMemo(() => getOptions(), [data]);
  useEffect(() => {
    let myEcharts = null;
    const initChart = () => {
      myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
      // 设置初始大小
      myEcharts.resize();
      // 监听窗口大小变化，自动调整图表大小
      window.addEventListener("resize", handleResize);
      const options = getOptions();
      myEcharts.setOption(options);
    };
    const handleResize = () => {
      myEcharts.resize();
    };

    // 在组件挂载时进行初始化
    initChart();

    // 在组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
      myEcharts.dispose();
    };
  }, [data, interGap]);
  return (
    <div
      ref={chartRef}
      className="LineChatsStyle"
      style={{
        width: "100%",
        height: "350px",
      }}
    ></div>
  );
};

export default LineCharts;
