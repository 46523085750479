import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import AppContext from "../../context/AppContext";
import BottomNavigationBar from "./BottomNavigationBar";
import { ReactComponent as Logo } from "../../assets/images/L3ePriceLabel.svg";

export default function MainLayout(props) {
  const [tokenRefreshTimer, setTokenRefreshTimer] = useState(-1);
  const [selectedClient, setSelectedClient] = useState({});
  const isLargeScreen = useMediaQuery("(min-width: 960px)");

  const value = {
    tokenRefreshTimer,
    selectedClient,
    setSelectedClient,
    setTokenRefreshTimer,
  };

  return (
    <AppContext.Provider value={value}>
      <Box
        display={"flex"}
        flexDirection="column"
        sx={{ height: "100vh", overflow: "hidden" }}
      >
        {/* <Box pl={3} pt={2} pb={2}>
          <Logo />
        </Box> */}
        <Box overflow={"auto"} display="flex" flex="1">
          <Grid
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              backgroundColor: "#f7fbfe",
              overflow: "hidden",
            }}
          >
            <Grid
              style={{ height: "100%", display: "flex", flexDirection: "row" }}
            >
              <Box
                sx={{
                  display: { xs: "none", lg: "block" },
                  content: "''",
                  position: "absolute",
                  left: "-202px",
                  width: "400px",
                  height: "180px",
                  background:
                    "transparent conic-gradient(from -49deg at 50% 50%, #1487CA 0.00%, #78BC27 100.00%) 0% 0% no-repeat padding-box",
                  opacity: "0.8",
                  backdropFilter: "blur(10px)",
                  filter: "blur(30px)",
                }}
              />
              <Box
                sx={{
                  display: { xs: "none", lg: "block" },
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: "-202px",
                  width: "400px",
                  height: "180px",
                  background:
                    "transparent conic-gradient(from 180deg at 50% 50%, #78BC27 0.00%, #1487CA 100.00%) 0% 0% no-repeat padding-box",
                  opacity: "0.8",
                  backdropFilter: "blur(30px)",
                  filter: "blur(10px)",
                }}
              />
              <Grid
                sx={{
                  display: { xs: "none", lg: "block" },
                  position: "relative",
                  paddingTop: "10px",
                  zIndex: 1,
                }}
                overflowY={"auto"}
              >
                <Sidebar />
              </Grid>
              {/* <Box sx={{ display: { xs: "block", lg: "none" }, zIndex: 1 }}>
                <BottomNavigationBar />
              </Box> */}

              <Grid
                container
                mx={2}
                style={{
                  height: "100%",
                  overflowY: "auto",
                  display: "block",
                  width: "-webkit-fill-available",
                }}
              >
                <Box
                  my={2}
                  mx={0}
                  height={"95%"}
                  fullWidth
                  sx={{ pt: { xs: "30px", md: 0 } }}
                >
                  {props.children}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AppContext.Provider>
  );
}
