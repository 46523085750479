import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_DEVICE_SCREENS } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { Box, Button, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { getScreenById, updateScreenById } from "../../services/ScreenService";

export default function EditScreen() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState({
    sn: "",
    battery: "",
    screenId: "",
    screenName: "",
    positionNo: "",
  });

  const [error, setError] = useState({
    sn: "",
    battery: "",
    screenId: "",
    screenName: "",
    positionNo: "",
  });

  const validateForm = () => {
    // if (CommonUtil.isEmptyString(payload.positionNo)) {
    //   setError({
    //     ...error,
    //     positionNo: t("tips.required"),
    //   });
    //   return;
    // }
    // if (CommonUtil.isEmptyString(payload.screenName)) {
    //   setError({
    //     ...error,
    //     screenName: t("tips.required"),
    //   });
    //   return;
    // }
    if (CommonUtil.isEmptyString(payload.sn)) {
      setError({
        ...error,
        sn: t("tips.required"),
      });
      return;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        screenName: payload.screenName,
        positionNo: payload.positionNo,
        sn: payload.sn,
      };
      updateScreenById(screenId, request).then((res) => {
        if (res.data.code === "DMSI0000") {
          navigate(REACT_DEVICE_SCREENS);
          enqueueSnackbar("Screen Updated Successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const location = useLocation();
  const [sn, setSn] = useState("");
  const [name, setName] = useState("");
  const [screenId, setScreenId] = useState("");
  const [battery, setBattery] = useState("");
  const [screenName, setScreenName] = useState("");
  const [positionNo, setPositionNo] = useState("");
  const calculateBatteryStatus = (batteryState) => {
    if (batteryState === undefined || batteryState === null) {
      return "";
    }
    const v = batteryState / 1000;
    const vs = Math.floor(batteryState / 100) / 10;
    if (v >= 2.3) {
      return t("screen.normal") + "(" + vs + "V)";
    } else {
      return t("screen.lowPower") + "(" + vs + "V)";
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    const deviceId = location?.state?.deviceId;
    getScreenById(id, deviceId).then((response) => {
      if (response.data.code === "DMSI0000") {
        setSn(response.data.data.sn);
        setScreenId(response.data.data.id);
        setName(response.data.data.name);
        setBattery(calculateBatteryStatus(response.data.data.battery));
        setScreenName(response.data.data.screenName);
        setPositionNo(response.data.data.positionNo);
        setPayload((prevPayload) => ({
          ...prevPayload,
          screenId: response.data.data.id,
          sn: response.data.data.sn,
          battery: calculateBatteryStatus(response.data.data.battery),
          screenName: response.data.data.screenName,
          positionNo: response.data.data.positionNo,
        }));
      }
    });
  }, []);

  return (
    <RightViewLayout
      navigateBack={REACT_DEVICE_SCREENS}
      title={t("screen.edit_screen")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditScreen1"
            required
            label={t("screen.screenId")}
            size="small"
            name="screenId"
            value={payload.screenId}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
            validation={"alpha-numeric"}
            disabled={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditScreen2"
            label={t("screen.positionNo")}
            size="small"
            name="positionNo"
            error={error.positionNo}
            resetError={() => setError({ ...error, positionNo: "" })}
            value={payload.positionNo}
            handleChange={handleChange}
            inputProps={{
              maxLength: 5,
            }}
            placeholder={t("tips_screens.enter_position_no")}
            helperText={error.positionNo}
            validation={"alpha-numeric"}
            // disabled={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditScreen3"
            label={t("screen.screenName")}
            size="small"
            name="screenName"
            error={error.screenName}
            resetError={() => setError({ ...error, screenName: "" })}
            value={payload.screenName}
            handleChange={handleChange}
            inputProps={{
              maxLength: 10,
            }}
            placeholder={t("tips_screens.enter_screen_name")}
            helperText={error.screenName}
            validation={"alpha-numeric"}
            // disabled={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditScreen4"
            required
            label={t("screen.powerStatus")}
            size="small"
            name="battery"
            value={payload.battery}
            // handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
            validation={"alpha-numeric"}
            disabled={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="EditScreen5"
            required
            label={t("screen.gateway")}
            size="small"
            name="sn"
            error={error.sn}
            resetError={() => setError({ ...error, sn: "" })}
            value={payload.sn}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.sn}
            placeholder={t("tips_screens.enter_gateway_sn")}
            validation={"alpha-numeric"}
            // disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="EditScreen-button-01"
                variant="contained"
                sx={{
                  marginLeft: 1,
                  background: `linear-gradient(to right, ${"#1487CA"}, ${"#78BC27"})`,
                  padding: "4px",
                }}
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
              >
                {t("common.save")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="EditScreen-button-02"
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_DEVICE_SCREENS)}
                size="large"
              >
                {t("common.back")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
