import React, { useEffect, useState, useContext } from "react";
import { Box, InputLabel, Grid, FormHelperText } from "@mui/material";
import OutletDialog from "./OutletDialog";
import { getOnlineOutlet } from "../js/interface";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { onLineOutletContext } from "../menu/AreaOutlet";
import { ChangePriceContext } from "../AddPriceEvent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
function Tag(props) {
  const { label, onDelete, ...other } = props;

  return (
    <div
      style={{
        display: "flex",
        width: "126px",
        justifyContent: "space-between",
        textAlign: "center",
        lineHeight: "30px",
      }}
      {...other}
    >
      <span
        style={{
          width: "70px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </span>
      <span
        style={{
          marginTop: "5px",
        }}
      >
        <CloseIcon onClick={onDelete} />
      </span>
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  width: 130px;
   justifyContent: "space-between",
  align-items: center;
  height: 32px;
  margin: 2px;
  line-height: 22px;
  whiteSpace: nowrap;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;

`
);

function OutletInput(props) {
  const { addFormik } = props;
  const { deviceOnlineOutlet, setDeviceOnlineOutlet, initValue } =
    useContext(onLineOutletContext);
  const { t } = useTranslation();
  const { outletMarked, setOutletMarked } = useContext(ChangePriceContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [dataZoonId, setDataZoonId] = useState("+12:00");
  const [totalRecords, setTotalRecords] = useState(0);
  const { getInputProps, getTagProps } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: data,
    getOptionLabel: (option) => option.name,
  });

  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 5,
  });

  useEffect(() => {
    const selectedOutlets = initValue || [];
    setData(selectedOutlets);
  }, [initValue]);

  useEffect(() => {
    getOnlineOutlet(
      filters,
      setTotalRecords,
      setDeviceOnlineOutlet,
      dataZoonId?.value
    );
    addFormik.setFieldValue("zoneId", dataZoonId?.value);
  }, [dataZoonId]);

  const handleDelete = (index, e) => {
    e.stopPropagation(); // 阻止事件冒泡
    setData((prevData) => {
      prevData = prevData.filter((_, i) => i !== index);
      addFormik.setFieldValue(
        "outletIds",
        prevData?.map((x) => x.id)
      );
      return prevData;
    });
  };

  return (
    <>
      <Box letiant="standard" style={{ width: "100%" }} pb={1}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          style={{ paddingLeft: "0px", fontSize: "24px" }}
        >
          {props.required ? <span style={{ color: "red" }}>*</span> : " "}
          {props.label}
        </InputLabel>

        <Grid
          sx={{
            width: "100%",
            height: "40px",
            border: "1px solid #ccc",
            display: "flex",
            borderRadius: "5px",
          }}
          {...getInputProps()}
          onClick={() => {
            setOpen(true);
          }}
        >
          {data?.slice(0, 3).map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Grid key={key}>
                <StyledTag
                  {...tagProps}
                  label={option.name}
                  onDelete={(e) => handleDelete(index, e)}
                />
              </Grid>
            );
          })}

          {/* 如果 data1 超过 3 个，显示省略号 */}
          {data?.length > 3 && (
            <span
              style={{
                marginTop: "15px",
              }}
            >
              ......
            </span>
          )}
        </Grid>
        {outletMarked == false && data?.length === 0 && (
          <FormHelperText
            error
            id={`standard-weight-helper-text-${"outletIds"}`}
            sx={{
              mt: 2,
            }}
          >
            {t("tips_outlet.select_outlet")}
          </FormHelperText>
        )}

        <OutletDialog
          open={open}
          setOpen={setOpen}
          setData={setData}
          data={data}
          addFormik={addFormik}
          totalRecords={totalRecords}
          setDataZoonId={setDataZoonId}
          setTotalRecords={setTotalRecords}
          dataZoonId={dataZoonId}
        ></OutletDialog>
      </Box>
    </>
  );
}
export default OutletInput;
