import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import ListLayout from "../../components/ListLayout";
import IconHandaler from "../../components/IconHandaler";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import DataTable from "../../components/DataTable";
import {
  REACT_APPROVAL_FLOW_CHART_ADD,
  REACT_APPROVAL_FLOW_CHART_EDIT,
  REACT_PRODUCT_EDIT,
} from "../../router/ReactEndPoints";
import { useNavigate } from "react-router-dom";
//import { deletePriceChangeEventsApprovalFlowChartList} from "../../services/PriceChangeEventservice";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import { useSnackbar } from "notistack";
import {
  getPriceChangeEventsFlowChart,
  deleteApprovalFlowChartListById,
} from "../../services/ApprovalService";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";

export default function ApprovalFlowChart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  const user = JSON.parse(localStorage.getItem("USER"));
  const retailClient = localStorage.getItem("RETAIL_CLIENT");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  let userId = "";
  if (roleName === "OWNER" && retailClient !== "") {
    userId = retailClient;
  } else {
    userId = user.id;
  }
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
    loggedId: userId,
  });

  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
    loggedId: userId,
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const loadData = (request) => {
    getPriceChangeEventsFlowChart(request).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setRecords(res?.data?.data.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.pageSize);
      }
      if (res?.data?.code === "LVLE0054") {
        setRecords([]);
        setTotalRecords(0);
      }
      if (res?.data?.code === "LVLI0011") {
        setRecords([]);
        setTotalRecords(0);
      }
    });
  };

  useEffect(() => {
    loadData(filters);
  }, [filters]);

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddApprovalFlowChart");
    isAllowedView = permission && permission.includes("ViewApprovalFlowChart");
    isAllowedEdit = permission && permission.includes("EditApprovalFlowChart");
    isAllowedDelete =
      permission && permission.includes("DeleteApprovalFlowChart");
  }
  const columns = [
    {
      field: "userName",
      headerName: `${t("table_approval.person")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip title={e.row.userName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.userName)}</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "createAt",
      headerName: `${t("table_approval.createtime")}`,
      flex: 1,
      renderCell: (e) => (
        <>
          <Tooltip
            title={moment(e.row.createdAt).format(dateTimeFormat)}
            arrow
            placement="bottom"
          >
            <span>
              {CommonUtil.formatLongText(
                moment(e.row.createdAt).format(dateTimeFormat)
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      headerName: `${t("common.actions")}`,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedDelete && (
            <Tooltip title={t("common.delete")} sx={{ marginLeft: 1 }}>
              <DeleteIcon
                onClick={() => handleActions("Delete", e.row)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {/* {isAllowedEdit && (
                <Tooltip title={t("common.edit")} sx={{ marginLeft: 1 }}>
                  <EditIcon
                      onClick={() => handleActions("Edit", e.row)}
                      style={{
                        alignSelf: "center",
                        paddingTop: "0px",
                        cursor: "pointer",
                        opacity: "0.6",
                        height: "17px",
                        width: "20px",
                        padding: "2px",
                      }}
                  />
                </Tooltip>
            )} */}
        </IconHandaler>
      ),
    },
  ];

  const handleDelete = (data) => {
    var request = {
      id: data.id,
    };
    deleteApprovalFlowChartListById(request).then((response) => {
      if (response.data.code === "LVLI0000") {
        enqueueSnackbar(t("tips_approval.deteled"), {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
        loadData(filters);
      }
      if (response.data.code === "LVLE0054") {
        enqueueSnackbar("No Recors Found", {
          variant: "error",
        });
        setConfirm(false);
        loadData(filters);
      }
      if (response.data.code === "LVLE0000") {
        setConfirm(false);
        loadData(filters);
      }
      setConfirm(false);
      loadData(filters);
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      //navigate(REACT_PRODUCT_VIEW + data);
    }
    if (action === "Edit") {
      const id = data.id;
      navigate(REACT_APPROVAL_FLOW_CHART_EDIT + id, {
        state: data,
      });
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const toolbarProps = {
    // add: true,
    add: isAllowedCreate,
    filter: false,

    refresh: true,
    onAdd: (data) => {
      navigate(REACT_APPROVAL_FLOW_CHART_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...filters, ...defaultFilters });
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageSize: defaultFilters.pageSize,
      pageNumber: e,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...filters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  return (
    <ListLayout title={t("table_approval.title")} toolbarProps={toolbarProps}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => handleDelete(id)}
            >
              {t("common.delete")}
            </Button>
          </Box>
        </Grid>
      </Dialog>
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onSelection={() => console.log()}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
      />
    </ListLayout>
  );
}
