import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import ZKDigimaxLogo from "../../assets/images/ZKDIGIMAX LOGO - NEW - 1 (4).svg";
import { getSubscriptionByClientId } from "../../services/SubscriptionService";
import { t } from "i18next";
import { getVersionData } from "@s/UserService";
import dayjs from "dayjs";
export default function About() {
  const [daysRemaning, setDaysRemaning] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [daysUsed, setDaysUsed] = useState("");
  const [totalDevices, setTotalDevices] = useState("");
  const [totalDevicesQuota, setTotalDevicesQuota] = useState("");
  const [leftDevice, setLeftDevice] = useState("");
  const [flagZero, setFlagZero] = useState("");
  const [versionData, setVersionData] = useState([]);
  const [versionInfo, setVersionInfo] = useState({});
  const calculateProgress = (used, total) => (used / total) * 100;

  // const versionInfo = {
  //   version: `SCREEN DIRECT ${versionData[0]["git.build.version"]}`,
  //   recommendedBrowser: "Firefox 27+/Chrome 33+/Edge",
  //   displayResolution: "Suggested screen resolution: 1920x1080 or higher",
  // };

  useEffect(() => {
    const fetchVersionData = async () => {
      const res = await getVersionData();
      const data = res?.data?.data;
      setVersionData(data);

      // 检查 versionData 是否有效
      if (data && data.length > 0) {
        const version = data[0]["git.build.version"];
        const name = data[0]["git.table.name"];
        const build = data[0]["git.build.time"];

        setVersionInfo({
          version: `${name} ${version} (Build:${dayjs(build).format(
            "YYYYMMDD"
          )})`,
          recommendedBrowser: "Firefox 27+/Chrome 33+/Edge",
          displayResolution: "Suggested screen resolution: 1920x1080 or higher",
        });
      }
    };

    fetchVersionData();
  }, []);
  const packageDetails = {
    remainingValidDays: { used: daysUsed, total: totalDays },
    remainingDeviceCount: { used: flagZero, total: totalDevicesQuota },
  };

  useEffect(() => {
    getSubscriptionByClientId().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setDaysRemaning(res.data.data.daysLeft);
        setTotalDays(res.data.data.totalDays);
        setDaysUsed(res.data.data.daysUsed);
        setTotalDevices(res.data.data.totalDevices);
        setTotalDevicesQuota(res.data.data.totalDevicesQuota);
        setLeftDevice(res.data.data.leftDevice);
        setFlagZero(res.data.data.addedDeviceCount);
        // console.log(res);
      }
    });
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ background: "#FAFAFA", padding: 2, width: 850 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box>
                    <img
                      src={ZKDigimaxLogo}
                      alt="ZK DIGIMAX"
                      style={{ width: 150, marginBottom: 16 }}
                    />
                    <Typography variant="h6">{t("about.version")}</Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {versionInfo.version}
                    </Typography>
                    <Typography variant="h6">
                      {t("about.about_system")}
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {versionInfo.recommendedBrowser}
                    </Typography>
                    <Typography variant="h6">
                      {t("about.display_resolution")}
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {versionInfo.displayResolution}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h6">
                      {t("about.package_details")}
                    </Typography>
                    <Box
                      padding={2}
                      border={1}
                      borderColor="grey.300"
                      borderRadius={2}
                      marginBottom={2}
                      sx={{ backgroundColor: "white" }} // Set background to white
                    >
                      <Typography>
                        {t("about.remaining_valid_days")} {daysRemaning}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={calculateProgress(
                          packageDetails.remainingValidDays.used,
                          packageDetails.remainingValidDays.total
                        )}
                        sx={{ height: 10, borderRadius: 5, marginBottom: 1 }}
                      />
                      <Box display="flex" justifyContent="space-between">
                        <Typography>
                          {t("about.no_used")}{" "}
                          {packageDetails.remainingValidDays.used}
                        </Typography>
                        <Typography>
                          {t("about.total_no_limit")}{" "}
                          {packageDetails.remainingValidDays.total}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      padding={2}
                      border={1}
                      borderColor="grey.300"
                      borderRadius={2}
                      sx={{ backgroundColor: "white" }} // Set background to white
                    >
                      <Typography>
                        {t("about.remaining_device_count")} {leftDevice}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={calculateProgress(
                          packageDetails.remainingDeviceCount.used,
                          packageDetails.remainingDeviceCount.total
                        )}
                        sx={{ height: 10, borderRadius: 5, marginBottom: 1 }}
                      />
                      <Box display="flex" justifyContent="space-between">
                        <Typography>
                          {t("about.no_used")}{" "}
                          {packageDetails.remainingDeviceCount.used}
                        </Typography>
                        <Typography>
                          {t("about.total_no_limit")}{" "}
                          {packageDetails.remainingDeviceCount.total}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box textAlign="center" marginTop={2}>
        <Typography> Copyright 2024 &copy; ZKDigimax</Typography>
      </Box>
    </Box>
  );
}
