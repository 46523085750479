import { useTranslation } from "react-i18next";
import RightViewLayout from "../../components/RighViewLayout";
import {
  REACT_TEMPLATE_LIST,
  REACT_UPDATE_TEMPLATE,
  REACT_TEMPLATE_EDITOR,
} from "../../router/ReactEndPoints";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  findTemplateById,
  getDownloadUrl,
  updateTemplate,
} from "../../services/TemplateService";
import { useSnackbar } from "notistack";
import axios from "axios";
import CommonUtil from "../../util/CommonUtils";
import { common } from "@mui/material/colors";

export default function EditTemplate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();
  const [templateImage, setTemplateImage] = useState();
  const direction = ["vertical", "horizontal"];
  const templateTypeOptions = [
    "General Use",
    "Discount",
    "By Unit",
    "By Value",
    "Special Promotion",
  ];
  // const resolution = ["152*152", "202*104", "250*128", "296*128", "400*300", "640*384", "296*152", "360*240", "648*480", "416*240", "320*240"]
  const resolution = [
    "200*200",
    "202*104",
    "250*128",
    "296*152",
    "320*240",
    "400*300",
    "648*480",
  ];
  const [model, setModel] = useState([]);
  const [resolutionValue, setResolutionValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [templateType, setTemplateType] = useState("");

  const [payload, setPayload] = useState({
    name: "",
    screenOriantation: "",
    screenResolution: "",
    size: "",
    model: "",
    type: "",
  });
  const [error, setError] = useState({
    name: "",
    screenOriantation: "",
    screenResolution: "",
    model: "",
    type: "",
    size: "",
  });

  const myMap = new Map();
  // myMap.set("152*152", ["ESL-15BW", "ESL-15R"]);
  // myMap.set("202*104", ["ESL-21BW"]);
  // myMap.set("250*128", ["ESL-21R", "ESL-21BWRY", "ESL-21MBW"]);
  // myMap.set("296*128", ["ESL-29BW", "ESL-29R", "ESL-29BWRY"]);
  // myMap.set("400*300", ["ESL-42BW", "ESL-42R", "ESL-42BWRY"]);
  // myMap.set("640*384", ["ESL-75BW", "ESL-75R", "ESL-75BWRY"]);
  //
  // myMap.set("296*152", ["ESL-26BW", "ESL-26R", "ESL-26BWRY"]);
  // myMap.set("360*240", ["ESL-35BW", "ESL-35R", "ESL-35BWRY"]);
  // myMap.set("648*480", ["ESL-58BW", "ESL-58R", "ESL-58BWRY"]);
  // myMap.set("416*240", ["ESL-37BW", "ESL-37R"]);
  //
  // myMap.set("320*240", ["ESL-102BW"]);

  myMap.set("200*200", ["DG04-A2F15"]);
  myMap.set("202*104", ["DG04-A2P21"]);
  // myMap.set("212*104", ["DG04-A2P21"]);
  myMap.set("250*128", ["DG04-A2F21"]);
  // myMap.set("296*128", ["DG04-A2F29"]);
  myMap.set("400*300", ["DG04-A2F42"]);
  // myMap.set("640*384", ["ESL-75BW", "ESL-75R", "ESL-75BWRY"]);

  myMap.set("296*152", ["DG04-A2F26"]);
  // myMap.set("360*240", ["DG04-A2F35"]);
  myMap.set("320*240", ["DG04-B2F24"]);
  myMap.set("648*480", ["DG04-A2F58"]);
  // myMap.set("416*240", ["ESL-37BW", "ESL-37R"]);

  // myMap.set("320*240", ["DG04-B2F24"]);
  // myMap.set("800*400", ["DG04-A2F75"]);

  useEffect(() => {
    findTemplateById(id).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setPayload({ ...payload, ...res.data.data });
      }
    });
  }, []);

  getDownloadUrl(payload.templateObjectKey).then((res) => {
    if (res?.data?.data && res?.data?.code === "LVLI0000") {
      setImage(res.data.data.downloadUrl);
    }
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const isValidForm = () => {
    if (CommonUtil.isEmpty(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return false;
    }
    if (CommonUtil.isEmpty(payload.screenResolution)) {
      setError({
        ...error,
        screenOriantation: t("tips.required"),
      });
      return false;
    }

    if (!CommonUtil.isValidResolution(payload.screenResolution)) {
      setError({
        ...error,
        screenResolution: t("EPLSCN0022"),
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.type)) {
      enqueueSnackbar(t("tips.required"), {
        variant: "error",
      });
      setError({
        ...error,
        type: t("tips.required"),
      });

      return false;
    }

    return true;
  };

  const handleSave = (e) => {
    if (isValidForm()) {
      updateTemplate(payload).then((res) => {
        if (res?.data?.code === "LVLI0003" && res?.data?.data) {
          enqueueSnackbar(t("tips_Template.updated"), {
            variant: "success",
          });
          navigate(REACT_TEMPLATE_LIST);
          return;
        }
      });
    }
  };

  const handleSubmit = (e) => {
    if (isValidForm()) {
      getDownloadUrl(payload.templateObjectKey);
      const name = payload.name;
      const screenResolution = payload.screenResolution;
      const screenOriantation = payload.screenOriantation;
      const model = payload.model;
      const type = payload.type;
      const templateObjectKey = payload.templateObjectKey;
      const templateImage = image;
      const id = payload.id;
      const imageObjectKey = payload.imageDownloadObjectKey;

      const templateJson = payload.templateJson;

      navigate(REACT_TEMPLATE_EDITOR, {
        state: {
          name,
          screenResolution,
          screenOriantation,
          model,
          type,
          templateObjectKey,
          templateImage,
          id,
          imageObjectKey,
          templateJson,
        },
      });
    }
  };
  return (
    <RightViewLayout
      id="edittempback"
      title={t("template.edit_template")}
      navigateBack={REACT_TEMPLATE_LIST}
    >
      <Grid sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box" }}>
        <Grid container px={2}>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                id="AddTemplate1"
                required
                label={t("template.name")}
                size="small"
                name="name"
                value={payload.name}
                error={error.name}
                resetError={() => setError({ ...error, name: "" })}
                inputProps={{
                  maxLength: 50,
                }}
                handleChange={handleChange}
                helperText={error.name}
                placeholder={t("tips_Template.name")}
              />
            </Grid>

            {/* <Grid item md={4} xs={12} ></Grid> */}
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("template.resolution")}{" "}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                id="AddTemplateResolution"
                disabled
                options={resolution}
                getOptionLabel={(option) => option}
                value={payload.screenResolution}
                onChange={(e, v) => {
                  setModelValue("");
                  setResolutionValue(v ? v : "");
                  setPayload({
                    ...payload,
                    screenResolution: v ? v : "",
                  });
                  setError({
                    ...error,
                    screenResolution: `${t("")}`,
                  });
                  if (v !== null) {
                    setModel(myMap.get(v));
                  }
                  if (v === null) {
                    setModel([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="screenResolution"
                    size="small"
                    error={error.screenResolution}
                    helperText={error.screenResolution}
                    sx={{
                      "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
                        fontSize: "13px",
                        padding: "12px",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("template.screen_model")}{" "}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>

              <Autocomplete
                id="AddTemplateModel"
                disabled
                noOptionsText={t("tips.no_options")}
                options={model}
                value={payload.model}
                getOptionLabel={(option) => option}
                onChange={(e, v) => {
                  setModelValue(v);
                  setPayload({
                    ...payload,
                    model: v ? v : "",
                  });
                  setError({
                    ...error,
                    model: `${t("")}`,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="screenModel"
                    size="small"
                    error={error.model}
                    helperText={error.model}
                    sx={{
                      "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
                        fontSize: "13px",
                        padding: "12px",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("template.screen_direction")}
              </InputLabel>
              <Autocomplete
                id="AddTemplateDirection"
                disabled
                options={direction}
                getOptionLabel={(option) => option}
                value={payload.screenOriantation}
                onChange={(e, v) => {
                  setPayload({
                    ...payload,
                    screenOriantation: v,
                  });
                  setError({
                    ...error,
                    screenOriantation: `${t("")}`,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="screenOriantation"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
                        fontSize: "13px",
                        padding: "12px",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("template.type")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                disabled
                id="AddTemplateType"
                options={templateTypeOptions}
                getOptionLabel={(option) => option}
                value={payload.type}
                onChange={(e, v) => {
                  setTemplateType(v);
                  setPayload({
                    ...payload,
                    type: v,
                  });
                  setError({
                    ...error,
                    type: `${t("")}`,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="type"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
                        fontSize: "13px",
                        padding: "12px",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid container md={12} xs={12}>
              <Grid item md={6} xs={12} />
              <Grid item md={6} xs={12} mb={2}>
                <Box
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  style={{ marginTop: "30px", width: "100%" }}
                >
                  <Box item>
                    <Button
                      id="addtempnext"
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      style={{
                        size: "medium",
                        background:
                          "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        opacity: 1,
                      }}
                      onClick={handleSubmit}
                    >
                      {t("common.next")}
                    </Button>
                  </Box>
                  <Box item mr={2}>
                    <Button
                      id="addtempnext"
                      variant="outlined"
                      size="large"
                      className="text-transform-none"
                      onClick={handleSave}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item mr={2}>
                    <Button
                      id="addtempcan"
                      className="text-transform-none"
                      variant="none"
                      onClick={() => navigate(REACT_TEMPLATE_LIST)}
                      size="large"
                      style={{
                        size: "medium",
                        borderRadius: "8px",
                        opacity: 1,
                        color: "#1487CA",
                      }}
                    >
                      {t("common.cancel")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
