import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import backgroundImage from "../../assets/images/Background_Image_1.svg";
import { ReactComponent as Logo } from "../../assets/images/L3ePriceLabel.svg";
import Footer from "../../components/layout-components/Footer";

export default function OnboardLayout(props) {
  const bgStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };
  return (
    <Grid container sx={{ display: "flex", flexWrap: "wrap", width: "100vw", justifyContent: "center", alignContent: "center", backgroundImage: `url(${backgroundImage})` }} >
      <Grid item component={Paper} elevation={1} style={{  top: "30px", width: "500px",
        height: "450px", background: "#FFFFFF", borderRadius: "10px",
        opacity: "1", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
      }}>
        <Grid item sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center" }}>
          <Logo height={"40px"} width={"300px"} />
        </Grid>
        <Grid display={"flex"} justifyContent={"flex-start"}
          sx={{ pt: "20px", pb: "20px", width: "90%", height: "34px", }}>
          <Typography sx={{ fontWeight: 'bold' }}>{props.label ? props.label : "Welcome"}</Typography>
        </Grid>
        <Grid item sx={{ flexWrap: "wrap", pt: "20px" }}>
          <div style={{ width: "100%" }}>
            {props.children}
          </div>
        </Grid>
      </Grid>
      <Grid style={{ bottom: "1px", minWidth: "100%", position: "fixed" }} >
        <Footer />
      </Grid>
    </Grid>

  );
}
