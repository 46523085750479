import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MinervaIoT from '../../assets/images/MinervaIoT_Logo.svg';

import { useTranslation } from 'react-i18next';

const MinervaIoTImg = styled('img')({
  height: ' 8px',
});

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <Grid display={'flex'} minWidth='100vw' justifyContent={'center'}>
      <Grid pr={1}>
        <Typography variant='label'> {t('Powered by')} </Typography>
      </Grid>
      <Grid pr={3} paddingTop='2px'>
        <MinervaIoTImg alt='complex' src={MinervaIoT} />
      </Grid>
    </Grid>
  );
}
