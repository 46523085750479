import React, { useEffect, useState, useRef } from "react";
import DataTable from "../../components/DataTable";
import IconHandaler from "../../components/IconHandaler";
import { Tooltip, Typography } from "@mui/material";
import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import { ReactComponent as UpdateIcon } from "../../assets/Update_apk.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import UploadUpgrade from "./UploadUpgrade";
import {
  REACT_DEVICE_ADD_INSTRUCTIONS,
  REACT_DEVICE_GATEWAY,
  REACT_DEVICE_VIEW,
} from "../../router/ReactEndPoints";
//
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CommonUtil from "../../util/CommonUtils";
import { deviceList, unBindDevice } from "../../services/DeviceService";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import DeviceListLayout from "../../components/layout-components/DeviceListLayout";
import moment from "moment-timezone";
import { dateTimeFormat } from "@/constants/TimeFormat";

export default function Device() {
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();
  const [siteIds, setSiteIds] = useState([]);
  const [foundItems, setFoundItems] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [deviceManufacturer, setDeviceManufacturer] = useState("0");
  let outlet = {};
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  let isAllowedBindScreen = true;

  const upload = useRef(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
    id: "",
    sn: "",
    deviceModel: "",
    deviceType: "",
    clientId: "",
    deviceAlias: "",
    mac: "",
    siteId: "",
    zoneId: "",
    checkInOrOutType: "",
    ip: "",
    registrationDevice: "",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 8,
    id: "",
    sn: "",
    deviceModel: "",
    deviceType: "",
    clientId: "",
    deviceAlias: "",
    mac: "",
    siteId: "",
    zoneId: "",
    checkInOrOutType: "",
    ip: "",
    registrationDevice: "",
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  useEffect(() => {
    loadData();
    const ids = records?.map((record) => record.siteId);
    setSiteIds(ids);
  }, [filters]);

  const loadData = () => {
    deviceList(filters).then((res) => {
      setRecords(res?.data?.data?.devices || []);
      setTotalRecords(res?.data?.data?.totalCount);
      setPage(res?.data?.data?.currentPage);
      setRowsPerPage(res?.data?.data?.totalCount);
    });
  };

  const updateDeviceIds = new Set(
    records
      ?.filter((item) => {
        if (item?.parameters?.hasOwnProperty("device_manufacturer")) {
          return item.parameters.device_manufacturer === "0";
        }
      })
      ?.map((item) => item.id)
  );
  const [siteFilters, setSiteFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    ids: [],
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
      localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const getStatus = (data) => {
    return data === 1 ? "online" : "offline";
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddGateway");
    isAllowedView = permission && permission.includes("ViewGateway");
    isAllowedEdit = permission && permission.includes("EditGateway");
    isAllowedDelete = permission && permission.includes("DeleteGateway");
    isAllowedBindScreen = permission && permission.includes("BindingScreen");
  }

  let roleCode = JSON.parse(localStorage.getItem("USER"))?.roleCode;
  const getZoneUpdatedTime = (e) => {
    return moment.utc(e).tz(moment.tz.guess()).format(dateTimeFormat);
  };

  const columns = [
    {
      field: "sn",
      headerName: `${t("gateway.gateway")}`,

      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.sn} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
    {
      field: "deviceAlias",
      headerName: `${t("gateway.gatewayName")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.deviceAlias} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
    {
      field: "GatewayIp",
      headerName: `${t("gateway.gatewayIPAddress")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.deviceModel} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.ip)}</span>
        </Tooltip>
      ),
    },
    {
      field: "deviceType",
      headerName: `${t("gateway.gatewayFirmware")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.deviceType} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.fwVersion)}</span>
        </Tooltip>
      ),
    },

    {
      field: "status",
      headerName: `${t("gateway.gatewayRunningStatus")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={getStatus(e.row.status)} arrow placement="bottom">
          {getStatus(e.row.status) === "online" ? (
            <span style={{ color: "green" }}>
              {CommonUtil.formatLongText(getStatus(e.row.status))}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {CommonUtil.formatLongText(getStatus(e.row.status))}
            </span>
          )}
        </Tooltip>
      ),
    },
    {
      field: "updatedAt",
      // headerName: `${t('gateway.gatewayName')}`,
      headerName: `${t("gateway.gatewayUpdateTime")}`,
      flex: 1,
      renderCell: (e) => (
        <Tooltip
          title={getZoneUpdatedTime(e.row.updatedAt, e.row.timeZone)}
          arrow
          placement="bottom"
        >
          <span>{getZoneUpdatedTime(e.row.updatedAt, e.row.timeZone)}</span>
        </Tooltip>
      ),
    },
    {
      headerName: `${t("common.actions")}`,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => {
        return (
          <IconHandaler>
            {isAllowedView && (
              <Tooltip title={t("common.view")} arrow>
                <VisibilityIcon
                  onClick={() => handleActions("View", e)}
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    cursor: "pointer",
                    opacity: "0.6",
                    height: "17px",
                    width: "20px",
                    padding: "2px",
                  }}
                />
              </Tooltip>
            )}
            {isAllowedDelete && (
              <Tooltip title={t("common.delete")}>
                <DeleteIcon
                  id="Device-delete-icon"
                  onClick={() => handleActions("Delete", e.row.id)}
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    paddingLeft: "5px",
                    // opacity: "1",
                    cursor: "pointer",
                    opacity: "0.6",
                    padding: "2px",
                  }}
                />
              </Tooltip>
            )}

            {isAllowedBindScreen && (
              <Tooltip title={t("common.update")}>
                {updateDeviceIds.has(e.row.id) && roleCode === "OWNER" ? (
                  <UpdateIcon
                    id="Device-delete-icon"
                    onClick={() => handleActions("Upgrade", e.row.id)}
                    style={{
                      alignSelf: "center",
                      paddingTop: "0px",
                      paddingLeft: "5px",
                      // opacity: "1",
                      cursor: "pointer",
                      opacity: "0.6",
                      padding: "2px",
                    }}
                  />
                ) : null}
              </Tooltip>
            )}
          </IconHandaler>
        );
      },
    },
  ];

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  //设备id集合
  const [screenIdList, setScreenIdList] = useState([]);
  const handleActions = (action, data) => {
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    } else if (action === "View") {
      const sn = data.row.id;
      navigate(REACT_DEVICE_VIEW, { state: { data: sn } });
    } else if (action === "Upgrade") {
      setOpenUpload(true);
      let ids = [];
      ids.push(data);
      setScreenIdList(ids);
    }
  };

  const handleDelete = (id) => {
    unBindDevice(id).then((response) => {
      if (response?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("tips_gateway.deviceUnbindSuccess"), {
          variant: "success",
        });

        setConfirm(false);
        loadData();
      } else
        enqueueSnackbar(response?.data?.message, {
          variant: "error",
        });
      setConfirm(false);
    });
  };

  const toolbarProps = {
    //add: true,
    add: isAllowedCreate,
    filter: false,
    refresh: true,
    update: updateDeviceIds.size !== 0 && roleCode === "OWNER",
    onAdd: (data) => {
      navigate(REACT_DEVICE_ADD_INSTRUCTIONS);
    },
    onRefresh: (data) => {
      setFoundItems([]);
      setSearchValue("");
      setFilters({
        ...defaultFilters,
      });
      navigate(REACT_DEVICE_GATEWAY);
    },
    onFilter: (data) => {
      console.log("onFilter");
    },

    onUpdate: (data) => {
      setOpenUpload(true);
      let idsList = [];
      records.map((item) => {
        idsList.push(item.id);
      });

      setScreenIdList(idsList);
    },
  };

  const DeviceFilter = {
    onReset: () => {
      setFilters({ ...filters, ...defaultFilters });
    },
    onFilter: (filter) => {
      let companies = [];
      if (CommonUtil.isEmpty(filter)) {
        filter = { ...defaultFilters };
      } else if (filter.client !== undefined && filter.client !== "-1") {
        companies.push(filter.client);
      }

      setFilters({
        ...filters,
        siteId: filter?.outlet,
      });
      console.log(filter?.outlet);
    },
    onClientChange: (id) => {
      if (id === "-1") {
        setFilters({
          ...filters,
          companyIds: [],
        });
      }
    },
  };

  const getRowId = (device) => device.id;
  const handleSearchClick = () => {
    if (!searchValue.trim()) {
      setFoundItems([]);
      setFilters({
        ...defaultFilters,
      });
      setSearchValue("");
      return;
    }
    setFilters({
      ...defaultFilters,
      sn: searchValue,
      operator: "AND",
    });
    const suggestions = records?.filter(
      (record) =>
        // console.log(record?.sn == searchValue)
        record?.sn == searchValue
    );
    setSearchSuggestions(suggestions);
    setFoundItems(suggestions);
  };

  useEffect(() => {
    if (foundItems.length != 0) {
      setRecords(foundItems);
      setFoundItems([]);
    }
  }, [foundItems, records]);

  // 关闭
  const handlCloseCancel = () => {
    setOpenUpload(false);
    setScreenIdList([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(); // 调用搜索函数
    }
  };

  const handlerClear = () => {
    setSearchValue("");
    setFilters({
      ...defaultFilters,
      name: "",
    });
  };

  const searchClientProps = () => {
    return (
      <TextField
        label={t("tips_gateway.searchByDeviceSn")}
        value={searchValue}
        onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          style: {
            height: 50,
            width: "300px",
            background: "#fff",
            color: "#474B4F",
            opacity: "0.6",
            boxShadow: "0px 1px 3px #0000001A",
            borderRadius: "8px",
            border: "0px !important",
            padding: "10px",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                id="clearButton"
                onClick={handlerClear}
                style={{ visibility: searchValue ? "visible" : "hidden" }} // 根据输入框内容控制可见性
              >
                <ClearIcon />
              </IconButton>
              <IconButton id="Device1" onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiInputLabel-root": {
            color: "#D1D1D1",
          },
        }}
      />
    );
  };

  const isRowSelectable = (params) => {
    return updateDeviceIds.has(params.id);
  };

  const hanlderUpdate = (e) => {
    let updataDevice = [];
    updataDevice.push(e);
    setScreenIdList(updataDevice);
  };
  return (
    <DeviceListLayout
      navigateBack={false}
      title={t("menu.gateway")}
      //DeviceFilter={DeviceFilter}
      toolbarProps={toolbarProps}
      searchProps={searchClientProps()}
    >
      <DataTable
        columns={columns}
        rows={records}
        onSelection={(e) => hanlderUpdate(e)}
        getRowId={getRowId}
        page={filters.pageNumber - 1}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        checkboxSelection={roleCode === "OWNER" ? true : false}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
        disableSelectionOnClick={true}
        isRowSelectable={isRowSelectable}
      />

      <UploadUpgrade
        ref={upload}
        open={openUpload}
        onCancel={handlCloseCancel}
        // deviceListID={deviceListID}
        screenIdList={screenIdList}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => handleDelete(id)}
            >
              {" "}
              {t("common.delete")}{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </DeviceListLayout>
  );
}
