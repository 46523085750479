import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconHandaler from "../../components/IconHandaler";
import ListLayout from "../../components/ListLayout";
import {
  REACT_PICTURE_LIBRARY,
  REACT_PICTURE_LIBRARY_ADD,
  REACT_PICTURE_LIBRARY_EDIT,
} from "../../router/ReactEndPoints";
import {
  deletePicture,
  getDownloadUrl,
  pictureList,
  getDownloadUrlBatch,
} from "../../services/PictureService";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import DataTable from "../../components/DataTable";

export default function PictureDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [urls, setUrls] = useState([]);
  const [downloadUrlObjData, setDownloadUrlObjData] = useState("");
  let [objectKeys, setObjectKeys] = useState([]);
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  const retailClient = localStorage.getItem("RETAIL_CLIENT");
  let isAllowedCreate = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
    name: "",
  });
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };
  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
    name: "",
  };

  const handleChangePage = (newPage) => {
    setFilters({
      ...defaultFilters,
      pageNumber: newPage,
      pageSize: rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setFilters({ ...defaultFilters, pageNumber: 0, pageSize: pageSize });
    setRowsPerPage(pageSize);
  };

  const loadData = () => {
    pictureList(filters).then((response) => {
      if (response?.data?.code === "LVLI0000" && response?.data?.data) {
        setRecords(response?.data?.data?.picturesData?.picturesData);
        const keys = response?.data?.data?.picturesData?.picturesData?.map(
          (e) => e.objectKey
        );
        setObjectKeys(keys);
        setTotalRecords(response?.data?.data?.picturesData?.totalCount);
        setDownloadUrlObjData(response?.data?.data?.downloadUrlData);
        navigate(REACT_PICTURE_LIBRARY);
      } else if (response?.data?.code === "LVLE0000") {
        setRecords([]);
        setTotalRecords(0);
      }
    });
  };

  const displaydownloadUrl = (objectKey) => {
    const displayUrl = downloadUrlObjData.find(
      (url) => url.objectKey === objectKey
    );
    return displayUrl.downloadUrl;
  };

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
    });
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  useEffect(() => {
    if (objectKeys && objectKeys.length > 0) {
      getDownloadUrlBatch(objectKeys).then((res) => {
        if (res?.data?.code === "LVLI0000" && res?.data?.data) {
          setUrls(res?.data?.data);
        }
      });
    }
  }, [objectKeys]);

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddPictureDetails");
    isAllowedEdit = permission && permission.includes("EditPictureDetails");
    isAllowedDelete = permission && permission.includes("DeletePictureDetails");
  }

  const columns = [
    {
      field: "pictureName",
      headerName: t("picture_library.name"),
      flex: 1,
      renderCell: (e) => e.row.pictureName,
    },
    {
      field: "pictureId",
      headerName: t("picture_library.id"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip>
          <span>{e.row.pictureId}</span>
        </Tooltip>
      ),
    },
    {
      field: "objectKey",
      headerName: t("picture_library.perview"),
      flex: 1,
      renderCell: (e) => (
        <>
          <Avatar
            sx={{ bgcolor: "#C0C0C0", marginRight: "16px" }}
            imgProps={{ draggable: "false" }}
            alt={e.row.firstName}
            src={displaydownloadUrl(e.row.objectKey)}
            variant="circular"
          ></Avatar>
        </>
      ),
    },
    {
      field: "pictureType",
      headerName: t("picture_library.type"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip>
          <span>{e.row.pictureType}</span>
        </Tooltip>
      ),
    },
    {
      field: "size",
      headerName: t("picture_library.size"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip>
          <span>{e.row.size}</span>
        </Tooltip>
      ),
    },
    {
      field: "picturePreProcessing",
      headerName: t("picture_library.progressing"),
      flex: 1.5,
      renderCell: (e) => (
        <Tooltip>
          <span>{e.row.picturePreProcessing}</span>
        </Tooltip>
      ),
    },
    {
      headerName: t("common.actions"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => {
        const canEdit =
          (e.row.type !== "Default" && roleName !== "OWNER" && isAllowedEdit) ||
          (e.row.type !== "Default" &&
            roleName === "OWNER" &&
            retailClient !== "") ||
          (roleName === "OWNER" && retailClient === "");

        const canDelete =
          (e.row.type !== "Default" &&
            roleName !== "OWNER" &&
            isAllowedDelete) ||
          (e.row.type !== "Default" &&
            roleName === "OWNER" &&
            retailClient !== "") ||
          (roleName === "OWNER" && retailClient === "");

        return (
          <IconHandaler>
            {canEdit && (
              <Tooltip title={t("common.edit")} arrow>
                <EditIcon
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    cursor: "pointer",
                    opacity: "0.6",
                    height: "17px",
                    width: "20px",
                    padding: "2px",
                  }}
                  onClick={() => handleActions("Edit", e.id)}
                />
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title={t("common.delete")} arrow sx={{ marginLeft: 1 }}>
                <DeleteIcon
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    cursor: "pointer",
                    opacity: "0.6",
                    height: "17px",
                    width: "20px",
                    padding: "2px",
                  }}
                  onClick={() => handleActions("Delete", e.id)}
                />
              </Tooltip>
            )}
          </IconHandaler>
        );
      },
    },
  ];

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  const handleDelete = (id) => {
    deletePicture(id).then((response) => {
      if (response.data.code === "LVLI0002") {
        enqueueSnackbar(t("tips_picture.deteled"), {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
        loadData();
        // navigate(REACT_PICTURE_LIBRARY);
      }
    });
  };

  const handleActions = (action, data) => {
    console.log(data);
    if (action === "Edit") {
      navigate(REACT_PICTURE_LIBRARY_EDIT + data);
    } else if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const getpicPreview = (key) => {
    getDownloadUrl(key.value).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        let pictureLibrary = [];
        records.map((e) => {
          let picture = e;

          if (e.objectKey === key.value) {
            picture.pictureUrl = res.data.data.downloadUrl;
          }

          pictureLibrary.push(picture);
        });
        setRecords(pictureLibrary);
      }
    });
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchClientProps = () => {
    return (
      <Grid container display={"flex"} justifyContent={"flex-end"} pb={0.5}>
        <Autocomplete
          options={searchSuggestions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("tips.search_by_name")}
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                style: {
                  height: 50,
                  width: "400px",
                  background: "#fff",
                  color: "#474B4F",
                  opacity: "0.6",
                  boxShadow: "0px 1px 3px #0000001A",
                  borderRadius: "8px",
                  border: "0px !important",
                  padding: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton id="retailClint" onClick={handleSearchClick}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#D1D1D1",
                },
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const toolbarProps = {
    // add: true,
    add: isAllowedCreate,
    filter: false,
    refresh: true,

    onAdd: (data) => {
      navigate(REACT_PICTURE_LIBRARY_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
      // loadData();
      // navigate(REACT_PICTURE_LIBRARY);
    },
  };

  return (
    <ListLayout
      navigateBack={false}
      title={t("picture_library.picture_library")}
      toolbarProps={toolbarProps}
      // searchProps={searchClientProps()}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "normal normal normal 14px / 22px Roboto" }}
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              {t("common.cancel")}{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
              }}
              onClick={() => handleDelete(id)}
            >
              {" "}
              {t("common.delete")}{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
      <DataTable
        columns={columns}
        rows={records}
        page={filters.pageNumber}
        onSelection={() => console.log()}
        // selectionModel={selectedRows}
        // checkboxSelection={(params) => {
        //   const id = params.row.id;
        // }}
        rowsPerPage={filters.pageSize}
        totalRecords={totalRecords}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </ListLayout>
  );
}
