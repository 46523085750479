export default {
  tips: {
    no_options: "No Options",
    search_by_name: "Search By Name",
    required: "This Field Is Required",
    invalid_email_address: "Invalid Email address",

    mobild_nubmer:
      "Mobile Number Including Country Code Must Be Between 8 to 23 Digits",
    alphabets: "Only Accepts Alphabets, Numerics",
    numerics_and_hyphen: "Only Accepts Alphabets, Numerics And Hyphen",
    numerics_and_space: "Only Accepts Alphabets, Numerics And Space",
    country_code_required: "Country Code Is Required",
    password_title:
      "To Keep Password Secure, It Must Contain: Capital Letters, Special Symbols, Numbers. Example : C89$Mp@q",
    numerics_and_underscore: "Only Accepts Alphabets, Numerics And Underscore",
    dot_and_space: "Only Accepts Alphabets, Numerics , Space And Dot",
    underscore_and_space:
      "Only Accepts Alphabets, Numerics , Space And Underscore",
    selected_detele_record: "Are You Sure Want To Delete Selected Record?",
    occurred: "An error occurred",
    no_record_found: "No Record Found.",
    no_of_records_rer_page: "No of Records Per Page",
    select_price_period: "Please select the price change period",

    outlet_code: "Please Enter Outlet Code",
    country_name: "Please Enter Country Name",
    state_name: "Please Enter State Name",
    city_name: "Please Enter City Name",
    city_zone: "Please Enter City Zone",
    address: "Please Enter Address",
    area_code: "Please Enter Area Code",
    upload_size: "The image cannot be larger than 3M",
    enter_number: "Please enter the number",
    search_retail_name: "Search by Retail Client Name",
  },

  common_tips: {
    country_code: "Please Enter Country Code",
    mobile_number: "Please Enter Mobile Number",
    first_name: "Please Enter First Name",
    last_name: "Please Enter Last Name",
    address: "Please Enter Address",
    country_name: "Please Enter Country Name",
    state_name: "Please Enter State Name",
    city_name: "Please Enter City Name",
    start_date: "Please Select Start Date",
    end_date: "Please Select Expire Date",
  },

  tips_retail_client: {
    name: "Please Enter Retail Name",
    code: "Please Enter Retail Code",
    email: "Please Enter Retail Email",
    devices: "Please Enter Number of Devices",
    mobile_required: "Mobile Number is Required",

    send_activation: "Are You Sure Want To Send Activation Mail?",
    activation_success: "Activation Mail Sent Successfully",
    created_success: "Client Created Successfully",
    updated_success: "Client Updated Successfully",
    deleted_success: "Client Deleted Successfully",
    user_exist: "User Already Exist.",
    given_name: "Client Already Exist With Given Name.",
    given_code: "Client Already Exist With Given Code.",

    subscription_start_date_invalid:
      "Invalid date, please select subscription start date.",
    subscription_expire_date_invalid:
      "Invalid date, please select subscription expire date.",
  },

  tips_Template: {
    name: "Please Enter Template Name",
    characters: "Name should Be Less Than 51 Characters.",
    screen_resolution: "Invalid Screen Resolution",
    success: "Template Created Successfully",
    updated: "Template Updated Successfully",
    deleted: "Template Deleted Successfully",
    not_deteled: "Default Template Can not be deleted",
    sure_delete: "Are you sure to delete the selected components?",
    can_not_deteled: "Template Sync With ESL, Can Not Be Deleted.",
    template_exist: "Template Name Already Exist",
  },

  tips_editor: {
    is_exists: "The element already exists",
    height_than: "Height Should Not Be Greater Than ",
    width_than: "Width Should Not Be Greater Than ",
    cannot_add_shape:
      "Cannot Add Shape When An Image With Equal Size Of Canvas.",
    cannot_add_text:
      "Cannot Add Text Label When An Image With Equal Size Of Canvas.",
    already_added: "Already Added.",

    cannot_add_image:
      "Cannot Add Image When An Image With Equal Size Of Canvas.",
    image_equal:
      "Image Has Equal Height And Width But Canvas Already Contains Objects.",
    adjusted_exceeds: "Adjusted Width Or Height Exceeds Canvas Width Height.",
    canvas_is_empty: "The Canvas Is Empty. Add Some Labels Or Image.",
    provide_image: "Please Provide Image To Template",
    text_price: "I'm a text piece",
    dimensions_height: "Height should not exceed canvas dimensions",
    dimensions_width: "Width should not exceed canvas dimensions",
  },

  tips_openAPI: {
    name: "PLease Enter Name",
    company_code: "PLease Enter Company Code",
    company_name: "PLease Enter Company Name",
    email: "PLease Enter E-Mail",
    email_exist: "PLease Email Already Exist.",
  },

  tips_picture: {
    id: "Please Enter Picture Id",
    name: "Please Enter Picture Name",
    image_resolution: "Image resolution must be below 400x300 pixels",
    file_size: "File size cannot exceed 2 MB",
    upload_type: "Upload .png,.jpeg,.jpg only",
    character_50: "pictureName cannot exceed 50 character",
    character_100: "pictureId cannot exceed 100 character",
    character_name: "First Name Cannot Exceed 100 Characters",
    pictureId_exist: "Picture Id Already Exist",
    upload_image: "Please Upload An Image",
    image_size: "Image should be below 2MB",
    support_size: "Supports .jpeg, .jpg, .png",
    resolution_size: "Resolution should be below 400*300",
    success: "Picture Created Successfully",
    updated: "Picture Updated Successfully",
    deteled: "Picture Deleted Successfully",
  },

  tips_dashboard: {
    than_start_date: "To Date Should Be Grater Than Start Date",
    than_from_date: "To Date Should Be Grater Than From Date",
    period_date: "Please Select  Period To Date",
    period_from_date: "Please Select Period From Date",
  },

  tips_authorization: {
    name: "Enter Authorization Level Name",
    requires_name: "Name Is Required",
    requires_permission: "Permission required",
    role_client_user: "Role Associated With Client User",
    deleted: "Authorization Deleted Successfully",
    created: "Authorization Created Successfully",
    updated: "Authorization Updated Successfully",
  },

  tips_product: {
    cannot_deleted: "The label is bound to the product and cannot be deleted!",
    last_deleted: "Last label cannot be deleted.",
    deleted_product: "Product Label Deleted Successfully",
    added_product: "Product Label Added Successfully",
    field_empty: "This Field Is Empty",

    product_name: "Please Enter Product Name",
    product_sku: "Please Enter Product SKU",
    category_level_1: "Please Enter Category Level 1",
    category_level_2: "Please Enter Category Level 2",
    category_level_3: "Please Enter Category Level 3",
    quantity: "Please Enter Quantity",
    barcode: "Please Enter Barcode",
    product_price: "Please Enter Product Price",
    discount_price: "Please Enter Discount Price",
    brand_name: "Please Enter Brand Name",
    origin_of_product: "Please Enter Origin Of Product",
    manufacture: "Please Enter Manufacture",
    qr_code: "Please Enter QR code",

    choose_excel_file: "Choose A Excel File To Upload Products",
    note_excel_format: "Note: Only Xls And Xlsx Formats Are Supported",
    download_excel_format: "Download Excel File Format",
    add_product_excel: "Add Product From Excel File",
    upload_picture: "Click Here To Upload Picture",
    country_name: "Please Enter Country Name",

    name_character_limit: "Name should be less than 51 characters.",
    file_size_limit: "File size cannot exceed 3 MB",
    upload_image_format: "(Upload Png, Jpeg, Jpg Only)",
    quantity_character_limit: "Quantity Can Not Be More Than 6 Characters",
    category_1_character_limit: "Category 1 should be less than 51 characters.",
    category_2_character_limit: "Category 2 should be less than 51 characters.",
    category_3_character_limit: "Category 3 should be less than 51 characters.",
    manufacturer_character_limit:
      "Manufacturer should be less than 51 characters.",
    origin_character_limit:
      "Origin of product should be less than 51 characters.",
    name_required: "Name Is Required",
    product_exists: "Product Already Exists With Given Name.",
    name_character_limit_50: "Name Should Be Less Than 50 Characters.",
    name_special_characters: "Name Should Not Contain Special Characters.",
    drag_and_drop_file: "Drag And Drop File Here Or Choose File",
    upload_file_format: "(upload.png.jpg)",

    product_deleted_success: "Product Deleted Successfully",
    product_created_success: "Product Created Successfully",
    accept_excel_format: "Accept Excel File Format Only!",
    product_updated_success: "Product Updated Successfully",
    product_change_confirmation:
      "Making changes to a product changes the associated price tag and the change event simultaneously.<br>Are you sure you want to change the product?",
    product_delete_confirmation:
      "Deleting a product synchronously removes the associated price change events and synchronized logs.",
    sure_change_product:
      "Making changes to a product changes the associated price tag and the change event simultaneously.<br>Are you sure you want to change the product?",
  },

  tips_outlet: {
    outlet_name: "Please Enter Outlet Name",
    outlet_given_name: "Outlet Already Exist With Given Name.",
    outlet_given_code: "Outlet Already Exist With Given Code.",
    name_length: "Name Should Be Less Than 50 Characters.",
    code_length: "Code Should Be Less Than 30 Characters.",
    code_alphanumeric: "Code Contains Only Alphanumeric Value.",
    code_exceed: "Code Can Not Exceed 30 Characters.",
    outlet_delete_restricted: "Outlet Mapped To Device Can Not Be Deleted",
    outlet_deleted: "Outlet Deleted Successfully.",
    outlet_created: "Outlet Created Successfully.",
    outlet_updated: "Outlet Updated Successfully.",
    select_outlet: "Please Select Outlet",
  },

  tips_user: {
    outlet_in_charge: "In Charge For Outlet",
    permission_group_name: "Permission Group Name",
    date_of_joining: "Date of Joining",
    first_name: "Please Enter First Name",
    last_name: "Please Enter Last Name",
    person_id: "Please Enter Person Id",
    enter_email: "Please Enter Email",
    select_date: "DD-MM-YYYY (Please Select Date)",
    first_name_limit: "First Name Cannot Exceed 100 Characters",
    allowed_file_types: "Allowed Only *JPEG, *JPG, *PNG",
    max_file_size: "Maximum Size Of 3 MB",
    user_exists: "User Already Exist.",
    phone_required: "Phone Is Required",
    outlet_required: "Outlet Is Required",
    role_required: "Role Is Required",
    activation_mail_confirmation: "Are You Sure Want To Send Activation Mail?",
    activation_mail_sent: "Activation Mail Sent Successfully",
    cannot_delete_default_user: "Default User CanNot Be Deleted.",
    person_deleted: "Person Deleted Successfully",
    person_created: "Person Created Successfully",
    person_updated: "Person Updated Successfully",
  },

  tips_gateway: {
    deviceNetworkConfigSteps: "Device Network Configuration Steps",
    step1PowerUp: "Step 1: Power Up And Turn On The Device",
    step1Description:
      "If The Device Has A Network, It Will Automatically Connect To The Network And Start Working.",
    step2ConfigureNetwork: "Step 2: Configure The Network",
    step2Description:
      "You May Use Bluetooth To Set Up The Network. Or Some Device Has Touch Screen That Has Network Setting In Firmware.",
    step3RestoreFactorySettings: "Step 3: Restore The Factory Settings",
    step3Description:
      "Some Devices Can Not Have Network Setting. You May Try To Reset The Device Factory Setting.",
    readInstructions: "I Have Read These Instructions",

    manualRegisterDevice: "Manual Register Device",
    powerUpAndSetNetwork: "Power Up And Set Device Network",
    step1EthernetConnection:
      "1. Plug In The Network Cable If Device Support Ethernet Function.",
    step2CommunicationSetting:
      "2. Enter Your Device Ethernet setting/WiFi Setting Menu To Enter Communication Setting Page. Network Setup Is Successful, Device Will Display A QR Code In Standby Page.",
    step3FindSerialNumber:
      "3. On The Side Of Device Box Or On The Back Of Device, Can Find The Device Serial Number.",
    step4FillSerialNumber: "4. Fill In Device Serial Number On System.",

    specifyDeviceForClient:
      "Please Specify The Device To A Retail Client And Outlet.",
    syncOutletTimeZone:
      "This Device Will Sync The Same Time Zone Of The Outlet.",

    selectFile: "Please select the file",
    versionStartWithV: "Must start with the letter V",
    enterUpgradePackageVersion:
      "Please enter the upgrade package version, starting with the letter 'V'",
    uploadSuccessful: "Upload Successful",
    uploadAndUpgrade: "Upload upgrade package and upgrade",
    uploadFile: "Upload File",
    unsupportedType: "The current type is not supported",
    fileSizeLimit:
      "File size exceeds limit: {{fileSize}}MB. Please upload files that are smaller than or equal to 100MB.",
    dragOrBrowse: "Drag or browse",
    onlyApkFormat: "Only allow uploading files in apk format",

    searchByDeviceSn: "Search By Device Sn",
    selectYourZone: "Please Select Your Zone",
    bindZone: "Bind Zone",
    enterDeviceSerialNumber: "Please Enter Device Serial Number",
    readInstructionsToAddDevice:
      "Please Read The Instructions And Check The Box To Add A Device.",

    deviceUnbindSuccess: "Device Unbind Successfully",
    deviceBindSuccess: "Device Bound Successfully",
  },

  tips_screens: {
    choose_Product_display: "Choose the Product want this screen display",
    choose_Template_display: "Choose the Templates want this screen display",
    selected_screen: "Selected Screen",
    searchby_screenId: "Search By Screen ID",
    enter_screen_name: "Please Enter Screen Name",
    enter_position_no: "Please Enter Position No",
    enter_gateway_sn: "Please Enter Gateway Sn",
    template_not_empty: "Template Should not be Empty",
    product_not_empty: "Product Should not be Empty",
    company_id_required: "CompayId is Required",
    select_one_screen: "Please Select Screen",
    confirm_unbind_device: "Are You Sure To Unbind Selected Device?",
    deviceUn_bind_success: "Device Unbind Successfully",
    screens_sync_success: "Screens sync successfully",
    select_promotion_StartTime: "Please select the start time of the promotion",
    select_or_screen: "Please select one or more screens",
  },

  tips_events: {
    deleted: "Price Change Event Deleted Successfully",
    created: "Price Change Event Created Successfully",
    updated: "Price Change Event Updated Successfully",
  },

  tips_approval: {
    change_status: "Are sure to change status?",
    updated: "Record Updated SuccessFully",
    not_empty: "Please select a Unique value and should not be Empty.",
    deteled: "Approval FlowChart Deleted successfully",
    added: "Approval FLowChart Added successfully",
    information:
      "Any One Of The Approval Person Approves, System Will Sync Information To Screen.",
  },
};
