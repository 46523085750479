import { Box, Grid, FormControlLabel, Checkbox } from "@mui/material";
import {
  getFutureYears,
  handleYearChange,
  handlerAllCheckYears,
} from "../js/way";
import { useState, useEffect } from "react";
function Years(props) {
  const { addFormik } = props;
  const years = getFutureYears("2024", "2028");
  const [selectedYears, setSelectedYears] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setSelectedYears(addFormik.values.years);
  }, []);

  useEffect(() => {
    const isAllSelected = years.every((date) =>
      selectedYears?.includes(date.value)
    );

    setIsChecked(isAllSelected);
  }, [selectedYears, addFormik.values.years]);

  return (
    <Box m={1} ml={2.5}>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e) =>
                handlerAllCheckYears(
                  e,
                  setSelectedYears,
                  setIsChecked,
                  years,
                  addFormik
                )
              }
            />
          }
          required
          label={"Valid Year"} // 修改为你想要的多选框标签
          sx={{
            textAlign: "left",
            font: "normal normal medium 18px/24px Roboto",
            fontWeight: "bold",
            letterSpacing: "10px",
            color: "red",
          }}
        />
      </Box>
      <Box ml={4}>
        {years.map((item) => {
          return (
            <FormControlLabel
              key={item.id}
              checked={selectedYears?.includes(item.value)}
              control={
                <Checkbox
                  onChange={() =>
                    handleYearChange(
                      item.value,
                      selectedYears,
                      setSelectedYears,
                      addFormik,
                      years,
                      setIsChecked
                    )
                  }
                />
              }
              label={item.value}
              sx={{
                textAlign: "left",
                font: "normal normal medium 18px/24px Roboto",
                fontWeight: "bold",
                letterSpacing: "10px",
                color: "#474B4F",
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default Years;
