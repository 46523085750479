import { Box, Grid, InputLabel, FormControlLabel, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimePickerDate from "../../../components/TimePickerDate";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

function Daily(props) {
  const { addFormik } = props;
  const { t } = useTranslation();

  const [allDay, setAllday] = useState(false);

  useEffect(() => {
    setAllday(addFormik.values.fullDay);
  }, [addFormik.values.fullDay]);

  useEffect(() => {
    if (allDay) {
      addFormik.setFieldValue(
        "startDayTime",
        dayjs().startOf("day").format("HH:mm:ss")
      );

      addFormik.setFieldValue(
        "endDayTime",
        dayjs().endOf("day").format("HH:mm:ss")
      );
    }
  }, [allDay]);

  return (
    <Grid display={"flex"} pb={3}>
      <Box m={1}>
        <InputLabel shrink htmlFor="bootstrap-input">
          <span style={{ color: "red" }}>*</span>
          {t("Start Time")}
        </InputLabel>
        <Grid mt={2}>
          <TimePickerDate
            name="startDayTime"
            disabled={allDay}
            defaultValue={dayjs(addFormik.values.startDayTime, "HH:mm:ss")}
            value={
              allDay
                ? dayjs().startOf("day")
                : dayjs(addFormik.values.startDayTime, "HH:mm:ss")
            }
            onChange={(e) => {
              addFormik?.setFieldValue(
                "startDayTime",
                dayjs(e).format("HH:mm:ss")
              );
            }}
            placeholder={t("common_tips.start_date")}
          ></TimePickerDate>
        </Grid>
      </Box>

      <Box m={1}>
        <InputLabel shrink htmlFor="bootstrap-input">
          <span style={{ color: "red" }}>*</span> {t("End Time")}
        </InputLabel>
        <Grid mt={2}>
          <TimePickerDate
            name="endDayTime"
            disabled={allDay}
            defaultValue={dayjs().endOf("day").format("HH:mm:ss")}
            placeholder={t("common_tips.end_date")}
            value={
              allDay
                ? dayjs().endOf("day")
                : dayjs(addFormik.values.endDayTime, "HH:mm:ss")
            }
            onChange={(e) => {
              addFormik?.setFieldValue(
                "endDayTime",
                dayjs(e).format("HH:mm:ss")
              );
            }}
          ></TimePickerDate>
        </Grid>
      </Box>

      <Box mt={7} ml={5}>
        <FormControlLabel
          control={
            <Radio
              id={`role-radio-1`}
              checked={allDay}
              value={allDay}
              onClick={() => {
                const newValue = !allDay; // 切换值
                setAllday(newValue); // 更新状态
                addFormik.setFieldValue("fullDay", newValue); // 更新 Formik 的值
              }}
              name="AllDay"
            />
          }
          label={"All Day"}
        />
      </Box>
    </Grid>
  );
}

export default Daily;
