/* eslint-disable no-unreachable */
import React, { forwardRef, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, OutlinedInput, IconButton } from "@mui/material";
import CmpBaseSelect from "./CmpBaseSelect";
import ClearIcon from "@mui/icons-material/Clear";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CustomSelect = forwardRef((props, ref) => {
  const {
    // 具体传递的对象 格式：[{label:"xx",value:"11"}]
    options = [],
    labelOptions = { label: "label", value: "value" },
    // 回调的value
    value = "",
    isClear = true,
    isDic = false,
    multiple = false,
    dicType = "",
    // 表单name
    name = "",
    size = "Normal",
    // 表单校验错误
    error = false,
    placeholder = "",
    // change事件
    onChange = () => {},
    // 清除按钮需要双击生效
    onClear = () => {},
    formik = null,
    // blur事件
    onBlur = () => {},
    menuWidth = 250,
    disabled = false,
    sx,
  } = props;

  const [haveValue, setHaveValue] = useState(false);

  const [selectOption, setSelectOptions] = useState([]);

  useEffect(() => {
    if (!isDic && !dicType) {
      setSelectOptions(options);
    }
  }, [options]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: menuWidth,
      },
    },
  };
  const selectRef = useRef(null);

  useEffect(() => {
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    ) {
      setHaveValue(false);
    } else {
      setHaveValue(true);
    }
  }, [value]);

  return (
    <Select
      disabled={disabled}
      ref={selectRef}
      onChange={(event, child) => {
        onChange(event, child);
      }}
      onBlur={onBlur}
      fullWidth
      error={error}
      displayEmpty
      name={name}
      size={size}
      value={value}
      multiple={multiple}
      sx={{
        overflow: "hidden",
        color: value === "" ? "#757575" : "black",
        ...sx,
        marginTop: "4px",
      }}
      input={
        <OutlinedInput
          endAdornment={
            isClear
              ? haveValue &&
                !disabled && (
                  <IconButton
                    sx={{}}
                    onClick={(e) => {
                      if (formik) {
                        let nvalue = multiple ? [] : "";
                        formik.setFieldValue(name, nvalue);
                      }
                      e.stopPropagation();
                      onClear();
                    }}
                  >
                    <ClearIcon
                      fontSize="small"
                      sx={{
                        color: "#757575",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )
              : null
          }
        />
      }
      MenuProps={MenuProps}
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem disabled value="">
        <span>{placeholder}</span>
      </MenuItem>
      {selectOption.map((item) => {
        return (
          <MenuItem
            key={isDic ? item.value : item[labelOptions.value]}
            value={isDic ? item.value : item[labelOptions.value]}
          >
            {isDic ? item.text : item[labelOptions.label]}
          </MenuItem>
        );
      })}
    </Select>
  );
});

CmpBaseSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  error: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  labelOptions: PropTypes.object,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};
export default CustomSelect;
