import { Box, Grid, FormControlLabel, Checkbox } from "@mui/material";
import {
  getFormattedDaysArray,
  handleDayChange,
  handlerAllCheckDay,
} from "../js/way";
import { useState, useEffect } from "react";
function VaildDate(props) {
  const { addFormik } = props;
  // 获取特定月份的所有天数
  const formattedDaysArray = getFormattedDaysArray(1, 2025);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    setSelectedDates(addFormik.values.dates);
  }, []);

  useEffect(() => {
    // 判断是否全选中
    const isAllSelected = formattedDaysArray.every((date) =>
      selectedDates?.includes(date.value)
    );

    setIsChecked(isAllSelected);
  }, [selectedDates]);
  return (
    <Box ml={2.5}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(e) =>
              handlerAllCheckDay(
                e,
                setSelectedDates,
                setIsChecked,
                formattedDaysArray,
                addFormik
              )
            }
          />
        }
        required
        label="Valid Date" // 修改为你想要的多选框标签
        sx={{
          textAlign: "left",
          font: "normal normal medium 18px/24px Roboto",
          fontWeight: "bold",
          letterSpacing: "10px",
          color: "red",
        }}
      />

      <Box ml={4}>
        <Grid container spacing={1} md={10}>
          {formattedDaysArray?.map((item) => {
            return (
              <Grid key={item.id} item xs={2} pr={1}>
                <FormControlLabel
                  key={item.id}
                  checked={selectedDates?.includes(item.value)}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleDayChange(
                          item.value,
                          selectedDates,
                          setSelectedDates,
                          addFormik,
                          setIsChecked,
                          formattedDaysArray
                        );
                      }}
                    />
                  }
                  label={item.value}
                  sx={{
                    textAlign: "left",
                    font: "normal normal medium 18px/24px Roboto",
                    fontWeight: "bold",
                    letterSpacing: "10px",
                    color: "#474B4F",
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default VaildDate;
