import React, { forwardRef } from 'react';
import { Stack, InputLabel, OutlinedInput, Select, MenuItem } from '@mui/material';
const CustomSelect = forwardRef((props, ref) => {
    const { onChange, value, label, required, items, labelKey, valueKey, ...rest } = props;

    const handleChangeSelect = (event) => {
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Stack sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }} spacing={0}>
            <InputLabel sx={{ color: '#707070', fontSize: '14px', mr: 2, whiteSpace: 'nowrap' }}>
                {label} {required && <i style={{ color: 'red' }}>*</i>}
            </InputLabel>
            <Select
                style={{
                    marginTop: '0px'
                }}
                sx={{
                    minWidth: 80,
                    '.MuiSelect-select': {
                        padding: '5px'
                    }
                }}
                displayEmpty
                {...rest}
                value={props.value}
                onChange={handleChangeSelect}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {(items || []).map((item) => {
                    let isObject = typeof item === 'object';
                    if (isObject) {
                        return (
                            <MenuItem key={item[valueKey || 'value']} value={item[valueKey || 'value']}>
                                {item[labelKey || 'label']}
                            </MenuItem>
                        );
                    } else {
                        return (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        );
                    }
                })}
            </Select>
        </Stack>
    );
});

export default CustomSelect;
