import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import RenderComponent from "./RenderComponent";
import "./fonts/fonts.css";
const PreView = (props) => {
  const [json, setJson] = useState(null);
  const elementId = props.id || "preViewId";
  const { layoutJSON } = props;

  useEffect(() => {
    if (layoutJSON) {
      let parsedJson;
      if (typeof layoutJSON === "object") {
        parsedJson = layoutJSON; // 如果已经是对象，则直接使用
      } else {
        try {
          parsedJson = JSON.parse(layoutJSON); // 尝试解析成对象
        } catch (error) {
          console.error("Error parsing layoutJSON:", error);
          parsedJson = null;
        }
      }
      setJson(parsedJson);
    } else {
      setJson(null);
    }
  }, [layoutJSON]);

  if (json === null || json === undefined) {
    return null;
  } else {
    return (
      <Grid
        className="boundary-element"
        id={elementId}
        sx={{
          width: json?.width,
          height: json?.height,
          backgroundColor: json?.bgColor,
          position: "relative",
          shapeRendering: "crispEdges",
          imageRendering: "pixelated",
        }}
      >
        {json?.componentList?.map((item) => {
          let info = JSON.parse(JSON.stringify(item));
          return (
            <RenderComponent
              isPreView={true}
              {...props}
              key={info.id}
              info={info}
            ></RenderComponent>
          );
        })}
      </Grid>
    );
  }
};
export default PreView;
