import { Grid } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { FormLabel, PrettoSlider } from "../components/PropertiesComponent";
import ColorPick from "../components/ColorPick";
import CustomSelect from "../components/CustomSelect";
import { fontSizeList, fontList } from "../utils";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CustomInput from "../components/CustomInput";
import { Stack, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
const ImagePropertise = (props) => {
  const {
    setActiveId,
    activeId,
    current,
    setCurrent,
    layoutJSON,
    setLayoutJSON,
  } = props;
  const { t } = useTranslation();
  const [properties, setProperties] = useState({
    type: "",
    id: "",
    left: 0,
    top: 0,
    right: 0,
    width: 200,
    height: 100,
    hide: false,
    zIndex: 10,
    rotate: 0,
    url: "",
    transparency: 1, //透明度
    borderRadius: 0,
  });

  useEffect(() => {
    let componentList = JSON.parse(JSON.stringify(layoutJSON.componentList));
    let componentListResult = componentList.filter((element, fIndex) => {
      if (element.id === activeId) {
        return true;
      } else {
        return false;
      }
    });
    if (componentListResult.length === 1) {
      let aa = componentListResult[0];
      setProperties({
        ...properties,
        ...aa,
      });
    }
  }, [activeId, current, layoutJSON]);

  const updateProperties = (newInfo) => {
    let jsonObj = JSON.parse(JSON.stringify(layoutJSON));
    setProperties(newInfo);
    let index = "";
    jsonObj.componentList.forEach((element, fIndex) => {
      if (element.id === newInfo.id) {
        index = fIndex;
      }
    });
    if (index !== "") {
      jsonObj.componentList[index] = newInfo;
      setLayoutJSON(JSON.parse(JSON.stringify(jsonObj)));
    }
  };

  const changeProperties = (event) => {
    const name = event.target.name;
    let newInfo = {
      ...properties,
      [name]: event.target.value,
    };
    updateProperties(newInfo);
  };

  const handleSliderRadiusChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      borderRadius: event.target.value,
    };
    updateProperties(newInfo);
  };

  const handleRotationChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      rotate: newValue,
    };
    updateProperties(newInfo);
  };

  const handleSliderChange = (event, newValue) => {
    let newInfo = {
      ...properties,
      transparency: event.target.value,
    };
    updateProperties(newInfo);
  };

  return (
    <Grid
      sx={{
        height: "100%",
        overflow: "auto",
        p: 1.5,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.rounded_corners")}</FormLabel>
        <PrettoSlider
          onChange={handleSliderRadiusChange}
          size="small"
          min={0}
          max={500}
          step={1}
          color="secondary"
          value={properties.borderRadius}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 3,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.rotation_angle")}</FormLabel>
        <PrettoSlider
          onChange={handleRotationChange}
          size="small"
          min={0}
          max={360}
          step={1}
          color="secondary"
          value={properties.rotate}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 3,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.opacity")}</FormLabel>
        <PrettoSlider
          onChange={handleSliderChange}
          size="small"
          min={0.1}
          max={1}
          step={0.1}
          color="secondary"
          value={properties.transparency}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid>

      <Grid
        sx={{
          mt: 2,
        }}
      >
        <InputLabel
          sx={{
            color: "#707070",
            fontSize: "14px",
            mr: 2,
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
        >
          {t("editor.level")}
        </InputLabel>
        <CustomInput
          label=""
          type="number"
          value={properties.zIndex}
          onChange={changeProperties}
          name="zIndex"
        ></CustomInput>
      </Grid>

      <Grid sx={{ mt: 2 }}>
        <CustomInput
          label="X："
          value={properties.left}
          onChange={changeProperties}
          name="left"
        ></CustomInput>

        <CustomInput
          label="Y："
          value={properties.top}
          onChange={changeProperties}
          name="top"
        ></CustomInput>

        <CustomInput
          label={t("editor.width")}
          value={properties.width}
          onChange={changeProperties}
          name="width"
        ></CustomInput>

        <CustomInput
          label={t("editor.height")}
          value={properties.height}
          onChange={changeProperties}
          name="height"
        ></CustomInput>
      </Grid>
    </Grid>
  );
};

export default ImagePropertise;
