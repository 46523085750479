import { numberToDate } from "./way";

export const getInitialValues = (state, detailData) => {
  if (state?.action === "Edit") {
    return {
      name: state?.name,
      promotionType: state?.promotionType,
      promotionDescription: state?.promotionDescription,
      scheduleMode:
        state?.scheduleMode === "Immediate" ? "" : state?.scheduleMode,
      outletIds: detailData?.outlets,
      productId: state?.productId,
      templateId: state?.templateId,
      startDayDate: state?.startDayDate, // Start Promotion
      endDayDate: state?.endDayDate, //End Promotion
      startDayTime: state?.startDayTime, // Start Time
      endDayTime: state?.endDayTime, //End Time
      fullDay: state?.fullDay,
      days: state.days,
      dates: numberToDate(state.dates),
      months: state?.months,
      years: state?.years,
      id: state?.id,
      resolution: state?.resolution,
      zoneId: state.zoneId,
      url: state.downloadUrl,
      endOrNot: state?.endOrNot,
      isAllowEdit: state?.isAllowEdit,
      elementId: state?.elementId,
      productAttributeId: state?.productAttributeId,
      productBrand: state?.productName,
    };
  } else {
    return {
      name: "",
      promotionType: "",
      promotionDescription: "",
      scheduleMode: "",
      outletIds: [],
      productId: "",
      templateId: "",
      startDayDate: "", // Start Promotion
      endDayDate: "", //End Promotion
      startDayTime: "", // Start Time
      endDayTime: "", //End Time
      fullDay: false,
      endOrNot: false,
      days: [],
      dates: [],
      months: [],
      years: [],
      id: "",
      resolution: "",
      zoneId: "",
      status: "0",
      url: "",
      elementId: "",
      productAttributeId: "",
      productBrand: "",
    };
  }
};
