import api from "../configurations/http-common";

export const listThirdPartyClient = async (filter) => {
  return api.securedAxios().get("web/thirdPartyAppList", {
    params: filter,
  });
};

export const createThirdPartyClient = async (request) => {
  return api.securedAxios().post("web/thirdPartyApp", request);
};
