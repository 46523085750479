import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useSnackbar } from "notistack";
import CommonUtil from "../util/CommonUtils";
import BaiDuMap from "../pages/baiduMap/BaiduMap";
import tz_lookup from "tz-lookup";

const lib = ["places"];

function AddressFromMap(props) {
  const selectMapRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [center, setCenter] = useState({
    lat: 22.479921867491587,
    lng: 78.87748125873155,
  });
  const [zoom, setZoom] = useState(10);
  const [map, setMap] = useState(null);

  const [searchBox, setSearchBox] = useState(null);
  const [googleMapKey, setGoogleMapKey] = useState("");
  useEffect(() => {
    // dispatch(getServicesConfig())
    //   .unwrap()
    //   .then((data) => {
    //     if (data.code === 'ZLINKI0001') {
    //       setGoogleMapKey(data?.data?.googleMapKey);
    //       Geocode.setApiKey(data?.data?.googleMapKey);
    //     }
    //   });
    setGoogleMapKey("AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238");
    Geocode.setApiKey("AIzaSyA9MaTVJlWIWpINjcgyJl5eS6JDhe60238");
  }, []);

  Geocode.setLanguage("en");

  const getAddress = (lat, lng) => {
    var location = {
      lat: "",
      lng: "",
      addressLine1: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      timeZone: "",
    };
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        location.lat = lat;
        location.lng = lng;

        const address = response.results[0].formatted_address;
        const addressObject = address.split(",");

        if (addressObject.length < 2) {
          enqueueSnackbar("Address details not found", {
            variant: "warning",
          });
          return;
        }

        if (addressObject.length > 0) {
          if (addressObject.length > 3) {
            var temp = addressObject[addressObject.length - 2].trim();
            var state = temp.split(" ");
            if (state.length > 1) {
              location.state = state[0];
              location.pincode = state[state.length - 1];
            } else {
              location.state = addressObject[addressObject.length - 2].trim();
            }
            location.city = addressObject[addressObject.length - 3];
            location.country = addressObject[addressObject.length - 1];
            var tempAddress = "";
            addressObject.map((a, index) => {
              if (index < addressObject.length - 3) {
                tempAddress = tempAddress + " " + a;
              }
            });
            location.addressLine1 = tempAddress;
          } else if (addressObject.length > 2) {
            location.city = addressObject[addressObject.length - 3];
            var temp = addressObject[addressObject.length - 2].trim();
            var state = temp.split(" ");
            if (state.length > 1) {
              location.state = state[0];
              location.pincode = state[state.length - 1];
            } else {
              location.state = addressObject[addressObject.length - 2].trim();
            }
            location.country = addressObject[addressObject.length - 1];
          } else if (addressObject.length > 2) {
            var temp = addressObject[addressObject.length - 2].trim();
            var state = temp.split(" ");
            if (state.length > 1) {
              location.state = state[0];
              location.pincode = state[state.length - 1];
            } else {
              location.state = addressObject[addressObject.length - 2].trim();
            }
            location.country = addressObject[addressObject.length - 1];
          } else {
            location.country = addressObject[addressObject.length - 1];
          }
        }
        // timeZoneData.map((item) => {
        //   const timezonevalue = item.name.slice(4, 7) + item.name.slice(8, 10);
        //   if (timeZone.utc_offset === timezonevalue) {
        //     location.timeZone = item.id;
        //   }
        // });

        props.onSelectLocation(location);
        handleClose();
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getNowLocation = () => {};

  const handleZoomChange = () => {
    if (map) {
      if (map.getZoom() < 2) {
        map.setZoom(2);
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    getNowLocation();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getLocation = (value, location) => {
    props.onSelectLocation(location);
    handleClose();
  };

  const onPlacesChanged = () => {
    var data = searchBox.getPlaces();

    try {
      var location = {
        lat: "",
        lng: "",
        addressLine1: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        timeZone: "",
      };

      if (!CommonUtil.isEmpty(data[0].address_components)) {
        data[0].address_components.map((e) => {
          console.log(e);
          switch (e.types[0]) {
            case "postal_code":
              location.pincode = e.long_name;
              break;
            case "country":
              location.country = e.long_name;
              break;
            case "administrative_area_level_1":
              location.state = e.long_name;
              break;
            case "locality":
              location.city = e.long_name;
              break;
            case "administrative_area_level_2":
              if (CommonUtil.isEmptyString(location.city)) {
                location.city = e.long_name;
              }
              break;
            case "locality":
              location.addressLine1 = e.long_name;
              break;
          }
        });
      }
      if (!CommonUtil.isEmpty(data[0].geometry)) {
        let lat = data[0].geometry.location.lat();
        let long = data[0].geometry.location.lng();
        console.log(lat, long);
      }
      Geocode.fromLatLng(
        data[0].geometry.location.lat(),
        data[0].geometry.location.lng()
      ).then(
        (response) => {
          location.lat = data[0].geometry.location.lat();
          location.lng = data[0].geometry.location.lng();
          const address = response.results[0].formatted_address;
          const addressObject = address.split(",");

          if (addressObject.length < 2) {
            return;
          }

          if (addressObject.length > 0) {
            if (addressObject.length > 3) {
              var tempAddress = "";
              addressObject.map((a, index) => {
                if (index < addressObject.length - 3) {
                  tempAddress = tempAddress + " " + a;
                }
              });
              location.addressLine1 = tempAddress;
            }
            if (addressObject.length === 3) {
              var tempAddress = "";
              addressObject.map((a, index) => {
                if (index === 0) {
                  tempAddress = tempAddress + " " + a;
                }
              });
              location.addressLine1 = tempAddress;
            }
          }
          // timeZoneData.map((item) => {
          //   const timezonevalue = item.name.slice(4, 7) + item.name.slice(8, 10);
          //   if (timeZone.utc_offset === timezonevalue) {
          //     location.timeZone = item.id;
          //   }
          // });
          const timezone = tz_lookup(
            data[0].geometry.location.lat(),
            data[0].geometry.location.lng()
          );
          if (timezone) {
            location.timeZone = timezone;
          }
          props.onSelectLocation(location);
          handleClose();
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };
  const options = {
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 10,
    },
  };

  const baiduMap = localStorage.getItem("BAIDUMAP");
  return (
    <div>
      <MyLocationIcon
        onClick={handleClickOpen}
        style={{
          cursor: "pointer",
        }}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogContent>
          {baiduMap === "cn" ? (
            <BaiDuMap
              ref={selectMapRef}
              getLocation={getLocation}
              open={open}
            ></BaiDuMap>
          ) : (
            <LoadScript
              id="script-loader"
              googleMapsApiKey={googleMapKey}
              language={"en"}
              libraries={lib}
            >
              <GoogleMap
                onClick={(e) => {
                  getAddress(e.latLng.lat(), e.latLng.lng());
                  console.log(e);
                }}
                onLoad={(map) => {
                  setMap(map);
                }}
                mapContainerStyle={{
                  height: "500px",
                  width: "100%",
                }}
                zoom={zoom}
                center={center}
                onZoomChanged={() => handleZoomChange()}
                options={options}
              >
                <StandaloneSearchBox
                  style={{ zIndex: "999" }}
                  onPlacesChanged={onPlacesChanged}
                  onLoad={onSBLoad}
                >
                  <input
                    id="searchinput"
                    type="text"
                    placeholder="Search Site"
                    style={{
                      boxSizing: "border-box",
                      border: `1px solid transparent`,
                      width: `270px`,
                      height: `40px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      margin: "center",
                      textOverflow: `ellipses`,
                      position: "absolute",
                      top: "10px",
                      marginLeft: "68%",
                    }}
                  />
                </StandaloneSearchBox>
              </GoogleMap>
            </LoadScript>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default AddressFromMap;
