import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import ListLayout from "../../components/ListLayout";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import CommonUtil from "../../util/CommonUtils";
import IconHandaler from "../../components/IconHandaler";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useSnackbar } from "notistack";
import {
  REACT_PERSON_LIST,
  REACT_PRINCIPAL_ADD,
  REACT_PRINCIPAL_EDIT,
  RETAIL_CLIENT,
} from "../../router/ReactEndPoints";
import {
  deleteClientById,
  getUserByClientId,
  listClients,
  sendActivationMail,
} from "../../services/CompanyService";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import ConfirmModal from "../../components/ConfirmModel";

export default function PrincipalManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { enqueueSnackbar } = useSnackbar();
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
    type: "PRINCIPAL",
  });
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };
  const defaultFilters = {
    pageNumber: 0,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
    type: "PRINCIPAL",
  };

  useEffect(() => {
    loadData();
  }, [filters]);
  const loadData = () => {
    listClients(filters).then((res) => {
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.client
      ) {
        setRecords(res?.data?.data?.client);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
      }
    });
  };

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };
  const toolbarProps = {
    add: true,
    filter: false,

    refresh: true,
    onAdd: (data) => {
      navigate(REACT_PRINCIPAL_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...defaultFilters });
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
      operator: "AND",
    });
  };

  useEffect(() => {
    const suggestions = records.filter(
      (record) =>
        record.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        record.code.toLowerCase().includes(searchValue.toLowerCase())
    );
    console.log(suggestions);
    setSearchSuggestions(suggestions);
  }, [searchValue, records]);

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e });
  };

  const handlePageSize = (pageSize) => {
    setFilters({ ...filters, pageNumber: 0, pageSize: pageSize });
  };

  const columns = [
    {
      field: "name",
      headerName: t("LVLRC0002"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t("LVLRC0003"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.code} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.code)}</span>
        </Tooltip>
      ),
    },
    {
      field: "addressLine1",
      headerName: t("LVLDAC0022"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.addressLine1} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.addressLine1)}</span>
        </Tooltip>
      ),
    },
    {
      field: "country",
      headerName: t("LVLDAC0023"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.country?.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.country?.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "state",
      headerName: t("LVLOT0006"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.state?.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.state?.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "city",
      headerName: t("LVLGF0003"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.city?.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.city?.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: t("LVLDAC0021"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e?.row?.phone} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e?.row?.phone)}</span>
        </Tooltip>
      ),
    },
    {
      headerName: t("LVLRC0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (e) => (
        <IconHandaler>
          {/* <Tooltip title="View" arrow>
                        <VisibilityIcon
                            //    onClick={() => handleActions("View", e)}
                            style={{
                                alignSelf: "center",
                                paddingTop: "0px",
                                paddingLeft: "5px",
                                opacity: "1",
                            }}
                        />
                    </Tooltip> */}
          <Tooltip title={t("PERTO0004")} arrow sx={{ pl: 1 }}>
            <PeopleAltIcon
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
              }}
              onClick={() =>
                navigate(
                  "/" +
                    "principal" +
                    "/" +
                    e.row.id +
                    "/" +
                    e.row.code +
                    REACT_PERSON_LIST
                )
              }
            />
          </Tooltip>
          <Tooltip title={t("PERTO0001")} arrow sx={{ marginLeft: 1 }}>
            <EditIcon
              sx={{
                "& .MuiSvgIcon-root": {
                  marginLeft: "0px",
                },
              }}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
                paddingLeft: "5px",
                height: "17px",
                width: "20px",
              }}
              onClick={() => handleActions("Edit", e.id)}
            />
          </Tooltip>
          <Tooltip title={t("PERTO0003")} arrow sx={{ marginLeft: 1 }}>
            <ForwardToInboxIcon
              sx={{
                "& .MuiSvgIcon-root": {
                  marginLeft: "0px",
                },
              }}
              onClick={() => handleActions("ResendEmail", e.row.id)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
                paddingLeft: "5px",
                height: "17px",
                width: "20px",
              }}
            />
          </Tooltip>
          {/* <Tooltip title={t("PERTO0002")} sx={{ marginLeft: 1 }}>
            <DeleteIcon
              sx={{
                "& .MuiSvgIcon-root": {
                  marginLeft: "0px",
                },
              }}
              onClick={() => handleActions("Delete", e.row.id)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                cursor: "pointer",
                opacity: "0.6",
                paddingLeft: "5px",
                height: "17px",
                width: "20px",
              }}
            />
          </Tooltip>  */}
        </IconHandaler>
      ),
    },
  ];

  const sendEmail = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            // text={t("LVLRC0027")}
            text={
              <span>
                <span
                  style={{
                    font: "normal normal normal 15px/20px Roboto",
                    color: "#000",
                  }}
                >
                  {t("LVLRC0027")}
                </span>
              </span>
            }
            onConfirm={() => {
              getUserByClientId(id).then((res) => {
                if (res?.data?.code === "UASI0033") {
                  sendActivationMail(res.data.data.email).then((res) => {
                    if (res?.data?.code === "LVLI0000") {
                      enqueueSnackbar(t("LVLRC0028"), {
                        variant: "success",
                      });
                      loadData();
                    } else
                      enqueueSnackbar(res?.data?.message, { variant: "error" });
                  });
                } else
                  enqueueSnackbar(res?.data?.message, { variant: "error" });
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };
  const handleDelete = (data) => {
    deleteClientById(data).then((response) => {
      if (response?.data?.code === "LVLI0002") {
        enqueueSnackbar(t("LVLDAC0030"), {
          variant: "success",
        });
        setConfirm(false);
        loadData();
      }
      if (response?.data?.code === "LVLE00101") {
        enqueueSnackbar(t("PRNC0001"), {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
      }
    });
  };

  const handleActions = (action, data) => {
    if (action === "ResendEmail") {
      sendEmail(data);
    }
    if (action === "Edit") {
      navigate(REACT_PRINCIPAL_EDIT + data);
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const searchClientProps = () => {
    return (
      <Grid container display={"flex"} justifyContent={"flex-end"} pb={0.5}>
        <Autocomplete
          options={searchSuggestions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("LVLRC0011")}
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                style: {
                  height: 50,
                  width: "300px",
                  background: "#fff",
                  color: "#474B4F",
                  opacity: "0.6",
                  boxShadow: "0px 1px 3px #0000001A",
                  borderRadius: "8px",
                  border: "0px !important",
                  padding: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearchClick}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#D1D1D1",
                },
              }}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <ListLayout
      navigateBack={false}
      title={t("EPLSB0003")}
      globalFilterProps={false}
      toolbarProps={toolbarProps}
      searchProps={searchClientProps()}
    >
      <DataTable
        columns={columns}
        rows={records}
        onSelection={() => console.log()}
        page={filters.pageNumber}
        totalRecords={totalRecords}
        rowsPerPage={filters.pageSize}
        onPageChange={(pn) => handlePageChange(pn)}
        onPageSizeChange={(ps) => handlePageSize(ps)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {" "}
          <Typography variant="subtitle2">{t("LVLDAC0029")}</Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDelete(id)}
            >
              {" "}
              Delete{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </ListLayout>
  );
}
