export default {
  login: {
    wrong_password: "รหัสผ่านไม่ถูกต้อง",
  },

  about: {
    version: "เวอร์ชัน",
    about_system: "แนะนำให้ใช้เบราว์เซอร์สำหรับระบบนี้",
    display_resolution: "ความละเอียดหน้าจอ",
    package_details: "รายละเอียดแพ็กเกจ",
    remaining_valid_days: "วันที่เหลืออยู่:",
    remaining_device_count: "จำนวนอุปกรณ์ที่เหลืออยู่:",
    no_used: "จำนวนการใช้งาน:",
    total_no_limit: "จำนวนรวมของขีดจำกัด:",
  },

  company: {
    company_profile: "โปรไฟล์บริษัท",
  },

  user: {
    user_profile: "โปรไฟล์ผู้ใช้",
  },

  retail_client: {
    add_retail_client: "เพิ่มลูกค้าขายปลีก",
    edit_retail_client: "แก้ไขลูกค้าขายปลีก",
    view_retail_client: "ดูลูกค้าขายปลีก",
    retail_client_name: "ชื่อลูกค้าขายปลีก",
    retail_client_code: "รหัสลูกค้าขายปลีก",
    retail_admin_email: "อีเมลผู้ดูแลระบบขายปลีก",
    retail_client_first_name: "ชื่อผู้ดูแลระบบขายปลีก",
    retail_client_last_name: "นามสกุลผู้ดูแลระบบขายปลีก",
    number_of_devices: "จำนวนอุปกรณ์",
    subscription_start_date: "วันเริ่มต้นการสมัคร",
    subscription_expire_date: "วันหมดอายุการสมัคร",
    select_one_retaile: "เลือกไคลเอนต์ค้าปลีก ที่ต้องการดำเนินการ",
  },

  template: {
    view_template: "ดูเทมเพลต",
    add_template: "เพิ่มเทมเพลต",
    edit_template: "แก้ไขเทมเพลต",
    template_editor: "ตัวแก้ไขเทมเพลต",
    name: "ชื่อเทมเพลต",
    resolution: "ความละเอียด",
    type: "ประเภทเทมเพลต",
    screen_direction: "ทิศทางหน้าจอ",
    preview: "ตัวอย่างเทมเพลต",
    screen_model: "รุ่นหน้าจอ",
  },
  editor: {
    product_element: "องค์ประกอบสินค้า",
    price_pule: "กฎราคา",
    product_price: "ราคาสินค้า",
    product_label: "ป้ายสินค้า",
    quantity: "จำนวน",
    category1: "หมวดหมู่1",
    category2: "หมวดหมู่2",
    currency: "สกุลเงิน",
    origin: "แหล่งกำเนิด",
    manufacturer: "ผู้ผลิต",
    discount_price: "ราคาส่วนลด",
    barcode: "บาร์โค้ด",
    qrcode: "QR โค้ด",
    company_logo: "โลโก้บริษัท",
    basic_tags: "แท็กพื้นฐาน",
    image_library: "คลังภาพ",
    change_rule: "เปลี่ยนกฎ",
    free_text: "ข้อความอิสระ",
    add_label: "เพิ่มป้าย",
    add_shape: "เพิ่มรูปร่าง",
    delete_element: "ลบองค์ประกอบ",
    rounded_corners: "มุมโค้ง:",
    content_example: "ตัวอย่างเนื้อหา",
    bgcolor: "สีพื้นหลัง:",
    show_border: "แสดงขอบ:",
    show_scale: "แสดงขนาด:",
    show_value: "แสดงค่า:",
    font_color: "สีตัวอักษร:",
    font_size: "ขนาดตัวอักษร:",
    font_style: "สไตล์ตัวอักษร",
    font_weight: "น้ำหนักตัวอักษร:",
    font: "แบบตัวอักษร:",
    spacing: "ระยะห่าง:",
    rotation_angle: "มุมหมุน:",
    width: "ความกว้าง:",
    height: "ความสูง:",
    opacity: "ความทึบแสง:",
    line_color: "สีเส้น:",
    foreground_color: "สีพื้นหน้า:",
    level: "ระดับ",
    color: "สี",
    shape: "รูปร่าง",
    line_height: "ความสูงของเส้น",
  },

  openAPI: {
    open_api_client: "ไคลเอ็นต์ API เปิด",
    add_open_api: "เพิ่มไคลเอ็นต์ API เปิด",
    secret_key: "คีย์ลับ",
  },

  picture_library: {
    picture_library: "คลังภาพ",
    add_picture: "เพิ่มภาพ",
    edit_picture: "แก้ไขภาพ",
    name: "ชื่อภาพ",
    id: "รหัสภาพ",
    perview: "ตัวอย่างภาพ",
    type: "ประเภทภาพ",
    size: "ขนาดภาพ",
    progressing: "กำลังประมวลผลภาพ",
  },
  dashboard: {
    welcome_back: "ยินดีต้อนรับ!",
    login_for_next:
      "การสมัครของคุณหมดอายุแล้ว คุณสามารถเข้าสู่ระบบสำหรับครั้งต่อไปได้",
    days_only: " วันเท่านั้น",
    subscription_expires: "การสมัครของคุณหมดอายุในวันที่",
    title: "NuTag แดชบอร์ด",
    period_from_date: "ระยะเวลาตั้งแต่วันที่",
    from_date: "ตั้งแต่วันที่",
    period: "ระยะเวลา",
    period_to_date: "ระยะเวลาถึงวันที่",
    registered_gateway: "เกตเวย์ที่ลงทะเบียน",
    registered_ESL: "ESL ที่ลงทะเบียน",
    gateway_online_status: "สถานะเกตเวย์ออนไลน์",
    last_refreshed: "รีเฟรชครั้งล่าสุด",
    refresh_success_rate: "อัตราความสำเร็จในการรีเฟรช",
    total_refreshes: "การรีเฟรชทั้งหมด",
    total_number: "จำนวนการอัพเดต ESL ทั้งหมดภายในระยะเวลาที่ระบุ",
    refreshes: "รีเฟรชทั้งหมด",
    devices_distribution: "การกระจายอุปกรณ์",
    esl_total_stores: "จำนวนร้านค้าที่มี ESL เทียบกับจำนวนร้านค้าทั้งหมด",
    esl_stores: "ร้านค้าที่มีการติดตั้ง ESL",
    total_stores: "จำนวนร้านค้าทั้งหมด",
    refresh_events: "รีเฟรชอีเวนต์",
    time_in_hours: "เวลาเป็นชั่วโมง",
    refresh_time: "เวลารีเฟรช",
    success_rate: "อัตราความสำเร็จ",
    day: "วัน",
    store: "ร้านค้า",
    all_stores: "ร้านค้าทั้งหมด",
    apply_filters: "ใช้ตัวกรอง",
    period_from_date: "ระยะเวลาตั้งแต่วันที่",
    stores_installed: "ร้านค้าที่ติดตั้ง",
    period_to_date: "ระยะเวลาถึงวันที่",
    select_store: "กรุณาเลือกร้านค้า",
  },

  authorization: {
    title: "ระดับการอนุญาต",
    add: "เพิ่มระดับการอนุญาต",
    edit: "แก้ไขระดับการอนุญาต",
    view: "ดูระดับการอนุญาต",
    name: "ชื่อระดับการอนุญาต",
    list: "รายการระดับการอนุญาต",
  },

  product: {
    title: "ข้อมูลผลิตภัณฑ์",
    add_product_label: "เพิ่มฉลากผลิตภัณฑ์",
    sku: "SKU ของผลิตภัณฑ์",
    category_level1: "หมวดหมู่ 1",
    category_level2: "หมวดหมู่ 2",
    category_level3: "หมวดหมู่ 3",
    currency_symbol: "สัญลักษณ์สกุลเงิน",
    brand_name: "ชื่อแบรนด์",
    origin_of_product: "ต้นกำเนิดของผลิตภัณฑ์",
    product: "ผลิตภัณฑ์",
    add_product: "เพิ่มผลิตภัณฑ์",
    view_product: "ดูผลิตภัณฑ์",
    edit_product: "แก้ไขผลิตภัณฑ์",
    product_picture: "ภาพผลิตภัณฑ์",
    product_price: "ราคาผลิตภัณฑ์",
    discount_price: "ราคาส่วนลด",
    serial_number: "ซีเรียลนัมเบอร์",
    number_one: "1",
    coca_cola: "Coca Cola",
    drink: "เครื่องดื่ม",
    cold_drink: "เครื่องดื่มเย็น",
    number_two: "2",
  },

  outlet: {
    title: "ร้านจำหน่ายสินค้า",
    add_outlet: "เพิ่มร้านจำหน่ายสินค้า",
    edit_outlet: "แก้ไขร้านจำหน่ายสินค้า",
    view_outlet: "ดูร้านจำหน่ายสินค้า",
    name: "ชื่อร้านจำหน่าย",
    code: "รหัสร้านจำหน่าย",
  },

  user: {
    name: "ผู้ใช้",
    person_list: "รายชื่อบุคคล",
    create_person: "สร้างบุคคล",
    edit_person: "แก้ไขบุคคล",
    view_person: "ดูบุคคล",
    person_name: "ชื่อบุคคล",
    person_Id: "รหัสประจำตัวบุคคล",
  },

  gateway: {
    gateway: "เกตเวย์",
    viewDeviceDetails: "ดูรายละเอียดอุปกรณ์",
    addDeviceToOutlet: "เพิ่มอุปกรณ์ไปยังจุดเชื่อมต่อ",
    bindDeviceToOutlet: "ผูกอุปกรณ์ไปยังจุดเชื่อมต่อ",
    gatewaySerialNumber: "หมายเลขซีเรียลเกตเวย์",
    gatewayName: "ชื่อเกตเวย์",
    gatewayIPAddress: "ที่อยู่ IP เกตเวย์",
    gatewayFirmware: "เฟิร์มแวร์เกตเวย์",
    gatewayRunningStatus: "สถานะการทำงานของเกตเวย์",
    gatewayUpdateTime: "เวลาอัปเดตเกตเวย์",
    outlet: "จุดเชื่อมต่อ",
    outletTimeZone: "โซนเวลาจุดเชื่อมต่อ",
    versionNumber: "หมายเลขเวอร์ชัน",
    gatewayType: "ประเภทเกตเวย์",
    gatewayModel: "รุ่นเกตเวย์",
    outletCode: "รหัสจุดเชื่อมต่อ",
    logTime: "เวลาบันทึก",
    logType: "ประเภทบันทึก",
    logCode: "รหัสบันทึก",
    enable: "เปิดใช้งาน",
    deviceName: "ชื่ออุปกรณ์",
    deviceModel: "รุ่นอุปกรณ์",
    serialNumber: "หมายเลขซีเรียล",
    deviceType: "ประเภทอุปกรณ์",
    outletId: "รหัสเอาท์เล็ต",
    clientName: "ชื่อไคลเอนต์",
    deviceLog: "บันทึกอุปกรณ์",
  },

  screen: {
    edit_screen: "แก้ไขหน้าจอ",
    bind_to_screen: "ผูกข้อมูลกับหน้าจอ",
    screenId: "รหัสหน้าจอ",
    screen_resolution: "ความละเอียดหน้าจอ",
    positionNo: "หมายเลขตำแหน่ง",
    screenName: "ชื่อหน้าจอ",
    powerStatus: "สถานะแบตเตอร์รี่",
    gateway: "เกตเวย์",
    outlet: "เต้าเสียบ",
    bindToProduct: "ผูกกับผลิตภัณฑ์",
    templatePreview: "ตัวอย่างเทมเพลต",

    screenStatus: "สถานะหน้าจอ",
    screenUpdateTime: "เวลาอัปเดตหน้าจอ",
    gatewaySerialNumber: "หมายเลขซีเรียลเกตเวย์",
    gatewayName: "ชื่อเกตเวย์",
    deviceModel: "รุ่นอุปกรณ์",
    deviceType: "ประเภทอุปกรณ์",
    gatewayRunningStatus: "สถานะเกตเวย์กำลังทำงาน",
    unbindDevice: "ยกเลิกผูกอุปกรณ์",

    bindData: "ผูกข้อมูล",
    normal: "ปกติ",
    lowPower: "แบตเตอร์รี่ต่ำ",
    total: "รวม",
  },

  events: {
    price_Change_Events: "กิจกรรมการเปลี่ยนแปลงราคา",
    add_priceChangeEvent: "เพิ่มกิจกรรมการเปลี่ยนแปลงราคา",
    edit_priceChangeEvent: "แก้ไขกิจกรรมการเปลี่ยนแปลงราคา",
    view_priceChangeEvent: "ดูกิจกรรมการเปลี่ยนแปลงราคา",
    priceChangeName: "ชื่อการเปลี่ยนแปลงราคา",
    priceChangeRule: "กฎการเปลี่ยนแปลงราคา",
    templateName: "ชื่อเทมเพลต",
    scheduleTime: "กำหนดเวลา",
    approvedStatus: "สถานะการอนุมัติ",
    areaFilter: "ตัวกรองพื้นที่",
    outlet: "Outlet",
    productFilter: "ตัวกรองผลิตภัณฑ์",
    productElement: "องค์ประกอบผลิตภัณฑ์",
    brand: "แบรนด์",
    promotionType: "ประเภทโปรโมชัน",
    templateSelection: "การเลือกเทมเพลต",
    selectTemplate: "เลือกเทมเพลตที่จะใช้สำหรับกิจกรรมการเปลี่ยนแปลงราคา",
    schedule: "กำหนดเวลา",
    scheduleMode: "โหมดกำหนดเวลา",
    timeZone: "โซนเวลา",
    startPromotion: "เริ่มต้น โปรโมชั่น",
    endPromotion: "สิ้นสุดโปรโมชั่น",
    doesNotEnd: "ไม่สิ้นสุด",
    setRepeatCondition: "กำหนดเงื่อนไขการทำซ้ำ",
    instantSync: "ซิงค์ทันที",
    startTime: "เวลาเริ่มต้น",
    endTime: "เวลาสิ้นสุด",
    validYear: "ปีที่ถูกต้อง",
    validMonth: "เดือนที่ถูกต้อง",
    validDate: "วันที่ถูกต้อง",
    startDate: "วันที่เริ่มต้น",
    endDate: "วันที่สิ้นสุด",
    days: "วัน",
    dates: "วันที่",
    allDay: "ทั้งวัน",
  },

  approval: {
    approvalList: "รายการอนุมัติ",
    priceChangeDetails: "รายละเอียดการเปลี่ยนแปลงราคา",
    viewApprovalRequest: "ดูคำขออนุมัติของเหตุการณ์การเปลี่ยนแปลงราคา",
    approvalEvent: "เหตุการณ์การอนุมัติ",
    maker: "ผู้สร้าง",
    createTime: "สร้างเวลา",
    priceChangeName: "ชื่อการเปลี่ยนแปลงราคา",
    productFilter: "ตัวกรองผลิตภัณฑ์",
    priceChangeRule: "กฎการเปลี่ยนแปลงราคา",
    priceChangeRuleName: "ชื่อกฎการเปลี่ยนแปลงราคา",
    templateSelection: "การเลือกเทมเพลต",
    templateName: "ชื่อเทมเพลต",
    schedule: "กำหนดการ",
    timeZone: "โซนเวลา",
    startTime: "เวลาเริ่มต้น",
    endTime: "เวลาสิ้นสุด",
    setRepeatCondition: "กำหนดเงื่อนไขการทำซ้ำ",
    includeExcludeDays: "รวม/ไม่รวมวันในสัปดาห์",
    approve: "อนุมัติ",
    reject: "ปฏิเสธ",
    rejectUpper: "ปฏิเสธ",
    approveUp: "อนุมัติ",
    approvalLog: "บันทึกการอนุมัติ",
    approvalLogEvent: "เหตุการณ์การอนุมัติ",
    approvalLogPerson: "ผู้อนุมัติ",
    approvalLogApprovalTime: "เวลาอนุมัติ",
    approvalLogStatus: "สถานะการอนุมัติ",
  },

  synchronized: {
    systemRecords: "Sบันทึกระบบ",

    screenID: "รหัสหน้าจอ",
    outlet: "ทางออก",
    product: "ผลิตภัณฑ์",
    deviceSN: "SN อุปกรณ์",
    status: "สถานะ",
    picture: "ภาพ",

    // 新增
    mobile: "มือถือ",
    ที่อยู่: "ที่อยู่",
    ประเทศ: "ประเทศ",
    รัฐจังหวัด: "รัฐ/จังหวัด",
    เมือง: "เมือง",
    noRecordFound: "ไม่พรายการ",
    ล้าง: "ล้าง",
    ยืนยัน: "ยืนยัน",
  },
};
