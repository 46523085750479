import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import OnboardLayout from "../../components/layout-components/OnboardLayout";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { ReactComponent as Phone } from "../../assets/images/icon_phone.svg";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState({
    email: "",
  });
  const [fieldType, setFieldType] = useState("Email");
  const [error, setError] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    setPayload({
      ...payload,
      [name]: e.target.value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };
  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: t("tips.required"),
      });
      return;
    }
    if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("tips.invalid_email_address"),
      });
      return;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      UserService.forgotPassword(payload.email).then((res) => {
        if (res.data.code === "NFSI0002") {
          enqueueSnackbar(res.data.message, { variant: "success" });
          navigate("/");
        } else enqueueSnackbar(res.data.message, { variant: "error" });
      });
    }
  };

  return (
    <Grid container sx={{ display: "flex", width: "auto", height: "100vh" }}>
      <OnboardLayout>
        <Typography fontSize={25} fontWeight={550} pt={4}>
          Forgot Password
        </Typography>
        <Typography
          pt={2}
          sx={{ font: "normal normal normal 20px/28px Roboto" }}
        >
          {" "}
          System will send a link to reset your password to the email or mobile
          used in your account, please check it carefully.
        </Typography>
        <Grid
          component="form"
          noValidate
          sx={{
            display: "flex",
            gridTemplateColumns: { sm: "1fr 1fr" },
            gap: 1,
          }}
        >
          <CustomInput
            required
            label="Email"
            name="email"
            size="small"
            value={payload.email}
            error={error.email}
            resetError={() => setError({ ...error, email: "" })}
            helperText={error.email}
            placeholder="Enter your Email"
            validation="email"
            handleChange={handleChange}
            inputProps={{
              maxLength: 36,
            }}
          />
          <Tooltip title="Login with mobile">
            <Phone
              onClick={() => setFieldType("Phone")}
              style={{
                height: "45px",
                width: "45px",
                marginTop: "20px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Grid>
        <Grid pt={2}>
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Send
          </Button>
        </Grid>
        <Grid pt={1} pb={4}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ color: "black" }}
            onClick={() => navigate("/")}
          >
            Back to Login
          </Button>
        </Grid>
      </OnboardLayout>
    </Grid>
  );
}
