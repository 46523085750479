import {
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import DataTable from "../../components/DataTableCopy";
import IconHandaler from "../../components/IconHandaler";

import { ReactComponent as VisibilityIcon } from "../../assets/images/View_Icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit_Icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete_Icon.svg";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
  REACT_OUTLET_ADD,
  REACT_OUTLET_EDIT,
  REACT_OUTLET_VIEW,
} from "../../router/ReactEndPoints";
import { deleteOutlet, filterOutlet } from "../../services/OutletService";
import CommonUtil from "../../util/CommonUtils";
import OutletLayout from "../../components/OutletLayout";
import ClearIcon from "@mui/icons-material/Clear";
export default function Outlet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const htmlContentRef = useRef();
  const [htmlContent, setHtmlContent] = useState("");
  const [permission, setPermission] = useState(
    localStorage.getItem("permission")
  );
  const roleName = localStorage.getItem("ROLE_NAME");
  let isAllowedCreate = true;
  let isAllowedView = true;
  let isAllowedEdit = true;
  let isAllowedDelete = true;
  const [filters, setFilters] = useState({
    page: 0,
    rowsPerPage: 8,
    name: "",
    country: "",
    state: "",
    city: "",
    cityZone: "",
    code: "",
    ids: [],
    clientId: "",

    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
      localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });
  const defaultFilters = {
    page: 0,
    rowsPerPage: 8,
    name: "",
    country: "",
    state: "",
    city: "",
    cityZone: "",
    code: "",
    outletType: "",
    ids: [],
    clientId: "",
    companyIds: [],
  };

  const [outletParam, setOutletParam] = useState({
    pageNumber: 1,
    pageSize: 5,
    id: "",
    sn: "",
    deviceType: "",
    deviceModel: "",
    companyId: "",
    deviceAlias: "",
    mac: "",
    siteId: "",
    zoneId: "",
    checkInOrOutType: "",
    ip: "",
    registrationDevice: "",
    name: "",
  });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  };

  const ImagePopUp = () => {
    return (
      <div
        id="htmlContent"
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    filterOutlet(filters.page + 1, filters.rowsPerPage, filters).then(
      (response) => {
        if (response?.data?.code === "LMSI6000" && response?.data?.data) {
          setRecords(response?.data?.data?.objects);
          setTotalRecords(response?.data?.data?.totalCount);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setTotalRecords(0);
        }
      }
    );
  };

  if (roleName === "ADMIN") {
    isAllowedCreate = permission && permission.includes("AddOutlet");
    isAllowedView = permission && permission.includes("ViewOutlet");
    isAllowedEdit = permission && permission.includes("EditOutlet");
    isAllowedDelete = permission && permission.includes("DeleteOutlet");
  }

  const columns = [
    {
      field: "name",
      headerName: t("outlet.name"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t("outlet.code"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.code} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.code)}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: t("table.address"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.addressLine1} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.addressLine1)}</span>
        </Tooltip>
      ),
    },
    {
      field: "cityZone",
      headerName: t("table.city_zone"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.cityZone} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.cityZone)}</span>
        </Tooltip>
      ),
    },
    {
      field: "timeZone",
      headerName: t("table.time_zone"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip
          title={CommonUtil.getTimeZoneNameByValue(e.row.timeZone)}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              CommonUtil.getTimeZoneNameByValue(e.row.timeZone)
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      headerName: t("common.actions"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandaler>
          {isAllowedView && (
            <Tooltip title={t("common.view")} arrow>
              <VisibilityIcon
                onClick={() => handleActions("View", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
          {isAllowedEdit && (
            <Tooltip title={t("common.edit")} arrow sx={{ marginLeft: 0.5 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          )}
          {isAllowedDelete && (
            <Tooltip title={t("common.delete")} sx={{ marginLeft: 0.5 }}>
              <DeleteIcon
                onClick={() => handleActions("Delete", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  cursor: "pointer",
                  opacity: "0.6",
                  height: "17px",
                  width: "20px",
                  padding: "2px",
                }}
              />
            </Tooltip>
          )}
        </IconHandaler>
      ),
    },
  ];

  const handleDelete = (data) => {
    deleteOutlet(data.row.id).then((response) => {
      if (response.data.code === "LVLI0002") {
        enqueueSnackbar(t("tips_outlet.outlet_deleted"), {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
        loadData();
      }
      if (response.data.code !== "LVLI0002") {
        enqueueSnackbar(response.data.message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          style: {
            marginTop: "300px",
          },
        });
        setConfirm(false);
      }
    });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
      navigate(REACT_OUTLET_VIEW, { state: { id: data.id } });
    } else if (action === "Edit") {
      navigate(REACT_OUTLET_EDIT, { state: { id: data.id } });
    }
    if (action === "Delete") {
      setId(data);
      setConfirm(true);
    }
  };

  const toolbarProps = {
    //add: true,
    add: isAllowedCreate,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_OUTLET_ADD);
    },
    onRefresh: (data) => {
      setFilters({ ...filters, ...defaultFilters });
      setSearchValue("");
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  const OutletFilter = {
    onReset: () => {
      setFilters({ ...filters, ...defaultFilters });
    },
    onFilter: (filter) => {
      console.log(filter);
      let companies = [];
      if (CommonUtil.isEmpty(filter)) {
        filter = { ...defaultFilters };
      } else if (filter.client !== undefined && filter.client !== "-1") {
        companies.push(filter.client);
      }

      setFilters({
        ...filters,
        // siteId: filter?.outlet,
        country: filter?.country,
        state: filter?.state,
        city: filter?.city,
      });
      console.log(filter?.outlet);
    },
    onClientChange: (id) => {
      if (id === "-1") {
        setFilters({
          ...filters,
          companyIds: [],
        });
      }
    },
  };

  const handleChangePage = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setFilters({ ...filters, page: 0, rowsPerPage: pageSize });
  };

  const getRowId = (data) => data.id;

  const [searchValue, setSearchValue] = useState(null);

  const handleSearchClick = () => {
    setFilters({
      ...defaultFilters,
      name: searchValue,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(); // 调用搜索函数
    }
  };

  const handlerClear = () => {
    setSearchValue("");
    setFilters({
      ...defaultFilters,
      name: "",
    });
  };

  const searchClientProps = () => {
    return (
      <Grid container display={"flex"} justifyContent={"flex-end"} pb={0.5}>
        <TextField
          label={t("tips.search_by_name")}
          value={searchValue}
          onKeyDown={handleKeyDown} // 添加 onKeyDown 事件
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            style: {
              height: 50,
              width: "300px",
              padding: "10px",
              background: "#fff",
              color: "#474B4F",
              opacity: "0.6",
              boxShadow: "0px 1px 3px #0000001A",
              borderRadius: "8px",
              border: "0px !important",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id="clearButton"
                  onClick={handlerClear}
                  style={{ visibility: searchValue ? "visible" : "hidden" }}
                >
                  <ClearIcon />
                </IconButton>
                <IconButton id="Device1" onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputLabel-root": {
              color: "#D1D1D1",
            },
          }}
        />
      </Grid>
    );
  };

  return (
    <OutletLayout
      navigateBack={false}
      title={t("outlet.title")}
      toolbarProps={toolbarProps}
      //OutletFilter={OutletFilter}
      onFilter={OutletFilter.onFilter}
      searchProps={searchClientProps()}
    >
      <DataTable
        columns={columns}
        rows={records}
        getRowId={getRowId}
        onSelection={() => console.log()}
        page={filters.page}
        totalRecords={totalRecords}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(pageSize) => handleChangeRowsPerPage(pageSize)}
        rowsPerPage={filters.rowsPerPage}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <DialogContent>
          <ImagePopUp />
        </DialogContent>
      </Dialog>
      <Dialog open={confirm} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="subtitle2">
            {t("tips.selected_detele_record")}
          </Typography>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"10px"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ marginRight: 2 }}
            >
              {" "}
              {t("common.cancel")}{" "}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%)",
                color: "#FFFFFF",
                fontSize: "normal normal normal 14px / 22px Roboto",
                width: "80px",
                textTransform: "none",
              }}
              size="small"
              id="ConfirmModal-button-1"
              onClick={() => handleDelete(id)}
            >
              {" "}
              {t("common.delete")}{" "}
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </OutletLayout>
  );
}
