import { Avatar, Box, Paper } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import OverFlowText from "../../components/OverFlowText";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffect } from "react";
import cameraplus2 from "../../assets/images/AddPhotoIcon.svg";
import { downloadImage, findProductById } from "../../services/ProductService";

export default function ViewProduct() {
  const { t } = useTranslation();
  const params = useParams();
  const [product, setProduct] = useState({});
  const [customValues, setCustomValues] = useState({});
  const [image, setImage] = useState(null);
  const [objectKey, setObjectKey] = useState();
  const location = useLocation();

  useEffect(() => {
    findProductById(location.state?.id).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        setProduct(res.data.data.product);
        setCustomValues(res.data.data.object);
        setObjectKey(res.data.data.product.objectKey);
      }
    });
  }, [location.state?.id]);

  useEffect(() => {
    if (objectKey) {
      downloadImage(objectKey).then((res) => {
        if (res?.data?.data && res?.data?.code === "LVLI0000") {
          setImage(res.data.data.url);
        }
      });
    }
  }, [objectKey]);

  const formatLabel = (label) => {
    return label
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/\b\w/g, (c) => c.toUpperCase());
  };

  return (
    <RightViewLayout title={t("product.view_product")} navigateBack={"-1"}>
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          paddingTop: "20px",
          paddingBottom: "36px",
          paddingLeft: "26px",
          border: "1px solid #DDDDDD",
          margin: "0 1rem 2rem 0",
          overflow: "hidden",
        }}
      >
        <Box>
          <Box>
            {image ? (
              <Avatar
                imgProps={{ draggable: "false" }}
                alt="Organisation profile pic"
                src={image}
                style={{
                  height: "200px",
                  width: "250px",
                  zIndex: "99",
                  borderRadius: 0,
                }}
              />
            ) : null}
          </Box>
          <Box pt={2}>
            {/* {Object.entries(customValues).map(([key, value]) => {
              console.log("BBBBBBBBBBBBBBBBBBBBBBB", key, value);

              return (
                <React.Fragment key={key}>
                  <Box mt={2}>
                    <OverFlowText variant="fieldLabel">
                      {formatLabel(key)}:
                    </OverFlowText>
                  </Box>
                  <OverFlowText variant="fieldValue">
                    {value ? value : "-"}
                  </OverFlowText>
                </React.Fragment>
              );
            })} */}

            {Object.entries(customValues)
              .sort(([keyA], [keyB]) => {
                // 如果keyA是productName, 它排在前面
                if (keyA === "productName") return -1;
                // 如果keyB是productName, 它排在前面
                if (keyB === "productName") return 1;
                // 否则保持原顺序
                return 0;
              })
              .map(([key, value]) => {
                return (
                  <React.Fragment key={key}>
                    <Box mt={2}>
                      <OverFlowText variant="fieldLabel">
                        {formatLabel(key)}:
                      </OverFlowText>
                    </Box>
                    <OverFlowText variant="fieldValue">
                      {value ? value : "-"}
                    </OverFlowText>
                  </React.Fragment>
                );
              })}
          </Box>
        </Box>
      </Paper>
    </RightViewLayout>
  );
}
