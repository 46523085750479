import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
function DropzoneComponent(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64data = reader.result;
        // Pass the base64 data to the parent component
        props.getExcelFile({ file: file, base64: base64data });
      };
      reader.readAsDataURL(file);
    });

    if (acceptedFiles.length === 0 || acceptedFiles == null) {
      enqueueSnackbar(t("The image format is incorrect."), {
        variant: "error",
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png", ".svg", ".gif"],
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <div>{props.children}</div>
    </div>
  );
}

export default DropzoneComponent;
