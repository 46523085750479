import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
const Header = (props) => {
  return <AppBar sx={{
    height: '50px',
    backgroundColor: "#ffffff",
    color: '#000000',
    boxShadow: '0px 1px 1px 0px #f1f1f1'
  }} position="static">
    <Toolbar variant="dense" sx={{ padding: "0px", height: '50px', width: '100%', alignItems: 'stretch' }}>
      {props.children}
    </Toolbar>
  </AppBar>
}
export default Header