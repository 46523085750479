import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";

export const getCompanyDetails = async (companyId) => {
  return api
    .securedAxios()
    .get("web/company/" + companyId + "?toggleKeyToURL=" + true);
};

export const createClient = async (request) => {
  return api.securedAxios().post("api/v2/client", request);
};

export const updateClient = async (request) => {
  return api.securedAxios().put("api/v2/client", request);
};

export const loadAllCompanies = async () => {
  return listCompanies({ pageNumber: 1, pageSize: 1000 });
};

export const listCompanies = async (filter) => {
  const loginType = CommonUtil.getLoginType();
  let url = "web/companies/list?type=CUSTOMER";
  if (loginType !== "HQ") {
    url = url + "&cascadeLimit=0";
  } else {
    url = url + "&cascadeLimit=1";
  }
  if (filter?.name) {
    url = url + "&name=" + filter.name;
  }
  if (filter?.code) {
    url = url + "&code=" + filter.code;
  }
  if (filter?.country) {
    url = url + "&country=" + filter.country;
  }
  if (filter?.operator) {
    url = url + "&operator=" + filter.operator;
  }
  if (filter?.pageNumber) {
    url = url + "&pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  return api.securedAxios().get(url);
};

export const listRetailCompanies = async (filter) => {
  let url = "web/retail_clients/list?";
  const loginType = CommonUtil.getLoginType();

  if (filter?.name) {
    url = url + "&name=" + filter.name;
  }
  if (filter?.code) {
    url = url + "&code=" + filter.code;
  }
  if (filter?.country) {
    url = url + "&country=" + filter.country;
  }
  if (filter?.operator) {
    url = url + "&operator=" + filter.operator;
  }
  if (filter?.pageNumber) {
    url = url + "&pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  if (loginType !== "HQ") {
    url = url + "&cascadeLimit=0";
  } else {
    url = url + "&cascadeLimit=1";
  }
  return api.securedAxios().get(url);
};

export const listCompaniesPages = async (page, pageSize) => {
  return api
    .securedAxios()
    .get(
      "web/companies/list/page" +
        "?pageNumber=" +
        page +
        "&pageSize=" +
        pageSize
    );
};

export const getCompanyByIdOrCode = async (id) => {
  return api.securedAxios().get("web/companies/" + id);
};

export const addClient = async (payload) => {
  return api.securedAxios().post("api/v2/client", payload);
};

export const editClient = async (payload) => {
  return api.securedAxios().put("api/v2/client", payload);
};

export const deleteClientById = async (id) => {
  return api.securedAxios().delete("api/v2/client" + "?id=" + id);
};

export const listClients = async (filter) => {
  let url = "api/v2/clients/list?type=";
  if (filter?.type) {
    url = url + filter.type;
  }
  if (filter?.id) {
    url = url + "&id=" + filter.id;
  }
  if (filter?.name) {
    url = url + "&name=" + encodeURIComponent(filter.name);
  }
  if (filter?.code) {
    url = url + "&code=" + filter.code;
  }
  if (filter?.country) {
    url = url + "&country=" + filter.country;
  }
  if (filter?.pageNumber) {
    url = url + "&pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }

  return api.securedAxios().get(url);
};

export const loadRetailClients = async (clientType) => {
  return listClients({ pageNumber: 1, pageSize: 1000, type: clientType});
};

export const loadClients = async () => {
  return listClients({ pageNumber: 1, pageSize: 1000});
};

export const getClientById = async (id) => {
  return api.securedAxios().get("api/v2/client?" + "id=" + id);
};

export const sendActivationMail = async (emailId) => {
  return api.securedAxios().get("api/v2/client/activation_mail/" + emailId);
};

export const getUserByClientId = async (id) => {
  return api.securedAxios().get("api/v2/client/miot_email?" + "id=" + id);
};

export const activateUserAccount = async (request) => {
  return api.securedAxios().put("web/user/activate_account", request);
};


