import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_THIRD_PARTY_LIST } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { Box, Button, Grid, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import {
  createThirdPartyClient,
  listThirdPartyClient,
} from "../../services/ThirdPartyClientService";

export default function AddThirdPartyClient() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [listThirdParty, setListThirdParty] = useState([]);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 0,
    pageSize: 8,
    name: "",
    code: "",
    country: "",
    operator: "",
  });

  useEffect(() => {
    listThirdPartyClient(filters).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setListThirdParty(res?.data?.data?.thirdPartyUsers);
      }
    });
  }, []);

  const checkDuplicateName = (name) => {
    const result = listThirdParty.some(
      (item) => item.email.toLowerCase() === name.toLowerCase()
    );
    return result;
  };

  const [payload, setPayload] = useState({
    name: "",
    companyName: "",
    companyCode: "",
    phone: "",
    email: "",
  });

  const [error, setError] = useState({
    name: "",
    companyName: "",
    companyCode: "",
    phone: "",
    email: "",
  });

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.companyName)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.companyCode)) {
      setError({
        ...error,
        code: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: t("tips.required"),
      });
      return;
    } else if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("tips.invalid_email_address"),
      });
      return;
    }

    if (!CommonUtil.isEmptyString(payload.email)) {
      if (checkDuplicateName(payload.email)) {
        setError({
          ...error,
          email: t("tips_openAPI.email_exist"),
        });
        return false;
      }
    }

    if (!CommonUtil.isEmptyString(phone)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: t("tips.country_code_required"),
        });
        return;
      }
      var mobile = "+" + countryCode.replace("+", "") + "-" + phone;
      if (mobile.length < 10 || mobile.length > 25) {
        setError({
          ...error,
          phone: t("tips.mobild_nubmer"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phone)) {
        setError({
          ...error,
          phone: t("tips_retail_client.mobile_required"),
        });
        return;
      }
    }

    if (CommonUtil.isEmptyString(countryCode)) {
      if (CommonUtil.isEmptyString(phone)) {
        setError({
          ...error,
          phone: t("tips.required"),
        });
        return;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        email: payload.email.toLowerCase(),
        phone: "+" + countryCode.replace("+", "") + "-" + phone,
      };
      if (CommonUtil.isEmptyString(countryCode)) {
        if (CommonUtil.isEmptyString(phone)) {
          var request = {
            ...payload,
            phone: "",
          };
        }
      }
      createThirdPartyClient(request).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          enqueueSnackbar(t("tips_retail_client.created_success"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_THIRD_PARTY_LIST);
        } else if (response?.data?.code === "LVLE0071") {
          enqueueSnackbar(t("tips_retail_client.given_name"), {
            variant: "error",
          });
        } else if (response?.data?.code === "LVLE0075") {
          enqueueSnackbar(t("tips_retail_client.given_code"), {
            variant: "error",
          });
        } else if (response?.data?.code === "LVLE0068") {
          enqueueSnackbar(t("tips_retail_client.user_exist"), {
            variant: "error",
          });
        } else if (response?.data?.code === "UASE0149") {
          enqueueSnackbar(t("tips.invalid_email_address"), {
            variant: "error",
          });
        }
        return;
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  return (
    <RightViewLayout
      navigateBack={REACT_THIRD_PARTY_LIST}
      title={t("openAPI.add_open_api")}
    >
      <Grid sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box", p: 5 }}>
        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddRetailClint1"
              required
              label={t("table.name")}
              size="small"
              name="name"
              error={error.name}
              resetError={() => setError({ ...error, name: "" })}
              value={payload.name}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              placeholder={t("tips_openAPI.name")}
              helperText={error.name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddRetailClint2"
              required
              label={t("table.company_code")}
              size="small"
              name="companyCode"
              error={error.companyCode}
              resetError={() => setError({ ...error, companyCode: "" })}
              value={payload.companyCode}
              handleChange={handleChange}
              inputProps={{
                maxLength: 30,
              }}
              placeholder={t("tips_openAPI.company_code")}
              helperText={error.code}
              validation={"companyCode"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} px={2} mt={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddRetailClint4"
              required
              label={t("table.company_name")}
              size="small"
              name="companyName"
              value={payload.companyName}
              error={error.companyName}
              resetError={() => setError({ ...error, companyName: "" })}
              helperText={error.companyName}
              inputProps={{
                maxLength: 60,
              }}
              placeholder={t("tips_openAPI.company_name")}
              validation="alpha-numeric"
              handleChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              style={{ marginBottom: "-10px" }}
            >
              {t("table.mobile")} {<span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <CustomePhoneFiled
              id="AddPhoneno1"
              error={error.phone}
              resetError={() => setError({ ...error, phone: "" })}
              handleCountryCode={(data) => {
                setCountryCode(data.dialCode);
                setError({ ...error, phone: "" });
              }}
              countryCode={countryCode}
              placeholder={t("table.mobile")}
              phoneNumber={phone}
              name={"phone"}
              size={"small"}
              handleChange={(e) => {
                setPhone(e);
                setError({ ...error, phone: "" });
              }}
              label={"table.mobile"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} px={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              id="AddretailClint3"
              required
              label={t("table.email")}
              size="small"
              name="email"
              value={payload.email}
              error={error.email}
              resetError={() => setError({ ...error, email: "" })}
              helperText={error.email}
              inputProps={{
                maxLength: 36,
              }}
              placeholder={t("tips_openAPI.email")}
              validation="email"
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} px={2}>
          <Grid item xs={12} sx={{ pb: "20px" }}>
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Box item pl={2}>
                <Button
                  id="addRetailClint1"
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  onClick={handleSubmit}
                  style={{
                    size: "medium",
                    borderRadius: "8px",
                    opacity: 1,
                    background:
                      "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  {t("common.save")}
                </Button>
              </Box>
              <Box item>
                <Button
                  id="addRetailClint2"
                  className="text-transform-none"
                  variant="outlined"
                  onClick={() => navigate(REACT_THIRD_PARTY_LIST)}
                  size="large"
                >
                  {t("common.back")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
