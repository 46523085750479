import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { Box, Typography } from "@mui/material";
import { getCompanyByIdOrCode } from "../../services/CompanyService";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";

export function ViewCompanyProfile() {
  const [company, setCompany] = useState({
    name: "",
    email: "",
    phone: "",
    code: "",
  });
  const { t } = useTranslation();
  useEffect(() => {
    const user = CommonUtil.decodeToken();
    if (user) {
      const companyId = user.companyId;
      getCompany(companyId);
    }

    async function getCompany(companyId) {
      try {
        const response = await getCompanyByIdOrCode(companyId);
        const companyData = response?.data.data;
        setCompany(companyData);
      } catch (error) {
        console.error("Error retrieving company data:", error);
      }
    }
  }, []);

  return (
    <RightViewLayout navigateBack={"-1"} title={t("menu.view_company")}>
      <ViewPage>
        <Box>
          <Typography variant="fieldLabel">{t("table.name")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company && company.name ? company.name : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.code")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company && company.code ? company.code : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.email")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company && company.ownerEmail ? company.ownerEmail : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("table.phone")}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company && company.phone ? company.phone : "-"}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
