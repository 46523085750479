import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SidebarSubMenuItem(props) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(props.link);
  };

  return (
    <Box
      fullWidth
      sx={{
        margin: "8px",
        height: { xs: "100%", md: "auto" },
        py: { xs: 1.5, md: 0 },
        px: { xs: 0.5, md: 4 },
      }}
    >
      <Box
        id="SidebarSubMenuItem-box-1"
        display={"flex"}
        alignItems={"center"}
        style={{ cursor: "pointer", margin: "8px" }}
        onClick={() => handleClick()}
      >
        <Box>
          {props.isActive && (
            <Typography
              variant="menuItem"
              sx={{
                display: {
                  xs: "none",
                  md: "inline",
                  font: "normal normal normal 15px/22px Roboto",
                  letterSpacing: "0px",
                  textTransform: "capitalize",
                  opacity: "1",
                },
                color: "#474B4F",
              }}
            >
              {props.label}
            </Typography>
          )}

          {!props.isActive && (
            <Typography
              variant="menuItem"
              sx={{
                display: {
                  xs: "none",
                  md: "inline",
                  font: "normal normal normal 15px/22px Roboto",
                  letterSpacing: "0px",
                  textTransform: "capitalize",
                  opacity: "1",
                },
              }}
            >
              {props.label}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
