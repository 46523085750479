export default {
  menu: {
    retail_client_management: "การจัดการลูกค้ารายย่อย",
    template_list: "รายการเทมเพลต",
    picture_library: "คลังภาพ",
    open_api_management: "การจัดการ เปิด API",
    dashboard: "แดชบอร์ด",
    system_setting: "การตั้งค่าระบบ",
    authorization_level: "ระดับการอนุญาต",
    approved_flowchart: "ผังงานการอนุมัติ",
    product_information: "ข้อมูลผลิตภัณฑ์",
    retail_main_data: "ข้อมูลหลักของการขายปลีก",
    outlet: "เอาท์เล็ต",
    product: "ผลิตภัณฑ์",
    user_management: "การจัดการผู้ใช้",
    user: "ผู้ใช้",
    device_management: "การจัดการอุปกรณ์",
    gateway: "เกตเวย์",
    screens: "หน้าจอ",
    price_management: "การจัดการราคา",
    price_change_events: "เหตุการณ์การเปลี่ยนแปลงราคา",
    approval: "การอนุมัติ",
    approval_list: "รายการอนุมัติ",
    approval_log: "บันทึกการอนุมัติ",
    system_records: "บันทึกระบบ",
    synchronized_logs: "บันทึกที่ซิงโครไนซ์",
    view_company: "ดูบริษัท",
    about: "เกี่ยวกับ",
    view_profile: "ดูโปรไฟล์",
    logout: "ออกจากระบบ",
    english: "อังกฤษ",
    chinese: "จีน",
    indonesian: "อินโดนีเซีย",
    thai: "ไทย",
    language: "ภาษา",
    arabic: "อาหรับ",
    back_to_login: "กลับไปล็อกอิน",
  },
};
