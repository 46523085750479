/* eslint-disable no-debugger */
/* eslint-disable no-dupe-else-if */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/rules-of-hooks */
import { Dropzone, FileMosaic, useFakeProgress } from "@files-ui/react";
import React, { useState, useRef } from "react";
import {
  Button,
  Stack,
  Typography,
  Grid,
  Alert,
  InputLabel,
  Box,
  FormHelperText,
  AlertTitle,
  TextField,
} from "@mui/material";
import {
  BootstrapActions,
  BootstrapContent,
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../components/dialog";
import { useTranslation } from "react-i18next";
// import { uploadCompnatLang } from "@/utils/langUtils";
import { uploadUpgrade } from "../../services/DeviceService";
import { getFileSize } from "../../util/utils";
// 消息提示
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

const UploadUpgrade = (props, ref) => {
  const { t } = useTranslation();
  const { open, onCancel, screenIdList } = props;
  const [files, setFiles] = useState([]);
  const [type, setType] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState(undefined);

  const [filesError, setFilesError] = useState(t("tips_gateway.selectFile"));
  const [isFilesError, setIsFilesError] = useState(false);
  const dropzoneRef = useRef(null);
  const progress = useFakeProgress();
  const [uploadLoading, setUploadLoding] = useState(false);
  const errorStyle = { border: "1px solid #ff4d4f" };

  // 表单定义
  const uploadFormik = useFormik({
    initialValues: {
      // type: type,
      version: "",
    },
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        handelSaveSubmit(values);
        setStatus({ success: false });
        setSubmitting(false);
      } catch (err) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      version: Yup.string()
        .matches(/^V/, t("tips_gateway.versionStartWithV"))
        .required(t("tips_gateway.enterUpgradePackageVersion")),
    }),
  });

  const updateFiles = (incommingFiles) => {
    setIsFilesError(false);
    let arrayFile = [];
    arrayFile.push(incommingFiles[incommingFiles.length - 1]);
    setFiles(arrayFile);
  };
  const removeFile = (id) => {
    setFiles([]);
  };
  // 样式
  const sxGridContainer = React.useMemo(
    () => ({
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      gap: 5,
    }),
    []
  );
  // 支持的格式，用于正则校验
  const suffix = `(apk)`;
  //表单提交
  const handelSaveSubmit = async (formValues) => {
    // 校验文件
    if (files.length === 0) {
      setIsFilesError(true);
      return;
    }
    setIsFilesError(false);
    // 设置按钮等于加载
    setUploadLoding(true);

    const values = {
      type: formValues.type,
      version: formValues.version,
      deviceIdList: screenIdList,
    };
    const errorFiles = [];
    for (let i = 0; i < files.length; i++) {
      values.file = files[i].file;
      files[i].uploadStatus = "uploading";
      files[i].progress = progress;
      await uploadUpgrade(values)
        .then((res) => {
          files[i].progress = 100;
          files[i].uploadStatus = "success";

          if (res?.data?.code === "DMSI0000") {
            toast.success(res.data.message);
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((error) => {
          files[i].uploadStatus = "error";
          files[i].uploadMessage = [
            typeof error === "string" ? error : error.message,
          ];
          errorFiles.push(files[i]);
        });
    }
    // 设置按钮等于加载
    setUploadLoding(false);
    if (errorFiles.length === 0) {
      toast.success(t("tips_gateway.uploadSuccessful"));
      handleClose();
      return;
    }
    setFiles([...errorFiles]);
  };

  //关闭清除表单值
  const handleClose = () => {
    setFiles([]);
    uploadFormik.handleReset();
    onCancel();
  };

  return (
    <>
      <BootstrapDialog
        open={open}
        maxWidth={files.length > 0 ? "md" : "xs"}
        fullWidth
        onClose={handleClose}
      >
        <form noValidate onSubmit={uploadFormik.handleSubmit}>
          <BootstrapDialogTitle onClose={handleClose}>
            <Typography variant="h5" component="p">
              {t("tips_gateway.uploadAndUpgrade")}
            </Typography>
          </BootstrapDialogTitle>
          <BootstrapContent>
            <Grid container spacing={2}>
              <Grid item xs={files.length > 0 ? 6 : 12}>
                <Stack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{ marginBottom: 2 }}
                >
                  <InputLabel htmlFor="version">
                    {t("gateway.versionNumber")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    id="version"
                    fullWidth
                    type="text"
                    placeholder={t(
                      "Please enter the upgrade package version, starting with the letter 'V'"
                    )}
                    variant="outlined"
                    name="version"
                    onBlur={uploadFormik.handleBlur}
                    onChange={uploadFormik.handleChange}
                    value={uploadFormik.values.version}
                    error={Boolean(
                      uploadFormik.touched.version &&
                        uploadFormik.errors.version
                    )}
                  />

                  {uploadFormik.touched.version &&
                    uploadFormik.errors.version && (
                      <FormHelperText error id="version-error">
                        {uploadFormik.errors.version}
                      </FormHelperText>
                    )}
                </Stack>

                <Stack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{ marginBottom: 2 }}
                >
                  <InputLabel htmlFor="username-login">
                    {t("tips_gateway.uploadFile")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Dropzone
                    // localization={uploadCompnatLang()}
                    ref={dropzoneRef}
                    style={{ ...(isFilesError ? errorStyle : {}) }}
                    autoClean
                    fakeUpload
                    uploading
                    onUploadStart={(uploadAbleFiles) => {
                      console.log(uploadAbleFiles);
                    }}
                    uploadConfig={{ url: "#", autoUpload: false }}
                    disabled={files.length >= 1 ? true : false}
                    footer={false}
                    accept={"application/vnd.android.package-archive"}
                    validator={(file) => {
                      let fileName = file.name.substring(
                        0,
                        file.name.lastIndexOf(".")
                      );
                      // 校验文件类型名称无法同时上传相同的
                      for (let i = 0; i < files.length; i++) {
                        let filestemName = files[i].name.substring(
                          0,
                          file.name.lastIndexOf(".")
                        );
                        if (fileName === filestemName) {
                          setErrorMsg(
                            t(
                              "File name: {{name}}, Already exists, Cannot upload",
                              {
                                name: file.name,
                              }
                            )
                            // `文件名："${file.name}" , 相同，无法上传`
                          );
                          return {
                            valid: false,
                            errors: [
                              t(
                                "File name: {{name}}, Already exists, Cannot upload",
                                {
                                  name: file.name,
                                }
                              ),
                            ],
                          };
                        }
                      }
                      const fileSize = getFileSize(file.size);
                      // eslint-disable-next-line no-useless-escape
                      let regular = new RegExp(`.*\.${suffix}`);
                      if (!regular.test(file.name.toLocaleLowerCase())) {
                        setErrorMsg(
                          t("tips_gateway.unsupportedType", {
                            type: file.type,
                          })
                        );
                        return {
                          valid: false,
                          errors: [
                            t("tips_gateway.unsupportedType", {
                              type: file.type,
                            }),
                          ],
                        };
                      } else if (fileSize > 100) {
                        setErrorMsg(
                          t("tips_gateway.fileSizeLimit", {
                            fileSize: fileSize,
                          })
                        );
                        return {
                          valid: false,
                          errors: [
                            t("tips_gateway.fileSizeLimit", {
                              fileSize: fileSize,
                            }),
                          ],
                        };
                      } else {
                        return { valid: true };
                      }
                      // return regular.test(file.name) ? true : null;
                    }}
                    header={false}
                    uploadOnDrop={() => {
                      console.log("aaa");
                    }}
                    onChange={updateFiles}
                    value={files}
                    maxFileSize={1 * 1024 * 1024 * 1024}
                    maxFiles={1}
                    // accept={"image/*"}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <svg
                        t="1688974869714"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2826"
                        width="80"
                        height="80"
                      >
                        <path
                          d="M1024 640.192C1024 782.912 919.872 896 787.648 896h-512C123.904 896 0 761.6 0 597.504 0 451.968 94.656 331.52 226.432 302.976 284.16 195.456 391.808 128 512 128c152.32 0 282.112 108.416 323.392 261.12C941.888 413.44 1024 519.04 1024 640.192z m-341.312-139.84L512 314.24 341.312 500.48h341.376z m-213.376 0v256h85.376v-256H469.312z"
                          fill="#bfbfbf"
                          p-id="2827"
                        ></path>
                      </svg>
                      <Typography>{t("tips_gateway.dragOrBrowse")}</Typography>
                    </Stack>
                  </Dropzone>
                  {isFilesError && (
                    <FormHelperText error id="files-error">
                      {filesError}
                    </FormHelperText>
                  )}
                </Stack>
                {errorMsg && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setErrorMsg(undefined);
                    }}
                    sx={{ marginBottom: 1 }}
                  >
                    {errorMsg}
                  </Alert>
                )}

                <Alert severity="warning">
                  <AlertTitle>{t("common.prompt")}</AlertTitle>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("tips_gateway.onlyApkFormat"),
                    }}
                  />
                </Alert>
              </Grid>
              {files.length > 0 && (
                <Grid item xs={6}>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Typography>{t("common.preview")}</Typography>
                    <Box sx={sxGridContainer} key="preview">
                      {files.map((file, index) => (
                        <Box>
                          <FileMosaic
                            // localization={uploadCompnatLang()}
                            {...file}
                            progress={progress}
                            key={file?.id}
                            onDelete={removeFile}
                            preview
                            info
                          />
                        </Box>
                      ))}
                    </Box>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </BootstrapContent>
          <BootstrapActions>
            <Stack spacing={1} direction="row">
              <Button color="info" variant="outlined" onClick={handleClose}>
                {t("common.cancel")}
              </Button>
              <LoadingButton
                type="submit"
                loading={uploadLoading}
                variant="contained"
                color="primary"
                disableElevation
                // disabled={uploadBtnDisable}
              >
                {t("common.ok")}
              </LoadingButton>
            </Stack>
          </BootstrapActions>
        </form>
      </BootstrapDialog>
    </>
  );
};
export default React.forwardRef(UploadUpgrade);
