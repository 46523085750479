import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Grid, FormHelperText, styled, InputLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import CustomInput from "@c/TimeCustInput";
import RequirePoint from "@z/RequirePoint";
function TimePickerDate(props) {
  const {
    formik = null,
    label,
    name,
    error,
    disabled = false,
    disableFuture,
    disablePast,
    onChangeText,
    placeholder,
    size,
    labelPostion,
    width,
    fontSize = "22px",
    ...orther
  } = props;

  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    setDateTime(formik.values[name]);
  }, []);

  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          defaultValue={props.defaultValue}
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          onChangeText={onChangeText}
          label={
            <InputLabel
              shrink
              htmlFor={"CmpAutoComPlete_" + name}
              style={{
                marginTop: labelPostion === "left" ? "12px" : "",
                width: width,
                fontSize: fontSize,
              }}
            >
              {props.required && <RequirePoint></RequirePoint>}
              {label || <Grid mt={4.3}></Grid>}
            </InputLabel>
          }
          name={name}
          value={dayjs(formik.values[name], "HH:mm:ss")}
          onChange={(e) => {
            setDateTime(e);
            formik.setFieldValue(name, dayjs(e).format("HH:mm:ss"));
          }}
          renderInput={(params) => {
            return (
              <CustomInput
                fullWidth
                handleChange={(event) => event.preventDefault()}
                resetError={() => console.log("6666")}
                size={size ? size : " "}
                // value={dateTime}
                {...params}
                style={{
                  width: "100%",
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder,
                  readOnly: true,
                }}
              />
            );
          }}
          {...orther}
        />
        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </Grid>
  );
}

export default TimePickerDate;
