import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_OUTLET_LIST } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { timeZoneList, timeZoneMapping } from "../../constants/TimeZone";
import AddressFromMap from "../../components/AddressFromMap";
import { createOutlet } from "../../services/OutletService";

export default function AddOutlet() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState();

  const [timezone, setTimezone] = useState({});

  let dataInfo = localStorage.getItem("BAIDUMAP");
  useEffect(() => {
    if (dataInfo === "cn") {
      setTimezone({
        value: "+08:00",
        name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi,Kuala Lumpur, Singapore",
      });
    } else {
      setTimezone({});
    }
  }, [dataInfo]);

  const [payload, setPayload] = useState({
    name: "",
    code: "",
    addressLine1: "",
    city: "",
    state: "",
    country: "",
    areaCode: "",
    latitude: "",
    longitude: "",
    clientId: "",
    cityZoneId: "",
    cityZone: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    addressLine1: "",
    city: "",
    state: "",
    country: "",
    areaCode: "",
    latitude: null,
    longitude: null,
    clientId: "",
    cityZoneId: "",
    cityZone: "",
  });

  useEffect(() => {
    setUser(JSON.parse(localStorage?.getItem("USER")));
  }, []);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.code)) {
      setError({
        ...error,
        code: t("tips.required"),
      });
      return;
    }

    if (!CommonUtil.isEmptyString(payload.code) && payload.code.length > 30) {
      setError({
        ...error,
        code: t("tips_outlet.code_exceed"),
      });
      return;
    }
    if (CommonUtil.isEmpty(timezone)) {
      setError({
        ...error,
        timeZone: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.addressLine1)) {
      setError({
        ...error,
        addressLine1: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.country)) {
      setError({
        ...error,
        country: t("tips.required"),
      });
      return;
    }
    if (CommonUtil.isEmpty(payload.state)) {
      setError({
        ...error,
        state: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.city)) {
      setError({
        ...error,
        city: t("tips.required"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.cityZone)) {
      setError({
        ...error,
        cityZone: t("tips.required"),
      });
      return;
    }
    return true;
  };

  const handleSubmit = () => {
    let params = {
      ...payload,
      timeZone: timezone?.value,
    };
    if (validateForm()) {
      createOutlet(params).then((response) => {
        if (response?.data?.code === "LVLI0007") {
          enqueueSnackbar(t("tips_outlet.outlet_created"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_OUTLET_LIST);
          return;
        }

        enqueueSnackbar(response.data.message, { variant: "error" });

        switch (response?.data?.code) {
          case "LVLE0035":
            setError({
              ...error,
              name: t("tips_outlet.outlet_given_name"),
            });
            break;
          case "LVLE0036":
            setError({
              ...error,
              code: t("tips_outlet.outlet_given_code"),
            });
            break;
          case "LVLE0015":
            setError({
              ...error,
              name: t("tips_outlet.name_length"),
            });
            break;
          case "LVLE0016":
            setError({
              ...error,
              code: t("tips_outlet.code_length"),
            });
            break;
          case "LVLE0037":
            setError({
              ...error,
              code: t("tips_outlet.code_alphanumeric"),
            });
            break;
          default:
            break;
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleLocationFromMap = (data) => {
    const dataItem = data.content;
    const detailItem = dataItem?.address_detail;
    const baiduMap = localStorage.getItem("BAIDUMAP");

    if (baiduMap == "cn") {
      setPayload({
        ...payload,
        latitude: dataItem.point.x,
        longitude: dataItem.point.y,
        addressLine1: dataItem.address,
        country: detailItem.country,
        state: detailItem?.province,
        city: detailItem?.city,
        areaCode: detailItem?.city_code,
      });
      setError({
        ...error,
        latitude: "",
        longitude: "",
        addressLine1: "",
        countryId: "",
        stateId: "",
        cityId: "",
      });
    } else {
      if (data) {
        if (data.country) {
          let country = data.country;
          let state = data.state;
          let city = data.city;
          let areaCode = data.pincode;
          if (country) {
            let timeZone_ = data.timeZone;
            let timezone_ = timeZoneMapping[timeZone_];
            // setTimezone(timezone_)
          }
          let addressDetail = country + state + city;
          setPayload({
            ...payload,
            latitude: data.lat,
            longitude: data.lng,
            addressLine1: data.addressLine1 ? data.addressLine1 : addressDetail,
            country: country,
            state: state,
            city: city,
            areaCode: areaCode,
          });
          setError({
            ...error,
            latitude: "",
            longitude: "",
            addressLine1: "",
            countryId: "",
            stateId: "",
            cityId: "",
          });
        }
      }
    }
  };

  return (
    <RightViewLayout
      navigateBack={REACT_OUTLET_LIST}
      title={t("outlet.add_outlet")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutLet1"
            required
            label={t("outlet.name")}
            size="small"
            name="name"
            error={error.name}
            resetError={() => setError({ ...error, name: "" })}
            value={payload.name}
            handleChange={handleChange}
            validation={"alpha-numeric-ch-th"}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
            placeholder={t("tips_outlet.outlet_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet2"
            required
            label={t("outlet.code")}
            size="small"
            name="code"
            error={error.code}
            resetError={() => setError({ ...error, code: "" })}
            value={payload.code}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.code}
            validation="code"
            placeholder={t("tips.outlet_code")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("table.time_zone")}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            id="AddOutletTimeZoneList"
            options={timeZoneList}
            value={timezone}
            disabled={localStorage.getItem("BAIDUMAP")}
            onChange={(e, v) => {
              setTimezone(v);
              setPayload({ ...payload, timeZone: v ? v.value : "" });
              setError({ ...error, timeZone: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.timeZone}
                helperText={error.timeZone}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet3"
            required
            label={t("table.address")}
            size="small"
            name="addressLine1"
            value={payload.addressLine1}
            error={error.addressLine1}
            resetError={() => setError({ ...error, addressLine1: "" })}
            helperText={error.addressLine1}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AddressFromMap
                    id="AddOutletAddress3"
                    onSelectLocation={(address) =>
                      handleLocationFromMap(address)
                    }
                  />
                </InputAdornment>
              ),
            }}
            placeholder={t("tips.address")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet22"
            required
            label={t("table.country")}
            size="small"
            name="country"
            error={error.country}
            resetError={() => setError({ ...error, country: "" })}
            value={payload.country}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.country}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.country_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet22"
            required
            label={t("table.state")}
            size="small"
            name="state"
            error={error.state}
            resetError={() => setError({ ...error, state: "" })}
            value={payload.state}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.state}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.state_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet22"
            required
            label={t("table.city")}
            size="small"
            name="city"
            error={error.city}
            resetError={() => setError({ ...error, city: "" })}
            value={payload.city}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.city}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.city_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet21"
            label={t("table.area_code")}
            size="small"
            name="areaCode"
            error={error.areaCode}
            resetError={() => setError({ ...error, areaCode: "" })}
            value={payload.areaCode}
            handleChange={handleChange}
            inputProps={{
              maxLength: 12,
            }}
            helperText={error.areaCode}
            validation="alpha-numeric"
            placeholder={t("tips.area_code")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            id="AddOutlet21"
            label={t("table.city_zone")}
            size="small"
            required
            name="cityZone"
            error={error.cityZone}
            resetError={() => setError({ ...error, cityZone: "" })}
            value={payload.cityZone}
            handleChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
            helperText={error.cityZone}
            validation="alpha-numeric-ch-th"
            placeholder={t("tips.city_zone")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} pt={4}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                id="AddOutlet-button-01"
                variant="contained"
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
                style={{
                  size: "medium",
                  borderRadius: "8px",
                  opacity: 1,
                  background:
                    "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                }}
              >
                {t("common.save")}
              </Button>
            </Box>
            <Box item>
              <Button
                id="AddOutlet-button-02"
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_OUTLET_LIST)}
                size="large"
              >
                {t("common.back")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
