import { Grid } from "@mui/material";
import React, { useState } from "react";
import RenderComponent from "./RenderComponent";
import ComponentList from "./ComponentList";
const CenterArea = (props) => {
  const { layoutJSON, setLayoutJSON } = props;

  const setClickInfo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.setActiveId) {
      props.setActiveId("");
    }
    if (props.setCurrent) {
      props.setCurrent(null);
    }
  };

  return (
    <Grid
      onClick={setClickInfo}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {layoutJSON.componentList.length > 0 && (
        <ComponentList
          {...props}
          layoutJSON={layoutJSON}
          setLayoutJSON={setLayoutJSON}
        ></ComponentList>
      )}

      <Grid
        className="boundary-element"
        sx={{
          width: layoutJSON.width,
          height: layoutJSON.height,
          border: "1px solid gray",
          backgroundColor: layoutJSON.bgColor,
        }}
      >
        {layoutJSON.componentList.map((item) => {
          let info = JSON.parse(JSON.stringify(item));
          return (
            <RenderComponent
              {...props}
              key={info.id}
              info={info}
            ></RenderComponent>
          );
        })}
      </Grid>
    </Grid>
  );
};
export default CenterArea;
