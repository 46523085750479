import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as Info2 } from "../../assets/images/device_guide_configure.svg";
import { ReactComponent as Info3 } from "../../assets/images/device_guide_factory_reset.svg";
import { ReactComponent as Info1 } from "../../assets/images/device_guide_power_on.svg";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_DEVICE_ADD } from "../../router/ReactEndPoints";
import { useTranslation } from "react-i18next";

const DeviceInstructionPage = (props) => {
  const [read, setRead] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const handleSubmit = () => {
    if (read) navigate(REACT_DEVICE_ADD);
    else {
      enqueueSnackbar(`${t("tips_gateway.readInstructionsToAddDevice")}`, {
        variant: "error",
      });
    }
  };
  return (
    <>
      <RightViewLayout
        navigateBack={-1}
        title={t("gateway.addDeviceToOutlet")}
        actions={props.actions}
      >
        <Grid container spacing={1} mb={2} p={1} overflow="auto">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={{ marginBottom: "1rem", fontWeight: 450 }}>
              {t("tips_gateway.deviceNetworkConfigSteps")}
            </Typography>
          </Grid>
          <Grid
            item
            lg={3.9}
            md={6}
            sm={12}
            xs={12}
            border="1px solid #DDDDDD"
            borderRadius="5px"
            mr={"5px"}
          >
            <DetailBox
              title={t("tips_gateway.step1PowerUp")}
              information={t("tips_gateway.step1Description")}
              icon={<Info1 />}
            />
          </Grid>
          <Grid
            item
            lg={3.9}
            md={6}
            sm={12}
            xs={12}
            border="1px solid #DDDDDD"
            borderRadius="5px"
            mr={"5px"}
          >
            <DetailBox
              title={t("tips_gateway.step2ConfigureNetwork")}
              information={t("tips_gateway.step2Description")}
              icon={<Info2 />}
            />
          </Grid>
          <Grid
            item
            lg={3.9}
            md={6}
            sm={12}
            xs={12}
            border="1px solid #DDDDDD"
            borderRadius="5px"
            mr={"5px"}
          >
            <DetailBox
              title={t("tips_gateway.step3RestoreFactorySettings")}
              information={t("tips_gateway.step3Description")}
              icon={<Info3 />}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={10} marginTop="20px">
            <Box display={"flex"}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setRead(e.target.checked)} />
                  }
                  label={
                    <span style={{ fontSize: "0.8rem" }}>
                      {t("tips_gateway.readInstructions")}
                    </span>
                  }
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} marginTop="20px" pr={3}>
            <Button
              id="deviceInstructionPage1"
              fullWidth
              style={{
                size: "medium",
                background:
                  "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                borderRadius: "8px",
                opacity: 1,
                width: "160px",
                height: "48px",
              }}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {t("common.continue")}
            </Button>
          </Grid>
        </Grid>
      </RightViewLayout>
    </>
  );
};

const DetailBox = (props) => {
  return (
    <Box padding={1}>
      <Typography sx={{ fontSize: "16px", fontWeight: 450, color: "#474B4F" }}>
        {props.title}
      </Typography>
      <Typography
        sx={{
          marginTop: "8px",
          fontSize: "14px",
          fontWeight: 420,
          color: "#474B4F",
          opacity: "50%",
        }}
      >
        {props.information}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
          height: "200px",
          alignItems: "center",
        }}
      >
        {props.icon}
      </Box>
    </Box>
  );
};

export default DeviceInstructionPage;
