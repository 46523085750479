/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";

const RefushPie = ({ value }) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();

  const colorList = [
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: "#78BC27" },
      { offset: 1, color: "#1487CA" },
    ]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: "#78BC27" },
      { offset: 1, color: "#1487CA" },
    ]),
  ];
  const getOptions = () => {
    const colorList = value === 0 ? ["#FF6384"] : ["#88cbfa", "#FF6384"]; // 粉红色或蓝色与银色
    let option = {
      title: {
        text: value + "%",
        x: "center",
        y: "center",
        textStyle: {
          fontSize: 28,
          color: "#2A3A77",
        },
      },
      tooltip: {
        trigger: "item",
        show: false,
      },

      grid: {
        left: "5%",
        right: "5%",
        top: "5%",
        containLabel: true,
      },

      legend: {
        orient: "vertical",
        left: "center",
        bottom: "0%",
        data:
          value === 0
            ? [t("common.no_data")]
            : [t("common.success"), t("common.fail")],
        textStyle: {
          color: "#2A3A77",
          fontSize: 14,
        },

        backgroundColor: "#f5f5f5",
        borderColor: "#ccc",
        borderWidth: 1,
        padding: [10, 10, 10, 10],
        icon: "circle",
        itemGap: 10,
        itemWidth: 40,
        itemHeight: 10,
        borderRadius: 5,
      },

      series: [
        {
          type: "pie",
          center: ["50%", "50%"],
          radius: ["60%", "95%"],
          clockwise: false,
          avoidLabelOverlap: false,
          hoverOffset: 0,
          itemStyle: {
            color: function (params) {
              return colorList[params.dataIndex];
            },
          },

          label: {
            show: false,
          },

          labelLine: {
            length: 20,
            length2: 30,
            lineStyle: {
              width: 1,
            },
          },
          data:
            value === 0
              ? [
                  {
                    name: t("common.no_data"),
                    value: 1, // 确保显示饼图
                  },
                ]
              : [
                  {
                    name: t("common.success"),
                    value: value,
                  },
                  {
                    name: t("common.fail"),
                    value: 100 - value, // 假设总和为100
                  },
                ],
          animation: false, // 关闭动画
        },
      ],
    };

    return option;
  };

  const myEcharts = useRef(null); // 用 ref 保存 echarts 实例

  const options = useMemo(() => getOptions(), [value]);

  useEffect(() => {
    const initChart = () => {
      myEcharts.current = echarts.init(chartRef.current, null, {
        renderer: "svg",
      });
      myEcharts.current.resize();
      window.addEventListener("resize", handleResize);
      // 只在初始化时设置初始选项
      myEcharts.current.setOption(options, true);
    };

    const handleResize = () => {
      if (myEcharts.current) {
        myEcharts.current.resize();
      }
    };

    // 初始化图表
    initChart();

    // 在组件卸载时移除事件监听和销毁图表实例
    return () => {
      window.removeEventListener("resize", handleResize);
      if (myEcharts.current) {
        myEcharts.current.dispose();
      }
    };
  }, []); // 只在组件挂载时执行

  useEffect(() => {
    if (myEcharts.current) {
      // 当 options 变化时更新图表选项
      myEcharts.current.setOption(options, {
        notMerge: true, // 不合并选项
        lazyUpdate: true, // 延迟更新
      });
    }
  }, [options]); // 仅在 options 更新时执行
  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "330px",
      }}
    ></div>
  );
};

export default RefushPie;

// useEffect(() => {
//   let myEcharts = null;
//   const initChart = () => {
//     myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
//     // 设置初始大小
//     myEcharts.resize();
//     // 监听窗口大小变化，自动调整图表大小
//     window.addEventListener("resize", handleResize);
//     // const options = getOptions();
//     myEcharts.setOption(options);
//     updateChart();
//   };

//   const updateChart = () => {
//     // const options = getOptions();
//     myEcharts.setOption(options);
//   };
//   const handleResize = () => {
//     myEcharts.resize();
//   };
//   // 在组件挂载时进行初始化
//   initChart();

//   // 在组件卸载时移除事件监听
//   return () => {
//     window.removeEventListener("resize", handleResize);
//     myEcharts.dispose();
//   };
// }, [value]);
