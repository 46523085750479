import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import axios from "axios";

import { Box, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RightViewLayout from "../../components/RighViewLayout";
import {
  REACT_TEMPLATE_ADD,
  REACT_TEMPLATE_LIST,
} from "../../router/ReactEndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import { createTemplate } from "../../services/TemplateService";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { IconButton, Tooltip, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { attributeList } from "../../services/ProductService";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";

export default function TemplateEditor() {
  const { t } = useTranslation();

  const [canvasColor, setCanvasColor] = useState("white");
  // const [fontstyle, setFontstyle] = useState("normal");
  //const [selectedColor, setSelectedColor] = useState("#ff0000");
  //const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [screenResolution, setScreenResolution] = useState("");
  const [name, setName] = useState("");
  const [screenOrientation, setScreenOrientation] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const location = useLocation();
  const [splitArray, setSplitArray] = useState("");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const canvas = useRef(null);

  const [payload, setPayload] = useState({
    name: "",
    screenOriantation: "",
    screenResolution: "",
    templatePhoto: "",
    fileExtension: "",
    size: "",
    model: "",
    type: "",
  });
  const canvasHeight = splitArray[1]; // Adjust the canvas width as needed
  const canvasWidth = splitArray[0]; // Adjust the canvas height as needed
  const [boxHeight, setBoxHeight] = useState(100); // Initial height
  const [boxWidth, setBoxWidth] = useState(100); // Initial width
  //const [selectColor, setSelectColor] = useState("transparent");
  const [selectedShape, setSelectedShape] = useState("square");
  const [textColor, setTextColor] = useState("black");
  const [selectLabel, setSelectLabel] = useState("");
  const [dynamicFontStyle, setDynamicFontStyle] = useState("bold");
  const [dynamicFontSize, setDynamicFontSize] = useState("22");
  const [dynamicTextAlign, setDynamicTextAlign] = useState("left");
  const [activeElement, setActiveElement] = useState(null);

  const [activeHeight, setActiveHeight] = useState(0);
  const [activeWidth, setActiveWidth] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [newLabels, setNewLabels] = useState([]);
  const [labels, setLabels] = useState([]);
  useEffect(() => {
    attributeList().then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        const newLabelsData = res.data.data;
        const updatedNewLabels = {};
        const newLabelNames = [];

        newLabelsData.forEach((item) => {
          if (item.label) {
            updatedNewLabels[item.name] = item.name; // Using item.name as key
            newLabelNames.push(item.name); // Collecting the names in an array
          }
        });

        setNewLabels(updatedNewLabels);

        const initialLabels = [
          t("table_table_product.product_name"),
          t("editor.product_price"),
          t("editor.category1"),
          t("editor.category2"),
          t("editor.quantity"),
          t("editor.origin"),
          t("editor.manufacturer"),
          type === "Discount" ? t("editor.discount_price") : null,
        ];

        const combinedLabels = initialLabels.concat(
          newLabelNames.map((name) => updatedNewLabels[name])
        );

        setLabels(combinedLabels.filter((label) => label !== null));
      }
    });
    if (location.state) {
      setScreenResolution(location.state.screenResolution);
      setSplitArray(screenResolution.split("*"));
      setName(location.state.name);
      setScreenOrientation(location.state.screenOriantation);
      setModel(location.state.model);
      setType(location.state.type);

      localStorage.setItem("screenResolution", location.state.screenResolution);
      localStorage.setItem("name", location.state.name);
      localStorage.setItem(
        "screenOrientation",
        location.state.screenOriantation
      );
      localStorage.setItem("model", location.state.model);
      localStorage.setItem("type", location.state.type);
    } else {
      let screenResolution = localStorage.getItem("screenResolution");
      let name = localStorage.getItem("name");
      let screenOrientation = localStorage.getItem("screenOrientation");
      let model = localStorage.getItem("model");
      let type = localStorage.getItem("type");
      localStorage.getItem("");
      setScreenResolution(screenResolution);
      setSplitArray(screenResolution.split("*"));
      setName(name);
      setScreenOrientation(screenOrientation);
      setModel(model);
      setType(type);
    }

    updatePayload();
    canvas.current = initCanvas();

    let active = canvas.current.getActiveObject();
    setActiveElement(active);

    const handleKeyDown = (e) => {
      const movementAmount = 5;
      const activeObject = canvas.current.getActiveObject();
      if (activeObject) {
        let newLeft = activeObject.left;
        let newTop = activeObject.top;
        if (e.key === "ArrowLeft") {
          newLeft = Math.max(0, activeObject.left - movementAmount);
        } else if (e.key === "ArrowRight") {
          newLeft = Math.min(
            canvasWidth - activeObject.width,
            activeObject.left + movementAmount
          );
        } else if (e.key === "ArrowUp") {
          newTop = Math.max(0, activeObject.top - movementAmount);
        } else if (e.key === "ArrowDown") {
          newTop = Math.min(
            canvasHeight - activeObject.height,
            activeObject.top + movementAmount
          );
        }

        activeObject.set({
          left: newLeft,
          top: newTop,
        });
        canvas.current.renderAll();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Delete") {
        const activeObject = canvas.current.getActiveObject();
        if (activeObject) {
          canvas.current.remove(activeObject);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      canvas.current.dispose();
      canvas.current = null;
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [canvasWidth, canvasHeight]);

  //添加一个盒子到画布
  function addBoxToCanvas() {
    if (boxHeight > canvasHeight) {
      enqueueSnackbar(t("tips_editor.height_than") + canvasHeight + ".", {
        variant: "error",
      });
      return;
    }

    if (boxWidth > canvasWidth) {
      enqueueSnackbar(t("tips_editor.width_than") + canvasWidth + ".", {
        variant: "error",
      });
      return;
    }

    const hasEqualSizeImage = canvas.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvas.current.width) &&
          Number(obj.height) === Number(canvas.current.height)
      );

    if (hasEqualSizeImage) {
      enqueueSnackbar(t("tips_editor.cannot_add_shape"), { variant: "error" });
      return;
    }

    let rect;

    switch (selectedShape) {
      case "square":
        rect = new fabric.Rect({
          left: 0,
          top: 0,
          width: boxWidth,
          height: boxHeight,
          fill: textColor,
          lockRotation: true,
          lockScalingX: false, // Set lockScalingX property to true
          lockScalingY: false,
        });
        break;
      case "circle":
        rect = new fabric.Circle({
          left: 0,
          top: 0,
          radius: boxWidth / 2,
          fill: textColor,
          lockRotation: true,
          lockScalingX: false, // Set lockScalingX property to true
          lockScalingY: false,
        });
        break;
      // Add more shapes as needed
      default:
        rect = new fabric.Rect({
          left: 0,
          top: 0,
          width: boxWidth,
          height: boxHeight,
          fill: textColor,
          lockRotation: true,
          lockScalingX: false, // Set lockScalingX property to true
          lockScalingY: false,
        });
    }

    canvas.current.add(rect);
  }

  //添加产品标签到画布
  function addLabelToCanvas() {
    const hasEqualSizeImage = canvas.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvas.current.width) &&
          Number(obj.height) === Number(canvas.current.height)
      );

    //当画布存在图形并且图像占满画布则无法添加标签
    if (hasEqualSizeImage) {
      enqueueSnackbar(t("tips_editor.cannot_add_text"), { variant: "error" });
      return;
    }
    //添加产品标签
    switch (selectLabel) {
      case t("table_table_product.product_name"):
        addElementToCanvas(
          "${productName}",
          t("table_table_product.product_name")
        );
        break;
      case t("editor.price_pule"):
        addElementToCanvas("${priceRule}", t("editor.price_pule"));
        break;
      case t("editor.product_price"):
        addElementToCanvas("${productPrice}", t("editor.product_price"));
        break;
      case t("editor.quantity"):
        addElementToCanvas("${quantity}", t("editor.quantity"));
        break;
      case t("editor.category1"):
        addElementToCanvas("${category1}", t("editor.category1"));
        break;
      case t("editor.category2"):
        addElementToCanvas("${category2}", t("editor.category2"));
        break;
      case t("editor.currency"):
        addElementToCanvas("${currency symbol}", t("editor.currency"));
        break;
      case t("editor.origin"):
        addElementToCanvas("${origin}", t("editor.origin"));
        break;
      case t("editor.manufacturer"):
        addElementToCanvas("${manufacturer}", t("editor.manufacturer"));
        break;
      case t("editor.discount_price"):
        addElementToCanvas("${discountPrice}", t("editor.discount_price"));
        break;
      default:
        addElementToCanvas("${" + selectLabel + "}", selectLabel);
    }
  }

  //添加产品标签
  function addElementToCanvas(text, labelName) {
    if (dynamicFontSize < 0) {
      //字体大小不应该是负数
      console.error(`Error: ${labelName} - Negative dynamicFontSize.`);
      enqueueSnackbar(`${labelName} -  FontSize should not be Negative`, {
        variant: "error",
      });
      return;
    }

    const existingLabels = canvas.current
      .getObjects()
      .filter((obj) => obj.type === "i-text" && obj.text === text);

    if (existingLabels.length > 0) {
      //存在相同标签，不允许添加
      console.error(`Error: ${labelName} Already Added.`);
      enqueueSnackbar(` ${labelName}, ${t("tips_editor.already_added")}`, {
        variant: "error",
      });
      return;
    }

    let textBox = new fabric.IText(text, {
      left: 2,
      top: 2,
      fontSize: dynamicFontSize,
      fontStyle: dynamicFontStyle,
      fontFamily: "Helvetica",
      editable: false,
      fill: textColor,
      lockRotation: true,
      lockScalingX: false,
      lockScalingY: false,
      lockScalingFlip: true,
      textAlign: dynamicTextAlign,
    });
    //添加不可编辑产品标签
    canvas.current.add(textBox);
  }

  //添加价格规则标签
  function addPriceRuleToCanvas(e) {
    const hasEqualSizeImage = canvas.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvas.current.width) &&
          Number(obj.height) === Number(canvas.current.height)
      );

    if (hasEqualSizeImage) {
      enqueueSnackbar(t("tips_editor.cannot_add_text"), { variant: "error" });
      return;
    }

    const existingLabels = canvas.current
      .getObjects()
      .filter((obj) => obj.type === "i-text" && obj.text === "${priceRule}");

    if (existingLabels.length > 0) {
      enqueueSnackbar(t("tips_editor.already_added"), { variant: "error" });
      return;
    }
    let textBox = new fabric.IText("${priceRule}", {
      left: 2,
      top: 2,
      fontSize: dynamicFontSize,
      fontStyle: dynamicFontStyle,
      fontFamily: "Helvetica",
      editable: false,
      fill: textColor,
      lockRotation: true,
      lockScalingX: false, // Set lockScalingX property to true
      lockScalingY: false,
      lockScalingFlip: true,
    });
    canvas.current.add(textBox);
  }

  //添加自由文本
  function addTextToCanvas(e) {
    const hasEqualSizeImage = canvas.current
      .getObjects()
      .some(
        (obj) =>
          obj.type === "image" &&
          Number(obj.width) === Number(canvas.current.width) &&
          Number(obj.height) === Number(canvas.current.height)
      );

    if (hasEqualSizeImage) {
      enqueueSnackbar(t("tips_editor.cannot_add_image"), { variant: "error" });
      return;
    }

    let textBox = new fabric.IText(t("tips_editor.text_price"), {
      left: 5,
      top: 5,
      fontSize: dynamicFontSize,
      fontStyle: dynamicFontStyle,
      fontFamily: "Helvetica",
      fill: textColor,
      lockRotation: true,
      lockScalingX: false, // Set lockScalingX property to true
      lockScalingY: false,
      lockScalingFlip: true,
    });
    canvas.current.add(textBox);
  }

  //拖动之后禁止元素超出
  const movingFn = (rect) => {
    let obj = rect;
    // 获取画布边界
    let canvasWidth = canvas.current.width;
    let canvasHeight = canvas.current.height;

    // 计算对象的边界框
    let objLeft = obj.left;
    let objTop = obj.top;

    //计算元素实际宽高
    let objWidth = obj.width * obj.scaleX;
    let objHeight = obj.height * obj.scaleY;

    //计算元素右边位置和底部位置
    let objRight = objLeft + objWidth;
    let objBottom = objTop + objHeight; // 检查对象是否移出了画布边界

    let isOutside = false;

    //元素宽度超出画布宽度
    if (objWidth > canvasWidth) {
      obj.set({ width: canvasWidth });
      isOutside = true;
    }

    //元素高度超出画布宽度
    if (objHeight > canvasHeight) {
      obj.set({ height: canvasHeight });
      isOutside = true;
    }

    // 检查左边是否超出边界
    if (objLeft < 0) {
      obj.set({ left: 0 });
      isOutside = true;
    }

    // 检查上边是否超出边界
    if (objTop < 0) {
      obj.set({ top: 0 });
      isOutside = true;
    }

    // 检查右边是否超出边界
    if (objRight > canvasWidth) {
      obj.set({ left: canvasWidth - objWidth });
      isOutside = true;
    }
    // 检查底边是否超出边界
    if (objBottom > canvasHeight) {
      obj.set({ top: canvasHeight - objHeight });
      isOutside = true;
    }

    // 如果对象移出了边界，阻止对象移动
    if (isOutside) {
      canvas.current.renderAll();
    }
  };

  /*  const showColorPicker = () => {
     setColorPickerVisible(!colorPickerVisible);
   };

   const changeCanvasColor = () => {
     const selectedObjects = canvas.current.getActiveObjects();
     if (selectedObjects.length > 0) {
       selectedObjects.forEach((object) => {
         // Check if the object is a rectangle or text
         if (object instanceof fabric.Rect || object instanceof fabric.IText) {
           object.set("backgroundColor", selectedColor);
         }
       });
       // Render the canvas to apply the changes
       canvas.current.renderAll();
       setColorPickerVisible(!colorPickerVisible);
     }
   }; */

  const changeActiveHeight = (e) => {
    let tempHeight = parseInt(e.target.value);
    if (tempHeight > canvasHeight) {
      tempHeight = canvasHeight;
      enqueueSnackbar(t("tips_editor.dimensions_height"), {
        variant: "error",
      });
    }
    setActiveHeight(tempHeight);
    let active = canvas.current.getActiveObject();
    if (active) {
      let type = active.type;
      if (type === "image") {
        let scaleY = active.scaleY;
        active.set({
          height: tempHeight * scaleY,
          scaleY: scaleY,
        });
        canvas.current.renderAll();
      } else {
        let fontSize = active.fontSize;
        if (fontSize) {
          fontSize = parseInt(fontSize);
          if (tempHeight < fontSize) {
            tempHeight = fontSize;
            setActiveHeight(tempHeight);
          }
        }
        active.set("height", tempHeight);
        canvas.current.renderAll();
      }
      movingFn(active);
    }
  };

  const changeActiveWidth = (e) => {
    let tempWidth = parseInt(e.target.value);
    if (tempWidth > canvasWidth) {
      tempWidth = canvasWidth;
      enqueueSnackbar(t("tips_tips_editor.dimensions_width"), {
        variant: "error",
      });
    }
    setActiveWidth(tempWidth);

    let active = canvas.current.getActiveObject();
    if (active) {
      if (type === "image") {
        let scaleX = active.scaleX;
        active.set({
          width: tempWidth * scaleX,
          scaleX: scaleX,
        });
        canvas.current.renderAll();
      } else {
        active.set("width", tempWidth);
        canvas.current.renderAll();
      }
      movingFn(active);
    }
  };

  const changeTextAlign = (alignment) => {
    let active = canvas.current.getActiveObject();
    if (active && active.type === "i-text") {
      const originalWidth = active.width;
      const originalHeight = active.height;
      active.set({
        textAlign: alignment,
      });
      active.set({
        width: originalWidth,
        height: originalHeight,
      });
      canvas.current.renderAll();
      movingFn(active);
    }
  };

  //初始化画布
  const initCanvas = () => {
    let canvasObj = new fabric.Canvas("canvas", {
      height: canvasHeight,
      width: canvasWidth,
      backgroundColor: canvasColor,
      selection: false,
      renderOnAddRemove: true,
    });

    canvasObj
      .on("selection:updated", (e) => {
        let active = canvasObj.getActiveObject();
        setActiveElement(active);
        if (active) {
          let type = active.type;
          if (type === "image") {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            let canvasWidth = canvasObj.width;
            let canvasHeight = canvasObj.height;

            if (resultWidth > canvasWidth) {
              scaleX = canvasWidth / active.width;
            }

            if (resultHeight > canvasHeight) {
              scaleY = canvasHeight / active.height;
            }

            active.set({
              scaleX: scaleX,
              scaleY: scaleY,
            });

            canvas.current.renderAll();
            let activeNew = canvasObj.getActiveObject();
            setActiveElement(activeNew);
          } else {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            let type = active.type;
            if (type === "i-text") {
              setDynamicFontSize(parseInt(active.fontSize));
              setDynamicFontStyle(active.fontStyle);
            }
            setActiveHeight(resultHeight);
            setActiveWidth(resultWidth);
            active.set({
              width: resultWidth,
              height: resultHeight,
              scaleX: 1,
              scaleY: 1,
            });
            canvas.current.renderAll();
            let activeNew = canvasObj.getActiveObject();
            setActiveElement(activeNew);
          }
        }
      })
      .on("mouse:up", (e) => {
        let active = canvasObj.getActiveObject();
        setActiveElement(active);
        if (active) {
          let type = active.type;
          if (type === "image") {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            let canvasWidth = canvasObj.width;
            let canvasHeight = canvasObj.height;
            if (resultWidth > canvasWidth) {
              scaleX = canvasWidth / active.width;
            }
            if (resultHeight > canvasHeight) {
              scaleY = canvasHeight / active.height;
            }
            active.set({
              scaleX: scaleX,
              scaleY: scaleY,
            });
            canvas.current.renderAll();
            let activeNew = canvasObj.getActiveObject();
            setActiveElement(activeNew);
            movingFn(activeNew);
          } else {
            let scaleX = active.scaleX;
            let scaleY = active.scaleY;
            let resultWidth = parseInt(active.width * scaleX);
            let resultHeight = parseInt(active.height * scaleY);
            if (type === "i-text") {
              setDynamicFontSize(parseInt(active.fontSize));
              setDynamicFontStyle(active.fontStyle);
            }
            setActiveHeight(resultHeight);
            setActiveWidth(resultWidth);
            active.set({
              width: resultWidth,
              height: resultHeight,
              scaleX: 1,
              scaleY: 1,
            });
            canvas.current.renderAll();
            let activeNew = canvasObj.getActiveObject();
            setActiveElement(activeNew);
            movingFn(activeNew);
          }
        }
      });

    return canvasObj;
  };

  function submitURL(e) {
    const reader = new FileReader();
    // Set up a callback function for when the file reading is completed
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;

      // Set your desired height
      image.onload = function () {
        // Now you can access the image's width and height
        const base64Image = event.target.result; // The base64 representation of the image

        /*  if (image.width > canvas.current.width || image.height > canvas.current.height) {
           enqueueSnackbar(t("tips_editor.adjusted_exceeds"), { variant: "error" }); // Display an error message
           return;
         } */

        let scale = 1;
        const canvasWidth = canvas.current.width;
        const canvasHeight = canvas.current.height;
        const imageWidth = image.width;
        const imageHeight = image.height;

        if (imageWidth > canvasWidth || imageHeight > canvasHeight) {
          let widthScal = imageWidth / canvasWidth;
          let heightScal = imageHeight / canvasHeight;
          //宽度超出比例多
          if (widthScal > heightScal) {
            scale = canvasWidth / imageWidth;
          } else {
            scale = canvasHeight / imageHeight;
          }
        }

        const hasEqualSizeImage = canvas.current
          .getObjects()
          .some(
            (obj) =>
              obj.type === "image" &&
              Number(obj.width) === Number(canvas.current.width) &&
              Number(obj.height) === Number(canvas.current.height)
          );

        //已经存在图片，并且占满则不允许添加
        if (hasEqualSizeImage) {
          enqueueSnackbar(t("tips_editor.cannot_add_shape"), {
            variant: "error",
          });
          return;
        }

        //如果存在元素，则占满的图片也不允许添加
        if (
          Number(image.width) === Number(canvas.current.width) &&
          Number(image.height) === Number(canvas.current.height)
        ) {
          // Check if the canvas already contains any other objects
          if (canvas.current.getObjects().length > 0) {
            // Trigger an error or handle the condition as needed
            enqueueSnackbar(t("tips_editor.image_equal"), { variant: "error" });
            return;
          }
        }
        // Now you can use base64Image as needed, e.g., add it to the canvas or do something else
        //添加图片
        fabric.Image.fromURL(base64Image, function (img) {
          //var oImg = img.set({ left: 2, top: 2 }).scale(0.9);
          var oImg = img.set({
            left: 2,
            top: 2,
            scaleX: scale,
            scaleY: scale,
            width: image.width,
            height: image.height,
            lockRotation: true,
            lockScalingX: false,
            lockScalingY: false,
          });
          canvas.current.add(oImg).renderAll();
        });
      };
    };

    // Read the image file from the input field
    const inputElement = document.getElementById("input");
    const file = inputElement.files[0];
    if (file) {
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
    setPayload({
      ...payload,
      name: name,
      screenOriantation: screenOrientation,
      screenResolution: screenResolution,
      templatePhoto: name,
      fileExtension: "",
      size: "",
      model: model,
      type: type,
    });
  }

  const updatePayload = () => {
    setPayload({
      ...payload,
      name: name,
      screenOriantation: screenOrientation,
      screenResolution: screenResolution,
      templatePhoto: "",
      fileExtension: "",
      size: "",
      model: model,
      type: type,
    });
  };

  //将画布转成html
  function generateHTMLWithCanvasData(width, height) {
    //将画布对象转成json
    const canvasData = captureCanvasAsJson();
    const parsedData = JSON.parse(canvasData);
    let errorLabels = [];
    // Function to generate styles for the container
    const generateContainerStyle = (objectData) => `
        position: absolute;
        left: ${objectData.left}px;
        top: ${objectData.top}px;
        width: ${objectData.width}px;
        height: ${objectData.height}px;
        overflow: hidden;
    `;

    const htmlContent = parsedData.map((objectData, index) => {
      const containerStyle = generateContainerStyle(objectData);

      const aspectRatio = objectData.width / objectData.height;
      let adjustedWidth = objectData.width;
      let adjustedHeight = objectData.height;

      // Adjust width and height if they exceed container dimensions
      if (adjustedWidth > width || adjustedHeight > height) {
        if (adjustedWidth / width > adjustedHeight / height) {
          adjustedWidth = width;
          adjustedHeight = width / aspectRatio;
        } else {
          adjustedHeight = height;
          adjustedWidth = height * aspectRatio;
        }
      }

      let elementHtml = "";
      if (objectData.type === "i-text") {
        if (objectData.scaleX > 1 || objectData.scaleY > 1) {
          errorLabels.push(objectData.text);
        }
        let fontSize = objectData.fontSize;
        let fontStyle = objectData.fontStyle;
        let textAlign = objectData.textAlign;
        elementHtml = `<div style="overflow: hidden;${containerStyle}; text-align: ${textAlign}; font-size: ${fontSize}px; font-family: ${objectData.fontFamily}; color: ${objectData.fill}; font-style: ${fontStyle}; font-weight: ${fontStyle}; width: ${adjustedWidth}px; height: ${adjustedHeight}px;">${objectData.text}</div>`;
      } else if (objectData.type === "rect") {
        elementHtml = `<div style=" overflow: hidden;${containerStyle}; background-color: ${objectData.fill}; border: ${objectData.strokeWidth}px solid ${objectData.stroke}; width: ${adjustedWidth}px; height: ${adjustedHeight}px;"></div>`;
      } else if (objectData.type === "circle") {
        elementHtml = `<div style="overflow: hidden; ${containerStyle}; background-color: ${objectData.fill}; border-radius: 50%; border: ${objectData.strokeWidth}px solid ${objectData.stroke}; width: ${adjustedWidth}px; height: ${adjustedHeight}px;"></div>`;
      } else if (objectData.type === "image") {
        let imageWidth = objectData.width * objectData.scaleX;
        let imageHeight = objectData.height * objectData.scaleY;
        elementHtml = `<img src="${objectData.src}" style="overflow: hidden; ${containerStyle}; max-width: 100%; max-height: 100%; width: ${imageWidth}px; height: ${imageHeight}px;"/>`;
      }
      return elementHtml;
    });

    if (errorLabels.length > 0) {
      enqueueSnackbar(
        `Given Texts are wrong format: ${errorLabels.join(", ")}.`,
        { variant: "error" }
      );
      return;
    }

    return `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Exported HTML with Canvas</title>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        height: 100vh;
                    }
                </style>
            </head>
            <body>
                <div style="overflow: hidden;position: relative; width: ${width}px; height: ${height}px;">
                    ${htmlContent.join("")}
                </div>
            </body>
        </html>
    `;
  }

  //将画布对象转成json 数据
  function captureCanvasAsJson() {
    const objects = canvas.current.getObjects();
    const objectsData = objects.map((object) => object.toObject());
    return JSON.stringify(objectsData);
  }
  const [updateTemplatePayload, setUpdateTemplatePayload] = useState({
    id: "",
    imageDownloadUrl: "",
  });

  //提交
  async function convertToImg(width, height) {
    //没有元素不允许提交
    if (canvas.current.getObjects().length === 0) {
      enqueueSnackbar(t("tips_editor.canvas_is_empty"), { variant: "error" });
      return;
    }
    setDisableSubmit(true);
    const htmlContent = generateHTMLWithCanvasData(width, height);
    const file = new File([htmlContent], location.state.name, {
      type: "text/html",
    });

    if (htmlContent === null) {
      return;
    }
    localStorage.setItem("elementInnerHTML", htmlContent);
    try {
      const res = await createTemplate(payload);
      if (res?.data?.code === "LVLI0008" && res?.data?.data) {
        enqueueSnackbar(t("tips_editor.height_than"), {
          variant: "success",
        });
        const elementHTML = localStorage.getItem("elementInnerHTML");
        if (elementHTML) {
          const element = document.createElement("div");
          element.style.display = "hidden";
          element.innerHTML = elementHTML;
          document.body.appendChild(element);
          //let file = null;

          const borderSize = 2;
          const extraHeight = 6;
          const canvas = await html2canvas(element, {
            width: screenResolution.split("*")[0],
            height: screenResolution.split("*")[1],
          });

          element.style.visibility = "hidden";
          document.body.removeChild(element);

          const borderedCanvas = document.createElement("canvas");
          const ctx = borderedCanvas.getContext("2d");
          borderedCanvas.width = screenResolution.split("*")[0];
          borderedCanvas.height = screenResolution.split("*")[1];
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);
          ctx.drawImage(canvas, borderSize, borderSize);
          ctx.strokeStyle = "#000";
          ctx.lineWidth = borderSize;

          ctx.strokeRect(
            borderSize,
            borderSize,
            element.clientWidth,
            element.clientHeight
          );

          const dataUrl = borderedCanvas.toDataURL("image/png");
          const byteString = atob(dataUrl.split(",")[1]);
          const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const uintArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
          }

          const blob = new Blob([arrayBuffer], { type: mimeString });
          const file1 = new File(
            [blob],
            localStorage.getItem("productName") + ".png",
            {
              type: "image/png",
            }
          );

          if (!CommonUtil.isEmpty(res.data.data.templateUploadUrl)) {
            var bodyFormData = new FormData();
            bodyFormData.append("url", res?.data?.data?.templateUploadUrl);
            bodyFormData.append("file", file);
            bodyFormData.append("file1", file1);
            bodyFormData.append(
              "objectKey",
              res?.data?.data?.templateDetails?.templateObjectKey
            );
            bodyFormData.append("id", res?.data?.data?.templateDetails?.id);
            bodyFormData.append(
              "screenResolution",
              res?.data?.data?.templateDetails?.screenResolution
            );
            bodyFormData.append(
              "templateName",
              res?.data?.data?.templateDetails?.name
            );

            await axios({
              method: "post",
              url:
                process.env.REACT_APP_SERVER_URL +
                "/web/template/uploadTemplate",
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("USER_TOKEN"),
              },
            });
          }
          localStorage.removeItem("screenResolution");
          localStorage.removeItem("name");
          localStorage.removeItem("screenOrientation");
          localStorage.removeItem("model");
          localStorage.removeItem("type");
          localStorage.removeItem("elementInnerHTML");
          navigate(REACT_TEMPLATE_LIST);
          return;
        }
      }
      setDisableSubmit(false);

      if (res?.data?.code === "LVLE0000") {
        enqueueSnackbar(t("tips_editor.provide_image"), {
          variant: "error",
        });
        return;
      }
      if (res?.data?.code === "EPTE0031") {
        enqueueSnackbar(t("tips_Template.template_exist"), {
          variant: "error",
        });
        return;
      }
      if (res?.data?.code === "EPTE0032") {
        enqueueSnackbar(t("tips_Template.characters"), {
          variant: "error",
        });
        return;
      }
    } catch (error) {
      setDisableSubmit(false);
      console.error("Error:", error);
    }
  }

  /*  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
  }; */

  const handleShapeChange = (e) => {
    setSelectedShape(e.target.value);
  };

  //删除画布元素
  function deleteElement(e) {
    canvas.current.remove(canvas.current.getActiveObject());
  }

  const [selectedButton, setSelectedButton] = useState("red");

  const handleButtonClick = (color) => {
    setTextColor(color);
  };

  const [selectedLabel, setSelectedLabel] = useState(null);

  //设置选择标签
  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    setSelectLabel(label);
  };

  const changeFontStyle = (e) => {
    setDynamicFontStyle(e.target.value);
    if (activeElement) {
      let active = canvas.current.getActiveObject();
      let type = active.type;
      if (type === "image") {
      } else {
        let scaleX = active.scaleX;
        let scaleY = active.scaleY;
        let resultWidth = parseInt(active.width * scaleX);
        let resultHeight = parseInt(active.height * scaleY);
        setActiveHeight(resultHeight);
        setActiveWidth(resultWidth);
        let type = active.type;
        if (type === "i-text") {
          active.set("fontStyle", e.target.value);
          canvas.current.renderAll();
        }
        active.set({
          width: resultWidth,
          height: resultHeight,
          scaleX: 1,
          scaleY: 1,
        });
        canvas.current.renderAll();
      }
      movingFn(active);
    }
  };

  const updateTextBoxDimensions = (active, fontSize) => {
    const tempText = new fabric.Text(active.text, {
      fontSize: fontSize,
      fontFamily: active.fontFamily,
      fontWeight: active.fontWeight,
      fontStyle: active.fontStyle,
    });

    const textWidth = tempText.width;
    const textHeight = tempText.height;

    active.set({
      width: textWidth,
      height: textHeight,
    });

    canvas.current.renderAll();
  };

  const adjustTextSize = (active, fontSize) => {
    let textWidth, textHeight;
    let canvasWidth = canvas.current.width;
    let canvasHeight = canvas.current.height;

    // Create a temporary fabric.Text object to measure the text dimensions
    const tempText = new fabric.Text(active.text, {
      fontSize: fontSize,
      fontFamily: active.fontFamily,
      fontWeight: active.fontWeight,
      fontStyle: active.fontStyle,
    });

    textWidth = tempText.width;
    textHeight = tempText.height;

    if (textWidth <= canvasWidth && textHeight <= canvasHeight) {
      active.set({
        fontSize: fontSize,
        width: textWidth,
        height: textHeight,
      });
    } else {
      // If the new size exceeds the canvas size, revert to the previous size
      let prevFontSize = active.fontSize - 1;
      active.set({
        fontSize: prevFontSize,
      });
      enqueueSnackbar(t("Font size exceeds canvas dimensions"), {
        variant: "error",
      });
      setDynamicFontSize(prevFontSize);
    }

    active.set({
      width: active.width,
      height: active.height,
    });
    canvas.current.renderAll();
  };

  const changeFontSize = (e) => {
    let fontSize = parseInt(e.target.value);
    if (fontSize < 12) {
      fontSize = 12;
    }
    let active = canvas.current.getActiveObject();
    if (
      active &&
      (active.type === "textBox" ||
        active.type === "i-text" ||
        active.type === "text")
    ) {
      adjustTextSize(active, fontSize);
    }
    setDynamicFontSize(fontSize);
  };

  const blueFontSize = (e) => {
    let fontSize = parseInt(e.target.value);
    if (fontSize < 12) {
      fontSize = 12;
    }
    let active = canvas.current.getActiveObject();
    if (
      active &&
      (active.type === "textBox" ||
        active.type === "i-text" ||
        active.type === "text")
    ) {
      adjustTextSize(active, fontSize);
    }
    setDynamicFontSize(fontSize);
  };

  const labelHeight = 32; // Estimated height of a single label (including margin and padding)
  const maxVisibleLabels = 7;
  const maxHeight = labelHeight * maxVisibleLabels;

  return (
    <RightViewLayout
      id="tempeditorback"
      title={t("EPLTEM0005")}
      navigateBack={REACT_TEMPLATE_ADD}
    >
      <Grid container spacing={3} xs={12}>
        <Grid xs={2}>
          <Box
            item
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              padding: "5px",
              margin: "5px",
              fontFamily: "sans-serif",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              Container
              style={{ fontFamily: "sans-serif", justifyContent: "center" }}
            >
              {activeElement && activeElement.type !== "image" && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    marginTop: "20px",
                  }}
                >
                  <Tooltip title="Align Left">
                    <IconButton
                      onClick={() => changeTextAlign("left")}
                      style={{
                        padding: "3px",
                        margin: "3px",
                        cursor: "pointer",
                        fontFamily: "sans-serif",
                      }}
                      aria-label="left align"
                    >
                      <FormatAlignLeftIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Align Center">
                    <IconButton
                      onClick={() => changeTextAlign("center")}
                      style={{
                        padding: "3px",
                        margin: "3px",
                        cursor: "pointer",
                        fontFamily: "sans-serif",
                      }}
                      aria-label="center align"
                    >
                      <FormatAlignCenterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Align Right">
                    <IconButton
                      onClick={() => changeTextAlign("right")}
                      style={{
                        padding: "3px",
                        margin: "3px",
                        cursor: "pointer",
                        fontFamily: "sans-serif",
                      }}
                      aria-label="right align"
                    >
                      <FormatAlignRightIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box>
                <Typography
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  {t("editor.product_label")}
                </Typography>
                <div
                  className="custom-scrollbar"
                  style={{
                    display: "block",
                    textAlign: "left",
                    padding: "3px",
                    margin: "3px",
                    fontFamily: "sans-serif",
                    maxHeight: `${maxHeight}px`, // Set the maximum height
                    overflowY: "auto", // Enable vertical scrolling
                  }}
                >
                  {labels.map((label) => (
                    <div
                      key={label}
                      onClick={() => handleLabelClick(label)}
                      style={{
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginBottom: "5px", // Add some space between labels
                        backgroundColor:
                          selectedLabel === label ? "#E0F7FA" : "transparent", // Change background color for selected label
                      }}
                    >
                      {label}
                    </div>
                  ))}
                </div>
              </Box>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="tempeditoraddlabel"
                  variant="contained"
                  color="default"
                  size="medium"
                  className="text-transform-none"
                  onClick={addLabelToCanvas}
                >
                  {t("editor.add_label")}
                </Button>
              </Box>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="addPriceRule"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className="text-transform-none"
                  style={{
                    backgroundColor: "#00a5ff",
                    padding: "6px",
                    margin: "3px",
                    justifyContent: "center",
                  }}
                  onClick={addPriceRuleToCanvas}
                  component="span"
                >
                  {t("editor.change_rule")}
                </Button>
              </Box>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="addText"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className="text-transform-none"
                  style={{
                    backgroundColor: "#00a5ff",
                    padding: "6px",
                    margin: "3px",
                    justifyContent: "center",
                  }}
                  onClick={addTextToCanvas}
                  component="span"
                >
                  {t("editor.free_text")}
                </Button>
              </Box>

              <Box
                style={{
                  display: "flex",
                  alignItems: "left",
                  fontFamily: "sans-serif",
                  marginTop: "20px",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("editor.font_style")}{" "}
                </Typography>
                <select
                  id="TemplateEditorFontStyle"
                  value={dynamicFontStyle}
                  onChange={(e) => {
                    changeFontStyle(e);
                  }}
                  style={{
                    padding: "3px",
                    margin: "3px",
                    cursor: "pointer",
                    fontFamily: "sans-serif",
                  }}
                >
                  <option
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                    }}
                    value="bold"
                  >
                    Bold
                  </option>
                  <option
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                    }}
                    value="normal"
                  >
                    Normal
                  </option>
                  <option
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                    }}
                    value="italic"
                  >
                    Italic
                  </option>
                </select>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("editor.font_size")}
                </Typography>
                <input
                  type="number"
                  value={dynamicFontSize}
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    width: "75px",
                  }}
                  onBlur={(e) => {
                    blueFontSize(e);
                  }}
                  onChange={(e) => {
                    changeFontSize(e);
                  }}
                />
              </Box>
              {activeElement && activeElement.type !== "image" && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                  }}
                >
                  <Typography
                    style={{
                      padding: "2px",
                      margin: "2px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {t("editor.width")}
                  </Typography>
                  <input
                    type="number"
                    value={activeWidth}
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                      width: "75px",
                    }}
                    onChange={(e) => {
                      changeActiveWidth(e);
                    }}
                  />
                </Box>
              )}
              {activeElement && activeElement.type !== "image" && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                  }}
                >
                  <Typography
                    style={{
                      padding: "2px",
                      margin: "2px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {t("editor.height")}
                  </Typography>
                  <input
                    type="number"
                    value={activeHeight}
                    style={{
                      padding: "5px",
                      margin: "5px",
                      fontFamily: "sans-serif",
                      width: "75px",
                    }}
                    onChange={(e) => {
                      changeActiveHeight(e);
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid
          xs={8}
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <canvas id="canvas" style={{ border: "1px solid black" }} />
        </Grid>

        <Grid xs={2}>
          <Box
            item
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              padding: "5px",
              margin: "5px",
              fontFamily: "sans-serif",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              Container
              style={{
                padding: "3px",
                margin: "3px",
                justifyContent: "left",
                fontFamily: "sans-serif",
              }}
            >
              <Typography
                style={{
                  padding: "2px",
                  margin: "2px",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {t("common.color")}
              </Typography>
              <Box
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              >
                <button
                  style={{
                    padding: "20px",
                    marginRight: "5px",
                    backgroundColor: selectedButton === "red" ? "red" : "red",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick("red")}
                />
                <button
                  style={{
                    padding: "20px",
                    marginRight: "5px",
                    backgroundColor:
                      selectedButton === "black" ? "black" : "black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick("black")}
                />
                <button
                  style={{
                    padding: "20px",
                    marginRight: "5px",
                    backgroundColor:
                      selectedButton === "white" ? "white" : "white",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick("white")}
                />
              </Box>
            </Box>

            <Box
              Container
              style={{
                justifyContent: "end",
                padding: "3px",
                margin: "3px",
                fontFamily: "sans-serif",
              }}
            >
              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("editor.height")}
                </Typography>
                <input
                  type="number"
                  value={boxHeight}
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    width: "75px",
                  }}
                  onChange={(e) => setBoxHeight(parseInt(e.target.value))}
                />
              </Box>

              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("editor.width")}
                </Typography>
                <input
                  type="number"
                  value={boxWidth}
                  style={{
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "sans-serif",
                    width: "75px",
                  }}
                  onChange={(e) => setBoxWidth(parseInt(e.target.value))}
                />
              </Box>

              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <Typography
                  style={{
                    padding: "2px",
                    margin: "2px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {t("common.shape")}{" "}
                </Typography>
                <select
                  id="templateEditorAddShape"
                  onChange={handleShapeChange}
                  value={selectedShape}
                  style={{
                    padding: "3px",
                    margin: "3px",
                    cursor: "pointer",
                    fontFamily: "sans-serif",
                  }}
                >
                  <option value="square">Square</option>
                  <option value="circle">Circle</option>
                </select>
              </Box>

              <Box
                item
                style={{
                  justifyContent: "end",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <Button
                  id="tempeditoraddshape"
                  variant="contained"
                  color="default"
                  size="medium"
                  className="text-transform-none"
                  onClick={addBoxToCanvas}
                >
                  {" "}
                  {t("editor.add_shape")}{" "}
                </Button>
              </Box>
            </Box>

            <Box
              style={{
                display: "block",
                textAlign: "end",
                padding: "3px",
                margin: "3px",
                fontFamily: "sans-serif",
              }}
            >
              <Typography
                style={{
                  padding: "5px",
                  margin: "5px",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {t("editor.company_logo")}{" "}
              </Typography>
              <input
                style={{
                  padding: "3px",
                  margin: "15px",
                  border: "0.5px solid #000",
                  maxWidth: "135px",
                }}
                id="input"
                type="file"
                accept="image/*"
              />
              <Button
                id="tempeditoraddimage"
                variant="contained"
                color="primary"
                size="medium"
                className="text-transform-none"
                style={{
                  backgroundColor: "#00a5ff",
                  padding: "3px",
                  margin: "3px",
                  width: "100px",
                  justifyContent: "center",
                }}
                onClick={submitURL}
                component="span"
              >
                {t("common.upload")}
              </Button>
            </Box>

            <Box
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
              }}
            >
              <Button
                id="tempeditordelete"
                variant="contained"
                color="secondary"
                size="medium"
                className="text-transform-none"
                onClick={deleteElement}
                style={{
                  padding: "5px",
                  margin: "5px",
                  width: "150px",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                {t("editor.delete_element")}
              </Button>
            </Box>
          </Box>

          <Box
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              id="tempeditornext"
              variant="contained"
              color="primary"
              size="medium"
              className="text-transform-none"
              disabled={disableSubmit}
              onClick={() => convertToImg(canvasWidth, canvasHeight)}
            >
              {" "}
              {t("LVLOT0016")}{" "}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
