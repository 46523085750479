import { Avatar, Box, Paper } from "@mui/material";
import OverFlowText from "../../components/OverFlowText";
import { useState } from "react";
import { useEffect } from "react";
import { getPersonByIdOrCode } from "../../services/PersonService";
import { useLocation, useParams } from "react-router-dom";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PERSON_LIST, RETAIL_CLIENT } from "../../router/ReactEndPoints";
import { useTranslation } from "react-i18next";

export default function ViewPerson() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [person, setPerson] = useState({});
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    getPersonByIdOrCode(params.data).then((res) => {
      if (res?.data?.data && res?.data?.code === "UASI0033") {
        setPerson(res.data.data);
        setProfile(res?.data?.data?.imagePreSignedURL);
      }
    });
  }, []);
  return (
    <RightViewLayout
      id="viewpersonback"
      title={t("user.view_person")}
      navigateBack={
        window.location.pathname.includes("/retail-client")
          ? RETAIL_CLIENT +
            params.id +
            "/" +
            location.state.code +
            REACT_PERSON_LIST
          : "/principal/" +
            params.id +
            "/" +
            location.state.code +
            REACT_PERSON_LIST
      }
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          paddingTop: "20px",
          paddingBottom: "36px",
          paddingLeft: "26px",
          border: "1px solid #DDDDDD",
          margin: "0 1rem 2rem 0",
          overflow: "hidden",
        }}
      >
        <Box>
          <Box>
            <Avatar
              imgProps={{ draggable: "false" }}
              alt="Organisation profile pic"
              src={profile}
              sx={{ width: 150, height: 150 }}
            />
          </Box>
          <Box pt={2}>
            <Box>
              <OverFlowText variant="fieldLabel">
                {t("table.first_name")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.firstName ? person.firstName : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("table.last_name")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.lastName ? person.lastName : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("table.email")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.email ? person.email : "-"}
            </OverFlowText>
          </Box>
        </Box>
      </Paper>
    </RightViewLayout>
  );
}
