import React, { useEffect, useState } from "react";
import { getScheduleConfig } from "../js/config";
import { useTranslation } from "react-i18next";
import Accourdion from "@c/Accordion";
import CmpFormik from "@z/CmpFormik";
import { Grid, Radio, FormControlLabel } from "@mui/material";
import SetRepeatCondition from "./SetRepeatCondition";
function Schedule(props) {
  const {
    addFormik,
    scheduleConfig,
    setScheduleConfig,
    expandedIndex,
    handleChange,
    handleConfirm,
    handlerCancel,
  } = props;
  const { t } = useTranslation();
  const [does, setDoes] = useState(false);

  useEffect(() => {
    let configInfo = getScheduleConfig(t, does);
    setScheduleConfig(configInfo);
  }, [does]);

  useEffect(() => {
    setDoes(addFormik.values.endOrNot);
  }, [addFormik.values.endOrNot]);

  return (
    <Accourdion
      elevation={0}
      expanded={expandedIndex === 5}
      label={t("events.schedule")}
      onChange={handleChange(5, ["scheduleMode", "startTime", "timeDay"])}
      handlerConfirm={handleConfirm(5, [
        "scheduleMode",
        "startTime",
        "timeDay",
      ])}
      expandedIndex={expandedIndex}
      handlerCancel={handlerCancel(4, ["scheduleMode", "startTime", "timeDay"])}
    >
      <CmpFormik sx={4} formik={addFormik} formConfig={scheduleConfig}>
        <Grid mt={4} xs={12} position={"relative"}>
          {addFormik.values["scheduleMode"] &&
            addFormik.values["scheduleMode"] !== "Does not repeat" && (
              <SetRepeatCondition addFormik={addFormik}></SetRepeatCondition>
            )}
          <Grid
            sx={{
              position: "absolute",
              top: "-70px",
              right: "100px",
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  id={`role-radio-1`}
                  checked={does}
                  value={does}
                  onClick={() => {
                    const newValue = !does; // 切换值
                    setDoes(newValue); // 更新状态
                    addFormik.setFieldValue("endOrNot", newValue); // 更新 Formik 的值
                  }}
                  name="endOrNot"
                />
              }
              label={t("events.doesNotEnd")}
            />
          </Grid>
        </Grid>
      </CmpFormik>
    </Accourdion>
  );
}

export default Schedule;
