import { Grid } from "@mui/material";
import { FormLabel, PrettoSlider } from "../components/PropertiesComponent";
import ColorPick from "../components/ColorPick";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
const ContainerProperties = (props) => {
  const { layoutJSON, setLayoutJSON } = props;
  const { t } = useTranslation();
  const changeProperties = (event) => {
    const name = event.target.name;
    layoutJSON[name] = event.target.value;
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  const handleChangeDisplay = (event, newValue) => {
    layoutJSON.showBorder = newValue;
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };
  const handleChangeshowRule = (event, newValue) => {
    layoutJSON.showRule = newValue;
    setLayoutJSON(JSON.parse(JSON.stringify(layoutJSON)));
  };

  return (
    <Grid
      sx={{
        height: "100%",
        overflow: "auto",
        p: 1.5,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.bgcolor")}</FormLabel>
        <ColorPick
          value={layoutJSON.bgColor}
          name="bgColor"
          onChange={changeProperties}
        ></ColorPick>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.show_border")}</FormLabel>
        <Checkbox
          checked={layoutJSON.showBorder}
          onChange={handleChangeDisplay}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.show_scale")}</FormLabel>
        <Checkbox
          checked={layoutJSON.showRule}
          onChange={handleChangeshowRule}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>

      {/*
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <FormLabel sx={{ mr: 2 }}>{t("editor.opacity")}</FormLabel>
        <PrettoSlider
          onChange={handleSliderChange}
          size="small"
          min={0.1}
          max={1}
          step={0.1}
          color="secondary"
          value={layoutJSON.opacity}
          aria-label="Small"
          valueLabelDisplay="on"
        ></PrettoSlider>
      </Grid> */}
    </Grid>
  );
};

export default ContainerProperties;
