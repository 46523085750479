import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PRODUCT } from "../../router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  attributeList,
  createProduct,
  getCurrentData,
} from "../../services/ProductService";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import axios from "axios";
import DropzoneComponent from "../../components/layout-components/DropzoneComponent";
import { ReactComponent as Upload_Image } from "../../assets/images/Upload_Image.svg";

import { ReactComponent as ScanSvg } from "../../assets/images/Scan.svg";
export default function AddProduct() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const saleStatuOptions = ["On Sale", "Sold Out", "Discontinued"];
  // const priceUnitOptions = ["INR", "USD", "EU", "RMB", "THB"];
  const [priceUnitOptions, setPriceUnitOptions] = useState([]);
  const [image, setImage] = useState();
  const [productImage, setProductImage] = useState();
  const [payload, setPayload] = useState({
    name: "",
    sku: "",
    outlet: null,
    categoryLevel1: "",
    categoryLevel2: "",
    categoryLevel3: "",
    quantity: "",
    barcode: "",
    productPrice: "",
    discountPrice: "",
    priceUnit: "",
    brandName: "",
    origin: "",
    manufacturer: "",
    productPhoto: "",
    size: "",
    qrcode: "",
  });

  const [error, setError] = useState({
    name: "",
    sku: "",
    outlet: null,
    categoryLevel1: "",
    categoryLevel2: "",
    categoryLevel3: "",
    quantity: "",
    barcode: "",
    productPrice: "",
    discountPrice: "",
    priceUnit: "",
    brandName: "",
    origin: "",
    manufacturer: "",
    size: "",
    qrcode: "",
  });

  const [newLabels, setNewLabels] = useState([]);
  const [customePayload, setCustomePayload] = useState({});

  useEffect(() => {
    attributeList().then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0000") {
        let list = res.data.data.filter((item) => {
          return !(
            item.dataLevel === "System Default" && item.name === "priceRule"
          );
        });
        setNewLabels(list);
      }
    });
  }, []);

  useEffect(() => {
    getCurrentData().then((res) => {
      setPriceUnitOptions(res?.data?.data);
    });
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      name: "",
      outlet: "",
      categoryLevel1: "",
      categoryLevel2: "",
      quantity: "",
      productPrice: "",
      discountPrice: "",
      priceUnit: "",
      barcode: "",
      origin: "",
      manufacturer: "",
      brandName: "",
      qrcode: "",
    });
  };

  useEffect(() => {
    const payloadObject = {};
    newLabels.forEach((label) => {
      payloadObject[label.name] = "";
    });
    setCustomePayload(payloadObject);
  }, [newLabels]);

  const handleCustomChange = (name, value) => {
    setCustomePayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const [file, setFile] = useState({});
  const [state, setState] = useState({
    profilePhoto: "",
  });

  const handleImage = (file) => {
    const maxSize = 3 * 1024 * 1024; // 3MB in bytes
    if (
      file.file.type === "image/png" ||
      file.file.type === "image/jpeg" ||
      file.file.type === "image/bmp"
    ) {
      let fileSize = file.file.size;
      let size = parseInt(fileSize);
      if (size <= maxSize) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(",")[1],
        });
        setImage(file?.base64);
        setProductImage(file?.file);
      } else {
        setFile("");
        enqueueSnackbar(t("tips_product.file_size_limit"), {
          variant: "error",
        });
      }
    } else {
      setFile("");
      //setProfile(state.profilePhoto);
      enqueueSnackbar(t("EPLPR0304"), {
        variant: "error",
      });
    }
    setPayload((prevPayload) => ({
      ...prevPayload,
      productPhoto: file.file.name,
    }));
    // setImage(file?.base64);
    // setProductImage(file?.file);
  };

  const uploadProductPhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append("url", data?.data?.data?.preSignedUrl);
    bodyFormData.append("file", productImage);

    axios({
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "/web/product/image",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("USER_TOKEN"),
      },
    }).then(() => {
      enqueueSnackbar(t("EPLPR0403"), { variant: "success" });
      navigate(REACT_PRODUCT);
    });
  };

  const handleSubmit = () => {
    let request = {
      product: payload,
      object: customePayload,
    };

    createProduct(request).then((res) => {
      if (res?.data?.data && res?.data?.code === "LVLI0008") {
        if (CommonUtil.isEmpty(res.data.data.preSignedUrl) && !productImage) {
          enqueueSnackbar(t("EPLPR0403"), { variant: "success" });
          navigate(REACT_PRODUCT);
        } else {
          uploadProductPhoto(res);
        }
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
      }
    });
  };

  return (
    <RightViewLayout
      title={t("product.add_product")}
      navigateBack={REACT_PRODUCT}
    >
      <Grid sx={{ height: "100%" }}>
        <Card elevation={0} sx={{ height: "100%" }}>
          <Grid container item mt={3}>
            <Grid container px={2} spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgb(71, 75, 79)",
                  }}
                  color="textSecondary"
                  pt={1}
                >
                  {t("product.product_picture")}
                </Typography>
                <DropzoneComponent
                  getExcelFile={(excelData) => handleImage(excelData)}
                >
                  <Box
                    mt={0}
                    p={1}
                    sx={{
                      border: "2px dashed #36C96D",
                      borderRadius: "5px",
                      backgroundColor: "rgba(54, 201, 109,0.1)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "400px",
                    }}
                  >
                    {image ? (
                      <img
                        src={image}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Upload_Image />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            textAlign: "center",
                            opacity: "0.8",
                            mt: 1,
                          }}
                        >
                          {t("tips_product.drag_and_drop_file")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            textAlign: "center",
                            opacity: "0.8",
                          }}
                        >
                          {t("tips_product.upload_image_format")}
                        </Typography>
                      </div>
                    )}
                  </Box>
                </DropzoneComponent>
              </Grid>

              <Grid container xs={12} md={8} item spacing={2}>
                {newLabels.map((field, index) => (
                  <Grid key={index} item md={4} xs={12}>
                    <CustomInput
                      id="AddProduct12"
                      size="small"
                      label={field.label}
                      value={customePayload[field.name]}
                      handleChange={(event) =>
                        handleCustomChange(field.name, event.target.value)
                      }
                      required={field.name == "productName"}
                      name={field.name}
                      inputProps={{
                        maxLength: 50,
                      }}
                      resetError={() => console.log()}
                      fullWidth
                      placeholder={"Enter " + field.label}
                    />
                  </Grid>

                  // <Grid key={index} item md={4} xs={12}>
                  //   <InputLabel shrink htmlFor="bootstrap-input">
                  //     {field.label}
                  //   </InputLabel>
                  //   {field.type === "Enum" ? (
                  //     <Autocomplete
                  //       options={field.options}
                  //       value={customePayload[field.name]}
                  //       onChange={(event, value) =>
                  //         handleCustomChange(field.name, value)
                  //       }
                  //       getOptionLabel={(option) => option}
                  //       renderInput={(params) => <TextField {...params} />}
                  //     />
                  //   ) : (
                  //     <CustomInput
                  //       id="AddProduct12"
                  //       size="small"
                  //       value={customePayload[field.name]}
                  //       handleChange={(event) =>
                  //         handleCustomChange(field.name, event.target.value)
                  //       }
                  //       required={field.name == "productName"}
                  //       name={field.name}
                  //       inputProps={{
                  //         maxLength: 50,
                  //       }}
                  //       resetError={() => console.log()}
                  //       fullWidth
                  //       placeholder={"Enter " + field.label}
                  //     />
                  //   )}
                  // </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2} px={2} py={2}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Box item pl={2}>
                    <Button
                      id="AddProduct-button-01"
                      variant="contained"
                      size="large"
                      className="text-transform-none"
                      onClick={handleSubmit}
                      style={{
                        size: "medium",
                        borderRadius: "8px",
                        opacity: 1,
                        background:
                          "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                      }}
                    >
                      {t("common.save")}
                    </Button>
                  </Box>
                  <Box item>
                    <Button
                      id="AddProduct-button-02"
                      className="text-transform-none"
                      variant="outlined"
                      onClick={() => navigate(REACT_PRODUCT)}
                      size="large"
                    >
                      {t("common.back")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </RightViewLayout>
  );
}
