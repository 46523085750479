import { Route, Routes } from "react-router-dom";
import MainLayout from "../components/layout-components/MainLayout";
import {
  REACT_DASHBOARD,
  REACT_DEVICE_DETAILS,
  REACT_DEVICE_LIST,
  REACT_OUTLET_LIST,
  REACT_OUTLET_VIEW,
  REACT_PERSON_LIST,
  REACT_PERSON_VIEW,
  REACT_PRODUCT,
  REACT_PRODUCT_ADD,
  REACT_PRODUCT_EDIT,
  REACT_PRODUCT_LABEL,
  REACT_PRODUCT_VIEW,
  REACT_RETAIL_CLIENT_LIST,
  REACT_RETAIL_CLIENT_VIEW,
  REACT_VIEW_COMPANY_PROFILE,
  REACT_VIEW_USER_PROFILE,
  RETAIL_CLIENT,
  REACT_PICTURE_LIBRARY,
  REACT_TEMPLATE_LIST,
  REACT_PICTURE_LIBRARY_ADD,
  REACT_PICTURE_LIBRARY_EDIT,
  REACT_PRICE_CHANGE_RULES,
  REACT_ADD_PRICE_CHANGE_RULES,
  REACT_PRICE_CHANGE_EVENT,
  REACT_PRICE_CHANGE_EVENT_ADD,
  REACT_PRICE_CHANGE_RULES_VIEW,
  REACT_PRICE_CHANGE_RULES_EDIT,
  REACT_PRICE_CHANGE_EVENT_VIEW,
  REACT_DEVICE_GATEWAY,
  REACT_DEVICE_SCREENS,
  REACT_APPROVAL_FLOW_CHART_LIST,
  REACT_APPROVAL_FLOW_CHART_ADD,
  REACT_APPROVAL_FLOW_CHART_EDIT,
  REACT_APPROVAL_LIST,
  REACT_APPROVAL_LOG,
  REACT_TEMPLATE_ADD,
  REACT_TEMPLATE_EDIT,
  REACT_OUTLET_ADD,
  REACT_OUTLET_EDIT,
  REACT_DEVICE_ADD_INSTRUCTIONS,
  REACT_DEVICE_ADD,
  REACT_PRINCIPAL_MANAGEMENT,
  REACT_PRINCIPAL_ADD,
  REACT_PRINCIPAL_EDIT,
  REACT_RETAIL_CLIENT_ADD,
  REACT_RETAIL_CLIENT_UPDATE,
  REACT_UPDATE_USER_PROFILE,
  REACT_PERSON_ADD,
  REACT_CREATE_TEMPLATE,
  REACT_PERSON_EDIT,
  REACT_TEMPLATE_VIEW,
  REACT_FORGOT_PASSWORD,
  REACT_RESET_COMPANY_PASSWORD,
  REACT_LANDING,
  REACT_USER,
  REACT_AUTHORIZATION_LEVEL,
  REACT_AUTHORIZATION_LEVEL_ADD,
  REACT_UPDATE_TEMPLATE,
  REACT_AUTHORIZATION_LEVEL_EDIT,
  REACT_AUTHORIZATION_LEVEL_VIEW,
  REACT_USER_ADD,
  REACT_USER_EDIT,
  REACT_USER_VIEW,
  REACT_DEVICE_VIEW,
  REACT_DEVICE_EDIT_SCREENS,
  REACT_PRODUCT_BIND_TO_SCREEN,
  REACT_APPROVAL_LIST_VIEW,
  REACT_THIRD_PARTY_LIST,
  REACT_THIRD_PARTY_ADD,
  REACT_PRICE_CHANGE_EVENT_UPDATE,
  REACT_SCEEN_LOGS,
  REACT_TEMPLATE_EDITOR,
  REACT_SUBSCRIPTION,
  REACT_SUBSCRIPTION_CREATE,
  REACT_CREATE_SUBSCRIPTION_RECORD,
} from "./ReactEndPoints";
import Dashboard from "../pages/dashboard/Index";

import ViewUserProfile from "../pages/Users/ViewUserProfile";
import RetailClient from "../pages/company/RetailClient";
import ViewRetailClient from "../pages/company/ViewRetailClient";
import Device from "../pages/device/Device";
import Outlet from "../pages/outlet/Outlet";
import ViewOutlet from "../pages/outlet/ViewOutlet";

import { ViewCompanyProfile } from "../pages/company/ViewCompanyProfile";
import DeviceDetails from "../pages/device/DeviceDetails";
import Person from "../pages/person/Person";
import ViewPerson from "../pages/person/ViewPerson";
import PictureDetails from "../pages/pictureLibrary/PictureDetails";

import ProductLabel from "../pages/product/ProductLabel";
import Product from "../pages/product/Product";
import AddProduct from "../pages/product/AddProduct";
import ViewProduct from "../pages/product/ViewProduct";
import EditProduct from "../pages/product/EditProduct";
import AddPicture from "../pages/pictureLibrary/AddPicture";
import EditPicture from "../pages/pictureLibrary/EditPicture";

import PriceChangeEvent from "../pages/price/PriceChangeEvent";

import Template from "../pages/template/Template";
import AddPriceEvent from "../pages/price/AddPriceEvent";
import ViewPriceChangeEvent from "../pages/price/ViewPriceChangeEvent";
import Screen from "../pages/deviceManagement/Screen";
import ApprovalFlowChart from "../pages/approval/ApprovalFlowChart";
import EditFlowChart from "../pages/approval/EditApprovalFlowChart";
import AddFlowChart from "../pages/approval/AddApprovalFlowChart";
import ApprovalList from "../pages/approval/ApprovalList";
import AddTemplate from "../pages/template/AddTemplate";
import EditTemplate from "../pages/template/EditTemplate";
import Login from "../pages/onboard/Login";
import AddOutlet from "../pages/outlet/AddOutlet";
import EditOutlet from "../pages/outlet/EditOutlet";
import DeviceInstructionPage from "../pages/device/DeviceInstructionPage";
import BindDevice from "../pages/device/BindDevice";
import AddRetailClient from "../pages/company/AddRetailClient";
import PrincipalManagement from "../pages/principal/PrincipalManagement";
import AddPrincipal from "../pages/principal/AddPrincipal";
import EditPrincipal from "../pages/principal/EditPrincipal";
import UpdateUserProfile from "../pages/Users/UpdateUserProfile";
import UpdateRetailClient from "../pages/company/UpdateRetailClient";
import AddPerson from "../pages/person/AddPerson";
import TemplateEditor from "../pages/template/TemplateEditor";
import EditPerson from "../pages/person/EditPerson";
import ViewTemplate from "../pages/template/ViewTemplate";
import ForgotPassword from "../pages/onboard/ForgotPassword";
import ActivateAccount from "../pages/onboard/ActivateAccount";

import Landing from "../pages/dashboard/LandingPage";
import AuthorizationLevel from "../pages/userManagement/AuthorizationLevel";
import Users from "../pages/userManagement/Users";
import AddAuthorizationLevel from "../pages/userManagement/AddAuthorizationLevel";
import TemplateUpdateEditor from "../pages/template/TemplateUpdateEditor";
import EidtAuthorizationLevel from "../pages/userManagement/EditAuthorizationLevel";
import ViewAuthorizationLevel from "../pages/userManagement/ViewAuthorizationLevel";
import AddUser from "../pages/userManagement/AddUser";
import EditUser from "../pages/userManagement/EditUser";
import ViewUser from "../pages/userManagement/ViewUser";

import ViewDevice from "../pages/device/ViewDevice";
import EditScreen from "../pages/deviceManagement/EditScreen";
import BindDataToScreen from "../pages/product/BindProductToScreen";
import ViewApprovalList from "../pages/approval/ViewApprovalList";
import ApprovalLog from "../pages/approval/ApprovalLog";
import ThirdPartyClient from "../pages/thirdParty/ThirdPartyClient";
import AddThirdPartyClient from "../pages/thirdParty/AddThirdPartyClient";
import ScreenLogs from "../pages/systemRecords/ScreenLogs";

import NewEditor from "../pages/editor";
import PackageSelector from "../pages/subscription/PackageSelector";
import CreateSubscription from "../pages/subscription/CreateSubscription";
import SubscriptionRecords from "../pages/subscription/SubscriptionRecords";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={"/"} element={<Login />} />
      <Route path={REACT_FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={REACT_TEMPLATE_EDITOR} element={<NewEditor />} />
      <Route
        path={REACT_RESET_COMPANY_PASSWORD}
        element={<ActivateAccount />}
      />
      <Route
        path={REACT_DASHBOARD}
        element={
          <MainLayout>
            {" "}
            <Dashboard />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_RETAIL_CLIENT_LIST}
        element={
          <MainLayout>
            {" "}
            <RetailClient />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_RETAIL_CLIENT_ADD}
        element={
          <MainLayout>
            {" "}
            <AddRetailClient />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_RETAIL_CLIENT_UPDATE}
        element={
          <MainLayout>
            {" "}
            <UpdateRetailClient />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_RETAIL_CLIENT_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewRetailClient />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_OUTLET_LIST}
        element={
          <MainLayout>
            {" "}
            <Outlet />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_OUTLET_ADD}
        element={
          <MainLayout>
            {" "}
            <AddOutlet />
            {""}
          </MainLayout>
        }
      />

      <Route
        path={REACT_OUTLET_EDIT}
        element={
          <MainLayout>
            {" "}
            <EditOutlet />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_OUTLET_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewOutlet />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_DEVICE_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewDevice />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_DEVICE_EDIT_SCREENS}
        element={
          <MainLayout>
            {" "}
            <EditScreen />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_DEVICE_LIST}
        element={
          <MainLayout>
            {" "}
            <Device />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_VIEW_USER_PROFILE}
        element={
          <MainLayout>
            {" "}
            <ViewUserProfile />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_UPDATE_USER_PROFILE}
        element={
          <MainLayout>
            {" "}
            <UpdateUserProfile />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_VIEW_COMPANY_PROFILE}
        element={
          <MainLayout>
            {" "}
            <ViewCompanyProfile />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_DEVICE_DETAILS}
        element={
          <MainLayout>
            {" "}
            <DeviceDetails />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_DEVICE_GATEWAY}
        element={
          <MainLayout>
            {" "}
            <Device />
          </MainLayout>
        }
      />

      <Route
        path={REACT_DEVICE_ADD_INSTRUCTIONS}
        element={
          <MainLayout>
            {" "}
            <DeviceInstructionPage />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_DEVICE_ADD}
        element={
          <MainLayout>
            {" "}
            <BindDevice />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_DEVICE_SCREENS}
        element={
          <MainLayout>
            {" "}
            <Screen />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_PRINCIPAL_MANAGEMENT}
        element={
          <MainLayout>
            {" "}
            <PrincipalManagement />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_PRINCIPAL_ADD}
        element={
          <MainLayout>
            {" "}
            <AddPrincipal />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRINCIPAL_EDIT + ":id"}
        element={
          <MainLayout>
            {" "}
            <EditPrincipal />{" "}
          </MainLayout>
        }
      />

      <Route
        path={":name" + "/" + ":id" + "/" + ":code" + REACT_PERSON_LIST}
        element={
          <MainLayout>
            {" "}
            <Person />{" "}
          </MainLayout>
        }
      />

      <Route
        path={RETAIL_CLIENT + ":id" + "/" + ":code" + REACT_PERSON_ADD}
        element={
          <MainLayout>
            {" "}
            <AddPerson />{" "}
          </MainLayout>
        }
      />

      <Route
        path={
          REACT_PRINCIPAL_MANAGEMENT +
          "/" +
          ":id" +
          "/" +
          ":code" +
          REACT_PERSON_ADD
        }
        element={
          <MainLayout>
            {" "}
            <AddPerson />{" "}
          </MainLayout>
        }
      />

      <Route
        path={RETAIL_CLIENT + ":id" + REACT_PERSON_VIEW + ":data"}
        element={
          <MainLayout>
            {" "}
            <ViewPerson />{" "}
          </MainLayout>
        }
      />

      <Route
        path={
          REACT_PRINCIPAL_MANAGEMENT + "/" + ":id" + REACT_PERSON_VIEW + ":data"
        }
        element={
          <MainLayout>
            {" "}
            <ViewPerson />{" "}
          </MainLayout>
        }
      />

      <Route
        path={RETAIL_CLIENT + ":id" + REACT_PERSON_EDIT + ":data"}
        element={
          <MainLayout>
            {" "}
            <EditPerson />{" "}
          </MainLayout>
        }
      />

      <Route
        path={
          REACT_PRINCIPAL_MANAGEMENT + "/" + ":id" + REACT_PERSON_EDIT + ":data"
        }
        element={
          <MainLayout>
            {" "}
            <EditPerson />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PICTURE_LIBRARY}
        element={
          <MainLayout>
            {" "}
            <PictureDetails />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_TEMPLATE_LIST}
        element={
          <MainLayout>
            {" "}
            <Template />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_TEMPLATE_ADD}
        element={
          <MainLayout>
            {" "}
            <AddTemplate />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_TEMPLATE_EDIT + ":id"}
        element={
          <MainLayout>
            {" "}
            <EditTemplate />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_TEMPLATE_VIEW + ":id"}
        element={
          <MainLayout>
            {" "}
            <ViewTemplate />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PICTURE_LIBRARY_EDIT + ":id"}
        element={
          <MainLayout>
            {" "}
            <EditPicture />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_PICTURE_LIBRARY_ADD}
        element={
          <MainLayout>
            {" "}
            <AddPicture />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRODUCT}
        element={
          <MainLayout>
            {" "}
            <Product />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRODUCT_ADD}
        element={
          <MainLayout>
            {" "}
            <AddProduct />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRODUCT_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewProduct />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRODUCT_EDIT}
        element={
          <MainLayout>
            {" "}
            <EditProduct />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRODUCT_LABEL}
        element={
          <MainLayout>
            {" "}
            <ProductLabel />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRODUCT_BIND_TO_SCREEN}
        element={
          <MainLayout>
            {" "}
            <BindDataToScreen />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_PRICE_CHANGE_EVENT}
        element={
          <MainLayout>
            {" "}
            <PriceChangeEvent />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_PRICE_CHANGE_EVENT_ADD}
        element={
          <MainLayout>
            <AddPriceEvent />
          </MainLayout>
        }
      />
      <Route
        path={REACT_SCEEN_LOGS}
        element={
          <MainLayout>
            {" "}
            <ScreenLogs />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_PRICE_CHANGE_EVENT_VIEW + ":id"}
        element={
          <MainLayout>
            {" "}
            <ViewPriceChangeEvent />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_APPROVAL_FLOW_CHART_LIST}
        element={
          <MainLayout>
            {" "}
            <ApprovalFlowChart />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_APPROVAL_FLOW_CHART_ADD}
        element={
          <MainLayout>
            {" "}
            <AddFlowChart />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_APPROVAL_FLOW_CHART_EDIT + ":id"}
        element={
          <MainLayout>
            {" "}
            <EditFlowChart />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_APPROVAL_LIST}
        element={
          <MainLayout>
            {" "}
            <ApprovalList />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_APPROVAL_LIST_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewApprovalList />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_APPROVAL_LOG}
        element={
          <MainLayout>
            {" "}
            <ApprovalLog />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_CREATE_TEMPLATE}
        element={
          <MainLayout>
            {" "}
            <TemplateEditor />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_UPDATE_TEMPLATE}
        element={
          <MainLayout>
            {" "}
            <TemplateUpdateEditor />{" "}
          </MainLayout>
        }
      />
      <Route path={REACT_APPROVAL_LOG} element={<MainLayout> </MainLayout>} />
      <Route
        path={REACT_USER}
        element={
          <MainLayout>
            {" "}
            <Users />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_USER_ADD}
        element={
          <MainLayout>
            {" "}
            <AddUser />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_USER_EDIT}
        element={
          <MainLayout>
            {" "}
            <EditUser />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_USER_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewUser />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_AUTHORIZATION_LEVEL}
        element={
          <MainLayout>
            {" "}
            <AuthorizationLevel />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_AUTHORIZATION_LEVEL_ADD}
        element={
          <MainLayout>
            {" "}
            <AddAuthorizationLevel />{" "}
          </MainLayout>
        }
      />

      <Route path={REACT_LANDING} element={<Landing />} />
      <Route
        path={REACT_AUTHORIZATION_LEVEL_EDIT}
        element={
          <MainLayout>
            {" "}
            <EidtAuthorizationLevel />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_AUTHORIZATION_LEVEL_VIEW}
        element={
          <MainLayout>
            {" "}
            <ViewAuthorizationLevel />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_THIRD_PARTY_LIST}
        element={
          <MainLayout>
            {" "}
            <ThirdPartyClient />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_THIRD_PARTY_ADD}
        element={
          <MainLayout>
            {" "}
            <AddThirdPartyClient />{" "}
          </MainLayout>
        }
      />

      <Route
        path={REACT_SUBSCRIPTION}
        element={
          <MainLayout>
            {" "}
            <PackageSelector />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_SUBSCRIPTION_CREATE}
        element={
          <MainLayout>
            {" "}
            <CreateSubscription />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_CREATE_SUBSCRIPTION_RECORD}
        element={
          <MainLayout>
            {" "}
            <SubscriptionRecords />{" "}
          </MainLayout>
        }
      ></Route>
    </Routes>
  );
}
