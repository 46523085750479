import api from "../configurations/http-common"

export const priceStrategyList = async () => {
  return api.securedAxios().get("web/price_strategies")
}

export const getSubscriptionByUserId = async (userId) => {
  return api.securedAxios().get("web/subscription" + "?userId=" + userId);
}

export const getSubscriptionByClientId = async () => {
  return api.securedAxios().get("web/subscription/client");
}

export const createSubscription = async (payload) => {
  return api.securedAxios().post("web/subscription", payload);
}

export const getAllSubscription = async (request,formValues) => {
  let query = "";
  if (request.pageNumber !== null || request.pageNumber !== "") {
    query += "?pageNumber=" + request.pageNumber;
  }
  if (request.pageSize !== null || request.pageSize !== "") {
    query += "&pageSize=" + request.pageSize;
  }
  return api.securedAxios().post("web/subscription/all" + query,formValues);
}