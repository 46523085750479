import React, { useState } from 'react'
import { Grid, styled } from '@mui/material'
import RenderCommon from './RenderCommon'
import qrcode from '../../../assets/editor/qrcode.png'
export default function RenderImage(props) {
  const { info } = props

  return (
    <RenderCommon {...props}>
      <Grid
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: info.bgColor,
          overflow: 'hidden',
          opacity: info.transparency,
          borderRadius: info.borderRadius + 'px',
        }}
      >
        <img
          crossOrigin="anonymous"
          style={{
            height: '100%',
            width: '100%',
          }}
          src={info.url+"?_aa=" + new Date().getTime()}
        ></img>
      </Grid>
    </RenderCommon>
  )
}
